import classnames from 'classnames';

/**
 * This creates a checkbox.  It is based on the USWDS checkbox which satisfies
 * Section 508 accessibility requirements.
 *
 * @param {string}   id           unique id for the checkbox
 * @param {string}   name         unique name for form submission
 * @param {string}   label        checkbox label displayed to the user
 * @param {string}   labelStyle   additional USWDS style classes to add to the
 *                                label, e.g. 'margin-top-0' to remove a margin
 * @param {string}   wrapperStyle additional USWDS style classes to add to the
 *                                wrapper, e.g. 'margin-top-0' to remove a margin
 * @param {boolean}  checked      true if the checkbox should be checked
 * @param {boolean}  disabled     true if the checkbox should be disabled
 * @param {function} onChange     checkbox click handler, also called for the
 *                                <enter> or <space> key event for keyboard
 *                                navigation
 * @param {object}   inputProps   additional props to put on the checkobx
 *
 * The 'key' prop is set on the checkbox in its parent but is not passed
 * in here due to React's Special Props Warning
 * https://react.dev/warnings/special-props
 */

const Checkbox = ({
    id,
    name,
    label,
    labelStyle,
    wrapperStyle,
    checked=false,
    disabled=false,
    onChange,
    ...inputProps
}) => {

    const wrapperClasses = classnames('usa-checkbox', wrapperStyle);
    const labelClasses = classnames('usa-checkbox__label', labelStyle);

    return (
        <div className={wrapperClasses} >
            <input
                className='usa-checkbox__input'
                id={id}
                key={id}
                type='checkbox'
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                onKeyDown={(e) => {
                    if (!!e.key && (e.key === 'Enter' || e.key === ' ')) {
                        e.preventDefault();
                        e.target.checked = !checked;
                        onChange(e);
                    }
                }}
                {...inputProps}
            />
            <label className={labelClasses} htmlFor={id} >
                {label}
            </label>
        </div>
    );
};

export default Checkbox;
