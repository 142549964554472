import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // The list of the metrics for each of the registered services for the
  // currently selected time range
  userMetricSummary: null,

  // For each of the services in userMetricSummary, this is just the
  // service ID and service name, used to populate the Services filter
  userServicesFilterList: []
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setUserMetricSummary: (state, action) => {
      state.userMetricSummary = action.payload;
    },
    setUserServicesFilterList: (state, action) => {
      state.userServicesFilterList = action.payload;
    },
    reset: () => initialState,
  },
})

// Action creators are generated for each case reducer function
export const { setUserMetricSummary, setUserServicesFilterList, reset } = dashboardSlice.actions

export const selectUserDashboardMetricSummary = state => state.dashboard.userMetricSummary;
export const selectUserDashboardServicesFilterList = state => state.dashboard.userServicesFilterList;

export default dashboardSlice.reducer
