import HelpOutlineIcon from '@mui/icons-material/Help';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import BodyText from '../common/BodyText';
import CircularProgressIndicator from '../common/CircularProgressIndicator';
import DipIconButton from '../common/DipIconButton';


/**
 * Creates a display of metrics for a service version.  The display has a
 * header label on the left and the table on the right.  The metrics are
 * expected to include the properties so that help can be displayed if the
 * user clicks the "?" icon.
 *
 * @param {string}   title                      metrics header label
 * @param {string}   lineTwo                    optional second line for
 *                                              the header
 * @param {array}    metrics                    metrics to display.  Each
 *                                              metric should have the
 *                                              properties below:
 * @param {string}   metric.key                 unique identifier for the metric
 * @param {string}   metric.name                metric name
 * @param {string}   metric.defintion           metric definition, for help
 * @param {string}   metric.methodOfCalculation how the metric was computed,
 *                                              for the help
 * @param {string}   metric.value               metric value
 * @param {function} openHelpDialog             function to open the help
 *                                              dialog if the user clicks
 *                                              the "?"; signature is
 *                                              ({array} metrics, {string} title)
 * @param {object}   helpRef                    React useRef for the help dialog
 * @param {boolean}  sort                       whether to sort metrics (true for sorting, false otherwise)
 * @param {string}   sortBy                     the name of the metric property by which to sort metrics
 */
const MetricsDisplayBlock = ({
    title,
    lineTwo,
    metrics,
    openHelpDialog,
    helpRef,
    sort,
    sortBy,
}) => {

    const finalMetrics = sort === true
        ? metrics.sort((a, b) => (a?.[sortBy] < b?.[sortBy] ? -1 : 1))
        : metrics;

    /**
     * Creates the JSX for the data table with the metric name and help icon
     * in the left column and the data table in the right column
     */
    const getMetricsTable = () => (
        <div style={{ display: "grid", width: "100%", marginTop: ".3rem" }}>
            {finalMetrics.map((metric) => (
                <div className="grid-row flex-align-start" key={metric.key}>
                    <div className="grid-col-9">
                        <div className="usa-label margin-y-0">
                            {metric.name}
                        </div>
                    </div>
                    <div className="grid-col-3">
                        <BodyText
                            label={metric.value ?? "N/A"}
                            labelStyle={
                                "margin-top-0 margin-left-2 margin-right-1 text-right"
                            }
                        />
                    </div>
                </div>
            ))}
        </div>
    );

    /**
     * Returns the JSX header for the table
     */
    const getHeader = () => (
        <Stack direction='column' >

            <p className='usa-label margin-y-0' >
                <b>{title}</b>
                <DipIconButton
                    aria-label={`Help for ${title}`}
                    onClick={() => openHelpDialog(metrics, title)}
                    data-open-modal
                    aria-controls={helpRef?.current?.modalId}
                >
                    <HelpOutlineIcon />
                </DipIconButton>
            </p>
            {
                !!lineTwo &&
                <p className='usa-label margin-top-0'>
                    {lineTwo}
                </p>
            }
        </Stack>
    );

    /**
     * Returns the JSX to displays a header on a left and a table of metrics
     * on the right.  The caller should have checked that either the metrics
     * are valid or the metrics are currently being loaded.
     */
    return (
        <Grid container
            sx={{
                ml: '1rem',
                mt: '1rem',
            }}
        >
            <Grid item xs={4} >
                {getHeader()}
            </Grid>
            <Grid item xs={8} >
                {
                    !!metrics ?
                        getMetricsTable()
                    :
                        <CircularProgressIndicator 
                            altText={`Retrieving ${title}...`}
                        />
                }
            </Grid>
        </Grid>
    );
};

export default MetricsDisplayBlock;
