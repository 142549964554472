import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setAlert } from '../app/slices/alert';
import { setPageTitle } from '../app/slices/page';
import { clearFilters, fetchCategories, fetchProviders, fetchSearchResults,
         selectFetchingSearchResult, updateSearchFilter 
       } from '../app/slices/search';
import { fetchSubscriptions, selectFetchingSubscriptions,
         selectSubscriptions } from '../app/slices/subscription';
import { selectCompanyId } from '../app/slices/user';
import { useAbortController } from '../hooks';
import { URL_SEARCH } from '../utils/navigation';
import FilterSelection from '../components/search/FilterSelection';
import ServicesCardDisplay from '../components/service-card/ServicesCardDisplay';
import SubscriptionsCardDisplay from '../components/service-card/SubscriptionsCardDisplay';

/**
 * This provides the main page with the service tiles and search filters.
 */

function Home() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // For obtaining the services that this user is subscribed to
  const [ loadedSubscriptions, setLoadedSubscriptions ] = useState(false);
  const subscriptions = useSelector(selectSubscriptions);
  const fetchingSubscriptions = useSelector(selectFetchingSubscriptions);
  const companyId = useSelector(selectCompanyId);

  const { abortSignalRef, abort } = useAbortController();

  // For obtaining all services
  const fetchingAllServices = useSelector(selectFetchingSearchResult);
  const [ allServices, setAllServices ] = useState([]);


  useEffect(() => {
    dispatch(setPageTitle('Catalog'));

    // clear filters when the page is opened
    // but not when the page is reloaded by clicking the back button.
    handleClearFilters();

    // get category list
    dispatch(fetchCategories({ abortSignalRef, abort }))
    .unwrap()
    .catch(err => {
        if (err === 'cancelled')
            return;

        console.error('Failed to get categories', err);
        dispatch(setAlert({
            show: true,
            message: 'Failed to get categories',
            severity: 'error'
        }));
    });

    // get provider list
    dispatch(fetchProviders({ abortSignalRef, abort }))
    .unwrap()
    .catch(err => {
        if (err === 'cancelled')
            return;

        console.error('Failed to get providers:', err);
        dispatch(setAlert({
            show: true,
            message: 'Failed to get providers',
            severity: 'error'
        }));
    });

    // Get the service information to display
    getAllServices();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get subscriptions.  This has to be done separately since it depends
  // on the companyId which is not yet set the first time this page is loaded
  useEffect(() => {
    if (companyId) {
        const getLogo = true;
        dispatch(fetchSubscriptions({ companyId, getLogo, abortSignalRef }))
        .unwrap()
        .catch(err => {
            if (err === 'cancelled')
                return;

            console.error('Failed to get subscriptions:', err);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get subscriptions',
                severity: 'error'
            }));
        })
        .finally(() => {
            setLoadedSubscriptions(true);
        });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);


  // Retrieves all services
  const getAllServices = async () => {
    dispatch(fetchSearchResults({
        categories: [],
        searchText: '',
        serviceTypes: [],
        providerIds: [],
        abortSignalRef: abortSignalRef,
        getServiceCountPerFilter: true
    }))
    .unwrap()
    .then(({ services }) => {
        let copyServices = [];
        if (services) {
            copyServices = [...services];
        }
        setAllServices(copyServices);
    })
    .catch(err => {
        if (err === 'cancelled')
            return;

        console.error('Failed to get all services', err);
        dispatch(setAlert({
            show: true,
            message: 'Failed to get all services',
            severity: 'error'
        }));
    });
  };

  // Update the filters and then fetch the search results
  const handleSearchFilterChange = (payload) => {
    dispatch(updateSearchFilter(payload));
    dispatch(fetchSearchResults({ 
        getServiceCountPerFilter: true, 
        abortSignalRef, abort 
    }))
    .unwrap()
    .then(() => navigate(URL_SEARCH))
    .catch(err => {
        if (err === 'cancelled')
        return;
    });
  }

  const handleClearFilters = async () => {
    dispatch(clearFilters());
    dispatch(fetchSearchResults({ 
        getServiceCountPerFilter: true, 
        abortSignalRef, abort 
    }))
    .unwrap()
    .catch(err => {
        if (err === 'cancelled')
        return;
    });
  }

  return (
      <Stack direction='row' id='home'
          sx={{
              display: 'flex',
              marginTop: '1rem',
          }}
      >
          { /* Checkboxes for filter selection */ }
          <FilterSelection
              handleSearchFilterChange={handleSearchFilterChange}
              handleClearFilters={handleClearFilters}
          />

          { /* Service cards */ }
          <Card>
              <CardContent>
                  <SubscriptionsCardDisplay
                      subscriptions={subscriptions}
                      fetching={!loadedSubscriptions || fetchingSubscriptions}
                  />
                  <ServicesCardDisplay
                      fetchingSubscriptions={fetchingSubscriptions}
                      subscriptions={subscriptions}
                      fetchingAllServices={fetchingAllServices}
                      services={allServices}
                      isAllServices={true}
                  />
              </CardContent>
          </Card>
      </Stack>
  );
};

export default Home
