import validator from "validator";
import { trimWhitespace } from "./utils";

export const isErrorObjectEmpty = (error) => {
  if (error === undefined || error === null ) {
    return true;
  }
  
  return Object.keys(error).length === 0 || 
    Object.values(error).every(v => (
      v === null || 
      v === undefined || 
      v === '' || 
      // Check nested object as well
      typeof(v) === 'object' ? isErrorObjectEmpty(v) : false
    ))
}

export const isInvalidTextField = (value, min, max, specials) => {
  let validString = null

  if(value) {
    value = value.trim()
    if(!validator.isLength(value, {min: min, max: max})) {
      validString = 'Value must be between ' + min + ' and ' + max + 
        ' characters (currently ' + value.length + ' characters)'
    } else if(!validator.isAlphanumeric(value, 'en-US', {ignore: specials})) {

      if(specials && specials.length > 0) {
        validString = "Allowed special characters are '" + specials + "'"
      } else {
        validString = 'Only alphanumeric values are allowed'
      }
    }
  } else {
    validString = 'Invalid text'
  }

  return validString
}

// Validates the date string has the correct format
export const isInvalidDate = (date, what, format='YYYY-MM-DD') => {
    let rtn = null;
    if (date) {
        if (!validator.isDate(date, {format: format})) {
            rtn = `${what ? what : 'Date'} format is required to be ${format}`;
        }
    }
    else {
        rtn = `${what ? what : 'Date'} is required`;
    }

    return rtn;
    
}

// Validates the version string has the correct X.Y.Z format
export const isInvalidVersion = (version, what) => {
    let rtn = null;
    if (version) {
        if (!validator.isSemVer(version)) {
            rtn = `${what ? what : 'Version'} format is required to be X.Y.Z`;
        }
    }
    else {
        rtn = `${what ? what : 'Version'} is required`;
    }

    return rtn;
}

/**
 * Determines if the given phone number is valid.  Punctuation can be
 * parentheses around the area code, either hyphens or periods between
 * the area code and number groups, and "x" or "ext" to prefix an optional
 * extension.  Examples:
 * 8005551212
 * 8005551212456
 * 800.555.1212
 * 800-555-1212
 * (800)555-1212
 * (800)555-1212 x456
 *
 * @param {string} phone   phone number entered by the user
 *
 * @return {boolean} true if the phone number is valid
 */

export const isValidPhoneNumber = (phone) => {
  let ok = false;
  phone = trimWhitespace(phone);
  if (phone) {
      let regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})(\s?[EeXxTt.]*\s?[0-9]*)?$/;
      ok = regex.test(phone);
  }
  return ok;
};


// Validates the list of domains
export const validateDomains = (domains) => {
  let rtn = null;
  if (domains) {
    for (let domain of domains) {
      if (!validator.isFQDN(domain.trim())) {
        rtn = `Invalid domain: ${domain}`;
        break;
      }
    }
  }
  return rtn;
}