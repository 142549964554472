import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // info about the company the user belongs to
  companyId: null,
  companyName: null,
  companyAbbreviation: null,
  companyRoles: [],

  // user's DIP id
  userId: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setField: (state, action) => {
      const {name, value} = action.payload;
      state[name] = value;
    },

    reset: () => initialState,
  },
})

// Action creators are generated for each case reducer function
export const { setField, reset } = userSlice.actions

export const selectCompanyId = state => state.user.companyId;
export const selectCompanyName = state => state.user.companyName;
export const selectCompanyAbbreviation = state => state.user.companyAbbreviation;
export const selectCompanyRoles = state => state.user.companyRoles;
export const selectUserId = state => state.user.userId;

export default userSlice.reducer
