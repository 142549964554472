import { useSelector } from 'react-redux';

import { selectBreadcrumbs } from '../../app/slices/breadcrumbs';
import { Link } from 'react-router-dom';


/**
 * This provides the display of breadcrumbs.  The breadcrumb data is obtained
 * from the store.
 */
function CatalogBreadcrumbs() {

    const breadcrumbs = useSelector(selectBreadcrumbs);

    return (
        <nav className="usa-breadcrumb padding-y-05 bg-base-lighter"
             aria-label="Breadcrumbs"
        >
            <ol className="usa-breadcrumb__list margin-left-4">
                {
                    breadcrumbs && breadcrumbs.map((link, idx) => {
                        if (!link) {
                            return null;
                        }
                        if (link.href !== null) {
                            return (
                                <li className="usa-breadcrumb__list-item"
                                    key={`breadcrumb-${idx}`}
                                >
                                    <Link
                                        to={link.href}
                                        className="usa-breadcrumb__link text-primary"
                                    >
                                        {link.name}
                                    </Link>
                                </li>
                            )
                        }
                        return (
                            <li className="usa-breadcrumb__list-item"
                                key={`breadcrumb-${idx}`}
                            >
                                {link.name}
                            </li>
                        )
                    })
                }
            </ol>
        </nav>
    );
}

export default CatalogBreadcrumbs;
