import { Card, CardContent, Container, Grid } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { createPlatformSummaryCapability, deletePlatformSummaryCapability, 
         getPlatformSummaryCapability, updatePlatformSummaryCapability 
       } from "../../../apiClient";
import { setAlert } from "../../../app/slices/alert";
import { setLinks as setBreadcrumbs } from '../../../app/slices/breadcrumbs';
import { setPageTitle } from '../../../app/slices/page';
import ActionButton from "../../../components/common/ActionButton";
import BodyText from "../../../components/common/BodyText";
import LinkButton from "../../../components/common/LinkButton";
import MajorHeading from "../../../components/common/MajorHeading";
import OkCancelDialog from "../../../components/common/OkCancelDialog";
import TextArea from "../../../components/common/TextArea";
import { useAbortController } from "../../../hooks";
import { PLATFORM_NEWS_PAGE_ACTION, validatePlatformSummary 
       } from "../../../utils/platformNews";
import { URL_ADMIN, URL_ADMIN_PLATFORM_NEWS, 
         URL_ADMIN_PLATFORM_SUMMARY_CAPABILITIES, URL_HOME 
       } from "../../../utils/navigation";
import { trimWhitespace, CANCEL_DELETE_TEXT, OK_DELETE_TEXT
       } from "../../../utils/utils";
import { isErrorObjectEmpty } from "../../../utils/validation";

/**
 * This component provide the ability to create/edit/delete a single
 * Summary of Capability item.
 */
export default function PlatformSummaryCapabilityDetail() {
    const { abortSignalRef, isCancel } = useAbortController();

    const formRef = useRef(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const [summaryId, setSummaryId] = useState(null);
    const [pageAction, setPageAction] = useState(PLATFORM_NEWS_PAGE_ACTION.create); // action determined based on searchParams
    const [readonlyInputFields, setReadonlyInputFields] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const deleteDialogRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const isCreating = useMemo(() => pageAction === PLATFORM_NEWS_PAGE_ACTION.create, [pageAction]);
    const isDeleting = useMemo(() => pageAction === PLATFORM_NEWS_PAGE_ACTION.delete, [pageAction]);
    const isEditing = useMemo(() => pageAction === PLATFORM_NEWS_PAGE_ACTION.update, [pageAction]);
    const isViewing = useMemo(() => pageAction === PLATFORM_NEWS_PAGE_ACTION.view, [pageAction]);

    const [input, setInput] = useState({
        description: ''
    });

    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPageAction(searchParams.get('action'));

        let summaryId = searchParams.get('summaryId')
        if (summaryId !== null) {
            setSummaryId(summaryId);
            initSummary(summaryId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])

    useEffect(() => {
        setReadonlyInputFields(false);

        // Set the page title and read-only flag
        let title = '';
        switch (pageAction) {
            case PLATFORM_NEWS_PAGE_ACTION.create:
                title = '[Admin] Create Summary Capability';
                break;
            case PLATFORM_NEWS_PAGE_ACTION.update:
                title = '[Admin] Edit Summary Capability';
                break;
            case PLATFORM_NEWS_PAGE_ACTION.view:
                setReadonlyInputFields(true);
                title = '[Admin] View Summary Capability:';
                break;
            case PLATFORM_NEWS_PAGE_ACTION.delete:
                setReadonlyInputFields(true);
                setShowDeleteDialog(true);
                title = '[Admin] Delete Summary Capability:';
                break;
            default:
                console.error(`Invalid page action: ${pageAction}`);
                break;
        };
        dispatch(setPageTitle(title));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageAction])

    const initSummary = (summaryId) => {
        setLoading(true);
        getPlatformSummaryCapability({ 
            id: summaryId, 
            abortSignal: abortSignalRef?.current 
        })
        .then(res => {
            if (res.data) {
                setInput({
                    description: res.data.description
                })
            }
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to get platform summary capability:', 
                err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get platform summary capability',
                severity: 'error'
            }));
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    const canSubmit = () => {
        let errors = validatePlatformSummary(input);
        setFormErrors(errors);

        let rtn = formRef.current.reportValidity() && isErrorObjectEmpty(errors)

        return rtn;
    };

    const resetFormErrors = () => {
        setFormErrors({})
    }

    const navigateToPlatformSummaryListPage = () => {
        navigate(URL_ADMIN_PLATFORM_SUMMARY_CAPABILITIES);
    };

    const handleCancelDeleteDialog = () => {
        setShowDeleteDialog(false);
        updateSearchParams({ action: PLATFORM_NEWS_PAGE_ACTION.view });
    };

    const handleDelete = () => {
        setShowDeleteDialog(false);
        setLoading(true);
        deletePlatformSummaryCapability({
            id: summaryId,
            abortSignal: abortSignalRef?.current
        })
        .then(() => {
            dispatch(setAlert({
                show: true,
                message: 'Successfully deleted summary capability',
                severity: 'success'
            }));
            setLoading(false);
            navigateToPlatformSummaryListPage();
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to delete the summary capability:', 
                err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to delete the summary capability',
                severity: 'error'
            }));

            setLoading(false);
        });
    }

    const handleSubmit = async () => {
        try {
            if (canSubmit()) {
                let body = { ...input };

                setLoading(true);
                // trim whitespace from all text input fields
                Object.entries(body).forEach(([k, v]) => {
                    body[k] = trimWhitespace(v);
                });

                switch (pageAction) {
                    case PLATFORM_NEWS_PAGE_ACTION.create:
                        await createPlatformSummaryCapability({ 
                            body, 
                            abortSignal: abortSignalRef?.current
                        });
                        dispatch(setAlert({
                            show: true,
                            message: 'Successfully created a new summary capability',
                            severity: 'success'
                        }));
                        break;
                    case PLATFORM_NEWS_PAGE_ACTION.update:
                        await updatePlatformSummaryCapability({ 
                            id: summaryId, 
                            body, 
                            abortSignal: abortSignalRef?.current 
                        });
                        dispatch(setAlert({
                            show: true,
                            message: 'Successfully updated the summary capability',
                            severity: 'success'
                        }));
                        break;
                    default:
                        console.error(`Invalid action type: ${pageAction}`)
                        break;
                }

                setLoading(false);
                resetFormErrors();
                navigateToPlatformSummaryListPage();
            }
        } catch (error) {
            if (isCancel(error))
                return

            console.error(`failed to ${pageAction} a summary capability:`, 
                error.message);
            dispatch(setAlert({
                show: true,
                message: `Failed to ${pageAction} a summary capability`,
                severity: 'error'
            }));

            setLoading(false);

        }
    }

    const updateSearchParams = (newParams) => {
        let ogParams = {}
        for (let [k, v] of searchParams.entries()) {
            ogParams = {
                ...ogParams,
                [k]: v
            }
        }
        setSearchParams({
            ...ogParams,
            ...newParams
        });
    };

    return (
        <Container id='admin-summary-capability-detail' disableGutters>
            <Card sx={{ p: '1rem' }}>
                <CardContent>
                    <form ref={formRef}>
                        <MajorHeading label='Summary Capability' loading={loading} />
                        <TextArea
                            id='summary-capability-description'
                            name='description'
                            label='Description'
                            value={input.description}
                            required
                            disabled={readonlyInputFields}
                            onChange={(e) => handleInputChange(e)}
                            errorMessage={formErrors?.description}
                        />

                        <Grid container item xs={12} spacing={2}
                            sx={{ mt: 2 }}
                        >
                            <Grid item
                                xs={12}
                                sm={3}
                                sx={{
                                    textAlign: { xs: 'end', sm: 'left' }
                                }}
                            >
                                {
                                    !isCreating &&
                                    <ActionButton text='Delete' isDelete
                                        disabled={loading}
                                        onClick={() => {
                                            updateSearchParams({
                                                action: PLATFORM_NEWS_PAGE_ACTION.delete
                                            })
                                        }}
                                    />
                                }
                            </Grid>
                            <Grid item
                                xs={12}
                                sm={9}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'end'
                                }}
                            >
                                <LinkButton text='Cancel'
                                    disabled={loading}
                                    href={URL_ADMIN_PLATFORM_SUMMARY_CAPABILITIES}
                                    style={{ marginRight: '1rem' }}
                                />
                                {
                                    isCreating &&
                                    <ActionButton text='Create'
                                        disabled={loading}
                                        onClick={() => handleSubmit()}
                                    />
                                }
                                {
                                    (isViewing || isDeleting) &&
                                    <ActionButton text='Edit'
                                        disabled={loading}
                                        onClick={() => updateSearchParams({
                                            action: PLATFORM_NEWS_PAGE_ACTION.update
                                        })}
                                    />
                                }
                                {
                                    isEditing &&
                                    <ActionButton text='Save'
                                        disabled={loading}
                                        onClick={() => handleSubmit()}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>

            {/* Dialog for the user to delete a summary capability.  It must be
                created even if not shown for the sake of the ref */}
            <OkCancelDialog
                id='confirm-summary-deletion-dialog'
                open={showDeleteDialog}
                heading='Confirm Summary Capability Deletion'
                okText={OK_DELETE_TEXT}
                cancelText={CANCEL_DELETE_TEXT}
                handleOkOp={handleDelete}
                handleCancelOp={handleCancelDeleteDialog}
                dialogRef={deleteDialogRef}
            >
                <BodyText labelStyle='text-center'>
                    Are you sure you want to delete summary capability
                </BodyText>
                <BodyText labelStyle='text-center margin-top-1'>
                    <strong>{input.description}</strong>?
                </BodyText>
            </OkCancelDialog>
        </Container>
    )
}


// Breadcrumbs are only available for the Admin
const breadcrumbs = [
    { name: 'Home', href: URL_HOME },
    { name: 'Admin', href: URL_ADMIN },
    { name: 'Platform News', href: URL_ADMIN_PLATFORM_NEWS },
    { name: 'Platform Summary of Capabilities', href: URL_ADMIN_PLATFORM_SUMMARY_CAPABILITIES },
    { name: 'Platform Summary Capability', href: null }
];
