import Divider from "@mui/material/Divider";

import MajorHeading from '../common/MajorHeading';

/**
 * This provides a general section header with a title and then a divider
 * beneath it.
 */
const SectionHeader = ({ title, labelStyle }) => (
  <>
    <MajorHeading
        label={title}
        labelStyle={`margin-bottom-0 ${labelStyle}`}
    />

    <Divider
        sx={{
            backgroundColor: 'darkgray',
            marginBottom: '1rem'
        }}
    />
  </>
);

export default SectionHeader;
