import validator from "validator"

import { DESCRIPTION_MAX_LENGTH } from "./serviceRegistration"
import { isInvalidTextField } from "./validation"

export const validateDataTransferSource = (input) => {
    let errors = {};

    if (input) {
        // allowed special characters for the text field
        let allowedSpecialChars = " .,()+-*/:";
        errors.name = isInvalidTextField(input.name, 1, 50, allowedSpecialChars);
        // uncomment the codes below when the source identifier and source url fields are available in UI again.
        // errors.source_identifier = isInvalidTextField(input.source_identifier, 1, 50, allowedSpecialChars);
        // errors.source_url = validator.isURL(input.source_url, {
        //     require_protocol: 'true',
        //     protocols: ['http', 'https', 'ftp', 's3']
        // }) ? null : 'Invalid URL format.';
        // errors.scopes = isInvalidTextField(input.scopes, 1, 50, allowedSpecialChars);
        errors.description = isInvalidTextField(input.description, 1,
            DESCRIPTION_MAX_LENGTH, allowedSpecialChars);
    }

    return errors;
}

export const validateDataTransferDestination = (input) => {
    let errors = {};

    if (input) {
        // allowed special characters for the text field
        let allowedSpecialChars = " .,()+-*/:";
        errors.name = isInvalidTextField(input.name, 1, 50, allowedSpecialChars);
        errors.dest_platform = isInvalidTextField(input.dest_platform, 1, 50, allowedSpecialChars);
        errors.dest_account = validator.isNumeric(input.dest_account, { no_symbols: true }) ? null :
            'Invalid account number';
        errors.dest_identifier = isRootOrRoleArn(input.dest_identifier) ? null :
            'Valid ARN format: arn:aws:iam::<account>:root or arn:aws:iam::<account>:role/<role name>';
        // errors.dest_url = validator.isURL(input.dest_url, {
        //     require_protocol: 'true',
        //     protocols: ['http', 'https', 'ftp', 's3']
        // }) ? null : 'Invalid URL format.';
    }

    return errors;
}

const rootOrRoleArnRegex = new RegExp(/^arn:aws:iam::\d+:(root|role\/[A-Za-z0-9_+=,.@-]+)$/);
const isRootOrRoleArn = (arn) => {
    let rtn = false;
    if (arn) {
        rtn = rootOrRoleArnRegex.test(arn);
    }
    return rtn;
}

export class DataTransferSubscriptionUtils {
    static STATUSES = {
      CREATED: 'created',
      UNDER_REVIEW: 'under_review',
      ENABLED: 'enabled',
      DISABLED: 'disabled'
    };
  }
