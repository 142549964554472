import Grid from '@mui/material/Grid';

import Select from '../common/Select';
import TextField from '../common/TextField';
import { AUTH_PROVIDER_COGNITO } from '../../utils/serviceRegistration';

/**
 * This provides the user interface to enter the OAuth2 authentication
 * parameters for a service version.
 */

const OAuth2Fields = ({
    input,
    handleInputChange,
    readOnly,
    isEditingRestricted,
    formErrors
}) => {
    return (

        <Grid container item spacing={2}>
            <Grid item xs={12}>
                <Select
                    id='auth-oauth2-provider'
                    name='auth_provider'
                    label='Authentication Provider'
                    labelStyle='margin-top-0'
                    value={input.auth_provider}
                    itemList={providerOptions}
                    onChange={handleInputChange}
                    required
                    disabled={isEditingRestricted || readOnly}
                    errorMessage={formErrors?.authProvider}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='auth-oauth2-client-id'
                    name='auth_client_id'
                    label='Authentication Client ID'
                    labelStyle='margin-top-0'
                    value={input.auth_client_id}
                    onChange={handleInputChange}
                    required
                    disabled={isEditingRestricted || readOnly}
                    errorMessage={formErrors?.authClientId}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='auth-oauth2-client-secret'
                    name='auth_client_secret'
                    label='Authentication Client Secret'
                    labelStyle='margin-top-0'
                    value={input.auth_client_secret}
                    onChange={handleInputChange}
                    required
                    disabled={isEditingRestricted || readOnly}
                    errorMessage={formErrors?.authClientSecret}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='auth-oauth2-token-url'
                    name='auth_token_url'
                    label='Authentication Token URL'
                    labelStyle='margin-top-0'
                    value={input.auth_token_url}
                    onChange={handleInputChange}
                    required
                    disabled={isEditingRestricted || readOnly}
                    errorMessage={formErrors?.authTokenUrl}
                />
            </Grid>
        </Grid>
    );
};

const providerOptions = Object.freeze([
    {
        id: 'auth-oauth2-provider',
        value: AUTH_PROVIDER_COGNITO,
    }
]);

export default OAuth2Fields;
