import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material';

import classnames from 'classnames';

import CircularProgressIndicator from './CircularProgressIndicator';

/**
 * This provides a backdrop with a circular progress icon and a message.
 *
 * @param {boolean} open        true to display the backdrop
 * @param {string}  msg         the message to display with the icon
 * @param {string}  msgColor    (optional) USWDS style for the message
 *                              color; default is white
 */

const ProcessingOverlay = ({open, msg, msgColor='text-white'}) => {

    const labelClasses = classnames('usa-label',
        msgColor, 'font-heavy', 'font-sans-2xl');

    return (
        <StyledBackdrop open={open} >
            {
                !!open &&
                <div className='display-flex flex-align-center flex-start'>
                    <CircularProgressIndicator
                        type='backdrop'
                        altText={msg}
                        sx={{ mr: '1rem'}}
                    />
                    <label className={labelClasses} >
                        {msg}
                    </label>
                </div>
            }
        </StyledBackdrop>
    );
};

const StyledBackdrop = styled(Backdrop)({
    backgroundColor: 'rgba(0, 0, 0, .7)',
    zIndex: 1,
});

export default ProcessingOverlay;
