import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setPageTitle } from "../app/slices/page";
import BodyText from "../components/common/BodyText";
import MinorHeading from "../components/common/MinorHeading";
import { isUserAuthenticated } from "../utils/auth";
import { URL_HOME, URL_REQUSET_ACCESS } from "../utils/navigation";


const DIP_DOCUMENT_LINK = 'https://nari.arc.nasa.gov/atmx-dip/';
const DIP_EMAIL = 'ARC-DIP-EXT@mail.nasa.gov';
const WELCOME_TEXT_STYLE = {
    textShadow: '0 0 5px #374375'
}
const FOOTER_TEXT_STYLE = {
    textShadow: '0 0 2px black,  2px 2px 5px #374375'
}
/**
 * This provides the Welcome page before the user has logged in.  It guides
 * the user to become a partner, create an account, or sign in.
 */

export default function Welcome() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setPageTitle(''));
        isUserAuthenticated()
            .then(res => {
                if (res) {
                    navigate(URL_HOME, { replace: true });
                }
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Creates an element for an "Access DIP" step; it consists
    // of the text of the step and an action button.
    //
    // Note:  This cannot use the standard 'ActionButton' becuase the href
    // goes outside of our domain and the ActionButton only works for routes
    // within our domain.
    const AccessStep = ({ step, action, onClick }) => (
        <Grid
            item
            xs={12}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
            }}
        >
            <Box>
                <MinorHeading label={step} labelStyle='margin-top-0' />
                <Button
                    variant='contained'
                    onClick={onClick}
                    sx={{
                        marginBottom: '1rem',
                        backgroundColor: '#374274',
                        color: 'white',
                        '&:hover, &:focus': {
                            backgroundColor: '#2051B1',
                            color: 'white',
                        },
                        textTransform: 'none',
                    }}
                >
                    {action}
                </Button>
            </Box>
        </Grid>
    );

    const WelcomeHeaders = () => (
        <>
            <h1 className='text-center text-white text-bold font-sans-3xl'
                style={{ ...WELCOME_TEXT_STYLE }}
            >
                Welcome to the Digital Information Platform
            </h1>
            <span className='display-flex flex-justify-center text-white text-normal font-sans-xl'
                style={{ ...WELCOME_TEXT_STYLE }}
            >
                NASA's Prime Resource for Services and Data Offerings in Aeronautics
            </span>
        </>
    )

    const WelcomeFooter = () => (
        <span className='display-flex flex-justify-center text-white text-normal font-sans-xl'
            style={{ ...FOOTER_TEXT_STYLE }}
        >
            Enabling Innovations via Cloud Driven Data Solutions
        </span>
    )

    const WelcomeCardContent = ({ ...props }) => (
        <Card
            sx={{
                height: '100%',
                py: '1.0rem'
            }}
        >
            <CardContent
                sx={{
                    height: '100%',
                    display: 'block',
                    alignItems: 'center'
                }}
            >
                {props.children}
            </CardContent>
        </Card>
    )

    return (
        <Grid container
            sx={{
                mt: '7vh'
            }}
        >
            <Grid
                item
                xs={12}
            >
                <WelcomeHeaders />
            </Grid>

            <Grid
                item
                container
                spacing={3}
                sx={{
                    my: '1rem'
                }}
            >
                {/* Welcome card on the left hand side */}
                <Grid
                    item
                    xs={12}
                    sm={6}
                >
                    <WelcomeCardContent>
                        <BodyText
                            label="Historical and real-time aviation-specific
                                services on DIP aim to improve the efficiency
                                and predictability of flight operations. NASA
                                and other service providers have registered
                                a catalog of these services that users can
                                view and subscribe to for data ingestion or to
                                leverage to build new advanced services."
                            labelStyle="font-body-md line-height-body-5 margin-top-0"
                        />
                        <BodyText
                            label="Official DIP partners, including conventional
                                and new entrant flight operators, service
                                providers, platform providers, and data
                                integrators, are committed to enabling an
                                ecosystem of cloud-based digital aviation
                                services."
                            labelStyle="font-body-md line-height-body-5"
                        />
                        <BodyText
                            label="Organizations contributing to the aviation community are welcome to request access to DIP's publicly available services."
                            labelStyle="font-body-md line-height-body-5"
                        />
                    </WelcomeCardContent>
                </Grid>

                {/* Welcome card on the right hand side */}
                <Grid
                    item
                    xs={12}
                    sm={6}
                >
                    <WelcomeCardContent>
                        <Stack
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={4}
                            sx={{
                                height: '100%'
                            }}
                        >
                            <AccessStep
                                step='Already have an account?'
                                action='Log In'
                                onClick={() => Auth.federatedSignIn()}
                            />

                            <AccessStep
                                step='New to DIP?'
                                action='Request Access'
                                onClick={() => navigate(URL_REQUSET_ACCESS)}
                            />

                            <Stack
                                alignItems="center"
                                spacing={2}
                            >
                                <BodyText
                                    label="To learn about DIP partnership
                                        opportunities and expanded access,"
                                    labelStyle="text-center font-body-2xs margin-top-0 line-height-body-5"
                                />
                                <BodyText
                                    label="please visit"
                                    labelStyle="text-center font-body-2xs margin-top-0 line-height-body-5"
                                >
                                    &nbsp;<a href={DIP_DOCUMENT_LINK} target='_blank' rel='noreferrer'>
                                        {DIP_DOCUMENT_LINK} (opens in a new tab)
                                    </a>
                                </BodyText>
                                <BodyText
                                    label="or contact us at"
                                    labelStyle="text-center font-body-2xs margin-top-0 line-height-body-5"
                                >
                                    &nbsp;<a href={`mailto:${DIP_EMAIL}`}>{DIP_EMAIL}</a>
                                </BodyText>
                            </Stack>
                        </Stack>
                    </WelcomeCardContent>
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                sx={{
                    mt: '2rem'
                }}
            >
                <WelcomeFooter />
            </Grid>
        </Grid>
    );
};
