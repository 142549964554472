import Box from '@mui/material/Box';

import SectionHeader from "../common/SectionHeader";
import TextArea from '../common/TextArea';
import TextField from '../common/TextField';

/**
 * This provides a user interface for a service provider to enter the
 * "domain of applicability" collection of information when registering
 * a new service or editing a service registration:
 * - geographical extent
 * - date ranges
 * - data sources
 */

const WizardVersionDomain = ({
    input,
    handleInputChangeByNameAndValue,
    readOnly,
    formErrors
}) => {

    const handleDomainOfApplicabilityChange = ({ target }) => {
        handleInputChangeByNameAndValue(
            'domain_of_applicability',
            {
                ...input.domain_of_applicability,
                [target.name]: target.value
            }
        );
    }

    return (
        <Box>
            <SectionHeader
                title='Domain of Applicability'
                labelStyle='margin-top-4'
            />
            <div>
                <TextField
                    id='geographical-extent'
                    name='geographical_extent'
                    label='Geographical Extent'
                    labelStyle='margin-top-0'
                    value={input?.domain_of_applicability?.geographical_extent ?? ''}
                    onChange={handleDomainOfApplicabilityChange}
                    disabled={readOnly}
                    errorMessage={formErrors?.doaGeographicExtent}
                    help='Provide a description of the
                          geographic coverage and date ranges
                          (where applicable) of the information
                          exchanged. Geographic coverage may be
                          expressed as Flight Information Region
                          (FIR), airspace, airport, polygon, etc.'
                />
                <TextField
                    id='date-range'
                    name='date_range'
                    label='Date range that data are available for'
                    value={input?.domain_of_applicability?.date_range ?? ''}
                    onChange={handleDomainOfApplicabilityChange}
                    disabled={readOnly}
                    errorMessage={formErrors?.doaDateRange}
                />
                <TextArea
                    id='data-sources'
                    name='data_sources'
                    label='Data Sources'
                    inputStyle='height-8'
                    value={input.data_sources}
                    onChange={e => handleInputChangeByNameAndValue(
                        'data_sources', e.target.value)}
                    disabled={readOnly}
                    errorMessage={formErrors?.doaDataSources}
                    help='Specify origin of the data and any
                          modifications applied.'
                />
            </div>
        </Box >
    );
};

export default WizardVersionDomain;
