import axios from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import { Auth } from "aws-amplify";
import { refreshSessionToken } from './utils/auth';

export const _axios = axios.create();

// Axios retry configuration
axiosRetry(_axios, {
  retries: 2, // number of retries
  
  // if retry condition is not specified, by default idempotent requests are retried
  retryCondition: (error) => {
    // Retry if error is not caused by network, idempotent request or 404 resource not found error.
    return isNetworkOrIdempotentRequestError(error) || error?.response?.status !== 404;
  }
});

const delta = 10 * 60; // 10 minutes

_axios.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    let session = await Auth.currentSession();
    // expiration time in seconds
    const exp = session.getIdToken().getExpiration();
    // current time in seconds
    const date = new Date();
    const now = Math.floor(date.getTime() / 1000);

    // Force token to refresh 10 minutes before it expires
    if (now >= exp - delta) {
      await refreshSessionToken();
      // get latest refreshed token
      session = await Auth.currentSession();
    }

    if (session.isValid()) {
      config.headers.Authorization = "Bearer " + session.getIdToken().getJwtToken();
    } else {
      console.error("invalid cognito session");
      session = await Auth.currentSession();
      console.log("attempted refresh, is it valid?", session.isValid());
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

