import { Card, CardContent, Container, Grid } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { createPlatformRelease, deletePlatformRelease, 
         getPlatformRelease, updatePlatformRelease 
       } from "../../../apiClient";
import { setAlert } from "../../../app/slices/alert";
import { setLinks as setBreadcrumbs } from '../../../app/slices/breadcrumbs';
import { setPageTitle } from '../../../app/slices/page';
import ActionButton from "../../../components/common/ActionButton";
import BodyText from "../../../components/common/BodyText";
import LinkButton from "../../../components/common/LinkButton";
import MajorHeading from "../../../components/common/MajorHeading";
import OkCancelDialog from "../../../components/common/OkCancelDialog";
import TextField from "../../../components/common/TextField";
import { useAbortController } from "../../../hooks";
import { URL_ADMIN, 
         URL_ADMIN_PLATFORM_NEWS, URL_HOME 
       } from "../../../utils/navigation";
import { getPlatformReleaseNewsItemPage, PLATFORM_NEWS_PAGE_ACTION, 
         validatePlatformRelease 
       } from "../../../utils/platformNews";
import { DATE_FORMAT, TIMESTAMP_FORMAT, getFormattedDateFromString, 
         getFormattedNowDate 
       } from "../../../utils/timeUtils";
import { trimWhitespace, CANCEL_DELETE_TEXT, OK_DELETE_TEXT
       } from "../../../utils/utils";
import { isErrorObjectEmpty } from "../../../utils/validation";

/**
 * This component allows the DIP Admin to create/edit/delete basic release
 * information, such as the release number and release date.
 */
const PlatformReleaseDetail = () => {

    const { abortSignalRef, isCancel } = useAbortController();

    const formRef = useRef(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const [pageAction, setPageAction] = useState(PLATFORM_NEWS_PAGE_ACTION.create); // action determined based on searchParams

    const [releaseId, setReleaseId] = useState(null);
    const [releaseNumber, setReleaseNumber] = useState(null);
    const [readOnly, setReadOnly] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const deleteDialogRef = useRef(null);
    const [isEditingRestricted, setIsEditingRestricted] = useState(false);
    const [loading, setLoading] = useState(false);

    const isCreating = useMemo(() => pageAction === PLATFORM_NEWS_PAGE_ACTION.create, [pageAction]);
    const isDeleting = useMemo(() => pageAction === PLATFORM_NEWS_PAGE_ACTION.delete, [pageAction]);
    const isEditing = useMemo(() => pageAction === PLATFORM_NEWS_PAGE_ACTION.update, [pageAction]);
    const isViewing = useMemo(() => pageAction === PLATFORM_NEWS_PAGE_ACTION.view, [pageAction]);

    const [input, setInput] = useState({
        release: '',
        timestamp: getFormattedNowDate(DATE_FORMAT)
    });

    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const localAction = searchParams.get('action');
        if (localAction) {
            setPageAction(localAction);
        }

        let localReleaseId = searchParams.get('releaseId')
        if (localReleaseId !== null) {
            setReleaseId(localReleaseId);
            initRelease(localReleaseId);
        }

        let hasBeenReleased = searchParams.get('released')
        if (!!hasBeenReleased) {
            setIsEditingRestricted(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])

    useEffect(() => {
        // Set the page title
        let title = '';
        switch (pageAction) {
            case PLATFORM_NEWS_PAGE_ACTION.create:
                title = '[Admin] Create Release';
                break;
            case PLATFORM_NEWS_PAGE_ACTION.update:
                title = `[Admin] Edit Release ${releaseNumber}`;
                break;
            case PLATFORM_NEWS_PAGE_ACTION.view:
                title = `[Admin] View Release ${releaseNumber}`;
                break;
            case PLATFORM_NEWS_PAGE_ACTION.delete:
                title = `[Admin] Delete Release ${releaseNumber}`;
                break;
            default:
                console.error(`Invalid page action: ${pageAction}`);
                break;
        };
        dispatch(setPageTitle(title));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [releaseNumber, pageAction])

    useEffect(() => {
        setReadOnly(false);

        // Set the read-only flag
        switch (pageAction) {
            case PLATFORM_NEWS_PAGE_ACTION.view:
                setReadOnly(true);
                break;
            case PLATFORM_NEWS_PAGE_ACTION.delete:
                setReadOnly(true);
                setShowDeleteDialog(true);
                break;
            default:
                break;
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageAction])

    const initRelease = (localReleaseId) => {
        setLoading(true);
        getPlatformRelease({ 
            id: localReleaseId, 
            abortSignal: abortSignalRef?.current 
        })
        .then(res => {
            if (res.data) {
                setInput({
                    release: res.data.release,
                    timestamp: getFormattedDateFromString(res.data.timestamp,
                                   DATE_FORMAT)
                });
                setReleaseNumber(res.data.release);
            }
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to get platform release:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get platform release',
                severity: 'error'
            }));
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    const canSubmit = () => {
        let rtn = false;

        let errors = validatePlatformRelease(input);
        setFormErrors(errors);

        rtn = formRef.current.reportValidity() && isErrorObjectEmpty(errors)

        return rtn;
    };

    const resetFormErrors = () => {
        setFormErrors({})
    }

    const navigateToPlatformNewsPage = () => {
        navigate(URL_ADMIN_PLATFORM_NEWS);
    };

    const handleCancelDeleteDialog = () => {
        setShowDeleteDialog(false);
        updateSearchParams({ action: PLATFORM_NEWS_PAGE_ACTION.view });
    };

    const handleDelete = () => {
        setShowDeleteDialog(false);
        setLoading(true);
        deletePlatformRelease({
            id: releaseId,
            abortSignal: abortSignalRef?.current
        })
        .then(() => {
            dispatch(setAlert({
                show: true,
                message: 'successfully deleted release',
                severity: 'success'
            }));
            setLoading(false);
            navigateToPlatformNewsPage();
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to delete the release:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'failed to delete the release',
                severity: 'error'
            }));

            setLoading(false);
        });
    };

    const handleSubmit = async () => {
        try {
            if (canSubmit()) {
                let createdReleaseId = null;
                let body = { ...input };

                setLoading(true);
                // trim whitespace from all text input fields
                Object.entries(body).forEach(([k, v]) => {
                    body[k] = trimWhitespace(v);
                });

                // Timestamp had to be mm/dd/yyyy for the data entry but
                // needs to have the time for the backend
                body['timestamp'] = getFormattedDateFromString(body.timestamp,
                    TIMESTAMP_FORMAT);

                switch (pageAction) {
                    case PLATFORM_NEWS_PAGE_ACTION.create:
                        let res = await createPlatformRelease({ 
                            body, 
                            abortSignal: abortSignalRef?.current
                        });
                        createdReleaseId = res?.data?.id;
                        dispatch(setAlert({
                            show: true,
                            message: 'successfully created a new release',
                            severity: 'success'
                        }));
                        break;
                    case PLATFORM_NEWS_PAGE_ACTION.update:
                        await updatePlatformRelease({ 
                            id: releaseId, 
                            body, 
                            abortSignal: abortSignalRef?.current 
                        });
                        dispatch(setAlert({
                            show: true,
                            message: 'successfully updated the release',
                            severity: 'success'
                        }));
                        break;
                    default:
                        console.error(`Invalid action type: ${pageAction}`)
                        break;
                }

                setLoading(false);
                resetFormErrors();
                if (isCreating) {
                    // When creating a new release, automatically open the
                    // page to enter a news item
                    navigate(getPlatformReleaseNewsItemPage([
                        `releaseId=${createdReleaseId}`, `first=${true}`,
                        `action=${PLATFORM_NEWS_PAGE_ACTION.create}`]));
                } else {
                    // Otherwise, go to the news-for-this-release page
                    navigateToPlatformNewsPage();
                }
            }
        } catch (error) {
            if (isCancel(error))
                return

            console.error(`failed to ${pageAction} a release:`, error.message);
            dispatch(setAlert({
                show: true,
                message: `failed to ${pageAction} a release: ${error.response.data.error}`,
                severity: 'error'
            }));

            setLoading(false);

        }
    }

    const updateSearchParams = (newParams) => {
        let ogParams = {}
        for (let [k, v] of searchParams.entries()) {
            ogParams = {
                ...ogParams,
                [k]: v
            }
        }
        setSearchParams({
            ...ogParams,
            ...newParams
        });
    };

    return (
        <Container id='admin-release-detail' disableGutters>
            <Card sx={{ p: '1rem' }}>
                <CardContent>
                    <form ref={formRef}>
                        <MajorHeading label='Release' loading={loading} />
                        <TextField
                            id='release'
                            name='release'
                            label='Release Number (format: x.x.x)'
                            value={input.release}
                            required
                            disabled={readOnly || isEditingRestricted}
                            onChange={(e) => handleInputChange(e)}
                            errorMessage={formErrors?.release}
                        />
                        <TextField
                            id='release-date'
                            name='timestamp'
                            label='Release Date (format: mm/dd/yy)'
                            type='date'
                            value={input.timestamp}
                            required
                            disabled={readOnly}
                            onChange={(e) => handleInputChange(e)}
                            errorMessage={formErrors?.timestamp}
                        />

                        <Grid container item xs={12} spacing={2}
                            sx={{ mt: 2 }}
                        >
                            <Grid item
                                xs={12}
                                sm={3}
                                sx={{
                                    textAlign: { xs: 'end', sm: 'left' }
                                }}
                            >
                                {
                                    !isCreating &&
                                    <ActionButton text='Delete' isDelete
                                        onClick={() => {
                                            updateSearchParams({
                                                action: PLATFORM_NEWS_PAGE_ACTION.delete
                                            })
                                        }}
                                        disabled={loading}
                                    />
                                }
                            </Grid>
                            <Grid item
                                xs={12}
                                sm={9}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'end'
                                }}
                            >
                                <LinkButton text='Cancel'
                                    href={URL_ADMIN_PLATFORM_NEWS}
                                    style={{ marginRight: '1rem' }}
                                    disabled={loading}
                                />
                                {
                                    isCreating &&
                                    <ActionButton text='Create'
                                        onClick={() => handleSubmit()}
                                        disabled={loading}
                                    />
                                }
                                {
                                    (isViewing || isDeleting) &&
                                    <ActionButton text='Edit'
                                        onClick={() => updateSearchParams({
                                            action: PLATFORM_NEWS_PAGE_ACTION.update
                                        })}
                                        disabled={loading}
                                    />
                                }
                                {
                                    isEditing &&
                                    <ActionButton text='Save'
                                        onClick={() => handleSubmit()}
                                        disabled={loading}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>

            {/* Dialog for the user to delete a release.  It must be
                created even if not shown for the sake of the ref */}
            <OkCancelDialog
                id='confirm-release-deletion-dialog'
                open={showDeleteDialog}
                heading='Confirm Release Deletion'
                okText={OK_DELETE_TEXT}
                cancelText={CANCEL_DELETE_TEXT}
                handleOkOp={handleDelete}
                handleCancelOp={handleCancelDeleteDialog}
                dialogRef={deleteDialogRef}
            >
                <BodyText labelStyle='text-center'>
                    Are you sure you want to delete release
                </BodyText>
                <BodyText labelStyle='text-center margin-top-1'>
                    <strong>{input.release}</strong>?
                </BodyText>
            </OkCancelDialog>
        </Container>
    )
};


// Breadcrumbs are only available for the Admin
const breadcrumbs = [
    { name: 'Home', href: URL_HOME },
    { name: 'Admin', href: URL_ADMIN },
    { name: 'Platform News', href: URL_ADMIN_PLATFORM_NEWS },
    { name: 'Platform Release', href: null }
];

export default PlatformReleaseDetail;
