import Typography from '@mui/material/Typography';

import { Link } from "react-router-dom";

/**
 * This provides a generic link component.  It is intended to be used on
 * links within the website.  Styles in this component and in StyledLink
 * should match.
 */

function DipLink({ text, href, sx, ...props }) {

    const linkSx = {
        ...sx,
        backgroundColor: '#fff',
        color: '#0050C9',
        '&:focus, &:hover': {
            color: '#5C0099'
        }
    };

    return (
        <Typography
            component={Link}
            to={href}
            sx={linkSx}
            {...props}
        >
            {text}
        </Typography>
    );
};

export default DipLink;
