import Box from "@mui/material/Box";

import BodyTitle from "../common/BodyTitle";

/**
 * This component is for the Request Access step in the request access process.
 * The user is presented with a disclaimer.
 */

const DIP_TERMS = [
    "The Digital Information Platform and services are used at your own risk.",
    "Services on DIP are not intended for operational use or safety purposes.",
    "DIP reserves the right to manage access to the platform and services.",
    "Services and data on DIP may be subject to access restrictions.",
    "Service consumers do not own data accessed through DIP.",
    "DIP provides no warranty of performance or quality of services and data.",
    "Services on DIP may be suspended or removed without notice.",
    "DIP is not responsible for any damages caused by services.",
    "DIP assumes that it is the service consumer's responsibility to stop using services."
];

const DisclaimerStep = () => (
    <div>
        <BodyTitle
            label='Please take note of the following:'
        />
        <Box
            sx={{
                border: '1px solid black',
                padding: '1rem'
            }}
        >
            <ol className='usa-list margin-top-0' >
                {
                    DIP_TERMS.map((term, idx) => (
                        <li key={idx} className='usa-list-item font-serif-md' >
                            {term}
                        </li>
                    ))
                }
            </ol>

        </Box>
    </div>
);

export default DisclaimerStep;
