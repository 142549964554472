
/**
 * This provides the menu button to open the mobile menu.
 *
 * @param {string}     id         unique identifier for this button
 * @param {function}   onClick    click handler to open the menu when the
 *                                user clicks the button
 */
const NavMenuButton = ({ id, onClick }) => {

    return (
        <button
            id={id}
            onClick={onClick}
            className="usa-menu-btn mobile-menu-button"
            type="button"
        />
    )
}

export default NavMenuButton;
