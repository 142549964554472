import classnames from 'classnames';
import CircularProgressIndicator from './CircularProgressIndicator';


/**
 * This creates a major heading.  A circular progress icon and/or some other
 * icon can optionally be displayed next to the label.
 *
 * @param {string}  label               the label displayed to the user
 * @param {string}  labelStyle          additional USWDS style classes to add
 *                                      to the label, e.g. 'margin-top-0' to
 *                                      remove a margin
 * @param {boolean} loading             true to display a circular progress
 *                                      icon next to the header
 * @param {boolean} adjoiningComponent  optional component to display next
 *                                      to the header, such as a help icon
 * @param {object}  props               any additional properties are applied
 *                                      to the heading element
 */

const MajorHeading = ({
    label,
    labelStyle,
    loading,
    adjoiningComponent,
    ...props
}) => {

    const classes = classnames('text-primary', labelStyle);

    return (
        <h2 className={classes} {...props}>
            {label}
            {
                loading &&
                <CircularProgressIndicator sx={{ ml: '1rem'}} />
            }
            {
                !!adjoiningComponent && adjoiningComponent
            }
        </h2>
    );
};

export default MajorHeading;
