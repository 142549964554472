import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import validator from 'validator';
import { deleteDipUser, getCompanies, getUser, updateUser } from '../../apiClient';
import { setAlert } from '../../app/slices/alert';
import { setLinks as setBreadcrumbs } from '../../app/slices/breadcrumbs';
import { setPageTitle } from '../../app/slices/page';
import ActionButton from '../../components/common/ActionButton';
import BodyText from '../../components/common/BodyText';
import CompanySelect from '../../components/common/CompanySelect';
import LinkButton from '../../components/common/LinkButton';
import OkCancelDialog from '../../components/common/OkCancelDialog';
import ProcessingOverlay from "../../components/common/ProcessingOverlay";
import TextField from '../../components/common/TextField';
import { useAbortController } from '../../hooks';
import { URL_ADMIN, URL_ADMIN_USERS, URL_HOME } from '../../utils/navigation';
import { CANCEL_DELETE_TEXT, OK_DELETE_TEXT } from '../../utils/utils';
import { isErrorObjectEmpty, isInvalidTextField } from '../../utils/validation';


/**
 * This provides the DIP Administrator the ability to view or edit user data.
 */

const UserDetail = () => {
    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { userId } = useParams();
    const formRef = useRef(null);

    const [readonlyInputFields, setReadonlyInputFields] = useState(true);
    const [action, setAction] = useState('view');
    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('');
    const [input, setInput] = useState({
        cognito_username: '',
        company_id: null,
        company_name: '',
        email: '',
        id: null,
        name: '',
        registration_time: null
    });
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const deleteDialogRef = useRef(null);

    const breadcrumbs = [
        { name: 'Home', href: URL_HOME },
        { name: 'Admin', href: URL_ADMIN },
        { name: 'Users', href: URL_ADMIN_USERS },
        { name: 'User', href: null }
    ];

    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));
        dispatch(setPageTitle('User Detail Administration'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        initPageTitle();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, input.name]);

    useEffect(() => {
        setFormErrors({})
        switch (action) {
            case 'view':
                initUserData();
                setReadonlyInputFields(true);
                break;
            case 'edit':
                setReadonlyInputFields(false);
                break;
            default:
                break;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action]);

    const initCompanies = async () => {
        let companies = null;
        try {
            let res = await getCompanies({ abortSignal: abortSignalRef?.current });
            companies = res.data?.companies ?? [];
            setCompanies(companies.map(c => ({ id: c.id, name: c.name })));
        } catch (err) {
            if (isCancel(err))
                return;

            console.error('failed to get companies:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get companies',
                severity: 'error'
            }));
        }
        return companies;
    };

    const initUserData = () => {
        setLoading(true);
        setLoadingMsg('Loading user...');
        getUser({ userId, abortSignal: abortSignalRef?.current })
        .then(async (res) => {
            setInput(res.data);
            let companyList = companies;
            if (companies.length === 0) {
                companyList = await initCompanies();
            };
            setSelectedCompany(companyList.find(c => c.id === res.data.company_id));
            setLoading(false);
            setLoadingMsg('');
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to get users:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get users',
                severity: 'error'
            }));

            setLoading(false);
            setLoadingMsg('');
        })
    };

    const initPageTitle = () => {
        let title = '';
        switch (action) {
            case 'view':
                title = `[Admin] View User: ${input.name}`;
                break;
            case 'edit':
                title = `[Admin] Edit User: ${input.name}`;
                break;
            default:
                console.error("Unhandled user detail action:", action);
                break;
        }
        dispatch(setPageTitle(title));
    };

    const handleInputChange = (fieldName, value) => {
        const updatedInput = {
            ...input,
            [fieldName]: value
        };
        setInput(updatedInput);
    };

    const handleCompanySelect = (newSelectedCompany) => {
        setSelectedCompany(newSelectedCompany);
    };

    const validateUser = (input) => {
        let errors = {}

        errors.name = isInvalidTextField(input?.name, 1, 100, " .-'")

        if (!validator.isEmail(input?.email)) {
            errors.email = 'Invalid email format'
        }

        return errors
    }

    const handleSave = () => {
        let errors = validateUser(input)
        setFormErrors(errors)

        if (formRef.current.reportValidity() && isErrorObjectEmpty(errors)) {
            const cleanup = () => {
                setFormErrors({})
                setLoading(false);
                setLoadingMsg('');
                setAction('view')
            };

            setLoading(true);
            setLoadingMsg('Saving user...');
            let body = {
                name: input.name,
                email: input.email,
                cognito_username: input.cognito_username,
                company_id: selectedCompany.id
            }
            updateUser({ userId, body, abortSignal: abortSignalRef?.current })
            .then(() => {
                dispatch(setAlert({
                    show: true,
                    message: 'successfully updated the user',
                    severity: 'success'
                }));

                cleanup();
                navigate(URL_ADMIN_USERS);
            })
            .catch(err => {
                if (isCancel(err))
                    return;

                console.error('failed to update the user:', err.message);
                dispatch(setAlert({
                    show: true,
                    message: 'Failed to update the user',
                    severity: 'error'
                }));

                cleanup();
            })
        }
    };

    const handleDeleteUser = () => {
        setOpenDeleteConfirmation(false);
        setLoading(true);
        setLoadingMsg('Deleting user...');
        deleteDipUser({ userId, abortSignal: abortSignalRef?.current })
        .then(() => {
            setLoading(false);
            setLoadingMsg('');
            dispatch(setAlert({
                show: true,
                message: 'successfully deleted the user',
                severity: 'success'
            }));
            navigate(URL_ADMIN_USERS);
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to delete user:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to delete user',
                severity: 'error'
            }));

            setLoading(false);
            setLoadingMsg('');
        })
    };

    return (
        <Fragment>

            {/* Shows the circular progress icon when loading the data */}
            <ProcessingOverlay open={loading} msg={loadingMsg} />

            <Card id='admin-user-detail' sx={{ p: '1rem' }}>
                <CardContent>

                    <form ref={formRef}>
                        <Grid container rowSpacing={2} >
                            <Grid item xs={12} >
                                <TextField
                                    id='name'
                                    name='name'
                                    label='Name'
                                    labelStyle='margin-top-0'
                                    value={input.name}
                                    onChange={(e) =>
                                        handleInputChange('name',
                                            e.target.value)}
                                    required
                                    disabled={readonlyInputFields}
                                    errorMessage={formErrors?.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='username'
                                    name='username'
                                    label='Username'
                                    labelStyle='margin-top-0'
                                    value={input.cognito_username}
                                    onChange={(e) =>
                                        console.error('should never get here')}
                                    required
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='email'
                                    name='email'
                                    label='Email'
                                    labelStyle='margin-top-0'
                                    value={input.email}
                                    onChange={(e) =>
                                        handleInputChange('email',
                                            e.target.value)}
                                    required
                                    disabled={readonlyInputFields}
                                    errorMessage={formErrors?.email}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: '1rem' }} >
                                <CompanySelect
                                    companyOptions={companies}
                                    handleCompanySelect={handleCompanySelect}
                                    selectedCompany={selectedCompany}
                                    disabled={readonlyInputFields}
                                />
                            </Grid>

                            <Grid container item xs={12}
                                sx={{ marginTop: '1.5rem' }}
                            >
                                <Grid item
                                    xs={12}
                                    sm={3}
                                    sx={{
                                        textAlign: { xs: 'end', sm: 'left' }
                                    }}
                                >
                                    <ActionButton text='Delete' isDelete
                                        onClick={() =>
                                            setOpenDeleteConfirmation(true)}
                                        data-open-modal
                                        aria-controls={deleteDialogRef?.current?.modalId}
                                    />
                                </Grid>
                                <Grid item
                                    xs={12}
                                    sm={9}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'end'
                                    }}
                                >
                                    <LinkButton text='Cancel'
                                        href={URL_ADMIN_USERS}
                                        style={{ marginLeft: '1rem' }}
                                        data-close-modal
                                    />
                                    {
                                        action === 'view' &&
                                        <ActionButton text='Edit'
                                            onClick={() => setAction('edit')}
                                            buttonStyle='margin-left-2'
                                        />
                                    }
                                    {
                                        action === 'edit' &&
                                        <ActionButton text='Save'
                                            onClick={handleSave}
                                            buttonStyle='margin-left-2'
                                            data-close-modal
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>

            {/* Dialog to delete a user.  It must be created even if not
                shown for the sake of the ref */}
            <OkCancelDialog
                id='confirm-user-deletion-dialog'
                open={openDeleteConfirmation}
                heading='Confirm User Deletion'
                okText={OK_DELETE_TEXT}
                cancelText={CANCEL_DELETE_TEXT}
                handleOkOp={handleDeleteUser}
                handleCancelOp={() => setOpenDeleteConfirmation(false)}
                dialogRef={deleteDialogRef}
            >
                {
                    openDeleteConfirmation &&
                    <>
                        <BodyText labelStyle='text-center'>
                            Are you sure you want to delete user
                        </BodyText>
                        <BodyText labelStyle='text-center margin-top-1'>
                            <strong>{input.name}</strong>?
                        </BodyText>
                    </>
                }
            </OkCancelDialog>
        </Fragment>
    )
};

export default UserDetail;
