import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // a list of the link objects where link object has 'name" and 'href' as keys.
  links: []
}

export const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setLinks: (state, action) => {
      state.links = action.payload;
    },

    resetLinks: (state) => {
      state.links = []
    }
  },
})

// Action creators are generated for each case reducer function
export const { setLinks, resetLinks } = breadcrumbsSlice.actions

export const selectBreadcrumbs = state => state.breadcrumbs.links;

export default breadcrumbsSlice.reducer;