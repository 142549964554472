import React, { forwardRef } from 'react';
import classnames from 'classnames';

import ModalCloseButton from './ModalCloseButton';


/**
 * This component provides the main content of a modal window.  When forceAction
 * is false, a close button will be added to the top right corner of the window.
 * The forceAction should be set to true whenever the modal is providing the
 * user with the ability to take an action like editing, saving, or deleting -
 * basically, any time content is presented for which the user can do anything
 * besides just look at it.
 *
 * @param {string}   modalId        Id of the parent modal
 * @param {string}   modalHeadingId Id of the heading that labels this modal
 * @param {JSX}      children       the main content and any footer
 * @param {function} handleClose    function to call to close the modal; used
 *                                  when forceAction is false
 * @param {boolean}  isLarge        true for a large modal window;
 *                                  default=false.
 * @param {boolean}  forceAction    true if the user is required to use one of
 *                                  the modal's buttons to close it;
 *                                  default=false.
 * @param {object}   divProps       any additional properties for the modal
 * @param {object}   ref            ref attribute passed by the parent component
 */
// This is wrapped in a FocusTrap which requires its child element to be a
// functional component that uses the React.forwardRef() API.
const ModalWindow = forwardRef((props, ref) => {

    const {
        modalId,
        modalHeadingId,
        children,
        handleClose,
        isLarge=false,
        forceAction=false,
        ...divProps
    } = props;

    const classes = classnames(
        'usa-modal',
        {
            'usa-modal--lg': isLarge,
        },
    )

    return (
        <div
            {...divProps}
            className={classes}
            aria-labelledby={modalHeadingId}
            ref={ref}
            data-force-action={forceAction}
       >
            <div className="usa-modal__content" >
                <div className="usa-modal__main" >
                    {children}
                </div>
                {/* CSS will display the "X" close button at the top right of
                    the modal window, but placing the close button at the
                    bottom of the modal will prevent some screen readers from
                    reading the close button first and allow users to navigate
                    directly to the main content instead. */}
                {
                    !forceAction &&
                    <ModalCloseButton
                        aria-controls={modalId}
                        handleClose={handleClose}
                    />
                }
            </div>
        </div>
    );
});

export default ModalWindow;
