import Check from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAlert } from '../../app/slices/alert';
import { selectSortOption } from '../../app/slices/browse';


/**
 * This provides a sort menu.  Available sort options are currently
 * Service Name and Provider Name.
 *
 * @param {string}   idPrefix      prefix to give 'id' attributes on the menu
 * @param {object}   sortSelector  state selector used to get the current value
 *                                 of the sort option
 * @param {function} setSortBy     function to call to set the new sort option
 */

function SortMenu({ idPrefix, sortSelector, setSortBy }) {
    const dispatch = useDispatch();

    // The available sort options
    const sortOption = useSelector(selectSortOption);

    // What the currently-selected sort option is
    const sortBy = useSelector(sortSelector);

    // Sort icon border
    const border = `2px solid ${useTheme().palette.primary.main}`;

    // For the sort menu
    const [anchorEl, setAnchorEl] = useState(null);
    const sortOpen = Boolean(anchorEl);

    // If the user clicks the sort button, open the menu
    const handleSortButtonClick = (event) => {
        setAnchorEl(event.target);
    };

    // Close the menu
    const handleSortMenuClose = () => {
        setAnchorEl(null);
    };

    // Sets the new sort option in the store
    const handleSetSort = async (byWhat) => {
        try {
            dispatch(setSortBy(byWhat))
        } catch (error) {
            console.error("failed to set sort:", error);
            dispatch(setAlert({
                show: true,
                message: 'Failed to set sort',
                severity: 'error'
            }));
        };
    };

    // Creates a menu item.  The currently selected item will have a checkmark
    // next to it.
    const TabbableMenuItem = ({ sortKey, label, ...props}) => (
        <MenuItem
            tabIndex={0}
            id={`${idPrefix}-${sortKey}-menu`}
            onClick={() => {
                handleSortMenuClose();
                handleSetSort(sortKey);
            }}
            { ...props }
        >
            { (sortKey === sortBy) ?
                <>
                    <ListItemIcon>
                        <Check />
                    </ListItemIcon>
                    {label}
                </>
              :
                <>
                    <ListItemText inset>
                        {label}
                    </ListItemText>
                </>
            }
        </MenuItem>
    );

    return (
      <Grid item xs="auto"
          sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end'
          }}
      >
          <Button
              onClick={handleSortButtonClick}
              id={`${idPrefix}-sort-menu-button`}
              aria-controls={sortOpen ? 'sort-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={sortOpen ? 'true' : undefined}
              disableRipple
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                  color: 'primary',
                  fontSize: '1rem',
                  textTransform: 'none',
                  alignItems: 'center',
                  '&:focus-visible, &:hover': {
                      outline: border,
                  },
                  display: 'flex'
              }}
          >
              Sort
          </Button>

          <Menu
              id={`${idPrefix}-sort-menu`}
              anchorEl={anchorEl}
              open={sortOpen}
              onClose={handleSortMenuClose}
              autoFocus={false}
              sx={{
                  mt: '.5rem'
              }}
          >
              <TabbableMenuItem
                  sortKey={sortOption.serviceName}
                  label='Service Name'
              />

              <TabbableMenuItem
                  sortKey={sortOption.providerName}
                  label='Provider Name'
              />
          </Menu>
      </Grid>
    );
};

export default SortMenu;
