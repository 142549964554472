import { Link } from 'react-router-dom';
import dipLogo from '../../assets/DIP_logo_and_text.png';


/**
 * This holds the logo and "DIP" title on the left hand side of the main header.
 */
const NavLogo = ( ) => {

    return (
        <div id='logo' className="site-logo" >
            <Link to='/'
               title="Digital Information Platform (DIP) Home"
            >
                <img src={dipLogo} alt='DIP logo' />
            </Link>
        </div>
    );
}

export default NavLogo;
