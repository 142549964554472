import SelectObject from '../common/SelectObject';
import { TIME_RANGE_FILTER_OPTIONS } from '../../utils/serviceMetrics';

/**
 * This provides the controls for a user to select a time range for a 
 * service metrics graph.
 *
 * @param {function} handleTimeRangeSelect  selection handler; note this
 *                                          should handle just the
 *                                          selected time range, NOT the
 *                                          selection event
 * @param {object}   selectedTimeRange      time range to select
 * @param {boolean}  disabled               true to disable the control
 */
const GraphTimeRangeSelect = ({
    handleTimeRangeSelect,
    selectedTimeRange,
    disabled=false
}) => {
    return (
        <SelectObject
            id='common-graph-time-range-select'
            name='time-range-select'
            label='Time Range'
            labelStyle='margin-top-0'
            value={selectedTimeRange}
            itemList={TIME_RANGE_FILTER_OPTIONS}
            valueAttribute='name'
            handleSelect={handleTimeRangeSelect}
            disabled={disabled}
        />
    )
};

export default GraphTimeRangeSelect;
