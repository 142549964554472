import Grid from "@mui/material/Grid";
import BodyText from "../common/BodyText";
import SectionHeader from "../common/SectionHeader";
import TextField from "../common/TextField";
import { formatPhoneNumber } from "../../utils/utils";


/**
 * This component is for the Team Information step in the request access 
 * process.  The Team Information step has two branches.  One branch is when 
 * the user's email domain matches the domain of a previously entered team, and
 * so the user will be prompted if they belong to that team.  The other branch 
 * is when the user's email domain does NOT match a domain of a previously 
 * entered team, and so the user will be prompted to enter new team information.
 * This component handles that second case of entering new team information.
 *
 * @param {object}   input                team information entered so far
 * @param {function} handleInputChange    function to call when the user enters
 *                                        a value
 * @param {boolean}  formErrors           results of input validation
 *
 * @returns JSX for entering new team information
 */
const TeamInformationStepTeamEntry = ({
    input,
    handleInputChange,
    formErrors
}) => {

    const teamInfoTextField = (label, fieldName, handler=handleInputChange) => (
        <TextField
            id={`team-info-${fieldName}`}
            name={fieldName}
            label={label}
            labelStyle='margin-top-0'
            value={input[fieldName]}
            onChange={handler}
            errorMessage={formErrors?.[fieldName]}
            required
            style={{marginBottom: '1.5rem'}}
        />
    );

    return (
        <Grid container id='team-info-team-entry-user-input'>
            <Grid item xs={12}>
                <BodyText
                    label='DIP administers user profiles based on team membership within an organization.'
                    labelStyle='text-center margin-top-1'
                />
                <BodyText
                    label='Please indicate the organization and team that you are affiliated with and their point of contact for the use of the Digital Information Platform and its catalog of services.'
                    labelStyle='text-center margin-top-1'
                />
                <BodyText
                    label="Note that DIP administrators may use this information to verify your organization’s interest in accessing the Digital Information Platform."
                    labelStyle='text-center margin-top-1'
                />
            </Grid>
            <Grid
                container
                item
                xs={12}
                spacing={3}
                sx={{
                    mt: '1rem'
                }}
            >
                <Grid item xs={12} md={4}>
                    <SectionHeader title='Your Organization' />
                    {
                        teamInfoTextField('Organization', 'organization')
                    }
                    {
                        teamInfoTextField('Organization Address', 'organization_address')
                    }
                    {
                        teamInfoTextField('Organization Country', 'organization_country')
                    }
                </Grid>
                <Grid item xs={12} md={4}>
                    <SectionHeader title='Your Team' />
                    {
                        teamInfoTextField('Team Name', 'team_name')
                    }
                    {
                        teamInfoTextField('Team URL', 'team_url')
                    }
                    {
                        teamInfoTextField('Team Logo URL', 'team_logo_url')
                    }
                    <BodyText
                        labelStyle='text-center'
                        label="If your team does not have a URL or a logo URL, you may use your organization's instead."
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <SectionHeader title='Your Team POC' />
                    {
                        teamInfoTextField('Team POC Name', 'team_poc_name')
                    }
                    {
                        teamInfoTextField('Team POC email', 'team_poc_email')
                    }
                    {
                        teamInfoTextField(
                            'Team POC Phone Number',
                            'team_poc_phone',
                            (e) => {
                                e.target.value = formatPhoneNumber(e.target.value);
                                handleInputChange(e);
                            }
                        )
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TeamInformationStepTeamEntry;
