import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import WarningIcon from "@mui/icons-material/Warning";

import defaultCompanyImg from '../../assets/icon/Company.png';
import BodyText from '../common/BodyText';
import BodyTitle from '../common/BodyTitle';
import CircularProgressIndicator from '../common/CircularProgressIndicator';
import { getFileByType } from '../../utils/serviceDetail';
import { VIRUS_SCAN_STATUS } from '../../utils/serviceRegistration';
import NewVersionIndicator from '../subscription/NewVersionIndicator';
import { ServiceTypeUtils } from "../../utils/utils";

/**
 * This provides the service identifying information across the top of the
 * Service Detail page: service name, provider name, subscription status,
 * service info
 */
const ServiceHeader = ({ service, provider, isSubscribed, isSubscribedToCurrentVersion, loadingService }) => {

    // If the service isn't ready to display yet, bail.
    if (loadingService || !service || !provider) {
        return (
            <Card>
                <CircularProgressIndicator 
                    type='large'
                    altText='Loading service data...'
                />
            </Card>
        );
    }

    // Set the service type and number of subscribers that will go in the
    // subscription details section
    const serviceType =
        `${ServiceTypeUtils.TYPE_TO_STRING[service.service_type]} Service`;
    const numSubscribers =
        `${!!service.subscription_count ? service.subscription_count : 0} Subscriber${service.subscription_count !== 1 ? 's' : ''}`;

    // Common style applied to each cell which helps display everything
    // correctly
    const styleEachCell = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        padding: '.5rem'
    };

    const shouldShowServiceLogoDataUrl = getFileByType(service?.files, 
        'service_logo')?.virus_scan_status === VIRUS_SCAN_STATUS.clean;

    return (
        <Grid container
            sx={{
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                padding: '1rem'
            }}
        >

            {/* First row: service name, provider name, subscription status */}
            <Grid item xs={4} sx={styleEachCell} >
                <ServiceInfo text={service.name} labelledBy='service-name' />
            </Grid>
            <Grid item xs={4} sx={styleEachCell} >
                <ServiceInfo text={provider.name} labelledBy='provider-name' />
            </Grid>
            <Grid item xs={4} sx={styleEachCell} >
                <SubscriptionStatus
                    isSubscribed={isSubscribed}
                    isSubscribedToCurrentVersion={isSubscribedToCurrentVersion}
                />
            </Grid>

            {/* Second row: service logo, provider logo, details */}
            <Grid item xs={4} sx={styleEachCell} >
                {/* Service logo, if defined; if not defined,  create an
                  * acronym out of the service name and display that instead
                  */}
                {
                    shouldShowServiceLogoDataUrl ?
                        <ImageBox
                            id='service-logo'
                            alt={`${service.name}`}
                            src={service.service_logo_data_url}
                        />
                    :
                        <label className='usa-label font-ui-3xl text-primary text-center ' >
                            {getAcronym(service.name)}
                        </label>
                }
            </Grid>

            {/* Second row: Provider logo */}
            <Grid item xs={4} sx={styleEachCell} >
                <ImageBox
                    id='company-logo'
                    alt={`${provider.name}`}
                    src={provider.logo_url || defaultCompanyImg}
                />
            </Grid>

            {/* Second row: service info */}
            <Grid container item xs={4} sx={styleEachCell} >
                <Grid item xs={12}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Detail text={serviceType} />
                </Grid>
                <Grid item xs={12}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Detail text={numSubscribers} />
                </Grid>

            </Grid>

            {/* Third row: labels */}
            <Grid item xs={4} sx={styleEachCell} >
                <ServiceLabel text='Service Name' id='service-name' />
            </Grid>
            <Grid item xs={4} sx={styleEachCell} >
                <ServiceLabel text='Service Provider' id='provider-name' />
            </Grid>
            <Grid item xs={4} sx={styleEachCell} >
                <ServiceLabel text='Service Details' id='service-details' />
            </Grid>
        </Grid>
    );
};

/**
 * Displays service information, either the service or provider name.
 *
 * @param {string} text         the service or provider name
 * @param {string} labelledBy   unique identifier of the ServiceLabel which
 *                              labels this element
 *
 */
const ServiceInfo = ({ text, labelledBy }) => (
    <BodyTitle
        label={text}
        labelStyle='text-center text-primary margin-top-0 '
        aria-labelledby={labelledBy}
    />
);

/**
 * Displays a label for the service information.
 *
 * @param {string} text   the label
 * @param {string} id     the unique identifier, so that a ServiceInfo can
 *                        reference it with aria-labelledby
 */
const ServiceLabel = ({ text, id }) => (
    <BodyText
        label={text}
        labelStyle='text-center margin-0 '
        id={id}
    />
);

/**
 * Displays one line of text in the subscription details block.
 *
 * @param {string} text   the detail text
 */
const Detail = ({ text }) => (
    <BodyText
        label={text}
        labelStyle='text-primary margin-top-0'
    />
);

/**
 * Creates the colored block to show the subscription status.
 *
 * @param {boolean} isSubscribed   true of subscribed
 */
const SubscriptionStatus = ({ isSubscribed, isSubscribedToCurrentVersion }) => {
    let labelComponent = null;
    if (isSubscribed) {
        if (isSubscribedToCurrentVersion) {
            labelComponent = 'SUBSCRIBED'
        } else {
            labelComponent = (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <WarningIcon
                        color='subscribed.warn'
                        fontSize='inherit'
                        sx={{ mr: '0.25rem' }}
                    />
                    SUBSCRIBED
                </Box>
            )
        }
    } else {
        labelComponent = 'NOT SUBSCRIBED';
    }

    // We have to set the subscription status color manually because USWDS
    // doesn't provide a color option that our Human Factors team wants
    let labelStyle = { maxWidth: 'max-content' };
    if (isSubscribed) {
        if (isSubscribedToCurrentVersion) {
            labelStyle.backgroundColor = '#276344';
        } else {
            labelStyle.backgroundColor = '#947100';
        }
    } else {
        labelStyle.backgroundColor = '#005A9E';
    }

    let rtn = (
        <Box
            sx={{
                textAlign: "center",
            }}
        >
            <BodyTitle
                label={labelComponent}
                labelStyle="text-center text-white margin-top-0 padding-1 "
                style={labelStyle}
            />
        </Box>
    );

    if (isSubscribed && !isSubscribedToCurrentVersion) {
        rtn = (
            <NewVersionIndicator
                hideWarningIcon={true}
                bottomPartSxProps={{
                    fontSize: "0.9rem",
                    textAlign: "center"
                }}
            >
                {rtn}
            </NewVersionIndicator>
        )
    }

    return rtn;
};

/**
 * Creates a box to hold a logo
 *
 * @param {object} props       image properties
 * @param {string} props.id    unique id
 * @param {string} props.alt   label for accessibility
 * @param {string} props.src   image location
 */
const ImageBox = (props) => (
    <Box
        component='img'
        sx={{
          width: '100%',
          height: '8rem',
          borderRadius: '7px',
          objectFit: 'contain'
        }}
        {...props}
    />
);

/**
 * Creates an acronym from a service name.
 */
const getAcronym = (name) => {
    const words = name?.split(' ') ?? [];
    let acronym = '';
    words.forEach( word => acronym = `${acronym}${word[0]}`);

    return acronym.toUpperCase();
};

export default ServiceHeader;
