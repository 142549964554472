import classnames from 'classnames';

/**
 * This creates text intended for the body of the web page.
 *
 * @param {string}  label          the label displayed to the user
 * @param {string}  labelStyle     additional USWDS style classes to add to the
 *                                 label, e.g. 'margin-top-0' to remove a margin
 * @param {object}  props          additional properties applied to the
 *                                 containing 'div'
 * @param {object}  props.children optional content to display next to the label
 */

const BodyText = ({ label, labelStyle, ...props }) => {

    const classes = classnames('usa-label', labelStyle);

    return (
        <div className={classes} {...props} >
            {label}{props.children}
        </div>
    );
};

export default BodyText;
