import closeImg from '@uswds/uswds/src/img/usa-icons/close.svg'


/**
 * This provides the close button for the mobile menu.
 *
 * @param {string}     id         unique identifier for this button
 * @param {function}   onClick    click handler to close the menu when the
 *                                user clicks the button
 */
const NavCloseButton = ({ id, onClick }) => {

    return (
        <button
            id={id}
            className='usa-nav__close'
            onClick={onClick}
            type="button"
        >
            <img src={closeImg} alt="close" />
        </button>
    )
}

export default NavCloseButton;
