import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";


import {
    selectServicesSortBy, selectSortOption, setServicesSortBy
} from "../../app/slices/browse";
import CircularProgressIndicator from '../common/CircularProgressIndicator';
import NoMatchLabel from "../common/NoMatchLabel";
import FilterChips from "./../search/FilterChips";
import LargeServiceCard from "./LargeServiceCard";
import SectionHeader from "./SectionHeader";

/**
 * This provides the display of large service cards for a set of services.
 * The services can either be all services or the results of a search.
 * If the services are the result of a search, filter chips will also be
 * displayed.  It is expected that this will be shown on the Home page (all
 * services) or the Catalog page (search results).
 *
 * @param {boolean} fetchingSubscriptions     true if the fetch of subscriptions
 *                                            is still in progress
 * @param {arrayOf{object}} subscriptions     services the user is subscribed to
 * @param {boolean} fetchingAllServices       true if the fetch of all services
 *                                            is still in progress
 * @param {arrayOf{object}} services          services to display
 * @param {boolean} isAllServices             true if "services" reflects all
 *                                            services; false if "services" are
 *                                            the result of a search
 * @param {function} handleSearchFilterChange when displaying filter results
 *                                            and filter chips are displayed,
 *                                            this is the action handler when
 *                                            the user clicks on the filter chip
 * @param {function} handleClearSearchText    when displaying filter results
 *                                            and filter chips are displayed,
 *                                            this is the action handler when
 *                                            the user clicks on the chip
 *                                            representing the search bar
 */

function ServicesCardDisplay({
    fetchingSubscriptions,
    subscriptions,
    fetchingAllServices,
    services,
    isAllServices,
    handleSearchFilterChange,
    handleClearSearchText
}) {

    // Save the services
    const [ serviceList, setServiceList ] = useState([]);
    
    // This mapping stores the boolean value indicating whether or not a user is subscribed to
    // the latest launched version for each service
    const ltsVersionSubscriptionMap = useMemo(() => {
        let keys = new Map();
        subscriptions?.forEach(({provider_id, service_id, version_id, subscribedLatest}) => {
            const key = getUniqueServiceKey({
                provider_id: provider_id,
                service_id: service_id,
            });

            keys.set(key, subscribedLatest);

        });
        return keys;
    }, [subscriptions]);


    // For sorting the services
    const sortOption = useSelector(selectSortOption);
    const sortBy = useSelector(selectServicesSortBy);
    
    // Process the displayed services
    useEffect(() => {
        // Create a key that matches the key of the subscribed services above.
        let copyServices = services ?? [];
        if (services) {
            copyServices = services.map(service => ({
                ...service,
                key: getUniqueServiceKey({
                    provider_id: service.provider_id,
                    service_id: service.id,
                }),
            }));

            // Sort the displayed services
            copyServices.sort((a, b) => {
                const nameA = getSortByField(a);
                const nameB = getSortByField(b);

                if (nameA < nameB) {
                    return -1;
                }
                else if (nameA > nameB) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
        }
        setServiceList(copyServices);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [services, sortBy]);

    // Given a service, this returns the field that should be used for sorting
    const getSortByField = (service) => {
        if (sortBy === sortOption.serviceName) {
            return service.name.toUpperCase();
        }
        else if (sortBy === sortOption.providerName) {
            return service.provider_name.toUpperCase();
        }

        return service.name;
    };

    // These flags are to simplify the logic of whether to display the
    // "fetching" icon, the service cards, or the "No match" message.
    const showFetch = fetchingAllServices;
    const showServices = !fetchingAllServices && (serviceList.length > 0);
    const showNoMatch = !fetchingAllServices && !serviceList.length &&
                        !isAllServices;

    const title = isAllServices ?
                      "All Services" :
                      `Showing ${serviceList.length} results`;
    return (
        <Grid container spacing={2}>
            {/* Services title bar */}
            <SectionHeader
                title={title}
                idPrefix="services"
                sortMenuSelector={selectServicesSortBy}
                sortMenuSetSortBy={setServicesSortBy}
            />

            {/* Filter chips */}
            { !isAllServices &&
              <Grid item xs={12}
                    sx={{
                      display: 'inline-flex',
                      flexWrap: 'wrap',
                    }}
                >
                    <FilterChips
                        handleSearchFilterChange={handleSearchFilterChange}
                        handleClearSearchText={handleClearSearchText}
                    />
                </Grid>
            }

            {/* "Fetch" icon / Service cards / "No match" message */}
            <Grid
                item
                xs={12}
                id="large-service-card-display"
                sx={{
                    display: "inline-flex",
                    flexWrap: "wrap",
                    marginBottom: "8px",
                }}
            >
                { showFetch &&
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgressIndicator 
                            type='large' 
                            altText='Loading services...' 
                            id='loading-services' 
                        />
                    </Box>
                }

                {showServices &&
                    serviceList.map((service, idx) => (
                        <LargeServiceCard
                            service={service}
                            serviceLogoDataUrl={service.service_logo_data_url}
                            key={`service-${service.key}`}
                            isSubscribed={ltsVersionSubscriptionMap.has(service.key)}
                            isSubscribedToLatest={
                                !!ltsVersionSubscriptionMap.get(service.key) 
                            }
                            fetchingSubscriptions={fetchingSubscriptions}
                        />
                    ))}

                {showNoMatch && <NoMatchLabel label="No services were found" />}
            </Grid>
        </Grid>
    );
};

const getUniqueServiceKey = ({ provider_id, service_id }) => {
    return `${provider_id}_${service_id}`;
};

export default ServicesCardDisplay;
