import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getCompanyDetail, getMyAccount } from '../apiClient';
import { setAlert } from '../app/slices/alert';
import { setPageTitle } from '../app/slices/page';
import DipIconButton from '../components/common/DipIconButton';
import FieldName from '../components/common/FieldName';
import FieldValue from '../components/common/FieldValue';
import LabelValueTable from '../components/common/LabelValueTable';
import MajorHeading from '../components/common/MajorHeading';
import { useAbortController } from '../hooks';
import { getCurrentAuthenticatedUser } from '../utils/auth';
import { copyToClipboard, hideSecret } from '../utils/utils';

/**
 * This provides the account details and company credentials when the
 * user clicks the "Profile" menu item from the page header.
 */

export default function Profile() {

    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();

    const [loadingAccount, setLoadingAccount] = useState(true);

    const [accountDetails, setAccountDetails] = useState({});

    const [hideIvt, setHideIvt] = useState(true);
    const [hideClientSecret, setHideClientSecret] = useState(true);
    const [hideApiKey, setHideApiKey] = useState(true);

    useEffect(() => {
        dispatch(setPageTitle('Profile'));

        setLoadingAccount(true);
        getCurrentAuthenticatedUser()
            .then(async (user) => {
                if (user) {
                    let accountDetails = {
                        email: user.attributes.email,
                        name: user.attributes.given_name && user.attributes.family_name ? `${user.attributes.given_name} ${user.attributes.family_name}` : user.username,
                        username: user.username
                    }

                    // set data from user object
                    setAccountDetails(accountDetails);

                    try {
                        let res = await getMyAccount({
                            field: 'email',
                            value: user.attributes.email,
                            abortSignal: abortSignalRef?.current
                        });
                        let myAccount = res.data;

                        let companyDetail = await getCompanyDetail({
                            id: myAccount.id,
                            abortSignal: abortSignalRef?.current
                        });

                        setAccountDetails({
                            ...accountDetails,
                            // set company specific data
                            companyId: myAccount.id,
                            companyAddress: myAccount.address,
                            companyName: myAccount.name,
                            companyUrl: myAccount.url,
                            companyPocName: companyDetail.data.poc_name,
                            companyPocEmail: companyDetail.data.poc_email,
                            companyLogoUrl: companyDetail.data.logo_url,
                            companyIvt: companyDetail.data.ivt,
                            clientId: myAccount.client_id,
                            clientSecret: myAccount.client_secret,
                            apiKey: myAccount.api_key,
                        });

                        setLoadingAccount(false);

                    } catch (err) {
                        if (isCancel(err))
                            return;

                        console.error('failed to get client credentials:',
                            err.message);
                        dispatch(setAlert({
                            show: true,
                            message: 'Failed to get client credentials',
                            severity: 'error'
                        }));

                        setLoadingAccount(false);
                    }
                }
            })
            .catch(err => {
                if (isCancel(err))
                    return;

                console.error('failed to the user data', err);
                dispatch(setAlert({
                    show: true,
                    message: 'Failed to get the user data',
                    severity: 'error'
                }));
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getApiHideButton = () => (
        <DipIconButton
            id='show-hide-api-key'
            aria-label={hideApiKey ? 'Show API Key' : 'Hide API Key'}
            onClick={() => setHideApiKey(!hideApiKey)}
        >
            {hideApiKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </DipIconButton>
    );

    const getClientSecretHideButton = () => (
        <DipIconButton
            id='show-hide-client-secret'
            aria-label={hideClientSecret ?
                'Show Client Secret' : 'Hide Client Secret'}
            onClick={() => setHideClientSecret(!hideClientSecret)}
        >
            {hideClientSecret ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </DipIconButton>
    );

    const getIvtHideButton = () => (
        <DipIconButton
            id='show-hide-ivt'
            aria-label={hideIvt ?
                'Show IVT' : 'Hide IVT'}
            onClick={() => setHideIvt(!hideIvt)}
        >
            {hideClientSecret ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </DipIconButton>
    );

    const getApiCopyButton = () => (
        <CopyButton
            id='copy-api-key'
            aria-label='Copy API Key'
            onClick={() => copyToClipboard(accountDetails.apiKey)}
        />
    );

    const getClientIdCopyButton = () => (
        <CopyButton
            id='copy-client-id'
            aria-label='Copy Client ID'
            onClick={() => copyToClipboard(accountDetails.clientId)}
        />
    );

    const getClientSecretCopyButton = () => (
        <CopyButton
            id='copy-client-secret'
            aria-label='Copy Client Secret'
            onClick={() => copyToClipboard(accountDetails.clientSecret)}
        />
    );

    const getIvtCopyButton = () => (
        <CopyButton
            id='copy-ivt'
            aria-label='Copy ISA Verification Token'
            onClick={() => copyToClipboard(accountDetails.companyIvt)}
        />
    );

    const getTokenCopyButton = () => (
        <CopyButton
            id='copy-token-url'
            aria-label='Copy Token URL'
            onClick={() =>
                copyToClipboard(process.env.REACT_APP_COGNITO_TOKEN_ENDPOINT)}
        />
    );

    const getHeader = (title) => {

        return (
            <>
                <MajorHeading
                    label={title}
                    labelStyle='margin-top-0 margin-bottom-0'
                    loading={loadingAccount}
                />
                <Divider
                    sx={{
                        backgroundColor: 'primary',
                        my: '1rem',
                    }}
                />
            </>
        );
    }

    // A generic copy icon
    const CopyButton = ({ onClick, ...props }) => (
        <Tooltip title="Copy" placement="top" arrow>
            <DipIconButton onClick={onClick} {...props} >
                <ContentCopyIcon />
            </DipIconButton>
        </Tooltip>
    );

    // Builds one row for the Company Credentials display
    const getCredentialRow = (label, data, showButton, copyButton, baseId) => (
        <tr>
            <td>
                <FieldName
                    name={label}
                    id={`${baseId}-name`}
                    valueId={`${baseId}-value`}
                />
            </td>
            <td>
                <FieldValue
                    id={`${baseId}-value`}
                    value={data}
                />
            </td>
            <td>
                {showButton}
            </td>
            <td>
                {copyButton}
            </td>
        </tr>
    );

    // Data for the Account Details table
    const tableData = [
        {
            label: 'User:',
            value: accountDetails.name,
            baseId: 'user'
        },
        {
            label: 'Username:',
            value: accountDetails.username,
            baseId: 'user-name'
        },
        {
            label: 'User Email:',
            value: accountDetails.email,
            baseId: 'user-email'
        },
        {
            label: 'Company:',
            value: accountDetails.companyName,
            baseId: 'company'
        },
        {
            label: 'Company URL:',
            value: accountDetails.companyUrl,
            baseId: 'company-url'
        },
        {
            label: 'Company POC:',
            value: accountDetails.companyPocName,
            baseId: 'company-poc'
        },
        {
            label: 'Company POC Email:',
            value: accountDetails.companyPocEmail,
            baseId: 'company-poc-email'
        }
    ];

    return (
        <Grid container
            id='profile'
            spacing={2}
        >
            { /* Left side - Account Details */}
            <Grid item sm={12} md={6} >
                <Card id='account-details' >
                    <CardContent>
                        {getHeader('Account Details')}

                        <Stack direction='row'
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                        >
                            <LabelValueTable tableData={tableData} />

                            <Box
                                component='img'
                                alt={!!accountDetails.companyName ?
                                    `${accountDetails.companyName} logo` :
                                    'company logo'}
                                src={accountDetails.companyLogoUrl}
                                sx={{
                                    width: '30%',
                                    mx: 'auto'
                                }}
                            />
                        </Stack>

                    </CardContent>
                </Card>
            </Grid>

            { /* Right side - Company Credentials */}
            <Grid item sm={12} md={6} sx={{ width: '100%' }}>
                <Card id='company-global-credentials' >
                    <CardContent>
                        {getHeader('Company Global Credentials')}

                        <table role='presentation'>
                            <tbody>
                                {
                                    getCredentialRow('API Key:',
                                        hideSecret(hideApiKey,
                                            accountDetails.apiKey),
                                        getApiHideButton(), getApiCopyButton())
                                }
                                {
                                    getCredentialRow('Client ID:',
                                        accountDetails.clientId,
                                        null, getClientIdCopyButton())
                                }
                                {
                                    getCredentialRow('Client Secret:',
                                        hideSecret(hideClientSecret,
                                            accountDetails.clientSecret),
                                        getClientSecretHideButton(),
                                        getClientSecretCopyButton())
                                }
                                {
                                    getCredentialRow('Token URL:',
                                        process.env.REACT_APP_COGNITO_TOKEN_ENDPOINT,
                                        null, getTokenCopyButton())
                                }
                                {
                                    getCredentialRow(
                                        'Invitation Verification Token:',
                                        hideSecret(hideIvt,
                                            accountDetails.companyIvt),
                                        getIvtHideButton(),
                                        getIvtCopyButton()
                                    )
                                }
                            </tbody>
                        </table>

                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
};
