import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import BodyText from "../common/BodyText";
import RadioButtonGroup from "../common/RadioButtonGroup";
import TextArea from "../common/TextArea";
import AccessPurposeCheckboxes from "./AccessPurposeCheckboxes";
import { JUSTIFICATION_FAMILIARITY_ID_MAP } from "../../utils/requestAccess";

/**
 * This component is for the Justification step in the request access process.
 * The user must provide a justification as to why they want to access DIP.
 *
 * @param {object}   input                team information entered so far
 * @param {function} handleInputChange    function to call when the user enters
 *                                        any data except the checkboxes
 * @param {function} handleJustificationPurposeCheckboxChange
 *                                        function to call when the user
 *                                        toggles any "purpose" checkbox except
 *                                        "other"
 * @param {function} handleJustificationOtherTextChange
 *                                        function to call when the user
 *                                        toggles the Other purpose and enters
 *                                        text in the text field
 * @param {object}   formErrors           results of input validation
 *
 * @returns JSX for entering justification
 */
const JustificationStep = ({
    input,
    handleInputChange,
    handleJustificationPurposeCheckboxChange,
    handleJustificationPurposeOtherTextChange,
    formErrors
}) => {

    // Put a red box around the purposes box when there's an error
    const purposeBorder = formErrors?.purposes?.selectedIds ?
        'border-05 border-emergency padding-05' : '';

    return (
        <Grid container>
            <Grid item xs={12}>
                <BodyText
                    label='Please provide a justification for accessing the Digital Information Platform and its catalog of services.'
                    labelStyle='text-center'
                />
                <BodyText
                    label='Note that DIP administrators will use this information to determine eligibility to access the Digital Information Platform.'
                    labelStyle='text-center margin-top-1'
                />
            </Grid>
            <Grid container item
                xs={12}
                spacing={3}
                sx={{
                    my: '1rem'
                }}
            >
                <Grid item xs={12} sm={7}>
                    <TextArea
                        id='justification-access'
                        name='justification'
                        label='How do you intend to use the Digital Information Platform?'
                        labelStyle='margin-top-0'
                        value={input.justification}
                        onChange={handleInputChange}
                        required
                        errorMessage={formErrors?.justification}
                        style={{
                            height: '6rem'
                        }}
                    />


                    <Box
                        sx={{
                            mt: '2rem',
                        }}
                        className={purposeBorder}
                    >
                        <AccessPurposeCheckboxes
                            input={input}
                            handleJustificationPurposeCheckboxChange={handleJustificationPurposeCheckboxChange}
                            handleJustificationPurposeOtherTextChange={handleJustificationPurposeOtherTextChange}
                            errors={formErrors?.purposes}
                        />
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={5}
                >
                    <RadioButtonGroup
                        label='How familiar is the team with using an API?'
                        itemList={Object.entries(JUSTIFICATION_FAMILIARITY_ID_MAP).map(([label, id]) => ({
                            id: `api-familiarity-${id}`,
                            value: id,
                            label: label,
                            name: 'api_familiarity',
                            checked: Number(input.api_familiarity) === id,
                            labelStyle: 'margin-left-2'
                        }))}
                        onChange={handleInputChange}
                        required
                    />
                    <RadioButtonGroup
                        label='How familiar is the team with aviation data?                            '
                        groupStyle='margin-top-6'
                        itemList={Object.entries(JUSTIFICATION_FAMILIARITY_ID_MAP).map(([label, id]) => ({
                            id: `aviation-data-familiarity-${id}`,
                            value: id,
                            label: label,
                            name: 'aviation_data_familiarity',
                            checked: Number(input.aviation_data_familiarity) === id,
                            labelStyle: 'margin-left-2'
                        }))}
                        onChange={handleInputChange}
                        required
                    />
                </Grid>
            </Grid>
        </Grid>
    )
};

export default JustificationStep;
