import Grid from '@mui/material/Grid';

import Accordion from '../common/Accordion';
import BodyText from '../common/BodyText';
import FieldHelp from '../common/FieldHelp';
import MinorHeading from '../common/MinorHeading';
import StyledLink from '../common/StyledLink';
import ServiceVersionButtons from './ServiceVersionButtons';
import ServiceVersionDynamicMetrics from './ServiceVersionDynamicMetrics';
import ServiceVersionStaticMetrics from './ServiceVersionStaticMetrics';
import { useEffect, useState } from 'react';
import AdditionalFiles from './AdditionalFiles';


/**
 * This provides the information about a single service version which is viewed
 * from a Service Detail page.
 */
const ServiceVersion = ({
    serviceId,
    serviceName,
    serviceVersion,
    serviceType,
    companyId,
    providerId,
    hideApiButton,
    handleOpenConnectDialog,
    disableSubscribeButton,
    handleSubscriptionButtonClick,
    handleOpenUnsubscribeDialog,
    handlingSubscription,
    subscriptionHandlingVersionId,  // version ID that's getting updated
    initiallyExpanded=false,
    connectDialogRef,
    unsubscribeDialogRef,
    dtsDialogRef,
    ...props
}) => {

    // Easier access to the version number
    const versionId = serviceVersion?.version_id;
    const baseId = `service-version-${versionId}`;

    // Mapping from file type to path for the current service version
    let [fileTypeToPath, setFileTypeToPath] = useState({});

    useEffect(() => {
        if (serviceVersion?.files) {
            let tmpFileTypeToPath = {};
            serviceVersion.files.forEach(f => tmpFileTypeToPath[f['type']] = f['path']);
            setFileTypeToPath(tmpFileTypeToPath)
        }
    }, [serviceVersion]);

    const getServiceVersionButtons = () => (
        <section key={`section-buttons-version-${versionId}`}>
            <ServiceVersionButtons
                serviceId={serviceId}
                serviceName={serviceName}
                serviceVersion={serviceVersion}
                serviceType={serviceType}
                companyId={companyId}
                providerId={providerId}
                hideApiButton={hideApiButton}
                handleOpenConnectDialog={handleOpenConnectDialog}
                disableSubscribeButton={disableSubscribeButton}
                handleSubscriptionButtonClick={handleSubscriptionButtonClick}
                handleOpenUnsubscribeDialog={handleOpenUnsubscribeDialog}
                handlingSubscription={handlingSubscription}
                subscriptionHandlingVersionId={subscriptionHandlingVersionId}
                apiSpecFileLocation={fileTypeToPath?.['openapi_spec']}
                connectDialogRef={connectDialogRef}
                unsubscribeDialogRef={unsubscribeDialogRef}
                dtsDialogRef={dtsDialogRef}
            />
        </section>
    );

    const getReleaseNotes = () => (
        <section key={`section-releasenotes-version-${versionId}`}>
            <MinorHeading
                label='Release Notes'
                labelStyle='margin-y-0'
            />
            <Grid container>
               {
                    serviceVersion.release_summary &&
                    <Grid item xs={12} >
                        <BodyText
                            label={serviceVersion.release_summary}
                            labelStyle='margin-top-1 margin-left-2 text-pre-wrap '
                        />
                    </Grid>
                }
            </Grid>
        </section>
    );

    const getDomainOfApplicability = () => (
        <section key={`section-domain-version-${versionId}`}>
            <MinorHeading
                label='Domain of Applicability'
                labelStyle='margin-y-0'
            />
            <Grid container>
               {
                    serviceVersion.domain_of_applicability.date_range &&
                    <Grid item xs={12} >
                        <BodyText
                            label={serviceVersion.domain_of_applicability.date_range}
                            labelStyle='margin-top-1 margin-left-2'
                        />
                    </Grid>
                }
                {
                    serviceVersion.domain_of_applicability.geographical_extent &&
                    <Grid item xs={12} >
                        <BodyText
                            label={serviceVersion.domain_of_applicability.geographical_extent}
                            labelStyle='margin-top-1 margin-left-2'
                        />
                    </Grid>
                }
            </Grid>
        </section>
    );

    const getDataSources = () => (
        <section key={`section-datasources-version-${versionId}`}>
            <MinorHeading
                label='Data Source'
                labelStyle='margin-y-0'
            />
            <Grid container sx={{ mt: 0 }}>
                <Grid item
                    xs={12}
                    sx={{
                        pl: '1rem',
                        whiteSpace: 'pre-wrap'
                    }}
                >
                    <BodyText
                        label={serviceVersion.data_sources}
                        labelStyle='text-pre-wrap margin-y-05'
                    />
                </Grid>
            </Grid>
        </section>
    );

    const getAdditionalInfo = () => (
        <section key={`section-additionalinfo-version-${versionId}`}>
            <MinorHeading
                label='Additional Information'
                labelStyle='margin-bottom-0 margin-top-0'
            />
            <FieldHelp
                help='Links open in a new window'
                helpStyle='margin-top-0 margin-left-2 margin-bottom-05'
            />
            <Grid container sx={{ mb: '1rem' }}>
                {
                    serviceVersion.additional_info_links.map((link, idx) => {
                        const key = `${baseId}-additional-info-link-${idx}`;
                        // If the link does not start with the protocol
                        // e.g. "http", the routing thinks it's a relative link
                        // and tries to open it as a link within the Catalog,
                        // which does not work (use Additional Files for that).
                        // We added the requirement of the protocol in the URL
                        // entry field, but the check below is to catch any
                        // partial URLs entered before the validation was
                        // added.
                        let useLink = link.url;
                        if (!link.url.startsWith("http")) {
                            useLink = "http://" + link.url;
                        }
                        return (
                            <Grid item xs={12} key={key} >
                                <StyledLink
                                    id={key}
                                    href={useLink}
                                    target='_blank'
                                    sx={{
                                        pl: '1rem'
                                    }}
                                >
                                    {link.title}
                                </StyledLink>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </section>
    );

    const getServiceVersionDynamicMetrics = () => (
        <section key={`section-dynamic-version-${versionId}`}>
            <ServiceVersionDynamicMetrics
                serviceId={serviceId}
                versionId={versionId}
                providerId={providerId}
            />
        </section>
    );

    const getServiceVersionStaticMetrics = () => (
        <section key={`section-static-version-${versionId}`}>
            <ServiceVersionStaticMetrics
                versionId={versionId}
                serviceVersion={serviceVersion}
            />
        </section>
    );

    const getAdditionalFiles = (files) => (
        <section key={`section-additional-files-${versionId}`}>
            <AdditionalFiles id={`${baseId}-additional-files`} files={files} />
        </section>
    )

    // Put together the items that will be displayed for the version.
    const itemList = [];
    itemList.push(getServiceVersionButtons());
    if (!!serviceVersion.release_summary) {
        itemList.push(getReleaseNotes());
    }
    if (!!serviceVersion.domain_of_applicability) {
        itemList.push(getDomainOfApplicability());
    }
    if (!!serviceVersion.data_sources) {
        itemList.push(getDataSources());
    }
    if (!!serviceVersion.additional_info_links) {
        itemList.push(getAdditionalInfo());
    }
    itemList.push(getServiceVersionDynamicMetrics());
    itemList.push(getServiceVersionStaticMetrics());


    if (!!serviceVersion.additionalFiles?.length) {
        itemList.push(getAdditionalFiles(serviceVersion.additionalFiles));
    }

    return (
        <Grid item xs={12} key={`grid-${versionId}`} >
            <Accordion
                id={`${baseId}`}
                title={`${versionId}${serviceVersion.is_beta ? ' - Beta' : ''}`}
                itemList={itemList}
                show={initiallyExpanded}
            />
        </Grid>
    );
};

export default ServiceVersion;
