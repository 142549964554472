import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import Pagination from '../common/Pagination';
import NewsLeftSide from './NewsLeftSide';
import NewsRightSide from './NewsRightSide';


/**
 * This provides the layout for a news page.  It consists of a two-column
 * layout, with graphic, highlights, and "latest" news on the left side
 * and "earlier" news on the right side.  The "earlier" news on the right is
 * paginated.
 *
 * @param {object}        newsImg             the graphic to display
 * @param {boolean}       loading             true if the data is still loading
 * @param {string}        highlightTitle      the title to display in the
 *                                            "highlights" block
 * @param {array(string}} highlightItems      content to display as a bulleted
 *                                            list in the "highlights" block
 * @param {string}        highlightKeyPrefix  prefix to use for each key in the
 *                                            list of highlightItems so that
 *                                            React doesn't complain
 * @param {string}        latestTitle         the title to display in the
 *                                            "latest" block
 * @param {JSX}           latestContent       JSX content to display in the
 *                                            "latest" block
 * @param {string}        earlierTitle        the title to display in the
 *                                            "earlier" block on the right side
 * @param {JSX}           earlierContent      JSX content to display in the
 *                                            "earlier" block on the right side
 * @param {number}        initialRowsPerPage  how many "rows" of data to display
 *                                            on the right side
 * @param {number}        page                current page number, 0-based
 * @param {function}      pageCallback        function to call when the user
 *                                            changes the page of data to 
 *                                            display by clicking a page 
 *                                            number button or the 
 *                                            Previous/Next button.  This 
 *                                            function should expect to 
 *                                            receive the new 0-based page 
 *                                            number
 * @param {function}      rowsPerPageCallback function to call when the user
 *                                            changes the number of rows to 
 *                                            display
 * @param {number}        numRows             total number of rows of data
 * @param {string}        units               units to display in the 
 *                                            pagination label, e.g. "rows" or
 *                                            "months" or "services"
 */
const NewsPage = ({
    newsImg,
    loading,
    highlightTitle,
    highlightItems,
    highlightKeyPrefix,
    latestTitle,
    latestContent,
    earlierTitle,
    earlierContent,
    initialRowsPerPage,
    page,
    pageCallback,
    rowsPerPageCallback,
    numRows,
    units
}) => {

    return (
        <Grid container
            direction='row'
            alignItems='start'
            spacing={2}
        >
            { /* Left side content */ }
            <Grid container item
                xs={12}
                md={6}
                direction='column'
                sx={{
                }}
            >
                <NewsLeftSide
                    newsImg={newsImg}
                    loading={loading}
                    highlightTitle={highlightTitle}
                    highlightItems={highlightItems}
                    highlightKeyPrefix={highlightKeyPrefix}
                    latestTitle={latestTitle}
                    latestContent={latestContent}
                />
            </Grid>

            { /* Right side content */ }
            <Grid container item
                xs={12}
                md={6}
                direction='column'
                sx={{
                }}
            >
                <NewsRightSide
                    loading={loading}
                    sideTitle={earlierTitle}
                    sideContent={earlierContent}
                />
                {
                    !loading &&
                    <Card sx={{ mt: '1rem' }} >
                        <Pagination
                            id='news-pagination'
                            initialRowsPerPage={initialRowsPerPage}
                            page={page}
                            pageCallback={pageCallback}
                            rowsPerPageCallback={rowsPerPageCallback}
                            numRows={numRows}
                            units={units}
                        />
                    </Card>
                }
            </Grid>
        </Grid>
    )
}

export default NewsPage;
