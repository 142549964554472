import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteDataTransferSubscription, getCompanies,
         getTransferSubscriptionList, updateDataTransferSubscriptionStatus
       } from "../../../apiClient";
import { setDataTransferSubscriptions } from "../../../app/slices/admin";
import { setAlert } from "../../../app/slices/alert";
import ActionButton from '../../../components/common/ActionButton';
import BodyText from "../../../components/common/BodyText";
import CircularProgressIndicator from '../../../components/common/CircularProgressIndicator';
import CompanySelect, { ALL_COMPANIES_ID } from '../../../components/common/CompanySelect';
import DataTable from "../../../components/common/DataTable";
import FieldName from "../../../components/common/FieldName";
import FieldValue from "../../../components/common/FieldValue";
import OkCancelDialog from "../../../components/common/OkCancelDialog";
import Select from "../../../components/common/Select";
import Modal from "../../../components/common/modal/Modal";
import ModalFooter from "../../../components/common/modal/ModalFooter";
import { useAbortController } from '../../../hooks';
import { DataTransferSubscriptionUtils } from '../../../utils/dataTransfer';
import { getDateFromTimestamp } from "../../../utils/timeUtils";
import { CANCEL_DELETE_TEXT, OK_DELETE_TEXT } from '../../../utils/utils';


/**
 * This provides the subscription information for a data transfer service.
 * Right now you access this from Admin -> Data Transfer Service, then
 * click the Subscriptions tab then click an item in the table.
 */
const Subscriptions = () => {

    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();

    const subscriptionList = useSelector(state => state.admin.dataTransferSubscriptions);

    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false)
    const [updatingMsg, setUpdatingMsg] = useState('')
    const [openDialog, setOpenDialog] = useState(false);
    const subscriptionDialogRef = useRef(null);
    const [editSubscription, setEditSubscription] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const deleteDialogRef = useRef(null);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    useEffect(() => {
        getSubscriptions();
        initCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getSubscriptions(selectedCompany?.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany]);

    const getSubscriptions = async (destProviderId) => {
        setLoading(true);
        try {
            let res = await getTransferSubscriptionList({
                        dest_provider_id: destProviderId,
                        abortSignal: abortSignalRef?.current
            });
            dispatch(
                setDataTransferSubscriptions(
                    (res.data?.transfer_subscriptions ?? []).map(s => ({
                        ...s,
                        created_timestamp: getDateFromTimestamp(s.created_timestamp)
                    }))
                )
            );

            setLoading(false);
        } catch (err) {
            if (isCancel(err))
                return;

            console.error('failed to get the subscriptions:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get the subscriptions',
                severity: 'error'
            }));

            setLoading(false);
        }
    }

    const initCompanies = async () => {
        getCompanies({ abortSignal: abortSignalRef?.current })
        .then(res => {
            setCompanies([...(res.data.companies ?? [] )]);
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to get companies:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get companies',
                severity: 'error'
            }));
        });
    };

    const handleRowClick = (data) => {
        setSelectedSubscription({ ...data });
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setEditSubscription(false);
    }

    const handleInputChange = (fieldName, fieldValue) => {
        setSelectedSubscription({
            ...selectedSubscription,
            [fieldName]: fieldValue
        })
    }

    const handleOpenDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const handleCloseDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const handleDelete = () => {
        const cleanup = () => {
            getSubscriptions();
            handleCloseDeleteConfirmation();
            handleCloseDialog();
            setUpdating(false);
            setUpdatingMsg('');
        };

        if (selectedSubscription?.id !== null &&
            selectedSubscription?.id !== undefined) {
            setUpdating(true);
            setUpdatingMsg('Deleting subscription...');
            deleteDataTransferSubscription({ id: selectedSubscription.id,
                delete_service_subscription: true,
                abortSignal: abortSignalRef?.current })
            .then(() => {
                dispatch(setAlert({
                    show: true,
                    message: 'successfully deleted the subscription',
                    severity: 'success'
                }));

                cleanup();
            })
            .catch(err => {
                if (isCancel(err))
                    return;

                console.error(`failed to delete the subscription ${selectedSubscription.id}: ${err}`)
                dispatch(setAlert({
                    show: true,
                    message: 'Failed to delete the subscription',
                    severity: 'error'
                }));

                cleanup();
            })
        }
    }

    const handleSave = () => {

        // if the save button's clicked on
        if (selectedSubscription) {
            setUpdating(true);
            setUpdatingMsg('Processing subscription...');
            // for now the status is the only editable field
            updateDataTransferSubscriptionStatus({
                id: selectedSubscription.id,
                body: {
                    status: selectedSubscription.status,
                    source_bucket_name: selectedSubscription.source_bucket_name,
                    destination_account_number: selectedSubscription.dest_account
                },
                abortSignal: abortSignalRef?.current
            })
            .then(() => {
                dispatch(setAlert({
                    show: true,
                    message: 'successfully updated the subscription status',
                    severity: 'success'
                }));

                getSubscriptions();
                setUpdating(false);
                setUpdatingMsg('');
                handleCloseDialog();
            })
            .catch(err => {
                if (isCancel(err))
                    return;

                console.error(`failed to update the subscription status for ${selectedSubscription.id}: ${err}`)
                dispatch(setAlert({
                    show: true,
                    message: 'Failed to update the subscription status',
                    severity: 'error'
                }));

                setUpdating(false);
                setUpdatingMsg('');
            })
        }

        setEditSubscription(false);
    }

    const handleCompanySelect = (newSelectedCompany) => {
        if (newSelectedCompany.id === ALL_COMPANIES_ID) {
            setSelectedCompany(null);
        } else {
            setSelectedCompany(newSelectedCompany);
        }
    };

    return (
        <Fragment>
            <Card id='admin-data-transfer-subscriptions'>
                <CardContent>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <CompanySelect
                                companyOptions={companies}
                                handleCompanySelect={handleCompanySelect}
                                selectedCompany={selectedCompany}
                                showAllOption={true}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <DataTable
                                id='data-transfer-suscriptions-table'
                                columns={tableColumns}
                                rows={subscriptionList}
                                handleRowClick={handleRowClick}
                                enableFilter={true}
                                showLoadingIcon={loading}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {/* Dialog for the data transfer subscription.  It must be
                created even if not shown for the sake of the ref,
                but don't create the contents if it is not displayed.
              */}
            <Modal
                id='subscription-detail-dialog'
                isOpen={!!selectedSubscription && openDialog}
                heading='Data Transfer Subscription'
                ref={subscriptionDialogRef}
            >
                {
                    !!selectedSubscription && openDialog &&
                    (
                        <div>
                            <div style={{ display: 'grid', gridTemplateColumns: 'minmax(min-content, max-content) auto' }} >

                                <FieldName id='src-lbl' valueId='src-val' name='Source:' />
                                <FieldValue id='src-val' value={selectedSubscription.source_bucket_name} />

                                <FieldName id='prov-lbl' valueId='prov-val' name='Source Provider:' />
                                <FieldValue id='prov-val' value={selectedSubscription.source_provider_name} />

                                <FieldName id='dest-lbl' valueId='dest-val' name='Destination:' />
                                <FieldValue id='dst-val' value={selectedSubscription.dest_bucket_name} />

                                <FieldName id='dest-prov-lbl' valueId='dest-prov-val' name='Destination Provider:' />
                                <FieldValue id='dest-prov-val' value={selectedSubscription.dest_provider_name} />

                                <FieldName id='acct-lbl' valueId='acct-val' name='Destination Account Number:' />
                                <FieldValue id='acct-val' value={selectedSubscription.dest_account} />

                                <FieldName id='created-lbl' valueId='created-val' name='Created:' />
                                <FieldValue id='created-val' value={selectedSubscription.created_timestamp} />

                                <FieldName id='created-by-lbl' valueId='created-by-val' name='Created by:' />
                                <FieldValue id='created-by-val' value={selectedSubscription.created_by} />

                                <FieldName id='updated-by-lbl' valueId='updated-by-val' name='Updated by:' />
                                <FieldValue id='updated-by-val' value={selectedSubscription.last_updated_by} />

                                <FieldName id='status-lbl' valueId='status-val' name='Status:' />
                                {
                                    editSubscription ? (
                                        <Select
                                            id='status-val'
                                            name='status_select'
                                            label=''
                                            value={selectedSubscription.status}
                                            onChange={(e) =>
                                                handleInputChange('status',
                                                    e.target.value)}
                                            itemList={
                                                Object.values(DataTransferSubscriptionUtils.STATUSES).map(status => {
                                                    return ({
                                                        id: status,
                                                        value: status
                                                    })
                                                })
                                            }
                                        />
                                    ) : <FieldValue id='status-val' value={selectedSubscription.status} />
                                }
                            </div>

                            {
                                updating ?
                                    <CircularProgressIndicator
                                        type='large'
                                        altText={updatingMsg}
                                        sx={{ ml: '1rem' }}
                                    />
                                :
                                    <ModalFooter>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item
                                                xs={12}
                                                sm={3}
                                                sx={{
                                                    textAlign: { xs: 'end', sm: 'left' }
                                                }}
                                            >
                                                {
                                                    <ActionButton text='Delete' isDelete
                                                        onClick={handleOpenDeleteConfirmation}
                                                        data-open-modal
                                                        aria-controls={deleteDialogRef?.current?.modalId}
                                                    />
                                                }
                                            </Grid>
                                            <Grid item
                                                xs={12}
                                                sm={9}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'end'
                                                }}
                                            >
                                                    <ActionButton text='Cancel'
                                                        onClick={handleCloseDialog}
                                                        style={{ marginLeft: '1rem' }}
                                                        data-close-modal
                                                        aria-controls={subscriptionDialogRef?.current?.modalId}
                                            />
                                            {
                                                editSubscription ?
                                                    <ActionButton text='Save'
                                                        onClick={handleSave}
                                                        buttonStyle='margin-left-2'
                                                        data-close-modal
                                                        aria-controls={subscriptionDialogRef?.current?.modalId}
                                                    />
                                                :
                                                    <ActionButton text='Edit'
                                                        onClick={() =>
                                                            setEditSubscription(true)}
                                                        buttonStyle='margin-left-2'
                                                        data-open-modal
                                                        aria-controls={subscriptionDialogRef?.current?.modalId}
                                                    />
                                            }
                                        </Grid>
                                    </Grid>
                                </ModalFooter>
                            }
                        </div>
                    )
                }
            </Modal>

            {/* Dialog for the user to delete a subscription.  It must be
                created even if not shown for the sake of the ref.  */}
            <OkCancelDialog
                id='confirm-subscription-deletion-dialog'
                open={showDeleteConfirmation}
                heading='Confirm Subscription Deletion'
                okText={OK_DELETE_TEXT}
                cancelText={CANCEL_DELETE_TEXT}
                handleOkOp={handleDelete}
                handleCancelOp={handleCloseDeleteConfirmation}
                dialogRef={deleteDialogRef}
            >
                {
                    showDeleteConfirmation &&
                    <>
                        <BodyText labelStyle='text-center' >
                            Are you sure you want to delete the subscription to
                        </BodyText>
                        <BodyText labelStyle='text-center margin-top-1' >
                            <strong>{selectedSubscription.source_bucket_name}</strong>?
                        </BodyText>
                    </>
                }
            </OkCancelDialog>
        </Fragment>
    );
};

const tableColumns = [
    {
        header: 'ID',
        accessor: 'id',
        enableSort: true,
        align: 'right'
    },
    {
        header: 'Source',
        accessor: 'source_bucket_name',
        enableSort: true
    },
    {
        header: 'Source Provider',
        accessor: 'source_provider_name',
        enableSort: true
    },
    {
        header: 'Destination',
        accessor: 'dest_bucket_name',
        enableSort: true
    },
    {
        header: 'Destination Provider',
        accessor: 'dest_provider_name',
        enableSort: true
    },
    {
        header: 'Status',
        accessor: 'status',
        enableSort: true
    },
    {
        header: 'Created',
        accessor: 'created_timestamp',
        enableSort: true,
        formatter: getDateFromTimestamp,
        align: 'right'
    }
];

export default Subscriptions;
