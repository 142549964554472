import Grid from '@mui/material/Grid';

import { useEffect, useRef, useState } from 'react';

import BodyText from '../common/BodyText';
import DataTable from '../common/DataTable';
import MinorHeading from '../common/MinorHeading';
import { isQosOther } from '../../utils/qosMetrics';
import MetricsDisplayBlock from './MetricsDisplayBlock';
import MetricHelp from './MetricHelp';


/**
 * This displays the static metrics for a single service version as part of
 * a Service Detail page.
 */
const ServiceVersionStaticMetrics = ({
    versionId,
    serviceVersion,
}) => {

    // Provider static QoS metrics
    const [ providerStaticQosMetrics,
                setProviderStaticQosMetrics ] = useState(null);
    const [ loadingProviderStaticQosMetrics,
                setLoadingProviderStaticQosMetrics] = useState(false);

    // Provider static additional metrics
    const [ hasProviderAdditionalStaticMetrics,
                setHasProviderAdditionalStaticMetrics ] = useState(false);

    // Display metric help dialog
    const [ showHelpForMetric, setShowHelpForMetric] = useState(null);
    const [ helpDialogTitle, setHelpDialogTitle ] = useState(null);
    const [ isHelpDialogOpen, setHelpDialogOpen ] = useState(false);
    const helpRef = useRef(null);

    /**
     * Get the Service Provider's static QoS metrics
     */
    useEffect(() => {
        setLoadingProviderStaticQosMetrics(true);
        if (serviceVersion?.quality_of_service &&
           (serviceVersion.quality_of_service.length > 0)) {

            // Remove any metric for which the value is unset.
            let metrics = serviceVersion.quality_of_service.filter(param =>
                !!param.value);

            // Create the metric display object
            let data = null;
            if (!!metrics) {
                data = metrics.map((param, idx) => {
                    const name = isQosOther(param?.parameterType) ?
                                      param.customType : param.parameterType;
                    return ({
                        key: `${versionId}-sp-qos-${idx}`,
                        name: name,
                        value: param.value,
                        definition: param.description,
                        methodOfCalculation: param.method,
                    });
                });
            }
            setProviderStaticQosMetrics(data);
        }
        else {
            setProviderStaticQosMetrics(null);
        }
        setLoadingProviderStaticQosMetrics(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceVersion?.quality_of_service]);

    /**
     * Set the convenience flag for whether the Service Provider
     * has additional static metrics.
     */
    useEffect(() => {
        setHasProviderAdditionalStaticMetrics(
            (serviceVersion?.metrics?.columns &&
             serviceVersion?.metrics?.rows));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceVersion?.metrics]);


    /**
     *  Opens the help dialog for the given metric display object
     */
    const openHelpDialog = (metric, title) => {
        setShowHelpForMetric(metric);
        setHelpDialogTitle(title);
        setHelpDialogOpen(true);
    };

    /**
     *  Closes the help dialog.
     */
    const closeHelpDialog = () => {
        setShowHelpForMetric(null);
        setHelpDialogTitle(null);
        setHelpDialogOpen(false);
    };

    /**
     * Returns the JSX header for the Service Provider static Additional
     * metrics table
     */
    const getProviderStaticAdditionalHeader = () => (
        <p className='usa-label margin-top-2 margin-bottom-0'>
            <b>Provider Additional Metrics</b>
        </p>
    );

    /**
     * Returns the JSX for the Service Provider static additional metrics.
     * This method assumes the caller already checked that those metrics exist.
     */
    const getProviderStaticAdditionalMetrics = () => (
        <>
            <Grid item
                xs={12}
                sx={{
                    ml: '1rem',
                }}
            >
                { getProviderStaticAdditionalHeader() }
            </Grid>
            {
                serviceVersion.metrics.description &&
                <Grid item
                    xs={12}
                    sx={{
                        mb: '1rem',
                        ml: '1rem',
                    }}
                >
                    <BodyText
                        label={serviceVersion.metrics.description}
                        labelStyle='text-pre-wrap margin-top-1'
                    />
                </Grid>
            }
            <Grid item
                xs={12}
                sx={{
                    mb: '1rem',
                    ml: '1rem',
                }}
            >
                <DataTable
                    id='additional-metric-data-table'
                    columns={serviceVersion.metrics.columns}
                    rows={serviceVersion.metrics.rows}
                    width='auto'
                    minWidth='auto'
                    hidePagination='true'
                />
            </Grid>
        </>
    );

    return (
        <>
            <Grid container
                id={`${versionId}-metrics`}
            >
                { /* Print the header for the provider static metrics if
                   * there are either Provider static QoS metrics -or-
                   * if there are Provider static additional metrics
                   */
                }
                {
                    (!!providerStaticQosMetrics ||
                        loadingProviderStaticQosMetrics ||
                        hasProviderAdditionalStaticMetrics) &&
                    <Grid item
                        xs={12}
                        sx={{
                            mb: '0.5rem',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <MinorHeading
                            label='Static Metrics'
                            labelStyle='margin-bottom-0 margin-top-2'
                        />
                    </Grid>
                }

                { /* Provider static QoS metrics */ }
                {
                    (!!providerStaticQosMetrics ||
                       loadingProviderStaticQosMetrics) &&
                    <MetricsDisplayBlock
                        title='Provider QoS Metrics'
                        metrics={providerStaticQosMetrics}
                        openHelpDialog={openHelpDialog}
                        helpRef={helpRef}
                    />
                }

                { /* Provider static additional metrics */ }
                {
                    hasProviderAdditionalStaticMetrics &&
                    getProviderStaticAdditionalMetrics()
                }
            </Grid>

            <MetricHelp
                id={`${versionId}-provider-metrics-help`}
                isDialogOpen={isHelpDialogOpen}
                closeDialog={closeHelpDialog}
                rows={showHelpForMetric}
                heading={helpDialogTitle}
                helpRef={helpRef}
            />
        </>
    );
};

export default ServiceVersionStaticMetrics;
