import Grid from "@mui/material/Grid";

import { Box } from "@mui/material";
import { Fragment, memo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../app/slices/alert";
import {
    ADDL_FILES_MAX_NUMBER_OF_FILES,
    ADDL_FILES_MAX_TOTAL_FILE_SIZE_BYTES,
    OTHER_SERVICE_FILE_TYPE,
    getFileSize,
} from "../../utils/serviceRegistration";
import { CANCEL_DELETE_TEXT, OK_DELETE_TEXT } from "../../utils/utils";
import ActionButton from "../common/ActionButton";
import BodyText from "../common/BodyText";
import OkCancelDialog from "../common/OkCancelDialog";
import SectionHeader from "../common/SectionHeader";
import TextField from "../common/TextField";
import VirusScanWrapperForResourceFile from "./VirusScanWrapperForResourceFile";

const FILE_NAME_VERSION_REGEX = new RegExp(/ \((\d+)\)$/);
const S3_TARGET_FOLDER_NAME =
    process.env.REACT_APP_CATALOG_SERVICE_S3_RESOURCE_FOLDER_NAME +
    "/additional files";
/**
 * This provides the component for the user to enter additional files for a
 * service or a service version.
 *
 * @param {Array} additionalFiles       array of objects representing additional
 *                                      files. Each object has the following 
 *                                      properties:
 *                                      - fileType: type of the file
 *                                      - description: description of the file
 *                                      - file: The file object
 * @param {number} totalFileSizeBytes   the total size of all files in bytes
 * @param {number} totalNumberOfFiles   the total number of files
 * @param {function} handleUpdate       function to handle updates
 * @param {boolean} readOnly            indicates whether the component is in 
 *                                      read-only mode.
 * @param {object} formErrors           form validation errors
 * @param {function} setFormErrors      setter for formErrors
 * @param {boolean} isEditingRestricted indicates whether editing is restricted
 *
 * @returns {JSX.Element} the AdditionalFiles component.
 */
const AdditionalFiles = ({
    additionalFiles,
    totalFileSizeBytes,
    totalNumberOfFiles,
    handleUpdate,
    readOnly,
    formErrors,
    setFormErrors,
    isEditingRestricted,
}) => {
    const dispatch = useDispatch();

    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [targetIndexToDelete, setTargetIndexToDelete] = useState();

    // Keep track of the current version of the file in case there were multiple files uploaded
    // with the same name at once.
    const [fileVersionTrackMap, setFileVersionTrackMap] = useState({});

    const deleteDialogRef = useRef(null);

    const handleAddBtnClick = () => {
        handleUpdate({
            action: "add",
            payload: [...additionalFiles, getInitialAdditionalFileObject()],
        });
    };

    const handleFileInputUpdate = (
        targetIndex,
        fieldName,
        value,
        resourceFileRef
    ) => {
        // Limit the total file size of all files
        const existingFileSize =
            getFileSize(additionalFiles[targetIndex]?.file) ?? 0;
        const newFileSize = value?.size ?? 0;
        const finalFileSize = newFileSize - existingFileSize;
        if (totalFileSizeBytes + finalFileSize < ADDL_FILES_MAX_TOTAL_FILE_SIZE_BYTES) {
            let update = [...additionalFiles];
            update[targetIndex] = {
                ...update[targetIndex],
                [fieldName]: value,
            };
            handleUpdate({
                action: "update",
                payload: update,
                fileSize: finalFileSize,
            });
            
            // Reset the form error message for the uploaded file
            if (formErrors?.[targetIndex]) {
                let formErrorUpdate = { ...formErrors };
                formErrorUpdate[targetIndex].file = null;
                setFormErrors(formErrorUpdate);
            }
        } else {
            resourceFileRef?.current?.clearFiles();
            dispatch(
                setAlert({
                    show: true,
                    message: 'Limit of maximum size of all files (200MB) reached for this service',
                    severity: "error",
                })
            );
        }
    };

    /**
     * Deleting a file object from the additionalFiles list using the provided indexToReplace value.
     * If replacing the file, it also deletes the correspodning form error messages
     * using both indexToReplace and currentIndex values.
     * @param {{indexToReplace: number, currentIndex?: number}} obj
     * @param {number} obj.indexToReplace - The index of the file to be deleted/replaced.
     * @param {number} [obj.currentIndex] - The current index, used to delete the form error message (optional).
     */
    const handleFileDelete = ({ indexToReplace, currentIndex }) => {
        const targetFileSize =
            getFileSize(additionalFiles[indexToReplace]?.file) ?? 0;
        let update = [...additionalFiles];
        update.splice(indexToReplace, 1);
        handleUpdate({
            action: "delete",
            payload: update,
            fileSize: targetFileSize,
        });

        // Delete the form error message for the deleted file
        // and reset it for the current file if exists.
        let formErrorUpdate = { ...formErrors };
        delete formErrorUpdate[indexToReplace];
        if (currentIndex >= 0 && formErrorUpdate[currentIndex])
            formErrorUpdate[currentIndex] = null;

        // Reset the formErrors's keys to be 0-indexed
        // because the errors in the middle might have been deleted.
        // Each key (index) in formErrors corresponds to an item
        // in the additional files list.
        setFormErrors(Object.assign({}, Object.values(formErrorUpdate)));
    };

    function splitFileNameAndExtension(fileName) {
        const lastDotIndex = fileName.lastIndexOf('.');
        if (lastDotIndex === -1) {
            // No dot found, return the original fileName and an empty string for the extension
            return { name: fileName, extension: '' };
        }
    
        const name = fileName.substring(0, lastDotIndex);
        const extension = fileName.substring(lastDotIndex + 1);
        return { name, extension };
    }

    const handleKeepBoth = ({ currentIndex, indexToReplace, fileInput }) => {

        let { name, extension } = splitFileNameAndExtension(fileInput.name);

        let version = fileVersionTrackMap[fileInput.name] ?? 2;
        let match = name.match(FILE_NAME_VERSION_REGEX);

        if (match) {
            version = Number(match[1]) + 1;
            // Remove the version from the filename using the index of the match.
            // The String.match returns the index of the match in the string.
            name = name.slice(0, match.index)
        }

        const newFileName =
            `${name} (${version})` + (extension ? `.${extension}` : "");
        const newFile = new File([fileInput], newFileName, { type: fileInput.type });
        newFile.newUpload = fileInput.newUpload;
        newFile.targetFolderName = fileInput.targetFolderName;
        handleFileInputUpdate(currentIndex, "file", newFile);

        setFileVersionTrackMap((prev) => ({
            ...prev,
            [newFileName]: version + 1
        }))
        
        // Reset the form error message for files as well, if any.
        let formErrorUpdate = { ...formErrors };
        formErrorUpdate[indexToReplace].file = null;
        if (currentIndex >= 0 && formErrorUpdate[currentIndex])
            formErrorUpdate[currentIndex].file = null;
        setFormErrors(formErrorUpdate);
    };

    let maxedOut = (totalNumberOfFiles >= ADDL_FILES_MAX_NUMBER_OF_FILES);

    return (
        <div>
            <SectionHeader title="Additional Files" />

            {
                additionalFiles &&
                additionalFiles.map((af, idx) => (
                    <Fragment key={idx}>
                        <FileInputFields
                            index={idx}
                            additionalFile={af}
                            readOnly={readOnly}
                            isEditingRestricted={isEditingRestricted}
                            handleOnChange={(target, value, ref) =>
                                handleFileInputUpdate(idx, target, value, ref)
                            }
                            handleFieldDelete={() => {
                                setTargetIndexToDelete(idx);
                                setOpenDeleteConfirmation(true);
                            }}
                            handleAdditionalFileDelete={handleFileDelete}
                            handleKeepBoth={handleKeepBoth}
                            formErrors={formErrors?.[idx]}
                        />
                        <Box
                            sx={{
                                mb: "1rem",
                            }}
                        />
                    </Fragment>
                ))
            }

            <>
                <ActionButton
                    text="Add"
                    aria-label="add additional file"
                    onClick={handleAddBtnClick}
                    disabled={readOnly || isEditingRestricted || maxedOut}
                />
                {
                    maxedOut &&
                    <span className='margin-left-2' >
                        <i>
                            Maximum number of files
                           ({ADDL_FILES_MAX_NUMBER_OF_FILES})
                           has been reached for this service
                        </i>
                    </span>
                }
            </>

            {/* Dialog to confirm deleting file. */}
            <OkCancelDialog
                id="confirm-delete-resource-file"
                open={openDeleteConfirmation}
                okText={OK_DELETE_TEXT}
                cancelText={CANCEL_DELETE_TEXT}
                heading="Confirm File Deletion"
                handleOkOp={() => {
                    setOpenDeleteConfirmation(false);
                    handleFileDelete({ indexToReplace: targetIndexToDelete });
                }}
                handleCancelOp={() => setOpenDeleteConfirmation(false)}
                dialogRef={deleteDialogRef}
            >
                {openDeleteConfirmation && (
                    <BodyText labelStyle="text-center">
                        Are you sure you want to delete this file?
                    </BodyText>
                )}
            </OkCancelDialog>
        </div>
    );
};

const FileInputFields = ({
    index,
    additionalFile,
    handleOnChange,
    handleFieldDelete,
    handleAdditionalFileDelete,
    handleKeepBoth,
    readOnly,
    isEditingRestricted,
    formErrors,
}) => {
    const ref = useRef(null);

    return (
        <Grid
            container
            item
            columnSpacing={1}
            sx={{
                border: "1px solid",
                borderRadius: "5px",
                borderColor: "#C4C4C4",
                p: "1rem",
            }}
        >
            <Grid item xs={12}>
                <TextField
                    id={`additioanl-file-description-text-field-${index}`}
                    name="additionalFileDescriptionTextField"
                    label="File Description"
                    labelStyle="margin-top-0"
                    value={additionalFile.description}
                    onChange={(e) =>
                        handleOnChange("description", e.target.value)
                    }
                    errorMessage={formErrors?.description}
                    disabled={readOnly}
                    required={true}
                />
            </Grid>

            <Grid item xs={12} sx={{ mt: "1rem" }}>
                <VirusScanWrapperForResourceFile
                    id={`additional-file-upload-${index}`}
                    fileInput={additionalFile.file}
                    ref={ref}
                    required={true}
                    handleInputChange={(value) =>
                        handleOnChange("file", value, ref)
                    }
                    handleAdditionalFileDelete={handleAdditionalFileDelete}
                    handleKeepBoth={handleKeepBoth}
                    targetFolderName={S3_TARGET_FOLDER_NAME}
                    readOnly={readOnly}
                    isEditingRestricted={isEditingRestricted}
                    formError={formErrors?.file}
                />
            </Grid>

            <Grid item xs={12} sx={{ mt: "1rem", textAlign: "right" }}>
                <ActionButton
                    text="Delete"
                    isDelete
                    onClick={handleFieldDelete}
                    disabled={readOnly || isEditingRestricted}
                />
            </Grid>
        </Grid>
    );
};

const getInitialAdditionalFileObject = () => ({
    fileType: OTHER_SERVICE_FILE_TYPE,
    description: "",
    file: null,
});

export default memo(AdditionalFiles);
