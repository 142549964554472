import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { useEffect, useState } from 'react';

import ActionButton from './ActionButton';
import AlertInline from './AlertInline';
import Checkbox from './Checkbox';

/**
 * This provides two list selection boxes, "Available" and "Selected".
 * The user can move items between the two boxes by clicking left and right
 * arrow buttons.  The items displayed in the two list boxes are completely
 * customized via the passed-in arguments.
 *
 * @param {array{object)} leftList          set of all options; must contain
 *                                          at a minimum the attributes:
 *                                          'id' - unique identifier
 *                                          '<itemNameKey>' - value to display
 * @param {array{string)} rightList         set of already-selcted options; must
 *                                          contain the minimum attributes
 *                                          'id' and '<itemNameKey>'
 * @param (string}        itemNameKey       name of the attribute in leftList
 *                                          and rightList with the text to
 *                                          display to the user
 * @param (string}        transferListName  the name of the variable being
 *                                          updated
 * @param (string}        boxTitle          the name of what is being selected
 *                                          to put in the box titles of
 *                                          "Available <boxTitle>" and
 *                                          "Selected <boxTitle>"
 * @param (string}        errorText         validation errors
 * @param (function}      handleTransfer    callback when a checkbox is clicked
 * @param (boolean}       readOnly          true to disable checkbox selection
 */

export default function TransferList({
    leftList,
    rightList,
    itemNameKey,
    transferListName,
    boxTitle='Categories',
    errorText,
    handleTransfer,
    readOnly
}) {
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    useEffect(() => {
        setLeft(not(leftList, rightList));
        setRight(rightList);
    }, [leftList, rightList]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleCheckedRight = () => {
        let newRight = right.concat(leftChecked);
        setRight(newRight);
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));

        // Get the focus on the first checkbox to get the focus off the button,
        // otherwise the button retains the focus which is disconcerting
        if (leftChecked[0]) {
            try {
                document.getElementById(leftChecked[0].id).focus();
            }
            catch(err) {
                console.warn('document was slow to load', leftChecked[0]);
            }
        }
        if (handleTransfer) {
            handleTransfer(transferListName, newRight);
        };
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        let filteredRight = not(right, rightChecked)
        setRight(filteredRight);
        setChecked(not(checked, rightChecked));

        // Get the focus on the first checkbox to get the focus off the button,
        // otherwise the button retains the focus which is disconcerting
        if (rightChecked[0]) {
            try {
                document.getElementById(rightChecked[0].id).focus();
            }
            catch(err) {
                console.warn('document was slow to load', leftChecked[0]);
            }
        }
        if (handleTransfer) {
            handleTransfer(transferListName, filteredRight);
        };
    };

    const customList = (title, items, setId) => (
        <Card sx={{pb: '1rem'}} >
            <div className='text-center text-bold padding-2'>
                {title}
            </div>

            <Divider />

            {
                items && items.map((item, idx) => {
                    const tag = `${setId}-${idx}`;
                    return (
                        <Checkbox
                            id={tag}
                            key={tag}
                            name={tag}
                            label={item[itemNameKey]}
                            labelStyle='margin-left-2'
                            checked={checked.indexOf(item) !== -1}
                            onChange={handleToggle(item)}
                            disabled={readOnly}
                        />
                    )
                })
            }
        </Card>
    );

    const labelAvailable = 'Available ' + boxTitle;
    const labelSelected = 'Selected ' + boxTitle;
    const errorStyle = errorText ?
        'border-05 border-emergency padding-05' : '';

    return (
        <Grid container spacing={2}
            alignItems='start'
            justifyContent='space-between'
            className={errorStyle}
        >
            { /* Error message if the user didn't select any elements */ }
            { !!errorText &&
                <Grid item xs={12} >
                    <AlertInline errorMessage={errorText} />
                </Grid>
            }

            { /* Available items */ }
            <Grid item xs={12} sm={5}
                sx={{
                    paddingLeft: '0px !important',
                    paddingTop: '0px !important',
                    border: 1,
                }}
            >
                {customList(labelAvailable, left, 'available-list')}
            </Grid>

            { /* Buttons to move items between Available and Selected
               * There are 4 buttons here; only two will be dispalyed
               * based on the page width
               *
               * NARROW display - up and down arrows displayed horizontally
               */ }
            <Grid item xs={12}
                alignItems='center'
                sx={{
                    display: { xs: 'flex', sm: 'none' },
                    justifyContent: 'center',
                    margin: '1rem',
                }}
            >
                <ActionButton text='&darr;'
                    onClick={handleCheckedRight}
                    disabled={readOnly || leftChecked.length === 0}
                    aria-label='add to the selected list'
                />
                <ActionButton text='&uarr;'
                    onClick={handleCheckedLeft}
                    disabled={readOnly || rightChecked.length === 0}
                    aria-label='remove from the selected list'
                />
            </Grid>
            { /* NORMAL display - left and right arrows stacked vertically
               */ }
            <Grid container item xs={2}
                alignItems='center'
                direction={{
                    sm: 'column'
                }}
                sx={{
                    display: { xs: 'none', sm: 'flex' },
                    justifyContent: 'center',
                }}
            >
                <Grid item xs={12}
                    sx={{
                        marginTop: '2rem',
                        marginBottom: '1rem !important'
                    }}
                >
                    <ActionButton text='&gt;'
                        onClick={handleCheckedRight}
                        disabled={readOnly || leftChecked.length === 0}
                        aria-label='add to the selected list'
                    />
                </Grid>
                <Grid item xs={12}  >
                    <ActionButton text='&lt;'
                        onClick={handleCheckedLeft}
                        disabled={readOnly || rightChecked.length === 0}
                        aria-label='remove from the selected list'
                    />
                </Grid>
            </Grid>

            { /* Selected items */ }
            <Grid item xs={12} sm={5}
                sx={{
                    paddingLeft: '0px !important',
                    paddingTop: '0px !important',
                    border: 1,
                }}
            >
                {customList(labelSelected, right, 'selected-list')}
            </Grid>
        </Grid>
    );
};

const not = (a, b) => {
  return a.filter((aValue) => b.findIndex(bValue => bValue.id === aValue.id) === -1);
};

const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};
