import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import { Fragment, useCallback, useEffect, useMemo, useRef, useState
       } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { createServiceNews, createServiceVersion, deleteServiceVersion,
         getCompanyDetail, getServiceNewsNumLaunchedVersions,
         getServiceVersionDetail, updateServiceVersion
       } from '../../../apiClient';
import { setAlert } from '../../../app/slices/alert';
import { setLinks as setBreadcrumbs } from '../../../app/slices/breadcrumbs';
import { setPageTitle } from '../../../app/slices/page';
import { fetchCategories, selectCategories } from '../../../app/slices/search';
import { selectCompanyId } from '../../../app/slices/user';
import ActionButton from '../../../components/common/ActionButton';
import BodyText from '../../../components/common/BodyText';
import OkCancelDialog from '../../../components/common/OkCancelDialog';
import ProcessingOverlay from "../../../components/common/ProcessingOverlay";
import ServiceVersionReview from '../../../components/service-registration/ServiceVersionReview';
import { useAbortController } from '../../../hooks';
import { URL_ADMIN, URL_ADMIN_SERVICES, URL_HOME } from '../../../utils/navigation';
import { copyServiceVersionState, createServiceVersionRequestBody,
         getInitialServiceVersionState, getServicesPage,
         isVersionEditingRestricted, MANAGE_SERVICE_PAGE_ACTION, parseScopeStr,
         serviceVersionDetailToRegistrationInput,
         validateVersionAdminBasic, validateVersionApiSpec,
         validateVersionAuthentication, validateVersionDomain,
         validateVersionCore, validateVersionPerformance,
         SERVICE_NEWS_NEW_SERVICE_LAUNCHED,
         SERVICE_NEWS_NEW_SERVICE_VERSION_LAUNCHED,
         uploadResourceFiles,
         validateAdditionalFiles
       } from '../../../utils/serviceRegistration';
import { checkServiceType, ServiceTypeUtils, CANCEL_DELETE_TEXT, OK_DELETE_TEXT
       } from '../../../utils/utils';
import { isErrorObjectEmpty } from '../../../utils/validation';


/**
 * This provides the user interface for a user to enter a new version or
 * view, edit, or delete an existing version parameters for a registered
 * service.
 */

const ServiceVersionRegistration = ({ admin, versionId,
    action=MANAGE_SERVICE_PAGE_ACTION.create }) => {

    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [ searchParams, setSearchParams ] = useSearchParams();

    const formRef = useRef(null);

    const categories = useSelector(useCallback(selectCategories, []));
    const companyId = useSelector(useCallback(selectCompanyId, []));

    const [error, setError] = useState({
        message: '',
        show: false
    });

    const clearErrors = {
        versionAdminBasic: {},
        versionApiSpec: {},
        versionAuthentication: {},
        versionDomain: {},
        versionCore: {},
        versionPerformance: {},
        versionAdditionalFiles: {},
    };

    const [formErrors, setFormErrors] = useState({ ...clearErrors });

    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('');
    const [pageAction, setPageAction] = useState(action);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const deleteDialogRef = useRef(null);

    const serviceId = useMemo(() =>
              searchParams.get('serviceId'), [searchParams]);
    const serviceType = useMemo(() =>
              searchParams.get('serviceType'), [searchParams]);
    const serviceName = useMemo(() =>
              searchParams.get('serviceName'), [searchParams]);

    const isEditing = useMemo(() =>
              pageAction === MANAGE_SERVICE_PAGE_ACTION.edit, [pageAction]);
    const isCreating = useMemo(() =>
              pageAction === MANAGE_SERVICE_PAGE_ACTION.create, [pageAction]);
    const isViewing = useMemo(() =>
              pageAction === MANAGE_SERVICE_PAGE_ACTION.view, [pageAction]);
    const isCopying = useMemo(() =>
              pageAction === MANAGE_SERVICE_PAGE_ACTION.copy, [pageAction]);

    const pageTitle = useMemo(() => {
        let prefix = (admin ? '[Admin] ' : '');
        let title = ''
        switch (pageAction) {
            case MANAGE_SERVICE_PAGE_ACTION.create:
                title = `Create Version for: ${serviceName}`;
                break;
            case MANAGE_SERVICE_PAGE_ACTION.edit:
                title = `Edit Version for: ${serviceName}`;
                break;
            case MANAGE_SERVICE_PAGE_ACTION.view:
                title = `View Version for: ${serviceName}`;
                break;
            case MANAGE_SERVICE_PAGE_ACTION.copy:
                title = `Copy Version for: ${serviceName}`;
                break;
            case MANAGE_SERVICE_PAGE_ACTION.remove:
                title = `Delete Version for: ${serviceName}`;
                break;
            default:
                console.error(`Invalid page action: ${pageAction}`);
        }
        return prefix + title;
    }, [admin, pageAction, serviceName])


    // If serviceType is not known, the service version state is being loaded
    // from the API response.
    const [input, setInput] = useState(!!serviceType ?
        getInitialServiceVersionState(serviceName, serviceType) : {});
    const [origVersionStatus, setOrigVersionStatus] = useState(input.status);
    const [origIsBeta, setOrigIsBeta] = useState(input.is_beta);
    const [isEditingRestricted, setIsEditingRestricted] = useState(false);

    useEffect(() => {
        dispatch(setPageTitle(pageTitle));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageTitle])

    useEffect(() => {
        const localAction = searchParams.get('action');
        if (localAction) {
            setPageAction(localAction);
        }
    }, [searchParams]);

    useEffect(() => {
        if (pageAction === MANAGE_SERVICE_PAGE_ACTION.remove) {
             setShowDeleteConfirmation(true);
        }
    }, [pageAction]);

    useEffect(() => {
        window.scrollTo(0, 0);

        initBreadCrumbs(serviceType);
        dispatch(fetchCategories({ abortSignalRef }))
        .unwrap()
        .catch(err => {
            if (err === 'cancelled')
                return;

            console.error('Failed to get categories:', err);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get categories',
                severity: 'error'
            }));
        });

        if (!isCreating) {
            setLoading(true);
            setLoadingMsg('Loading version data...');
            getServiceVersionDetail({ service_id: serviceId,
                version_id: versionId, abortSignal: abortSignalRef?.current })
            .then(async (res) => {
                if (res?.data) {
                    let version = res.data;

                    // parse the scope and assign appropriate value to scope
                    // read_access, write_access, and stream_access variables
                    let scope = parseScopeStr(version.scope);
                    let versionCategories = version.category_ids ?
                           categories.filter(c =>
                               version.category_ids.includes(c.id)) : [];
                    let input = await serviceVersionDetailToRegistrationInput(
                        version,
                        scope,
                        versionCategories
                    );

                    if (isCopying) {
                        input = copyServiceVersionState(input);
                    }
                    setInput({ ...input });
                    setOrigVersionStatus(input.status);
                    setOrigIsBeta(input.is_beta);
                    setIsEditingRestricted(isVersionEditingRestricted(input));
                }

                setLoading(false);
                setLoadingMsg('');
            })
            .catch(err => {
                if (isCancel(err))
                    return;

                console.error(`failed to get service version detail for service ${serviceId} version ${versionId}:`, err.message);
                dispatch(setAlert({
                    show: true,
                    message: 'Failed to get service version detail',
                    severity: 'error'
                }));

                setLoading(false);
                setLoadingMsg('');
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Breadcrumbs should only be shown for admin
    const initBreadCrumbs = (serviceType) => {
        if (admin) {
            if (serviceType) {
                let breadcrumbs = [
                    { name: 'Home', href: URL_HOME },
                    { name: 'Admin', href: URL_ADMIN },
                    { name: `${ServiceTypeUtils.TYPE_TO_STRING[serviceType]} Services`, href: `${URL_ADMIN_SERVICES}/${ServiceTypeUtils.TYPE_TO_SERVICE_PAGE_URL[serviceType]}` },
                    { name: 'Service Version Registration', href: null },
                ];

                dispatch(setBreadcrumbs(breadcrumbs));
            }
        }
    };

    const handleInputChangeByNameAndValue = (name, value) => {
        setInput({
            ...input,
            [name]: value
        });
    };

    const handleInputChange = (e) => {
        handleInputChangeByNameAndValue(e.target.name, e.target.value);
    };

    const canProceed = (isSubmitting, requireApi) => {
        const validateInputs = (input) => {

            let valid = true;
            let versionAdminBasicErrors = validateVersionAdminBasic(input);
            valid = valid && isErrorObjectEmpty(versionAdminBasicErrors);

            let versionApiSpecErrors = validateVersionApiSpec(input.version_files.openapi_spec,
                requireApi);
            valid = valid && isErrorObjectEmpty(versionApiSpecErrors)

            let versionAuthenticationErrors = validateVersionAuthentication(
                serviceType, isSubmitting, input);
            valid = valid && isErrorObjectEmpty(versionAuthenticationErrors);

            let versionDomainErrors = validateVersionDomain(input);
            valid = valid && isErrorObjectEmpty(versionDomainErrors)

            let versionCoreErrors = validateVersionCore(isSubmitting, input);
            valid = valid && isErrorObjectEmpty(versionCoreErrors);

            let versionPerformanceErrors = validateVersionPerformance(input);
            valid = valid && isErrorObjectEmpty(versionPerformanceErrors);

            let versionAdditionalFilesErrors = validateAdditionalFiles(
                input.version_additional_files,
                requireApi
            );
            valid = valid && isErrorObjectEmpty(versionAdditionalFilesErrors)
            
            setFormErrors({
                versionAdminBasic: versionAdminBasicErrors,
                versionApiSpec: versionApiSpecErrors,
                versionAuthentication: versionAuthenticationErrors,
                versionDomain: versionDomainErrors,
                versionCore: versionCoreErrors,
                versionPerformance: versionPerformanceErrors,
                versionAdditionalFiles: versionAdditionalFilesErrors,
            })

            return valid;
        };

        let valid = validateInputs(input);

        setError({
            message: (valid ? '' : 'Invalid input fields detected'),
            show: !valid
        });
        return valid;
    };

    const updateSearchParams = (newParams) => {
        let ogParams = {}
        for (let [k, v] of searchParams.entries()) {
            ogParams = {
                ...ogParams,
                [k]: v
            }
        }
        setSearchParams({
            ...ogParams,
            ...newParams
        });
    };

    const handleSave = async (isSubmitting) => {
        // Is the API specificiation file required?
        const requireApi = isSubmitting &&
            (checkServiceType(serviceType, ServiceTypeUtils.TYPES.REST) ||
             checkServiceType(serviceType, ServiceTypeUtils.TYPES.STREAMING));
        if (canProceed(isSubmitting, requireApi)) {
            setFormErrors({ ...clearErrors });
            let alertErrorMsg = 'Failed to register the service version';
            try {
                setLoading(true);
                setLoadingMsg('Registering service version...');

                let providerId = input.provider_id || companyId;
                let res = await getCompanyDetail({ id: providerId,
                                    abortSignal: abortSignalRef?.current });
                let providerName = res?.data?.name;

                if (providerId && providerName) {
                    // If the status was already set, that status is retained.
                    // Otherwise, set the status based on whether the user is
                    // submitting or just saving the version.
                    let newStatus = !!input.status ? input.status :
                                      (isSubmitting ? 'submitted' : 'creating');

                    // This handles the case that a user started to create a
                    // version but chose to Save For Later so its status is
                    // 'creating', and now the user is editing it again and
                    // wants to submit it this time.    So in this case we want
                    // to override the previous 'creating' status and give it
                    // 'submitted' status.
                    if (isEditing && isSubmitting &&
                       (newStatus === 'creating')) {
                            newStatus = 'submitted'
                    }
                    let dataToSend = await createServiceVersionRequestBody(
                        input,
                        serviceId,
                        providerId,
                        newStatus,
                        providerName,
                        admin
                    );

                    if (isCreating || isCopying) {
                        res = await createServiceVersion({ body: dataToSend,
                                        abortSignal: abortSignalRef?.current });
                    } else {
                        delete dataToSend.service_id;
                        res = await updateServiceVersion({
                            service_id: serviceId,
                            version_id: versionId,
                            body: dataToSend,
                            abortSignal: abortSignalRef?.current
                        });

                        // Create service news for only fully launched versions.
                        if (
                            !input.is_beta &&
                            newStatus === "launched" &&
                            (newStatus !== origVersionStatus ||
                                input.is_beta !== origIsBeta)
                        ) {
                            res = await getServiceNewsNumLaunchedVersions({
                                            service_id: serviceId,
                                            abortSignal: abortSignalRef?.current
                                        });
                            const numLaunchedVersions =
                                res?.data?.num_launched_versions;
                            if (numLaunchedVersions) {

                                // If there is more than one launched version,
                                // then the event type is
                                // "new service version launched."
                                // Otherwise, the event type is the simpler
                                // "new service launched."
                                const eventId = numLaunchedVersions > 1 ?
                                    SERVICE_NEWS_NEW_SERVICE_VERSION_LAUNCHED :
                                    SERVICE_NEWS_NEW_SERVICE_LAUNCHED;
                                res = await createServiceNews({
                                    body: {
                                        provider_id: providerId,
                                        service_id: serviceId,
                                        version_id: versionId,
                                        event_id: eventId
                                    },
                                    abortSignal: abortSignalRef?.current
                                });
                            }
                            else {
                                // This should never happen
                                console.error(`launched service ${serviceId} has no launched versions`)
                            }
                        }

                    }
                    
                    // Upload the resource files for the service
                    await Promise.all(
                        uploadResourceFiles(
                            input.version_files,
                            input.version_additional_files,
                            setLoadingMsg,
                            abortSignalRef
                        )
                    ).catch((error) => {
                        alertErrorMsg = error.message;
                        throw error;
                    });

                    setLoading(false);
                    setLoadingMsg('');

                    dispatch(setAlert({
                        show: true,
                        message: 'Successfully registered the service version',
                        severity: 'success'
                    }));

                    goToServiceListPage();
                } else {
                    setLoading(false);
                    setLoadingMsg('');
                    console.error(`${alertErrorMsg}. Invalid providerId=${providerId} or providerMame=${providerName}`)
                    dispatch(setAlert({
                        show: true,
                        message: alertErrorMsg,
                        severity: 'error'
                    }));
                }
            } catch (error) {
                if (isCancel(error))
                    return;

                setLoading(false);
                setLoadingMsg('');

                console.error(`${alertErrorMsg}:`, error);
                dispatch(setAlert({
                    show: true,
                    message: alertErrorMsg,
                    severity: 'error'
                }));
            }
        }
    };

    const handleCancelDelete = () => {
        updateSearchParams({ action: MANAGE_SERVICE_PAGE_ACTION.edit })
        setShowDeleteConfirmation(false);
    };

    const handleCloseDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const goToServiceListPage = () => {
        navigate(getServicesPage(admin, input.service_type), { replace: true });
    };

    const handleDelete = async () => {
        handleCloseDeleteConfirmation();
        setLoading(true);
        setLoadingMsg('Deleting service version...');
        try {
            await deleteServiceVersion({
                service_id: serviceId,
                version_id: versionId,
                abortSignal: abortSignalRef?.current
            });
            dispatch(setAlert({
                show: true,
                message: 'Successfully deleted the service version',
                severity: 'success'
            }));
            setLoading(false);
            setLoadingMsg('');
            goToServiceListPage();

        } catch (err) {
            if (isCancel(err))
                return;

            console.error('failed to delete the service version:', err);
            dispatch(setAlert({
                show: true,
                message: 'Failed to delete the service version',
                severity: 'error'
            }));

            setLoading(false);
            setLoadingMsg('');
        }
    };

    return (
        <Fragment>
            <ProcessingOverlay open={loading} msg={loadingMsg} />

            <Card sx={{ p: '1rem' }}>
                <CardContent>
                    <form ref={formRef}>
                        <ServiceVersionReview
                            input={input}
                            setInput={setInput}
                            isEditingRestricted={isEditingRestricted}
                            handleInputChange={handleInputChange}
                            handleInputChangeByNameAndValue={handleInputChangeByNameAndValue}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                            readOnly={isViewing}
                            admin={admin}
                        />
                    </form>

                    <Grid
                        container
                        sx={{
                            mt: '2rem'
                        }}
                    >
                        {
                            error.show &&
                            <Grid item xs={12}>
                                <Alert
                                    severity='error'
                                    variant='filled'
                                    sx={{
                                        mb: '1rem'
                                    }}
                                >
                                    {error.message}
                                </Alert>
                            </Grid>
                        }
                        <Grid container item xs={12} spacing={2}>
                            <Grid
                                item
                                xs={12}
                                sm={3}
                                sx={{
                                    textAlign: { xs: 'end', sm: 'left' }
                                }}
                            >
                                {
                                    !isCreating && !isCopying &&
                                    <ActionButton text='Delete' isDelete
                                        onClick={() => { updateSearchParams({
                                                 action: MANAGE_SERVICE_PAGE_ACTION.remove
                                            })
                                        }}
                                        data-open-modal
                                        aria-controls={deleteDialogRef?.current?.modalId}
                                    />
                                }
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={9}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'end'
                                }}
                            >
                                <ActionButton text='Cancel'
                                    onClick={goToServiceListPage}
                                    buttonStyle='margin-left-2'
                                />
                                {
                                    isViewing &&
                                    <ActionButton text='Edit'
                                        onClick={() => { updateSearchParams({
                                                 action: MANAGE_SERVICE_PAGE_ACTION.edit})
                                        }}
                                        buttonStyle='margin-left-2'
                                    />
                                }
                                {
                                    (isCreating || isCopying ||
                                    (isEditing && input?.status === 'creating')) &&
                                    <ActionButton text='Save for Later'
                                        onClick={() => handleSave(false)}
                                        buttonStyle='margin-left-2'
                                    />
                                }
                                {
                                    (isCreating || isCopying || isEditing) &&
                                    <ActionButton text='Submit'
                                        onClick={() => handleSave(true)}
                                        buttonStyle='margin-left-2'
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {/* Dialog for the user to delete a service version.  It must be
                created even if not shown for the sake of the ref */}
            <OkCancelDialog
                id='confirm-service-version-deletion-dialog'
                open={!loading && showDeleteConfirmation}
                heading='Confirm Service Version Deletion'
                okText={OK_DELETE_TEXT}
                cancelText={CANCEL_DELETE_TEXT}
                handleOkOp={handleDelete}
                handleCancelOp={handleCancelDelete}
                dialog={deleteDialogRef}
            >
                {
                    !loading && showDeleteConfirmation &&
                    <>
                        <BodyText labelStyle='text-center margin-top-0' >
                            Are you sure you want to delete version <strong>{input.version}</strong>
                        </BodyText>
                        <BodyText labelStyle='text-center margin-top-1'>
                            from the service <strong>{serviceName}</strong>?
                        </BodyText>
                    </>
                }
            </OkCancelDialog>
        </Fragment>
    );
};

export default ServiceVersionRegistration;
