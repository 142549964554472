import Skeleton from '@mui/material/Skeleton';

import { Fragment, useEffect, useState } from 'react';

import SelectObject from './SelectObject';



/**
 * This provides a drop-down selector to select a company.
 *
 * @param {string}        companyLabel          label for the select control;
 *                                              default is "Company"
 * @param {array(object)} companyOptions        list of companies; each item
 *                                              must contain attributes
 *                                              "id" and "name"
 * @param {function}      handleCompanySelect   selection handler; note this
 *                                              should handle just the
 *                                              selected company, NOT the
 *                                              selection event
 * @param {object}        selectedCompany       company to select, if any
 * @param {boolean}       showAllOption         true to prepend <allOptionValue>
 *                                              to the head of companyOptions
 * @param {array(object)} allOptionValue        "all companies" option(s); each
 *                                              item must contain attributes
 *                                              "id" and "name"
 * @param {boolean}       disabled              true to disable the selector
 * @param {boolean}       loading               Indicates whether the companyOptions param
 *                                              is currenlty being loaded
 */
const CompanySelect = ({
    companyLabel='Company',
    companyOptions,
    handleCompanySelect,
    selectedCompany,
    showAllOption,
    allOptionValue=[ALL_COMPANIES_OPTION],
    disabled,
    loading
}) => {

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        setCompanies(companyOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyOptions]);

    return (
        <Fragment>
            {
                loading ? (
                    <Skeleton variant='rounded' height='3rem' />
                ) : (
                    <SelectObject
                        id='common-company-select'
                        name='company-select'
                        label={companyLabel}
                        labelStyle='margin-top-0'
                        value={selectedCompany}
                        itemList={showAllOption ? [...allOptionValue, ...companies] : companies}
                        idAttribute='id'
                        valueAttribute='name'
                        handleSelect={handleCompanySelect}
                        disabled={disabled}
                    />
                )
            }
        </Fragment>
    );
};

export const ALL_COMPANIES_ID = 'all-companies';

const ALL_COMPANIES_OPTION = { id: ALL_COMPANIES_ID, name: 'All Companies' };

export default CompanySelect;
