import { Hub } from 'aws-amplify';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './app/store';
import './assets/uswds/css/styles.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { configureAmplify } from './utils/amplify';
import { COMPANY_ROLE_NAME_TO_ROLE_ID } from './utils/companyRegistration';
import { URL_HOME } from './utils/navigation';
import { ServiceTypeUtils, cleanUpCatalog, initializeCatalog } from './utils/utils';
import About from './views/About';
import AsyncApiSpec from './views/AsyncApiSpec';
import CatalogSearch from './views/CatalogSearch';
import Faq from './views/Faq';
import Feedback from './views/Feedback';
import Home from './views/Home';
import OpenApiSpec from './views/OpenApiSpec';
import PlatformNews from './views/PlatformNews';
import Profile from './views/Profile';
import QualityOfService from './views/QualityOfService';
import RegisteredServices from './views/RegisteredServices';
import RequestAccess from './views/RequestAccess';
import ServiceDetail from './views/ServiceDetail';
import ServiceNews from './views/ServiceNews';
import Subscriptions from './views/Subscriptions';
import Support from './views/Support';
import TestAccountLogin from './views/TestAccountLogin';
import UserDashboard from './views/UserDashboard';
import UserGuides from './views/UserGuides';
import Welcome from './views/Welcome';
import AccessRoleDetail from './views/admin/AccessRoleDetail';
import AccessRoleList from './views/admin/AccessRoleList';
import Admin from './views/admin/Admin';
import CompanyDetail from './views/admin/CompanyDetail';
import CompanyList from './views/admin/CompanyList';
import PlatformNewsList from './views/admin/PlatformNewsList';
import ProviderMetricsService from './views/admin/ProviderMetricsService';
import RegisteredServicesAdmin from './views/admin/RegisteredServicesAdmin';
import UserDetail from './views/admin/UserDetail';
import UserList from './views/admin/UserList';
import AccessRequestDetail from './views/admin/access-request/AccessRequestDetail';
import AccessRequests from './views/admin/access-request/AccessRequests';
import DataTransferTabs from './views/admin/data-transfer/DataTransferTabs';
import PlatformReleaseDetail from './views/admin/news/PlatformReleaseDetail';
import PlatformReleaseNewsDetail from './views/admin/news/PlatformReleaseNewsDetail';
import PlatformReleaseNewsList from './views/admin/news/PlatformReleaseNewsList';
import PlatformSummaryCapabilityDetail from './views/admin/news/PlatformSummaryCapabilityDetail';
import PlatformSummaryCapabilityList from './views/admin/news/PlatformSummaryCapabilityList';
import PageNotFound from './views/errors/PageNotFound';
import PageLayout from './views/layouts/PageLayout';
import RequireAdmin from './views/require/RequireAdmin';
import RequireAuth from './views/require/RequireAuth';
import RequireCompanyRole from './views/require/RequireCompanyRole';
import RequireIvt from './views/require/RequireIvt';
import ManageMainService from './views/service/manage/ManageMainService';
import ManageServiceVersion from './views/service/manage/ManageServiceVersion';
import MainServiceRegistration from './views/service/registration/MainServiceRegistration';
import NotAuthorized from './views/errors/NotAuthorized';
import ServiceVersionRegistration from './views/service/registration/ServiceVersionRegistration';

// disable react dev tools in production build
if (process.env.NODE_ENV === 'production') {
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
    for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
      if (prop === "renderers") {
        // this line will remove the console error
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map()
      } else {
        // Replace all of its properties with a no-op function or a null value
        // depending on their types
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] =
          typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === "function"
            ? () => { }
            : null;
      }
    }
  }
};

// Configure Amplify
configureAmplify();

// Listens for Authentication event messages
// 1. set the user state for the current signed in user
// 2. reset the catalog UI state when user signs out
Hub.listen('auth', (data) => {
  switch (data.payload.event) {
    case 'signIn':
    case 'tokenRefresh':
    case 'configured':
      initializeCatalog();
      break;
    case 'oAuthSignOut':
    case 'signOut':
    case 'tokenRefresh_failure':
      cleanUpCatalog();
      break;
    default:
      break;
  }
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route
              element={<PageLayout />}
            >
              {/* Routes that does not require auth and IVT checks */}
              <Route path='about' element={<About />} />
              <Route path='welcome' element={<Welcome />} />
              <Route path='request/access' element={<RequestAccess />} />
              <Route path='test-account-login' element={<TestAccountLogin />} />

              {/* Routes that require auth and IVT checks */}
              <Route
                path={URL_HOME}
                element={
                  <RequireAuth>
                    <RequireIvt>
                    </RequireIvt>
                  </RequireAuth>
                }
              >
                <Route index element={<Home />} />

                <Route path='platform-news' element={<PlatformNews />} />
                <Route path='service-news' element={<ServiceNews />} />
                <Route path='support' element={<Support />} />
                <Route path='feedback' element={<Feedback />} />
                <Route path='profile' element={<Profile />} />
                <Route path='faq' element={<Faq />} />
                <Route path='user-guides' element={<UserGuides />} />

                <Route path='asyncapi' element={<AsyncApiSpec />} />
                <Route path='openapi' element={<OpenApiSpec />} />
                <Route path='search' element={<CatalogSearch />} />
                <Route path='subscriptions' element={<Subscriptions />} />

                <Route path='dashboard'>
                  <Route path='user' element={<UserDashboard />} />
                </Route>

                <Route path='manage/service'>
                  <Route path='version/:versionId' element={<ManageServiceVersion />} />
                  <Route path=':serviceId' element={<ManageMainService />} />
                </Route>

                <Route path='service'>
                  <Route path=':serviceId' element={<ServiceDetail />} />
                  {/* Quality of Service */}
                  <Route path='qos' element={<QualityOfService />} />
                  <Route path='version/register' element={<ServiceVersionRegistration />} />
                </Route>

                <Route path='services'>
                  <Route path='rest' element={<RegisteredServices />} />
                  <Route path='register'>
                    <Route
                      path='rest'
                      element={
                        <RequireCompanyRole
                          required_role_ids={[
                            COMPANY_ROLE_NAME_TO_ROLE_ID['ISA'],
                            COMPANY_ROLE_NAME_TO_ROLE_ID['Provider']
                          ]}
                        >
                          <MainServiceRegistration serviceType={ServiceTypeUtils.TYPES.REST} />
                        </RequireCompanyRole>
                      }
                    />
                    {/* uncomment the following line below to allow stream service creation for all users */}
                    {/* <Route path='streaming' element={<MainServiceRegistration serviceType='Streaming' />} /> */}
                  </Route>
                </Route>
              </Route>

              {/* Admin route */}
              <Route
                path='admin'
                element={<RequireAdmin />}
              >
                <Route index element={<Admin />} />
                <Route path='access-requests' element={<AccessRequests />} />
                <Route path='access-role' element={<AccessRoleDetail />} />
                <Route path='access-roles' element={<AccessRoleList />} />
                <Route path='companies' element={<CompanyList />} />
                <Route path='company' element={<CompanyDetail />} />
                <Route path='platform-news' element={<PlatformNewsList />} />
                <Route path='platform-release' element={<PlatformReleaseDetail />} />
                <Route path='platform-release-news' element={<PlatformReleaseNewsList />} />
                <Route path='platform-release-news-item' element={<PlatformReleaseNewsDetail />} />
                <Route path='platform-summary-capability' element={<PlatformSummaryCapabilityDetail />} />
                <Route path='platform-summary-capabilities' element={<PlatformSummaryCapabilityList />} />
                <Route path='user/:userId' element={<UserDetail />} />
                <Route path='users' element={<UserList />} />

                <Route path='manage'>
                  <Route path='access-request'>
                    <Route path=':requestId' element={<AccessRequestDetail />} />
                  </Route>

                  <Route path='service'>
                    <Route path=':serviceId' element={<ManageMainService admin={true} />} />
                    <Route path='version/:versionId' element={<ManageServiceVersion admin={true} />} />
                  </Route>
                </Route>

                <Route path='service/version/register' element={<ServiceVersionRegistration admin={true} />} />

                <Route path='service-provider-metrics' element={<ProviderMetricsService />} />

                <Route
                  path='services'
                >
                  <Route path={ServiceTypeUtils.TYPE_TO_SERVICE_PAGE_URL[ServiceTypeUtils.TYPES.REST]} element={
                    <RegisteredServicesAdmin serviceType={ServiceTypeUtils.TYPES.REST} />
                  } />
                  <Route path={ServiceTypeUtils.TYPE_TO_SERVICE_PAGE_URL[ServiceTypeUtils.TYPES.STREAMING]} element={
                    <RegisteredServicesAdmin serviceType={ServiceTypeUtils.TYPES.STREAMING} />
                  } />
                  <Route path={ServiceTypeUtils.TYPE_TO_SERVICE_PAGE_URL[ServiceTypeUtils.TYPES.SFTP]} element={
                    <RegisteredServicesAdmin serviceType={ServiceTypeUtils.TYPES.SFTP} />
                  } />
                  <Route path={ServiceTypeUtils.TYPE_TO_SERVICE_PAGE_URL[ServiceTypeUtils.TYPES.DATA_TRANSFER]} element={
                    <DataTransferTabs />
                  } />

                  {/* Only admin user can register streaming service and data transfer source for now */}
                  <Route path='register'>
                    <Route
                      path='streaming'
                      element={<MainServiceRegistration admin={true} serviceType={ServiceTypeUtils.TYPES.STREAMING} />}
                    />
                    <Route
                      path='data-transfer-source'
                      element={<MainServiceRegistration admin={true} serviceType={ServiceTypeUtils.TYPES.DATA_TRANSFER} />}
                    />
                    <Route
                      path='sftp'
                      element={<MainServiceRegistration admin={true} serviceType={ServiceTypeUtils.TYPES.SFTP} />}
                    />
                  </Route>
                </Route>

              </Route>

              {/* None of the routes match a URL */}
              <Route path='403-forbidden' element={<NotAuthorized />} />
              <Route path='*' element={<PageNotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
