import Grid from '@mui/material/Grid';

import MajorHeading from '../common/MajorHeading';
import BulletedList from './BulletedList';


/**
 * This provides the display of the bulleted list of highlights.
 *
 * @param {string}        highlightTitle      the title to display in the
 *                                            "highlights" block
 * @param {array(string}} highlightItems      content to display as a bulleted
 *                                            list in the "highlights" block
 * @param {string}        highlightKeyPrefix  prefix to use for each key in the
 *                                            list of highlightItems so that
 *                                            React doesn't complain
 */

const NewsHighlights = ({
    highlightTitle,
    highlightItems,
    highlightKeyPrefix
}) => (

    <Grid container item
        sx={{
            marginTop: '1rem',
            padding: '1rem',
            backgroundColor: '#e1e6f9', // USWDS indigo-cool-10
            width: '100%',
        }}
    >
        <Grid item xs={12}>
        <MajorHeading
            label={highlightTitle}
            labelStyle='margin-left-1 margin-top-1 margin-bottom-0'
        />
        </Grid>
        <Grid item xs={12} >
            <BulletedList
                listItems={highlightItems}
                listStyle='margin-top-1'
                keyPrefix={highlightKeyPrefix}
            />
        </Grid>
    </Grid>
);

export default NewsHighlights;
