import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  /**
   * states for admin/companies page
   */
  // collection of company data
  companies: [],

  // company action constants
  companyAction: {
    create: 'create',
    update: 'update',
    view: 'view',
    remove: 'remove'
  },

  /**
   * states for admin/services page
   */
  // rest services with is_launched = false for all companies
  restServicesNotLaunched: [],

  // rest services with is_launched = true for selected company
  restServicesLaunchedForCompany: [],

  // streaming services with is_launched = false for all companies
  streamingServicesNotLaunched: [],

  // streaming services with is_launched = true for selected company
  streamingServicesLaunchedForCompany: [],

  // dataTransfer services with is_launched = false for all companies
  dataTransferServicesNotLaunched: [],

  // dataTransfer services with is_launched = true for selected company
  dataTransferServicesLaunchedForCompany: [],

  // dataTransfer services with is_launched = false for all companies
  sftpServicesNotLaunched: [],

  // dataTransfer services with is_launched = true for selected company
  sftpServicesLaunchedForCompany: [],

  // data transfer subscriptions
  dataTransferSubscriptions: []
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setCompanies: (state, action) => {
      const { companies } = action.payload;
      if (companies) {
        state.companies = companies;
      }
    },
    setRestServicesNotLaunched: (state, action) => {
      state.restServicesNotLaunched = action.payload;
    },
    setRestServicesLaunchedForCompany: (state, action) => {
      state.restServicesLaunchedForCompany = action.payload;
    },
    setStreamingServicesNotLaunched: (state, action) => {
      state.streamingServicesNotLaunched = action.payload;
    },
    setStreamingServicesLaunchedForCompany: (state, action) => {
      state.streamingServicesLaunchedForCompany = action.payload;
    },
    setDataTransferServicesNotLaunched: (state, action) => {
      state.dataTransferServicesNotLaunched = action.payload;
    },
    setDataTransferServicesLaunchedForCompany: (state, action) => {
      state.dataTransferServicesLaunchedForCompany = action.payload;
    },
    setDataTransferSubscriptions: (state, action) => {
      state.dataTransferSubscriptions = action.payload;
    },
    setSftpServicesNotLaunched: (state, action) => {
      state.sftpServicesNotLaunched = action.payload;
    },
    setSftpServicesLaunchedForCompany: (state, action) => {
      state.sftpServicesLaunchedForCompany = action.payload;
    },
    updateServiceByServiceId: (state, action) => {
      let { whichService, serviceId, key, value } = action.payload;
      if (state[whichService]) {
        let idxToUpdate = state[whichService].findIndex(s => s.id === serviceId);
        if (idxToUpdate >= 0) {
          state[whichService][idxToUpdate][key] = value;
        } else {
          console.error('Failed to update the service. No service found with service ID:', serviceId);
        }
      } else {
        console.error('Failed to update the service. Invalid state name:', whichService);
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setCompanies,
  setRestServicesNotLaunched,
  setRestServicesLaunchedForCompany,
  setStreamingServicesNotLaunched,
  setStreamingServicesLaunchedForCompany,
  setDataTransferServicesNotLaunched,
  setDataTransferServicesLaunchedForCompany,
  setDataTransferSubscriptions,
  setSftpServicesNotLaunched,
  setSftpServicesLaunchedForCompany,
  updateServiceByServiceId
} = adminSlice.actions

export const selectCompanies = state => state.admin.companies;
export const selectCompanyAction = state => state.admin.companyAction;

export default adminSlice.reducer
