import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getServices } from '../apiClient';
import { setAlert } from '../app/slices/alert';
import { setLinks as setBreadcrumbs } from '../app/slices/breadcrumbs';

import { setPageTitle } from '../app/slices/page';
import { selectServices, setServices } from '../app/slices/registeredServices';
import { selectCompanyId } from '../app/slices/user';
import CircularProgressIndicator from '../components/common/CircularProgressIndicator';
import LinkButton from '../components/common/LinkButton';
import ServiceList from '../components/service-registration/ServiceList';
import { useAbortController } from '../hooks';
import { URL_ADMIN, URL_HOME, URL_ADMIN_MANAGE_SERVICE } from "../utils/navigation";

import { getServiceRegisterPage } from '../utils/serviceRegistration';
import { ServiceTypeUtils } from '../utils/utils';
import RequireCompanyRole from './require/RequireCompanyRole';
import { COMPANY_ROLE_NAME_TO_ROLE_ID } from '../utils/companyRegistration';
import { getServicesWithServiceLogoDataUrl } from '../utils/serviceDetail';

/**
 * This provides the list of services that the user has registered, and allows
 * the user access to edit them.
 */

const RegisteredServices = () => {

    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();

    const services = useSelector(selectServices);
    const companyId = useSelector(selectCompanyId);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        initBreadcrumbs();
        dispatch(setPageTitle('Registrations'));
        setLoading(true);
        getServices({
            provider_id: companyId,
            service_type: ServiceTypeUtils.TYPES.REST,
            abortSignal: abortSignalRef?.current
        })
        .then(async res => {
            let newServices = await getServicesWithServiceLogoDataUrl(res.data?.services ?? []);
            dispatch(setServices(newServices));
            setLoading(false);
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to get registered services:', err);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get registered services',
                severity: 'error'
            }));

            setLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initBreadcrumbs = () => {
        // This page can be access by both a regular user and by the admin.
        // If this page is being shown from the admin page tree, set up
        // breadcrumbs.
        if (window.location.pathname &&
            window.location.pathname.startsWith(URL_ADMIN))
        {
            const breadcrumbs = [
              { name: 'Home', href: URL_HOME },
              { name: 'Admin', href: URL_ADMIN },
              { name: `${ServiceTypeUtils.TYPE_TO_STRING[ServiceTypeUtils.TYPES.REST]} Services`, href: URL_ADMIN_MANAGE_SERVICE },
              { name: 'Service', href: null }
            ];

            dispatch(setBreadcrumbs(breadcrumbs));
        }
    };

    return (
        <Card id='registered-services' sx={{ p: '1rem' }}>
            <CardContent>
                {
                    loading &&
                    <CircularProgressIndicator
                        type='large' 
                        altText='Loading services...'
                    />
                }

                <RequireCompanyRole
                    required_role_ids={[
                        COMPANY_ROLE_NAME_TO_ROLE_ID['ISA'],
                        COMPANY_ROLE_NAME_TO_ROLE_ID['Provider']
                    ]}
                    redirect={false}
                >
                    <Box sx={{ textAlign: 'end' }}>
                        <LinkButton text='Register a Service'
                            href={getServiceRegisterPage(null,
                                    ServiceTypeUtils.TYPES.REST)}
                        />
                    </Box>
                </RequireCompanyRole>

                <ServiceList
                    services={services}
                    serviceType={ServiceTypeUtils.TYPES.REST}
                />
            </CardContent>
        </Card>
    );
};

export default RegisteredServices;
