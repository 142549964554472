import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { selectRerenderNavBar } from '../../app/slices/page';
import backgroundImgAbout from '../../assets/background/about.png';
import backgroundImgBrowse from '../../assets/background/browse.png';
import backgroundImgService from '../../assets/background/service.png';
import backgroundImgSubscription from '../../assets/background/subscription.png';
import backgroundImgWelcome from '../../assets/background/welcome.png';
import Alert from '../../components/common/Alert';
import CatalogBreadcrumbs from '../../components/common/CatalogBreadcrumbs';
import Footer from '../../components/common/Footer';
import HeaderBar from '../../components/common/HeaderBar';
import NavBar from '../../components/nav-bar/NavBar';
import { URL_ADMIN, URL_HOME } from '../../utils/navigation';
import { SkipNavContent, SkipNavLink } from './SkipNav';

/**
 * This provides the layout for all pages, including the main navbar,
 * the main header, optional breadcrumbs, the main content, and a footer.
 */

function PageLayout() {

    // RerenderNavar value is used to trigger a rerender of the navigation bar 
    // when toggled.  This trigger is activated when the user signs in or when 
    // the user session token is refreshed.
    const rerenderNavBar = useSelector(selectRerenderNavBar);

    const [backgroundImage, setBackgroundImage] = useState('');
    const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);

    // This element is used for the skip-nav functionality
    const mainContentRef = useRef(null);

    const location = useLocation();

    useEffect(() => {
        let backgroundImg = null;
        let pathname = location.pathname;
        if (pathname === URL_HOME) {
            backgroundImg = LOCATION_TO_BACKGROUND_IMAGE[pathname];
        } else {
            pathname = pathname.split('/')[1];
            backgroundImg = LOCATION_TO_BACKGROUND_IMAGE[pathname];
        }

        // using backgroundImgAbout if no image is found.
        setBackgroundImage(backgroundImg ?? backgroundImgAbout);

        // display breadcrumbs only on the admin pages
        setShowBreadcrumbs(location.pathname.startsWith(URL_ADMIN))

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <ThemeProvider theme={THEME}>
            <Box
                id='app'
                className='App'
                sx={{
                    bgcolor: 'background.main',
                    position: 'relative',
                    minHeight: '100vh',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed',
                }}
            >
                <SkipNavLink focusOnEl={mainContentRef} />

                <Alert id='app-alert' />

                <Fragment
                    key={!!rerenderNavBar}
                >
                    <NavBar
                        sx={{
                            py: '5px',
                            borderTop: 2,
                            borderBottom: 2,
                            borderColor: 'border.main',
                            display: {
                                xs: 'flex',
                            }
                        }}
                    />
                    <HeaderBar
                        sx={{
                            borderTop: 2,
                            borderBottom: 2,
                            borderColor: 'border.main',
                        }}
                    />
                </Fragment>

                {
                    showBreadcrumbs &&
                    <CatalogBreadcrumbs />
                }

                <Box
                    id='app-content'
                    sx={{
                        paddingTop: '2rem',
                        paddingLeft: '3rem',
                        paddingRight: '3rem',
                        paddingBottom: '6rem',
                    }}
                >

                    {/*
                        Outlet component renders the child routes.
                        The child components can access the outlet context
                        via useOutletContext() from react-router-dom.
                    */}
                    <SkipNavContent ref={mainContentRef} >
                        <Outlet />
                    </SkipNavContent >

                </Box>

                <Footer />

            </Box>
        </ThemeProvider>
    );
}

const LOCATION_TO_BACKGROUND_IMAGE = {
    '/': backgroundImgBrowse,
    'about': backgroundImgAbout,
    'search': backgroundImgBrowse,
    'services': backgroundImgService,
    'subscriptions': backgroundImgSubscription,
    'manage': backgroundImgService,
    'welcome': backgroundImgWelcome,
};

const THEME = createTheme({
    palette: {
        primary: {
            main: '#374274',
        },
        secondary: {
            main: '#005A9E',
        },
        background: {
            main: '#F0F1F5',
            subtitle: '#E3E3EB'
        },
        border: {
            main: '#2F96FF'
        },
        text: {
            grey: '#8A91A3'
        },
        subscribed: {
            main: '#276344',
            warn: '#A7580A'
        },
        backdropContrast: {
            main: '#e8eaf6'
        },
    },
});

export default PageLayout;
