import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isCancel } from "axios";
import semverLt from "semver/functions/lt";

import { getSubscriptions } from "../../apiClient";
import { getServiceLogoDataUrl } from '../../utils/serviceDetail';


const initialState = {
  // collection of subscriptions
  subscriptions: [],

  // whether or not the subscription is being fetched
  fetchingSubscriptions: false,
}

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
    }
  },
  extraReducers: builder => {
    // extra reducers for fetching subscriptions result
    builder
      .addCase(fetchSubscriptions.pending, (state, action) => {
          state.fetchingSubscriptions = true;
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
          state.fetchingSubscriptions = false;
          state.subscriptions = action.payload;
      })
      .addCase(fetchSubscriptions.rejected, (state, action) => {
          state.fetchingSubscriptions = false;
      });
  }
})

// Sends a post request to the subscription API and returns a Promise for the 
// caller to handle
export const fetchSubscriptions = createAsyncThunk(
  'search/fetchSubscriptions',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      // cancel the previous request
      if (payload?.abort) payload.abort();

      let consumer_id = payload?.companyId ?? undefined;

      const res = await getSubscriptions({ consumer_id, abortSignal: payload?.abortSignalRef?.current });

      let subscriptions = res.data.services ?? [];
      let newSubscriptions = [];

      /**
       * This mapping tracks the latest subscribed version ID for each service ID.
       * The subscribed version ID refers to the latest version the user is subscribed to, 
       * not the latest version available in platform:
       *  - key: the service ID
       *  - value: the latest subscribed version ID
       */
      let idToLtsSubscribedVersion = {};

      if (subscriptions.length > 0) {
        for (let s of subscriptions) {
          if (
              idToLtsSubscribedVersion[s.service_id] == null ||
              semverLt(
                  idToLtsSubscribedVersion[s.service_id],
                  s.version_id
              )
          ) {
              idToLtsSubscribedVersion[s.service_id] = s.version_id;
          }
        }
        
        /**
         * 1. Create a unique key for the subscription; this makes it easier for
         *    setting the unique key on display elements
         * 2. Generate service_log_data_url for the subscription to display in the service card.
         * 3. Calculate the subscribedLatest flag to indicate if the user is subscribed to the latest
         *    version of the service. Note that the latest_launched_version_id will be null if the service
         *    the user is subscribed to is reverted to a status other than "launched". In this scenario,
         *    set the subscribedLatest to true to prevent displaying the "new version available" message.
         */
        let serviceLogoDataUrls = payload.getLogo ? await Promise.all(subscriptions.map(s => getServiceLogoDataUrl(s))) : [];
        for (let [idx, s] of subscriptions.entries()) {
          newSubscriptions.push({
            ...s,
            key: `${s.provider_id}_${s.service_id}_${s.version_id}`,
            service_logo_data_url: serviceLogoDataUrls.length > idx ? serviceLogoDataUrls[idx] : null,
            subscribedLatest: s.latest_launched_version_id === null ? true : idToLtsSubscribedVersion[s.service_id] === s.latest_launched_version_id
          });
        }
        // sort by name field
        newSubscriptions.sort((a, b) => {
          return a?.service_name?.toLowerCase() > b?.service_name?.toLowerCase();
        });
      };

      return newSubscriptions;
    }
    catch (error) {
      if (isCancel(error))
        return rejectWithValue('cancelled');

      console.error('Failed to fetch subscriptions:', error);
      return rejectWithValue(error.message);
    }
  });


// Action creators are generated for each case reducer function
export const { setSubscriptions, setField } = subscriptionSlice.actions

export const selectSubscriptions = state => state.subscription.subscriptions;
export const selectFetchingSubscriptions = state => state.subscription.fetchingSubscriptions;
export const selectIdToLtsSubscribedVersion = state => state.subscription.idToLtsSubscribedVersion;

export default subscriptionSlice.reducer
