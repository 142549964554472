import { Card, CardContent, Container, Grid } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { createAccessRole, deleteAccessRoleDetail, getAccessRoleDetail,
         updateAccessRole } from "../../apiClient";
import { setAlert } from "../../app/slices/alert";
import { setLinks as setBreadcrumbs } from '../../app/slices/breadcrumbs';
import { setPageTitle } from '../../app/slices/page';
import ActionButton from "../../components/common/ActionButton";
import BodyText from "../../components/common/BodyText";
import LinkButton from "../../components/common/LinkButton";
import MajorHeading from "../../components/common/MajorHeading";
import OkCancelDialog from "../../components/common/OkCancelDialog";
import TextArea from "../../components/common/TextArea";
import TextField from "../../components/common/TextField";
import { useAbortController } from "../../hooks";
import { ACCESS_ROLE_PAGE_ACTION, validateAccessRole } from "../../utils/accessRole";
import { URL_ADMIN, URL_ADMIN_ACCESS_ROLES, URL_HOME } from "../../utils/navigation";
import { trimWhitespace, CANCEL_DELETE_TEXT, OK_DELETE_TEXT
       } from "../../utils/utils";
import { isErrorObjectEmpty } from "../../utils/validation";


// Breadcrumbs are only available for the Admin
const breadcrumbs = [
    { name: 'Home', href: URL_HOME },
    { name: 'Admin', href: URL_ADMIN },
    { name: 'Access Roles', href: URL_ADMIN_ACCESS_ROLES },
    { name: 'Access Role', href: null }
];

export default function AccessRoleDetail() {
    const { abortSignalRef, isCancel } = useAbortController();

    const formRef = useRef(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const [roleId, setRoleId] = useState(null);
    const [pageAction, setPageAction] = useState(ACCESS_ROLE_PAGE_ACTION.create); // action determined based on searchParams
    const [readonlyInputFields, setReadonlyInputFields] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const deleteDialogRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const isCreating = useMemo(() => pageAction === ACCESS_ROLE_PAGE_ACTION.create, [pageAction]);
    const isDeleting = useMemo(() => pageAction === ACCESS_ROLE_PAGE_ACTION.delete, [pageAction]);
    const isEditing = useMemo(() => pageAction === ACCESS_ROLE_PAGE_ACTION.update, [pageAction]);
    const isViewing = useMemo(() => pageAction === ACCESS_ROLE_PAGE_ACTION.view, [pageAction]);

    const [input, setInput] = useState({
        role: '',
        definition: ''
    });

    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPageAction(searchParams.get('action'));

        let roleId = searchParams.get('roleId')
        if (roleId !== null) {
            setRoleId(roleId);
            initRole(roleId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])

    useEffect(() => {
        setReadonlyInputFields(false);

        // Set the page title and read-only flag
        let title = '';
        switch (pageAction) {
            case ACCESS_ROLE_PAGE_ACTION.create:
                title = '[Admin] Create an Access Role';
                break;
            case ACCESS_ROLE_PAGE_ACTION.update:
                title = `[Admin] Edit Access Role: ${input.role}`;
                break;
            case ACCESS_ROLE_PAGE_ACTION.view:
                setReadonlyInputFields(true);
                title = `[Admin] View Access Role: ${input.role}`;
                break;
            case ACCESS_ROLE_PAGE_ACTION.delete:
                setReadonlyInputFields(true);
                setShowDeleteDialog(true);
                title = `[Admin] Delete Access Role: ${input.role}`;
                break;
            default:
                console.error(`Invalid page action: ${pageAction}`);
                break;
        };
        dispatch(setPageTitle(title));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageAction, input?.role])

    const initRole = (roleId) => {
        setLoading(true);
        getAccessRoleDetail({ id: roleId, abortSignal: abortSignalRef?.current })
        .then(res => {
            if (res.data) {
                setInput({
                    role: res.data.role,
                    definition: res.data.definition
                })
            }
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to get access role:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get access role',
                severity: 'error'
            }));
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    const canSubmit = () => {
        let rtn = false;
        // send a request if all required text fields are filled out
        let errors = validateAccessRole(input);
        setFormErrors(errors);

        rtn = formRef.current.reportValidity() && isErrorObjectEmpty(errors)

        return rtn;
    };

    const resetFormErrors = () => {
        setFormErrors({})
    }

    const navigateToAccessRoleListPage = () => {
        navigate(URL_ADMIN_ACCESS_ROLES);
    };

    const handleCancelDeleteDialog = () => {
        setShowDeleteDialog(false);
        updateSearchParams({ action: ACCESS_ROLE_PAGE_ACTION.view });
    };

    const handleDelete = () => {
        setShowDeleteDialog(false);
        setLoading(true);
        deleteAccessRoleDetail({
            id: roleId,
            abortSignal: abortSignalRef?.current
        })
        .then(() => {
            dispatch(setAlert({
                show: true,
                message: 'successfully deleted the access role',
                severity: 'success'
            }));
            setLoading(false);
            navigateToAccessRoleListPage();
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to delete the access role:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'failed to delete the access role',
                severity: 'error'
            }));

            setLoading(false);
        });
    }

    const handleSubmit = async () => {
        try {
            if (canSubmit()) {
                let body = { ...input };

                setLoading(true);
                // trim whitespace from all text input fields
                Object.entries(body).forEach(([k, v]) => {
                    body[k] = trimWhitespace(v);
                });

                switch (pageAction) {
                    case ACCESS_ROLE_PAGE_ACTION.create:
                        await createAccessRole({ body, abortSignal: abortSignalRef?.current });
                        dispatch(setAlert({
                            show: true,
                            message: 'successfully created a new access role',
                            severity: 'success'
                        }));
                        break;
                    case ACCESS_ROLE_PAGE_ACTION.update:
                        await updateAccessRole({ id: roleId, body, abortSignal: abortSignalRef?.current });
                        dispatch(setAlert({
                            show: true,
                            message: 'successfully updated the access role',
                            severity: 'success'
                        }));
                        break;
                    default:
                        console.error(`Invalid action type: ${pageAction}`)
                        break;
                }

                setLoading(false);
                resetFormErrors();
                navigateToAccessRoleListPage();
            }
        } catch (error) {
            if (isCancel(error))
                return

            console.error(`failed to ${pageAction} an access role:`, error.message);
            dispatch(setAlert({
                show: true,
                message: `failed to ${pageAction} an access role: ${error.response.data.error}`,
                severity: 'error'
            }));

            setLoading(false);

        }
    }

    const updateSearchParams = (newParams) => {
        let ogParams = {}
        for (let [k, v] of searchParams.entries()) {
            ogParams = {
                ...ogParams,
                [k]: v
            }
        }
        setSearchParams({
            ...ogParams,
            ...newParams
        });
    };

    return (
        <Container id='admin-access-role-detail' disableGutters>
            <Card sx={{ p: '1rem' }}>
                <CardContent>
                    <form ref={formRef}>
                        <MajorHeading label='Access Role' loading={loading} />
                        <TextField
                            id='access-role-name'
                            name='role'
                            label='Name'
                            labelStyle='margin-top-2 width-mobile-lg'
                            value={input.role}
                            required
                            disabled={readonlyInputFields}
                            onChange={(e) => handleInputChange(e)}
                            errorMessage={formErrors.role}
                        />
                        <TextArea
                            id='access-role-definition'
                            name='definition'
                            label='Definition'
                            labelStyle='margin-top-1'
                            value={input.definition}
                            required
                            disabled={readonlyInputFields}
                            onChange={(e) => handleInputChange(e)}
                            errorMessage={formErrors?.definition}
                        />

                        <Grid container item xs={12} spacing={2}
                            sx={{ mt: 2 }}
                        >
                            <Grid item
                                xs={12}
                                sm={3}
                                sx={{
                                    textAlign: { xs: 'end', sm: 'left' }
                                }}
                            >
                                {
                                    !isCreating &&
                                    <ActionButton text='Delete' isDelete
                                        onClick={() => {
                                            updateSearchParams({
                                                action: ACCESS_ROLE_PAGE_ACTION.delete
                                            })
                                        }}
                                    />
                                }
                            </Grid>
                            <Grid item
                                xs={12}
                                sm={9}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'end'
                                }}
                            >
                                <LinkButton text='Cancel'
                                    href={URL_ADMIN_ACCESS_ROLES}
                                    style={{ marginRight: '1rem' }}
                                />
                                {
                                    isCreating &&
                                    <ActionButton text='Create'
                                        onClick={() => handleSubmit()}
                                    />
                                }
                                {
                                    (isViewing || isDeleting) &&
                                    <ActionButton text='Edit'
                                        onClick={() => updateSearchParams({
                                            action: ACCESS_ROLE_PAGE_ACTION.update
                                        })
                                        }
                                    />
                                }
                                {
                                    isEditing &&
                                    <ActionButton text='Save'
                                        onClick={() => handleSubmit()}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>

            {/* Dialog for the user to delete a role.  It must be
                created even if not shown for the sake of the ref */}
            <OkCancelDialog
                id='confirm-role-deletion-dialog'
                open={showDeleteDialog}
                heading='Confirm Role Deletion'
                okText={OK_DELETE_TEXT}
                cancelText={CANCEL_DELETE_TEXT}
                handleOkOp={handleDelete}
                handleCancelOp={handleCancelDeleteDialog}
                dialogRef={deleteDialogRef}
            >
                <BodyText labelStyle='text-center'>
                    Are you sure you want to delete access role
                </BodyText>
                <BodyText labelStyle='text-center margin-top-1'>
                    <strong>{input.role}</strong>?
                </BodyText>
            </OkCancelDialog>
        </Container>
    )
}
