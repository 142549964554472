import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setPageTitle } from '../app/slices/page';
import BodyText from '../components/common/BodyText'
import MajorHeading from '../components/common/MajorHeading'
import StyledLink from '../components/common/StyledLink'

/**
 * This provides the display of the "Contact Us" email if the user has
 * questions.
 */

const Support = () => {

    const dispatch = useDispatch()

    const contactEmail = process.env.REACT_APP_SUPPORT_EMAIL;

    useEffect(() => {
        dispatch(setPageTitle('Support: Contact Us'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card id='support' sx={{ p: '1rem' }}>
            <CardContent
                sx={{
                    textAlign: 'center'
                }}
            >
                <MajorHeading label='Contact Us' />
                <BodyText labelStyle='margin-bottom-2'
                    label='To learn more about DIP or for other inquiries, please send an email to:'
                />
                <StyledLink href={`mailto:${contactEmail}`} >
                    {contactEmail}
                </StyledLink>

            </CardContent>
        </Card>
    )
};

export default Support;
