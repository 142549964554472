import { forwardRef } from 'react';

import './SkipNav.css';


/**
 * This provides the pair of components to implement a skip-nav feature.
 * The technique is documented here:  https://webaim.org/techniques/skipnav/
 */

/**
 * Renders a link that remains hidden until focused to skip to the main content.
 *
 * @param {string} label       text to display for the link; default is
 *                             "Skip to main content"
 * @param {string} contentId   anchor #id that the link will skip to when 
 *                             clicked
 * @param {object} focusOnEl   element from useRef() that will be focused on
 *                             when the link is clicked
 * @param {object} props       any additional properties to apply to the link
 */
export const SkipNavLink = ({ 
    label="Skip to main content", 
    contentId=DEFAULT_ID,
    focusOnEl=null, 
    ...props
} ) => {

    return (
        <a  className='skip-nav-link'
            href={`#${contentId}`}
            onClick={() => {
                focusOnEl?.current?.focus(); 
            }}
            {...props}
        >
            {label}
        </a>
    );
};

/**
 * Provides a 'div' wrapper around content that should obtain the focus when
 * the SkipNavLink is clicked.  Theoretically a "div" should not be receiving
 * the focus instead an actual element, but the content of our site is too 
 * extensive to add the specific element on each page which should receive
 * the focus.  So that can be a project for another day.
 *
 * @param {string} id       anchor id on the div that the link will skip to 
 *                          when clicked
 * @param {object} props    any additional properties to apply to the div
 */
export const SkipNavContent = forwardRef(
    (
        { 
            id=DEFAULT_ID,
            ...props 
        },
        ref
    ) => {

        return (
            <div
                ref={ref}
                id={id}
                tabIndex={-1} // <-- allow focus, but not be tab-able to
                {...props}
            />
        );
    }
);

const DEFAULT_ID='main-content';
