import HelpOutlineIcon from '@mui/icons-material/Help';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCompanies, getMetricServiceSummary, getServices } from '../apiClient';
import { setAlert } from '../app/slices/alert';
import { selectUserDashboardMetricSummary,
         selectUserDashboardServicesFilterList, setUserMetricSummary,
         setUserServicesFilterList } from '../app/slices/dashboard';
import { setPageTitle } from '../app/slices/page';
import { selectCompanyId } from '../app/slices/user';
import ActionButton from '../components/common/ActionButton';
import BodyText from '../components/common/BodyText';
import CompanySelect, { ALL_COMPANIES_ID } from '../components/common/CompanySelect';
import DataTable from '../components/common/DataTable';
import DipIconButton from '../components/common/DipIconButton';
import MajorHeading from '../components/common/MajorHeading';
import Modal from '../components/common/modal/Modal';
import NoMatchLabel from '../components/common/NoMatchLabel';
import { SELECT_ID, SELECT_TITLE }  from '../components/common/Select';
import ServiceSelect from '../components/common/ServiceSelect';
import StyledFilterChip from '../components/common/StyledFilterChip';
import ServiceMetrics from '../components/service-detail/ServiceMetrics';
import ServiceProviderMetrics from '../components/service-detail/ServiceProviderMetrics';
import DashboardSummaryHelpTable from '../components/service-metric/DashboardSummaryHelpTable';
import GraphTimeRangeSelect from '../components/service-metric/GraphTimeRangeSelect';
import GraphUnitSelect from '../components/service-metric/GraphUnitSelect';
import { useAbortController } from '../hooks';
import { formatMetrics, getTimeRangeFilterParams, TIME_RANGE_FILTER_OPTIONS,
         UNIT_FILTER_OPTIONS } from '../utils/serviceMetrics';

/**
 * This provides the display of the Dashboard, which is a table of the summary
 * metrics that the user is subscribed to.
 */

const UserDashboard = () => {
    // for the providing and subscribed services
    const { abortSignalRef, isCancel } = useAbortController();
    // for the metrics
    const {
        abortSignalRef: metricSummaryAbortSignalRef,
        abort: metricSummaryRequestAbort,
    } = useAbortController();

    const dispatch = useDispatch();

    // The list of all providers and the list of those available to select.
    // The providers already selected are in selectedProvidersFilter
    const [ allProviders, setAllProviders ] = useState([]);
    const [ selectableProviders, setSelectableProviders ] = useState([]);

    const companyId = useSelector(selectCompanyId);
    const metricSummary = useSelector(selectUserDashboardMetricSummary);
    const servicesFilterList = useSelector(selectUserDashboardServicesFilterList);

    const [filteredSummaryList, setFilteredSummaryList] = useState(null);
    const [loadingSummaryData, setLoadingSummaryData] = useState(true);
    const [loadingProviders, setLoadingProviders] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedProvidersFilter, setSelectedProvidersFilter] = useState([]);
    const [disableProvidersFilter, setDisableProvidersFilter] = useState(false);
    const [selectedServicesFilter, setSelectedServicesFilter] = useState(null);
    const [providingServiceIds, setProvidingServiceIds] = useState(new Set());
    const [subscribedServiceIds, setSubscribedServiceIds] = useState(new Set());
    const [selectedTimeRangeFilter, setSelectedTimeRangeFilter] = useState(TIME_RANGE_FILTER_OPTIONS[3]);
    const [selectedUnitFilter, setSelectedUnitFilter] = useState(UNIT_FILTER_OPTIONS[1]);
    const [graphsRerenderKey, setGraphsRerenderKey] = useState(false);
    const [openHelpDialog, setOpenHelpDialog] = useState(false);
    const helpModalRef = useRef(null);

    useEffect(() => {
        dispatch(setPageTitle("Dashboard"));
        initProviders();
        initProvidingAndSubscribedServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        initMetricSummaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTimeRangeFilter]);

    // when the unit filter changes, rerender the ServiceMetrics component
    useEffect(() => {
        if (selectedRow) {
            rerenderGraphs();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUnitFilter]);

    // close the graphs component if the selected service is not in the
    // filtered summary list anymore
    useEffect(() => {
        if (selectedRow) {
            let isSelectedServiceInFilteredData =
                filteredSummaryList?.findIndex(d => d.serviceid === selectedRow[SERVICE_IDENTIFIER_KEY]) !== -1;

            if (!isSelectedServiceInFilteredData) {
                setSelectedRow(null);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredSummaryList]);

    // The onChange event is not fired when the clear icon is clicked in the
    // service filter.  Therefore, look at the selectedServicesFilter value to
    // enable the providers filter
    useEffect(() => {
        if (selectedServicesFilter === null) {
            setDisableProvidersFilter(false);
        }
        getFilteredSummaryList(selectedProvidersFilter, selectedServicesFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metricSummary, selectedServicesFilter]);

    // Initialize the list of all providers
    const initProviders = async () => {
        setLoadingProviders(true);
        const providersOnly = true;
        getCompanies({ providersOnly, abortSignal: abortSignalRef?.current })
        .then(res => {
            let providers =
                (res.data?.companies ?? []).map(c => ({ id: c.id, name: c.name }));
            setAllProviders(providers);
            setSelectableProviders(providers);
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to get providers:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get providers',
                severity: 'error'
            }));
        })
        .finally(() => {
            setLoadingProviders(false);
        });
    }

    // Initializes the set of the services that this user
    // 1) provides and
    // 2) subscribes to
    // which is useful when filtering on "My Subscribed Services" and
    // "My Registered Services"
    const initProvidingAndSubscribedServices = async () => {
        try {
            let providingServicesPromise = getServices({
                provider_id: companyId,
                is_launched: true,
                abortSignal: abortSignalRef?.current
            });
            let subscribedServicesPromise = getServices({
                is_launched: true,
                consumer_id: companyId,
                abortSignal: abortSignalRef?.current
            });
            let [providingServicesRes, subscribedServicesRes] = await Promise.all([providingServicesPromise, subscribedServicesPromise]);

            setProvidingServiceIds(new Set((providingServicesRes.data?.services ?? []).map(s => s.id)));
            setSubscribedServiceIds(new Set((subscribedServicesRes.data?.services ?? []).map(s => s.id)));
        } catch (err) {
            if (isCancel(err)) {
                return;
            }
            console.error('failed to initialize providing and subscribed services:',
                err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to initialize providing and subscribed services',
                severity: 'error'
            }));
        }
    }

    // Initializes the set of metrics for all services at the current time
    // range.  Also sets the list of services to show in the Services filter
    // dropdown list.
    const initMetricSummaryData = async () => {
        try {
            // cancel the previous metric summary request
            metricSummaryRequestAbort();

            setLoadingSummaryData(true);
            let { startTime, endTime } =
                getTimeRangeFilterParams(selectedTimeRangeFilter);

            let metricSummaryRes = await getMetricServiceSummary({
                body: { start_time: startTime, end_time: endTime },
                abortSignal: metricSummaryAbortSignalRef?.current
            });
            
            let metricSummary = formatMetrics(
                metricSummaryRes.data?.metrics ?? [], SERVICE_IDENTIFIER_KEY);
            dispatch(setUserMetricSummary(metricSummary));
            dispatch(setUserServicesFilterList(metricSummary.map(s => ({
                [SERVICE_IDENTIFIER_KEY]: s[SERVICE_IDENTIFIER_KEY],
                service_name: s.servicename
            }))));

            setLoadingSummaryData(false);
        } catch (err) {
            if (isCancel(err)) {
                return;
            }

            console.error('failed to initialize metric summary data:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to initialize metric summary data',
                severity: 'error'
            }));

            setLoadingSummaryData(false);
        } finally {
            if (selectedRow) {
                rerenderGraphs();
            }
        }
    };

    const rerenderGraphs = () => {
        // changing the key of the component rerenders the component.
        setGraphsRerenderKey(!graphsRerenderKey);
    };

    const handleTableRowClick = (row) => {
        // if the same row is clicked again, close the metric graphs.
        if (selectedRow && selectedRow.serviceid === row.serviceid) {
            setSelectedRow(null);
        } else {
            setSelectedRow(row);
        }
    };

    // Called when the user selects a provider
    const handleProviderFilterSelect = (newSelectedProvider) => {

        // The user selected "all"
        if (newSelectedProvider.id === ALL_COMPANIES_ID) {

            // Reset the list of available-to-select providers
            setSelectableProviders([...allProviders]);

            // Clear the list of selected providers
            setSelectedProvidersFilter([]);

            // Filter the displayed data based on the provider selection
            getFilteredSummaryList(null, selectedServicesFilter,);
        }
        // The user selected a provider; ignore it if the user picked 'Select'
        else if (newSelectedProvider.id !== SELECT_ID) {

            // Add the provider to the selected list
            const providers = [ ...selectedProvidersFilter, newSelectedProvider ];
            setSelectedProvidersFilter(providers);

            // Remove the provider from the available-to-selected list
            setSelectableProviders(removeProvidersFromSelectableList(providers));

            // Filter the displayed data based on the updated provider selection
            getFilteredSummaryList(providers, selectedServicesFilter);
        }
    };

    // Called when the user clicks a filter chip to remove a provider
    const handleProviderFilterRemove = (removeProvider) => {

        // If this is the last selected item, just clear the list
        if (selectedProvidersFilter?.length === 1) {
            setSelectableProviders([...allProviders]);
            setSelectedProvidersFilter([]);
            getFilteredSummaryList(null, selectedServicesFilter);
        }
        // Otherwise, remove the provider from the selected list
        else if (selectedProvidersFilter?.length > 1) {
            const selected = selectedProvidersFilter.filter(c =>
                        c.id !== removeProvider.id);
            setSelectableProviders(removeProvidersFromSelectableList(selected));
            setSelectedProvidersFilter(selected);
            getFilteredSummaryList(selected, selectedServicesFilter,);
        }
    };

    // Utility to remove any selected providers from the list of all
    // providers, returning the list of providers available to select
    const removeProvidersFromSelectableList = (selectedProviders) => {
        let selectable = [ ...allProviders ];
        if (!!selectedProviders && !!selectedProviders.length) {
            selectable = selectable.filter(c =>
                {
                    const found = selectedProviders.find(s =>
                        s.id === c.id);
                    return !found;
                }
            );
        }
        return selectable;
    }

    const handleServiceFilterSelect = (newSelectedService) => {
        setSelectedServicesFilter(newSelectedService);
        getFilteredSummaryList(selectedProvidersFilter, newSelectedService);
    };

    const handleTimeRangeFilterSelect = (newSelectedTimeRange) => {
        setSelectedTimeRangeFilter(newSelectedTimeRange);
    };

    const handleUnitFilterSelect = (newSelectedUnit) => {
        setSelectedUnitFilter(newSelectedUnit);
    };

    const filterSummaryListByServiceId = (serviceId, summaryList) => {
        let rtn = summaryList;
        let disableProvidersFilter = false;
        switch (serviceId) {
            case SERVICE_FILTER_DEFAULT_OPTION_ID.ALL:
                break;
            case SERVICE_FILTER_DEFAULT_OPTION_ID.REGISTERED:
                // disable and clear the providers filter when the user chooses
                // "My Registered Services" option
                disableProvidersFilter = true;
                setSelectedProvidersFilter([]);
                rtn = summaryList.filter(s => providingServiceIds.has(s[SERVICE_IDENTIFIER_KEY]));
                break;
            case SERVICE_FILTER_DEFAULT_OPTION_ID.SUBSCRIBED:
                rtn = summaryList.filter(s => subscribedServiceIds.has(s[SERVICE_IDENTIFIER_KEY]));
                break;
            default:
                rtn = summaryList.filter(s => s[SERVICE_IDENTIFIER_KEY] === serviceId);
        }
        setDisableProvidersFilter(disableProvidersFilter);
        return rtn;
    }

    // Filters the full list of metrics by the provider filter selection and
    // the service filter selection.  The full list of metrics was retrieved
    // based on date range.
    const getFilteredSummaryList = (providerFilter, serviceFilter) => {
        let filteredList = metricSummary;
        if (metricSummary && metricSummary.length > 0) {
            // Filter by providers
            // Do not filter by providers if user chooses
            // "My Registered Services" option
            if ((serviceFilter?.[SERVICE_IDENTIFIER_KEY] !==
                    SERVICE_FILTER_DEFAULT_OPTION_ID.REGISTERED) &&
                providerFilter && (providerFilter.length > 0)) {

                let providerIds = providerFilter.map(p => p.id);
                filteredList =
                    filteredList.filter(m => providerIds.includes(m.provider_id))
            }

            // Filter by services
            if (serviceFilter && serviceFilter[SERVICE_IDENTIFIER_KEY]) {
                filteredList = filterSummaryListByServiceId(
                    serviceFilter[SERVICE_IDENTIFIER_KEY],
                    filteredList,
                );
            }
        }

        setFilteredSummaryList(filteredList);
    };

    const getHelpIcon = () => (
        <DipIconButton
            id='metrics-help-icon'
            aria-label='metrics help'
            onClick={(e) => setOpenHelpDialog(true)}
            data-open-modal
            aria-controls={helpModalRef?.current?.modalId}
        >
            <HelpOutlineIcon />
        </DipIconButton>
    );

    return (
        <Fragment>
            <Card>
                <CardContent>
                    <Grid
                        id='user-dashboard'
                        container
                        spacing={2}
                    >
                        <Grid container item xs={12}>
                            <Grid item xs={12} sm={6}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <MajorHeading
                                    label="Summary of Metrics"
                                    labelStyle='margin-right-1'
                                    loading={loadingSummaryData}
                                    adjoiningComponent={getHelpIcon()}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    alignItems: 'center'
                                }}
                            >
                                {
                                    !loadingSummaryData &&
                                    <BodyText
                                        label='Select a row to display service graphics below this table'
                                        labelStyle='text-italic margin-top-05'
                                    />
                                }
                            </Grid>
                        </Grid>

                        {/* Filters */}
                        <Grid container item spacing={1}>
                            <Grid item xs={6} sm={3}>
                                <CompanySelect
                                    companyLabel='Providers'
                                    companyOptions={selectableProviders}
                                    handleCompanySelect={handleProviderFilterSelect}
                                    showAllOption={true}
                                    allOptionValue={PROVIDER_ALL_OPTION}
                                    disabled={!allProviders || !allProviders.length || disableProvidersFilter}
                                    loading={loadingProviders}
                                />
                                {
                                    selectedProvidersFilter &&
                                    selectedProvidersFilter.map(company =>
                                        <StyledFilterChip
                                            label={`${company.name} X`}
                                            key={company.id}
                                            onClick={() =>
                                                handleProviderFilterRemove(company)
                                            }
                                            sx={{ mt: '.4rem' }}
                                        />
                                    )
                                }
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <ServiceSelect
                                    serviceOptions={servicesFilterList}
                                    handleServiceSelect={handleServiceFilterSelect}
                                    selectedService={selectedServicesFilter}
                                    showAllOption={true}
                                    allOptionValue={SERVICE_FILTER_ALL_OPTION_VALUE}
                                    serviceLabel='Services'
                                    serviceIdAttribute={SERVICE_IDENTIFIER_KEY}
                                    serviceValueAttribute='service_name'
                                    disabled={loadingSummaryData}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <GraphTimeRangeSelect
                                    handleTimeRangeSelect={handleTimeRangeFilterSelect}
                                    selectedTimeRange={selectedTimeRangeFilter}
                                    disabled={loadingSummaryData}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <GraphUnitSelect
                                    handleUnitSelect={handleUnitFilterSelect}
                                    selectedUnit={selectedUnitFilter}
                                    supportedUnitIds={selectedTimeRangeFilter.supportedUnitIds}
                                    disabled={loadingSummaryData}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            {
                                !filteredSummaryList?.length ? (
                                    <NoMatchLabel
                                        label='No matching services were found'
                                    />
                                ) : (
                                    <DataTable
                                        id='dashboard-table'
                                        columns={TABLE_COLUMNS}
                                        rows={filteredSummaryList}
                                        defaultRowsPerPage={5}
                                        handleRowClick={handleTableRowClick}
                                        enableFilter={false}
                                        dense={true}
                                        rowIdentifier={SERVICE_IDENTIFIER_KEY}
                                        showLoadingIcon={loadingSummaryData}
                                        defaultOrderBy='servicename'
                                    />
                                )
                            }
                        </Grid>

                        {
                            !!selectedRow && (
                                <Fragment>
                                    <Grid container item xs={12} sx={{ mt: '1rem' }}>
                                        <Grid
                                            item
                                            xs={12}
                                            md={9}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <MajorHeading
                                                label={`Selected: ${selectedRow.provider_name} - ${selectedRow.servicename}`}
                                                labelStyle='margin-y-0'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={3}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'end',
                                                mt: {
                                                    xs: '1rem',
                                                    md: 0
                                                }
                                            }}
                                        >
                                            <ActionButton text='Close'
                                                onClick={() => setSelectedRow(null)}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item key={graphsRerenderKey}>
                                        <ServiceMetrics
                                            serviceId={selectedRow[SERVICE_IDENTIFIER_KEY]}
                                            versionId={selectedRow[SERVICE_VERSION_KEY]}
                                            metricTimeRange={selectedTimeRangeFilter}
                                            metricUnit={selectedUnitFilter}
                                            dynamicUpdate={false}
                                            loading={loadingSummaryData}
                                        />

                                        <ServiceProviderMetrics
                                            serviceId={selectedRow[SERVICE_IDENTIFIER_KEY]}
                                            versionId={selectedRow[SERVICE_VERSION_KEY]}
                                            metricTimeRange={selectedTimeRangeFilter}
                                            metricUnit={selectedUnitFilter}
                                            dynamicUpdate={false}
                                            loading={loadingSummaryData}
                                        />
                                    </Grid>
                                </Fragment>
                            )
                        }
                    </Grid>
                </CardContent>
            </Card>

            {/* Help dialog.  We need to create the dialog for the sake of the
                ref but don't create the contents if the dialog is closed
             */}
            <Modal
                id='dashboard-metrics-help'
                isOpen={openHelpDialog}
                heading='Metric Definitions'
                handleClose={(e) => setOpenHelpDialog(false)}
                isLarge
                ref={helpModalRef}
            >
                {
                    openHelpDialog &&
                    <DashboardSummaryHelpTable />
                }
            </Modal>
        </Fragment>
    );
};

const PROVIDER_ALL_OPTION = [
    {
        id: SELECT_ID,
        name: SELECT_TITLE
    },
    {
        id: ALL_COMPANIES_ID,
        name: 'All Providers'
    }
];

const TABLE_COLUMNS = [
    {
        header: 'Name',
        accessor: 'service_name_w_version',
        enableSort: true
    },
    {
        header: 'Provider',
        accessor: 'provider_name',
        enableSort: true
    },
    {
        header: 'No. Calls',
        accessor: 'total_calls',
        enableSort: true,
        align: 'right'
    },
    {
        header: 'Availability',
        accessor: 'availability',
        enableSort: true,
        align: 'right'
    },
    {
        header: 'No. 500 Errors',
        accessor: 'fivehundred_errors',
        enableSort: true,
        align: 'right'
    },
    {
        header: 'No. 400 Errors',
        accessor: 'fourhundred_errors',
        enableSort: true,
        align: 'right'
    },
    {
        header: 'Mean Total Response Time',
        accessor: 'avg_response_latency',
        enableSort: true,
        align: 'right'
    },
    {
        header: 'Mean Platform Latency',
        accessor: 'avg_dip_latency',
        enableSort: true,
        align: 'right'
    },
    {
        header: 'Mean Service Processing Time',
        accessor: 'avg_integration_latency',
        enableSort: true,
        align: 'right'
    },
    {
        header: 'Mean Payload Throughput',
        accessor: 'avg_response_length',
        enableSort: true,
        align: 'right'
    },
    {
        header: 'Sum of Data Usage',
        accessor: 'sum_response_length',
        enableSort: true,
        align: 'right'
    },
    {
        header: 'Provider Dynamic Metrics?',
        accessor: 'provider_metric_exists',
        enableSort: true,
        formatter: (value) => String(value) === 'true' ? 'YES' : 'NO'
    }
];

const SERVICE_FILTER_DEFAULT_OPTION_ID = {
    ALL: 'all_services',
    REGISTERED: 'registered_services',
    SUBSCRIBED: 'subscribed_services'
};

const SERVICE_IDENTIFIER_KEY = 'serviceid';
const SERVICE_VERSION_KEY = 'serviceversion';

const SERVICE_FILTER_ALL_OPTION_VALUE = [
    {
        [SERVICE_IDENTIFIER_KEY]: SERVICE_FILTER_DEFAULT_OPTION_ID.ALL,
        service_name: 'All Services'
    },
    {
        [SERVICE_IDENTIFIER_KEY]: SERVICE_FILTER_DEFAULT_OPTION_ID.SUBSCRIBED,
        service_name: 'My Subscribed Services'
    },
    {
        [SERVICE_IDENTIFIER_KEY]: SERVICE_FILTER_DEFAULT_OPTION_ID.REGISTERED,
        service_name: 'My Registered Services'
    }
];

export default UserDashboard;
