import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { markNewsRead } from '../apiClient';
import { setAlert } from '../app/slices/alert';
import { setPageTitle } from '../app/slices/page';
import { fetchPlatformSummaryOfCapabilities, fetchPlatformReleases, 
         selectFetchingPlatformReleases, 
         selectFetchingPlatformSummaryOfCapabilities,
         selectPlatformReleases, selectPlatformSummaryOfCapabilities,
         setUnreadPlatformNews 
       } from '../app/slices/news';
import { selectUserId } from '../app/slices/user';
import { useAbortController } from '../hooks';
import platformNewsImg from '../assets/news/platform_news.png';
import CircularProgressIndicator from '../components/common/CircularProgressIndicator';
import MinorHeading from '../components/common/MinorHeading';
import BulletedList from '../components/news/BulletedList';
import NewsPage from '../components/news/NewsPage';
import { extractReleaseNews, extractSummaryDescription, PLATFORM_SUMMARY_TITLE 
       } from "../utils/platformNews";
import { getFormattedDateFromString } from "../utils/timeUtils";
import { paginate } from '../utils/utils';


/**
 * This provides the "Platform News" page, which provides an overview of each
 * release of the Platform.
 */
function PlatformNews() {

    const dispatch = useDispatch();

    const { abortSignalRef, isCancel } = useAbortController();

    const userId = useSelector(selectUserId);

    const fetchingSummary = useSelector(
        selectFetchingPlatformSummaryOfCapabilities);
    const platformSummary = useSelector(selectPlatformSummaryOfCapabilities);

    const fetchingReleases = useSelector(selectFetchingPlatformReleases);
    const platformReleases = useSelector(selectPlatformReleases);
    const [displayedPlatformReleases, setDisplayedPlatformReleases] = 
               useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [numItemsPerPage, setNumOfItemsPerPage] = useState(5);

    useEffect(() => {
        dispatch(setPageTitle('Platform News'));
        setNewsRead();
        initSummaryOfCapabilities();
        initReleases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Sets the status that the news has been read.
    const setNewsRead = async () => {
        try {
            // Update the back end
            markNewsRead({userId, newsType:"platform", abortSignalRef});

            // Update the front end
            dispatch(setUnreadPlatformNews(false));
        } catch (err) {
            if (isCancel(err)) {
                return;
            }
            console.error('failed to set platform news read status:', 
                err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to set platform news read status',
                severity: 'error'
            }));
        }
    };

    useEffect(() => {
        setDisplayedPlatformReleases(paginate(platformReleases?.slice(1), 
            numItemsPerPage, currentPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [platformReleases, currentPage, numItemsPerPage]);

    // Initialize the information displayed in the summary block at the top
    const initSummaryOfCapabilities = () => {
        dispatch(fetchPlatformSummaryOfCapabilities({
            abortSignalRef: abortSignalRef
        }))
        .unwrap()
        .catch(err => {
            if (err === 'cancelled')
                return;
    
            console.error('Failed to get the platform summary of capabilities', err);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get the platform summary of capabilities',
                severity: 'error'
            }));
        });
    };

    // Initialize the releases
    const initReleases = () => {
        dispatch(fetchPlatformReleases({
            abortSignalRef: abortSignalRef
        }))
        .unwrap()
        .catch(err => {
            if (err === 'cancelled')
                return;
    
            console.error('Failed to get the platform releases', err);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get the platform releases',
                severity: 'error'
            }));
        });
    };

    // For pagination of the earlier releases
    const setPageFromPagination = (zeroBasedPage) => {
        setCurrentPage(zeroBasedPage + 1);
    }

    // For pagination of the earlier releases
    const setRowsPerPageFromPagination = (rowsPerPage) => {
        setCurrentPage(1);
        setNumOfItemsPerPage(rowsPerPage);                                          }

    /**
     * Gets the JSX for the latest release block.
     */
    const getLatestReleaseBlock = () => {
        if (fetchingReleases || !platformReleases || !platformReleases?.length) {
            return <CircularProgressIndicator altText='Loading latest news...' />;
        }

        return (
            <Release release={platformReleases?.at(0)} />
        );
    }

    /**
     * Gets the JSX for the previous releases block.
     */
    const getPreviousReleasesBlock = () => {
        if (fetchingReleases || !displayedPlatformReleases || !displayedPlatformReleases?.length) {
            return <CircularProgressIndicator altText='Loading previous news...' />;
        }

        return (
            <>
                {
                    displayedPlatformReleases?.map((release, idx) => (
                        <Release key={`release-${idx}`}
                            release={release}
                        />
                    ))
                }
            </>
        );
    };

    const summary = extractSummaryDescription(platformSummary);

    return (
        <NewsPage
            newsImg={platformNewsImg}
            loading={fetchingSummary || fetchingReleases || !displayedPlatformReleases || !displayedPlatformReleases?.length}
            highlightTitle={PLATFORM_SUMMARY_TITLE}
            highlightItems={summary}
            highlightKeyPrefix='summary'
            latestTitle='Latest Release'
            latestContent={getLatestReleaseBlock()}
            earlierTitle='Previous Releases'
            earlierContent={getPreviousReleasesBlock()}
            initialRowsPerPage={numItemsPerPage}
            page={currentPage - 1}
            pageCallback={setPageFromPagination}
            rowsPerPageCallback={setRowsPerPageFromPagination}
            numRows={!!platformReleases ? platformReleases.length - 1 : 0}
            units='Releases'
        />
    );
};

/**
 * Formats the display block for a single release.
 *
 * @param {object} release                      information about the release 
 *                                              with attributes:
 *        {string} release.version              version in the form of "x.y.z"
 *        {string} release.timestamp            deployment date 
 *        {array(string)} release.release_news  release news to display in
 *                                              a bulletted list
 */
const Release = ({release}) => {
    if (!!release) {
        const news = extractReleaseNews(release.release_news);
        const date = getFormattedDateFromString(release.timestamp, 
                         "MMMM DD, YYYY");
        return (
            <Grid container item xs={12}
                sx={{
                    width: '100%'
                }}
            >
                <ReleaseHalfHeading halfHeading={release.release} />
                <ReleaseHalfHeading halfHeading={date} />
                <Grid item xs={12}>
                    <BulletedList listItems={news}
                        keyPrefix={release.release}
                    />
                </Grid>
            </Grid>
        );
    } else {
        return null;
    }
};

/**
 * Formats half of the heading of the release block.  Two of these are expected
 * to be displayed side-by-side.
 *
 * @param {string} halfHeading   what to display
 */
const ReleaseHalfHeading = ({halfHeading}) => {
    return (
        <Grid item xs={6}
            sx={{
                marginTop: '1.5rem',
                borderTop: 2,
                width: '100%'
            }}
        >
            <MinorHeading
                label={halfHeading}
                labelStyle='margin-left-1 margin-top-1 margin-bottom-0'
            />
        </Grid>
    );
};


export default PlatformNews;
