import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isCancel } from "axios";
import { getAccessRoles } from '../../apiClient';

const initialState = {
  // all company roles
  roles: [],

  // company roles for the service
  rolesForService: [],

  // whether or not roles are being fetched
  fetchingRoles: false,
}

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
  },
  extraReducers: builder => {
    // extra reducers for fetching roles
    builder
      .addCase(fetchRoles.pending, (state, action) => {
        state.fetchingRoles = true;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.fetchingRoles = false;
        state.roles = action.payload.roles;
        // the company roles for the service should not contain the 'Provider' role.
        state.rolesForService = action.payload.roles.filter(roleDetail => roleDetail.role !== 'Provider');
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.fetchingRoles = false;
      });
  }
})

// Send a get request to the company roles API and returns a Promise for the
// caller to handle
export const fetchRoles = createAsyncThunk(
    'company/fetchRoles',
    async (payload, { rejectWithValue }) => {
      try {
        const res = await getAccessRoles({ abortSignal: payload?.abortSignalRef?.current });
        return res.data;
      }
      catch (error) {
        if (isCancel(error))
          return rejectWithValue('cancelled');
  
        console.error('Failed to get company roles', error.message);
        return rejectWithValue(error.response.data);
      }
    });

// Action creators are generated for each case reducer function
export const { setRoles } = companySlice.actions

export const selectRoles = state => state.company.roles;
export const selectRolesForService = state => state.company.rolesForService;
export const selectFetchingRoles = state => state.company.fetchingRoles;

export default companySlice.reducer;