import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import SectionHeader from "../common/SectionHeader";
import ProviderMetrics from './ProviderMetrics';
import QualityOfServiceHelp from './QualityOfServiceHelp';
import QualityOfServiceMetrics from './QualityOfServiceMetrics';

/**
 * This provides a user interface for a service provider to enter the
 * performance metrics when registering a new service or editing a service
 * registration:
 * - quality of service help
 * - quality of service metrics
 * - additional performance metrics
 */
const WizardVersionPerformance = ({
    input,
    handleInputChange,
    handleInputChangeByNameAndValue,
    readOnly,
    formErrors,
    ...props
}) => {
    return (
      <>
        <SectionHeader
            title='Service Performance'
            labelStyle='margin-top-4'
        />
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <QualityOfServiceHelp />
                <Box
                    sx={{
                        pt: '1rem'
                    }}
                >
                    <QualityOfServiceMetrics
                        inputAttributeName='quality_of_service'
                        input={input}
                        readOnly={readOnly}
                        handleInputChangeByNameAndValue={handleInputChangeByNameAndValue} />
                </Box>
            </Grid>

            <Grid item xs={12}>
                <ProviderMetrics
                    input={input}
                    readOnly={readOnly}
                    handleInputChange={handleInputChange}
                    formErrors={formErrors} />
            </Grid>

            {props.children}

        </Grid>
      </>
    );
};

export default WizardVersionPerformance;
