import { Box, Card, CardActions, CardContent, Divider, Grid } from "@mui/material";

import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { createDipUser, getAccessRequestDetail, getCompanies, updateAccessRequestStatus } from "../../../apiClient";
import { selectCompanyAction } from "../../../app/slices/admin";
import { setAlert } from "../../../app/slices/alert";
import { setLinks as setBreadcrumbs } from '../../../app/slices/breadcrumbs';
import { setPageTitle } from "../../../app/slices/page";
import ActionButton from "../../../components/common/ActionButton";
import BodyTitle from "../../../components/common/BodyTitle";
import ProcessingOverlay from "../../../components/common/ProcessingOverlay";
import RadioButtonGroup from "../../../components/common/RadioButtonGroup";
import TextArea from "../../../components/common/TextArea";
import TextField from "../../../components/common/TextField";
import AccessPurposeCheckboxes from "../../../components/request-access/AccessPurposeCheckboxes";
import { useAbortController } from "../../../hooks";
import { getCompanyDetailLink } from "../../../utils/companyRegistration";
import { URL_ADMIN, URL_ADMIN_ACCESS_REQUESTS, URL_HOME } from "../../../utils/navigation";
import { DEFAULT_ACCESS_REQUEST_INPUT, FIRST_TIME_USER_TEAM_ID,
         JUSTIFICATION_FAMILIARITY_ID_MAP, deserializeAccessRequestDetail
       } from "../../../utils/requestAccess";

// Breadcrumbs are only available for the Admin
const breadcrumbs = [
    { name: 'Home', href: URL_HOME },
    { name: 'Admin', href: URL_ADMIN },
    { name: 'Access Requests', href: URL_ADMIN_ACCESS_REQUESTS },
    { name: 'Access Request Detail', href: null }
];

const AccessRequestDetail = () => {

    const { requestId } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const companyAction = useSelector(selectCompanyAction);

    const formRef = useRef(null);

    const { abortSignalRef, isCancel } = useAbortController();

    const [input, setInput] = useState({ ...DEFAULT_ACCESS_REQUEST_INPUT });
    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('');
    const [selectedTeamId, setSelectedTeamId] = useState(FIRST_TIME_USER_TEAM_ID);
    const [matchingTeams, setMatchingTeams] = useState([]);

    const isApproved = useMemo(() => input?.status === 'approved', [input]);

    useEffect(() => {
        dispatch(setPageTitle('Admin: DIP Access Request Account'));

        dispatch(setBreadcrumbs(breadcrumbs));
        initAccessRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId]);

    const initAccessRequest = async () => {
        try {
            setLoading(true);
            setLoadingMsg('Fetching request details...');
            let res = await getAccessRequestDetail({
                id: requestId,
                abortSignal: abortSignalRef?.current
            });
            let accessRequest = deserializeAccessRequestDetail(res.data);
            if (accessRequest) {
                setInput({ ...accessRequest });

                let requesterEmail = accessRequest.requester_email;

                // Get companies whose email domain matches the requester's email.
                let companiesRes = await getCompanies({
                    emailDomain: requesterEmail ? requesterEmail.split('@')[1] : null,
                    selectFields: 'c.id,c.name,c.ivt',
                    abortSignal: abortSignalRef?.current
                });

                setMatchingTeams([
                    ...(companiesRes.data?.companies ?? [])
                ]);

            } else {
                console.error("Invalid access request", accessRequest)
                dispatch(setAlert({
                    show: true,
                    message: 'Invalid access request detail',
                    severity: 'error'
                }));
            }
        } catch (error) {
            if (isCancel(error))
                return;

            console.error('failed to retrieve access request detail', error);
            dispatch(setAlert({
                show: true,
                message: 'Failed to retrieve access request detail',
                severity: 'error'
            }));
        } finally {
            setLoading(false);
            setLoadingMsg('');
        }
    }

    const accessRequestTextField = (label, fieldName) => (
        <TextField
            id={`team-info-${fieldName}`}
            name={fieldName}
            label={label}
            labelStyle='margin-top-0'
            value={input[fieldName]}
            disabled={true}
        />
    )

    const gridRowContentLeftRight = (left, right) => (
        <Grid container item xs={12} spacing={2} sx={{ mb: '1rem' }}>
            {
                left && (
                    <Grid item xs={12} sm={6}>
                        {left}
                    </Grid>
                )
            }
            {
                right && (
                    <Grid item xs={12} sm={6}>
                        {right}
                    </Grid>
                )
            }
        </Grid>
    );

    const gridRowContentSingle = (item) => (
        <Grid container item xs={12} spacing={2} sx={{ mb: '1rem' }}>
            <Grid item xs={12}>
                {item}
            </Grid>
        </Grid>
    );

    const handleCreateNewTeam = () => {
        navigate(
            getCompanyDetailLink([
                `action=${companyAction.create}`,
                `accessRequestId=${requestId}`
            ])
        );
    }

    const contentForRequestDetails = () => (
        <>
            {
                gridRowContentLeftRight(
                    accessRequestTextField('Name', 'requester_name')
                )
            }
            {
                gridRowContentLeftRight(
                    accessRequestTextField('Organization Name', 'organization'),
                    accessRequestTextField('Team Name', 'team_name')
                )
            }
            {
                gridRowContentLeftRight(
                    accessRequestTextField('Organization Address', 'organization_address'),
                    accessRequestTextField('Organization Country', 'organization_country')
                )
            }
            {
                gridRowContentLeftRight(
                    accessRequestTextField('Organization URL', 'team_url'),
                    accessRequestTextField('Logo URL', 'team_logo_url')
                )
            }
            {
                gridRowContentLeftRight(
                    accessRequestTextField('Team POC Name', 'team_poc_name'),
                )
            }
            {
                gridRowContentLeftRight(
                    accessRequestTextField('POC email', 'team_poc_email'),
                    accessRequestTextField('POC Number', 'team_poc_phone'),
                )
            }
            {
                gridRowContentSingle(
                    <TextArea
                        id='justification-access'
                        name='justification'
                        label='How do you intend to use the Digital Information Platform?'
                        labelStyle='margin-top-1'
                        value={input.justification}
                        disabled={true}
                    />
                )
            }
            {
                gridRowContentSingle(

                    <AccessPurposeCheckboxes
                        input={input}
                        disabled={true}
                    />
                )
            }
            {
                gridRowContentLeftRight(
                    <RadioButtonGroup
                        label='How familiar is the team with using an API?'
                        itemList={Object.entries(JUSTIFICATION_FAMILIARITY_ID_MAP).map(([label, id]) => ({
                            id: `api-familiarity-${id}`,
                            value: id,
                            label: label,
                            name: 'api_familiarity',
                            checked: Number(input.api_familiarity) === id,
                            labelStyle: 'margin-left-2'
                        }))}
                        disabled={true}

                    />,
                    <RadioButtonGroup
                        label='How familiar is the team with aviation data?                            '
                        itemList={Object.entries(JUSTIFICATION_FAMILIARITY_ID_MAP).map(([label, id]) => ({
                            id: `aviation-data-familiarity-${id}`,
                            value: id,
                            label: label,
                            name: 'aviation_data_familiarity',
                            checked: Number(input.aviation_data_familiarity) === id,
                            labelStyle: 'margin-left-2'
                        }))}
                        disabled={true}
                    />,
                )
            }
        </>
    )

    const contentForTeamSelectionOrCreation = () => {

        // Adjust the prompt based on the number of matching teams
        const hasMatchingTeams = !!matchingTeams?.length;
        let teamQuery = 'Does the proposed team match, or should be integrated with, any of the following existing teams?';
        let noneOption = "None of the above";
        if (hasMatchingTeams && (matchingTeams.length === 1)) {
            teamQuery = 'Does the proposed team match, or should be integrated with, the following existing team?';
            noneOption = "No";
        }

        return (
            <Grid item xs={12}
                sx={{
                    mb: '2rem',
                    '& .usa-radio': {
                        pl: {
                            xs: 0,
                            sm: '35%',
                            md: '40%',
                        }
                    }
                }}
            >
                <Divider sx={{ mb: '2rem', }} />

                { hasMatchingTeams ?
                    (
                        <RadioButtonGroup
                            label={teamQuery}
                            labelStyle='text-center text-bold'
                            itemList={[
                                ...matchingTeams,
                                {
                                    id: FIRST_TIME_USER_TEAM_ID,
                                    name: noneOption
                                }
                            ].map(({ id, name }) => ({
                                id: `team-info-team-select-${id}`,
                                value: id,
                                label: name,
                                name: 'teamsList',
                                checked: Number(selectedTeamId) === id,
                                labelStyle: 'margin-left-2'
                            }))}
                            onChange={(e) => setSelectedTeamId(Number(e.target.value))}
                        />
                    ) : (
                        <BodyTitle
                            label='There are no teams that match the proposed team.'
                            labelStyle='text-center text-bold'
                        />
                    )
                }

                {
                    // Shows the create button only if no matching teams or
                    // 'None of the above' option is selected.
                    (!hasMatchingTeams ||
                     (selectedTeamId === FIRST_TIME_USER_TEAM_ID)) && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mt: '2rem'
                            }}
                        >
                            <ActionButton
                                text='Create new team'
                                onClick={handleCreateNewTeam}
                            />
                        </Box>
                    )
                }
            </Grid>
        )
    }

    const handleClickApprove = async () => {
        // Update the access request status to 'approved'
        // and proceed to create a dip user account
        setLoading(true);
        setLoadingMsg('Approving the request...');
        let errorMsg = '';
        try {
            let selectedTeamInfoIdx = matchingTeams.findIndex(t => t.id === selectedTeamId);
            if (selectedTeamInfoIdx === -1) {
                errorMsg = 'Matching team information not found';
                throw new Error(`Team ID not found in matching teams list: ${selectedTeamId}`)
            }

            errorMsg = 'Failed to approve the access request';
            await updateAccessRequestStatus({
                id: requestId,
                body: {
                    status: 'approved'
                },
                abortSignal: abortSignalRef?.current
            });

            errorMsg = 'Failed to create a new user';
            setLoadingMsg('Creating new user...');
            await createDipUser({
                body: {
                    name: input.requester_name,
                    cognito_username: input.requester_username,
                    email: input.requester_email,
                    ivt: matchingTeams[selectedTeamInfoIdx].ivt
                },
                abortSignal: abortSignalRef?.current
            });

            dispatch(setAlert({
                show: true,
                message: 'Successfully approved the request',
                severity: 'success'
            }));

            navigate(URL_ADMIN_ACCESS_REQUESTS);
        } catch (error) {
            if (isCancel(error))
                return;

            console.error(errorMsg, error);
            dispatch(setAlert({
                show: true,
                message: errorMsg,
                severity: 'error'
            }));
        } finally {
            setLoading(false);
            setLoadingMsg('');
        }
    }

    const handleClickReject = () => {
        // Update the access request status to 'rejected'
        setLoading(true);
        setLoadingMsg('Rejecting the request....');
        updateAccessRequestStatus({
            id: requestId,
            body: {
                status: 'rejected'
            },
            abortSignal: abortSignalRef?.current
        })
        .then(() => {
            dispatch(setAlert({
                show: true,
                message: 'Successfully rejected the request',
                severity: 'success'
            }));
            navigate(URL_ADMIN_ACCESS_REQUESTS);
        })
        .catch((error) => {
            if (isCancel(error))
                return;

            console.error('failed to reject the access request', error);
            dispatch(setAlert({
                show: true,
                message: 'Failed to reject the access request',
                severity: 'error'
            }));
        })
        .finally(() => {
            setLoading(false);
            setLoadingMsg('');
        })
    }

    return (
        <>
            <Card id='access-request-detail-card'>
                <CardContent sx={{ mt: '1rem' }}>
                    <form ref={formRef}>
                        <Grid container spacing={5}>
                            {/* Request details */}
                            <Grid container item xs={12} >
                                {!loading && contentForRequestDetails()}
                            </Grid>

                            {/*
                                Team selection or creation, displayed
                                only if the request status is not 'approved'
                            */}
                            {
                                !isApproved && (
                                    <Grid container item xs={12} >
                                        {!loading && contentForTeamSelectionOrCreation()}
                                    </Grid>
                                )
                            }
                        </Grid>
                    </form>
                </CardContent>
                <CardActions
                    sx={{
                        justifyContent: 'space-between',
                        mb: '1rem'
                    }}
                >
                    <ActionButton
                        text='Reject'
                        isDelete={true}
                        onClick={handleClickReject}
                    />
                    {
                        // Show the approve button only if a valid team is selected
                        !isApproved && (selectedTeamId !== FIRST_TIME_USER_TEAM_ID) && (
                            <ActionButton
                                text='Approve'
                                onClick={handleClickApprove}
                            />
                        )
                    }
                </CardActions>
            </Card >

            <ProcessingOverlay open={loading} msg={loadingMsg} />
        </>
    )
};

export default AccessRequestDetail;
