import Grid from '@mui/material/Grid';

import ApiKeyFields from './ApiKeyFields';
import OAuth2Fields from './OAuth2Fields';
import BodyText from '../common/BodyText';
import SectionHeader from "../common/SectionHeader";
import Select from '../common/Select';
import { SELECT_TITLE } from '../common/Select';
import TextArea from '../common/TextArea';
import { AUTH_MODE_API_KEY, AUTH_MODE_OAUTH2 } from '../../utils/serviceRegistration';


const WizardVersionAuthentication = ({
    input,
    handleInputChange,
    readOnly,
    isEditingRestricted,
    formErrors,
    ...props
}) => {

    const getAuthFieldsToRender = (authMode) => {
        let rtn = null;

        switch (authMode) {
            case AUTH_MODE_OAUTH2:
                rtn = <OAuth2Fields
                    input={input}
                    handleInputChange={handleInputChange}
                    readOnly={readOnly}
                    isEditingRestricted={isEditingRestricted}
                    formErrors={formErrors} />
                break;
            case AUTH_MODE_API_KEY:
                rtn = <ApiKeyFields
                    input={input}
                    handleInputChange={handleInputChange}
                    readOnly={readOnly}
                    isEditingRestricted={isEditingRestricted}
                    formErrors={formErrors} />
                break;
            case '':
            case SELECT_TITLE:
                rtn = null;
                break;
            default:
                console.error(`Invalid authMode: ${authMode}`);
        }

        return rtn;
    }

    return (
        <>
            <SectionHeader title='Service Authentication' labelStyle='margin-top-4' />
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <BodyText
                        label='What kind of Authentication does your service use?'
                        labelStyle='text-italic margin-y-0'
                    />
                </Grid>

                <Grid item xs={12}>
                    <Select
                        id='auth-mode'
                        name='auth_mode'
                        label='Authentication Mode'
                        labelStyle='margin-top-0'
                        value={input.auth_mode}
                        itemList={authChoiceList}
                        required
                        disabled={isEditingRestricted || readOnly}
                        onChange={handleInputChange}
                        errorMessage={formErrors?.authMode}
                    />
                </Grid>
                {
                    getAuthFieldsToRender(input.auth_mode)
                }
                <Grid item xs={12}>
                    <TextArea
                        id='authentication-details'
                        name='auth_comments'
                        label='Additional Authentication Details'
                        labelStyle='margin-top-0'
                        value={input.auth_comments}
                        disabled={readOnly}
                        onChange={(e) => handleInputChange(e)}
                        errorMessage={formErrors?.authComments}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const authChoiceList = Object.freeze([
    {
        id: 'mode-unset',
        value: SELECT_TITLE,
    },
    {
        id: 'mode-oauth2',
        value: AUTH_MODE_OAUTH2
    },
    {
        id: 'mode-api-key',
        value: AUTH_MODE_API_KEY
    }
]);

export default WizardVersionAuthentication;
