import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import CircularProgressIndicator from '../common/CircularProgressIndicator';
import MajorHeading from '../common/MajorHeading';
import NewsHighlights from './NewsHighlights';


/**
 * This provides the display of the left hand side of a news page.  It
 * consists of:
 * - a graphic on top
 * - a bulleted list of highlights
 * - the latest / most recent news
 *
 * @param {object}        newsImg             the graphic to display
 * @param {boolean}       loading             true if data is still loading
 * @param {string}        highlightTitle      the title to display in the
 *                                            "highlights" block
 * @param {array(string}} highlightItems      content to display as a bulleted
 *                                            list in the "highlights" block
 * @param {string}        highlightKeyPrefix  prefix to use for each key in the
 *                                            list of highlightItems so that
 *                                            React doesn't complain
 * @param {string}        latestTitle         the title to display in the
 *                                            "latest" block
 * @param {JSX}           latestContent       JSX content to display in the
 *                                            "latest" block
 */

const NewsLeftSide = ({
    newsImg,
    loading,
    highlightTitle,
    highlightItems,
    highlightKeyPrefix,
    latestTitle,
    latestContent
}) => {

    /**
     * Displays the graphic in the top left corner.
     */
    const getNewsGraphicBlock= () => (
        <Box
            component='img'
            alt=''
            src={newsImg}
            sx={{
                width: '100%',
                height: 'fit-content',
                borderRadius: '7px'
            }}
        />
    );

    /**
     * Displays the news highlights block.
     */
    const getHighlightsBlock = () => (
        <NewsHighlights
            highlightTitle={highlightTitle}
            highlightItems={highlightItems}
            highlightKeyPrefix={highlightKeyPrefix}
        />
    );

    /**
     * Displays the "Latest xxx" block.
     */
    const getLatestBlock = () => (
        <Grid container item
            sx={{
                marginTop: '1rem',
                width: '100%'
            }}
        >
            <MajorHeading
                label={latestTitle}
                labelStyle='margin-left-0 margin-top-1 margin-bottom-0'
            />
            {latestContent}
        </Grid>
    );

    return (
        <Grid container id='left-side-card'
            sx={{
                backgroundColor: 'white',
                padding: '1rem',
            }}
        >
            {getNewsGraphicBlock()}
            {
                loading ?
                    <CircularProgressIndicator 
                        type='large'
                        altText='Loading latest news' 
                    />
                :
                    <>
                        {getHighlightsBlock()}
                        {getLatestBlock()}
                    </>
            }
        </Grid>
    );
};

export default NewsLeftSide;
