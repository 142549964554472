import classnames from 'classnames';

/**
 * This provides the wrapper around the footer in a modal window.  This was
 * adapted from Trussworks' https://github.com/trussworks/react-uswds/blob/main/src/components/Modal/ModalFooter/ModalFooter.tsx
 *
 * @param {JSX}    children    content to display in the footer, usually buttons
 * @param {string} footerStyle any additional USWDS styles to apply to the
 *                             the footer
 * @param {object} divProps    any additional attributes to apply to the div
 *                             wrapping the footer
 */
const ModalFooter = ({
    children,
    footerStyle,
    ...divProps
}) => {

    const classes = classnames('usa-modal__footer', footerStyle);

    return (
        <div className={classes} {...divProps}>
            {children}
        </div>
    );
}

export default ModalFooter;
