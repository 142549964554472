import { useEffect, useState } from 'react';
import { isCurrentUserInGroup, getAdminGroupName } from '../../utils/auth';
import { Outlet, useNavigate } from "react-router-dom";

/**
 * This component is a wrapper around the child components
 * and it checks if the user belongs to the admin group or not.
 * If user is not in the admin group, redirect to the previous page.
 */
export default function RequireAdmin({ children }) {
  const navigate = useNavigate();

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    isCurrentUserInGroup(getAdminGroupName())
      .then(val => {
        setIsAdmin(val);
        if (!val) {
          // go back to the previous page if user is not in admin group
          navigate(-1, { replace: true });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAdmin ? children ?? <Outlet /> : null;
}