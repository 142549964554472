import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import placeholderImage from '../../assets/placeholder_image.png';

/**
 * This provides the logo, provider name, and service name on a service card.
 */

function ServiceCardLogoProviderServiceName({ service, serviceLogoDataUrl }) {
    return (
        <Grid container
            id={`service-card-logo-provider-service-name-${service.id}`}
            sx={{
                width: '13rem',
                height: '5rem',
                padding: '5px',
            }}
        >

            { /* Left side: logo */}
            <Grid item xs={4}
                sx={{
                    display: 'flex',
                    jusifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    height: '100%',
                    width: '100%',
                    borderRadius: '0.25rem'
                }}
            >
                <Box
                    id={`small-service-card-img-${service.id}`}
                    alt={`${service.name}`}
                    component='img'
                    src={
                        serviceLogoDataUrl ||
                        service.provider_logo_url ||
                        placeholderImage
                    }
                    sx={{
                        width: '100%',
                        objectFit: 'contain'
                    }}
                />
            </Grid>

            { /* Right side: provider and service names */}
            <Grid item container xs={8}
                sx={{
                    boxSizing: 'border-box',
                    paddingLeft: '8px',
                    alignItems: 'center',
                    fontSize: '0.875rem',
                }}
            >

                <Stack>
                    { /* Provider name */}
                    <Typography
                        id={`service-provider-name-${service.id}`}
                        component='div'
                        variant='body'
                        color='primary'
                        sx={{
                            minHeight: '1.1rem',
                            maxHeight: '1.1rem',
                            overflow: 'hidden',
                            paddingTop: '1px'
                        }}
                    >
                        {service.provider_name_abbrev ?? service.provider_name}
                    </Typography>

                    { /* Service name */}
                    <Typography
                        id={`service-name-${service.id}`}
                        component='div'
                        variant='body'
                        color='secondary.main'
                        sx={{
                            minHeight: '2.4rem',
                            maxHeight: '3rem',
                            lineHeight: '1.0rem',
                            fontStyle: 'italic',
                            overflow: 'hidden',
                            paddingTop: '1px',
                        }}
                    >
                        {service.name}
                    </Typography>
                </Stack>

            </Grid>
        </Grid>
    );
};

export default ServiceCardLogoProviderServiceName;
