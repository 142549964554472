import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

/**
 * This provides a filter chip styled for the Catalog.  At a minimum,
 * the props should include a label, a key, and an onClick handler.
 */
const CatalogFilterChip = ({...props}) => 
    <Chip color='primary' variant='outlined' size='small' {...props} />

const StyledFilterChip = styled(CatalogFilterChip)(({ theme }) => ({
    marginRight: '5px',
    backgroundColor: theme.palette.background.subtitle,
    '&:focus, &:hover': {
        border: '3px solid'
    }
  }));

export default StyledFilterChip;
