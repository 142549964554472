
/**
 * This is the container for the main header across the top of the Catalog site.
 *
 * @param {object} props     props.children contains the children to display,
 *                           e.g. the logo and the navigation menu
 */
const Header = ({...props}) => {

    return (
        <header className="usa-header--basic site-header role='banner'" >
            {props.children}
        </header>
    );
};

export default Header;
