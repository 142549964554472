import './SearchTextField.css'

/**
 * This provides a custom text field for a search input field which
 * satisfies Sort Site's accessibility requirements.
 *
 * @param {string}   id           unique identifier for the search field
 * @param {string}   value        current value entered
 * @param {string}   placeholder  placeholder text inside the text field;
 *                                USE OF THIS IS STRONGLY DISCOURAGED
 * @param {string}   ariaLabel    value for its aria-label if the field does
 *                                not have a separate label
 * @param {function} setValue     method to call to update the value as the
 *                                user types
 * @param {function} handleEnter  method to call to start the search
 * @param {object}   props        any additional properties to apply to the
 *                                input field
 */
function SearchTextField({
    id, value, placeholder, ariaLabel, setValue, handleEnter, ...props }) {

    return (
        <input
            id={id}
            className='searchField'
            type='text'
            role='search'
            value={value}
            placeholder={placeholder}
            aria-label={ariaLabel}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && !!handleEnter && handleEnter()}
            {...props}
        />
    );
}

export default SearchTextField;
