
/**
 * This provides a navigation bar along the left side of the display.
 * The 'items' prop is an array of objects.  Each object should have attributes:
 * - title : text to display in the navigation bar
 * - href  : anchor on the page, e.g. "#overview"
 */
function SideNavBar({items}) {
    return (
        <nav aria-label="Side navigation," >
            <ul className="usa-sidenav" >
                {
                    items && items.map((item, idx) => (
                        <li key={`sidenav_item_${idx}`}
                            className="usa-sidenav__item"
                        >
                            <a href={item.href} >
                                {item.title}
                            </a>
                        </li>
                    ))
                }
            </ul>
        </nav>
    );
};

export default SideNavBar;
