import { isInvalidDate, isInvalidTextField, isInvalidVersion } from './validation';
import { URL_ADMIN_PLATFORM_RELEASE, URL_ADMIN_PLATFORM_RELEASE_NEWS, URL_ADMIN_PLATFORM_RELEASE_NEWS_ITEM, URL_ADMIN_PLATFORM_SUMMARY_CAPABILITY 
       } from './navigation';

export const PLATFORM_SUMMARY_TITLE = 'Summary of Capabilities'

export const PLATFORM_NEWS_PAGE_ACTION = {
    create: 'create',
    view: 'view',
    delete: 'delete',
    update: 'update'
}

/**
 * Validates the release version and timestamp have been set and are valid.
 */
export const validatePlatformRelease = (input) => {
    let errors = {};

    errors.release = isInvalidVersion(input?.release, 'Release Number');
    errors.timestamp = isInvalidDate(input?.timestamp, 'Release date', 'YYYY-MM-DD');

    return errors;
}

/**
 * Validates the release news item is not blank and is valid.
 */
export const validatePlatformReleaseNewsItem = (input) => {
    let errors = {};

    if (!!input && !!input.news) {
        errors.news = isInvalidTextField(input?.news, 1, 256, ' ,.-+@');
    } else {
        errors.news = 'A news item is required';
    }

    return errors;
}

/**
 * Validates the platform summary of capability is not blank and is valid.
 */
export const validatePlatformSummary = (input) => {
    let errors = {};

    if (!!input && !!input.description) {
        errors.description = isInvalidTextField(
            input?.description, 1, 256, ' ,.-+@');
    } else {
        errors.description = 'Missing Summary of Capability description';
    }

    return errors;
}


/**
 * Extracts just the summary capability items out of the summary data.
 *
 * @param {array(object)} summaryData    summary of capabilities; attribute 
 *                                       "description" will be extracted
 *
 * @return {array(string)} just the summary text
 */
export const extractSummaryDescription = (summaryData) => {
    let rtn = [];

    if (!!summaryData && !!summaryData.length) {
        rtn = summaryData?.map(item => item.description);
    }

    return rtn;
}

/**
 * Extracts just the release news items out of the release data.
 *
 * @param {array(object)} releaseNews    release news items; attribute "news"
 *                                       will be extracted
 *
 * @return {array(string)} just the news items
 */
export const extractReleaseNews = (releaseNews) => {
    let rtn = [];

    if (!!releaseNews && !!releaseNews.length) {
        rtn = releaseNews?.map(item => item.news);
    }

    return rtn;
}

/**
 * Gets a link to a page for creating/editing/deleting primary release data.
 *
 * @param {array{string}} queryParams    array of routing parameters, e.g.
 *                                       releaseId=<id>, action=<action>
 */
export const getPlatformReleasePage = (queryParams) => {
    return `${URL_ADMIN_PLATFORM_RELEASE}?${queryParams.join('&')}`;
}

/**
 * Gets a link to the page with the list of news items for a release.
 *
 * @param {array{string}} queryParams    array of routing parameters, e.g.
 *                                       releaseId=<id>
 */
export const getPlatformReleaseNewsPage = (queryParams) => {
    return `${URL_ADMIN_PLATFORM_RELEASE_NEWS}?${queryParams.join('&')}`;
}

/**
 * Gets a link to a page for creating/editing/deleting a news item for a 
 * release.
 *
 * @param {array{string}} queryParams    array of routing parameters, e.g.
 *                                       releaseId=<id>, newsId=<id>, 
 *                                       action=<action>
 */
export const getPlatformReleaseNewsItemPage = (queryParams) => {
    return `${URL_ADMIN_PLATFORM_RELEASE_NEWS_ITEM}?${queryParams.join('&')}`;
}

/**
 * Gets a link to a summary capability page for creating/editing/deleting.  
 *
 * @param {array{string}} queryParams    array of routing parameters, e.g.
 *                                       summaryId=<id>, action=<action>
 */
export const getSummaryCapabilityPage = (queryParams) => {
    return `${URL_ADMIN_PLATFORM_SUMMARY_CAPABILITY}?${queryParams.join('&')}`;
}
