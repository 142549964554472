import classnames from 'classnames';

/**
 * This creates text intended as a title for text in the body of the web page.
 * The title is in a bond and slightly larger font than normal text.
 *
 * @param {string}  label          the label displayed to the user
 * @param {string}  labelStyle     additional USWDS style classes to add to the
 *                                 label, e.g. 'margin-top-0' to remove a margin
 * @param {object}  props          additional properties applied to the
 *                                 containing 'div'
 * @param {object}  props.children optional content to display next to the label
 */

const BodyTitle = ({ label, labelStyle, ...props }) => {

    const classes = classnames('usa-label', 'font-body-md', 'text-bold',
        labelStyle);

    return (
        <div className={classes} {...props} >
            {label}{props.children}
        </div>
    );
};

export default BodyTitle;
