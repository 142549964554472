import classnames from 'classnames';

import FieldLabel from './FieldLabel';

/**
 * This creates a select choice list.  It is based on the USWDS select component
 * which satisfies Section 508 accessibility requirements.
 *
 * @param {string}   id                  unique id for this component
 * @param {string}   name                unique name for this component for
 *                                       form submission
 * @param {string}   label               label for the group of options
 * @param {string}   labelStyle          additional USWDS style classes to add
 *                                       to the label, e.g. 'margin-top-0' to
 *                                       remove a margin
 * @param {string}   value               option to select
 * @param {object}   itemList            spec for each option
 * @param {string}   itemList.id         unique id for this option
 * @param {string}   itemList.label      option label (optional). itemList.value
 *                                       is used if missing
 * @param {string}   itemList.value      option value
 * @param {boolean}  required            true if an option must be selected
 * @param {boolean}  disabled            true if the options are disabled
 * @param {function} onChange            selection handler
 * @param {string}   errorMessage        any error message to display
 * @param {object}   props               any additional properties are applied
 *                                       to the select element
 */
const Select = ({
    id,
    name,
    label,
    labelStyle,
    value,
    itemList,
    required=false,
    disabled=false,
    onChange,
    errorMessage='',
    ...props
}) => {

    const labelClasses = classnames('usa-label', labelStyle);

    const selectClasses = classnames(
        'usa-select',
        {
            'usa-input--error': errorMessage
        }
    );

    // Make sure the itemList has something in it; it can be empty if a
    // select component has dynamic content that is still loading
    if (!itemList || (itemList.length < 1)) {
        itemList = [SELECT_ELEMENT];
    }

    const selectValue = !!value ? value : itemList[0].value;

    return (
        <>
            <FieldLabel label={label} id={`${id}-select-label`}
                entryId={id} labelStyle={labelClasses}
                required={required}
            />

            {
                !!errorMessage &&
                <span className='usa-error-message' role='alert' >
                    {errorMessage}
                </span>
            }

            <select className={selectClasses} name={name} id={id}
                value={selectValue}
                onChange={onChange}
                disabled={disabled}
                {...props}
            >
                {
                    itemList && itemList.map(item => {
                        return (
                            <option
                                id={item.id}
                                key={item.id}
                                value={item.value}
                            >
                                {item.label ?? item.value}
                            </option>
                        )
                    })
                }
            </select>
        </>
    );
};

export default Select;

// Constants that can be used by other components for a consistent UI
export const SELECT_ID = 'not-selected';
export const SELECT_TITLE = '-- Select --';
export const SELECT_ELEMENT = Object.freeze({
    id: SELECT_ID,
    value: SELECT_TITLE,
});
