import { Box, Card, CardContent, Divider, Grid, Stack } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setAlert } from "../../app/slices/alert";
import { setLinks as setBreadcrumbs } from '../../app/slices/breadcrumbs';
import { fetchPlatformSummaryOfCapabilities, fetchPlatformReleases,
         selectFetchingPlatformReleases,
         selectFetchingPlatformSummaryOfCapabilities,
         selectPlatformReleases, selectPlatformSummaryOfCapabilities
       } from '../../app/slices/news';
import { setPageTitle } from '../../app/slices/page';
import BodyText from "../../components/common/BodyText";
import LinkButton from "../../components/common/LinkButton";
import MajorHeading from "../../components/common/MajorHeading";
import MinorHeading from "../../components/common/MinorHeading";
import NoMatchLabel from "../../components/common/NoMatchLabel";
import BulletedList from "../../components/news/BulletedList";
import NewsHighlights from "../../components/news/NewsHighlights";
import Pagination from '../../components/common/Pagination';
import { useAbortController } from "../../hooks";
import { extractSummaryDescription, extractReleaseNews, 
         getPlatformReleaseNewsPage, getPlatformReleasePage, 
         PLATFORM_NEWS_PAGE_ACTION, PLATFORM_SUMMARY_TITLE 
       } from "../../utils/platformNews";
import { URL_ADMIN, URL_ADMIN_PLATFORM_SUMMARY_CAPABILITIES, URL_HOME 
       } from "../../utils/navigation";
import { getFormattedDateFromString } from '../../utils/timeUtils';
import { paginate } from '../../utils/utils';

/**
 * This page allows the DIP Admin to enter and edit the Platform Summary of
 * Capabilities and the Platform Release News which are displayed on the
 * Platform News page to users.
 * - The current Platform Summary of Capabilities is displayed at the top of the
 *   page; click the associated button to edit the information
 * - The release are displayed on the bottom of the page.  Click the associated
 *   button to create a new release.  For each release already created, the
 *   release has an accordion which can be expanded to show the current news
 *   for the release.  There is an associated button to be able to edit the
 *   news for the release.
 */
const PlatformNewsList = () => {
    const { abortSignalRef } = useAbortController();

    const dispatch = useDispatch();

    const fetchingSummary = useSelector(
        selectFetchingPlatformSummaryOfCapabilities);
    const platformSummary = useSelector(selectPlatformSummaryOfCapabilities);

    const fetchingReleases = useSelector(selectFetchingPlatformReleases);
    const platformReleases = useSelector(selectPlatformReleases);
    const [displayedPlatformReleases, setDisplayedPlatformReleases] = 
               useState([]);

    const [idToFlags, setIdToFlags] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [numItemsPerPage, setNumOfItemsPerPage] = useState(5);

    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));
        dispatch(setPageTitle('Platform News Administration'));
        initSummaryOfCapabilities();
        initReleases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [platformReleases]);

    useEffect(() => {
        setDisplayedPlatformReleases(paginate(platformReleases, numItemsPerPage,
            currentPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [platformReleases, currentPage, numItemsPerPage]);


    // Initialize the information displayed in the summary block at the top
    const initSummaryOfCapabilities = async () => {
        dispatch(fetchPlatformSummaryOfCapabilities({
            abortSignalRef: abortSignalRef
        }))
        .unwrap()
        .catch(err => {
            if (err === 'cancelled')
                return;

            console.error('Failed to get the platform summary of capabilities', 
                err);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get the platform summary of capabilities',
                severity: 'error'
            }));
        });
    };

    // Initialize the releases
    const initReleases = async () => {
        dispatch(fetchPlatformReleases({
            abortSignalRef: abortSignalRef
        }))
        .unwrap()
        .catch(err => {
            if (err === 'cancelled')
                return;

            console.error('Failed to get the platform releases', err);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get the platform releases',
                severity: 'error'
            }));
        });
    };

    // For paginating the releases
    const setPageFromPagination = (zeroBasedPage) => {
        setCurrentPage(zeroBasedPage + 1);
    }

    // For paginating the releases
    const setRowsPerPageFromPagination = (rowsPerPage) => {
        setCurrentPage(1);
        setNumOfItemsPerPage(rowsPerPage);
    }

    // For expanding/contracting the accordion to display a release's news
    const toggleNews = async (releaseId) => {
        let expanded = !!idToFlags?.[releaseId]?.['expanded'];
        let flags = { ...idToFlags };
        flags[releaseId] = { expanded: !expanded }
        setIdToFlags({ ...flags });
    };

    // This displays the list of news items for a single release within a
    // releases accordion; this should only be called if the user expanded the 
    // news accordion for a release.
    const getNewsDisplay = (release) => (
        <>
            <Grid container item
                xs={12}
                key={`news-list-for-${release.id}`}
                component={List}
                dense
            >
                {
                    (idToFlags?.[release.id]?.['expanded'] &&
                        <BulletedList
                            listItems={extractReleaseNews(release.release_news)}
                            listStyle='margin-top-0'
                            keyPrefix={release.release}
                        />
                    )
                }
            </Grid>
            <Grid item
                xs={12}
                sx={{
                    textAlign: 'end'
                }}
            >
                <LinkButton text='Edit news'
                    href={getPlatformReleaseNewsPage([`releaseId=${release.id}`])}
                />
            </Grid>
        </>
    );

    // This displays the "News" accordion underneath the release name & date.
    const NewsAccordion = ({ releaseId, release }) => {

        let primaryId = `news-for-${releaseId}`;
        let expanded = idToFlags?.[releaseId]?.['expanded'] ?? false;
        return (
            <div className="usa-accordion" id={primaryId} key={primaryId} >
                <div className="usa-accordion__heading">
                    <button
                        id={`${primaryId}-button`}
                        type="button"
                        className="usa-accordion__button"
                        aria-expanded={expanded}
                        aria-controls={`${primaryId}-content`}
                        onClick={(e) => toggleNews(releaseId) }
                    >
                        Release {release.release} News
                    </button>
                </div>
                <div className="usa-accordion__content usa-prose"
                    id={`${primaryId}-content`} hidden={!expanded}
                >
                    {
                        getNewsDisplay(release)
                    }
                </div>
            </div>
        );
    };

    // Creates the JSX to display the summary of capabilities
    const getSummaryCard = () => {
        const summary = extractSummaryDescription(platformSummary);
        const loading = fetchingSummary || !summary || !summary?.length;

        return (
            <MainCard 
                id='admin-summary-card'
                loading={loading}
                heading='Platform Summary'
                linkText='Edit summary'
                linkHref={URL_ADMIN_PLATFORM_SUMMARY_CAPABILITIES}
            >
                {
                    loading ? 
                        null 
                    :
                        <NewsHighlights
                            highlightTitle={PLATFORM_SUMMARY_TITLE}
                            highlightItems={summary}
                            highlightKeyPrefix='summary'
                        />
                }
            </MainCard >
        );
    }

    // Creates the JSX to display the releases
    const getReleaseCard = () => {
        const loading = fetchingReleases || !displayedPlatformReleases ||
            !displayedPlatformReleases?.length;

        return (
            <MainCard 
                id='admin-release-card'
                loading={loading}
                heading='Platform Releases'
                linkText='Create a release'
                linkHref={getPlatformReleasePage([`action=${PLATFORM_NEWS_PAGE_ACTION.create}`])}
            >
                {
                    loading ? 
                        null 
                    : 
                        getReleaseList() 
                }
            </MainCard >
        );
    };

    // Creates the JSX to display the core content of the releases 
    const getReleaseList = () => {
        return (
            <Box>
                <List dense>
                {
                    displayedPlatformReleases && 
                    !!displayedPlatformReleases.length  &&
                    displayedPlatformReleases.map((release, idx) => (
                        <Fragment key={`release-${idx}`}>
                            <Box key={release.id}
                                sx={{
                                    width: '100%',
                                    mb: '2rem'
                                }}
                            >
                                <ListItem
                                    key={`release-info-${release.id}`}
                                    sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: '100%',
                                            px: 0
                                    }}
                                >
                                    <Grid container spacing={2} id='label-grid' sx={{ mt:0 }} >
                                        <Grid item xs={6} sx={{ pt: 0, mt: 0 }} id='rel' >
                                            <MinorHeading
                                                label={`${release.release}`}
                                                labelStyle='margin-top-0 margin-bottom-0 margin-left-2'
                                            />
                                        </Grid>
                                        <Grid item xs={6} sx={{ pt: 0, mt: 0 }} id='date' >
                                            <BodyText 
                                                label={getFormattedDateFromString(release.timestamp, 'MM/DD/YYYY')} 
                                                labelStyle='margin-top-0 margin-bottom-0 '
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box id='box-grid'
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <LinkButton text='View'
                                            href={getPlatformReleasePage([`releaseId=${release.id}`, `action=${PLATFORM_NEWS_PAGE_ACTION.view}`, `released=${hasBeenReleased(release)}`])}
                                            style={{ marginRight: '1rem' }}
                                        />
                                        <LinkButton text='Edit'
                                            href={getPlatformReleasePage([`releaseId=${release.id}`, `action=${PLATFORM_NEWS_PAGE_ACTION.update}`, `released=${hasBeenReleased(release)}`])}
                                            style={{ marginRight: '1rem' }}
                                        />
                                        <LinkButton text='Delete' isDelete
                                            href={getPlatformReleasePage([`releaseId=${release.id}`, `action=${PLATFORM_NEWS_PAGE_ACTION.delete}`, `released=${hasBeenReleased(release)}`])}
                                        />
                                    </Box>
                                </ListItem>
                                <ListItem
                                    key={`release-accordian-${release.id}`}
                                    sx={{
                                        px: 0
                                    }}
                                >
                                    <NewsAccordion
                                        releaseId={release.id}
                                        release={release}
                                    />
                                </ListItem>
                            </Box>
                            <Divider
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '80%',
                                    marginLeft: '10%',
                                    marginRight: '10%',
                                    marginBottom: '1.5rem',
                                }}
                            />
                        </Fragment>
                    ))
                }
            </List>

            {
                (displayedPlatformReleases && 
                !!displayedPlatformReleases.length) ?  (
                    <Pagination
                        id='releases-pagination'
                        initialRowsPerPage={numItemsPerPage}
                        page={currentPage - 1}
                        pageCallback={setPageFromPagination}
                        rowsPerPageCallback={setRowsPerPageFromPagination}
                        numRows={platformReleases.length}
                        units='Releases'
                    />
                ) : (
                    <NoMatchLabel label='No releases were found' />
                )
            }
        </Box>
        );
    };

    // Determines if a release has already been released by comparing its
    // release date to today's date.
    const hasBeenReleased = (release) => {
        const today = Date.now();
        const releaseDate = release.timestamp ? 
            Date.parse(release.timestamp) : null;
        const released = (!!releaseDate && releaseDate < today) ? true : false;
        return released;
    }

    return (
        <Stack spacing={2}>
            { getSummaryCard() }
            { getReleaseCard() }
        </Stack>
    );
}

// MainCard wraps a) the summary of capabilities and b) the releases,
// so that both sections have consistent styling
const MainCard = ({
    id, 
    loading,  
    heading, 
    linkText, 
    linkHref, 
    ...props
}) => {
    return (
        <Card id={id} >
            <CardContent>
                { 
                    loading ?
                        <MajorHeading label={heading} loading={loading} />
                    : (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <MajorHeading label={heading} />
                                <div>
                                    <LinkButton text={linkText}
                                        href={linkHref}
                                    />
                                </div>
                            </Box>
    
                            {props.children}
                        </>
                    )
                }
            </CardContent>
        </Card >
    );
};

// Breadcrumbs are only available for the Admin
const breadcrumbs = [
    { name: 'Home', href: URL_HOME },
    { name: 'Admin', href: URL_ADMIN },
    { name: 'Platform News', href: null }
];

export default PlatformNewsList;
