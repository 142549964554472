import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCompanies } from '../../apiClient';
import { selectCompanies, selectCompanyAction, setCompanies } from '../../app/slices/admin';
import { setAlert } from '../../app/slices/alert';
import { setLinks as setBreadcrumbs } from '../../app/slices/breadcrumbs';
import { setPageTitle } from '../../app/slices/page';
import CircularProgressIndicator from '../../components/common/CircularProgressIndicator';
import FilterInput from '../../components/common/FilterInput';
import LinkButton from '../../components/common/LinkButton';
import NoMatchLabel from '../../components/common/NoMatchLabel';
import Pagination from '../../components/common/Pagination';
import { useAbortController } from '../../hooks';
import { getCompanyDetailLink } from '../../utils/companyRegistration';
import { URL_ADMIN, URL_HOME } from '../../utils/navigation';
import { paginate } from '../../utils/utils';

/**
 * This provides the ability to create, view, or update the information
 * for a single company.  It is expected that only the DIP Admin can perform
 * these functions.
 */

export default function Company() {

    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();

    const companies = useSelector(selectCompanies);
    const companyAction = useSelector(selectCompanyAction);

    const [loading, setLoading] = useState(false);
    const [filterCompanyName, setFilterCompanyName] = useState('');
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [displayedCompanies, setDisplayedCompanies] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [numItemsPerPage, setNumOfItemsPerPage] = useState(10);

    const breadcrumbs = [
        { name: 'Home', href: URL_HOME },
        { name: 'Admin', href: URL_ADMIN },
        { name: 'Companies', href: null }
    ];

    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));
        dispatch(setPageTitle('Company Administration'));
        setLoading(true);
        getCompanies({ abortSignal: abortSignalRef?.current })
        .then(res => {
            dispatch(setCompanies({ companies: res.data?.companies ?? [] }));
            setLoading(false);
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to get companies:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get companies',
                severity: 'error'
            }));

            setLoading(false);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCurrentPage(1);
        setFilteredCompanies(filterCompanies(companies));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companies, filterCompanyName]);

    useEffect(() => {
        setDisplayedCompanies(paginate(filteredCompanies, numItemsPerPage,
            currentPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredCompanies, currentPage, numItemsPerPage]);

    const filterCompanies = (companiesToFilter) => {
        let rtn = companiesToFilter;

        if (companiesToFilter && !!companiesToFilter.length) {
            // filter by company name
            if (!!filterCompanyName) {
                let lowerCasedCompanyName = filterCompanyName.toLowerCase();
                rtn = companiesToFilter.filter(s =>
                        s?.name?.toLowerCase().includes(lowerCasedCompanyName));
            }
        }

        return rtn;
    };


    const setPageFromPagination = (zeroBasedPage) => {
        setCurrentPage(zeroBasedPage + 1);
    }

    const setRowsPerPageFromPagination = (rowsPerPage) => {
        setCurrentPage(1);
        setNumOfItemsPerPage(rowsPerPage);
    }

    return (
        <Card id='admin-company' sx={{ p: '1rem' }}>
            <CardContent>

                {
                    loading &&
                    <CircularProgressIndicator
                        type='large'
                        altText='Loading companies...'
                    />
                }
                <Box sx={{ textAlign: 'end' }}>
                    <LinkButton text='Create a Company'
                        href={getCompanyDetailLink([`action=${companyAction.create}`])}
                    />
                </Box>

                <List dense={false}>
                    <ListItem
                        sx={{
                            px: 0
                        }}
                    >
                        <FilterInput
                            id='company-search'
                            label='Search for companies'
                            value={filterCompanyName || ''}
                            setValue={setFilterCompanyName}
                            handleClear={() => setFilterCompanyName('')}
                        />
                    </ListItem>
                    {
                        displayedCompanies && !!displayedCompanies.length &&
                        displayedCompanies.map(c => {
                            // The default avatar background color is not dark
                            // enough to satisfy 508 color contrast requirements
                            // so darken the color of the default avatar if the
                            // service doesn't have its own logo
                            let avatarBgColor = !!c.logo_url ?
                                'none' : '#374274';
                            return (
                                <ListItem
                                    key={c.id}
                                    sx={{
                                        px: 0
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            alt=''
                                            src={c.logo_url}
                                            sx={{
                                                img: {
                                                    objectFit: 'contain'
                                                },
                                                backgroundColor: avatarBgColor,
                                            }}
                                        />
                                    </ListItemAvatar>
    
                                    <ListItemText primary={c.name} />
    
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <LinkButton text='View'
                                            href={getCompanyDetailLink([`companyId=${c.id}`, `action=${companyAction.view}`])}
                                            style={{ marginRight: '1rem' }}
    
                                        />
                                        <LinkButton text='Edit'
                                            href={getCompanyDetailLink([`companyId=${c.id}`, `action=${companyAction.update}`])}
                                            style={{ marginRight: '1rem' }}
                                        />
                                        <LinkButton text='Delete' isDelete
                                            href={getCompanyDetailLink([`companyId=${c.id}`, `action=${companyAction.remove}`])}
                                        />
                                    </Box>
    
                                </ListItem>
                            )
                        })
                    }
                </List>

                {
                    (displayedCompanies && !!displayedCompanies.length ) ? (
                        <Pagination
                            id='company-pagination'
                            initialRowsPerPage={numItemsPerPage}
                            page={currentPage - 1}
                            pageCallback={setPageFromPagination}
                            rowsPerPageCallback={setRowsPerPageFromPagination}
                            numRows={filteredCompanies.length}
                            units='Companies'
                        />
                    ) : (
                        <NoMatchLabel
                            label='No companies were found'
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};
