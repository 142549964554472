import HelpOutlineIcon from '@mui/icons-material/Help';

import { useRef, useState } from 'react';

import DipIconButton from '../common/DipIconButton';
import MinorHeading from '../common/MinorHeading';
import TextArea from '../common/TextArea';
import Modal from '../common/modal/Modal';

/**
 * This component is part of the version performance input for a service
 * being registered.  This provides a prompt and text area for the service
 * provider to specify additional metrics related to the data delivered
 * by the service.  The metrics are entered in the text area in JSON format
 * for display in a table in the Service Detail page.
 */
const ProviderMetrics = ({
    input,
    readOnly,
    handleInputChange,
    formErrors
}) => {

    const [showHelpInfo, setShowHelpInfo] = useState(false);
    const helpRef = useRef(null);
    const toggleHelpInfoVisibility = () => {
        setShowHelpInfo(!showHelpInfo);
    }

    return (
        <>
            <MinorHeading
                label='Provider Metrics'
                labelStyle='display-inline-flex margin-bottom-0 flex-align-center'
            />
                <DipIconButton
                    id='provider-metrics-help-icon'
                    aria-label='provider metrics help'
                    onClick={toggleHelpInfoVisibility}
                    data-open-modal
                    aria-controls={helpRef?.current?.modalId}
                >
                    <HelpOutlineIcon />
                </DipIconButton>

            <TextArea
                id='additional-metrics'
                name='metrics'
                label='Additional metrics related to the data delivered by the service'
                labelStyle='margin-top-1'
                value={input.metrics}
                disabled={readOnly}
                onChange={(e) => handleInputChange(e)}
                errorMessage={formErrors?.additionalMetrics}
            />

            { /* Help text */ }
            <Modal
                id='provider-metrics-help'
                isOpen={showHelpInfo}
                handleClose={toggleHelpInfoVisibility}
                heading='Provider Metrics Help'
                isLarge
                ref={helpRef}
            >
                {getHelpText()}
            </Modal>
        </>
    )
}

const getHelpText = () => (
    <div>
        <p className='margin-bottom-0'>
            Additional metrics related to the data delivered by the service.
            Any additional service metrics provided should follow a specific
            JSON format. The base JSON object has two required properties
            ("columns" and "rows") and an optional property ("description").
        </p>
        <ul className='margin-top-0' >
            <li>
                "<strong>columns</strong>" are JSON objects with
                properties "<strong>header</strong>" (required) and
                "<strong>align</strong>" (optional) where:
                <ul>
                    <li>
                        "<strong>header</strong>" is the header value to
                        display
                    </li>
                    <li>
                        "<strong>align</strong>" (left/center/right) is the
                        horizontal alignment of values in the column.
                    </li>
                </ul>
            </li>
            <li>
                "<strong>rows</strong>" are JSON objects which are a
                mapping from header to value.
            </li>
            <li>
                "<strong>description</strong>" is the description of the
                metrics.
            </li>
        </ul>
        <p className='margin-top-0'>
            Example:
        </p>
        <pre>
            <CodeLevel0>
                &#123;
            </CodeLevel0>
            <CodeLevel1>
                "description": "Validation Metric: Median Absolute Deviation (measured in seconds); Training/Validation Date Range: 6/1/19 - 12/30/19",
            </CodeLevel1>
            <CodeLevel1>
                "columns": [
            </CodeLevel1>
            <CodeLevel2>
                &#123;"header": "Model"&#125;,
            </CodeLevel2>
            <CodeLevel2>
                &#123;"header": "Airport"&#125;,
            </CodeLevel2>
            <CodeLevel2>
                &#123;"header": "Accuracy (MAD) in sec", "align": "right"&#125;
            </CodeLevel2>
            <CodeLevel1>
                ],
            </CodeLevel1>
            <CodeLevel1>
                "rows": [
            </CodeLevel1>
            <CodeLevel2>
                &#123;"Model": "Unimpeded Taxi OUT AMA",
            </CodeLevel2>
            <CodeLevel2>
                "Airport": "KABC",
            </CodeLevel2>
            <CodeLevel2>
                "Accuracy (MAD) in sec": "60.8" &#125;,
            </CodeLevel2>
            <CodeLevel2>
                &#123;"Model": "Unimpeded Taxi OUT Ramp",
            </CodeLevel2>
            <CodeLevel2>
                "Airport": "KABC",
            </CodeLevel2>
            <CodeLevel2>
                "Accuracy (MAD) in sec": "87.5"&#125;
            </CodeLevel2>
            <CodeLevel1>
                ],
            </CodeLevel1>
            <CodeLevel0>
                &#125;
            </CodeLevel0>
        </pre>
    </div>
);

const CodeLevel0 = ({ ...props }) => (
    <p className='margin-top-0 margin-bottom-0 margin-left-0 text-pre-wrap'>
        {props.children}
    </p>
)
const CodeLevel1 = ({ ...props }) => (
    <p className='margin-top-0 margin-bottom-0 margin-left-4 text-pre-wrap'>
        {props.children}
    </p>
)
const CodeLevel2 = ({ ...props }) => (
    <p className='margin-top-0 margin-bottom-0 margin-left-8 text-pre-wrap'>
        {props.children}
    </p>
)

export default ProviderMetrics;
