import semverLt from "semver/functions/lt";

import { getS3ObjectAsDataUrl } from "./s3Utils";

export const getStreamingServiceCodeSample = (targetUrl, serviceIdentifier) => `
// --- Node.js socket.io sample client for DIP Streaming services ---
// 1. Install Node.js v14 or higher, and npm: https://nodejs.org/en/
// 2. Create a directory for the project on your system.
// 3. Go to that directory in a terminal or commmand window and type: npm init. 
//    Take the defaults.  This will create a package.json file.
// 4. Type 'npm install socket.io-client' in the project directory.
// 5. Copy this code sample into a text file named app.js in your project folder.
//    Edit the values for serviceIdentifier, token, and apiKey.
// 6. Run the code using the command 'node app.js'
      
const { io } = require("socket.io-client");

// Provide a service identifier which maps to specific streaming target 
// as defined in the service API specifications
const serviceIdentifier = '${serviceIdentifier ?? "service identifier"}'

// Provide your access token
const token = 'access token'

// Provide your API key
const apikey = 'api key'
      
const socket = io(\`${targetUrl}?token=\${token}&apikey=\${apikey}&dip-service=\${serviceIdentifier}\`, {
  transports: [ "websocket" ]
});

socket.on("connect", () => {
  console.log('connected');
  setTimeout(()=> {
    socket.emit('join', '*');
  }, 1000);
});

socket.on("connect_error", () => {
  console.log('socket connect error, please check your parameters');
});

socket.on("disconnect", () => {
  console.log('disconnected');
});

socket.on('message', (msg) => {
  console.log(msg)
})
`;

export const getDataTransferServiceCodeSample = (sourceBucketName) => `
import boto3

# Create a profile in your home/.aws/credentials file with 
# your AWS account access key and secret
# Example:
# [dip-access]
# aws_access_key_id=your access key
# aws_secret_access_key=your secret key
#
# In this case, use arn:aws:iam::YourAWSAccountID:root when 
# asked for the arn in your subscription.
#
# You may also choose to create a named profile in home/.aws/config 
# if you are assuming a role.
# Example:
# [profile diptransfer]
# role_arn = arn:aws:iam::YourAWSAccountID:role/YourRoleName
# source_profile = dip-access
#
# In this case, use arn:aws:iam::YourAWSAccountID:role/YourRoleName 
# when asked for the arn in your subscription.
#
# Consult your AWS admin for the proper configuration for your AWS account.

# Create a session using the profile from the AWS credentials file.
# If using a role, use the profile from the AWS config file
session = boto3.Session(profile_name='dip-access')
s3 = session.client('s3')

# name of the DIP source bucket
bucket = '${sourceBucketName}'

# name of the object (file) in the bucket to get
key = 'path/to/object'
obj = s3.get_object(Bucket=bucket, Key=key)
content = obj['Body'].read()
print(content.decode('utf-8'))
`;

/**
 * @param {object} service
 * @returns whether or not the service is CDM service by inspecting the service's scope
 */
export const isCdmServiceByServiceScope = (service) => {
    return service && service.scope && service.scope.includes("cdmdata");
};

/**
 * @param {object} company
 * @returns whether or not the user is CDM user  by inspecting the company's scope
 */
export const isCdmUserByCompanyScope = (company) => {
    let isCdmUser = false;
    if (
        company &&
        company.allowed_oauth_scopes &&
        company.allowed_oauth_scopes.length > 0
    ) {
        for (let scope of company.allowed_oauth_scopes) {
            if (scope.includes("cdmdata")) {
                isCdmUser = true;
                break;
            }
        }
    }
    return isCdmUser;
};

export const getFileByType = (files, type) => {
    let rtn = null;
    if (files) {
        rtn = files.find((f) => f.type === type);
    }
    return rtn;
};

export const getServiceLogoDataUrl = async (service) => {
    let rtn = null;
    if (service) {
        if (service.files) {
            let service_logo_file = service?.files?.find(
                (f) => f.type === "service_logo"
            );
            let service_logo_file_path = service_logo_file?.path;
            rtn = await getS3ObjectAsDataUrl(service_logo_file_path);
        }
    }
    return rtn;
};

export const getServicesWithServiceLogoDataUrl = async (services) => {
    let rtn = [];
    if (services) {
        // Generate service_log_data_url for the subscription to display in the service card.
        let serviceLogoDataUrls = await Promise.all(
            services.map((s) => getServiceLogoDataUrl(s))
        );
        for (let [idx, s] of services.entries()) {
            rtn.push({
                ...s,
                service_logo_data_url:
                    serviceLogoDataUrls.length > idx
                        ? serviceLogoDataUrls[idx]
                        : null,
            });
        }
    }

    return rtn;
};


/**
 * Find the current version as the version with the highest version number.
 *
 * @param {array(object)} version  array of versions for the service
 *
 * @return the current version; right now this should be non-null, but
 *         @TODO when we add lifecycles, this may be null if a service
 *         has had all versions retired
 */
export const findCurrentVersion = (versions) => {
    let maxVersion = null;
    let maxVersionIdx = null;
    versions?.forEach(({ version_id }, idx) => {
        if (maxVersion === null || semverLt(maxVersion, version_id)) {
            maxVersion = version_id;
            maxVersionIdx = idx;
        }
    });
    // While shaking things out, report this as an error.  Once we add
    // lifecycles, @TODO this will no longer be an error but perhaps
    // just a warning
    if (!maxVersion) {
        console.error("Can't find version", maxVersion);
    }

    return versions[maxVersionIdx];
}