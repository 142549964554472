import { useState } from 'react';
import classnames from 'classnames';


/**
 * This provides an accordian control which is a button that, when clicked,
 * shows or hides content.  This Accordion is based on the USWDS accordion
 * which satisfies Section 508 accessibility requirements.
 *
 * @param {string}     id              base id for the accordion
 * @param {string}     title           text for the expand/collapse button
 * @param {array{JSX}} itemList        content to display when expanded
 * @param {boolean}    show            true to display expanded initially
 * @param {string}     accordionStyle  any additional USWDS styles to apply
 * @param {JSX}        children        children for the component
 * @param {string}     divProps        any additional properties to apply to
 *                                     the containing 'div'
 */
const Accordion = ({
    id,
    title,
    itemList,
    show=false,
    accordionStyle,
    children,
    ...divProps
}) => {
    const [expanded, setExpanded] = useState(show);

    const classes = classnames('usa-accordion', accordionStyle);

    return (
        <div className={classes} id={id} key={`accordion-${id}`} {...divProps} >
            <div className="usa-accordion__heading">
                <button
                    id={`${id}-button`}
                    type="button"
                    className="usa-accordion__button"
                    aria-expanded={expanded}
                    aria-controls={`${id}-content`}
                    onClick={(e) => setExpanded(!expanded)}
                >
                    {title}
                </button>
            </div>
            <div className="usa-accordion__content usa-prose"
                id={`${id}-content`} hidden={!expanded}
            >
                {
                    itemList && itemList.map((item, idx) => {
                        return (
                            {...item}
                        )
                    })
                }
                {
                    children
                }
            </div>
        </div>
    );
};

export default Accordion;
