import NotificationsIcon from '@mui/icons-material/Notifications';

import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Auth } from 'aws-amplify';

import { getUserId, hasUnreadNews } from '../../apiClient';
import { setAlert } from '../../app/slices/alert';
import { clearFilters, clearSearchText } from '../../app/slices/search';
import { selectNewsIntervalId, selectUnreadPlatformNews, 
         selectUnreadServiceNews, setNewsIntervalId, setUnreadPlatformNews, 
         setUnreadServiceNews
       } from '../../app/slices/news';
import { selectUserId, setField as setUserStateField 
       } from '../../app/slices/user';
import nasaLogo from '../../assets/NASA_logo_graduated_3x2_right.png';
import Header from './Header';
import NavDropDownMenu from './NavDropDownMenu';
import NavDropDownButton from './NavDropDownButton';
import NavList from './NavList';
import NavLogo from './NavLogo';
import NavCloseButton from './NavCloseButton';
import NavMenuButton from './NavMenuButton';
import { useAbortController } from '../../hooks';
import { isUserAuthenticated, getCurrentAuthenticatedUser,
         isCurrentUserInGroup, getAdminGroupName, hasISAVerificationToken 
       } from '../../utils/auth';
import { navigationLinks, profileLinks, URL_WELCOME, USER_TAG,
         NEWS, PLATFORM_NEWS, SERVICE_NEWS
       } from '../../utils/navigation';
import ActionButton from '../common/ActionButton';


/**
 * This is the top-level of the main navigation header for the Catalog.
 * It holds the header which in turn contains the logo and the navigation bar.
 */

const NavBar = ( ) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { abortSignalRef, isCancel } = useAbortController();

    let newsIntervalId = useSelector(selectNewsIntervalId);
    let unreadServiceNews = useSelector(selectUnreadServiceNews);
    let unreadPlatformNews = useSelector(selectUnreadPlatformNews);
    let userId = useSelector(selectUserId);

    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const onClick = () => {
        setShowMobileMenu((prvShowMobileMenu) => !prvShowMobileMenu)
    };

    const [greetingName, setGreetingName] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    let [userAuthenticated, setUserAuthenticated] = useState(false);
    let [ivtVerified, setIvtVerified] = useState(false);
    let [isMenuOpen, setIsMenuOpen] = useState({});

    const location = useLocation();

    // When the user navigates to a new page
    useEffect(() => {
        // Close all dropdown menus
        initIsMenuOpenFlags();

        // Hide the mobile menu
        setShowMobileMenu(false);
    }, [location])

    // If the user is logged in, set the user info and admin flag.
    useEffect(() => {
        initIsMenuOpenFlags();

        isUserAuthenticated()
        .then(res => {
            setUserAuthenticated(res);
            if (res) {
                getCurrentAuthenticatedUser()
                .then(userInfo => {
                    if (userInfo) {
                        if (userInfo['attributes'] &&
                            userInfo['attributes']['given_name'])
                        {
                            setGreetingName(userInfo['attributes']['given_name']);
                        }
                        else
                        {
                            setGreetingName(userInfo.username);
                        }

                        if (userInfo['attributes'] &&
                            userInfo['attributes']['email'])
                        {
                            const email = userInfo.attributes.email;
                            getUserId({email})
                            .then(async (resp) => {
                                const userId = resp.data.id;
                                dispatch(setUserStateField(
                                        { name: 'userId', value: userId }));
                            })
                            .catch(err => {
                                console.error('failed to get user id', err);
                            });
                        }
                        else
                        {
                            console.error('failed to get user id: no email');
                        }
                    }
                })
                .catch(err => {
                    console.error('failed to get authenticated user', err);
                });

                isCurrentUserInGroup(getAdminGroupName())
                .then(val => {
                    setIsAdmin(val);
                });

                hasISAVerificationToken()
                .then(res => {
                    setIvtVerified(res);
                })
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Once we have a user, set up a timer to periodically check for news 
    // updates.
    useEffect(() => {
        if (userId) {
            if ((typeof newsIntervalId === 'undefined') ||
                (newsIntervalId === null)) {

                // Get the current status
                setTimeout(checkForUnreadNews, 0);

                // Re-check every 12 hours
                const intervalId = setInterval(checkForUnreadNews, 12*60*60*1000);
                dispatch(setNewsIntervalId(intervalId));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);


    // Initialize the is-menu-open flags
    const initIsMenuOpenFlags = () => {
        let update = {}
        navigationLinks.forEach(element => {
            if (!!element.menu) {
                update[element.menu.menuId] = false;
            }
        });
        setIsMenuOpen(update);
    }

    /**
     * Queries the back end to see if there is any unread news for this user.
     */
    const checkForUnreadNews = async () => {
        try {
            const res = await hasUnreadNews({ userId: userId, 
                                 abortSignal: abortSignalRef?.current });
            const news = res?.data;
            if (!!news) {
                dispatch(setUnreadServiceNews(news.has_unread_service_news));
                dispatch(setUnreadPlatformNews(news.has_unread_platform_news));
            }
        } catch (error) {
            if (isCancel(error)) {
                return;
            }
            console.error('Failed to get unread news status', error);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get unread news status',
                severity: 'error'
            }));
            return null;
        }
    };

    /**
     * Gets menu items to display based on a specification array.
     *
     * @param {array(object)} menuSpec               specification for each
     *                                               menu item
     * @param {string}        menuSpec.id            unique identifier for the
     *                                               menu item
     * @param {string}        menuSpec.name          name of the menu item
     * @param {string}        menuSpec.link          URL if the menu item is
     *                                               a link
     * @param {string}        menuSpec.menu          specification if the menu
     *                                               item is a menu
     * @param {string}        menuSpec.menu.menuId   unique identifier of the
     *                                               submenu
     * @param {string}        menuSpec.menu.items    specification for each
     *                                               submenu option; this spec
     *                                               has the same attributes as
     *                                               menuSpec
     * @param {string}        menuSpec.requiresAuth  true if the menu item
     *                                               requires use authentication
     *                                               for access
     * @param {string}        menuSpec.requiresAdmin true if the menu item is
     *                                               only allowed for the admin
     *                                               user
     * @param {string}        type                   type of menu: 'primary'
     *                                               for the main menu bar or
     *                                               'subnav' for a sub-menu
     * @param {string}        tag                    if set, replaces menu item
     *                                               of name=tag with
     *                                               replacementTag
     * @param {string}        replacementTag         if set, replaces menu item
     *                                               of name=tag with
     *                                               replacementTag
     */
    const getMenuItems = (menuSpec, type, tag, replacementTag) => {
        let items = [];
        if (menuSpec) {
            items = menuSpec.map((item, linkIdx) => {
                // Create a menu item that is a basic link
                if (!!item.link) {
                    if (
                        (
                            (Boolean(item.requiresAuth) && userAuthenticated) ||
                             !Boolean(item.requiresAuth)
                        ) &&
                        (
                            (Boolean(item.requiresIvt) && ivtVerified) ||
                            !Boolean(item.requiresIvt)
                        ) &&
                        (
                            (Boolean(item.requiresAdmin) && isAdmin) ||
                             !Boolean(item.requiresAdmin)
                        )
                    ) {

                        let name = item.name;
                        if (!!tag && name === tag) {
                            name = replacementTag;
                        }
                        let showNotification = !!item.checkNotification &&
                              showNotificationIcon(name);

                        return (
                            <Link
                                id={item.id}
                                key={item.id}
                                to={item.link}
                                className={`usa-nav__link ${showMobileMenu ? 'text-primary' : 'text-white' }`}
                            >
                                {name}
                                { showNotification &&
                                  getNotificationIcon(name)
                                }
                            </Link>
                        )
                    } else {
                        return null;
                    }

                // Create a menu item that is a submenu
                } else if (!!item.menu) {
                    if (
                        (
                            (Boolean(item.requiresAuth) && userAuthenticated) ||
                            !Boolean(item.requiresAuth)
                        ) &&
                        (
                            (Boolean(item.requiresIvt) && ivtVerified) ||
                            !Boolean(item.requiresIvt)
                        )
                    ) {

                        let name = item.name;
                        if (!!tag && name === tag) {
                            name = replacementTag;
                        }
                        let showNotification = !!item.checkNotification &&
                              showNotificationIcon(name);

                        return (
                            <Fragment key={item.id}>
                                <NavDropDownButton
                                    id={item.id}
                                    label={name}
                                    menuId={item.menu.menuId}
                                    className={`${showMobileMenu ? 'text-primary' : 'text-white' }`}
                                    icon={ showNotification &&
                                           getNotificationIcon(name)
                                    }
                                    onToggle={() => {
                                        setIsMenuOpen({
                                            ...isMenuOpen,
                                            [item.menu.menuId]: !isMenuOpen[item.menu.menuId]
                                        });
                                    }}
                                    isOpen={!!isMenuOpen[item.menu.menuId]}
                                />
                                <NavDropDownMenu
                                    id={item.menu.menuId}
                                    items={getMenuItems(item.menu.items, 'subnav', tag, replacementTag)}
                                    type='subnav'
                                    isOpen={!!isMenuOpen[item.menu.menuId]}
                                />
                            </Fragment>
                        )
                    } else {
                        return null;
                    }
                } else if (!!item.action && (item.action === 'logout')) {
                    if ((Boolean(item.requiresAuth) && userAuthenticated) ||
                        !Boolean(item.requiresAuth)) {

                        return (
                            <ActionButton
                                id={item.id}
                                key={item.id}
                                type="button"
                                buttonStyle='logout-button padding-x-2 padding-y-1 margin-0'
                                onClick={handleLogout}
                                text={item.name}
                            />
                        )
                    } else {
                        return null;
                    }
                } else {
                    console.error('Unhandled menu spec', item);
                    return null;
                }
            })
        }
        items = items.filter( item  => item !== null);

        return items;
    };

    /**
     * Determines if the given menu item should have the notification icon
     * displayed by checking the "unread news" flag for that menu item.
     *
     * @param {string} itemName   name of a menu item
     *
     * @return {boolean} true to show the notification icon
     */
    const showNotificationIcon = (itemName) => {
        let showNotification = false;
        if ((itemName === PLATFORM_NEWS && unreadPlatformNews) ||
            (itemName === SERVICE_NEWS && unreadServiceNews) ||
            (itemName === NEWS && (unreadServiceNews || unreadPlatformNews))) {

            showNotification = true;
        }

        return showNotification;
    };

    /**
     * Returns the JSX for the notification icon for the main menu.
     *
     * @param {string} itemName   menu item name, used for the image alt-text
     *
     * @return {object} JSX for the notification icon
     */
    const getNotificationIcon = (itemName) => (
        <NotificationsIcon
            alt={`There is new ${itemName}`}
            fontSize="small"
            className='bell-icon'
        />
    );

    /**
     * Returns the JSX for the notification icon for the mobile menu.
     *
     * @return {object} JSX for the mobile menu notification icon
     */
    const getMobileNotificationIcon = () => {
        if (!needMobileNotification(navigationLinks)) {
            return null;
        }
        return (
            <NotificationsIcon
                alt={`There is new ${NEWS}`}
                fontSize="small"
                className='mobile-bell-icon'
            />
        );
    }

    /**
     * Determines if the notification should be displayed for *any* item
     * in the given menu.
     *
     * @param {array(object)} menuSpec  specification for each menu item.  See
     *                                  #getMenuItems for a full description
     *
     * @return true if any menu item should have a notification displayed
     */
    const needMobileNotification = (menuSpec) => {
        let show = false
        if (menuSpec) {
            show = menuSpec.some((item) => 
                       !!item.checkNotification &&
                       showNotificationIcon(item.name));
        }

        return show;
    };

    /**
     * Logs the user out.
     */
    const handleLogout = async () => {
        clearInterval(newsIntervalId);
        dispatch(setNewsIntervalId(null));
        setShowMobileMenu(false);
        try {
            dispatch(clearFilters());
            dispatch(clearSearchText());

            await Auth.signOut();

            navigate(URL_WELCOME, { replace: true });
        } catch (error) {
            console.error('failed to sign out:', error);
            dispatch(setAlert({
                show: true,
                message: 'Failed to sign out',
                severity: 'error'
            }));
        };
    };

    // Get the menu items:  the main menu bar and the profile menu
    const mainNavMenuItems = getMenuItems(navigationLinks, 'primary');
    const profileNavMenuItems = getMenuItems(profileLinks, 'primary',
        USER_TAG, greetingName);
    const both = mainNavMenuItems.concat(profileNavMenuItems);

    return (
        <>
            { /* Gray overlay on main content when mobile menu is shown */ }
            <div
                className={`site-overlay ${showMobileMenu ? 'is-visible' : ''}`}
            >
            </div>

            <Header>
                <div className="site-nav-container " >
                    <div className="nav-sub-container" >
                        <div className='mobile-menu'>
                            <NavMenuButton 
                                id='mobile-menu-open' 
                                onClick={onClick}
                            />
                            {
                                getMobileNotificationIcon()
                            }
                        </div>

                        <NavLogo />
                    </div>

                    <nav aria-label="Primary navigation"
                       className={`usa-nav ${showMobileMenu ? 'is-visible' : ''}`}
                    >
                        <NavCloseButton
                            id='mobile-menu-close'
                            onClick={onClick}
                        />
                        <NavList
                            id='main-navigation-menu'
                            type='primary'
                            items={both}
                            isOpen='true'
                        />
                    </nav>
                    <img src={nasaLogo} alt='NASA logo' className='nasa-logo' />
                </div>
            </Header>
        </>
    );
}

export default NavBar;
