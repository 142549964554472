import classnames from 'classnames';

/**
 * This creates a label to display for the situation of
 * "no matching XXX was found."
 *
 * @param {string}   label        the label displayed to the user
 * @param {string}   labelStyle   additional USWDS style classes to add to the
 *                                label, e.g. 'margin-top-0' to remove a margin
 */
const NoMatchLabel = ({ label, labelStyle }) => {

    const classes = classnames('usa-label', 'text-center', 'text-bold',
        'font-sans-md', labelStyle);

    return (
        <div className={classes}>
            {label}
        </div>
    );
};

export default NoMatchLabel;
