import Grid from '@mui/material/Grid';

import SectionHeader from "../common/SectionHeader";
import Select from "../common/Select";
import TextArea from "../common/TextArea";
import TextField from "../common/TextField";

/**
 * This provides the user interface for the data transfer source parameters.
 * This is on the main service registration page.
 */
const DataTransferSource = ({
    input,
    handleInputChange,
    readOnly,
    formErrors
}) => {
    return (
        <Grid item container rowSpacing={2} columnSpacing={1}>
            <SectionHeader title='Data Transfer Source' />
            <Grid item xs={12}>
                <TextField
                    id='dts-bucket'
                    name='name'
                    label='Transfer Source Bucket Name'
                    labelStyle='margin-top-0'
                    value={input?.name}
                    onChange={handleInputChange}
                    required
                    disabled={readOnly}
                    errorMessage={formErrors?.name}
                />
            </Grid>
            <Grid item xs={12}>
                <Select
                    id='source-platform'
                    name='source_platform'
                    label='Transfer Source Platform'
                    labelStyle='margin-top-0'
                    value={input?.source_platform}
                    itemList={optionList}
                    onChange={handleInputChange}
                    required
                    disabled={readOnly}
                />
            </Grid>

            <Grid item xs={12}>
                <TextArea
                    id='dts-description'
                    name='description'
                    label='Transfer Source Description'
                    labelStyle='margin-top-0'
                    value={input?.description}
                    onChange={handleInputChange}
                    required
                    disabled={readOnly}
                    errorMessage={formErrors?.description}
                />
            </Grid>
        </Grid>
    );
};

const optionList = [
   {
       id: 'aws',
       value:'AWS',
   }
];

export default DataTransferSource;
