import Grid from '@mui/material/Grid';

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { createProxyApi, deleteProxyApi } from '../../apiClient';
import { setAlert } from '../../app/slices/alert';
import { selectCompanyAbbreviation } from '../../app/slices/user';
import { useAbortController } from '../../hooks';
import { checkServiceType, ServiceTypeUtils } from '../../utils/utils';
import { VERSION_STATUS_OPTIONS } from '../../utils/serviceRegistration';
import ActionButton from '../common/ActionButton';
import AlertInline from '../common/AlertInline';
import CircularProgressIndicator from '../common/CircularProgressIndicator';
import SectionHeader from "../common/SectionHeader";
import Select from '../common/Select';
import TextField from '../common/TextField';

/**
 * This provides additional actions for the DIP Admin when creating/editing
 * a service version, such as the ability to set the version status, the
 * API Gateway URL (REST services), the API Gateway ID (REST services),
 * and API ID (non-REST services),
 */

const WizardVersionAdminBasic = ({
    input,
    setInput,
    handleInputChange,
    readOnly,
    isEditingRestricted,
    serviceType,
    formErrors
}) => {

    const { abortSignalRef, isCancel } = useAbortController();

    const [searchParams] = useSearchParams();

    const dispatch = useDispatch();

    const companyAbbrev = useSelector(selectCompanyAbbreviation);

    const serviceId = useMemo(() => searchParams.get('serviceId'), [searchParams]);

    const [showCreateApiProxyBtn, setShowCreateApiProxyBtn] = useState(false);
    const [createApiProxyErrorMsg, setCreateApiProxyErrorMsg] = useState(false);
    const [performingApiProxyOp, setCreatingApiProxy] = useState(false);

    const [showDeleteApiProxyBtn, setShowDeleteApiProxyBtn ] = useState(false);
    const [deleteApiProxyErrorMsg, setDeleteApiProxyErrorMsg] = useState(false);

    useEffect(() => {
        // show create api proxy button if the service is REST type and api gateway url is not assigned
        setShowCreateApiProxyBtn(
            checkServiceType(serviceType, ServiceTypeUtils.TYPES.REST) &&
            !input.url &&
            input.status !== 'creating'
        );

        // show delete api proxy button if the service is REST type and api gateway id and url are assigned
        setShowDeleteApiProxyBtn(
            checkServiceType(serviceType, ServiceTypeUtils.TYPES.REST) &&
            input.api_gateway_id && input.url &&
            input.status !== 'creating'
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceType, input]);

    const canCreateProxyApi = () => {
        let msg = null;
        if (!input.name) {
            msg = `Invalid service name=${input.name}`;
        } else if (!input.version) {
            msg = `Invalid version id=${input.version}`;
        } else if (!companyAbbrev) {
            msg = `Invalid company abbreviation=${companyAbbrev}`;
        } else if (!serviceId) {
            msg = `Invalid service id=${serviceId}`;
        }
        setCreateApiProxyErrorMsg(msg);
        return !msg;
    }

    const canDeleteProxyApi = () => {
        let msg = null;
        if (!input.api_gateway_id) {
            msg = `Invalid api gateway ID=${input.api_gateway_id}`;
        } else if (!input.version) {
            msg = `Invalid version id=${input.version}`;
        } else if (!serviceId) {
            msg = `Invalid service id=${serviceId}`;
        }
        setDeleteApiProxyErrorMsg(msg);
        return !msg;
    }

    const handleCreateProxyApi = async () => {
        if (canCreateProxyApi()) {
            try {
                setCreatingApiProxy(true);
                let body = {
                    service_name: input.name,
                    company_name_abbrev: companyAbbrev,
                    service_id: serviceId,
                    version_id: input.version,
                    old_api_gateway_id: input.api_gateway_id,
                }
                let res = await createProxyApi({ body, abortSignal: abortSignalRef?.current })
                setInput({
                    ...input,
                    api_gateway_id: res.data.api_id,
                    url: res.data.url
                })
                dispatch(setAlert({
                    show: true,
                    message: `Successfully created the proxy API`,
                    severity: 'success'
                }));
            } catch (err) {
                if (isCancel(err))
                    return;

                console.error(`Failed to create proxy api:`, err.message);
                dispatch(setAlert({
                    show: true,
                    message: 'Failed to create proxy api',
                    severity: 'error'
                }));
            } finally {
                setCreatingApiProxy(false);
            }
        }
    }

    const handleDeleteProxyApi = async () => {
        if (canDeleteProxyApi()) {
            try {
                setCreatingApiProxy(true);
                let res = await deleteProxyApi({
                    service_id: serviceId,
                    version_id: input.version,
                    api_id: input.api_gateway_id,
                    abortSignal: abortSignalRef?.current
                })
                setInput({
                    ...input,
                    api_gateway_id: res.data.api_id,
                    url: '',
                })
                dispatch(setAlert({
                    show: true,
                    message: `Successfully deleted the proxy API`,
                    severity: 'success'
                }));
            } catch (err) {
                if (isCancel(err))
                    return;

                console.error(`Failed to delete proxy api:`, err.message);
                dispatch(setAlert({
                    show: true,
                    message: 'Failed to delete proxy api',
                    severity: 'error'
                }));
            } finally {
                setCreatingApiProxy(false);
            }
        }
    }

    return (
        <Grid container item spacing={2}>
            <Grid item xs={12}>
                <SectionHeader
                    title='Admin Controls'
                    labelStyle='margin-top-3'
                />
            </Grid>
            <Grid item xs={12} >
                <Select
                    id='admin-status-select'
                    name='status'
                    label='Status'
                    labelStyle='margin-top-0'
                    value={input?.status || ''}
                    onChange={handleInputChange}
                    disabled={readOnly}
                    itemList={
                        VERSION_STATUS_OPTIONS.map(status => {
                            return ({
                                id: status,
                                value: status
                            })
                        })
                    }
                />
            </Grid>
            {
                checkServiceType(serviceType, ServiceTypeUtils.TYPES.REST) && (
                    <Grid item xs={12}>
                        <TextField
                            id='api-gateway-url'
                            name='url'
                            label='API Gateway URL'
                            labelStyle='margin-top-0'
                            value={input.url}
                            onChange={handleInputChange}
                            disabled={isEditingRestricted || readOnly}
                            errorMessage={formErrors?.gatewayUrl}
                            help='The DIP public URL for the API.
                                 This must be the base URL for the service
                                 including any root paths needed before the
                                 proxy resource path.'
                        />
                    </Grid>
                )
            }
            <Grid item xs={12}>
                <TextField
                    id='api-gateway-id'
                    name='api_gateway_id'
                    label={checkServiceType(serviceType, ServiceTypeUtils.TYPES.REST) ? 'API Gateway ID' : 'API ID'}

                    labelStyle='margin-top-0'
                    value={input.api_gateway_id}
                    onChange={handleInputChange}
                    disabled={isEditingRestricted || readOnly}
                    errorMessage={formErrors?.apiGatewayId}
                    required={checkServiceType(serviceType, ServiceTypeUtils.TYPES.STREAMING)}
                />
            </Grid>
            {
                showCreateApiProxyBtn && (
                    <>
                        <Grid item xs={12} >
                            {
                                performingApiProxyOp ? (
                                    <CircularProgressIndicator 
                                        altText='Creating API proxy...'
                                    />
                                ) : (
                                    <div>
                                        <ActionButton
                                            text='Create API Proxy'
                                            onClick={handleCreateProxyApi}
                                            disabled={isEditingRestricted || readOnly}
                                        />
                                    </div>
                                )
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <AlertInline errorMessage={createApiProxyErrorMsg} />
                        </Grid>
                    </>
                )
            }
            {
                showDeleteApiProxyBtn && (
                    <>
                        <Grid item xs={12} >
                            {
                                performingApiProxyOp ? (
                                    <CircularProgressIndicator
                                        altText='Deleting API proxy...'
                                    />
                                ) : (
                                    <div>
                                        <ActionButton
                                            text='Delete API Proxy'
                                            onClick={handleDeleteProxyApi}
                                            disabled={isEditingRestricted || readOnly}
                                        />
                                    </div>
                                )
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <AlertInline errorMessage={deleteApiProxyErrorMsg} />
                        </Grid>
                    </>
                )
            }
        </Grid >
    )
};

export default WizardVersionAdminBasic;
