import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCompanies, getServices } from '../../apiClient';
import { setDataTransferServicesLaunchedForCompany,
         setDataTransferServicesNotLaunched,
         setRestServicesLaunchedForCompany,
         setRestServicesNotLaunched,
         setSftpServicesLaunchedForCompany,
         setSftpServicesNotLaunched,
         setStreamingServicesLaunchedForCompany,
         setStreamingServicesNotLaunched
       } from '../../app/slices/admin';
import { setAlert } from '../../app/slices/alert';
import { setLinks as setBreadcrumbs } from '../../app/slices/breadcrumbs';
import { setPageTitle } from '../../app/slices/page';
import MajorHeading from '../../components/common/MajorHeading';
import CompanySelect, { ALL_COMPANIES_ID } from '../../components/common/CompanySelect';
import LinkButton from '../../components/common/LinkButton';
import ServiceList from '../../components/service-registration/ServiceList';
import { useAbortController } from '../../hooks';
import { getServiceRegisterPage } from '../../utils/serviceRegistration';
import { checkServiceType, ServiceTypeUtils } from '../../utils/utils';
import { URL_ADMIN, URL_HOME } from '../../utils/navigation';
import { getServicesWithServiceLogoDataUrl } from '../../utils/serviceDetail';


/**
 * This provides the list of registered services for the given service type
 * for the Administrator.  From this page an Administrator can search for
 * services or edit services.
 */
const RegisteredServicesAdmin = ({ serviceType }) => {

    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();

    const [companies, setCompanies] = useState([]);
    const servicesNotLaunched = useSelector(state => state.admin[`${ServiceTypeUtils.TYPE_TO_ADMIN_STORE_SLICE_PREFIX[serviceType]}ServicesNotLaunched`]);
    const servicesLaunchedForCompany = useSelector(state => state.admin[`${ServiceTypeUtils.TYPE_TO_ADMIN_STORE_SLICE_PREFIX[serviceType]}ServicesLaunchedForCompany`]);

    const [selectedCompany, setSelectedCompany] = useState(null);

    const [loadingNotLaunched, setLoadingNotLaunched] = useState(false);
    const [loadingLaunched, setLoadingLaunched] = useState(false);

    const breadcrumbs = [
        { name: 'Home', href: URL_HOME },
        { name: 'Admin', href: URL_ADMIN },
        { name: `${ServiceTypeUtils.TYPE_TO_STRING[serviceType]} Services`, href: null }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setBreadcrumbs(breadcrumbs));
        dispatch(setPageTitle(`${ServiceTypeUtils.TYPE_TO_STRING[serviceType]} Service Administration`));
        initCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // side effect when selectedCompany changes
    useEffect(() => {
        // get all services with status != launched for the selected company
        getServicesNotLaunched(selectedCompany?.id || null);

        // get all services with status = launched for the selected company
        getServicesLaunchedForCompany(selectedCompany?.id || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany])

    const initCompanies = async () => {
        getCompanies({ abortSignal: abortSignalRef?.current })
        .then(res => {
            setCompanies([...(res.data?.companies ?? [])]);
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to get companies:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get companies',
                severity: 'error'
            }));
        });
    };

    const handleCompanySelect = (newSelectedCompany) => {
        if (newSelectedCompany.id === ALL_COMPANIES_ID) {
            setSelectedCompany(null);
        } else {
            setSelectedCompany(newSelectedCompany);
        }
    };

    const setServicesNotLaunched = (services) => {
        switch (serviceType) {
            case ServiceTypeUtils.TYPES.REST:
                dispatch(setRestServicesNotLaunched(services));
                break;
            case ServiceTypeUtils.TYPES.STREAMING:
                dispatch(setStreamingServicesNotLaunched(services));
                break;
            case ServiceTypeUtils.TYPES.DATA_TRANSFER:
                dispatch(setDataTransferServicesNotLaunched(services));
                break;
            case ServiceTypeUtils.TYPES.SFTP:
                dispatch(setSftpServicesNotLaunched(services));
                break;
            default:
                console.error('Invalid service type: ', serviceType);
                break;
        }
    };

    const setServicesLaunched = (services) => {
        switch (serviceType) {
            case ServiceTypeUtils.TYPES.REST:
                dispatch(setRestServicesLaunchedForCompany(services));
                break;
            case ServiceTypeUtils.TYPES.STREAMING:
                dispatch(setStreamingServicesLaunchedForCompany(services));
                break;
            case ServiceTypeUtils.TYPES.DATA_TRANSFER:
                dispatch(setDataTransferServicesLaunchedForCompany(services));
                break;
            case ServiceTypeUtils.TYPES.SFTP:
                dispatch(setSftpServicesLaunchedForCompany(services));
                break;
            default:
                console.error('Invalid service type: ', serviceType);
                break;
        }
    };

    const getServicesWithStatus = async (companyId, launched, loadingSetter, serviceSetter) => {
        try {
            loadingSetter(true);
            let res = await getServices({
                provider_id: companyId,
                is_launched: launched,
                service_type: serviceType,
                abortSignal: abortSignalRef?.current
            })

            let newServices = await getServicesWithServiceLogoDataUrl(res?.data?.services ?? []);
            serviceSetter(newServices)
        } catch (error) {
            if (isCancel(error))
                return;

            console.error('failed to get services:', error);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get services',
                severity: 'error'
            }));
        } finally {
            loadingSetter(false);
        }
    }

    /**
     * Get services with status != launched for company
     * if companyId is null, get services for all companies
     * @param {*} companyId
     */
    const getServicesNotLaunched = async (companyId) => {
        getServicesWithStatus(companyId, false, setLoadingNotLaunched, setServicesNotLaunched);
    };

    /**
     * Get services with status = launched and given companyId
     * If companyId is null, get services for all companies
     * @param {*} companyId
     */
    const getServicesLaunchedForCompany = async (companyId) => {
        getServicesWithStatus(companyId, true, setLoadingLaunched, setServicesLaunched);
    };

    return (
        <Card id={`registered-services-${serviceType}`} sx={{ p: '1rem' }}>
            <CardContent>
                {
                    (
                        checkServiceType(serviceType, ServiceTypeUtils.TYPES.STREAMING) ||
                        checkServiceType(serviceType, ServiceTypeUtils.TYPES.DATA_TRANSFER) ||
                        checkServiceType(serviceType, ServiceTypeUtils.TYPES.SFTP)
                    ) &&
                    <Box sx={{ textAlign: 'end', mb: '1rem' }}>
                        <LinkButton
                            text={`Register ${ServiceTypeUtils.TYPE_TO_STRING[serviceType]} Service`}
                            href={URL_ADMIN + getServiceRegisterPage(null, serviceType)}
                        />
                    </Box>
                }

                <CompanySelect
                    companyOptions={companies}
                    handleCompanySelect={handleCompanySelect}
                    selectedCompany={selectedCompany}
                    showAllOption={true}
                />

                {/* Service list for service status != launched */}
                <Divider
                    sx={{
                        backgroundColor: 'primary',
                        my: '2rem'
                    }}
                />

                <MajorHeading
                    label={`Services Not Launched for ${selectedCompany ? selectedCompany.name : 'All'}`}
                    loading={loadingNotLaunched}
                />

                <ServiceList
                    services={servicesNotLaunched}
                    admin={true}
                    serviceType={serviceType}
                />

                {/* Service list for service status = launched */}
                <Divider
                    sx={{
                        backgroundColor: 'primary',
                        my: '2rem'
                    }}
                />

                <MajorHeading
                    loading={loadingLaunched}
                    label={`Launched Services for ${selectedCompany ? selectedCompany.name : 'All'}`}
                />

                <ServiceList
                    services={servicesLaunchedForCompany}
                    admin
                    serviceType={serviceType}
                />
            </CardContent>
        </Card>
    )
};

export default RegisteredServicesAdmin;
