import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // registered services
  services: []
}

export const registeredServicesSlice = createSlice({
  name: 'registeredServices',
  initialState,
  reducers: {
    setServices: (state, action) => {
      state.services = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setServices } = registeredServicesSlice.actions

export const selectServices = state => state.registeredServices.services;

export default registeredServicesSlice.reducer