import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { isQosOther } from '../../utils/qosMetrics';
import Accordion from '../common/Accordion';
import ActionButton from '../common/ActionButton';
import BodyText from '../common/BodyText';
import BodyTitle from '../common/BodyTitle';


/**
 * This provides the display of a Quality of Service data for a single
 * metric.  A collapsible card shows the metric, its value, and its
 * definition in the header.  When expanded, the card displays the
 * description and the method of computation.  Buttons will allow the user
 * to edit or delete the metric.
 *
 * @param {string}   id              id for the metric
 * @param {object}   qosParameter    the QoS metric being displayed
 * @param {boolean}  editable        true to include the Edit and Delete buttons
 * @param {function} editAction      action handler upon edit
 * @param {function} removeAction    action handler upon delete
 * @param {object}   qosDialogRef    React useRef for the QoS add/edit dialog
 * @param {object}   deleteDialogRef React useRef for the QoS delete dialog
 * @param {object}   props           other props to apply to the expanded card
 *                                   fields
 */
const QualityOfServiceCollapsibleCard = ({
    id,
    qosParameter,
    editable=false,
    editAction,
    removeAction,
    qosDialogRef,
    deleteDialogRef,
    ...props
}) => {

    let label = isQosOther(qosParameter?.parameterType) ?
                    qosParameter.customType :
                    qosParameter.parameterType;

    return (
        <Accordion
            id={id}
            title={(
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <BodyTitle
                            label={label}
                            labelStyle='inline-flex margin-y-0 padding-y-0'
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <BodyText
                            label={qosParameter?.value}
                            labelStyle='inline-flex margin-y-0 padding-y-0 text-primary text-bold'
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BodyText
                            label={qosParameter?.definition}
                            labelStyle='inline-flex margin-y-0 padding-y-0'
                        />
                    </Grid>
                </Grid>
            )}
            aria-label={isQosOther(qosParameter?.parameterType) ?
                qosParameter.customType : qosParameter.parameterType}
            itemList={[
                <Stack direction='row' key='qos-stack'
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Stack direction='column'>
                        <QosInfoField name='Description'
                            value={qosParameter?.description}
                        />
                        <QosInfoField name='Method'
                            value={qosParameter?.method}
                        />
                    </Stack>
                    {
                        editable &&
                        <Stack direction='column' >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'end',
                                    paddingTop: '.5rem'
                                }}
                            >
                                <ActionButton text='Edit'
                                    onClick={editAction}
                                    buttonStyle='margin-right-1'
                                    data-open-modal
                                    aria-controls={qosDialogRef?.current?.modalId}
                                />
                                <ActionButton text='Delete' isDelete
                                    onClick={removeAction}
                                    data-open-modal
                                    aria-controls={deleteDialogRef?.current?.modalId}
                                />
                            </Box>
                        </Stack>
                    }
                </Stack>
            ]}
        />
    );
};

// This is a generic component for the elements in the expanded card
const QosInfoField = ({ name, value, ...props }) => (
    <Grid item
        xs={12}
        sx={{
            p: '.3rem',
            wordWrap: 'break-word'
        }}
        {...props}
    >
        <span><b>{name}:  </b></span>{value}
    </Grid>
);

export default QualityOfServiceCollapsibleCard;
