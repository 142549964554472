/**
 * This creates an alert that is expected to display inline.
 */

const AlertInline = ({ errorMessage='' }) => {
    return (
        <>
            { !!errorMessage &&
                <span className='usa-error-message' role='alert' >
                    {errorMessage}
                </span>
            }
        </>
    );
};

export default AlertInline;
