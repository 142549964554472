import Grid from '@mui/material/Grid';

import { Fragment } from 'react';

import { getApiSpecPageLink } from '../../utils/openApi';
import ActionButton from '../common/ActionButton';
import CircularProgressIndicator from '../common/CircularProgressIndicator';
import LinkButton from '../common/LinkButton';


/**
 * This provides the Connect, API, and/or Subscribe/Unsubscribe buttons
 * for a service version on the Service Detail page.
 */
const ServiceVersionButtons = ({
    serviceId,
    serviceName,
    serviceVersion,
    serviceType,
    companyId,
    providerId,
    hideApiButton,
    handleOpenConnectDialog,
    disableSubscribeButton,
    handleSubscriptionButtonClick,
    handleOpenUnsubscribeDialog,
    handlingSubscription,
    subscriptionHandlingVersionId,  // version ID that's getting updated
    apiSpecFileLocation,
    connectDialogRef,
    unsubscribeDialogRef,
    dtsDialogRef,
}) => {

    const getSubscribeButton = () => {
        let btnProps = {
            onClick: () => handleSubscriptionButtonClick(serviceVersion.version_id),
        }
        let BtnWrapper = null;
        let text = 'Subscribe';
        if (disableSubscribeButton) {
            btnProps['disabled'] = disableSubscribeButton;
            text = 'Restricted Access';
            BtnWrapper = ({ children, ...props }) => (
                <span> {children} </span>
            );
        } else {
            BtnWrapper = ({ children, ...props }) => <Fragment {...props}>{children}</Fragment>;
            btnProps['data-open-modal'] = true;
            btnProps['aria-controls'] = dtsDialogRef?.current?.modalId;
        }

        return (
            <BtnWrapper>
                <ActionButton text={text}
                    {...btnProps}
                />
            </BtnWrapper>
        );
    }

    return (
        <Grid item
            id='service-buttons'
            xs={12}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                mt: {
                    xs: '0.5rem',
                    sm: 0
                }
            }}
        >
            {
                !handlingSubscription &&
                serviceVersion.subscribed &&
                <ActionButton text='Connect'
                    onClick={() => handleOpenConnectDialog(
                                       serviceVersion.url,
                                       serviceVersion.service_identifier)}
                    buttonStyle='margin-right-2'
                    data-open-modal
                    aria-controls={connectDialogRef?.current?.modalId}
                />
            }

            {
                !hideApiButton &&
                <LinkButton text='API'
                    href={getApiSpecPageLink(serviceType, serviceName, apiSpecFileLocation, serviceId, providerId, serviceVersion.url, companyId, serviceVersion.version_id)}
                    disabled={!apiSpecFileLocation}
                    style={{ marginRight: '1rem' }}
                />
            }

            {
                handlingSubscription &&
                subscriptionHandlingVersionId === serviceVersion.version_id ? (
                    <CircularProgressIndicator
                        type='large'
                        altText='Subscribing to service version...'
                    />
                ) : (
                    serviceVersion.subscribed ? (
                        <ActionButton text='Unsubscribe'
                            onClick={() => handleOpenUnsubscribeDialog(serviceVersion.version_id)}
                            data-open-modal
                            aria-controls={unsubscribeDialogRef?.current?.modalId}
                        />
                    ) : (
                        getSubscribeButton()
                    )
                )
            }

        </Grid>
    );
};

export default ServiceVersionButtons;
