import { useEffect, useState } from 'react';

import SelectObject from '../common/SelectObject';
import { UNIT_FILTER_OPTIONS } from '../../utils/serviceMetrics';

/**
 * This provides the controls for a user to select time units for a 
 * service metrics graph.
 *
 * @param {function}   handleUnitSelect   selection handler; note this
 *                                        should handle just the selected
 *                                        unit, NOT the selection event
 * @param {object}     selectedTimeRange  units to select
 * @param {array(int)} supportedUnitIds   list of id's of UNIT_FILTER_OPTIONS 
 *                                        which can be displayed based on the 
 *                                        currently selected time range; if
 *                                        unset, show all options
 * @param {boolean}    disabled           true to disable the control
 */

const GraphUnitSelect = ({
    handleUnitSelect,
    selectedUnit,
    supportedUnitIds,
    disabled=false
}) => {

    const [filterOptions, setFilterOptions] = useState(UNIT_FILTER_OPTIONS);

    useEffect(() => {
        if (supportedUnitIds?.length > 0) {
            const filterOptions = 
                UNIT_FILTER_OPTIONS.filter(o => supportedUnitIds.includes(o.id));

            // if previously selected unit is not supported, 
            // update the selected unit with the last supported unit in the 
            // supportedUnitIds array.
            if (!supportedUnitIds.includes(selectedUnit.id)) {
                handleUnitSelect(
                    { ...filterOptions[filterOptions.length - 1] });
            }

            setFilterOptions([...filterOptions]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportedUnitIds]);

    return (
        <SelectObject
            id='common-graph-unit-select'
            name='graph-unit-select'
            label='Graph Unit'
            labelStyle='margin-top-0'
            value={selectedUnit}
            itemList={filterOptions}
            valueAttribute='name'
            handleSelect={handleUnitSelect}
            disabled={disabled}
        />
    )
};

export default GraphUnitSelect;
