
/**
 * The NavDropDownButton is a menu item on a menu bar which, when clicked,
 * opens a sub-menu (namely, a NavDropDownMenu).
 *
 * @param {string}   id         unique identifier
 * @param {string}   label      the menu item name to display
 * @param {string}   menuId     the ID of the menu this button controls
 * @param {boolean}  isOpen     true if the menu should be opened
 * @param {object}   icon       optional icon to display next to the label
 * @param {function} onToggle   click-handler of the menu item; this toggles
 *                              the menu opened/closed
 * @param {string}   className  additional styling classes to apply to the
 *                              menu item
 */
const NavDropDownButton = ({ id, label, menuId, isOpen, icon, onToggle, className }) => {

    const classes = 'usa-accordion__button usa-nav__link ' +
              (!!className? className : '');

    return (
        <button
            id={id}
            type="button"
            className={classes}
            aria-expanded={isOpen}
            aria-controls={menuId}
            onClick={onToggle}
        >
            <span>
                {label}
                {!!icon && icon}
            </span>
        </button>
    );
};

export default NavDropDownButton;
