import DataTable from '../common/DataTable';
import Modal from '../common/modal/Modal';

/**
 * This provides a dialog with a table of help for a single metric.  The
 * table has a single row with columns for the metric name, the metric
 * description, and how the metric was calculated.
 *
 * @param {string}        id            unique id for this dialog
 * @param {boolean}       isDialogOpen  true if the dialog should be displayed
 * @param {string}        heading       title for the help dialog
 * @param {function}      closeDialog   function to call to close the dialog
 * @param {array(object)} rows          array of help text, with the
 *                                      properties below:
 * @param {string}        rows.name                 metric name
 * @param {string}        rows.definition           definition of the metric
 * @param {string}        rows.methodOfCalculation  how the metric was calculated
 * @param {object}        helpRef       React useRef for the help modal
 */
const MetricHelp = ({
    id,
    isDialogOpen,
    heading,
    closeDialog,
    rows,
    helpRef
}) => {

    return (
        <Modal
            id={id}
            isOpen={isDialogOpen}
            handleClose={closeDialog}
            heading={heading}
            isLarge
            ref={helpRef}
        >
                {/* We need to create the dialog for the sake of the ref but
                    but don't create the main content if the dialog is closed
                 */}
            {
                isDialogOpen && !!rows &&
                <DataTable
                    id={`${id}-metric-table}`}
                    columns={[
                        {
                            header: 'Metric',
                            accessor: 'name',
                            enableSort: false
                        },
                        {
                            header: 'Description',
                            accessor: 'definition',
                            enableSort: false
                        },
                        {
                            header: 'Method',
                            accessor: 'methodOfCalculation',
                            enableSort: false
                        }
                    ]}
                    rows={rows}
                    enableFilter={false}
                    hidePagination={true}
                />
            }
        </Modal>
    );
}

export default MetricHelp;
