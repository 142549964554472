import Select from './Select';

/**
 * This creates a select choice list where the list of options are objects
 * instead of strings.  It is based on the select component which satisfies 
 * Section 508 accessibility requirements.
 *
 * @param {string}   id                  unique id for this component
 * @param {string}   name                unique name for this component for
 *                                       form submission
 * @param {string}   label               label for the group of options
 * @param {string}   labelStyle          additional USWDS style classes to add
 *                                       to the label, e.g. 'margin-top-0' to
 *                                       remove a margin
 * @param {object}   value               option to select
 * @param {object}   itemList            spec for each option
 * @param {string}   idAttribute         attribute of each option in itemList
 *                                       containing the option's unique 
 *                                       identifier
 * @param {string}   valueAttribute      attribute of each option in itemList
 *                                       containing the option's value
 * @param {boolean}  required            true if an option must be selected
 * @param {boolean}  disabled            true if the options are disabled
 * @param {function} handleSelect        selection handler; note this accepts 
 *                                       the selected item, NOT the event
 * @param {string}   errorMessage        any error message to display
 */

const SelectObject = ({ id, name, label, labelStyle, value, itemList,
    idAttribute='id', valueAttribute='value', required=false, disabled=false, 
    handleSelect, errorMessage='' }) => {

    // Pare the itemList down so that each option contains just the id and 
    // value, then forward this trimmed list on to the Select component.  
    // Put a prefix on each id because the dashboard page has multiple 
    // drop-downs where the id's are simple integers, and a) it is best to 
    // have unique ids on a page and b) there are currently multiple services 
    // with duplicate IDs and so when React outputs a "duplicate key"
    // error for just an integer value, it's hard to know at first where the
    // problem lies.
    const idPrefix = label.replace(' ', '-');
    const trimmedItemList = itemList.map(item => {
        return ( 
            { 
                id: `${idPrefix}-${item[idAttribute]}`, 
                value: item[valueAttribute] 
            }
        );
    });

    // Find the object to select; if none, select the first one
    let selectObject;
    if (!!value) {
        selectObject = 
            trimmedItemList.find(el => el.value === value[valueAttribute]);
    }
    if (!selectObject) {
        selectObject = trimmedItemList[0];
    }

    // After an item is selected, find the corresponding item from the
    // original item list, and pass that item on to the original event handler.
    const handleInputChange = (e) => {
        const found = 
            itemList.find(item => item[valueAttribute] === e.target.value);
        handleSelect(found);
    };

    return (
        <Select
            id={id}
            name={name}
            label={label}
            labelStyle={labelStyle}
            value={selectObject?.value}
            itemList={trimmedItemList}
            required={required}
            disabled={disabled}
            onChange={handleInputChange}
            errorMessage={errorMessage}
        />
    );
};

export default SelectObject;
