import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';

import DipIconButton from './DipIconButton';
import { hideSecret } from '../../utils/utils';

/**
 * This provides a display of a field name, a field value, and an "eye"
 * show/hide icon.  Clicking the show/hide icon will expose the field value
 * or render it with asterisks to hide it.
 */

const HidableField = ({ hide, toggleVisibility, fieldName, field, ...props }) => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }}
        {...props}
    >
        <span>
            <b>
                {fieldName}
            </b>
            <span
                style={{
                    wordBreak: 'break-all'
                }}
            >
                {hideSecret(hide, field)}
            </span>
        </span>
        <HideButton
            hide={hide}
            toggleVisibility={toggleVisibility}
            aria-label={getAriaLabel(hide, fieldName)}
        />
    </Box>
);

// This is the show/hide icon button
const HideButton = ({ hide, toggleVisibility, ...props }) => (
    <DipIconButton
        onClick={() => toggleVisibility(!hide)}
        {...props}
    >
        {hide ? <VisibilityOffIcon /> : <VisibilityIcon />}
    </DipIconButton>
)

// Create the aria label to put on the icon button
const getAriaLabel = (hide, fieldName) => {
    if (hide) {
        return 'Show ' + fieldName;
    }
    return 'Hide ' + fieldName;
}

export default HidableField;
