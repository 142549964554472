
/**
 * This provides a list of navigation menu items.  This can be either the
 * main menu bar across the top of the site or it can be the items in a
 * sub-menu; the "type" indicates which.
 *
 * @param {string}        id         unique identifier for this menu
 * @param {string}        type       type of menu: 'primary' for the main
 *                                   menu bar or 'subnav' for a sub-menu
 * @param {array(object)} items      array of sub-menu items
 * @param {boolean}       isOpen     true if the menu is open
 */

const NavList = ({ id, type, items, isOpen }) => {
    let ulClasses;
    let liClasses;

    switch (type) {
        case 'primary':
            ulClasses = 'usa-nav__primary usa-accordion';
            liClasses = 'usa-nav__primary-item'

            // if # of menu is less than or equal to 2, 
            // move them to be right-aligned in the nav bar.
            if (items.length <= 2) {
                ulClasses += ' flex-justify-end';
            }
            break;

        case 'subnav':
            ulClasses = 'usa-nav__submenu';
            liClasses = 'usa-nav__submenu-item';
            break;

        default:
            console.error('Unhandled nav type', type);
            break;
    }

    return (
        <ul id={id} key={id} className={ulClasses} hidden={!isOpen}>
            {
                items.map((item, i) => (
                    <li key={item.key} className={liClasses}>
                        {item}
                    </li>
                ))
            }
        </ul>
    );
};

export default NavList;
