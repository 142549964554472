import Grid from "@mui/material/Grid";

import MajorHeading from '../common/MajorHeading';
import SortMenu from "./SortMenu";


/**
 * This provides a section header consisting of a section title on the left and
 * a sort menu on the right.  It is expected that this will be shown for the
 * subscription cards or service cards on the Home page or the Catalog page.
 *
 * @param {string}   title             title to place on the section header
 * @param {string}   idPrefix          prefix to give 'id' attributes
 * @param {object}   sortMenuSelector  state selector used to get the current
 *                                     value of the sort option
 * @param {function} sortMenuSetSortBy function to call to set the new sort
 *                                     option
 */

function SectionHeader({ title, idPrefix, sortMenuSelector,
                         sortMenuSetSortBy }) {

    return (

        <Grid item container xs={12}
            id={`${idPrefix}-section-header`}
            sx={{
                backgroundColor: 'background.subtitle',
                borderRadius: 1,
                boxShadow: 2,
                ml: '1rem',
                mt: '1rem',
                px: '0px !important',
                py: '0px !important',
            }}
        >
            { /* Section title */ }
            <Grid item xs={12}  sm={9}
                id={`${idPrefix}-section-title-grid`}
                sx={{
                    display: 'flex',
                    boxSizing: 'border-box',
                    alignItems: 'center'
                }}
            >
                <MajorHeading
                    id={`${idPrefix}-section-title`}
                    label={title}
                    labelStyle='margin-y-05 margin-left-1 font-heading-md'
                />
            </Grid>

            { /* Sort menu */ }
            <Grid item xs={12}  sm={3}
                id={`${idPrefix}-sort-menu-grid`}
            >
                <SortMenu
                    id={`${idPrefix}-sort-menu-component`}
                    idPrefix={idPrefix}
                    sortSelector={sortMenuSelector}
                    setSortBy={sortMenuSetSortBy}
                    sx={{
                        justifyItems: 'end',
                        marginLeft: 'auto'
                    }}
                />
            </Grid>
        </Grid>

    );
};

export default SectionHeader;
