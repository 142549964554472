import classnames from 'classnames';

/**
 * JSX to display a bulleted list.
 *
 * @param {array(string)} listItems    text for each bullet
 * @param {string}        listStyle    additional USWDS style classes to apply
 *                                     to the list, e.g. 'margin-top-0' to 
 *                                     remove a margin
 * @param {string}        keyPrefix    prefix to prepend to the key so that
 *                                     each list item has a unique id
 */
const BulletedList = ({listItems, listStyle, keyPrefix}) => {

    const classes = classnames('usa-label', listStyle);

    return (
        <ul className={classes} >
          {
            listItems?.map((item, idx) => (
              <li key={`${keyPrefix}-${idx}`} className='line-height-sans-2'>
                  {item}
              </li>
            ))
          }
        </ul>
    );
};

export default BulletedList;
