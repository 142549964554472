import { Fragment } from 'react';

import BodyText from './BodyText';
import FieldName from './FieldName';
import FieldValue from './FieldValue';

/**
 * This provides a "business card" display of contact information:
 * type of contact, name, email, phone, and/or url.
 */

const ContactCard = ({ info }) => {

    // The code below is cleaner if we bail now if there is no info
    if (!info) {
        return null;
    }

    // Set a unique id if there are multiple contact cards.
    // The 'type' is a required field, so this should never be unset
    const idPrefix = !!info.type ? info.type.replace(' ', '_') : 'unset';

    return (
        <div>
            <BodyText
                label={info.type ?? ''}
                labelStyle='margin-top-1 margin-bottom-05 text-bold font-sans-sm'
            />
            <div
                style={{
                display: 'grid',
                gridTemplateColumns: 'minmax(min-content, max-content) auto',
                marginLeft: '1rem' }}
            >
                { !!info.name &&
                    <ContactInfoField
                        idPrefix={idPrefix}
                        label='Name'
                        value={info.name}
                    />
                }
                { !!info.phone &&
                    <ContactInfoField
                        idPrefix={idPrefix}
                        label='Phone'
                        value={info.phone}
                    />
                }
                { !!info.email &&
                    <ContactInfoField
                        idPrefix={idPrefix}
                        label='Email'
                        value={info.email}
                    />
                }
                { !!info.url &&
                    <ContactInfoField
                        idPrefix={idPrefix}
                        label='URL'
                        value={info.url}
                    />
                }
                { !!info.comments &&
                    <ContactInfoField
                        idPrefix={idPrefix}
                        label='Comments'
                        value={info.comments}
                    />
                }
            </div>
        </div>
    );
};

/**
 * This creates a single name and value field for the contact card.
 *
 * @param {string} label      label for the field, e.g. "Phone"
 * @param {string} phone      value of the field, e.g. "800-555-1212"
 * @param {string} idPrefix   prefix for this contact card's "id" properties
 *                            which is unique across all contact cards
 */
const ContactInfoField = ({ label, value, idPrefix }) => {

    // Create the unique ID for the label and the value fields
    const labelId = `${idPrefix}-${label}-lbl`;
    const valueId = `${idPrefix}-${label}-val`;

    return (
        <>
            <FieldName
                id={labelId}
                valueId={valueId}
                name={`${label}:`}
                topMargin='margin-top-0'
                nameStyle='margin-bottom-05'
            />
            <FieldValue
                id={valueId}
                value={value}
                topMargin='margin-top-0'
                valueStyle='margin-bottom-05'
            />
        </>
    );
};

export default ContactCard;
