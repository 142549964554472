import Box from "@mui/material/Box";

import { Link } from "react-router-dom";

import { getServiceDetailPageUrl } from "../../utils/navigation";
import ServiceCardLogoProviderServiceName from './ServiceCardLogoProviderServiceName';

/**
 * This provides the small service card with logo, provider name, and
 * service name.
 */

function SmallServiceCard ({ service, serviceLogoDataUrl }) {

  // Note: SmallServiceCard and LargeServiceCard need to have the same width,
  // margins, and borders.  Both cards also need the logo, provider, and
  // service name formatted the same.

  return (
    <Box
        id={`small-service-card-${service.id}`}
        key={`small-service-card-${service.id}`}
        component={Link}
        to={getServiceDetailPageUrl(service.id, service.provider_id)}
        sx={{
            display: 'inline-flex',
            border: '1px solid',
            borderColor: 'text.grey',
            borderRadius: 1,
            width: '13rem',
            height: '5rem',
            marginRight: '8px',
            marginBottom: '8px',
            textDecoration: 'none',
            '&:hover': {
                borderColor: 'primary.main',
                border: 3
            }
        }}
    >
        <ServiceCardLogoProviderServiceName
           service={service}
           serviceLogoDataUrl={serviceLogoDataUrl}
        />
    </Box>
  );
};

export default SmallServiceCard;
