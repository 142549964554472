import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import { useEffect, useState } from 'react';

import PageTitle from './PageTitle';
import SearchBar from '../search/SearchBar';
import { hasISAVerificationToken, isUserAuthenticated } from '../../utils/auth';

/**
 * This provides the page title and search bar that appears across every page.
 * This should not be displayed if the user has not yet logged in and IVT has not yet verified.
 */

function HeaderBar({ ...props }) {

let [userAuthenticated, setUserAuthenticated] = useState(false);
let [ivtVerified, setIvtVerified] = useState(false);

  // Set if the user is logged in.
  useEffect(() => {
      isUserAuthenticated()
      .then(authenticated => {
          setUserAuthenticated(authenticated);
          hasISAVerificationToken()
          .then(hasIvt => {
            setIvtVerified(hasIvt)
          })
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const border = `1px solid ${useTheme().palette.primary.main}`;

  if (userAuthenticated && ivtVerified) {
    return (
        <Grid container
            id='header-bar'
            sx={{
                minHeight: '2rem !important',
                justifyContent: 'space-between',
                paddingLeft: '3rem',
                paddingRight: '1rem',
                paddingTop: '5px',
                paddingBottom: '5px',
                backgroundColor: 'white',
                alignItems: 'center',
                borderBottom: border,
            }}
        >
            <Grid item xs={12} md={8} >
                <PageTitle />
            </Grid>

            <Grid item xs={12} md={4} >
                <SearchBar />
            </Grid>

        </Grid>
    );
  }
  else {
    return null;
  }
};

export default HeaderBar;
