import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useRef, useState } from 'react';
import validator from 'validator';

import ActionButton from '../common/ActionButton';
import BodyText from '../common/BodyText';
import FieldHelp from '../common/FieldHelp';
import MinorHeading from '../common/MinorHeading';
import OkCancelDialog from '../common/OkCancelDialog';
import Select, { SELECT_ELEMENT, SELECT_TITLE } from '../common/Select';
import TextArea from '../common/TextArea';
import TextField from '../common/TextField';
import { isQosOther, QOS_METRICS } from '../../utils/qosMetrics';
import { CANCEL_DELETE_TEXT, OK_DELETE_TEXT } from '../../utils/utils';
import { isInvalidTextField, isErrorObjectEmpty } from '../../utils/validation';
import QualityOfServiceCollapsibleCard from './QualityOfServiceCollapsibleCard';

const QualityOfServiceMetrics = ({
    inputAttributeName,
    input,
    readOnly,
    handleInputChangeByNameAndValue
}) => {

    const formRefQualityMetrics = useRef(null);
    const [openDialog, setOpenDialog] = useState(false)
    const qosDialogRef = useRef(null);
    const [editQualityMetrics, setEditMetrics] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const deleteDialogRef = useRef(null);
    const [customParameterTypeDisabled, setCustomParameterTypeDisabled] = useState(true)
    const [errors, setErrors] = useState({})
    const [qualityMetrics, setQualityMetrics] = useState({
        idx: null,
        parameterType: SELECT_TITLE,
        customType: '',
        value: '',
        definition: '',
        description: '',
        method: ''
    });

    const resetState = () => {
        setQualityMetrics({
            idx: null,
            parameterType: SELECT_TITLE,
            customType: '',
            value: '',
            definition: '',
            description: '',
            method: ''
        });

        setCustomParameterTypeDisabled(true);
        setErrors({});
    }

    const saveQualityMetricsDialog = () => {

        let newQualityMetrics = { ...qualityMetrics }
        let idx = newQualityMetrics.idx;
        delete newQualityMetrics.idx;

        let newErrors = {}
        let specialCharacters = " .,-+*/%()'"

        if (newQualityMetrics.parameterType === SELECT_TITLE) {
            newErrors.parameterType =
                'Select Quality of Service Parameter Type'
        }

        if (isQosOther(newQualityMetrics.parameterType)) {
            if (validator.isEmpty(newQualityMetrics.customType.trim())) {
                newErrors.customType = 'Please set a metric name';
            }
            else {
                newErrors.customType =
                    isInvalidTextField(newQualityMetrics.customType, 1, 50, ' ')
            }
        }

        newErrors.value = isInvalidTextField(
            newQualityMetrics.value, 1, 25, specialCharacters);
        newErrors.definition = isInvalidTextField(
            newQualityMetrics.definition, 1, 200, specialCharacters)
        newErrors.description = isInvalidTextField(
            newQualityMetrics.description, 1, 600, specialCharacters)
        newErrors.method = isInvalidTextField(
            newQualityMetrics.method, 1, 200, specialCharacters)

        if (isErrorObjectEmpty(newErrors)) {
            if (editQualityMetrics) {
                handleInputChangeByNameAndValue(
                    inputAttributeName,
                    input[inputAttributeName].map((oldLink, i) =>
                        i === idx ? newQualityMetrics : oldLink)
                )
            } else {
                handleInputChangeByNameAndValue(
                    inputAttributeName,
                    [...input[inputAttributeName], { ...newQualityMetrics }]
                )
            }

            closeQualityMetricsDialog();
        } else {
            setErrors(newErrors)
        }
    }

    const deleteQualityMetricsDialog = () => {
        handleInputChangeByNameAndValue(
            inputAttributeName,
            input[inputAttributeName].filter((l, i) => i !== qualityMetrics.idx)
        )
        setShowDeleteConfirmation(false);
        resetState();
    }

    const closeQualityMetricsDialog = () => {
        setEditMetrics(false);
        setOpenDialog(false);
        resetState();
    }

    const updateQualityParams = (update) => {
        if(update) {
            setQualityMetrics({...qualityMetrics, ...update});
        }
    }

    const updateParameterType = (event) => {

        let newType = event.target.value;

        if (isQosOther(newType)) {
            updateQualityParams({parameterType: newType})
            setCustomParameterTypeDisabled(false);
        } else {
            updateQualityParams({parameterType: newType, customType: ''})
            setCustomParameterTypeDisabled(true);
        }
    }

    return (
        <Box>
            <div className='display-flex flex-align-center flex-start'>
                <MinorHeading
                    label='Quality of Service Metrics'
                    labelStyle='display-inline-flex margin-y-0 flex-align-center'
                    aria-describedby='qos-metrics-help'
                />

                {
                    !readOnly &&
                    <ActionButton text='Add'
                        onClick={() => setOpenDialog(true)}
                        buttonStyle='margin-left-2 margin-y-1'
                        data-open-modal
                        aria-controls={qosDialogRef?.current?.modalId}
                    />
                }
            </div>

            <FieldHelp
                id='qos-metrics-help'
                help='Metrics based on ISO 25010 characteristics that relate
                      to the performance and reliability of the service.
                      Documented by the service provider.  It is recommended
                      to provide service availability at a minimum.'
            />

            {
                input[inputAttributeName] &&
                input[inputAttributeName].map((param, idx) => {
                    return (
                        <QualityOfServiceCollapsibleCard
                            id={`qos-metric-${idx}`}
                            key={idx}
                            qosParameter={param}
                            editable={!readOnly}
                            editAction={() => {
                                setQualityMetrics({ ...param, idx });
                                setEditMetrics(true);
                                setCustomParameterTypeDisabled(!isQosOther(param.parameterType))
                                setOpenDialog(true);
                            }}
                            removeAction={() => {
                                setQualityMetrics({ ...param, idx });
                                setShowDeleteConfirmation(true);
                            }}
                            qosDialogRef={qosDialogRef}
                            deleteDialogRef={deleteDialogRef}
                        />
                    );
                })
            }

            {/* Dialog for the user to enter/edit metrics */}
            <OkCancelDialog
                id='qos-metric-dialog'
                open={openDialog}
                heading='Quality of Service Metric'
                handleOkOp={saveQualityMetricsDialog}
                handleCancelOp={closeQualityMetricsDialog}
                dialogRef={qosDialogRef}
            >
                {/* We need to create the dialog for the sake of the 'ref'
                    but don't create the content if the dialog is closed */}
                {
                    openDialog &&
                    <form ref={formRefQualityMetrics}>
                        <Grid container spacing={0}>
                            <Grid container spacing={1}
                                sx={{ display: 'flex', alignItems: 'end' }}
                            >
                                <Grid item xs={6}>
                                    <Select
                                        id='qos-metric'
                                        name='qos_metric'
                                        label='Metric'
                                        value={qualityMetrics.parameterType}
                                        itemList={optionList}
                                        onChange={updateParameterType}
                                        required
                                        errorMessage={errors.parameterType}
                                        aria-describedby='custom-metric-help'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id='custom-metric'
                                        name='Other'
                                        label='Custom Metric'
                                        labelStyle='margin-top-0'
                                        value={qualityMetrics.customType}
                                        onChange={(event) =>
                                            updateQualityParams(
                                              {customType: event.target.value})}
                                        required={!customParameterTypeDisabled}
                                        disabled={customParameterTypeDisabled}
                                        errorMessage={errors?.customType}
                                        aria-describedby='custom-metric-help'
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <FieldHelp
                                    id='custom-metric-help'
                                    help='Select the desired metric from the
                                         drop-down menu.   To specify a metric
                                         not listed, choose "Other" and assign
                                         a name.'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='metric-value'
                                    name='Value'
                                    label='Value'
                                    value={qualityMetrics.value}
                                    onChange={(event) =>
                                        updateQualityParams(
                                            {value: event.target.value})}
                                    required
                                    errorMessage={errors?.value}
                                    help='Indicate the value
                                          associated with the metric.'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='metric-definition'
                                    name='Definition'
                                    label='Definition'
                                    value={qualityMetrics.definition}
                                    onChange={(event) =>
                                        updateQualityParams(
                                            {definition: event.target.value})}
                                    required
                                    errorMessage={errors?.definition}
                                    help='State a brief definition of
                                          what the value means, e.g. “Mean time
                                          to restore the service”.'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextArea
                                    id='metric-description'
                                    name='Description'
                                    label='Description'
                                    value={qualityMetrics.description}
                                    onChange={(event) =>
                                        updateQualityParams(
                                            {description: event.target.value})}
                                    required
                                    errorMessage={errors.description}
                                    help='Describe what the metric
                                        represents. Provide specific
                                        information about the method used, such
                                        such as the period of time the data was
                                        the data was collected and data points.'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='metric-method'
                                    name='Method'
                                    label='Method'
                                    value={qualityMetrics.method}
                                    onChange={(event) =>
                                        updateQualityParams(
                                            {method: event.target.value})}
                                    required
                                    errorMessage={errors.method}
                                    help='Describe how the value was computed.'
                                />
                            </Grid>
                        </Grid>
                    </form>
                }
            </OkCancelDialog>

            {/* Dialog for the user to delete metrics */}
            <OkCancelDialog
                id='qos-confirm-metric-delete-dialog'
                open={showDeleteConfirmation}
                heading='Confirm Metric Deletion'
                okText={OK_DELETE_TEXT}
                cancelText={CANCEL_DELETE_TEXT}
                handleOkOp={deleteQualityMetricsDialog}
                handleCancelOp={() => {
                    setShowDeleteConfirmation(false)
                    resetState()
                }}
                dialogRef={deleteDialogRef}
            >
                {/* We need to create the dialog for the sake of the 'ref'
                    but don't create the content if the dialog is closed */}
                {
                    showDeleteConfirmation &&
                    <>
                        <BodyText labelStyle='text-center'>
                            Are you sure you want to delete metric
                        </BodyText>
                        <BodyText labelStyle='text-center margin-top-1'>
                            <strong>
                                {isQosOther(qualityMetrics?.parameterType) ?
                                    qualityMetrics.customType :
                                    qualityMetrics.parameterType}
                            </strong>?
                        </BodyText>
                    </>
                }
            </OkCancelDialog>
        </Box>
    );
};

const optionList = Object.freeze([ SELECT_ELEMENT,  ...QOS_METRICS ]);

export default QualityOfServiceMetrics;
