import classnames from 'classnames';

/**
 * This creates a button which will execute some action when clicked.  It is
 * based on the USWDS button which satisfies Section 508 accessibility
 * requirements.
 *
 * @param {string}   text        the button text
 * @param {function} onClick     button click handler
 * @param {boolean}  isDelete    true changes the color of the button to red,
 *                               such as for a "Delete" button
 * @param {string}   buttonStyle any additional USWDS styles
 * @param {object}   props       any other proprties to apply to the button
 */
const ActionButton = ({
    text,
    onClick,
    isDelete=false,
    disabled=false,
    buttonStyle,
    ...props
}) => {

    if (onClick) {
        const classes = classnames('usa-button',
            {
                'usa-button--secondary' : isDelete
            },
            buttonStyle
        );

        return(
            <button type='button' className={classes}
                onClick={onClick}
                disabled={disabled}
                {...props}
            >
                {text}
            </button>
        );
    }

    console.error(`ActionButton ${text} missing click handler`);
    return null;
}

export default ActionButton;
