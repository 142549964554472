import validator from 'validator';

import ApprovedIcon from '../assets/icon/Approved.png';
import CreatingIcon from '../assets/icon/Creating.png';
import LaunchedIcon from '../assets/icon/Launched.png';
import SubmittedIcon from '../assets/icon/Submitted.png';
import UnderReviewIcon from '../assets/icon/UnderReview.png';
import { SELECT_TITLE } from '../components/common/Select';
import { getCurrentAuthenticatedUser } from './auth';
import { getCatalogServiceQuarantineBucketName, getFileDataFromS3, getResourceFilePathForService } from './s3Utils';
import {
    checkServiceType, convertObjectToQueryStrParams, ServiceTypeUtils,
    trimWhitespace
} from './utils';
import { isInvalidTextField, isInvalidVersion } from './validation';
import { uploadObjectToS3 } from '../apiClient';

// Authentication modes
export const AUTH_MODE_OAUTH2 = 'OAuth2';
export const AUTH_MODE_API_KEY = 'API Key';
// Authentication provider
export const AUTH_PROVIDER_COGNITO = 'Amazon Cognito';

/**
 * Maximum length of the service name and summary
 */
export const SERVICE_NAME_MAX_LENGTH = 35;
export const SERVICE_SUMMARY_MAX_LENGTH = 150;

/**
 * Maximum length of the service description, data source description, and
 * authentication description
 */
export const DESCRIPTION_MAX_LENGTH = 3000;

/**
 * Maximum length of the file description for additional files
 */
export const ADDL_FILES_FILE_DESCRIPTION_MAX_LENGTH = 256;
/**
 * Maximum allowed number of additional files
 */
export const ADDL_FILES_MAX_NUMBER_OF_FILES = 50;
/**
 * Maximum allowed file size for additional files.  If this ever changes, the 
 * text of the error message where this is used needs to be changed too
 */
export const ADDL_FILES_MAX_TOTAL_FILE_SIZE_BYTES = 200_000_000;
/**
 * Maximum allowed file size.  If this ever changes, the text of the error
 * message where this is used needs to be changed too
 */
export const MAX_FILE_SIZE_LIMIT_BYTES = 10_000_000;

/**
 * Virus scan status of a user-uploaded file.  This should match what is
 * set in the back end
 */
export const VIRUS_SCAN_STATUS = {
    clean: 'clean',
    infected: 'infected',
    in_progress: 'in progress'
};
/**
 * Virus scan status label
 */
export const VIRUS_SCAN_STATUS_LABEL = {
    [VIRUS_SCAN_STATUS.clean]: 'Clean',
    [VIRUS_SCAN_STATUS.infected]: 'Failed',
    [VIRUS_SCAN_STATUS.in_progress]: 'In Progress'
};
/**
 * Color to display the virus scan status label
 */
export const VIRUS_SCAN_STATUS_COLOR = {
    [VIRUS_SCAN_STATUS.clean]: 'text-mint',
    [VIRUS_SCAN_STATUS.infected]: 'text-secondary-light',
    [VIRUS_SCAN_STATUS.in_progress]: 'text-yellow'
};

export const SCOPE_MAP = {
    publicdata: 'everyone',
    cdmdata: 'cdm'
};

export const PUBLIC_ROLE_ID = 1;

export const MANAGE_SERVICE_PAGE_ACTION = {
    create: 'create',
    edit: 'edit',
    view: 'view',
    copy: 'copy',
    remove: 'remove',
};

// Service news events.  The values of these event IDs should match the
// event IDs in the service_news_event table
export const SERVICE_NEWS_NEW_SERVICE_LAUNCHED = 1;
export const SERVICE_NEWS_NEW_SERVICE_VERSION_LAUNCHED = 2;

export const VERSION_STATUS_OPTIONS = [
    'creating',
    'submitted',
    'under_review',
    'approved',
    'launched'
];

export const VERSION_STATUS_TO_ICON_MAP = {
    creating: CreatingIcon,
    submitted: SubmittedIcon,
    under_review: UnderReviewIcon,
    approved: ApprovedIcon,
    launched: LaunchedIcon
};

/**
 * File type that's required at the service and/or service version level
 */
export const REQUIRED_SERVICE_FILE_TYPE = {
    data_dictionary: 'data_dictionary',
    openapi_spec: 'openapi_spec',
    service_logo: 'service_logo',
};

export const OTHER_SERVICE_FILE_TYPE = 'other';

export const ERROR_CODE_FILE_ALREADY_EXISTS = 0;

/**
 * Returns if the service version is editable based on its status.
 *
 * @param {object} versionInput     the service version parameters
 *
 * @return {boolean} true if the status is not 'approved' or 'launched'
 */
export const isVersionEditingRestricted = (versionInput) => {
    if (versionInput &&
           ((versionInput.status === 'approved') ||
            (versionInput.status === 'launched'))) {
        return true;
    }
    return false;
};

/**
 * @returns JSON object with initial state of the main service registration form
 */

export const getInitialMainServiceState = (serviceType, companyId,
    companyAbbreviation) => {

    let rtn = {
        name: '',
        provider_id: companyId,
        provider_name_abbrev: companyAbbreviation,
        scope: 'everyone',
        read_access: true,
        write_access: false,
        stream_access: false,
        categories: [],
        summary: '',
        description: '',
        service_type: serviceType ?? null,
        point_of_contacts: [],
        technical_support_contacts: [],
        cdm: false,
        roles: {},
        service_files: {},
        additional_files: []
    };

    if (checkServiceType(serviceType, ServiceTypeUtils.TYPES.DATA_TRANSFER)) {
        rtn['dts'] = {
            name: '',
            source_platform: '',
            source_identifier: '',
            source_url: '',
            scopes: '',
            description: ''
        }
    }

    return rtn;
}

/**
 * @returns JSON object with initial state of the service version registration
 *          form
 */
export const getInitialServiceVersionState = (serviceName, serviceType) => {
    let rtn = {
        ...getInitialMainServiceState(serviceType),
        name: serviceName,
        url: '',
        target_url: '',
        release_summary: '',
        version: '',
        openapi_spec_file: null,
        uploaded_new_openapi_spec_file: false,
        auth_mode: checkServiceType(serviceType, ServiceTypeUtils.TYPES.REST) ? AUTH_MODE_OAUTH2  : null,
        auth_provider: checkServiceType(serviceType, ServiceTypeUtils.TYPES.REST) ? AUTH_PROVIDER_COGNITO : null,
        auth_comments: '',
        created_by: '',
        metrics: '',
        quality_of_service: [],
        opneapi_location: null,
        auth_client_id: '',
        auth_client_secret: '',
        auth_token_url: '',
        auth_api_key_location: null,
        auth_api_key_name: '',
        auth_api_key_value: '',
        status: '',
        api_gateway_id: '',
        protocol: checkServiceType(serviceType, ServiceTypeUtils.TYPES.STREAMING) ? 'SOCKET_IO' : null,
        additional_info_links: [],
        domain_of_applicability: {},
        data_sources: '',
        service_identifier: '',
        version_files: {},
        version_additional_files: [],
        is_beta: false,
    };

    return rtn;
};

/**
 * Copies the given service version.
 *
 * @param {object} input        the service version to copy
 *
 * @return copy of the object with key parameters that must be unique between
 *         version (e.g. version number) cleared
 */
export const copyServiceVersionState = (input) => {

    let rtn = {
        ...input,
        url: '',
        target_url: '',
        release_summary: '',
        version: '',
        openapi_spec_file: null,
        opneapi_location: null,
        uploaded_new_openapi_spec_file: false,
        version_files: {},
        version_additional_files: [],
        status: '',
        created_by: '',
        api_gateway_id: '',
    };

    return rtn;
};

/**
* Populate the file object fields such as
* 1. type               : The type of the file (e.g., service_logo).
* 2. path               : The path to the file object to be stored in the quarantine bucket.
* 2. virus_scan_status  : The virus scan status for the file.
* Note: This is intended to be used after creating or updating a service/version record.
*
* @param {Object[]} files - File objects objects.
* @param {Object[]} additionalFiles - Additional file objects.
* @param {string} providerName - The name of the service provider.
* @param {string} serviceName - The name of the service.
* @param {string} serviceVersion - The version of the service.
* @returns {Array} - An array of file metadata objects
*/
const populateFileObjectFields = (
    files,
    additionalFiles,
    providerName,
    serviceName,
    serviceVersion,
) => {

    const createResourceFileObject = (file, fileType, description) => {
        let resourceFilePath = file.filePath ??
            getResourceFilePathForService(
                getCatalogServiceQuarantineBucketName(),
                providerName,
                serviceName,
                serviceVersion,
                file,
                fileType
            );
        file.filePath = resourceFilePath;

        let resourceFile = {
            type: fileType,
            path: resourceFilePath,
            description: description,
            file_size_bytes: getFileSize(file) ?? 0

        };
        resourceFile['virus_scan_status'] = file.virusScanStatus ?? 'in progress';

        return resourceFile;
    }

    let resourceFiles = [];
    if (Object.keys(files) && Object.keys(files).length) {
        Object.keys(files).forEach(async (fKey) => {
            if (files[fKey]) {
                resourceFiles.push(
                    createResourceFileObject(
                        files[fKey],
                        fKey,
                        null    // No description for the required file input
                    )
                );
            }
        })
    }

    if (additionalFiles?.length > 0) {
        additionalFiles.forEach(af => {
            resourceFiles.push(
                createResourceFileObject(
                    af.file,
                    af.fileType,
                    af.description
                )
            );
        })
    }

    return resourceFiles;
}

/**
 * Retrieves the size of the specified file.
 * 
 * @param {File|object} file - The file object for which to retrieve the size.
 *                             For a File object, the size is directly accessed using the 'size' property.
 *                             Otherwise, the size is accessed using the 'fileSize' property.
 * @returns {number|null} - The size of the file in bytes, or null if the file is undefined or null.
 */
export const getFileSize = (file) => {
    let fileSize = null;
    if (file) {
        if (file instanceof File) {
            fileSize = file.size;
        } else {
            fileSize = file.fileSize;
        }
    }
    return fileSize;
}

/**
 * Create request body based on the input fields.  It can be used when
 * registering and updating the service.
 *
 * @param {object} input        object containing all service registration
 *                              values
 * @param {number} providerId   service provider id
 * @param {string} providerName service provider name
 * @param {boolean} admin       whether or not admin is creating request body
 * @param {boolean} isDts       whether or not the service is the data transfer
 *                              service
 *
 * @return {object} object to send to the main service registration API
 */
export const createMainServiceRequestBody = async (input, providerId, providerName, isDts) => {
    let rtn = {};
    let user = await getCurrentAuthenticatedUser();
    if (user) {
        // Recreate the roles field from clicked roles
        let roles = [];
        Object.entries(input.roles)?.forEach(([ roleId, checked ]) => {
            if (checked) {
                roles.push(Number(roleId));
            }
        })

        rtn = {
            name: input.name,
            // provider_name_abbrev is intentionally omitted
            provider_id: providerId,
            scope: input.scope,
            read_access: input.read_access,
            write_access: input.write_access,
            categories: input.categories.map(c => c.id),
            summary: input.summary || null,
            description: input.description,
            point_of_contacts: input.point_of_contacts?.length ? input.point_of_contacts : null,
            technical_support_contacts: input.technical_support_contacts?.length ? input.technical_support_contacts : null,
            service_type: input.service_type,
            cdm: input.scope === SCOPE_MAP.cdmdata,
            roles: roles,
            files: populateFileObjectFields(
                input.service_files,
                input.additional_files,
                providerName,
                input.name,
                null,
            )
        };

        if (isDts) {
            rtn['data_transfer_source'] = {
                ...input['dts'],
                provider_id: providerId,
                created_by: user.username,
                last_updated_by: user.username
            }
        }

        // trim whitespace from all text input fields
        Object.entries(rtn).forEach(([k, v]) => {
            rtn[k] = trimWhitespace(v);
        });
    } else {
        console.error('Failed to create the main service registration request body: invalid user');
    }
    return rtn;

}

/**
 * Create service version request body based on the input fields.
 * It can be used when registering and updating the service.
 *
 * @param {object} input         object containing all service version
 *                               registration values
 * @param {Number} serviceId     service ID
 * @param {number} providerId    service provider id
 * @param {string} status        service status
 * @param {string} providerName  service provider name
 * @param {boolean} admin        whether or not admin is creating request body
 *
 * @return {object} object to send to the service version registration API
 */
export const createServiceVersionRequestBody = async (input, serviceId, providerId, status, providerName, admin) => {
    let rtn = {};
    let user = await getCurrentAuthenticatedUser();
    if (user) {
        rtn = {
            name: input.name,
            service_type: input.service_type,
            provider_id: providerId,
            service_id: serviceId,
            version: input.version,
            url: input.url || null,
            target_url: input.target_url || null,
            release_summary: input.release_summary || null,
            auth_mode: input.auth_mode || null,
            auth_provider: input.auth_provider || null,
            auth_comments: input.auth_comments || null,
            status: status,
            created_by: input.created_by || user.username,
            metrics: input.metrics ? JSON.parse(input.metrics) : null,
            quality_of_service: input.quality_of_service?.length ? input.quality_of_service : null,
            protocol: input.protocol,
            additional_info_links: input.additional_info_links?.length ? input.additional_info_links : null,
            // send the domain of applicability value if any fields of the
            // Domain of Applicability form has been entered
            domain_of_applicability: Object.values(input.domain_of_applicability).findIndex(v => !!v) !== -1 ? input.domain_of_applicability : null,
            data_sources: !!input.data_sources ? input.data_sources : null,
            api_gateway_id: input.api_gateway_id || null,
            service_identifier: input.service_identifier || null,
            auth_client_id: input.auth_client_id || null,
            auth_token_url: input.auth_token_url || null,
            auth_client_secret: input.auth_client_secret || null,
            auth_api_key_location: input.auth_api_key_location || null,
            auth_api_key_name: input.auth_api_key_name || null,
            auth_api_key_value: input.auth_api_key_value || null,
            is_beta: input.is_beta,
            files: populateFileObjectFields(
                input.version_files,
                input.version_additional_files,
                providerName,
                input.name,
                input.version,
            )
        };

        // trim whitespace from all text input fields
        Object.entries(rtn).forEach(([k, v]) => {
            rtn[k] = trimWhitespace(v);
        });
    } else {
        console.error('Failed to create the service version registration request body: invalid user');
    }
    return rtn;
}

/**
 * Parses the given scope string and returns the object containing
 * name, read_access, and write_access as keys.
 *
 * @param {string} scope scope string to parse
 *
 * @returns {object} scope object
 */
export const parseScopeStr = (scopeStr) => {
    let rtn = {
        name: null,
        read_access: false,
        write_access: false
    };
    if (!!scopeStr) {
        // if multiple access values, the scopes are separated by space
        let scopes = scopeStr.split(' ');
        for (let scope of scopes) {
            if (scope) {
                // custom scope id and the scope value are separated by a slash
                let scopeValue = scope.split('/')[1];
                if (scopeValue) {
                    // scope name and access values are separated by a colon
                    let [name, access] = scopeValue.split(':');
                    if (!name ||
                       !['read', 'write', 'stream'].includes(access)) {
                        console.error('Invalid scope name/access value:', scopeValue);
                    } else {
                        rtn.name = SCOPE_MAP[name];
                        if (access === 'read') {
                            rtn.read_access = true;
                        } else if (access === 'write') {
                            rtn.write_access = true;
                        } else if (access === 'stream') {
                            rtn.stream_access = true;
                        }
                    }
                } else {
                    console.error('Invalid scope value:', scopeValue);
                }
            } else {
                console.error('Invalid scope:', scope);
            }
        }
    } else {
        console.error('Invalid scope string to parse:', scopeStr);
    }
    return rtn;
}

/**
 * Converts given main service detail, scope, and categories into the main
 * service registration input object
 *
 * @param {Object} service               service detail object
 * @param {Object} scope                 scope object
 * @param {Array}  categories            categories
 * @param {string} companyId             ID of the company associated with the 
 *                                       service.
 * @param {string} companyAbbreviation   abbreviation of the company associated
 *                                       with the service.
 * @param {boolean} isVersion            whether or not to incorporate the
 *                                       main service files with the ervice 
 *                                       version input.
 *
 * @returns {Promise<Object>} A Promise that resolves to the registration input object.
 */
export const mainServiceDetailToRegistrationInput = async (
    service, scope, categories,
    companyId, companyAbbreviation,
    isVersion,
) => {

    let roles = {};
    service.roles?.forEach(roleId => {
        roles[roleId] = true;
    });

    let requiredFiles = {};
    let additionalFiles = [];
    
    // Resource files at the service level don't need to be initialized
    // when generating the registration input for the service version level
    if (!isVersion) {
        const files = await initFiles(service.files);
        requiredFiles = files.requiredFiles;
        additionalFiles = files.additionalFiles;
    }

    let rtn = {
        name: service.name || '',
        summary: service.summary || '',
        description: service.description || '',
        scope: scope.name || SCOPE_MAP.publicdata,
        read_access: scope.read_access || false,
        write_access: scope.write_access || false,
        stream_access: scope.stream_access || false,
        categories: categories || [],
        point_of_contacts: service.point_of_contacts || [],
        technical_support_contacts: service.technical_support_contacts || [],
        provider_id: service.provider_id || companyId,
        provider_name_abbrev: service.provider_name_abbrev || companyAbbreviation,
        service_type: service.service_type,
        transfer_source_id: service.transfer_source_id,
        cdm: service.cdm,
        roles: roles,
        service_files: requiredFiles,
        additional_files: additionalFiles,
        total_file_size_bytes: service.total_file_size_bytes,
        total_number_of_files: service.total_number_of_files,
        additional_file_size_bytes: service.additional_file_size_bytes,
        additional_file_count: service.additional_file_count
    };

    // if data transfer source key exists, initalize the input data with
    // DTS data
    if (service['dts']) {
        let dts = service['dts'];
        rtn['dts'] = {
            name: dts.name || '',
            source_platform: dts.source_platform || '',
            source_identifier: dts.source_identifier || '',
            source_url: dts.source_url || '',
            provider_id: dts.provider_id || '',
            scopes: dts.scopes || '',
            description: dts.description || '',
        }
    }

    return rtn;
}

/**
 * Converts given service detail and spec into the service version
 * registration input object
 *
 * @param {Object} versionDetail          service detail object
 * @param {File} openapi_spec_file  openapi spec file
 * @param {Object} scope            scope object
 * @param {Array} categories        categories
 *
 * @return
 */
export const serviceVersionDetailToRegistrationInput = async (versionDetail, scope, categories) => {
    const {requiredFiles, additionalFiles} = await initFiles(versionDetail.files);

    return {
        ...(await mainServiceDetailToRegistrationInput(
            versionDetail,
            scope,
            categories,
            null,
            null,
            true
        )),
        url: versionDetail.url || '',
        target_url: versionDetail.target_url || '',
        release_summary: versionDetail.release_summary || '',
        openapi_location: versionDetail.openapi_location || '',
        uploaded_new_openapi_spec_file: false,
        version: versionDetail.version_id || '',
        auth_mode: versionDetail.auth_mode || '',
        auth_provider: versionDetail.auth_provider || '',
        auth_comments: versionDetail.auth_comments || '',
        auth_client_id: versionDetail.auth_client_id || '',
        auth_client_secret: versionDetail.auth_client_secret || '',
        auth_token_url: versionDetail.auth_token_url || '',
        auth_api_key_location: versionDetail.auth_api_key_location || '',
        auth_api_key_name: versionDetail.auth_api_key_name || '',
        auth_api_key_value: versionDetail.auth_api_key_value || '',
        api_gateway_id: versionDetail.api_gateway_id || '',
        status: versionDetail.status || '',
        created_by: versionDetail.created_by || '',
        provider_id: versionDetail.provider_id,
        metrics: versionDetail.metrics ? JSON.stringify(versionDetail.metrics, null, 2) : '',
        quality_of_service: versionDetail.quality_of_service || [],
        service_type: versionDetail.service_type,
        protocol: versionDetail.protocol,
        additional_info_links: versionDetail.additional_info_links || [],
        domain_of_applicability: versionDetail.domain_of_applicability || {},
        data_sources: versionDetail.data_sources || '',
        name: versionDetail.name,
        service_identifier: versionDetail.service_identifier || '',
        version_files: requiredFiles,
        version_additional_files: additionalFiles,
        is_beta: versionDetail.is_beta || false,
    };
}

/**
 * Gets a link to the manage service page for the given service with the given
 * action mode.
 *
 * @param {number} serviceId service ID
 * @param {string} action mode for the manage service page.
 * @param {boolean} admin whether or not to go to admin manage service page
 * @param {string} serviceType service type
 */
export const getManageServicePage = (serviceId, action, admin, serviceType) => {
    return `${admin ? '/admin' : ''}/manage/service/` +
        `${serviceId}?action=${action}` +
        `${checkServiceType(serviceType, ServiceTypeUtils.TYPES.DATA_TRANSFER) ? '&is_dts=true' : ''}` +
        `${serviceType ? `&service_type=${serviceType}` : ''}`;
};

/**
 * Gets a link to the manage service version page for the given service with
 * the given action mode.
 *
 * @param {number} serviceId service ID
 * @param {string} serviceName service Name
 * @param {string} versionId service version ID
 * @param {string} serviceType service type
 * @param {string} action mode for the manage service page.
 * @param {boolean} admin whether or not to go to admin manage service page
 */
export const getManageServiceVersionPage = (serviceId, serviceName, versionId, serviceType, action, admin) => {
    const queryParams = convertObjectToQueryStrParams({
        serviceId, action, admin, serviceType, serviceName
    });
    return `${admin ? '/admin' : ''}/manage/service/version/${versionId}${queryParams}`
};

/**
 * Gets a link to the service register page.  If serviceId is given, resume
 * registering the service.  Otherwise, register a new service.
 * serviceType argument determines which registration page to bring up
 *
 * @param {number} serviceId
 * @param {string} serviceType
 */
export const getServiceRegisterPage = (serviceId, serviceType) => {
    let searchParams = serviceId ? `?serviceId=${serviceId}` : '';
    return `/services/register/${ServiceTypeUtils.TYPE_TO_SERVICE_PAGE_URL[serviceType]}${searchParams}`;
};

/**
 * Gets a link to the service version register page.
 *
 * @param {number} serviceId
 * @param {string} serviceName
 * @param {string} serviceType
 * @param {string} action service page action
 * @param {boolean} admin whether or not to go to admin manage service page
 */
export const getServiceVersionRegisterPage = (serviceId, serviceName, serviceType, action, admin) => {
    const queryParams = convertObjectToQueryStrParams({
        serviceId, serviceName, serviceType, action
    })
    return `${admin ? '/admin' : ''}/service/version/register${queryParams}`;
};

/**
 * Gets a link to the services page.
 * serviceType argument determines which services page to bring up
 *
 * @param {boolean} admin
 * @param {string} serviceType
 */
export const getServicesPage = (admin, serviceType) => {
    return `${admin ? '/admin' : ''}/services/${ServiceTypeUtils.TYPE_TO_SERVICE_PAGE_URL[serviceType]}`;
};

/**
 * Checks at least one of the scope accesses (read/write) is selected
 *
 * @param {*} scope
 * @param {*} read_access
 * @param {*} write_access
 *
 * @return {object} the object containing valid and error message to display
 *         if not valid.
 */
export const validateScope = (scope, read_access, write_access, stream_access) => {
    let valid = scope && (read_access || write_access || stream_access);
    return {
        valid,
        errorMessage: valid ? '' : 'Scope and Read/Write or Stream Access must be selected'
    };
};

/**
 * Checks if the service metrics is valid JSON.
 *
 * @param {*} metrics
 *
 * @return {object} the object containing valid and error message to display
 *         if not valid.
 */
export const validateServiceMetrics = (metrics) => {
    let valid = true;

    if (metrics) {
        try {
            let keys = Object.keys(JSON.parse(metrics));
            if (keys.length === 2) {
                valid = keys.includes('columns') && keys.includes('rows');
            } else if (keys.length === 3) {
                valid = keys.includes('columns') && keys.includes('rows') && keys.includes('description');
            } else {
                valid = false;
            }

        } catch (error) {
            valid = false;
        }
    }

    return valid;
};

export const validateServiceCoreData = (input) => {
    let errors = {};
    if (input) {
        // Need to explicitly check for the name otherwise the field does not
        // turn red if the name is missing when editing a service (the field's
        // "required" property only works when entering a new service)
        if (!input.name) {
            errors.serviceName = 'Please enter a service name';
        }
        else {
            errors.serviceName = isInvalidTextField(
                input.name, 1, SERVICE_NAME_MAX_LENGTH, " .-'()");
        }

        // Need to explicitly check for the summary otherwise the field does not
        // turn red if the summary is missing when editing a service (the
        // field's "required" property only works when entering a new service)
        if (!input.summary) {
            errors.serviceSummary = 'Please enter a short description';
        }
        else {
            errors.serviceSummary = isInvalidTextField(input.summary,
                1, SERVICE_SUMMARY_MAX_LENGTH, " .,()+-'*/;:[]?_=\n\r");
        }

        // Need to explicitly check for the description otherwise the field does
        // not turn red if the description is missing when editing a service
        // (the field's "required" property only works when entering a new
        // service)
        if (!input.description) {
            errors.serviceDescription = 'Please enter a description';
        }
        else {
            errors.serviceDescription = isInvalidTextField(input.description,
                1, DESCRIPTION_MAX_LENGTH, " .,'()+-*/;:[]?_=\n\r");
        }

        if (!input.categories || (input.categories.length === 0)) {
            errors.serviceCategories = 'Please select at least one Domain';
        }

        if (input?.roles && Object.values(input.roles).filter(v => v).length === 0) {
            errors.companyRoles = 'Please select at least one role'
        }

        if (input?.additional_files?.length > 0) {
            errors.additionalFiles = validateAdditionalFiles(input.additional_files);
        }
    }

    return errors;
};

export const validateVersionCore = (isSubmitting, input) => {
    let errors = {};

    if (input) {
        errors.serviceVersion = isInvalidVersion(input.version);

        if (checkServiceType(input.service_type, ServiceTypeUtils.TYPES.REST) ||
            checkServiceType(input.service_type, ServiceTypeUtils.TYPES.SFTP) ||
            checkServiceType(input.service_type, ServiceTypeUtils.TYPES.STREAMING)) {

            // Need to explicitly check for the URL otherwise the field does not
            // turn red if the URL is missing when editing a version (the
            // field's "required" property only works when entering a new
            // version when using a form control)
            if (isSubmitting &&
               (!input.target_url || (input.target_url.length === 0))) {
                errors.serviceUrl = 'Please enter a target URL';
            }

            // Ditto for the release notes
            if (isSubmitting &&
               (!input.release_summary || (input.release_summary.length === 0))) {
                errors.releaseSummary = 'Please enter release notes';
            }

            if (checkServiceType(input.service_type,
                    ServiceTypeUtils.TYPES.SFTP)) {
                const serviceVersionRegex = new
                    RegExp(/^s3:\/\/[a-zA-Z0-9-][a-zA-Z0-9-]{1,61}(?:\/[a-zA-Z0-9][a-zA-Z0-9-]{1,61})*$/);
                let rtn = serviceVersionRegex.test(input.target_url);
                errors.serviceUrl = rtn ? null :
                    'Invalid S3 URL format: s3://{bucketName}[/{folder}]'
            }
            else if (input?.target_url &&
                    !validator.isEmpty(input.target_url) &&
                    !validator.isURL(input.target_url)) {
                errors.serviceUrl = 'Invalid URL format';
            }
            if (input.service_identifier) {
                errors.serviceIdentifier = isInvalidTextField(
                    input.service_identifier, 1, SERVICE_NAME_MAX_LENGTH,
                    " .-_");
            }
        }
    }

    return errors;
};

export const validateVersionDomain = (input) => {
    let errors = {}

    if (input?.domain_of_applicability?.geographical_extent &&
       !validator.isEmpty(input.domain_of_applicability.geographical_extent)) {
        errors.doaGeographicExtent = isInvalidTextField(
            input.domain_of_applicability.geographical_extent, 0, 75, " .,()'")
    }

    if (input?.domain_of_applicability?.date_range &&
       !validator.isEmpty(input.domain_of_applicability.date_range)) {
        errors.doaDateRange = isInvalidTextField(
            input.domain_of_applicability.date_range, 0, 75, ' -/,.')
    }

    if (input?.data_sources && !validator.isEmpty(input.data_sources)) {
        errors.doaDataSources = isInvalidTextField(
            input.data_sources, 0, DESCRIPTION_MAX_LENGTH, " .,()'\n\r")
    }

    return errors;
}

export const validateVersionPerformance = (input) => {
    let errors = {}

    if (input?.metrics && !validator.isEmpty(input.metrics)) {
        if (!validator.isJSON(input.metrics) ||
            !validateServiceMetrics(input.metrics)) {
            errors.additionalMetrics = 'Invalid service metrics format'
        }
    }

    return errors
}

/**
 * Validates if the API specification file is missing.
 *
 * @param {object}  input         the service version parameters
 * @param {boolean} requireApi    true if the service version requires an API
 *                                and the user is ready to submit the version;
 *                                false if the service version does not require
 *                                an API or the service version DOES require an
 *                                API but the user is only saving the version
 *                                for later submission
 *
 * @return {object} an object with 'openapi_spec_file' set with an error message
 *                  if input.openapi_spec_file is unset and requireApi is true;
 *                  an empty object otherwise
 */
export const validateVersionApiSpec = (specFile, requireApi) => {
    let errors = {}

    if (requireApi && !specFile) {
        errors.openapi_spec_file =
            "An API Specification file must be provided";
    }

    return errors;
}

export const validateVersionAuthentication = (serviceType, isSubmitting,
    input) => {

    let errors = {}

    if (input?.auth_comments && !validator.isEmpty(input.auth_comments)) {
        errors.authComments = isInvalidTextField(
            input.auth_comments, 0, DESCRIPTION_MAX_LENGTH, " .,()'\n\r")
    }

    if (checkServiceType(serviceType, ServiceTypeUtils.TYPES.REST)) {
        if (isSubmitting &&
           (!input?.auth_mode ||
               validator.isEmpty(input.auth_mode) ||
               (SELECT_TITLE === input.auth_mode))) {
            errors.authMode = 'Please select an Authorization Mode';
        }

        switch (input?.auth_mode) {
            case AUTH_MODE_OAUTH2:
                if (isSubmitting &&
                   (!input?.auth_provider ||
                       validator.isEmpty(input.auth_provider) ||
                       (SELECT_TITLE === input.auth_provider))) {
                    errors.authProvider = 'Please select a Provider';
                }

                if (isSubmitting &&
                   (!input.auth_client_id ||
                       validator.isEmpty(input.auth_client_id))) {
                    errors.authClientId = 'Please enter a Client ID';
                }
                else if (input?.auth_client_id &&
                        !validator.isEmpty(input?.auth_client_id)) {
                    errors.authClientId = isInvalidTextField(
                        input.auth_client_id, 0, 50)
                }

                if (isSubmitting &&
                   (!input.auth_client_secret ||
                       validator.isEmpty(input.auth_client_secret))) {
                    errors.authClientSecret = 'Please enter a Client Secret';
                }
                else if (input?.auth_client_secret &&
                        !validator.isEmpty(input?.auth_client_secret)) {
                    errors.authClientSecret =
                        isInvalidTextField(input.auth_client_secret, 0, 200)
                }

                if (isSubmitting &&
                   (!input?.auth_token_url ||
                       validator.isEmpty(input.auth_token_url))) {
                    errors.authTokenUrl = 'Please enter a Token URL';
                }
                else if (input?.auth_token_url &&
                        !validator.isEmpty(input.auth_token_url) &&
                        !validator.isURL(input.auth_token_url)) {
                    errors.authTokenUrl = 'Invalid URL format'
                }

                break;

            case AUTH_MODE_API_KEY:
                if (isSubmitting &&
                   (!input?.auth_api_key_location ||
                       validator.isEmpty(input.auth_api_key_location) ||
                       (SELECT_TITLE === input.auth_api_key_location))) {
                    errors.authApiKeyLocation = 'Please select an API Location';
                }

                if (isSubmitting &&
                   (!input?.auth_api_key_name ||
                       validator.isEmpty(input.auth_api_key_name))) {
                    errors.authApiKeyName = 'Please enter an API Key';
                }
                else if (input?.auth_api_key_name &&
                        !validator.isEmpty(input?.auth_api_key_name)) {
                    errors.authApiKeyName =
                        isInvalidTextField(input.auth_api_key_name, 0, 50, "-_")
                }

                if (isSubmitting &&
                   (!input?.auth_api_key_value ||
                       validator.isEmpty(input.auth_api_key_value))) {
                    errors.authApiKeyValue = 'Please enter an API Value';
                }
                else if (input?.auth_api_key_value &&
                        !validator.isEmpty(input.auth_api_key_value)) {
                    errors.authApiKeyValue = isInvalidTextField(
                        input.auth_api_key_value, 0, 200, "-_ ")
                }
                break;

            default:
                if (SELECT_TITLE !== input.auth_mode) {
                    console.error("Unhandled validation for auth mode :",
                        input?.authMode);
                }
                break;
        }
    }

    return errors;
}

export const validateVersionAdminBasic = (input) => {
    let errors = {}

    if (input?.url &&
        !validator.isEmpty(input.url) &&
        !validator.isURL(input.url)) {
        errors.gatewayUrl = 'Invalid URL format'
    }

    if (input?.api_gateway_id && !validator.isEmpty(input.api_gateway_id)) {
        errors.apiGatewayId = validateApiGatewayId(input.api_gateway_id);
    }

    return errors
}

export const validateAdditionalFiles = (additionalFiles) => {
    // Error object for additional files:
    // Key: index of the files in the array
    // Value: an object containing the field(s) with errors
    const afErrorObject = {};

    const fileToIndexMap = new Map();

    additionalFiles.forEach((af, index) => {
        const currentAfErrorObject = {};

        // check for the file description field
        if (!af.description) {
            currentAfErrorObject.description = 'Please enter a description';
        } else {
            currentAfErrorObject.description = isInvalidTextField(
                af.description,
                1,
                ADDL_FILES_FILE_DESCRIPTION_MAX_LENGTH,
                " .,'()+-*/;:[]?_=\n\r"
            );
        }

        // check for the file field
        if (!af.file) {
            currentAfErrorObject.file = { message: 'Please upload a file'};
        } else {
            const fileTypeToNameStr = `${af.fileType}:${af.file.name}`;
            if (fileToIndexMap.has(fileTypeToNameStr)) {
                currentAfErrorObject.file = {
                    message: `A file with this name "${af.file.name}" already exists. ` +
                        "Would you like to replace the existing one, keep both, or delete this one?",
                    errorCode: ERROR_CODE_FILE_ALREADY_EXISTS,
                    indexToReplace: fileToIndexMap.get(fileTypeToNameStr),
                    currentIndex: index,
                }
            } else {
                fileToIndexMap.set(fileTypeToNameStr, index);
            }
        }

        // If an error exists, populate the error object for additional files
        // for the current additional file.
        if (Object.keys(currentAfErrorObject).length > 0) {
            afErrorObject[index] = currentAfErrorObject;
        }
    })

    return afErrorObject;
}

const validateApiGatewayId = (apiGatewayId) => {
    return isInvalidTextField(apiGatewayId, 0, 50, '_:.');
}

/**
 * Initializes required and additional file objects
 * where each required object has the following properties:
 * - key: file type
 * - value: an object containing the following properties:
 *   - name: filename for the file
 *   - fileData: file data obtained from S3 for the file
 *   - filePath: S3 object key for the file
 *   - virusScanStatus: virus scan status for the file
 *   - description: description for the file
 * Each object in additionalFiles array has the following properties:
 * - fileType: file Type
 * - file: file object which is the same as the value of the required file object.
 * @param {object[]} files - An array of files to initialize.
 * @param {boolean} cleanFileOnly - Whether to include only files with clean virus scan status. Defaults to false.
 * @returns {Promise<object>} - A promise that resolves to initialized required and additional file objects.
 */
export const initFiles = async (files, cleanFileOnly=false) => {
    const requiredFiles = {};
    const additionalFilesArray = [];

    if (files) {
        let fileObjects = files.map(async (f) => {
            if (cleanFileOnly && f.virus_scan_status !== VIRUS_SCAN_STATUS.clean) {
                return null;
            }

            const paths = f.path.split('/')
            const filename = paths[paths.length - 1];

            return {
                name: filename,
                fileType: f.type,
                filePath: f.path,
                fileSize: f.file_size_bytes,
                virusScanStatus: f.virus_scan_status,
                description: f.description || '',
                // Get file from s3 only if the file passed the virus scan.
                fileData: f.virus_scan_status === VIRUS_SCAN_STATUS.clean ? await getFileDataFromS3(f.path) : null,
            };
        });

        fileObjects = await Promise.all(fileObjects);

        for (let fileObject of fileObjects) {
            if (fileObject) {
                const fileType = fileObject.fileType;
                if (REQUIRED_SERVICE_FILE_TYPE[fileType]) {
                    delete fileObject.fileType;
                    // Initialize the file object for the current service
                    requiredFiles[fileType] = fileObject
                    
                } else {
                    additionalFilesArray.push({
                        ...fileObject,
                        fileType: fileType,
                        file: fileObject,
                        description: fileObject.description
                    })
                }
            }
        }
        
        // sort the additional files array by file type and then name
        additionalFilesArray.sort((a, b) => {
            if (a.fileType.localeCompare(b.fileType) !== 0) {
                return a.fileType.localeCompare(b.fileType)
            } else {
                return a.name.localeCompare(b.name)
            }
        });
    }

    return {
        requiredFiles: requiredFiles,
        additionalFiles: additionalFilesArray
    };
}

/**
 * Uploads resource files to the s3 bucket.
 *
 * @param {object} files - An object containing service files to upload
 *                         where each key is the file type
 *                         and the value is the File object.
 * @param {function} setLoadingMsg - a function to set the loading message.
 * @param {reference} abortSignalRef - a refenrece to the abort signal.
 * @returns {Promise[]} - An array of promises representing each upload process.
 */
export const uploadResourceFiles = (
    files,
    additionalFiles,
    setLoadingMsg,
    abortSignalRef
) => {
    const allFiles = [
        ...Object.entries(files || {})?.map(([fileType, file]) => {
            return {
                fileType,
                file,
            };
        }),
        ...(additionalFiles || []),
    ];

    return allFiles?.map(async ({ file }) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (file?.newUpload) {
                    setLoadingMsg(`Uploading file: ${file.name}`);
                    await uploadObjectToS3({
                        objectPath: file.filePath,
                        file: file,
                        abortSignal: abortSignalRef?.current,
                    });
                    file.newUpload = false;
                }
                resolve(true);
            } catch (error) {
                error.message = `Failed to upload file: ${file.name}`;
                reject(error);
            }
        });
    });
};
