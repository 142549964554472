import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { Fragment, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isCancel } from 'axios';

import { setAlert } from '../../app/slices/alert';
import ActionButton from '../common/ActionButton';
import Accordion from '../common/Accordion';
import BodyText from '../common/BodyText';
import CircularProgressIndicator from '../common/CircularProgressIndicator';
import HidableField from '../common/HidableField';
import MajorHeading from '../common/MajorHeading';
import MinorHeading from '../common/MinorHeading';
import Modal from '../common/modal/Modal';
import { getAccessTokenFromCognito } from '../../utils/auth';
import { checkServiceType, ServiceTypeUtils } from '../../utils/utils';
import { getDataTransferServiceCodeSample, getStreamingServiceCodeSample } from '../../utils/serviceDetail';


/**
 * This component provides information to tell the user how to connect to the
 * service.  The information displayed depends upon the type of service.
 * This dialog is usually opened from the Service Detail page.
 *
 * @param {string}   id                  unique id to give the connection dialog
 * @param {boolean}  open                true to display the dialog
 * @param {function} handleClocseDialog  function to call to close the dialog
 * @param {object}   modalRef            React useRef() to track the dialog
 * @param {object}   company             company offering the service
 * @param {object}   service             the service
 */
const ConnectDialog = ({
    id='connect-dialog',
    open,
    handleCloseDialog,
    modalRef,
    company,
    service,
}) => {

    const dispatch = useDispatch();

    const [accessToken, setAccessToken] = useState(null);
    const [fetchingAccessToken, setFetchingAccessToken] = useState(false);
    const [hideClientSecret, setHideClientSecret] = useState(true);
    const [hideApiKey, setHideApiKey] = useState(true);

    const isRest = useMemo(() =>
        checkServiceType(service.service_type, ServiceTypeUtils.TYPES.REST), [service?.service_type]);
    const isDts = useMemo(() =>
        checkServiceType(service.service_type, ServiceTypeUtils.TYPES.DATA_TRANSFER), [service?.service_type]);
    const isStreaming = useMemo(() =>
        checkServiceType(service.service_type, ServiceTypeUtils.TYPES.STREAMING), [service?.service_type]);
    const isSftp = useMemo(() =>
        checkServiceType(service.service_type, ServiceTypeUtils.TYPES.SFTP), [service?.service_type]);

    const generateToken = async () => {
        setFetchingAccessToken(true);
        try {
            let token = await getAccessTokenFromCognito(
                process.env.REACT_APP_COGNITO_TOKEN_ENDPOINT,
                company?.client_id,
                company?.client_secret
            );

            if (!!token) {
                setAccessToken(token);
            }

            setFetchingAccessToken(false);
        } catch (err) {
            if (isCancel(err))
                return;

            dispatch(setAlert({
                show: true,
                message: 'Failed to get access token',
                severity: 'error'
            }));
            setFetchingAccessToken(false);
        }
    }

    const exampleContent = () => {
        let content = null;

        if (isRest) {
            // content for REST service
            content = (
                <ApiParameter
                    id='rest-api-parameters'
                    hideApiKey={hideApiKey}
                    setHideApiKey={setHideApiKey}
                    apiKey={company?.api_key}
                    url={service?.url}
                    apiItems={[
                        'Access token - Instructions on how to obtain one are discussed above in Token Request Parameters.  This is included in an Authorization Bearer header.',
                        'API key - Displayed above.  This is included in an x-api-key header.',
                        'API endpoint - The base URL for the API is shown above; endpoints for specific API calls are included in the API documentation.',
                        'Request method - HTTP method to call, such as GET or POST',
                        'Payload, if any - any additional information that must be sent with the request, such as the data for a POST request'
                    ]}
                    exampleTitle='API Request Example'
                >
                    <code key='token-sample' style={{ whiteSpace: 'nowrap' }}>
                        <CodeLine>curl -H "Content-Type: application/json" \ </CodeLine>
                        <CodeLine>-H "Authorization: Bearer &lt;access_token&gt;" \ </CodeLine>
                        <CodeLine>-H "x-api-key: &lt;api_key&gt;" \ </CodeLine>
                        <CodeLine>[-d "&lt;payload&gt;"] \ </CodeLine>
                        <CodeLine>-X &lt;request_method&gt; "&lt;api_endpoint&gt;"</CodeLine>
                    </code>
                </ApiParameter>

            )
        } else if (isStreaming) {
            // content for Streaming service
            const codeSample = getStreamingServiceCodeSample(
                service?.url, service?.serviceIdentifier);
            content = (
                <ApiParameter
                    id='streaming-api-parameters'
                    hideApiKey={hideApiKey}
                    setHideApiKey={setHideApiKey}
                    apiKey={company?.api_key}
                    url={service?.url}
                    apiItems={[
                            'Access token - Instructions on how to obtain one are discussed above in Token Request Parameters.  This is included as a query parameter in the URL.',
                            'API key - Displayed above.  This is included as a query parameter in the URL.',
                            'API endpoint - The base URL for the API is shown above; endpoints for specific API calls are included in the API documentation.',
                            'Service identifier - The streaming service identifier, available in the API documentation'
                        ]}
                    exampleTitle='API Request Example In Node.js'
                >
                    <code key='streaming-sample' style={{ whiteSpace: 'pre' }}>
                        {codeSample}
                    </code>
                </ApiParameter>
            )
        } else if (isDts) {
            const codeSample = getDataTransferServiceCodeSample(service.dts.name);
            content = (
                <div style={{ overflow: 'auto' }} >
                    <MinorHeading label='Data Transfer Request Example In Python' />
                    <code style={{ whiteSpace: 'pre' }}>
                        {codeSample}
                    </code>
                </div>
            )
        } else if (isSftp) {
            content = (
                <Fragment>
                    <MajorHeading label='SFTP Connection Parameters' />

                    <BodyText label='Use the SFTP client of your choice to connect to the SFTP host. Use the Client Id as your username and Client Secret as your password.' />

                    <NameAndValueField
                        name='SFTP Host URL:'
                        value={process.env.REACT_APP_SFTP_SERVER_URL}
                        sx={{
                            my: '1rem'
                        }}
                    />

                    <NameAndValueField
                        name='Client Id:'
                        value={company?.client_id}
                        sx={{
                            mb: '10px'
                        }}
                    />

                    <HidableField
                        hide={hideClientSecret}
                        toggleVisibility={setHideClientSecret}
                        fieldName='Client Secret: '
                        field={company?.client_secret}
                    />
                </Fragment>
            )
        }
        return content;
    }

    // We need to create the modal for the sake of the 'ref', but use the
    // "open" flag to short-circuit the work of creating the main content if
    // the modal is closed
    return (
        <Modal
            id={id}
            isOpen={open}
            isLarge
            handleClose={handleCloseDialog}
            heading='Connect to Service'
            ref={modalRef}
        >
            {
                open &&
                <div>
                    {
                        (isRest || isStreaming) && (
                            <Fragment>
                                <MajorHeading label='Token Request Parameters' />

                                <BodyText
                                    label='You need a token to make an API request. Tokens can (and should) be re-used for multiple requests. Tokens expire in 24 hours.'
                                    labelStyle='margin-top-0'
                                />

                                <NameAndValueField
                                    name='Client Id:'
                                    value={company?.client_id}
                                    sx={{
                                        mt: '10px'
                                    }}
                                />

                                <HidableField
                                    hide={hideClientSecret}
                                    toggleVisibility={setHideClientSecret}
                                    fieldName='Client Secret: '
                                    field={company?.client_secret}
                                />

                                <NameAndValueField
                                    name='Authentication Token URL:'
                                    value={process.env.REACT_APP_COGNITO_TOKEN_ENDPOINT}
                                />

                                <Accordion
                                    id='token-request-example'
                                    title='Token Request Example'
                                    show={false}
                                    accordionStyle='margin-top-2'
                                    itemList={[
                                        <code key='sample' style={{ whiteSpace: 'nowrap' }}>
                                            <CodeLine>curl -d "grant_type=client_credentials" \ </CodeLine>
                                            <CodeLine>-H "Content-Type: application/x-www-form-urlencoded" \ </CodeLine>
                                            <CodeLine>-u "&lt;client_id&gt;:&lt;client_secret&gt;" \ </CodeLine>
                                            <CodeLine>-X POST "&lt;authentication_token_url&gt;"</CodeLine>
                                        </code>
                                    ]}
                                />

                                <ActionButton text='Generate Token'
                                    buttonStyle='margin-top-2'
                                    onClick={generateToken}
                                />

                                <Box sx={{ mt: '.5rem'}} >
                                    {
                                        fetchingAccessToken &&
                                        <CircularProgressIndicator 
                                            altText='Fetching access token...'
                                        />
                                    }
                                    {
                                        accessToken &&
                                        <>
                                            <b>Generated Token:</b>
                                            <Box
                                                component='pre'
                                                sx={{
                                                    overflowX: 'auto'
                                                }}
                                            >
                                                {JSON.stringify(accessToken, null, 2)}
                                            </Box>
                                        </>
                                    }
                                </Box>
                                <Divider sx={{ my: '2rem' }} />
                            </Fragment>
                        )

                    }

                    {exampleContent()}
                </div>
            }
        </Modal>
    );
};

const CodeLine = ({ ...props }) => (
    <p style={{ marginTop:0, marginBottom:0}} >
        {props.children}
    </p>

)

const NameAndValueField = ({ name, value, ...props }) => (
    <Box {...props}>
        <b>{name}</b>
        <Box
            component='span'
            sx={{
                pl: {
                    xs: '5px',
                    sm: 0
                },
                wordBreak: 'break-all'
            }}
        >
            {' '}{value}
        </Box>
    </Box>
);

const ApiParameter = (props) => (
    <Fragment>
        <MajorHeading label='API Parameters' />

        <HidableField
            hide={props.hideApiKey}
            toggleVisibility={props.setHideApiKey}
            fieldName='API Key: '
            field={props.apiKey}
        />

        <NameAndValueField
            name='API Base URL:'
            value={props.url}
        />

        <div style={{ margin: '10px 0' }}>
            <BodyText
                label='To call an API, you need:'
                labelStyle='margin-top-0 margin-bottom-1'
            />
            <ul className='usa-list margin-top-0 margin-bottom-1'>
                {
                    props.apiItems.map((item, idx) => (
                        <li key={idx} className='usa-list-item line-height-body-2 font-serif-sm' >
                            {item}
                        </li>
                    ))
                }
            </ul>
            <BodyText
                label='Additional properties may also be allowed.  Complete details of available API resources and properties can be found in the API documentation.'
                labelStyle='margin-top-1'
            />
        </div>

        <Accordion
            id={`${props.id}-code-sample`}
            title={props.exampleTitle}
            accordionStyle='margin-top-2'
            itemList={[props.children]}
        />
    </Fragment>
);

export default ConnectDialog;
