import { useSelector } from 'react-redux';

import { selectPageTitle } from '../../app/slices/page';


/**
 * This displays the page title.
 */
const PageTitle = () => {

    const title = useSelector(selectPageTitle);

    return (
        <h1 className='text-primary margin-0' >
            {title}
        </h1>
    );
};

export default PageTitle;
