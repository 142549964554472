import { getObjectFromS3 } from "../apiClient";
import { contentType } from "mime-types";

/**
 * Retrieves the name of the s3 portal bucket used for storing files.
 *
 * @returns {string} - The name of the quarantine bucket.
 */
export const getCatalogServiceBucketName = () => {
  return process.env.REACT_APP_CATALOG_SERVICE_S3_BUCKET;
}

/**
 * Retrieves the name of the quarantine bucket used for virus scanning before transferring files to the catalog S3 bucket.
 *
 * @returns {string} - The name of the quarantine bucket.
 */
export const getCatalogServiceQuarantineBucketName = () => {
  return process.env.REACT_APP_CATALOG_SERVICE_S3_BUCKET_QUARANTINE;
}

/**
 * Retrieves the file path for a service/service version file within the specified catalog bucket.
 *
 * @param {string} bucketNam - The name of the catalog S3 bucket
 * @param {string} providerName - The name of the service provider.
 * @param {string} serviceName - The name of the service.
 * @param {string} version - The version of the service.
 * @param {File}   file - File object.
 * @param {string} fileType - The file type.
 * @returns {string} - The full s3 object key path for the specified service/version within the specified bucket.
 */
export const getResourceFilePathForService = (
  bucketName, providerName, serviceName, version, file, fileType
) => {
  let rtn = null;
  if (providerName && serviceName && file) {
    rtn = `${bucketName}/${providerName}/${serviceName}${version ? `/${version}` : ''}` +
      `/${file.targetFolderName}/${fileType}/${file.name}`
  } else {
    console.error("Invalid arguments");
  }
  return rtn;
}

const base64ToArrayBuffer = (data) => {
  var binaryString = window.atob(data);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

/**
 * Retrieves file data from S3 and returns it in the appropriate format.
 * If the file is determined to be one of the binary media types using the isBinaryMediaType function,
 * it's returned as a base64 string. Otherwise, it's returned as a File object.
 * @param {string} objectPath - The path to the object in S3.
 * @returns {Promise<string|File|null>} - A promise that resolves to the file data.
 */
export const getFileDataFromS3 = async (objectPath) => {
  let file = null;
  try {
    if (objectPath) {
      const res = await getObjectFromS3({ key: objectPath });
      
      if (isBinaryMediaType(res.headers['content-type'])) {
        file = `data:${res.headers['content-type']};base64,${res.data}`;
      }
      else {
        const paths = objectPath.split('/')
        const filename = paths[paths.length - 1];
        let fileData = null;
        try {
          // If the data is base64 encoded, convert it to bytes
          fileData = base64ToArrayBuffer(res.data);
        } catch(e) {
          // If failing, it's indicating that the data is not base64 encoded. 
          // use the data from the API directly
          fileData = res.data
        }
        file = new File([fileData], filename, {
          type: res.headers?.['content-type']
        });
      }
    }
  } catch (error) {
    console.error("Failed to get file from S3", error);
  } finally {
    return file;
  }
}

export const getS3ObjectAsDataUrl = async (objectPath) => {
  let rtn = null;
  try {
    if (objectPath) {
      let res = await getObjectFromS3({ key: objectPath });
      rtn = `data:${res.headers['content-type']};base64,${res.data}`;
    }
  } catch (error) {
    console.error("Failed to generate s3 object data url", error);
  } finally {
    return rtn;
  }
};

export const getS3ObjectAsFile = async (objectPath) => {
  let file = null;
  if (objectPath) {
      try {
          let paths = objectPath.split('/')
          let filename = paths[paths.length - 1];
          let res = await getObjectFromS3({ key: objectPath });
          let fileData = null;
          try {
            // If the data is base64 encoded, convert it to bytes
            fileData = base64ToArrayBuffer(res.data);
          } catch(e) {
            // If failing, it's indicating that the data is not base64 encoded. 
            // use the data from the API directly
            fileData = res.data
          }
          file = new File([fileData], filename, {
            type: res.headers?.['content-type'] || contentType(filename)
          });
      } catch (error) {
          console.error(`Failed to get file from ${objectPath}`, error);
      }
  }
  return file;
}

export const downloadS3File = async (filePath) => {
  let fileName = filePath.split('/').at(-1);
  let file = await getS3ObjectAsFile(filePath);
  let url = URL.createObjectURL(file);
  let a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  let clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  a.remove();
};

/**
 * Checks whether the provided media type is a binary media type as defined in the API gateway settings
 * for the S3 proxy API.
 * Note: If binary media types change in the API gateway settings, the "binaryMediaTypeSet" must be updated accordingly.
 * @param {string} type - The media type to be checked.
 * @returns {boolean} - True if the media type is binary, otherwise false.
 */
export const isBinaryMediaType = (type) => {
  return type.startsWith("image/") || binaryMediaTypeSet.has(type);
}

const binaryMediaTypeSet = new Set([
  "images/*",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
]);
