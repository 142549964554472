import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { useRef, useState } from "react";
import validator from 'validator';

import ActionButton from "../common/ActionButton";
import BodyText from "../common/BodyText";
import FieldHelp from "../common/FieldHelp";
import MinorHeading from "../common/MinorHeading";
import OkCancelDialog from "../common/OkCancelDialog";
import StyledLink from "../common/StyledLink";
import TextField from "../common/TextField";
import { CANCEL_DELETE_TEXT, OK_DELETE_TEXT } from '../../utils/utils';
import { isInvalidTextField, isErrorObjectEmpty } from "../../utils/validation";

/**
 * This provides a control for the user to enter options for additional
 * information.  The display includes
 * - a title for the additional information
 * - a "help" icon which the user can click to get more information
 * - an "add" button to add more links
 * - the list of already-entered links.
 * When the user clicks the "add" button, a new dialog (contained in this
 * component) is opened to prompt the user for a title and the URL to access
 * the additional information.
 */
const AdditionalInfoLink = ({
    input,
    readOnly,
    handleInputChangeByNameAndValue
}) => {

    const formRefAdditionalInfoLink = useRef(null);
    const [editAdditionalInfoLink, setEditAdditionalInfoLink] = useState(false);
    const [additionalInfoLink, setAdditionalInfoLink] = useState({
        idx: null,
        title: '',
        url: ''
    });

    const [openAdditionalInfoLinkDialog, setOpenAdditionalInfoLinkDialog] = useState(false);
    const linkRef = useRef(null);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const deleteRef = useRef(null);

    const [dialogErrors, setDialogErrors] = useState({});

    const resetAdditionalInfoLink = () => {
        setAdditionalInfoLink({
            idx: null,
            title: '',
            url: ''
        });
    }

    const closeAdditionalInfoLinkDialog = () => {
        setEditAdditionalInfoLink(false);
        setOpenAdditionalInfoLinkDialog(false);
        resetAdditionalInfoLink();
        setDialogErrors({});
    };

    const saveAdditionalInfoLinkDialog = () => {

        let newAdditionalInfoLink = { ...additionalInfoLink };
        let idx = newAdditionalInfoLink.idx;
        delete newAdditionalInfoLink.idx;

        let errors = {}

        if (validator.isEmpty(newAdditionalInfoLink.title.trim())) {
            errors.title = 'Please set the title';
        }
        else {
            errors.title = isInvalidTextField(newAdditionalInfoLink.title,
                1, 75, " .-'")
        }

        if (validator.isEmpty(newAdditionalInfoLink.url.trim())) {
            errors.url = 'Please set the url';
        }
        // The protocol is required in the URL otherwise the routing tries to
        // open the link within the Catalog
        else if (!validator.isURL(newAdditionalInfoLink.url.trim(),
                      { require_protocol: true} )) {
            errors.url = 'Invalid URL format'
        }

        if (isErrorObjectEmpty(errors)) {
            if (editAdditionalInfoLink) {
                handleInputChangeByNameAndValue(
                    'additional_info_links',
                    input.additional_info_links.map((oldLink, i) =>
                        i === idx ? newAdditionalInfoLink : oldLink)
                );
            } else {
                handleInputChangeByNameAndValue(
                    'additional_info_links',
                    [...input.additional_info_links,
                        { ...newAdditionalInfoLink }]
                );
            }

            closeAdditionalInfoLinkDialog();
        }
        else {
            setDialogErrors(errors);
        }
    };

    const deleteAdditionalInfoLink = () => {
        handleInputChangeByNameAndValue(
            'additional_info_links',
            input.additional_info_links.filter((l, i) =>
                i !== additionalInfoLink.idx)
        );
        setShowDeleteConfirmation(false);
        resetAdditionalInfoLink();
    };

    const linkHelp = 'Provide link(s) to a site where a user can ' +
                     'find more information on the service offering.' 
                      + ( input?.additional_info_links  &&
                          !!input?.additional_info_links?.length ?
                      '  Links open in a new window.' : '');

    return (
        <Box sx={{ marginTop: '1.5rem'}} >
            <div className='display-flex flex-align-center flex-start'>
                <MinorHeading
                    label='Additional Information Links'
                    labelStyle='display-inline-flex margin-y-0 flex-align-center'
                    aria-describedby='addl-info-links-help'
                />

                {
                    !readOnly &&
                    <ActionButton text='Add'
                        id='add-additional-info-link'
                        onClick={() => setOpenAdditionalInfoLinkDialog(true)}
                        buttonStyle='margin-left-2 margin-bottom-1'
                        data-open-modal
                        aria-controls={linkRef?.current?.modalId}
                    />
                }
            </div>

            <FieldHelp
                id='addl-info-links-help'
                help={linkHelp}
                helpStyle='margin-top-05'
            />

            <List>
                {
                    input.additional_info_links &&
                    input.additional_info_links.map((link, idx) => {
                        // If the link does not start with the protocol 
                        // e.g. "http", the routing thinks it's a relative link 
                        // and tries to open it as a link within the Catalog, 
                        // which does not work (use Additional Files for that).
                        // We added the requirement of the protocol in the URL
                        // entry field, but the check below is to catch any 
                        // partial URLs entered before the validation was 
                        // added.
                        let useLink = link.url;
                        if (!link.url.startsWith("http")) {
                            useLink = "http://" + link.url;
                        }

                        return (
                            <ListItem
                                key={idx}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    pl: '1rem',
                                    border: '1px solid',
                                    borderRadius: '5px',
                                    borderColor: '#C4C4C4',
                                    mt: idx !== 0 ? '0.5rem' : 0
                                }}
                            >
                                <StyledLink
                                    href={useLink}
                                    target='_blank'
                                >
                                    {link.title}
                                </StyledLink>
                                <Box
                                    sx={{
                                        display: readOnly ? 'none' : 'flex'
                                    }}
                                >
                                    <ActionButton text='Edit'
                                        onClick={() => {
                                            setAdditionalInfoLink({ ...link, idx });
                                            setEditAdditionalInfoLink(true);
                                            setOpenAdditionalInfoLinkDialog(true);
                                        }}
                                        data-open-modal
                                        aria-controls={linkRef?.current?.modalId}
                                    />
                                    <ActionButton text='Delete'
                                        isDelete
                                        onClick={() => {
                                            setAdditionalInfoLink({ ...link, idx });
                                            setShowDeleteConfirmation(true)
                                        }}
                                        buttonStyle='margin-left-2'
                                        data-open-modal
                                        aria-controls={deleteRef?.current?.modalId}
                                    />
                                </Box>
                            </ListItem>
                        );
                    })
                }
            </List>

            {/* Dialog for the user to enter/edit additional info. */}
            <OkCancelDialog
                id='additional-info-link-dialog'
                open={openAdditionalInfoLinkDialog}
                heading='Enter Title and URL'
                handleCancelOp={closeAdditionalInfoLinkDialog}
                handleOkOp={saveAdditionalInfoLinkDialog}
                isLarge
                dialogRef={linkRef}
            >
                {/* We need to create the dialog for the sake of the 'ref'
                    but don't create the content if the dialog is closed */}
                {
                    openAdditionalInfoLinkDialog &&
                    <form ref={formRefAdditionalInfoLink}>
                        <TextField
                            id='addl-info-title'
                            name='title'
                            label='Title'
                            labelStyle='margin-top-05 width-mobile-lg'
                            value={additionalInfoLink.title}
                            required
                            onChange={(e) =>
                                setAdditionalInfoLink({ ...additionalInfoLink,
                                    title: e.target.value })}
                            errorMessage={dialogErrors.title}
                            help='The Title can be used to identify
                                  the type of information that can
                                  be found at the link, such as an
                                  Interface Control Document.'
                        />
                        <TextField
                            id='addl-info-url'
                            name='url'
                            label='URL'
                            labelStyle='margin-top-2 width-mobile-lg'
                            value={additionalInfoLink.url}
                            required
                            onChange={(e) =>
                                setAdditionalInfoLink({ ...additionalInfoLink,
                                    url: e.target.value })}
                            errorMessage={dialogErrors.url}
                            help='Specify the full URL including protocol (e.g. "http://nasa.gov")'
                        />
                    </form>
                }
            </OkCancelDialog>

            {/* Dialog to confirm deletion of additional info. */}
            <OkCancelDialog
                id='confirm-info-deletion'
                open={showDeleteConfirmation}
                heading='Confirm Info Deletion'
                handleCancelOp={() => setShowDeleteConfirmation(false)}
                handleOkOp={deleteAdditionalInfoLink}
                okText={OK_DELETE_TEXT}
                cancelText={CANCEL_DELETE_TEXT}
                dialogRef={deleteRef}
            >
                {/* We need to create the dialog for the sake of the 'ref'
                    but don't create the content if the dialog is closed */}
                {
                    showDeleteConfirmation &&
                    <>
                        <BodyText labelStyle='text-center'>
                            Are you sure you want to delete
                        </BodyText>
                        <BodyText labelStyle='text-center margin-top-1'>
                            <strong>{additionalInfoLink.title}</strong>?
                        </BodyText>
                    </>
                }
            </OkCancelDialog>
        </Box>
    )
};

export default AdditionalInfoLink;
