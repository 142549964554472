import FieldName from './FieldName';
import FieldValue from './FieldValue';

/**
 * This provides a table of "label: value" pairs.  
 *
 * @param {arrayof(object)} tableData   array of row data with these elements:
 * @param {string} tableData.label      the label for the row
 * @param {string} tableData.value      the value to display, assumed to be a
 *                                      string (no typechecking is done)
 * @param {string} tableData.baseId     the base "id" attribute for the elements
 *                                      in the row; a unique id for the name and
 *                                      value will be manufactured from this
 * @param {string} tableData.valueStyle (optional) style to add to the default
 *                                      value style, e.g. "text-right" to 
 *                                      right-justify the value
 */
const LabelValueTable = ({ tableData }) => {

    return (
        <table role='presentation'>
            <tbody>
                {
                    tableData && tableData.map((row, idx) => {

                        const nameId = `${row.baseId}-name`;
                        const valueId = `${row.baseId}-value`;

                        return (
                            <tr key={`${row.baseId}-${idx}`}>
                                <td>
                                    <FieldName 
                                        id={nameId} 
                                        valueId={valueId} 
                                        name={row.label} 
                                    />
                                </td>
                                <td>
                                    <FieldValue 
                                        id={valueId} 
                                        value={row.value} 
                                        valueStyle={row.valueStyle} 
                                    />
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    );
};

export default LabelValueTable;
