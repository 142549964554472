import { styled } from '@mui/material';

/**
 * This provides a styled 'a' tag.  This is intended for links that are
 * external to the Catalog website.  Styles in this component and in StyledLink
 * should match.
 */

const StyledLink = styled('a')({
    backgroundColor: 'white',
    color: '#0050C9',
    whiteSpace: 'nowrap',
    wordBreak: 'break-all',
    '&:hover, &:focus': {
        color: '#5C0099',
    }
});

export default StyledLink;
