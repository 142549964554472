import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import CircularProgressIndicator from '../common/CircularProgressIndicator';
import { selectSubscriptionsSortBy, selectSortOption, setSubscriptionsSortBy
       } from "../../app/slices/browse";
import SectionHeader from "./SectionHeader";
import SmallServiceCard from "./SmallServiceCard";

/**
 * This provides the set of service cards that the user is subscribed to.  It
 * is expected that this will be shown on the Catalog page.
 */

function SubscriptionsCardDisplay({ subscriptions, fetching }) {

    const [ subscriptionList, setSubscriptionList ] = useState([]);

    // For sorting the subscriptions
    const sortOption = useSelector(selectSortOption);
    const sortBy = useSelector(selectSubscriptionsSortBy);

    useEffect(() => {
        let copySubscriptions = [];
        if (!!subscriptions && !!subscriptions.length) {
            // If the user is subscribed to multiple versions of the same
            // service, we still only want to show one service card.
            let uniqueServiceIds = new Set();
            let uniqueSubscriptions = subscriptions.filter(s => {
                if (uniqueServiceIds.has(s.service_id)) {
                    return false;
                } else {
                    uniqueServiceIds.add(s.service_id);
                    return true;
                }
            });

            // The subscription service's properties are different than the 
            // general service's properties.  Copy the service id and service 
            // name properties to correspond to the same ones as the general 
            // service properties before sending them to the service card 
            // renderer.
            copySubscriptions = uniqueSubscriptions.map(s => ({
                ...s,
                id: s.service_id,
                name: s.service_name,
            }));

            // Sort the displayed services
            copySubscriptions.sort((a, b) => {
                const nameA = getSortByField(a);
                const nameB = getSortByField(b);
                if (nameA < nameB) {
                    return -1;
                }
                else if (nameA > nameB) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
        }
        setSubscriptionList(copySubscriptions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetching, subscriptions, sortBy]);

    // Given a service, this returns the field that should be used for sorting
    const getSortByField = (service) => {
        if (sortBy === sortOption.serviceName) {
            return service.service_name.toUpperCase();
        }
        else if (sortBy === sortOption.providerName) {
            return service.provider_name.toUpperCase();
        }

        console.log("Unhandled sort option:", sortBy);
        return service.service_name;
    };

    return (
        <Grid container spacing={2}>

            {/* Title bar */}
            <SectionHeader
                title='My Subscriptions'
                idPrefix='subscriptions'
                sortMenuSelector={selectSubscriptionsSortBy}
                sortMenuSetSortBy={setSubscriptionsSortBy}
            />

            {/* Subscriptions service cards */}
            <Grid item xs={12}
                sx={{
                    display: 'inline-flex',
                    flexWrap: 'wrap',
                    overflow: 'scroll',
                    height: '13rem',
                    marginBottom: '8px',
                }}
            >
                {
                    !!fetching ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgressIndicator
                                type='large'
                                altText='Loading subscriptions...'
                                id='loading-subscriptions'
                            />
                        </Box>
                    ) : (
                        subscriptionList.map((service, idx) => (
                            <SmallServiceCard
                                service={service}
                                serviceLogoDataUrl={service.service_logo_data_url}
                                key={`subscribed-${service.key}`}
                            />
                        ))
                    )
              }

            </Grid>
        </Grid>
    )
};

export default SubscriptionsCardDisplay;
