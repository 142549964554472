import Grid from '@mui/material/Grid';

import CircularProgressIndicator from '../common/CircularProgressIndicator';
import MajorHeading from '../common/MajorHeading';

/**
 * This provides the display of the right hand side of a news page.  It
 * consists of a title and the not-the-latest content to display.
 *
 * @param {boolean} loading      true if data is still loading
 * @param {string}  sideTitle    the title to display on the right side
 * @param {JSX}     sideContent  JSX content to display on the right side
 */

const NewsRightSide = ({
    loading,
    sideTitle,
    sideContent
}) => {

    return (
        <Grid container id='right-side-card'
            sx={{
                backgroundColor: 'white',
                padding: '1rem',
            }}
        >
            <MajorHeading
                label={sideTitle}
                labelStyle='margin-right-1 margin-top-1 margin-bottom-0'
            />
            {
                loading ?
                    <CircularProgressIndicator
                        type='large'
                        altText='Loading previous news'
                    />
                :
                    sideContent
            }
        </Grid>
    );
};

export default NewsRightSide;
