import Grid from "@mui/material/Grid";

import classnames from 'classnames';

import { forwardRef, useEffect, useMemo, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import { setAlert } from "../../app/slices/alert";
import { downloadS3File } from "../../utils/s3Utils";
import { ERROR_CODE_FILE_ALREADY_EXISTS, MAX_FILE_SIZE_LIMIT_BYTES, VIRUS_SCAN_STATUS } from "../../utils/serviceRegistration";
import { CANCEL_DELETE_TEXT, OK_DELETE_TEXT } from "../../utils/utils";
import ActionButton from "../common/ActionButton";
import BodyText from "../common/BodyText";
import FieldLabel from "../common/FieldLabel";
import FileInput from "../common/FileInput";
import OkCancelDialog from "../common/OkCancelDialog";
import SectionHeader from "../common/SectionHeader";


/**
 * This provides a user interface for a service provider to upload a file.
 *
 * @param {string}   title                  optional section header
 * @param {string}   label                  optional label above the file
 *                                          chooser
 * @param {string}   id                     unique ID to assign the file chooser
 * @param {string}   targetFolderName       name of the folder to store the file
 * @param {string}   acceptedFileTypes      optional list of comma-separated
 *                                          file extensions to accept; if null,
 *                                          accepts any file
 * @param {function} handleInputChange      callback when a user selects or
 *                                          deletes a file; argument list
 *                                          expects an object describing the
 *                                          file
 * @param {boolean}  readOnly               true when the user is only viewing
 *                                          data
 * @param {boolean}  isEditingRestricted    true when editing is restricted,
 *                                          such as for a service version that
 *                                          has already been launched
 * @param {boolean}  required               true if the file is required
 * @param {boolean}  enableReplace          true to enable the replace button.
 * @param {string}   formError              error message to display, if any
 */
const WizardResourceFile = forwardRef(
    (
        {
            title,
            label,
            id,
            targetFolderName,
            acceptedFileTypes,
            fileInput,
            handleInputChange,
            handleAdditionalFileDelete,
            handleKeepBoth,
            readOnly,
            isEditingRestricted,
            required,
            enableReplace,
            formError,
        },
        ref
    ) => {

        const dispatch = useDispatch();

        const [errorMessage, setErrorMessage] = useState(null);
        const [openDeleteConfirmation, setOpenDeleteConfirmation] =
            useState(false);
        const deleteDialogRef = useRef(null);

        const fileInputRef = useRef(null);

        const fileUploadDisabled = useMemo(
            () => readOnly || isEditingRestricted,
            [readOnly, isEditingRestricted]
        );

        useEffect(() => {
            setErrorMessage(formError?.message);
        }, [formError]);

        const handleFileUpload = (targetFile) => {
            setErrorMessage(null);

            // Enforce the file size limit specified by
            // MAX_FILE_SIZE_LIMIT_BYTES variable.
            if (targetFile.size > MAX_FILE_SIZE_LIMIT_BYTES) {
                resetFileInput();
                setErrorMessage('File size cannot exceed 10MB');
                return;
            }

            // add a new property "newUpload" to indicate this is a new file
            // uploaded by a user.
            targetFile.newUpload = true;
            targetFile.targetFolderName = targetFolderName;
            handleInputChange(targetFile);
        };


        const handleFileInputChange = ({ target }) => {
            if (target?.files?.length > 0) {
                handleFileUpload(target.files[0]);
            }
        }

        const handleFileDelete = () => {
            resetFileInput();
            handleInputChange(null);
        };

        const handleFileReplace = () => {
            if (
                formError?.errorCode === ERROR_CODE_FILE_ALREADY_EXISTS && 
                formError.indexToReplace !== undefined
            ) {
                handleAdditionalFileDelete({
                    indexToReplace: formError.indexToReplace,
                    currentIndex: formError.currentIndex,
                });
            } else {
                console.error("Failed to replace the existing file", formError);
                dispatch(setAlert({
                    show: true,
                    message: 'Failed to replace the existing file',
                    severity: 'error'
                }));
            }
        };

        const handleFileReplaceInPlace = (e) => {
            e.preventDefault();
            if (fileInputRef?.current?.input) {
                fileInputRef.current.input.disabled = false;
                fileInputRef.current.input.click();
                fileInputRef.current.input.disabled = fileUploadDisabled;
            }
        }

        const handleDownloadClick = () => {
            if (fileInput.newUpload) {
                const url = URL.createObjectURL(fileInput);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = fileInput.name;
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);
            } else {
                downloadS3File(fileInput.filePath);
            }
        };

        const resetFileInput = () => {
            if (fileInputRef?.current?.clearFiles) {
                fileInputRef.current.clearFiles();
            }
        };

        const disableDownload = () => {
            let disable = true;
            // To download a file,
            // if a new file, the file must have ERROR_CODE_FILE_ALREADY_EXISTS.
            // if not,
            //  1. the file path must be defined
            //  2. the virus scan status must be clean
            if (
                !!fileInput && fileInput.newUpload
                    ? formError?.errorCode === ERROR_CODE_FILE_ALREADY_EXISTS
                    : !!fileInput.filePath &&
                      fileInput?.virusScanStatus === VIRUS_SCAN_STATUS.clean
            ) {
                disable = false;
            }
            return disable;
        };

        const fileUploadBorder = classnames(
            {
               'border-05': !!formError || !!errorMessage,
               'border-emergency': !!formError || !!errorMessage,
               'padding-05': !!formError || !!errorMessage,
            }
        );

        const onKeepBothButtonClick = () => {
            if (
                formError?.errorCode === ERROR_CODE_FILE_ALREADY_EXISTS &&
                formError.currentIndex !== undefined &&
                fileInput?.name
            ) {
                handleKeepBoth({
                    currentIndex: formError.currentIndex, 
                    indexToReplace: formError.indexToReplace, 
                    fileInput
                });
            } else {
                console.error("Failed to keep both files", formError);
                dispatch(setAlert({
                    show: true,
                    message: 'Failed to keep both files',
                    severity: 'error'
                }));
            }
        }

        return (
            <>
                {
                    title &&
                    <SectionHeader title={title} labelStyle="margin-top-4" />
                }
                <Grid container spacing={2}>
                    {
                        label &&
                        <Grid item xs={12}>
                            <FieldLabel
                                label={label}
                                id={`${id}-file-label`}
                                entryId={id}
                            />
                        </Grid>
                    }
                    <Grid item container xs={12}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {
                            !!fileInput && (
                                <div className={fileUploadBorder}>
                                    {
                                        !!formError?.message &&
                                        <span className='usa-error-message' role='alert' id={`${id}-file-error`}>
                                            {formError.message}
                                        </span>
                                    }
                                    <div className='display-flex flex-align-center margin-top-2'>
                                        <code className='text-bold ' id={id}>
                                            {fileInput.name}
                                        </code>
                                        <ActionButton
                                            text="Download"
                                            buttonStyle='margin-left-2'
                                            aria-label={`download file ${fileInput.name}`}
                                            onClick={handleDownloadClick}
                                            disabled={disableDownload()}
                                            data-open-modal
                                        />
                                        {
                                            enableReplace && (
                                                <ActionButton
                                                    text="Replace"
                                                    buttonStyle='margin-left-2'
                                                    aria-label={`replace file ${fileInput.name}`}
                                                    onClick={handleFileReplaceInPlace}
                                                    disabled={readOnly}
                                                />
                                            )
                                        }
                                        {
                                            formError?.errorCode === ERROR_CODE_FILE_ALREADY_EXISTS && (
                                                <>
                                                    <ActionButton
                                                        text="Replace"
                                                        buttonStyle='margin-left-2'
                                                        aria-label={`replace file ${fileInput.name}`}
                                                        onClick={handleFileReplace}
                                                        disabled={readOnly || isEditingRestricted}
                                                        data-open-modal
                                                    />
                                                    <ActionButton
                                                        text="Keep both"
                                                        buttonStyle='margin-left-2'
                                                        aria-label={`keep both ${fileInput.name}`}
                                                        onClick={onKeepBothButtonClick}
                                                        disabled={readOnly || isEditingRestricted}
                                                        data-open-modal
                                                    />
                                                </>
                                            )
                                        }
                                        <ActionButton
                                            text="Delete"
                                            buttonStyle='margin-left-2'
                                            isDelete
                                            aria-label={`delete file ${fileInput.name}`}
                                            onClick={() =>
                                                setOpenDeleteConfirmation(true)
                                            }
                                            disabled={readOnly || isEditingRestricted}
                                            data-open-modal
                                            aria-controls={deleteDialogRef?.current?.modalId}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        
                        <FileInput
                            ref={fileInputRef}
                            accept={acceptedFileTypes}
                            id={id}
                            type="file"
                            name="file"
                            disabled={fileUploadDisabled}
                            required={required}
                            onChange={handleFileInputChange}
                            errorMessage={errorMessage}
                            hidden={!!fileInput}
                        />
                    </Grid>
                </Grid>

                {/* Dialog to confirm deleting file. */}
                <OkCancelDialog
                    id="confirm-delete-resource-file"
                    open={openDeleteConfirmation}
                    okText={OK_DELETE_TEXT}
                    cancelText={CANCEL_DELETE_TEXT}
                    heading="Confirm File Deletion"
                    handleOkOp={() => {
                        setOpenDeleteConfirmation(false);
                        handleFileDelete();
                    }}
                    handleCancelOp={() => setOpenDeleteConfirmation(false)}
                    dialogRef={deleteDialogRef}
                >
                    {/* We need to create the dialog for the sake of the 'ref'
                    but don't create the content if the dialog is closed */}
                    {openDeleteConfirmation && (
                        <>
                            <BodyText labelStyle="text-center">
                                Are you sure you want to delete file
                            </BodyText>
                            <BodyText labelStyle="text-center margin-top-1">
                                <strong>{fileInput.name}</strong>?
                            </BodyText>
                        </>
                    )}
                </OkCancelDialog>
            </>
        );
    }
);

export default WizardResourceFile;
