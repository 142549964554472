import Accordion from "../common/Accordion";
import DataTable from "../common/DataTable";
import { downloadS3File } from "../../utils/s3Utils";
import { ALL_ITEMS } from "../../utils/utils";
import "./AdditionalFiles.css";

/**
 * This component renders an accordion with additional file information.
 * Each file is displayed in a data table with columns for file name and 
 * description.  The file name column contains clickable links to download the 
 * corresponding files from S3.
 * 
 * @param {string} id       unique ID for the AdditionalFiles component. 
 *                          default="additional-files".
 * @param {object[]} files  an array of objects representing additional files. 
 *                          Each object should have properties for name, 
 *                          description, and filePath.
 *
 * @returns {JSX} - AdditionalFiles component.
 */
const AdditionalFiles = ({ id="additional-files", files }) => (
    <Accordion id={`${id}-accordion`} title="Additional Files" show={false}>
        <DataTable
            id={`${id}-table`}
            columns={[
                {
                    header: "File Name",
                    accessor: "name",
                    enableSort: true,
                    formatter: (columnValue, row) => {
                        return (
                            <span
                                tabIndex={0}
                                className="additional-files-download-link"
                                onClick={() => downloadS3File(row.filePath)}
                                onKeyDown={(e) =>
                                    (e.key === "Enter" || e.key === " ") &&
                                    downloadS3File(row.filePath)
                                }
                            >
                                {columnValue}
                            </span>
                        );
                    },
                },
                {
                    header: "Description",
                    accessor: "description",
                    enableSort: true,
                },
            ]}
            rows={files}
            enableFilter={false}
            defaultRowsPerPage={ALL_ITEMS}
            minWidth={300}
        />
    </Accordion>
);

export default AdditionalFiles;
