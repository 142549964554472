import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getUsers } from '../../apiClient';
import { setLinks as setBreadcrumbs } from '../../app/slices/breadcrumbs';
import { setPageTitle } from '../../app/slices/page';
import { setAlert } from '../../app/slices/alert';
import CircularProgressIndicator from '../../components/common/CircularProgressIndicator';
import DataTable from '../../components/common/DataTable';
import { useAbortController } from '../../hooks';
import { getDateFromTimestamp } from '../../utils/timeUtils';
import { URL_HOME, URL_ADMIN, URL_ADMIN_USER } from '../../utils/navigation';

/**
 * This provides the list of users in a table for the DIP Administrator.
 */

const UserList = () => {
    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    const tableColumns = [
        {
            header: 'ID',
            accessor: 'id',
            enableSort: true,
            align: 'right'
        },
        {
            header: 'Name',
            accessor: 'name',
            enableSort: true
        },
        {
            header: 'Username',
            accessor: 'cognito_username',
            enableSort: true
        },
        {
            header: 'Email',
            accessor: 'email',
            enableSort: true
        },
        {
            header: 'Company',
            accessor: 'company_name',
            enableSort: true
        },
        {
            header: 'Registered',
            accessor: 'registration_time',
            enableSort: true,
            formatter: getDateFromTimestamp,
            align: 'right'
        }
    ];

    const breadcrumbs = [
        { name: 'Home', href: URL_HOME },
        { name: 'Admin', href: URL_ADMIN },
        { name: 'Users', href: null },
    ];

    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));
        dispatch(setPageTitle('User Administration'));
        setLoading(true);
        getUsers({ abortSignal: abortSignalRef?.current })
        .then(res => {
            setUsers(res.data?.users ?? []);
            setLoading(false);
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to get users:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get users',
                severity: 'error'
            }));

            setLoading(false);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRowClick = (data) => {
        if (data) {
            navigate(`${URL_ADMIN_USER}/${data.id}`);
        } else {
            console.error('Invalid row data: ', data);
        }
    };

    return (
        <Card id='admin-user-list-card' sx={{ p: '1rem' }}>
            <CardContent>
                { 
                    loading &&
                    <CircularProgressIndicator
                        type='large'
                        altText='Loading user data...'
                    />
                }

                <DataTable
                    id='user-list-table'
                    columns={tableColumns}
                    rows={users}
                    defaultRowsPerPage={25}
                    handleRowClick={handleRowClick}
                    enableFilter={true}
                />
            </CardContent>
        </Card>
    )
};

export default UserList;
