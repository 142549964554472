import dragHandle from '@uswds/uswds/src/img/usa-icons/drag_handle.svg';
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

/**
 * This component serves as a draggable wrapper for the child component,
 * allowing the child component to be draggable.
 * This component was copied and modified from one of the examples from React DnD:
 * https://react-dnd.github.io/react-dnd/examples/sortable/simple
 *
 * @param {string}   id             unique id for this item
 * @param {number}   index          index of this item in the list of items, 
 *                                  0-based
 * @param {string}   itemType       name of a class of items to which this item 
 *                                  belongs
 * @param {function} moveItem       handler that performs the move operation
 * @param {function} handleDragEnd  handler called when the dragging stops
 * @param {object}   props          props.children is the content being dragged
 */
const DraggableItem = ({ 
        id, 
        index, 
        itemType, 
        moveItem, 
        handleDragEnd, 
        ...props 
}) => {

    const dragRef = useRef(null);
    const dropRef = useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: itemType,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId()
            };
        },
        hover(item, monitor) {
            if (!dragRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = dragRef.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the 
            // item's height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            //
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveItem(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }
    });
    const [{ isDragging }, drag, preview] = useDrag({
        type: itemType,
        item: () => {
            return { id, index };
        },
        end(item, monitor) {
            // invoke the handlerDragEnd handler when the dragging stops.
            if (handleDragEnd) {
                handleDragEnd();
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const opacity = isDragging ? 0 : 1;

    drag(dragRef);
    drop(dropRef);
    return (
        <div ref={dropRef}>
            <div
                ref={preview}
                data-handler-id={handlerId}
                className='margin-bottom-1 display-flex'
                style={{ opacity }}
            >
                <img
                    ref={dragRef}
                    src={dragHandle}
                    alt='drag-handle'
                    className='margin-right-1'
                    style={{
                        cursor: 'move'
                    }}
                />
                {props.children}
            </div>
        </div>
    );
};

export default DraggableItem;
