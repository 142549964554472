import CircularProgress from '@mui/material/CircularProgress';


/**
 * This displays the circular progress indicator.
 *
 * @param {string} type      (optional) style if not using the default;
 *                           options are 'backdrop' or 'large'
 * @param {string} altText   accessible alt text for the indicator; 
 *                           default='Loading data...'
 * @param {object} props     additional properties to apply to the
 *                           circular progress icon
 */
const CircularProgressIndicator = ({
    type='default',
    altText='Loading data...',
    ...props
}) => {

    const useStyle = styles[type] ?? styles['default'];

    return (
        <CircularProgress 
            color={useStyle['color']}
            size={useStyle['size']}
            alt={altText} 
            {...props} 
        />
    );
};

const styles = {
    backdrop: {
        color: 'backdropContrast',
        size: '3rem',
    },
    large: {
        color: 'primary',
        size: '2.5rem',
    },
    default: {
        color: 'primary',
        size: '1.5rem',
    },
};

export default CircularProgressIndicator;
