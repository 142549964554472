import { Box, Card, CardContent, Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch } from "react-redux";

import dragHandle from '@uswds/uswds/src/img/usa-icons/drag_handle.svg';

import { getAccessRoles, reorderAccessRoles } from "../../apiClient";
import { setAlert } from "../../app/slices/alert";
import { setLinks as setBreadcrumbs } from '../../app/slices/breadcrumbs';
import { setPageTitle } from '../../app/slices/page';
import BodyText from "../../components/common/BodyText";
import DraggableItem from "../../components/common/DraggableItem";
import LinkButton from "../../components/common/LinkButton";
import MajorHeading from "../../components/common/MajorHeading";
import MinorHeading from "../../components/common/MinorHeading";
import { useAbortController } from "../../hooks";
import { ACCESS_ROLE_PAGE_ACTION } from "../../utils/accessRole";
import { URL_ADMIN, URL_ADMIN_ACCESS_ROLE, URL_HOME } from "../../utils/navigation";

// Breadcrumbs are only available for the Admin
const breadcrumbs = [
    { name: 'Home', href: URL_HOME },
    { name: 'Admin', href: URL_ADMIN },
    { name: 'Access Roles', href: null }
];

export default function AccessRoleList() {
    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [orderUpdated, setOrderUpdated] = useState(false);

    const [accessRoles, setAccessRoles] = useState();

    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));
        dispatch(setPageTitle('Access Role Administration'));

        setLoading(true);

        getAccessRoles({ abortSignal: abortSignalRef?.current })
        .then((res) => {
            setAccessRoles(res.data.roles);
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to get access roles', err.message);
            dispatch(setAlert({
                show: true,
                message: 'failed to get access roles',
                severity: 'error'
            }));
        })
        .finally(() => setLoading(false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // update the order of the list when the drag ends
    const handleDragEnd = () => {
        if (orderUpdated === true) {
            setLoading(true);
            let orders = accessRoles.map(({ id }, order) => ({ id, order }));
            reorderAccessRoles({
                body: { orders }, abortsignal: abortSignalRef?.current
            })
            .then(() => {
                dispatch(setAlert({
                    show: true,
                    message: 'successfully reordered the access roles',
                    severity: 'success'
                }));
            })
            .catch(err => {
                if (isCancel(err))
                    return;

                console.error('failed to update the access roles', err.message);
                dispatch(setAlert({
                    show: true,
                    message: 'failed to update the access roles',
                    severity: 'error'
                }));
            })
            .finally(() => {
                setLoading(false);
                setOrderUpdated(false);
            })
        }
    };

    // Actual logic to move the item in the list
    const moveAccessRole = useCallback((dragIndex, hoverIndex) => {
        setOrderUpdated(true);
        setAccessRoles((prevRoles) => {
            let rtn = [...prevRoles];
            rtn.splice(dragIndex, 1);
            rtn.splice(hoverIndex, 0, prevRoles[dragIndex])
            return rtn;
        });
    }, [])

    const renderCard = ({ id, role, definition }, index) => {
        return (
            <DraggableItem
                key={id}
                index={index}
                id={`${role}-${id}`}
                itemType='AccessRoleItem'
                moveItem={moveAccessRole}
                handleDragEnd={handleDragEnd}
            >
                <Grid container item key={id} sx={{ alignItems: 'center' }}>
                    <Grid item xs={2} >
                        <BodyText label={role} labelStyle='margin-top-0' />
                    </Grid>
                    <Grid item xs={7} >
                        <BodyText label={definition} labelStyle='margin-top-0' />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'end'
                        }}
                    >
                        <LinkButton text='View'
                            href={getAccessRoleDetailLink([`roleId=${id}`, `action=${ACCESS_ROLE_PAGE_ACTION.view}`])}
                            style={{ marginRight: '1rem' }}
                        />
                        <LinkButton text='Edit'
                            href={getAccessRoleDetailLink([`roleId=${id}`, `action=${ACCESS_ROLE_PAGE_ACTION.update}`])}
                            style={{ marginRight: '1rem' }}
                        />
                        <LinkButton text='Delete' isDelete
                            href={getAccessRoleDetailLink([`roleId=${id}`, `action=${ACCESS_ROLE_PAGE_ACTION.delete}`])}
                        />
                    </Grid>
                </Grid>
            </DraggableItem>
        )
    };

    const getAccessRoleDetailLink = useCallback((queryParams) => {
        return `${URL_ADMIN_ACCESS_ROLE}?${queryParams.join('&')}`;
    }, []);

    return (
        <Card id='admin-access-roles' sx={{ p: '1rem' }}>
            <CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <MajorHeading label='Access Roles' loading={loading} />
                    <div>
                        <LinkButton text='Create a new role'
                            href={getAccessRoleDetailLink([`action=${ACCESS_ROLE_PAGE_ACTION.create}`])}
                        />
                    </div>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        borderBottomWidth: '2px',
                        borderBottomColor: 'secondary',
                        borderBottomStyle: 'solid',
                        marginBottom: '1rem'
                    }}
                >
                    <img
                        src={dragHandle}
                        alt='drag a role to reorder it'
                        className='margin-right-1'
                    />
                    <Grid container>
                        <Grid item xs={2} sx={{ display: 'flex' }}>
                            <MinorHeading label='Names' />
                        </Grid>
                        <Grid item xs={10}>
                            <MinorHeading label='Definitions' />
                        </Grid>
                    </Grid>
                </Box>

                <Box>
                    <DndProvider backend={HTML5Backend}>
                        {
                            accessRoles && accessRoles.map((role, idx) => renderCard(role, idx))
                        }
                    </DndProvider>
                </Box>

            </CardContent>
        </Card >
    );
}
