import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setPageTitle } from '../app/slices/page';
import { downloadS3File, getCatalogServiceBucketName } from '../utils/s3Utils';
import MajorHeading from '../components/common/MajorHeading';
import './UserGuides.css';

/**
 * This component displays a set of user guide files which the user can click
 * on to download.
 */
const UserGuides = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPageTitle('Help: User Guides'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card id='user-guides' sx={{ p: '1rem' }}>
            <CardContent>
                <MajorHeading label='User Guides' labelStyle='text-center'/>
                <table className="usa-table user-guides-table usa-table--borderless width-full">
                    <thead>
                        <tr>
                            <th style={{ width: '30%' }}>Title</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            documents.map((doc, idx) => (
                                <tr key={idx}>
                                    <td>
                                        <span
                                            tabIndex={0}
                                            className='user-guides-download-link'
                                            onClick={() => downloadS3File(`${getCatalogServiceBucketName()}/${doc.filename}`)}
                                            onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') &&
                                                downloadS3File(`${getCatalogServiceBucketName()}/${doc.filename}`)
                                            }
                                        >
                                            {doc.title}
                                        </span>
                                    </td>
                                    <td>{doc.description}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </CardContent>
        </Card>
    )
};

const documents = [
    {
        title: 'DIP Portal User Guide',
        description: 'Step-by-step overview of the DIP Portal features',
        filename: 'DIP User Guide V1.7.pdf',
    },
    {
        title: 'How to invoke a REST Service',
        description: 'Detailed information about connecting and calling REST API services',
        filename: 'DIP How to Invoke REST API V1.7.pdf'
    },
    {
        title: 'SFTP Service Guide',
        description: 'How to connect to an SFTP service.',
        filename: 'SFTP Service Guide V1.7.pdf',
    },
]

export default UserGuides;
