import Card from '@mui/material/Card';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setPageTitle } from '../../app/slices/page';
import DipLink from '../../components/common/DipLink';
import BodyTitle from '../../components/common/BodyTitle';
import MajorHeading from '../../components/common/MajorHeading';
import { URL_HOME } from '../../utils/navigation';

function PageNotFound() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageTitle('404 - Page Not Found'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card  sx={{p: '1rem'}} >
            <MajorHeading label='The requested page could not be found.' />
            <BodyTitle label='Return to the '>
                <DipLink href={URL_HOME} text='Catalog' />.
            </BodyTitle>
        </Card>
    );
};

export default PageNotFound;
