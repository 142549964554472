import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  FLUSH, PAUSE,
  PERSIST, persistReducer, persistStore, PURGE,
  REGISTER, REHYDRATE
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import adminReducer from './slices/admin';
import alertReducer from './slices/alert';
import breadcrumbsReducer from './slices/breadcrumbs';
import browseReducer from './slices/browse';
import companyReducer from './slices/company';
import dashboardReducer from './slices/dashboard';
import newsReducer from './slices/news';
import pageReducer from './slices/page';
import registeredServicesReducer from './slices/registeredServices';
import searchReducer from './slices/search';
import subscriptionReducer from './slices/subscription';
import userReducer from './slices/user';

export const catalogAppDataKey = 'catalog-service';

const persistConfig = {
  key: catalogAppDataKey,
  version: 1,
  storage, // localStroage
  whitelist: ['user'], // the slices listed here will be persisted
  stateReconciler: autoMergeLevel2, // merge 2 level deep
};

const combinedRootReducer = combineReducers({
  admin: adminReducer,
  alert: alertReducer,
  breadcrumbs: breadcrumbsReducer,
  browse: browseReducer,
  company: companyReducer,
  dashboard: dashboardReducer,
  news: newsReducer,
  page: pageReducer,
  registeredServices: registeredServicesReducer,
  search: searchReducer,
  subscription: subscriptionReducer,
  user: userReducer,
});

const persistedReducer = persistReducer(persistConfig, combinedRootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production'
});

export const persistor = persistStore(store);