import ClearIcon from '@mui/icons-material/Clear';
import HelpOutlineIcon from '@mui/icons-material/Help';
import Search from '@mui/icons-material/Search'
import Box from '@mui/material/Box';

import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setAlert } from '../../app/slices/alert';
import { clearSearchText, fetchSearchResults, setField, selectSearchText,
         selectSelectedCategories, selectSelectedProviders,
         selectSelectedServiceTypes
       } from '../../app/slices/search';
import { useAbortController } from '../../hooks';
import { URL_HOME, URL_SEARCH } from '../../utils/navigation';
import DipIconButton from '../common/DipIconButton';
import Modal from '../common/modal/Modal';
import SearchTextField from './SearchTextField';

/**
 * This provides the search user interface.  There is a textfield for the user
 * to enter a search term; the search itself does not begin until the user
 * presses <enter> or clicks the "search" (magnifying glass) icon.
 * There is also help text.  When the search begins, the user is automatically
 * redirected to the search results page.
 */

function SearchBar() {

    const { abortSignalRef, abort } = useAbortController();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectedCategories = useSelector(selectSelectedCategories);
    const selectedProviders = useSelector(selectSelectedProviders);
    const selectedServiceTypes = useSelector(selectSelectedServiceTypes);

    const searchText = useSelector(selectSearchText);
    const [showSearchInfo, setShowSearchInfo] = useState(false);
    const searchModalRef = useRef(null);


    useEffect(() => {
        // When on the home page, make sure the search text is clear
        if (window.location.pathname === URL_HOME) {
            dispatch(clearSearchText());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);

    // Are any of the search filters on the Search page selected?
    const areFiltersSelected = () => {
        return ((selectedCategories && (selectedCategories.length > 0)) ||
                (selectedProviders && (selectedProviders.length > 0)) ||
                (selectedServiceTypes && (selectedServiceTypes.length > 0)));
    };

    const setSearchText = (value) => {
        let clearSearch = false;
        if (searchText && !value) {
            clearSearch = true;
        }
        if (clearSearch) {
            handleClear();
        }
        else {
            dispatch(setField({ field: 'searchText', value: value }))
        }
    }

    const handleSubmit = async () => {
        if (window.location.pathname !== URL_SEARCH) {
            // Navigate to the Catalog search page to instigate the search
            navigate(URL_SEARCH);
        }
        else {
            getSearchResults();
        }
    }

    const handleClear = () => {
        dispatch(clearSearchText());
        document.getElementById('search-input').focus();

        if (window.location.pathname === URL_SEARCH)
        {
            if (areFiltersSelected()) {
                getSearchResults();
            } else {
                navigate(URL_HOME);
            }
        }
    }

    const getSearchResults = async () => {
        dispatch(fetchSearchResults({
            getServiceCountPerFilter: true,
            abortSignalRef,
            abort
        }))
        .unwrap()
        .catch(err => {
            if (err === 'cancelled')
                return;

            console.error('failed to get search results:', err)
            dispatch(setAlert({
                show: true,
                message: 'Failed to get search results',
                severity: 'error'
            }));
        });
    }

    const toggleSearchInfoVisibility = () => {
        setShowSearchInfo(!showSearchInfo);
    }

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end'
                }}
            >
                <SearchTextField
                    id='search-input'
                    value={searchText}
                    placeholder='Search for services'
                    ariaLabel='enter criteria to search for services'
                    setValue={setSearchText}
                    handleEnter={handleSubmit}
                />

                <DipIconButton
                    id='search-icon'
                    aria-label='start search'
                    onClick={handleSubmit}
                    disabled={!searchText}
                >
                    <Search />
                </DipIconButton>

                <DipIconButton
                    id='clear-search-icon'
                    aria-label='clear search'
                    onClick={handleClear}
                    disabled={!searchText}
                >
                    <ClearIcon />
                </DipIconButton>

                <DipIconButton
                    id='search-help-icon'
                    aria-label='search help'
                    onClick={toggleSearchInfoVisibility}
                    data-open-modal
                    aria-controls={searchModalRef?.current?.modalId}
                >
                    <HelpOutlineIcon />
                </DipIconButton>

            </Box>

            { /* Help text */ }
            <Modal
                id='search-help-dialog'
                isOpen={showSearchInfo}
                handleClose={toggleSearchInfoVisibility}
                heading='Search Help'
                isLarge
                ref={searchModalRef}
            >
                {/* We need to create the modal for the sake of the 'ref',
                    but use the "open" flag to short-circuit the work of
                    creating the main content if the modal is closed */}
                {
                    showSearchInfo &&
                    <div className='usa-prose'>
                        The search feature looks for services containing
                        the search terms in the service name.
                        <ul>
                            <li>
                                All searches are case insensitive. (AND, OR,
                                and NOT are capitalized in the examples below
                                for clarity.)
                            </li>
                            <li>
                                Searches use a default logical AND operator
                                between keywords (e.g. a search for
                                '<b>flight cdm</b>' is interpreted as
                                '<b>flight AND cdm</b>').
                            </li>
                            <li>
                                The logic OR operator is supported but must be
                                explicitly provided (e.g.
                                '<b>flight OR cdm</b>').
                            </li>
                            <li>
                                When not using quotes, word order does not
                                matter (e.g. a search for '<b>flight cdm</b>'
                                will return the same results as
                                '<b>cdm flight</b>').
                            </li>
                            <li>
                                Any text wrapped with double quotes will be
                                interpreted as a 'followed by' operation (e.g.
                                ' <b>"arrival runway"</b> ' will return results
                                where keyword '<b>arrival</b>' is followed by
                                keyword '<b>runway</b>').
                            </li>
                            <li>
                                A NOT operator is supported by placing a dash
                                in front of the item to be excluded (e.g.
                                '<b>flight -DAL</b>' will return all flight
                                services except those providing DAL data).
                            </li>
                            <li>
                                Any of the search operators may be mix-matched
                                together, although it is strongly recommended
                                that parentheses be used when combining AND
                                and OR (e.g. '<b>flight AND (dfw OR dal)</b>').
                            </li>
                        </ul>
                    </div>
                }
            </Modal>
        </>
    );
}

export default SearchBar;
