import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    createAccessRequest, createDipUser, getAccessRequestDetail,
    getCompanies, verifyIvt
} from "../apiClient";
import { setAlert } from "../app/slices/alert";
import ActionButton from "../components/common/ActionButton";
import ProcessingOverlay from "../components/common/ProcessingOverlay";
import StepIndicator from "../components/common/StepIndicator";
import ConfirmationStep from "../components/request-access/ConfirmationStep";
import DisclaimerStep from "../components/request-access/DisclaimerStep";
import JustificationStep from "../components/request-access/JustificationStep";
import NasaGuestAccountStep from "../components/request-access/NasaGuestAccountStep";
import TeamInformationStepTeamEntry from "../components/request-access/TeamInformationStepTeamEntry";
import TeamInformationStepTeamSelection from "../components/request-access/TeamInformationStepTeamSelection";
import { useAbortController } from "../hooks";
import { getCurrentAuthenticatedUser, hasISAVerificationToken, refreshSessionToken } from "../utils/auth";
import { URL_HOME } from "../utils/navigation";
import {
    DEFAULT_ACCESS_REQUEST_INPUT, FIRST_TIME_USER_TEAM_ID,
    JUSTIFICATION_PURPOSES_ID_MAP, validateJustificationInfo,
    validateTeamInfoInput
} from "../utils/requestAccess";
import { is404NotFoundError } from "../utils/utils";
import { isErrorObjectEmpty } from "../utils/validation";

// Step values are 0-based
const STEP_1_NASA_GUEST_ACCOUNT = 0;
const STEP_2_TEAM_INFO = 1;
const STEP_3_JUSTIFICATION = 2;
const STEP_4_REQUEST_ACCESS = 3;
const STEP_5_CONFIRMATION = 4;

const REQUEST_ACCESS_STEPS = [
    {
        step: STEP_1_NASA_GUEST_ACCOUNT,
        label: 'NASA Guest Account'
    },
    {
        step: STEP_2_TEAM_INFO,
        label: 'Team Information'
    },
    {
        step: STEP_3_JUSTIFICATION,
        label: 'Justification'
    },
    {
        step: STEP_4_REQUEST_ACCESS,
        label: 'Request Access'
    },
    {
        step: STEP_5_CONFIRMATION,
        label: 'Confirmation'
    }
];

/**
 * This component provides the pages with sign-up/access request processes.
 * This is a multi-step process.  The top of the page will have a step indicator
 * to inform the user where they are in the process.  The central content of
 * the page will be updated based on what step the user is on.  The bottom
 * of the screen has Back and Next buttons for most steps so the user can
 * navigate between steps if desired.
 */
const RequestAccess = () => {

    const { abortSignalRef, isCancel } = useAbortController();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [loadingMsg, setLoadingMsg] = useState('');

    // User data
    const [userAuthenticated, setUserAuthenticated] = useState(false);
    const [requester, setRequester] = useState(
                { name: null, username: null, email: null });

    // for Step 1:  NGS => NASA Guest Services
    const [showNGSnotice, setShowNGSnotice] = useState(false);

    // for step 2:  companies with matching email domains
    const [matchingCompanies, setMatchingCompanies] = useState([]);
    // for step 2: whether or not to show matching teams component in the
    // team information step
    const [showMatchingCompanies, setShowMatchingCompanies] = useState(false);
    // for step 2: team ID if the user selected a matching team
    const [selectedTeamId, setSelectedTeamId] = useState(FIRST_TIME_USER_TEAM_ID);
    // for step 2: flag if the user didn't select a matching team
    const isNoMatchingTeamSelected = useMemo(() => selectedTeamId === FIRST_TIME_USER_TEAM_ID, [selectedTeamId]);
    // for step 2: if the IVT is valid
    const [invalidIvt, setInvalidIvt] = useState(false);

    // User inputs for team and justification
    const [input, setInput] = useState({ ...DEFAULT_ACCESS_REQUEST_INPUT });
    const formRef = useRef(null);
    const [formErrors, setFormErrors] = useState({});

    // Step navigation and flags
    const [disableNextButton, setDisableNextButton] = useState(false);
    const [activeStep, setActiveStep] = useState(STEP_1_NASA_GUEST_ACCOUNT);
    const isLastStep = useMemo(() => activeStep === STEP_5_CONFIRMATION, [activeStep]);
    const isRequestAccessStep = useMemo(() => activeStep === STEP_4_REQUEST_ACCESS, [activeStep]);

    // Set up basic user information and determine what step should be displayed
    useEffect(() => {
        setLoading(true);
        setLoadingMsg('Checking the user data...')

        // Check whether or not the user is a first-time user
        getCurrentAuthenticatedUser()
        .then(async (userData) => {
            let authenticated = userData !== null;
            setUserAuthenticated(authenticated);
            if (authenticated) {

                // If the user is authenticated and the IVT is verified,
                // redirect to the home page.  Otherwise, the signed-in
                // user continues the signup processes
                let ivtVerified = await hasISAVerificationToken();
                if (ivtVerified) {
                    navigate(URL_HOME);
                } else {
                    let nextStep = STEP_2_TEAM_INFO;
                    let userEmail = userData?.attributes?.email;
                    let userEmailDomain = userEmail ? userEmail.split('@')[1] : null;
                    setRequester({
                        name: userData.attributes.given_name && userData.attributes.family_name ?
                            `${userData.attributes.given_name} ${userData.attributes.family_name}` :
                            userData.username,
                        username: userData.username,
                        email: userEmail
                    });

                    let accessRequestDetail = null;

                    try {
                        // get id field of the access request record for the current user.
                        accessRequestDetail = await getAccessRequestDetail({
                            requesterUsername: userData.username,
                            selectFields: 'id',
                            abortSignal: abortSignalRef?.current
                        });
                    } catch (e) {
                        if (!is404NotFoundError(e)) {
                            throw e;
                        }
                    }

                    if (accessRequestDetail?.data) {
                        // If current user submitted the request already,
                        // show the request confirmation step
                        nextStep = STEP_5_CONFIRMATION;
                    } else {
                        // Get companies with matching email domain
                        let companies = await getCompanies({
                            emailDomain: userEmailDomain,
                            selectFields: 'c.id,c.name',
                            abortSignal: abortSignalRef?.current
                        });
                        companies = companies?.data?.companies;

                        // Show matching companies if found.
                        if (companies && companies.length > 0) {
                            setMatchingCompanies(companies);
                            setShowMatchingCompanies(true);
                        }
                    }

                    setActiveStep(nextStep);
                }
            }
        })
        .catch(error => {
            if (isCancel(error))
                return

            console.error('failed to initialize the request access page',
                error.message);
            dispatch(setAlert({
                show: true,
                message: 'failed to initialize the request access page',
                severity: 'error'
            }));
        })
        .finally(() => {
            setLoading(false);
            setLoadingMsg('');
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Enables or disables the Next button
    useEffect(() => {
        let disable = !userAuthenticated;

        // Check whether all required inputs satisfy their constraints.
        if (!disable && formRef.current?.elements) {
            disable = !formRef.current.checkValidity();
        }

        if (!disable) {
            if (activeStep === STEP_2_TEAM_INFO) {
                // If the user is prompted to select a team...
                if (showMatchingCompanies) {
                    // If the 'None of the above' team option is selected,
                    // enable the Next button.  Otherwise, only enable the
                    // Next button if the IVT is valid.
                    if (isNoMatchingTeamSelected) {
                        disable = false;
                    } else {
                        disable = !invalidIvt;
                    }
                }
                // If the user is entering new team info and hasn't entered
                // anything yet, disable the Next button
                else if (!formRef.current?.elements) {
                    disable = true;
                }
            }
        }

        setDisableNextButton(disable);
    }, [showMatchingCompanies, userAuthenticated, invalidIvt, activeStep,
        isNoMatchingTeamSelected, input]);

    /**
     * Determines if it is ok for the user to advance to the next step when the
     * Next button is clicked by validating the input of the current step, if
     * appropriate.
     */
    const canSubmit = () => {
        let errors = {};

        // Use the relevant validator for the current step to validate the input.
        if (activeStep === STEP_2_TEAM_INFO) {
            if (!showMatchingCompanies) {
                errors = validateTeamInfoInput(input);
            }
        } else if (activeStep === STEP_3_JUSTIFICATION) {
            errors = validateJustificationInfo(input);
        } else if (activeStep === STEP_4_REQUEST_ACCESS) {
            errors = {
                ...validateTeamInfoInput(input),
                ...validateJustificationInfo(input)
            };
        }

        setFormErrors(errors)
        const rtn = isErrorObjectEmpty(errors)

        return rtn;
    }

    /**
     * Handles changes to user input fields.
     */
    const handleInputChange = (e) => {
        handleInputChangeByFieldName(e.target.name, e.target.value);
    };

    const handleJustificationPurposeOtherTextChange = (e) => {
        let update = { ...input };
        update.purposes.otherText = e.target.value;
        setInput(update);
    }

    const handleJustificationPurposeCheckboxChange = (label, checked) => {
        let update = { ...input };
        let purposeId = JUSTIFICATION_PURPOSES_ID_MAP[label];
        if (checked) {
            update.purposes.selectedIds.add(purposeId);
        } else {
            update.purposes.selectedIds.delete(purposeId);
        }
        setInput(update);
    };

    const handleInputChangeByFieldName = (fieldName, value) => {
        setInput({
            ...input,
            [fieldName]: value
        });
    };

    /**
     * Increments the current step number.
     */
    const advanceToNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    /**
     * Handles the "Next" button click by doing the final processing for the
     * current step and/or performing step cleanup, before incrementing
     * the step.
     */
    const handleNext = () => {
        // Only proceed if the input of the current step is valid
        if (canSubmit()) {
            switch (activeStep) {
                // Team Information step
                case STEP_2_TEAM_INFO:
                    if (showMatchingCompanies &&
                       (selectedTeamId === FIRST_TIME_USER_TEAM_ID)) {
                        // no matching team was selected
                        setShowMatchingCompanies(false);
                    } else if (invalidIvt) {
                        setInvalidIvt(false);
                        setShowMatchingCompanies(false);
                    } else {
                        setShowMatchingCompanies(false);
                        advanceToNextStep();
                    }
                    break;
                // Request Access step
                case STEP_4_REQUEST_ACCESS:
                    setLoading(true);
                    setLoadingMsg('Submitting access request...')
                    let requestBody = {
                        ...input,
                        requester_name: requester.name,
                        requester_username: requester.username,
                        requester_email: requester.email
                    };
                    requestBody['purposes']['selectedIds'] =
                        [...requestBody['purposes']['selectedIds']]
                    createAccessRequest({
                        body: requestBody,
                        abortSignal: abortSignalRef?.current
                    })
                    .then(() => {
                        advanceToNextStep();
                    })
                    .catch((err) => {
                        if (isCancel(err))
                            return;

                        console.error('Failed to submit access request:',
                            err.message);
                        dispatch(setAlert({
                            show: true,
                            message: 'Failed to submit access request',
                            severity: 'error'
                        }));
                    })
                    .finally(() => {
                        setLoading(false);
                        setLoadingMsg('');
                    })
                    break;
                default:
                    advanceToNextStep();
            }
        } else {
            dispatch(setAlert({
                show: true,
                message: "Please verify you've entered all information correctly.",
                severity: 'error'
            }));
        }

        setShowNGSnotice(false);
    };

    /**
     * Handle when the user clicks the Back button.
     */
    const handleBack = () => {
        if (activeStep === STEP_1_NASA_GUEST_ACCOUNT && showNGSnotice) {
            setShowNGSnotice(false);
        } else if (activeStep === STEP_2_TEAM_INFO &&
                  !showMatchingCompanies && (matchingCompanies.length > 0)) {
            setShowMatchingCompanies(true);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    /**
     * Handles the user clicking the "Verify IVT" button.
     */
    const handleVerifyIvt = async (companyId, ivt) => {
        try {
            setLoading(true);
            setLoadingMsg('Verifying IVT...');
            let res = await verifyIvt({
                companyId: companyId,
                ivt: ivt,
                abortSignal: abortSignalRef?.current
            });

            let verified = res.data;
            if (verified) {

                dispatch(setAlert({
                    show: true,
                    message: 'Successfully verified IVT',
                    severity: 'success'
                }));

                // create dip user account, sign in the user if ivt is verified.
                const request = {
                    name: requester.name,
                    cognito_username: requester.username,
                    email: requester.email,
                    ivt: ivt
                };

                setLoadingMsg('Creating user account...');
                createDipUser({ body: request, abortSignal: abortSignalRef?.current })
                .then(() => {
                    // after creating the user, refresh the sesion token to get the
                    // IVT attribute added to the access/id token
                    refreshSessionToken()
                    .then(refreshed => {
                        if (refreshed) {
                            navigate(URL_HOME);
                        } else {
                            dispatch(setAlert({
                                show: true,
                                message: 'Failed to refresh the session. Please sign in again',
                                severity: 'error'
                            }));
                            setLoading(false);
                            setLoadingMsg('');
                        }
                    });
                })
                .catch(err => {
                    if (isCancel(err))
                        return;

                    console.error('Failed to create the dip user:',
                        err.response ? err.response.data : err);
                    dispatch(setAlert({
                        show: true,
                        message: 'Failed to create the DIP user',
                        severity: 'error'
                    }));
                    setLoading(false);
                    setLoadingMsg('');
                });
            } else {
                setLoading(false);
                setLoadingMsg('');
            }
            setInvalidIvt(!verified);
        } catch (err) {
            if (isCancel(err))
                return;

            console.error('failed to verify IVT', err)
            dispatch(setAlert({
                show: true,
                message: 'Failed to verify IVT',
                severity: 'error'
            }));
            setLoading(false);
            setLoadingMsg('');
        }
    }

    /**
     * Gets the main contents to display for the given step.
     */
    const getStepContent = (step) => {
        let content = null;
        if (!loading) {
            switch (step) {
                case STEP_1_NASA_GUEST_ACCOUNT:
                        content = (
                            <NasaGuestAccountStep
                                showNGSnotice={showNGSnotice}
                                setShowNGSnotice={setShowNGSnotice}
                                isUserAuthenticated={userAuthenticated}
                            />
                        );
                    break;
                case STEP_2_TEAM_INFO:
                    if (showMatchingCompanies) {
                        let teamQuery =
                              "Do you belong to one of the teams listed below?";
                        let noneOption = "None of the above";
                        if (matchingCompanies &&
                           (matchingCompanies.length === 1)) {
                            teamQuery =
                                "Do you belong to the team listed below?";
                            noneOption = "No";
                        }
                        content = (
                            <form ref={formRef}>
                                <TeamInformationStepTeamSelection
                                    teamQuery={teamQuery}
                                    setShowMatchingCompanies={setShowMatchingCompanies}
                                    teamsList={[
                                        ...matchingCompanies,
                                        {
                                            id: FIRST_TIME_USER_TEAM_ID,
                                            name: noneOption
                                        }
                                    ]}
                                    selectedTeamId={selectedTeamId}
                                    setSelectedTeamId={setSelectedTeamId}
                                    invalidIvt={invalidIvt}
                                    handleVerifyIvt={handleVerifyIvt}
                                />
                            </form>
                        )
                    } else {
                        content = (
                            <form ref={formRef}>
                                <TeamInformationStepTeamEntry
                                    input={input}
                                    handleInputChange={handleInputChange}
                                    formErrors={formErrors}
                                />
                            </form>
                        )
                    }
                    break;
                case STEP_3_JUSTIFICATION:
                    content = (
                        <form ref={formRef}>
                            <JustificationStep
                                input={input}
                                handleInputChange={handleInputChange}
                                handleJustificationPurposeCheckboxChange={handleJustificationPurposeCheckboxChange}
                                handleJustificationPurposeOtherTextChange={handleJustificationPurposeOtherTextChange}
                                formErrors={formErrors}
                            />
                        </form>
                    )
                    break;
                case STEP_4_REQUEST_ACCESS:
                    content = <DisclaimerStep />
                    break;
                case STEP_5_CONFIRMATION:
                    content = <ConfirmationStep />
                    break;
                default:
                    console.error("Invalid step", step)
            }
        }
        return content;
    };

    return (
        <>
            <Card sx={{ p: '1rem' }}>
                <CardContent>
                    <StepIndicator
                        stepList={REQUEST_ACCESS_STEPS}
                        activeStep={activeStep}
                    />

                    {/* Step Content */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '400px',
                            py: '1rem'
                        }}
                    >
                        {getStepContent(activeStep)}
                    </Box>

                    {/* Step button(s) */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        {
                            !isLastStep &&
                            (showNGSnotice || activeStep !== STEP_1_NASA_GUEST_ACCOUNT) && (
                                <ActionButton
                                    text='Back'
                                    onClick={handleBack}
                                />
                            )
                        }

                        <Box sx={{ flex: '1 1 auto' }} />

                        {
                            !isLastStep &&
                            ((activeStep !== STEP_1_NASA_GUEST_ACCOUNT) ||
                            ((activeStep === STEP_1_NASA_GUEST_ACCOUNT) &&
                              !showNGSnotice && userAuthenticated)) && (
                                <ActionButton
                                    text={isRequestAccessStep ? 'Submit Request' : 'Next'}
                                    onClick={handleNext}
                                    disabled={disableNextButton}
                                />
                            )
                        }
                    </Box>
                </CardContent>
            </Card>

            <ProcessingOverlay open={loading} msg={loadingMsg} />
        </>
    );
};

export default RequestAccess;
