import Grid from "@mui/material/Grid";

import { forwardRef } from "react";

import CircularProgressIndicator from '../common/CircularProgressIndicator';
import FieldHelp from "../common/FieldHelp";
import FieldLabel from "../common/FieldLabel";
import SectionHeader from "../common/SectionHeader";
import { VIRUS_SCAN_STATUS, VIRUS_SCAN_STATUS_COLOR, VIRUS_SCAN_STATUS_LABEL
       } from "../../utils/serviceRegistration";
import WizardResourceFile from "./WizardResourceFile";

/**
 * This is a wrapper component for the WizardResourceFile component.
 * It displays the virus scan status along with a note about the file.
 */
const VirusScanWrapperForResourceFile = forwardRef(
    (
        {
            loading,
            id,
            title,
            label,
            handleInputChange,
            handleAdditionalFileDelete,
            handleKeepBoth,
            serviceFileType,
            targetFolderName,
            fileInput,
            required,
            ...props
        },
        ref
    ) => {

        const showScanLabel = (!fileInput || !(fileInput.virusScanStatus));
        const helpId = showScanLabel ? `$[id}-scan-help` : null;

        return (
            <>
                {
                    loading ? 
                        <CircularProgressIndicator altText='Loading file' />
                    : (
                        <div>
                            {
                                title &&
                                <SectionHeader
                                    title={title}
                                    labelStyle="margin-top-2"
                                />
                            }
    
                            {
                                label &&
                                <Grid item xs={12}>
                                    <FieldLabel
                                        id={`${id}-scan-file-label`}
                                        label={label}
                                        entryId={id}
                                        helpId={helpId}
                                        required={required}
                                    />
                                </Grid>
                            }
    
                            {
                                <WizardResourceFile
                                    ref={ref}
                                    id={id}
                                    fileInput={fileInput}
                                    handleInputChange={handleInputChange}
                                    handleAdditionalFileDelete={handleAdditionalFileDelete}
                                    handleKeepBoth={handleKeepBoth}
                                    required={required}
                                    targetFolderName={targetFolderName}
                                    {...props}
                                />
                            }

                            {
                                (!fileInput || !(fileInput.virusScanStatus)) &&
                                <FieldHelp
                                    id={helpId}
                                    help='File will be scanned before being made
                                          available. Monitor scan status here.'
                                />
                            }
                            {
                                !!fileInput &&
                                !!fileInput.virusScanStatus &&
                                ((fileInput.virusScanStatus ===
                                         VIRUS_SCAN_STATUS.in_progress) ||
                                    (fileInput.virusScanStatus ===
                                         VIRUS_SCAN_STATUS.infected)) &&
                                <div className='margin-top-2' >
                                    <span>
                                        <i>Scan status:{"   "}</i>
                                    </span>
                                    <span className={`${VIRUS_SCAN_STATUS_COLOR[fileInput.virusScanStatus]} bg-base-darkest padding-x-1 padding-y-05`} >
                                         {VIRUS_SCAN_STATUS_LABEL[fileInput.virusScanStatus]}
                                    </span>
                                    {
                                        (fileInput.virusScanStatus === VIRUS_SCAN_STATUS.in_progress) &&
                                        <span className="margin-top-2 margin-left-2 font-mono-xs">
                                            <i>Check back later for updated status</i>
                                        </span>
                                    }
                                </div>
                            }
                        </div>
                    )
                }
            </>
        );
    }
);

export default VirusScanWrapperForResourceFile;
