import ActionButton from './ActionButton';
import Modal from './modal/Modal';
import ModalFooter from './modal/ModalFooter';


/**
 * This provides a confirmation dialog with two buttons such as "Ok" and
 * "Cancel"; the button labels can be customized.  The content to display in
 * the dialog is specified using props.children.
 *
 * @param {string}   id                base id for the dialog
 * @param {boolean}  open              true to display the dialog
 * @param {string}   heading           (required) title of the dialog
 * @param {string}   headingStyle      any additional USWDS styles to apply
 * @param {string}   okText            label for the Yes/Ok button; default="Ok"
 * @param {string}   cancelText        label for the No/Cancel button;
 *                                     default="Cancel"
 * @param {function} handleOkOp        function to call when the user
 *                                     clicks "Ok" or its equivalent
 * @param {function} handleCancelOp    function to call when the user
 *                                     clicks "Cancel" or its equivalent
 * @param {boolean}  isLarge           true to use a large-sized modal
 * @param {object}   dialogRef         React useRef for the modal
 * @param {object}   props             any additional properties to apply
 * @param {JSX}      props.children    the content to display in the dialog
 */
const OkCancelDialog = ({
    id,
    open,
    heading="Set Me! All Modals Are Required To Have A Title!",
    headingStyle,
    okText='Ok',
    cancelText='Cancel',
    handleOkOp,
    handleCancelOp,
    isLarge=false,
    dialogRef,
    ...props
}) => {

    return (
        <Modal
            id={id}
            isOpen={open}
            heading={heading}
            headingStyle={headingStyle}
            isLarge={isLarge}
            ref={dialogRef}
            {...props}
        >
            {props.children}
            <ModalFooter className='display-flex flex-justify-end margin-top-3' >
                <ActionButton text={cancelText}
                    onClick={handleCancelOp}
                    aria-controls={id}
                    data-close-modal
                />
                <ActionButton text={okText} buttonStyle='margin-left-2'
                    onClick={handleOkOp}
                    aria-controls={id}
                    data-close-modal
                />
            </ModalFooter>
        </Modal>
    )
};

export default OkCancelDialog;
