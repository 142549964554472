import classnames from 'classnames';

/**
 * This creates a minor heading.
 *
 * @param {string}   label        the label displayed to the user
 * @param {string}   labelStyle   additional USWDS style classes to add to the
 *                                label, e.g. 'margin-top-0' to remove a margin
 */
const MinorSubHeading = ({ label, labelStyle }) => {

    const classes = classnames('text-primary', labelStyle);

    return (
        <h4 className={classes} >
            {label}
        </h4>
    );
};

export default MinorSubHeading;
