import Box from '@mui/material/Box';

import { useSelector } from 'react-redux';

import StyledFilterChip from '../common/StyledFilterChip';

import {
    selectExecutedSearchText,
    selectSelectedCategories, selectSelectedProviders,
    selectSelectedServiceTypes
} from '../../app/slices/search';
import { ServiceTypeUtils } from '../../utils/utils';


/**
 * This provides the display of the filter chips when a user has instigated
 * a search on the main catalog page.
 */

function FilterChips({ handleSearchFilterChange, handleClearSearchText }) {

  const selectedCategories = useSelector(selectSelectedCategories);
  const selectedProviders = useSelector(selectSelectedProviders);
  const selectedServiceTypes = useSelector(selectSelectedServiceTypes);
  const searchText = useSelector(selectExecutedSearchText);

  const handleFilterChipDelete = (type, id) => {
    handleSearchFilterChange({
        type: type,
        id: id,
        checked: false
    });
  }

  return (
    <Box id='filter-chips' >
        { selectedServiceTypes &&
          selectedServiceTypes.map(({ id }) => {
              return (
                  <StyledFilterChip
                      label={ServiceTypeUtils.TYPE_TO_STRING[id]}
                      key={`chip-servicetype-${id}`}
                      variant='outlined'
                      size='small'
                      onDelete={() => {
                        handleFilterChipDelete('serviceTypes', id)
                      }}
                      onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') &&
                          handleFilterChipDelete('serviceTypes', id)
                      }
                  />
              )
          })
        }
        { selectedCategories &&
          selectedCategories.map(({ id, phrase }) => {
              return (
                  <StyledFilterChip
                      label={phrase}
                      key={`chip-domain-${id}`}
                      onDelete={() => {
                        handleFilterChipDelete('categories', id)
                      }}
                      onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') &&
                          handleFilterChipDelete('categories', id)
                      }
                  />
              )
          })
        }
        { selectedProviders &&
          selectedProviders.map(({ id, abbreviation }) => {
              return (
                  <StyledFilterChip
                      label={abbreviation}
                      key={`chip-provider-${id}`}
                      onDelete={() => {
                        handleFilterChipDelete('providers', id)
                      }}
                      onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') &&
                          handleFilterChipDelete('providers', id)
                      }
                  />
              )
          })
        }
        { searchText &&
          <StyledFilterChip
              label={searchText}
              key={`chip-searchText`}
              onDelete={() => {
                  handleClearSearchText()
              }}
              onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') &&
                  handleClearSearchText()
              }
          />
        }
    </Box>
  );
};

export default FilterChips;
