import { Box, Card, CardContent, Grid } from "@mui/material";
import { Auth } from "aws-amplify";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../app/slices/alert";
import ActionButton from "../components/common/ActionButton";
import TextField from "../components/common/TextField";
import CircularProgressIndicator from "../components/common/CircularProgressIndicator";
import { URL_HOME } from "../utils/navigation";
import { useNavigate } from "react-router-dom";

/**
 * The Cognito hosted UI in prod no longer supports the username/password login.
 * This component enables logging in using the test accounts in the catalog user pool.
 */
const TestAccountLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const formRef = useRef(null);

    const handleSignin = async () => {
        if (formRef.current.reportValidity()) {
            try {
                setLoading(true);
                await Auth.signIn(username, password);
                dispatch(
                    setAlert({
                        show: true,
                        message: "Successfully signed in",
                        severity: "success",
                    })
                );
                setLoading(false);

                navigate(URL_HOME, { replace: true });
            } catch (error) {
                console.error({ error });
                dispatch(
                    setAlert({
                        show: true,
                        message: error.message,
                        severity: "error",
                    })
                );
                setLoading(false);
            }
        }
    };

    return (
        <Box
            id="test-account-login"
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Card sx={{ maxWidth: "25rem" }}>
                <CardContent>
                    <form ref={formRef}>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    id="username"
                                    name="username"
                                    label="Username"
                                    value={username}
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                    onKeyDown={(e) =>
                                        e.key === "Enter" && handleSignin()
                                    }
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    onKeyDown={(e) =>
                                        e.key === "Enter" && handleSignin()
                                    }
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ mt: "2rem" }}>
                                {loading ? (
                                    <CircularProgressIndicator />
                                ) : (
                                    <ActionButton
                                        text="Sign in"
                                        onClick={handleSignin}
                                        buttonStyle="width-full"
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Box>
    );
};

export default TestAccountLogin;
