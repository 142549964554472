
import ActionButton from '../ActionButton';

/**
 * This provides the X in the top right corner to close a modal.  This was
 * adapted from Trussworks' https://github.com/trussworks/react-uswds/blob/main/src/components/Modal/ModalCloseButton/ModalCloseButton.tsx
 *
 * @param {string}   modalId        identifier of the dialog this closes
 * @param {function} handleClose    function to call to close the modal
 * @param {JSX}      buttonProps    any additional attributes to apply to the
 *                                  button
 */
const ModalCloseButton = ({
    modalId,
    handleClose,
    ...buttonProps
}) => {

    return (
        <ActionButton
            id={`${modalId}-close`}
            text="Close"
            aria-controls={modalId}
            aria-label="Close this dialog"
            onClick={handleClose}
            data-close-modal
            {...buttonProps}
        />
    );
}

export default ModalCloseButton;
