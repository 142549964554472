import Grid from '@mui/material/Grid';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setAlert } from '../app/slices/alert';
import { setPageTitle } from '../app/slices/page';
import { fetchSubscriptions, selectFetchingSubscriptions, selectSubscriptions } from '../app/slices/subscription';
import { selectCompanyId } from '../app/slices/user';
import CircularProgressIndicator from '../components/common/CircularProgressIndicator';
import DataTable from '../components/common/DataTable';
import NewVersionIndicator from '../components/subscription/NewVersionIndicator';
import { useAbortController } from '../hooks';
import { getServiceDetailPageUrl } from "../utils/navigation";
import { getDateFromTimestamp } from '../utils/timeUtils';
import { ALL_ITEMS } from '../utils/utils';

/**
 * This provides the table of all the services the user is subscribed to.
 */
export default function Subscriptions() {

    const { abortSignalRef } = useAbortController();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const subscriptions = useSelector(selectSubscriptions);
    const fetchingSubscriptions = useSelector(selectFetchingSubscriptions);

    const companyId = useSelector(selectCompanyId);

    useEffect(() => {
        dispatch(setPageTitle('Subscriptions'));

        // get subscriptions list
        dispatch(fetchSubscriptions({ companyId, getLogo: false, abortSignalRef }))
        .unwrap()
        .catch(err => {
            if (err === 'cancelled')
                return;

            console.error('failed to get subscriptions:', err.message);
            dispatch(setAlert({
                    show: true,
                    message: 'Failed to get subscriptions',
                    severity: 'error'
            }));
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRowClick = (data) => {
        if (data && data.service_id && data.provider_id) {
            navigate(getServiceDetailPageUrl(data.service_id, data.provider_id));
        } else {
            console.error('Invalid row data: ', data);
        }
    };

    return (
        <Grid
            id='my-account'
            container
            spacing={2}
        >
            <Grid container item xs={12}>
                {
                    fetchingSubscriptions &&
                    <CircularProgressIndicator 
                        altText='Loading subscriptions...' 
                    />
                }
                <DataTable
                    id='subscriptions-table'
                    columns={tableColumns}
                    rows={subscriptions}
                    handleRowClick={handleRowClick}
                    enableFilter={false}
                    defaultRowsPerPage={ALL_ITEMS}
                />
            </Grid>
        </Grid>
    );
};

const getVersionComponent = (versionId, rowData) => {
    return rowData.subscribedLatest ? (
        versionId
    ) : (
        <NewVersionIndicator>{versionId}</NewVersionIndicator>
    );
};

const tableColumns = [
    {
        header: 'ID',
        accessor: 'service_id',
        enableSort: true,
        align: 'right'
    },
    {
        header: 'Name',
        accessor: 'service_name',
        enableSort: true
    },
    {
        header: 'Version',
        accessor: 'version_id',
        enableSort: true,
        formatter: getVersionComponent,
        align: 'right'
    },
    {
        header: 'Provider',
        accessor: 'provider_name',
        enableSort: true
    },
    {
        header: 'Summary',
        accessor: 'summary',
        enableSort: true
    },
    {
        header: 'Subscribed',
        accessor: 'created_time',
        enableSort: true,
        formatter: getDateFromTimestamp,
        align: 'right'
    }
];