import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getServiceVersions } from '../../apiClient';
import { setAlert } from '../../app/slices/alert';
import { selectCompanyId } from '../../app/slices/user';
import { useAbortController } from '../../hooks';
import { getManageServicePage, getServiceVersionRegisterPage,
         MANAGE_SERVICE_PAGE_ACTION } from '../../utils/serviceRegistration';
import { getServiceDetailPageUrl } from '../../utils/navigation';
import { paginate } from '../../utils/utils';
import CircularProgressIndicator from '../common/CircularProgressIndicator';
import DipLink from '../common/DipLink';
import FilterInput from '../common/FilterInput';
import LinkButton from '../common/LinkButton';
import BodyText from '../common/BodyText';
import NoMatchLabel from '../common/NoMatchLabel';
import Pagination from '../common/Pagination';

const ServiceVersionsList = React.lazy(() => import('./ServiceVersionsList'));

const ServiceList = ({
    services,
    admin,
    numberOfItemsToShowPerPage,
    serviceType
}) => {

    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();
    const companyId = useSelector(selectCompanyId);

    const [filterServiceName, setFilterServiceName] = useState(null);
    const [filteredServices, setFilteredServices] = useState([]);
    const [displayedServices, setDisplayedServices] = useState([]);
    const [idToVersions, setIdToVersions] = useState({});
    const [idToFlags, setIdToFlags] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [numItemsPerPage, setNumOfItemsPerPage] = useState(10);

    // if current page changes, display the selected page
    useEffect(() => {
        setFilteredServices(filterServices(services));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    // if filter changes, display the first page
    useEffect(() => {
        setCurrentPage(1);
        setFilteredServices(filterServices(services));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [services, filterServiceName]);

    // update the displayed services when the filtered services changes,
    // when the current page changes, or the number of items per page changes
    useEffect(() => {
        setDisplayedServices(paginate(filteredServices, numItemsPerPage, 
            currentPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredServices, currentPage, numItemsPerPage]);

    // Filter the services
    const filterServices = (servicesToFilter) => {
        let rtn = servicesToFilter;

        if (servicesToFilter && !!servicesToFilter.length) {
            // filter by service name
            if (!!filterServiceName) {
                let lowerCasedServiceName = filterServiceName.toLowerCase();
                rtn = servicesToFilter.filter(s =>
                       s?.name?.toLowerCase().includes(lowerCasedServiceName));
            }
        }

        return rtn;
    };

    // Called from the pagination component to set the current page number
    const setPageFromPagination = (zeroBasedPage) => {
        setCurrentPage(zeroBasedPage + 1);
    }

    // Called from the pagination component to set the number of rows per page
    const setRowsPerPageFromPagination = (rowsPerPage) => {
        setCurrentPage(1);
        setNumOfItemsPerPage(rowsPerPage);
    }

    const updateServiceVersion = (serviceId, versionId, update) => {
        let newIdToVersions = { ...idToVersions };
        let idx = newIdToVersions[serviceId].findIndex(v =>
                       v.version_id === versionId);
        newIdToVersions[serviceId][idx] = { ...update };
        setIdToVersions(newIdToVersions);
    };

    const loadServiceVersions = async (serviceId) => {
        try {
            let res = await getServiceVersions({ service_id: serviceId,
                                  abortSignal: abortSignalRef?.current });
            let versions = res?.data?.versions;
            if (versions && versions.length > 0) {
                setIdToVersions({
                    ...idToVersions,
                    [serviceId]: versions
                })
            }
        } catch (error) {
            if (isCancel(error))
                return;

            console.error(`failed to get service Versions for ${serviceId}:`, error.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get service versions',
                severity: 'error'
            }));
        }
    };

    const toggleServiceVersions = async (serviceId) => {
        let expanded = !!idToFlags?.[serviceId]?.['expanded'];
        let flags = { ...idToFlags };
        flags[serviceId] = { expanded: !expanded }
        setIdToFlags({ ...flags });

        if (!expanded) {
            flags[serviceId]['loading'] = true;
            setIdToFlags({ ...flags });
            await loadServiceVersions(serviceId)
            flags[serviceId]['loading'] = false;
            setIdToFlags({ ...flags });
        }
    };

    // This displays the list of versions for a single service; this should
    // only be called if the user expanded the version accordion for a service.
    const getVersionDisplay = (service) => (
        <>
            <Grid container item
                xs={12}
                key={`version-list-for-${service.id}`}
                component={List}
                dense
            >
                {
                    (idToFlags?.[service.id]?.['expanded'] &&
                    !idToFlags[service.id]?.['loading']) ? 
                    (
                        <Suspense 
                            fallback={
                                <CircularProgressIndicator
                                    altText='Loading service versions...'
                                />
                            }
                        >
                            <ServiceVersionsList
                                service={service}
                                serviceVersions={idToVersions[service.id]}
                                serviceType={serviceType}
                                companyId={companyId}
                                admin={admin}
                                updateServiceVersion={updateServiceVersion}
                            />
                        </Suspense>
                    ) : 
                       <CircularProgressIndicator 
                           type='large'
                           altText='Loading service versions...'
                       />
                }
            </Grid>
            <Grid item
                xs={12}
                sx={{
                    textAlign: 'end'
                }}
            >
                <LinkButton text='Add a new version'
                    href={getServiceVersionRegisterPage(service.id, 
                              service.name, serviceType, 
                              MANAGE_SERVICE_PAGE_ACTION.create, admin)}
                />
            </Grid>
        </>
    );

    // This displays the "Version" accordion displayed underneath the service
    // name & buttons.  When the user clicks this, the list of version for
    // the service will be displayed.
    const VersionAccordion = ({ serviceId, service }) => {

        let primaryId = `versions-for-${serviceId}`;
        let expanded = idToFlags?.[serviceId]?.['expanded'] ?? false;
        return (
            <div className="usa-accordion" id={primaryId} key={primaryId} >
                <div className="usa-accordion__heading">
                    <button
                        id={`${primaryId}-button`}
                        type="button"
                        className="usa-accordion__button"
                        aria-expanded={expanded}
                        aria-controls={`${primaryId}-content`}
                        onClick={(e) => toggleServiceVersions(serviceId) }
                    >
                        Versions
                    </button>
                </div>
                <div className="usa-accordion__content usa-prose"
                    id={`${primaryId}-content`} hidden={!expanded}
                >
                    {
                        getVersionDisplay(service)
                    }
                </div>
            </div>
        );
    };

    return (
        <Box>
            <List dense>
                { /* Text field to search for services */ }
                <ListItem
                    sx={{
                        px: 0,
                        mb: '1rem'
                    }}
                >
                    <FilterInput
                        id='registered-service-search'
                        label='Search for registered services'
                        value={filterServiceName || ''}
                        setValue={setFilterServiceName}
                        handleClear={() => setFilterServiceName('')}
                    />
                </ListItem>

                { /* List of services */ }
                {
                    displayedServices && !!displayedServices.length  &&
                    displayedServices.map((service, idx) => {

                        // The default avatar background color is not dark
                        // enough to satisfy 508 color contrast requirements,
                        // so darken the color of the default avatar if the 
                        // service doesn't have its own logo
                        let avatarBgColor = !!service.service_logo_data_url ?
                            'none' : '#374274';

                        return (
                            <Fragment key={`serv-${idx}`}>
                                <Box key={service.id}
                                    sx={{
                                        width: '100%',
                                        mb: '2rem'
                                    }}
                                >
                                    <ListItem
                                        key={`service-info-${service.id}`}
                                        sx={{
                                            px: 0,
                                        }}
                                    >
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={`${service.name}`}
                                                src={service.service_logo_data_url}
                                                sx={{
                                                    img: {
                                                        objectFit: 'contain'
                                                    },
                                                    backgroundColor: avatarBgColor,
                                                }}
                                            />
                                        </ListItemAvatar>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                pr: '1rem',
                                                width: '100%'
                                            }}
                                        >
                                            <DipLink text={service.name}
                                                href={getServiceDetailPageUrl(service.id, service.provider_id)}
                                            />
                                            <BodyText
                                                label={`(ID:${service.id})`}
                                                labelStyle='font-alt-xs margin-y-0'
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <LinkButton text='View'
                                                href={getManageServicePage(service.id,
                                                    MANAGE_SERVICE_PAGE_ACTION.view,
                                                    admin, serviceType) }
                                                style={{ marginRight: '.5rem' }}
                                            />
                                            <LinkButton text='Edit'
                                                href={getManageServicePage(service.id,
                                                    MANAGE_SERVICE_PAGE_ACTION.edit,
                                                    admin, serviceType) }
                                                style={{ marginRight: '.5rem' }}
                                            />
                                            <LinkButton text='Delete' isDelete
                                                href={getManageServicePage(service.id,
                                                    MANAGE_SERVICE_PAGE_ACTION.remove,
                                                    admin, serviceType) }
                                                style={{ marginRight: '.5rem' }}
                                            />
                                        </Box>
                                    </ListItem>
                                    <ListItem
                                        key={`service-version-${service.id}`}
                                        sx={{
                                            px: 0
                                        }}
                                    >
                                        <VersionAccordion
                                            serviceId={service.id}
                                            service={service}
                                        />
                                    </ListItem>
                                </Box>
                                <Divider
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '80%',
                                        marginLeft: '10%',
                                        marginRight: '10%',
                                        marginBottom: '1.5rem',
                                    }}
                                />
                            </Fragment>
                        )
                    })
                }
            </List>

            {
                (displayedServices && !!displayedServices.length) ?  (
                    <Pagination
                        id='services-pagination'
                        initialRowsPerPage={numItemsPerPage}
                        page={currentPage - 1}
                        pageCallback={setPageFromPagination}
                        rowsPerPageCallback={setRowsPerPageFromPagination}
                        numRows={filteredServices.length}
                        units='Services'
                    />
                ) : (
                    <NoMatchLabel label='No services were found' />
                )
            }
        </Box>
    );
};


export default ServiceList;
