import { Card, CardContent, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAccessRequests } from "../../../apiClient";
import { setAlert } from "../../../app/slices/alert";
import { setLinks as setBreadcrumbs } from '../../../app/slices/breadcrumbs';
import { setPageTitle } from "../../../app/slices/page";
import DataTable from "../../../components/common/DataTable";
import MajorHeading from "../../../components/common/MajorHeading";
import { useAbortController } from "../../../hooks";
import { URL_ADMIN, URL_ADMIN_MANAGE_ACCESS_REQUEST, URL_HOME } from "../../../utils/navigation";


// Breadcrumbs are only available for the Admin
const breadcrumbs = [
    { name: 'Home', href: URL_HOME },
    { name: 'Admin', href: URL_ADMIN },
    { name: 'Access Requests', href: null }
];

const tableColumns = [
    {
        header: 'Name',
        accessor: 'requester_name',
        enableSort: true
    },
    {
        header: 'Username',
        accessor: 'requester_username',
        enableSort: true
    },
    {
        header: 'Organization',
        accessor: 'organization',
        enableSort: true
    },
    {
        header: 'Team',
        accessor: 'team_name',
        enableSort: true
    },
    {
        header: 'Time',
        accessor: 'created_time',
        enableSort: true
    },
    {
        header: 'Status',
        accessor: 'status',
        enableSort: true
    },
]

const AccessRequests = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { abortSignalRef, isCancel } = useAbortController();

    const [pendingRequests, setPendingRequests] = useState([]);
    const [disposedRequests, setDisposedRequests] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(setPageTitle('Access Request Administration'));
        dispatch(setBreadcrumbs(breadcrumbs));

        getAndFilterAccessRequests();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Retrieve all access requests and then filter them based on their status.
    // Access requests in a pending status are added to the "pendingRequests" array
    // while the others are added to the "disposedRequest" array.
    const getAndFilterAccessRequests = async () => {
        try {
            setLoading(true);
            let accessRequestsRes = await getAccessRequests({
                selectFields: 'id,requester_name,requester_username,organization,team_name,status,created_time',
                abortSignal: abortSignalRef?.current
            });

            let newPendingRequests = [];
            let newDisposedRequests = [];
            (accessRequestsRes.data?.access_requests ?? []).forEach(ac => {
                let status = ac.status;
                if (status === 'pending') {
                    newPendingRequests.push(ac);
                } else {
                    newDisposedRequests.push(ac);
                }
            })
            setPendingRequests([...newPendingRequests]);
            setDisposedRequests([...newDisposedRequests]);
        } catch (error) {
            if (isCancel(error))
                return;

            console.error('failed to retrieve access requests', error);
            dispatch(setAlert({
                show: true,
                message: 'Failed to retrieve access requests',
                severity: 'error'
            }));
        } finally {
            setLoading(false);
        }
    }

    const handleAccessRequestClick = ({ id }) => {
        navigate(`${URL_ADMIN_MANAGE_ACCESS_REQUEST}/${id}`)
    }

    return (
        <Card id='admin-access-requests-card' sx={{ py: '1rem' }}>
            <CardContent>
                <MajorHeading label='DIP Access Requests Pending Decision' loading={loading} />
                <DataTable
                    id={`access-requests-pending-decision-table`}
                    columns={tableColumns}
                    rows={pendingRequests}
                    enableFilter={true}
                    dense={true}
                    handleRowClick={handleAccessRequestClick}
                />

                <Divider sx={{ my: '1rem' }} />

                <MajorHeading label='DIP Access Requests Already Disposed' loading={loading} />
                <DataTable
                    id={`access-requests-disposed-table`}
                    columns={tableColumns}
                    rows={disposedRequests}
                    enableFilter={true}
                    dense={true}
                    handleRowClick={handleAccessRequestClick}
                />
            </CardContent>
        </Card>
    )
};

export default AccessRequests;



