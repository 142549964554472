import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setLinks as setBreadcrumbs } from '../../../app/slices/breadcrumbs';
import { setPageTitle } from '../../../app/slices/page';
import { selectDataTransferPage, setDataTransferPage } from '../../../app/slices/page';
import { ServiceTypeUtils } from '../../../utils/utils';
import RegisteredServicesAdmin from '../RegisteredServicesAdmin';
import Subscriptions from './Subscriptions';

/**
 * This provides the wrapper around a Data Transfer service.  There are two
 * tabs (Source and Subscriptions) which hold the main content.
 */
const DataTransfer = () => {
    const dispatch = useDispatch();

    const { tab } = useSelector(selectDataTransferPage);

    const breadcrumbs = [
        { name: 'Home', href: '/' },
        { name: 'Admin', href: '/admin' },
        { name: `${ServiceTypeUtils.TYPE_TO_STRING[ServiceTypeUtils.TYPES.DATA_TRANSFER]} Services`, href: null }
    ];

    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));
        dispatch(setPageTitle(
            `${ServiceTypeUtils.TYPE_TO_STRING[ServiceTypeUtils.TYPES.DATA_TRANSFER]} Service Administration`));

        if (tab === null || tab === undefined) {
            dispatch(
                setDataTransferPage({
                    tab: 0
                })
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTabValueChange = (event, newValue) => {
        dispatch(
            setDataTransferPage({
                tab: newValue
            })
        );
    };

    return (
        <Card id="admin-data-transfer">
            <CardContent>
                <Box>
                    <Tabs value={tab} onChange={handleTabValueChange}
                        aria-label="data transfer tabs"
                    >
                        {
                            TABS.map(({ label }, idx) =>
                                <Tab label={label} value={idx} key={idx}
                                    id={`admin-dts-tab-${idx}`} tabIndex={0}
                                    disableRipple
                                    sx={{ m: '0.25rem', fontSize: '1rem', textTransform: 'none', }}
                                />
                            )
                        }
                    </Tabs>
                </Box>

                {
                    TABS.map(({ component }, idx) => (
                        <TabPanel value={tab} index={idx} key={idx}>
                            {component}
                        </TabPanel>
                    ))
                }
            </CardContent>

        </Card>
    )
}

// Generic panel displayed in a tab
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`admin-dts-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const TABS = [
    {
        label: "Sources",
        component: <RegisteredServicesAdmin serviceType={ServiceTypeUtils.TYPES.DATA_TRANSFER} />
    },
    {
        label: "Subscriptions",
        component: <Subscriptions />
    }
]

export default DataTransfer;
