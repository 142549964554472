import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { isUserAuthenticated } from '../../utils/auth'
import { URL_WELCOME } from '../../utils/navigation';

/**
 * This component is a wrapper around the child components or route elements
 * and it checks if the user is authenticated.
 * If user is not authenticated, redirect to the welcome page.
 */
export default function RequireAuth({ children }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // perform side effect whenever the current location changes
  useEffect(() => {
    isUserAuthenticated()
      .then(async val => {
        setIsAuthenticated(val);
        // If unauthenticated, redirect to the welcome page.
        if (!val) {
          navigate(URL_WELCOME);
        };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return isAuthenticated ? children ?? <Outlet /> : null;
};
