import Box from '@mui/material/Box';

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { initialState as searchInitialState, selectCategories,
         selectProviders, selectServiceTypes, setField
       } from '../../app/slices/search';
import { ServiceTypeUtils } from '../../utils/utils';
import FilterCard from './FilterCard';

/**
 * This provides the filter option checkboxes, grouped by type:
 * Service Type, Service Domain, and Service Provider.
 */

function SearchFilter({ handleChange }) {

    const dispatch = useDispatch();

    const serviceTypes = useSelector(useCallback(selectServiceTypes, []));
    const categories = useSelector(useCallback(selectCategories, []));
    const providers = useSelector(useCallback(selectProviders, []));

    const [showCategory, setShowCategory] = useState(true);
    const [showProviders, setShowProviders] = useState(true);
    const [showServiceType, setShowServiceType] = useState(true);

    const handleShowCategory = () => {
        setShowCategory(!showCategory);
    };

    const handleShowProviders = () => {
        setShowProviders(!showProviders);
    };

    const handleShowServiceType = () => {
        setShowServiceType(!showServiceType);
    };

    useEffect(() => {
        if (!serviceTypes) {
            dispatch(setField({
                field: 'serviceTypes',
                value: searchInitialState.serviceTypes
            }));
        }

        if (!categories) {
            dispatch(setField({
                field: 'categories',
                value: []
            }));
        }

        if (!providers) {
            dispatch(setField({
                field: 'providers',
                value: []
            }));
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box
            id='filter-set'
            sx={{
                width: '100%',
                mt: '1rem'
            }}
        >
            <FilterCard
                id='service-type-filter'
                type='serviceTypes'
                title='Service Types'
                listItemTextField='id'
                listItemTextConvertMap={ServiceTypeUtils.TYPE_TO_STRING}
                filterList={serviceTypes}
                handleTitleClick={handleShowServiceType}
                handleChange={handleChange}
                show={showServiceType}
            />

            <FilterCard
                id='category-filter'
                type='categories'
                title='Service Domains'
                listItemTextField='phrase'
                filterList={categories}
                handleTitleClick={handleShowCategory}
                handleChange={handleChange}
                show={showCategory}
            />

            <FilterCard
                id='provider-filter'
                type='providers'
                title='Service Providers'
                listItemTextField='abbreviation'
                filterList={providers}
                handleTitleClick={handleShowProviders}
                handleChange={handleChange}
                show={showProviders}
            />
        </Box >
    );
};

export default SearchFilter;
