import { Box, Card, CardContent, Grid } from "@mui/material";

import { useCallback, useEffect, useState } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import dragHandle from '@uswds/uswds/src/img/usa-icons/drag_handle.svg';

import { getPlatformRelease, reorderPlatformReleaseNews 
       } from "../../../apiClient";
import { setAlert } from "../../../app/slices/alert";
import { setLinks as setBreadcrumbs } from '../../../app/slices/breadcrumbs';
import { setPageTitle } from '../../../app/slices/page';
import BodyText from "../../../components/common/BodyText";
import DraggableItem from "../../../components/common/DraggableItem";
import LinkButton from "../../../components/common/LinkButton";
import MajorHeading from "../../../components/common/MajorHeading";
import MinorHeading from "../../../components/common/MinorHeading";
import { useAbortController } from "../../../hooks";
import { getPlatformReleaseNewsItemPage, PLATFORM_NEWS_PAGE_ACTION 
       } from "../../../utils/platformNews";
import { URL_ADMIN, URL_ADMIN_PLATFORM_NEWS, URL_HOME 
       } from "../../../utils/navigation";

/**
 * This displays the list of news items for a release, providing the ability
 * to rearrange the list and to create/edit/delete individual news itms.
 */
const PlatformReleaseNewsList = () => {
    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [orderUpdated, setOrderUpdated] = useState(false);
    const [releaseId, setReleaseId] = useState(null);
    const [releaseNews, setReleaseNews] = useState([]);
    const [releaseNumber, setReleaseNumber] = useState(null);

    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));
        dispatch(setPageTitle('Platform Release News Administration'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let releaseId = searchParams.get('releaseId')
        if (releaseId !== null) {
            setReleaseId(releaseId);
            initRelease(releaseId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])

    const initRelease = async (releaseId) => {
        try {
            setLoading(true);
            let res = await getPlatformRelease({
                id: releaseId,
                abortSignal: abortSignalRef?.current
            });

            if (res?.data) {
                setReleaseNumber(res.data.release.release);
                if (!!res.data.release_news && !!res.data.release_news.length) {
                    setReleaseNews([...res.data.release_news]);
                }
            }
        } catch(err) {
            if (isCancel(err))
                return;

            console.error('failed to get platform release:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get platform release',
                severity: 'error'
            }));
        } finally {
            setLoading(false);
        }
    }

    // Update the order of the list when the drag ends
    const handleDragEnd = () => {
        if (orderUpdated === true) {
            setLoading(true);
            let orders = releaseNews.map(({ id }, order) => ({ id, order }));
            reorderPlatformReleaseNews({
                body: { orders }, abortsignal: abortSignalRef?.current
            })
            .then(() => {
                dispatch(setAlert({
                    show: true,
                    message: 'Fuccessfully reordered the platform release news',
                    severity: 'success'
                }));
            })
            .catch(err => {
                if (isCancel(err))
                    return;

                console.error('failed to reorder platform release news', 
                    err.message);
                dispatch(setAlert({
                    show: true,
                    message: 'Failed to reorder platform release news',
                    severity: 'error'
                }));
            })
            .finally(() => {
                setLoading(false);
                setOrderUpdated(false);
            })
        }
    };

    // Actual logic to move the item in the list
    const movePlatformReleaseNews = useCallback((dragIndex, hoverIndex) => {
        setOrderUpdated(true);
        setReleaseNews((prevNews) => {
            let rtn = [...prevNews];
            rtn.splice(dragIndex, 1);
            rtn.splice(hoverIndex, 0, prevNews[dragIndex])
            return rtn;
        });
    }, [])

    const renderCard = ({ id, news }, index) => {
        return (
            <DraggableItem
                key={id}
                index={index}
                id={id}
                itemType='PlatformReleaseNewsItem'
                moveItem={movePlatformReleaseNews}
                handleDragEnd={handleDragEnd}
            >
                <Grid container item key={id} sx={{ alignItems: 'center' }}>
                    <Grid item xs={8} >
                        <BodyText 
                            label={news} 
                            labelStyle='margin-top-0'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'end'
                        }}
                    >
                        <LinkButton text='View'
                            href={getPlatformReleaseNewsItemPage([
                                `releaseId=${releaseId}`, `newsId=${id}`, 
                                `action=${PLATFORM_NEWS_PAGE_ACTION.view}`,
                                `first=${(releaseNews.length === 1)}`])}
                            style={{ marginRight: '1rem' }}
                        />
                        <LinkButton text='Edit'
                            href={getPlatformReleaseNewsItemPage([
                                `releaseId=${releaseId}`, `newsId=${id}`, 
                                `action=${PLATFORM_NEWS_PAGE_ACTION.update}`,
                                `first=${(releaseNews.length === 1)}`])}
                            style={{ marginRight: '1rem' }}
                        />
                        <LinkButton text='Delete' isDelete
                            href={getPlatformReleaseNewsItemPage([
                                `releaseId=${releaseId}`, `newsId=${id}`, 
                                `action=${PLATFORM_NEWS_PAGE_ACTION.delete}`,
                                `first=${(releaseNews.length === 1)}`])}
                            disabled={releaseNews?.length === 1}
                        />
                    </Grid>
                </Grid>
            </DraggableItem>
        )
    };

    const heading = !!releaseNumber ?
        `Release ${releaseNumber} News` : 'Release News';

    const noneOrOneNewsItem = !releaseNews.length || (releaseNews.length === 1);

    return (
        <Card id='admin-release-news-card' sx={{ p: '1rem' }} >
            <CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <MajorHeading label={heading} loading={loading} />
                    <div>
                        <LinkButton text='Create a news item'
                            href={getPlatformReleaseNewsItemPage([
                                `releaseId=${releaseId}`,
                                `action=${PLATFORM_NEWS_PAGE_ACTION.create}`])}
                        />
                    </div>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        borderBottomWidth: '2px',
                        borderBottomColor: 'secondary',
                        borderBottomStyle: 'solid',
                        marginBottom: '1rem'
                    }}
                >
                    <img
                        src={dragHandle}
                        alt='drag a news item to reorder it'
                        className='margin-right-1'
                    />
                    <Grid container>
                        <Grid item xs={6}>
                            <MinorHeading label='News' />
                        </Grid>
                        <Grid item xs={6}
                            sx={{
                                display: 'flex',
                                justifyContent: 'end'
                            }}
                        >
                            {
                                noneOrOneNewsItem && 
                                <BodyText
                                    label='One news item is required for a release'
                                    labelStyle='text-align-right text-italic'
                                />
                            }
                        </Grid>
                    </Grid>
                </Box>

                <Box>
                    <DndProvider backend={HTML5Backend}>
                        {
                            !!releaseNews && !!releaseNews.length &&
                            releaseNews.map((item, idx) => renderCard(item, idx))
                        }
                    </DndProvider>
                </Box>

            </CardContent>
        </Card >
    );
}

// Breadcrumbs are only available for the Admin
const breadcrumbs = [
    { name: 'Home', href: URL_HOME },
    { name: 'Admin', href: URL_ADMIN },
    { name: 'Platform News', href: URL_ADMIN_PLATFORM_NEWS },
    { name: 'Platform Release News', href: null }
];

export default PlatformReleaseNewsList;
