import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { selectCompanyRoles } from "../../app/slices/user";


/**
 * This component is a wrapper around the child components
 * and it checks if the company has all of the required roles specified by the required_role_ids parameter.
 * If redirect param = true and company does not have the required_role_ids, redirect to the previous page.
 */
export default function RequireCompanyRole({
    required_role_ids,
    redirect=true,
    children
}) {
    const location = useLocation();
    const navigate = useNavigate();

    const [hasRole, setHasRole] = useState(false);
    const companyRoles = useSelector(selectCompanyRoles);

    // perform side effect whenever the current location changes
    useEffect(() => {
        let hasRole = true;
        // If company does not have the required role, redirect to the previous page.
        let companyRoleSet = new Set(companyRoles || []);
        for (let required_id of required_role_ids) {
            if (!companyRoleSet.has(required_id)) {
                hasRole = false;
                if (redirect) {
                    navigate(-1);
                }
                break;
            }
        }

        setHasRole(hasRole);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return hasRole ? children : null;
};
