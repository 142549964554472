import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { Box, Button, Collapse, Grid, Paper, styled, Table,
         TableBody, TableCell, TableContainer, TableHead,
         TableRow, TableSortLabel } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { tableRowClasses } from '@mui/material/TableRow';
import { tableSortLabelClasses } from '@mui/material/TableSortLabel';

import { useEffect, useState } from 'react';

import CircularProgressIndicator from './CircularProgressIndicator';
import DipIconButton from './DipIconButton';
import Pagination from './Pagination';
import Select from './Select';
import TextField from './TextField';
import { paginate } from '../../utils/utils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
    fontWeight: 'bold'
  }
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  [
    `&.${tableSortLabelClasses.active},
    &.${tableSortLabelClasses.root},
    & .${tableSortLabelClasses.icon}`
  ]: {
    color: `${theme.palette.common.white} !important`
  },
  // To support keyboard navigation
  '&:focus': {
    textDecoration: 'underline'
  },
  // show the arrow icon when tabbed into the label
  [`&.${tableSortLabelClasses.root}:focus > svg`]: {
    opacity: 1
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  [`
    &.${tableRowClasses.root}:hover,
    &.${tableRowClasses.selected}
  `]: {
    backgroundColor: 'rgba(64, 88, 130, 0.3)',
    border: `2.5px solid ${theme.palette.border.main}`
  },
  // To support keyboard navigation
  '&:focus': {
    border: `2.5px solid ${theme.palette.border.main}`
  },
}));

const collator = new Intl.Collator([], { numeric: true });

/**
 * This component displays a data table.
 *
 * @param {string}        id                  unique identifier for the table
 * @param {array(object)} columns             specification of the table's
 *                                            columns with attributes:
 * @param {string}        columns.header      name of the column
 * @param {string}        columns.accessor    name of the property in the "row"
 *                                            which holds the data to display
 * @param {boolean}       columns.enableSort  true to allow sorting of this
 * @param {function}      columns.formatter   function to format table data
 * @param {string}        columns.align       justification, e.g. 'right'
 * @param {array(any)}    rows                table data
 * @param {boolean}       dense               true to shrink the table
 * @param {function}      handleRowClick      function to call if the user
 *                                            clicks on a row
 * @param {boolean}       enableFilter        true to include controls that
 *                                            allows the user to filter
 *                                            table contents
 * @param {string}        defaultOrderBy      name of the column to sort by
 *                                            by default
 * @param {string}        width               CSS specifier for the width
 *                                            of the table; default is 100%
 * @param {string}        minWidth            CSS specifier for the minimum
 *                                            width of the table
 * @param {boolean}       hidePagination      true to show all rows in the
 *                                            table; false to allow pagination
 *                                            to show only N rows at a time
 * @param {number}        defaultRowsPerPage  initial number of rows per page
 *                                            to display, only used when
 *                                            <code>hidePagination</code>
 *                                            is false
 * @param {boolean}       showLoadingIcon     true to show the circular
 *                                            progress icon when the table
 *                                            is loading; default is false
 * @param {string}        rowIdentifier       name of the column to use as
 *                                            the identifier when a row is
 *                                            selected; default is 'id'
 */
export default function DataTable({
    id,
    columns,
    rows,
    dense,
    handleRowClick,
    enableFilter,
    defaultOrderBy,
    width,
    minWidth=650,
    hidePagination,
    defaultRowsPerPage=10,
    showLoadingIcon=false,
    rowIdentifier='id'
}) {

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(defaultOrderBy);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState({ column: columns?.[0]?.accessor, value: null });
    const [filteredRows, setFilteredRows] = useState(rows);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        setLoading(true);
        filterRows()
        .then(filteredRows => {
            setFilteredRows(filteredRows);
            setLoading(false);
            setPage(0);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, rows]);

    const filterRows = async () => {
        let tempRows = rows;
        if (enableFilter && filter?.column && filter?.value) {
            tempRows = rows.filter(row =>
                String(row[filter.column])
                    .toLowerCase()
                    .startsWith(String(filter.value).toLowerCase())
            );
        }
        return tempRows;
    };

    const handleChangeRowsPerPage = (rows) => {
        setRowsPerPage(rows);
        setPage(0);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getComparator = (order, orderBy) => {
        return (a, b) => {
            let rtn = collator.compare(a[orderBy], b[orderBy]);
            return order === 'asc' ? rtn : -rtn;
        }
    };

    // This method is created for cross-browser compatibility, if you don't
    // need to support IE11, you can use Array.prototype.sort() directly
    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => comparator(a[0], b[0]));
        return stabilizedThis.map((el) => el[0]);
    };

    const getRowsForCurrentPage = (array) => {
        let rtn;
        if (hidePagination) {
            rtn = array;
        } else {
            // DataTable's page is 0-based but paginate's page is 1-based
            rtn = paginate(array, rowsPerPage, (page + 1));
        }
        return rtn;
    }

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    const handleFilterByChange = (e) => {
        setFilter({
            ...filter,
            column: e.target.value
        });
    };

    const handleFilterValueChange = (e) => {
        setFilter({
            ...filter,
            value: e.target.value
        });
    };

    const isSelected = (row) => {
        return !!selectedRow && selectedRow[rowIdentifier] === row[rowIdentifier];
    }

    const handleDataRowActionEvent = (row) => {
        if (!showLoadingIcon && !!handleRowClick) {
            setSelectedRow(row);
            handleRowClick && handleRowClick(row);
        }
    }

    const getColumnValueForRow = (row, column) => {
        const columnValueForRow = row[column.accessor || column.header]
        const shouldFormat = !!column.formatter;
        return shouldFormat ? column.formatter(columnValueForRow, row) : columnValueForRow;
    }

    return (
        <Box id='data-table' sx={{ width: width ?? '100%' }}>
            <Paper sx={{ width: '100%' }}>
                {
                    enableFilter && !!columns && (
                        <Box>
                            <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                {
                                    (showLoadingIcon || loading) &&
                                    <CircularProgressIndicator />
                                }
                                <Button
                                    color='primary'
                                    onClick={toggleFilter}
                                    startIcon={showFilter ? <FilterListOffIcon /> : <FilterListIcon />}
                                >
                                    Filter
                                </Button>
                            </Box>
                            <Collapse in={showFilter}>
                                <Grid container spacing={1} sx={{ mt: '5px', mb: '15px' }}>
                                    <Grid item xs={12} sm={3}>
                                        <Select
                                            id='filter-by-label'
                                            name='filter-by'
                                            label='Filter By'
                                            labelStyle='margin-top-0'
                                            value={filter.column}
                                            itemList={columns.map(column => ({
                                                id: column.accessor,
                                                label: column.header,
                                                value: column.accessor,
                                                })
                                            )}
                                            onChange={handleFilterByChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'end',
                                            }}
                                        >
                                            <div style={{ flexGrow: '2', }} >
                                                <TextField
                                                    id='filter-value'
                                                    name='filter-value'
                                                    label='Filter Value'
                                                    labelStyle='margin-top-0'
                                                    value={filter.value || ''}
                                                    onChange={handleFilterValueChange}
                                                />
                                            </div>
                                            <DipIconButton
                                                aria-label='clear filter'
                                                onClick={() => setFilter({ ...filter, value: '' })}
                                                disabled={!filter.value}
                                            >
                                                <ClearIcon />
                                            </DipIconButton>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Box>
                    )
                }

                <TableContainer>
                    <Table id={id} sx={{ minWidth: minWidth }} size={dense ? 'small' : 'medium'}>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: 'primary.main' }}>
                                {
                                    columns && columns.map(column =>
                                        <StyledTableCell
                                            id={`table-cell-column-${column.accessor}`}
                                            key={column.header}
                                            sortDirection={order}
                                            align={column.align ?? 'inherit'}
                                        >
                                            {
                                                column?.enableSort ?
                                                    <StyledTableSortLabel
                                                        active={orderBy === column.accessor}
                                                        direction={order}
                                                        onClick={() => handleRequestSort(column.accessor)}
                                                    >
                                                        {column.header}
                                                    </StyledTableSortLabel>
                                                    : `${column.header}`
                                            }
                                        </StyledTableCell>
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                filteredRows && getRowsForCurrentPage(stableSort(filteredRows, getComparator(order, orderBy)))
                                    .map((row, idx) => (
                                        <StyledTableRow
                                            key={idx}
                                            selected={!!handleRowClick && isSelected(row)}
                                            onClick={() => handleDataRowActionEvent(row)}
                                            onKeyDown={({ key }) => {
                                                // to support keyboard navigation
                                                if (key === 'Enter') {
                                                    handleDataRowActionEvent(row)
                                                }
                                            }}
                                            sx={{
                                                [`&.${tableRowClasses.root}:hover`]: {
                                                    cursor: showLoadingIcon ? 'wait' : !!handleRowClick ? 'pointer' : 'default'
                                                },
                                            }}
                                            tabIndex={0}
                                        >
                                            {
                                                columns.map(column =>
                                                    <TableCell
                                                        key={column.header}
                                                        align={column.align ?? 'inherit'}
                                                    >
                                                        { getColumnValueForRow(row, column) }
                                                    </TableCell>
                                                )
                                            }
                                        </StyledTableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    !hidePagination &&
                    filteredRows &&
                    <Pagination
                        initialRowsPerPage={rowsPerPage}
                        page={page}
                        pageCallback={setPage}
                        rowsPerPageCallback={handleChangeRowsPerPage}
                        numRows={filteredRows ? filteredRows.length : 0}
                    />
                }
            </Paper>
        </Box>
    )
}
