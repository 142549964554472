import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  dataTransferPage: {
    tab: 0
  },
  pageTitle: '',
  
  // RerenderNavar value is used to trigger a rerender of the navigation bar when toggled.
  // This value is toggled when the user signs in or when the user session token is refreshed.
  rerenderNavBar: false
};

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setDataTransferPage: (state, action) => {
      if (action.payload) {
        state.dataTransferPage = action.payload;
      }
    },
    setPageTitle: (state, action) => {
      if (action.payload) {
        state.pageTitle = action.payload;
      }
    },
    setRerenderNavBar: (state, action) => {
      state.rerenderNavBar = action.payload;
    }
  }
});

export const {
  setDataTransferPage,
  setPageTitle,
  setRerenderNavBar
} = pageSlice.actions;

export const selectDataTransferPage = state => state.page.dataTransferPage;
export const selectPageTitle = state => state.page.pageTitle;
export const selectRerenderNavBar = state => state.page.rerenderNavBar;

export default pageSlice.reducer;
