import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isCancel } from "axios";
import { getCategories, getCompanies, getSearchResults,
         getServiceCountPerFilter } from '../../apiClient';
import { ServiceTypeUtils } from '../../utils/utils';
import { getCompanyRolesFromToken } from '../../utils/auth';
import { getServicesWithServiceLogoDataUrl } from '../../utils/serviceDetail';

export const initialState = {
  // what the user has entered in the text field
  searchText: '',

  // store the search result
  searchResult: [],

  // the value of searchText at the time the search is executed
  executedSearchText: '',

  // page number for the search result
  page: 0,

  // whether or not search result is being fetched
  fetchingSearchResult: false,

  // whether or not categories are being fetched
  fetchingCategories: false,

  // whether or not providers are being fetched
  fetchingProviders: false,

  // whether or not service count per filter is being fetched
  fetchingServiceCountPerFilter: false,

  // category list
  categories: [],
  sortCategoryAtoZ: true,

  // providers list
  providers: [],
  sortProvidersAtoZ: true,

  // service type list
  serviceTypes: [
    {
      id: ServiceTypeUtils.TYPES.DATA_TRANSFER,
      checked: false,
    },
    {
      id: ServiceTypeUtils.TYPES.REST,
      checked: false,
    },
    {
      id: ServiceTypeUtils.TYPES.SFTP,
      checked: false,
    },
    {
      id: ServiceTypeUtils.TYPES.STREAMING,
      checked: false,
    },
  ],

  // selected category from the filter (checkbox checked)
  selectedCategories: [],

  // selected service type from the filter (checkbox checked)
  selectedServiceTypes: [],

  // selected providers from the filter (checkbox checked)
  selectedProviders: []
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },

    clearFilters: (state) => {
      // clear the category filter
      state.categories = clearCheckboxFilter(state.categories);
      state.selectedCategories = [];

      // clear the service type filter
      state.serviceTypes = clearCheckboxFilter(state.serviceTypes);
      state.selectedServiceTypes = [];

      // clear the provider filter
      state.providers = clearCheckboxFilter(state.providers);
      state.selectedProviders = [];
    },

    clearSearchText: (state) => {
      state.searchText = '';
      state.executedSearchText = '';
    }

  },
  extraReducers: builder => {
    // extra reducers for fetching search result
    builder
      .addCase(fetchSearchResults.pending, (state, action) => {
        state.fetchingSearchResult = true;
      })
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        state.fetchingSearchResult = false;
        state.searchResult = action.payload;
      })
      .addCase(fetchSearchResults.rejected, (state, action) => {
        state.fetchingSearchResult = false;
      });

    // extra reducers for fetching categories
    builder
      .addCase(fetchCategories.pending, (state, action) => {
        state.fetchingCategories = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.fetchingCategories = false;
        state.categories = updateFilterValues(state.categories, action.payload);
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.fetchingCategories = false;
      });

    // extra reducers for fetching providers
    builder
      .addCase(fetchProviders.pending, (state, action) => {
        state.fetchingProviders = true;
      })
      .addCase(fetchProviders.fulfilled, (state, action) => {
        state.fetchingProviders = false;
        state.providers = updateFilterValues(state.providers, action.payload);
      })
      .addCase(fetchProviders.rejected, (state, action) => {
        state.fetchingProviders = false;
      });

    // extra reducers for fetching service count per filter
    builder
      .addCase(fetchServiceCountPerFilter.pending, (state, action) => {
        state.fetchingServiceCountPerFilter = true;
      })
      .addCase(fetchServiceCountPerFilter.fulfilled, (state, action) => {
        state.fetchingServiceCountPerFilter = false;

        if (action.payload) {
          let { providers, categories, service_types } = action.payload;
          updateServiceCountPerFilter(state.providers, providers);
          updateServiceCountPerFilter(state.categories, categories);
          updateServiceCountPerFilter(state.serviceTypes, service_types);
        }
      })
      .addCase(fetchServiceCountPerFilter.rejected, (state, action) => {
        state.fetchingServiceCountPerFilter = false;
      });
  }
});

const updateServiceCountPerFilter = (filterState, serviceCountPerFilter) => {
  const filterIdToCountMap = {};
  if (serviceCountPerFilter && serviceCountPerFilter.length > 0) {
    for (let { id, count } of serviceCountPerFilter) {
      filterIdToCountMap[id] = count;
    }
  }

  for (let filter of filterState) {
    filter.service_count = filterIdToCountMap[filter.id] ?? 0;
  }
};

const clearCheckboxFilter = (arr) => {
  return arr.map(el => {
    el.checked = false;
    return el;
  });
};

/**
 * Updates filter values based on existing state values.
 *
 * @param {Array} existingStateValues - The existing state values in the store.
 * @param {Array} updates - The array of filter values to be updated.
 * @returns {Array} - An array containing updated filter values. If a filter value in 'updates'
 *                   has a matching ID in 'existingStateValues', it is updated with the corresponding
 *                   value from 'existingStateValues'; otherwise, the original value from 'updates' is used.
 */
const updateFilterValues = (existingStateValues, updates) => {
  let existingIdToValueMap = {}
  for (let v of existingStateValues) {
    existingIdToValueMap[v.id] = v;
  }
  return updates.map(c => existingIdToValueMap[c.id] ?? c);
}

// Sends a post request to the search API and returns a Promise for the
// caller to handle
export const fetchSearchResults = createAsyncThunk(
  'search/fetchSearchResults',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      // cancel the previous request
      if (payload?.abort) payload.abort();

      const searchState = getState().search;

      const requestBody = {
        categories: payload?.categories ?? searchState.selectedCategories.map(dom => dom.id),
        search_text: payload?.searchText ?? searchState.searchText.trim(),
        service_types: payload?.serviceTypes ?? searchState.selectedServiceTypes.map(t => t.id),
        provider_ids: payload?.providerIds ?? searchState.selectedProviders.map(c => c.id),
        page_number: payload?.pageNumber ?? undefined,
        items_per_page: payload?.itemsPerPage ?? undefined,
        role_ids: await getCompanyRolesFromToken(),
      };

      const res = await getSearchResults({ body: requestBody, abortSignal: payload?.abortSignalRef?.current });
      let services = res.status === 204 ? [] : res.data?.services;

      // Generate service_log_data_url for the services to display in the service card.
      services = await getServicesWithServiceLogoDataUrl(services);

      // if getServiceCountPerFilter is true, invoke the fetchServiceCountPerFilter thunk function
      if (payload?.getServiceCountPerFilter && services) {
        dispatch(fetchServiceCountPerFilter({
          body: {
            service_ids: services.map(s => s.id)
          }, abortSignal: payload?.abortSignalRef
        }))
      }

      // Save the executed search term
      dispatch(setField({
        field: 'executedSearchText',
        value: payload?.searchText ?? searchState.searchText.trim(),
      }));

      // if page number is not passed as an argumnet, the search result is
      // for the first page
      if (!payload?.pageNumber) {
        dispatch(setField({
          field: 'page',
          value: 1
        }));
      }

      return { services };
    }
    catch (error) {
      if (isCancel(error))
        return rejectWithValue('cancelled');

      console.error('Failed to fetch search results:', error);
      return rejectWithValue(error.response.data);
    }
  });

// Send a get request to the companies API and returns a Promise for the
// caller to handle
export const fetchProviders = createAsyncThunk(
  'search/fetchProviders',
  async (payload, { getState, rejectWithValue }) => {
    try {
      // get the service providers only
      const res = await getCompanies({ providersOnly: true, abortSignal: payload?.abortSignalRef?.current });
      let providers = res.data.companies ?? [];
      if (providers.length > 0) {
        providers = providers.map(p =>
            ({ id: p.id, name: p.name, abbreviation: p.abbreviation }))
        // sort by name field
        providers.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return getState().search.sortProvidersAtoZ ? -1 : 1;
          }
          else if (nameA > nameB) {
            return getState().search.sortProvidersAtoZ ? 1 : -1;
          }
          else {
            return 0;
          }
        });
      };

      return providers;
    }
    catch (error) {
      if (isCancel(error))
        return rejectWithValue('cancelled');

      console.error('Failed to fetch providers:', error);
      return rejectWithValue(error.response.data);
    }
  });

// Send a get request to the category API and returns a Promise for the
// caller to handle
export const fetchCategories = createAsyncThunk(
  'search/fetchCategories',
  async (payload, { getState, rejectWithValue }) => {
    try {
      const res = await getCategories({ abortSignal: payload?.abortSignalRef?.current });
      if (res.data?.categories) {
        res.data.categories.sort((a, b) => {
          const nameA = a.phrase.toUpperCase();
          const nameB = b.phrase.toUpperCase();

          if (nameA < nameB) {
            return getState().search.sortCategoryAtoZ ? -1 : 1;
          }
          else if (nameA > nameB) {
            return getState().search.sortCategoryAtoZ ? 1 : -1;
          }
          else {
            return 0;
          }
        });
      };
      return res.data?.categories ?? [];
    }
    catch (error) {
      if (isCancel(error))
        return rejectWithValue('cancelled');

      console.error('Failed to fetch categories:', error);
      return rejectWithValue(error.response.data);
    }
  });

// Send a post request to the service count per filter API and returns a
// Promise for the caller to handle
export const fetchServiceCountPerFilter = createAsyncThunk(
  'search/fetchServiceCountPerFilter',
  async (payload, { getState, rejectWithValue }) => {
    try {
      const res = await getServiceCountPerFilter({ body: payload.body, abortSignal: payload?.abortSignalRef?.current });
      return res.data;
    }
    catch (error) {
      if (isCancel(error))
        return rejectWithValue('cancelled');

      console.error('Failed to fetch service count per filter:', error);
      return rejectWithValue(error.response.data);
    }
  });

// Update the search filter and return whether or not the filter is clear
export const updateSearchFilter = createAsyncThunk(
  'search/updateSearchFilter',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      const searchState = { ...getState().search };

      const { type, id, checked } = payload;
      // update the checked value
      const update = searchState[type].map(prop => {
        let newProp = { ...prop }
        if (newProp.id === id) {
          newProp.checked = checked;
        }
        return newProp;
      });

      dispatch(
        setField({
          field: type,
          value: update
        })
      );

      dispatch(
        setField({
          field: `selected${type[0].toUpperCase() + type.slice(1)}`,
          value: update.filter(prop => prop.checked === true)
        })
      );

      let isFilterClear = update.every(e => e.checked === false);

      // check if other filter types are clear as well
      if (isFilterClear) {
        switch (type) {
          case 'categories':
            isFilterClear = searchState['serviceTypes'].every(e => e.checked === false) &&
              searchState['providers'].every(e => e.checked === false);
            break;
          case 'providers':
            isFilterClear = searchState['categories'].every(e => e.checked === false) &&
              searchState['serviceTypes'].every(e => e.checked === false);
            break;
          case 'serviceTypes':
            isFilterClear = searchState['categories'].every(e => e.checked === false) &&
              searchState['providers'].every(e => e.checked === false);
            break;
          default:
            console.error(`Invalid type: ${type}`)
        }
      }

      return isFilterClear;
    }
    catch (error) {
      console.error('Failed to update search filter:', error);
      return rejectWithValue(error);
    }
  });

// Action creators are generated for each case reducer function
export const {
  setField,
  clearFilters,
  clearSearchText
} = searchSlice.actions;

export const selectFetchingSearchResult = state => state.search.fetchingSearchResult;
export const selectSearchText = state => state.search.searchText;
export const selectExecutedSearchText = state => state.search.executedSearchText;
export const selectSearchResult = state => state.search.searchResult;
export const selectCategories = state => state.search.categories;
export const selectProviders = state => state.search.providers;
export const selectServiceTypes = state => state.search.serviceTypes;
export const selectSearchPage = state => state.search.page;
export const selectSelectedCategories = state => state.search.selectedCategories;
export const selectSelectedProviders = state => state.search.selectedProviders;
export const selectSelectedServiceTypes = state => state.search.selectedServiceTypes;

export default searchSlice.reducer
