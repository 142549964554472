import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { useState } from "react";

import ActionButton from "../common/ActionButton";
import BodyText from "../common/BodyText";
import RadioButtonGroup from "../common/RadioButtonGroup";
import TextField from "../common/TextField";
import { FIRST_TIME_USER_TEAM_ID } from "../../utils/requestAccess";


/**
 * This component is for the Team Information step in the request access
 * process.  The Team Information step has two branches.  One branch is when
 * the user's email domain matches the domain of a previously entered team, and
 * so the user will be prompted if they belong to that team.  The other branch
 * This component handles that first case of the user selecting what team they
 * belong to, if any, and if so, to enter the team IVT.
 *
 * @param {object}   input                     team information entered so far
 * @param {string}   teamQuery                 label for prompting the user for
 *                                             which team
 * @param {function} setShowMatchingCompanies  function to call to switch from
 *                                             team selection to team entry
 * @param {array{object} teamsList             list of teams to choose from;
 *                                             each team has these attributes:
 * @param {number}       teamsList.id          - team ID
 * @param {string}       teamsList.name        - team name
 * @param {number}   selectedTeamId            which team the user may have
 *                                             already selected
 * @param {function} setSelectedTeamId         function to call when the user
 *                                             selects a new team
 * @param {boolean}  invalidIvt                true if the user selected a
 *                                             team and was prompted for the
 *                                             team's IVT but the IVT doesn't
 *                                             match
 * @param {function} handleVerifyIvt           function to call to validate
 *                                             the user-entered IVT
 *
 * @returns JSX for selecting a team and entering its IVT
 */
const TeamInformationStepTeamSelection = ({
    teamQuery,
    setShowMatchingCompanies,
    teamsList,
    selectedTeamId,
    setSelectedTeamId,
    invalidIvt,
    handleVerifyIvt,
}) => {

    const [ivt, setIvt] = useState('');

    return (
        <Grid id='team-info-team-selection' container >
            <Grid item xs={12}
                sx={{
                    mb: '2rem',
                    '& .usa-radio': {
                        pl: {
                            xs: 0,
                            sm: '35%'
                        }
                    }
                }}
            >
                <RadioButtonGroup
                    label={teamQuery}
                    labelStyle='text-center'
                    itemList={teamsList.map(({ id, name }) => ({
                        id: `team-info-team-select-${id}`,
                        value: id,
                        label: name,
                        name: 'teamsList',
                        checked: Number(selectedTeamId) === id,
                        labelStyle: 'margin-left-2'
                    }))}
                    onChange={(e) => setSelectedTeamId(Number(e.target.value))}
                />
            </Grid>

            <Grid
                item
                xs={12}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                {
                    selectedTeamId === FIRST_TIME_USER_TEAM_ID ? (
                        <BodyText
                            label='Please click "Next" to create a team'
                            labelStyle='text-center'
                        />
                    ) : (
                        <>
                            <Box
                                sx={{
                                    width: {
                                        xs: '100%',
                                        sm: '70%'
                                    },
                                    textAlign: 'center'
                                }}
                            >
                                <TextField
                                    id='team-info-ivt'
                                    label="Enter your Team's Invitation Verification Token"
                                    labelStyle='text-center'
                                    name='ivt'
                                    value={ivt}
                                    onChange={(e) => { setIvt(e.target.value) }}
                                    style={{marginBottom: '2rem'}}
                                />

                                <ActionButton
                                    text={'Verify Token'}
                                    onClick={() => handleVerifyIvt(selectedTeamId, ivt)}
                                    disabled={!ivt}
                                />

                                { invalidIvt ? (
                                    <>
                                        <BodyText
                                            label='We were unable to verify your affiliation with this team.'
                                            labelStyle='text-center usa-error-message'
                                        />
                                        <BodyText
                                            label='You may either try again or you may click "Next" to create a team.'
                                            labelStyle='text-center margin-top-1'
                                        />
                                    </>
                                  ) : (
                                    <>
                                        <BodyText
                                            label="If you do not know your team's Invitation Verification Token,"
                                            labelStyle='text-center'
                                        />
                                        <BodyText
                                            label="please ask your team's point of contact or"
                                            labelStyle='text-center margin-top-0'
                                        >
                                            &nbsp;<Box
                                                component='span'
                                                onClick={() => setShowMatchingCompanies(false)}
                                                sx={{
                                                    textDecoration: 'underline',
                                                    color: 'secondary.main',
                                                    '&:hover': {
                                                        cursor: 'pointer'
                                                    }
                                                }}
                                            >
                                                create a new team
                                            </Box>
                                            .
                                        </BodyText>
                                    </>
                                  )
                                }
                            </Box>
                        </>
                    )
                }
            </Grid>
        </Grid >
    );
};

export default TeamInformationStepTeamSelection;
