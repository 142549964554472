import classnames from 'classnames';

/**
 * This provides the heading in a modal window.  This was adapted from
 * Trussworks' https://github.com/trussworks/react-uswds/blob/main/src/components/Modal/ModalHeading/ModalHeading.tsx
 *
 * @param {string} id            the unique ID to give the heading
 * @param {string} heading       the heading text to display
 * @param {string} headingStyle  any additional USWDS styles to apply to
 *                               the heading
 * @param {object} headingProps  any additional properties to apply to
 *                               the div wrapping the heading
 */
const ModalHeading = ({
    id,
    heading,
    headingStyle,
    ...headingProps
}) => {

    const classes = classnames(
       'usa-modal__heading',
       headingStyle,
       'text-center',
       'text-primary');

    return (
        <h3 id={id} className={classes} {...headingProps}>
            {heading}
        </h3>
    );
}

export default ModalHeading;
