import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import yaml from 'js-yaml';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

import {
    CATALOG_SERVICE_MOCK_SERVER_URL,
    createApiMockServerExpectation,
    getCompanyDetail,
    getObjectFromS3,
    getSubscriptionDetail,
} from '../apiClient';
import { setAlert } from '../app/slices/alert';
import { setPageTitle } from '../app/slices/page';
import { useAbortController } from '../hooks';
import {
    getAuthorizationHeaders,
    getCatalogServiceAuthorizationHeaders,
} from '../utils/auth';
import CircularProgressIndicator from '../components/common/CircularProgressIndicator';
import './OpenApiSpec.css';

/**
 * This provides the display of the API of a service.  This page is displayed
 * when viewing the service detail page for a REST service then clicking the
 * API button.
 */

function OpenApiSpec() {
    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();

    const openApiLocation = searchParams.get('openApiLocation');
    const serviceId = searchParams.get('serviceId');
    const serviceName = searchParams.get('name');
    const companyId = searchParams.get('companyId');
    const serviceUrl = searchParams.get('serviceUrl');
    const versionId = searchParams.get('versionId');

    const [spec, setSpec] = useState(null);
    const [company, setCompany] = useState(null);
    const [serverToTryOut, setServerToTryOut] = useState('Mock'); // Hit the mock server by default
    const [loading, setIsLoading] = useState(true);

    useEffect(() => {
        initPageData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initPageData = () => {

        dispatch(setPageTitle('OpenAPI: ' + serviceName));

        // Array of promises to wait to initialize the necessary page data.
        const initPromises = [];

        // get API spec for the service
        initPromises.push(
            new Promise((resolve, reject) => {
                getObjectFromS3({
                    key: openApiLocation,
                    abortSignal: abortSignalRef?.current,
                })
                .then((res) => {
                    if (res && res['data']) {
                        let spec = yaml.load(res['data']);
                        if (spec) {
                            if (serviceUrl) {
                                // if the server url is not set in the spec, 
                                // the "Try It Out" button in the prod build 
                                // won't execute.
                                spec.servers = [
                                    {
                                        url: serviceUrl,
                                    },
                                ];
                            } else {
                                dispatch(
                                    setAlert({
                                        show: true,
                                        message: 'Invalid service URL',
                                        severity: 'error',
                                    })
                                );
                            }

                            setSpec(spec);
                        }
                    } else {
                        dispatch(
                            setAlert({
                                show: true,
                                message: 'Invalid OpenAPI Specification',
                                severity: 'error',
                            })
                        );
                    }

                    resolve();
                })
                .catch((err) => {
                    err.message = 'Failed to get the OpenAPI spec file';
                    reject(err);
                });
            })
        );

        // get user company details
        initPromises.push(
            new Promise((resolve, reject) => {
                getCompanyDetail({
                    id: companyId,
                    abortSignal: abortSignalRef?.current,
                })
                    .then((res) => {
                        if (res['data']) {
                            setCompany(res['data']);
                        }
                        resolve();
                    })
                    .catch((err) => {
                        err.message = 'Failed to get company details';
                        reject(err);
                    });
            })
        );

        // get subscription detail for the company
        initPromises.push(
            new Promise((resolve, reject) => {
                getSubscriptionDetail({
                    company_id: companyId,
                    service_id: serviceId,
                    version_id: versionId,
                    abortSignal: abortSignalRef?.current,
                })
                    .then((res) => {
                        let subscribed = !!res.data && Object.keys(res.data).length > 0;
                        setServerToTryOut(subscribed ? 'Actual' : 'Mock');
                        resolve();
                    })
                    .catch((err) => {
                        setServerToTryOut('Mock');
                        err.message = 'Failed to get subscription detail';
                        reject(err);
                    });
            })
        );

        Promise.all(initPromises)
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                if (isCancel(error)) return;

                console.error(error);
                dispatch(
                    setAlert({
                        show: true,
                        message: error.message,
                        severity: 'error',
                    })
                );
            });
    };

    // Plugin to wrap the swagger UI components
    const swaggerUIcomponentWrapperPlugin = function (system) {
        return {
            // refer to the following github page for a list of available components:
            // https://github.com/swagger-api/swagger-ui/tree/master/src/core/components
            wrapComponents: {
                // response body of the live response component
                responseBody: (Original, system) => (props) => {
                    // getConfigs() function is used by response-body component to
                    // determine whether or not to activate syntax highlighting.
                    // However, "system.getConfigs().syntaxHighlight = false" affects
                    // all componetns globally.    Therefore, to deactivate the syntax
                    // highlighting for this specific responseBody component, overwrite
                    // the getConfigs() method that returns syntaxHightlihght=false.
                    props = {
                        ...props,
                        getConfigs: () => ({
                            ...system.getConfigs(),
                            syntaxHighlight: false,
                        }),
                    };

                    return <Original {...props} />;
                },
            },
        };
    };

    const urlMutatorPlugin = (system) => ({
        rootInjects: {
            setServer: (server) => {
                const jsonSpec = system.getState().toJSON().spec.json;
                const servers = [{ url: server }];
                const newJsonSpec = Object.assign({}, jsonSpec, { servers });

                return system.specActions.updateJsonSpec(newJsonSpec);
            },
        },
    });

    /**
     * Intercept the request object to set the url and auth headers
     * for the request before sending it
     * @param {*} req
     * @returns
     */
    const requestInterceptor = async (req) => {
        // If trying the mock server
        if (serverToTryOut === 'Mock') {
            console.log('Mock It Now');

            // get authorization heaers for mock api
            let authHeaders = await getCatalogServiceAuthorizationHeaders();
            Object.assign(req.headers, authHeaders);

            // create the expectation and then send the request to the mock server
            try {
                let res = await createApiMockServerExpectation({
                    apiSpec: spec,
                    abortSignal: abortSignalRef?.current,
                });
                if (res.status === 201) {
                    // Replace the base url of the req.url with the mock server url.
                    const requestUrl = new URL(req.url);
                    req.url = `${CATALOG_SERVICE_MOCK_SERVER_URL}${requestUrl.pathname}`;
                } else {
                    dispatch(
                        setAlert({
                            show: true,
                            message: 'Failed to create the response in the mock server',
                            severity: 'warning',
                        })
                    );
                    // do not send request to the server
                    req.url = '';
                }
            } catch (err) {
                if (isCancel(err)) return;

                console.error('failed to connect with the mock server:', err.message);
                dispatch(
                    setAlert({
                        show: true,
                        message: 'Failed to connect with the mock server',
                        severity: 'error',
                    })
                );

                // do not send request to the server
                req.url = '';
            }
        } else {
            // If trying the actual server
            // Get authorization headers
            if (company) {
                let authHeaders = await getAuthorizationHeaders(
                    process.env.REACT_APP_COGNITO_TOKEN_ENDPOINT,
                    company.client_id,
                    company.client_secret,
                    company.api_key
                );
                Object.assign(req.headers, authHeaders);
            } else {
                console.error('Unable to set auth headers: Invalid company');
            }
        }
        return req;
    };

    return (
        <div id="openapi-spec-dom">
            {!loading && !!spec ? (
                <Card sx={{ px: '1rem' }}>
                    <SwaggerUI
                        spec={spec}
                        presets={[SwaggerUI.presets.apis]}
                        plugins={[
                            SwaggerUI.plugins.DownloadUrl,
                            urlMutatorPlugin,
                            swaggerUIcomponentWrapperPlugin,
                        ]}
                        requestInterceptor={requestInterceptor}
                    />
                </Card>
            ) : (
                <Box
                    sx={{
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgressIndicator 
                        type='large'
                        altText='Loading the spec...'
                    />
                </Box>
            )}
        </div>
    );
}

export default OpenApiSpec;
