import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Link } from "react-router-dom";

import { getServiceDetailPageUrl } from "../../utils/navigation";
import { SERVICE_SUMMARY_MAX_LENGTH } from "../../utils/serviceRegistration";
import { truncateString, ServiceTypeUtils } from "../../utils/utils";
import ServiceCardLogoProviderServiceName from './ServiceCardLogoProviderServiceName';
import NewVersionIndicator from "../subscription/NewVersionIndicator";


/**
 * This provides the large service card with logo, provider name, service name,
 * summary description, service type, and subscription status.
 *
 * @param {object}  service                the service information
 * @param {boolean} fetchingSubscriptions  true if the parent is still
 *                                         retrieving the services this user
 *                                         is subscribed to
 * @param {boolean} isSubscribed           true if this user is subscribed to
 *                                         'service'
 * @param {boolean} isSubscribedToLatest   true if this user is subscribed to
 *                                         the latset version of the 'service'
 * @param {boolean} serviceLogoDataUrl     Base64 encoded data URL for the service logo to display
 * @param {boolean} disableLink            (optional) true to disable the
 *                                         service card from linking to the
 *                                         service detail page; default=false
 */

function LargeServiceCard({ 
    service,
    fetchingSubscriptions,
    isSubscribed,
    isSubscribedToLatest,
    serviceLogoDataUrl,
    disableLink = false,
}) {

    // Note: SmallServiceCard and LargeServiceCard need to have the same width,
    // margins, and borders.  Both cards also need the logo, provider, and
    // service name formatted the same.

    // This truncation of the summary can go away after the 1.4.1 release since
    // by then all services should be either updated to add the summary or
    // deleted (i.e. test services)
    const summary = service.summary ??
              truncateString(service.description, SERVICE_SUMMARY_MAX_LENGTH);

    return (
        <Box
            id={`large-service-card-${service.id}`}
            key={`large-service-card-${service.id}`}
            component={disableLink ? 'div' : Link}
            tabIndex={disableLink? -1 : 0}
            to={disableLink ? null :
                    getServiceDetailPageUrl(service.id, service.provider_id)}
            sx={{
                display: 'inline-flex',
                border: '1px solid',
                borderColor: 'text.grey',
                borderRadius: 1,
                width: '13rem',
                minHeight: '15rem',
                maxHeight: '15.5rem',
                marginRight: '8px',
                marginBottom: '8px',
                textDecoration: 'none',
                '&:hover': {
                    borderColor: disableLink ? '' : 'primary.main',
                    border: disableLink ? '' : 3
                }
            }}
        >
            <Grid container>

                { /* Logo, provider name, and service name */ }
                <Grid item xs={12}>
                    <ServiceCardLogoProviderServiceName
                        service={service}
                        serviceLogoDataUrl={serviceLogoDataUrl}
                    />
                </Grid>

                { /* Service summary */ }
                <Grid item xs={12}>
                    <Typography
                        id={`service-summary-${service.id}`}
                        component='div'
                        variant='body'
                        color='primary.main'
                        sx={{
                            display: 'inline-block',
                            boxSizing: 'border-box',
                            lineHeight: '1.1rem',
                            minHeight: '7.2rem',
                            maxHeight: '7.2rem',
                            width: '100%',
                            fontSize: '0.875rem',
                            overflow: 'visible',
                            padding: '6px',
                            backgroundColor: 'background.main',
                        }}
                    >
                        { summary }
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    { /* Service type */ }
                    <Box
                        id={`service-type-${service.id}`}
                        component={Typography }
                        variant='body'
                        color='primary.main'
                        sx={{
                            padding: '6px',
                            fontSize: '0.875rem',
                        }}
                    >
                        {ServiceTypeUtils.TYPE_TO_STRING[service.service_type]}
                    </Box>
                    
                    { /* Subscription status */ }
                    <Box
                        id={`service-subscription-${service.id}`}
                        component={Typography }
                        variant='body'
                        color='subscribed.main'
                        sx={{
                            padding: '6px',
                            fontSize: '0.875rem',
                            fontWeight: 'bold',
                        }}
                    >
                    {fetchingSubscriptions
                        ? "?"
                        : SubscriptionStatus(
                            isSubscribed,
                            isSubscribedToLatest
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const SubscriptionStatus = (isSubscribed, isSubscribedToLatest) => {
    let rtn = "";

    if (isSubscribed) {
        rtn = "Subscribed"
        if (!isSubscribedToLatest) {
            rtn = <NewVersionIndicator>{rtn}</NewVersionIndicator>;
        }
    }
    
    return rtn;
}

export default LargeServiceCard;
