import classnames from 'classnames';

/**
 * This provides the formatting for the label of a user entry field.
 *
 * @param {string}  label        the field label to display
 * @param {string}  id           the unique identifier for this field
 * @param {string}  entryId      the unique identifier for the data entry field
 *                               that this is the label for; this is used to
 *                               set up the aria attributes
 * @param {string}  helpId       the unique identifier an auxiliary help
 *                               label, if any; this is used to set up
 *                               aria attributes
 * @param {string}  labelStyle   any additional USWDS styles which should be
 *                               applied to the field
 * @param {boolean} required     true if this field is required
 */
const FieldLabel = ({
    label,
    id,
    entryId,
    helpId,
    labelStyle,
    required,
}) => {

    const classes = classnames('usa-label', labelStyle);

    const displayLabel = required === true ? label + ' *' : label;

    return (
        <label
            id={id}
            htmlFor={entryId}
            aria-describedby={helpId}
            className={classes}
        >
            {displayLabel}
        </label>
    );
}

export default FieldLabel;
