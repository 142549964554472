import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getVirusScanStautsForFile } from '../../apiClient';
import { setAlert } from '../../app/slices/alert';
import { useAbortController } from '../../hooks';
import {
    DESCRIPTION_MAX_LENGTH, PUBLIC_ROLE_ID, SCOPE_MAP,
    REQUIRED_SERVICE_FILE_TYPE, SERVICE_NAME_MAX_LENGTH,
    SERVICE_SUMMARY_MAX_LENGTH, VIRUS_SCAN_STATUS
} from '../../utils/serviceRegistration';
import { ServiceTypeUtils, checkServiceType } from '../../utils/utils';
import AlertInline from '../common/AlertInline';
import BodyText from '../common/BodyText';
import Checkbox from '../common/Checkbox';
import MinorHeading from '../common/MinorHeading';
import OkCancelDialog from '../common/OkCancelDialog';
import RadioButtonGroup from '../common/RadioButtonGroup';
import TextArea from '../common/TextArea';
import TextField from '../common/TextField';
import TransferList from '../common/TransferList';
import LargeServiceCard from '../service-card/LargeServiceCard';
import ContactInformation from './ContactInformation';
import VirusScanWrapperForResourceFile from './VirusScanWrapperForResourceFile';
import AdditionalFiles from './AdditionalFiles';

/**
 * This provides a user interface for a service provider to enter the
 * fundamental pieces of information when registering a new service or
 * editing a service registration:
 * - service name
 * - service summary aka short description
 * - service description
 * - service logo
 * - who is allowed to use it
 * - company roles associated with it
 * - service domains
 */

const WizardServiceCore = ({
    input,
    serviceId,
    categories,
    companyRoles,
    handleInputChange,
    handleInputChangeByNameAndValue,
    handleInputChangeWithLimit,
    handleInputChangeCompanyRole,
    readOnly,
    serviceType,
    formErrors,
    setFormErrors,
    ...props
}) => {

    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();

    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const confirmationRef = useRef(null);
    const [loadingServiceFiles, setLoadingServiceFiles] = useState(false);

    const [totalNumberOfFiles, setTotalNumberOfFiles] = useState(0);
    const [totalFileSizeBytes, setTotalFileSizeBytes] = useState(0);

    const allowDataDictionaryFileUpload = useMemo(() => {
        return (
            checkServiceType(serviceType, ServiceTypeUtils.TYPES.SFTP) ||
            checkServiceType(serviceType, ServiceTypeUtils.TYPES.STREAMING)
        );
    }, [serviceType]);

    useEffect(() => {
        initServiceFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);

    useEffect(() => {
        setTotalNumberOfFiles(input.additional_file_count ?? 0);
        setTotalFileSizeBytes(input.additional_file_size_bytes ?? 0);
    }, [input.additional_file_count, input.additional_file_size_bytes]);

    const initServiceFiles = () => {
        setLoadingServiceFiles(true);

        const serviceFilePromises = [];

        Object.entries(input.service_files).forEach(([fileType, file]) => {
            // If the virus scan status for the file is not "clean",
            // send a status check request to the virus scan status API and
            // then update the scan status if the status changed.
            if (file?.virusScanStatus &&
                file.virusScanStatus !== VIRUS_SCAN_STATUS.clean) {

                // object path to the service logo file without bucket name
                let objectPath = file.filePath.split('/').slice(1).join('/');

                serviceFilePromises.push(new Promise(async (resolve, reject) => {
                    try {
                        let res = await getVirusScanStautsForFile({
                            objectPath: objectPath,
                            abortSignal: abortSignalRef?.current
                        });
                        let newVirusScanStatus = res.data.status.toLowerCase();
                        if (file.virusScanStatus !== newVirusScanStatus) {
                            file.virusScanStatus = newVirusScanStatus;
                        }
                        resolve(true);
                    } catch (error) {
                        error.message = `Failed to get virus scan status for ${file.name}`;
                        reject(error);
                    }
                }))
            }
        })

        Promise.all(serviceFilePromises)
        .then(() => {
            setLoadingServiceFiles(false);
        })
        .catch((error) => {
            if (isCancel(error)) {
                return;
            }
            console.error(error);
            dispatch(
                setAlert({
                    show: true,
                    message: error.message,
                    severity: "error",
                })
            );
        });
    }

    /**
     * Creates the text box label for the fields that need to limit the
     * number of characters; this label will show the number of characters
     * used and the maximum length.
     */
    const getLabel = (fieldName, title, limit) => {
        const value = input[fieldName] ?? '';
        return `${title} (${value.length} / ${limit} characters)`;
    };

    // Put a red box around the company roles when there's an error
    const companyRolesBorder = formErrors?.companyRoles ?
        'border-05 border-emergency padding-05' : '';


    const handleScopeChange = (scope) => {
        if (scope === SCOPE_MAP.cdmdata) {
            // if CDM scope is selected, uncheck the public role
            handleInputChangeCompanyRole(PUBLIC_ROLE_ID, false);
        }
        handleInputChangeByNameAndValue('scope', scope);
    }

    const handleResourceFileChange = (fileName, file) => {
        let copyFiles = { ...input.service_files };
        copyFiles[fileName] = file;
        handleInputChangeByNameAndValue('service_files', copyFiles);
    };

    const setAdtnlFilesFormErrors = (update) => {
        setFormErrors({
            ...formErrors,
            additionalFiles: update,
        });
    }
    
    return (
        <>
            <Stack spacing={3} >

                {/* Holds the service name, summary, and logo on the left,
                  * and sample service card on the right */}
                <Stack direction='row' spacing={1} >

                    { /* Left side:  Service name, summary, and logo */}
                    <Stack spacing={3}
                        sx={{
                            display: 'flex',
                            flex: '2',
                        }}
                    >
                        {/* Mui has a rule
                          *      .css-MuiStack-root >
                          *        :not(style + :not(style) {
                          *            margin-top: 24px;
                          *        }
                          * which adds excessive gaps.  This 'div' blocks that.
                          */}
                        <div>
                            <TextField
                                id='service-name'
                                name='name'
                                label={getLabel('name', 'Service Name',
                                    SERVICE_NAME_MAX_LENGTH)
                                }
                                labelStyle='margin-top-0'
                                value={input.name}
                                required
                                disabled={readOnly}
                                onChange={(e) =>
                                    handleInputChangeWithLimit('name',
                                        e.target.value, SERVICE_NAME_MAX_LENGTH)
                                }
                                errorMessage={formErrors?.serviceName}
                            />

                            <TextArea
                                id='service-summary'
                                name='summary'
                                label={getLabel('summary',
                                    'Short Description for Service Card',
                                    SERVICE_SUMMARY_MAX_LENGTH)
                                }
                                inputStyle='height-8'
                                value={input.summary}
                                required
                                disabled={readOnly}
                                onChange={(e) =>
                                    handleInputChangeWithLimit('summary',
                                        e.target.value, SERVICE_SUMMARY_MAX_LENGTH)
                                }
                                errorMessage={formErrors?.serviceSummary}
                            />

                            <Box sx={{ my: '1.5rem' }} />

                            <VirusScanWrapperForResourceFile
                                loading={loadingServiceFiles}
                                id='resource-file_service-logo'
                                label='Service Logo'
                                fileInput={input.service_files[REQUIRED_SERVICE_FILE_TYPE.service_logo]}
                                handleInputChange={(value) => handleResourceFileChange(REQUIRED_SERVICE_FILE_TYPE.service_logo, value)}
                                targetFolderName={process.env.REACT_APP_CATALOG_SERVICE_S3_SERVICE_LOGO_FOLDER_NAME}
                                acceptedFileTypes='.png,.jpg,.jpeg,.gif,.svg'
                                readOnly={readOnly}
                            />
                        </div>
                    </Stack>

                    { /* Right side: sample service card */}
                    <LargeServiceCard
                        service={input}
                        serviceLogoDataUrl={input.service_files?.service_logo?.fileData}
                        fetchingSubscriptions={false}
                        isSubscribed={true}
                        disableLink={true}
                    />
                </Stack>

                {/* Same kludgey div as above */}
                <div>
                    <TextArea
                        id='service-description'
                        name='description'
                        label={getLabel('description', 'Description',
                            DESCRIPTION_MAX_LENGTH)
                        }
                        value={input.description}
                        required
                        disabled={readOnly}
                        onChange={(e) =>
                            handleInputChangeWithLimit('description',
                                e.target.value, DESCRIPTION_MAX_LENGTH)
                        }
                        errorMessage={formErrors?.serviceDescription}
                    />

                    <div className="margin-y-2" />

                    { /* Who is allowed to access the service */}
                    <RadioButtonGroup
                        label='Does your service contain restricted data elements?'
                        itemList={getAccessRadioButtons(input)}
                        onChange={(e) => readOnly ? null : handleScopeChange(e.target.value)}
                        disabled={readOnly}
                    />

                    { /* Company Roles */}
                    <div className={companyRolesBorder}>
                        <MinorHeading label='Access Roles' labelStyle='margin-top-4 margin-bottom-0' />
                        <legend className='usa-label margin-top-1'>
                            Select who can access the service *
                        </legend>
                        {
                            formErrors?.companyRoles &&
                            <AlertInline errorMessage={formErrors?.companyRoles} />
                        }

                        <div className="grid-container margin-top-1 margin-left-3 margin-right-0 padding-x-0">
                            <div className="grid-row">
                                <BodyText labelStyle="tablet:grid-col-4 padding-left-3 margin-bottom-1 margin-top-0 text-underline"
                                    label='Name'
                                />
                                <BodyText labelStyle="tablet:grid-col-8 margin-bottom-1 margin-top-0 text-underline"
                                    label='Definition'
                                />
                            </div>
                        </div>
                        {
                            companyRoles &&
                            companyRoles.map(({ id, role, definition }) => {
                                const tag = `role-${id}`;
                                return (
                                    <div className="grid-row grid-gap-sm" key={id}>
                                        <div className="tablet:grid-col-4">
                                            <Checkbox
                                                id={id}
                                                name={tag}
                                                label={role}
                                                labelStyle='margin-left-2 margin-top-0 margin-bottom-1 flex-align-start'
                                                checked={!!input.roles?.[id]}
                                                // disable Public checkbox if CDM scope is selected
                                                disabled={
                                                    readOnly ||
                                                    (Number(id) === PUBLIC_ROLE_ID && input.scope === 'cdm')
                                                }
                                                onChange={(e) =>
                                                    Number(id) === PUBLIC_ROLE_ID && e.target.checked === true ?
                                                        setOpenConfirmationDialog(true) :
                                                        handleInputChangeCompanyRole(id, e.target.checked)}
                                                data-open-modal={Number(id) === PUBLIC_ROLE_ID}
                                                aria-controls={
                                                    Number(id) === PUBLIC_ROLE_ID ?
                                                    confirmationRef?.current?.modalId : null}
                                            />
                                        </div>
                                        <BodyText
                                            label={definition}
                                            labelStyle="tablet:grid-col-8 margin-top-1 margin-top-0 margin-bottom-1 flex-align-start"
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                { /* Domain selection */}
                <TransferList
                    leftList={categories}
                    rightList={input.categories}
                    itemNameKey='phrase'
                    transferListName='categories'
                    boxTitle='Domains'
                    errorText={formErrors?.serviceCategories}
                    handleTransfer={handleInputChangeByNameAndValue}
                    readOnly={readOnly}
                />

                <ContactInformation
                    id='poc-block'
                    title='Point of Contact'
                    header='Enter Contact Information'
                    helpText={
                        'Provide a point of contact to direct additional ' +
                        'questions for a potential consumer. This can be an ' +
                        'email address, a phone number, or a URL to request ' +
                        'further information.  Multiple contacts can be specified.'
                    }
                    typeHelpText={
                        'Contact type, e.g. Sales, Engineering, or Legal'
                    }
                    descHelpText={
                        'May be used to describe area of expertise, ' +
                        'hours of availability, etc.'
                    }
                    input={input}
                    inputAttributeName='point_of_contacts'
                    readOnly={readOnly}
                    handleInputChangeByNameAndValue={handleInputChangeByNameAndValue}
                />

                <ContactInformation
                    id='ts-block'
                    title='Technical Support'
                    header='Enter Support Information'
                    helpText={
                        'Provide types of support available, such as email, help ' +
                        'line, etc.  Multiple options can be specified.'
                    }
                    typeHelpText={
                        'Support type, e.g. Engineering, User Forum, or ' +
                        'Knowledge Base'
                    }
                    descHelpText={
                        'May be used to provide an overview of support ' +
                        'offered, hours of availability, number of ' +
                        'support calls per period of time, etc.'
                    }
                    input={input}
                    inputAttributeName='technical_support_contacts'
                    readOnly={readOnly}
                    handleInputChangeByNameAndValue={handleInputChangeByNameAndValue}
                />

                {
                    allowDataDictionaryFileUpload && (
                        <VirusScanWrapperForResourceFile
                            loading={loadingServiceFiles}
                            id='resource-file_data-dictionary'
                            title='Data Dictionary'
                            label='Provide an optional data dictionary for the service.'
                            fileInput={input.service_files[REQUIRED_SERVICE_FILE_TYPE.data_dictionary]}
                            handleInputChange={(value) => handleResourceFileChange(REQUIRED_SERVICE_FILE_TYPE.data_dictionary, value)}
                            targetFolderName={process.env.REACT_APP_CATALOG_SERVICE_S3_RESOURCE_FOLDER_NAME + '/data dictionary'}
                            acceptedFileTypes='.xlsx,.txt,.text,.json'
                            readOnly={readOnly}
                        />
                    )
                }

                <AdditionalFiles
                    additionalFiles={input.additional_files}
                    totalFileSizeBytes={totalFileSizeBytes}
                    totalNumberOfFiles={totalNumberOfFiles}
                    handleUpdate={({ action, payload, fileSize }) => {
                        fileSize = fileSize ?? 0;

                        if (action === "add") {
                            setTotalNumberOfFiles((n) => n + 1);
                        } else if (action === "delete") {
                            setTotalNumberOfFiles((n) => n - 1);
                            setTotalFileSizeBytes((s) => s - fileSize);
                        } else if (action === "update") {
                            setTotalFileSizeBytes((s) => s + fileSize);
                        }
                        handleInputChangeByNameAndValue(
                            "additional_files",
                            payload
                        );
                    }}
                    formErrors={formErrors?.additionalFiles}
                    setFormErrors={setAdtnlFilesFormErrors}
                    readOnly={readOnly}
                />
            </Stack>

            {/* Dialog to confirm public access. */}
            <OkCancelDialog
                id='confirm-public-access-dialog'
                open={openConfirmationDialog}
                heading='Confirm Public Access'
                handleOkOp={() => {
                    handleInputChangeCompanyRole(PUBLIC_ROLE_ID, true);
                    setOpenConfirmationDialog(false);
                }}
                handleCancelOp={() => setOpenConfirmationDialog(false)}
                dialogRef={confirmationRef}
            >
                {/* We need to create the dialog for the sake of the 'ref'
                    but don't create the content if the dialog is closed */}
                {
                    openConfirmationDialog &&
                    <>
                        <BodyText labelStyle='text-center'>
                            You selected <strong>Public - Available to All</strong>.
                        </BodyText>
                        <BodyText labelStyle='text-center margin-top-0'>
                            The service will be accessible to all users.
                        </BodyText>
                        <BodyText labelStyle='text-center'>
                            Are you sure?
                        </BodyText>
                    </>
                }
            </OkCancelDialog>
        </>
    );
};

/**
 * Gets the definition for the "who has access to your service" radio buttons,
 * extracted here just to keep the code above cleaner.
 *
 * @param {object}  current service definition, used to pick which radio button
 *                  to select
 */
const getAccessRadioButtons = (input) => [
    {
        id: 'access-everyone',
        name: 'access',
        value: 'everyone',
        label: 'Unrestricted',
        checked: !input || input.scope === 'everyone',
        labelStyle: 'margin-left-2'
    },
    {
        id: 'access-cdm',
        name: 'access',
        value: 'cdm',
        label: 'Collaborative Decision Making (CDM) restricted',
        checked: input?.scope === 'cdm',
        labelStyle: 'margin-left-2'
    },
];

export default WizardServiceCore;
