import classnames from 'classnames';
import CircularProgressIndicator from './CircularProgressIndicator';

/**
 * This creates a minor heading.
 *
 * @param {string}   label        the label displayed to the user
 * @param {string}   labelStyle   additional USWDS style classes to add to the
 *                                label, e.g. 'margin-top-0' to remove a margin
 */

const MinorHeading = ({ label, labelStyle, loading, ...props }) => {

    const classes = classnames('text-primary', labelStyle);

    return (
        <h3 className={classes} {...props} >
            {label}
            {
                loading &&
                <CircularProgressIndicator sx={{ ml: '1rem'}} />
            }
        </h3>
    );
};

export default MinorHeading;
