import classnames from 'classnames';

import FieldLabel from './FieldLabel';
import FieldHelp from './FieldHelp';


/**
 * This creates a text input field.  It is based on the USWDS text field which
 * satisfies Section 508 accessibility requirements.
 *
 * @param {string}   id           the unique id for the text field
 * @param {string}   name         unique name for form submission
 * @param {string}   label        the label displayed to the user
 * @param {string}   labelStyle   additional USWDS style classes to add to the
 *                                label, e.g. 'margin-top-0' to remove a margin
 * @param {string}   help         any additional help text to display
 * @param {string}   helpStyle    additional USWDS style classes to add to the
 *                                help text
 * @param {string}   value        contents to display in the text field
 * @param {boolean}  required     true if value must be entered (only affects
 *                                the label display)
 * @param {boolean}  disabled     true if the checkbox should be disabled
 * @param {string}   type         type of input field; default is 'text'
 * @param {function} onChange     callback when the value is changed
 * @param {string}   errorMessage any error message to display
 * @param {object}   props        any remaining props will be applied to the
 *                                input field
 */

const TextField = ({
    id,
    name,
    label,
    labelStyle,
    help,
    helpStyle,
    value,
    required=false,
    disabled=false,
    type='text',
    onChange,
    errorMessage='',
    ...props
}) => {

    const inputClasses = classnames('usa-input',
        {
            'usa-input--error' : !!errorMessage,
        }
    );

    const helpId = !!help ? `${id}-help` : null;

    return (
        <>
            <FieldLabel
                id={`${id}-label`}
                entryId={id}
                helpId={helpId}
                label={label}
                labelStyle={labelStyle}
                required={required}
            />

            {
                !!errorMessage &&
                <span className='usa-error-message' role='alert' >
                    {errorMessage}
                </span>
            }

            <input
                className={inputClasses}
                id={id}
                name={name}
                type={type}
                value={value}
                onChange={onChange}
                required={required}
                disabled={disabled}
                {...props}
            />

            {
                !!help &&
                <FieldHelp
                    id={helpId}
                    help={help}
                    helpStyle={helpStyle}
                />
            }
        </>
    );
};

export default TextField;
