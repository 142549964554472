import { Amplify, Analytics } from 'aws-amplify';
import { getCurrentAuthenticatedUser, isUserAuthenticated } from './auth';

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const localRedirectSignIn = 'http://localhost:3000';
const productionRedirectSignIn = window.location.origin;

const config = {
  Auth: {
    // Amazon Cognito Region
    region: process.env.REACT_APP_CATALOG_SERVICE_AUTH_AWS_COGNITO_REGION,
  
    // Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_CATALOG_SERVICE_AUTH_AWS_COGNITO_USER_POOL_ID,
  
    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_CATALOG_SERVICE_AUTH_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  
    identityPoolId: process.env.REACT_APP_CATALOG_SERVICE_AUTH_AWS_COGNITO_IDENTITY_POOL_ID,
  
    // Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
  
    // Note: Cookie storage can be used instead of the localStorage for user credentials
    // cookieStorage: {},
  
    // Hosted UI configuration
    oauth: {
      // sign in domain
      domain: process.env.REACT_APP_CATALOG_SERVICE_AUTH_AWS_COGNITO_APP_DOMAIN,
      // OAuth Scopes
      scope: process.env.REACT_APP_CATALOG_SERVICE_AUTH_AWS_COGNITO_APP_SCOPES.split(',').map(v => v.trim()),
      // indicates where the user will be redirected after a successful sign-in.
      redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
      // indicates where your user will be redirected after signing out.
      redirectSignOut: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  },

  Analytics: {
    // disable Analytics if true
    disabled: false,
  
    // Allow recording session events. Default is true.
    autoSessionRecord: true,
  
    AWSPinpoint: {
      // Amazon Pinpoint App Client ID
      appId: process.env.REACT_APP_CATALOG_SERVICE_AWS_PINPOINT_APP_ID,
  
      // Amazon service region
      region: process.env.REACT_APP_CATALOG_SERVICE_AWS_PINPOINT_REGION,
  
      endpoint: {
        // User attributes
        userAttributes: {
          username: []
        }
      }
    }
  }
}

export const configureAmplify = async () => {
  // Configure Amplify Authentication
  // The authetnication configs need to be done first before the analytics to get the username 
  Amplify.configure({ Auth: config.Auth });

  let disableAnalytics = false;
  let queryParams = Object.fromEntries((new URLSearchParams(window.location.search)).entries());
  if (Object.keys(queryParams).length > 0) {
    // disable Pinpoint if the query string param has playwright set to true
    disableAnalytics = queryParams?.playwright === 'true';
  }

  if (!disableAnalytics) {
    // Configure Amplify Analytics
    // Get the username and send it as part of the userAttributes to the AWS pinpoint. 
    if (await isUserAuthenticated()) {
      const userInfo = await getCurrentAuthenticatedUser();
      if (userInfo) {
        config.Analytics.AWSPinpoint.endpoint.userAttributes.username = [userInfo.username];
      }
    }
    Amplify.configure({ Analytics: config.Analytics });

    // Automatically track session event
    Analytics.autoTrack('session', {
      // REQUIRED, turn on/off the auto tracking
      enable: true,

      // OPTIONAL, the service provider, by default is the Amazon Pinpoint
      provider: 'AWSPinpoint'
    });

    // Automatically track pageView event
    Analytics.autoTrack('pageView', {
      // REQUIRED, turn on/off the auto tracking
      enable: true,

      // OPTIONAL, the event name, by default is 'pageView'
      eventName: 'pageView',

      // OPTIONAL, by default is 'multiPageApp'
      // you need to change it to 'SPA' if your app is a single-page app like React
      type: 'SPA',

      // OPTIONAL, the service provider, by default is the Amazon Pinpoint
      provider: 'AWSPinpoint',

      // OPTIONAL, to get the current page url
      getUrl: () => {
        // the default function
        return window.location.origin + window.location.pathname;
      }
    });
  }
};