import { useEffect, useState, useRef, forwardRef, useImperativeHandle
       } from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';

import { getScrollbarWidth } from '../../../utils/utils';
import ModalCloseButton from './ModalCloseButton';
import ModalFooter from './ModalFooter';
import ModalHeading from './ModalHeading';
import ModalWindow from './ModalWindow';
import ModalWrapper, { MODAL_BASE_CLASS } from './ModalWrapper';

/**
 * This component provides a modal.  It is based on the USWDS modal which
 * satisifes Section 508 accessibility requirements.  This component is
 * adapted from Trussworks'
 * https://github.com/trussworks/react-uswds/blob/main/src/components/Modal/Modal.tsx
 *
 * EXPECTED USAGE:
 * - A header is required.  The text is specified in the 'heading' property.
 * - The user is required to use the buttons in the modal to dismiss it.
 *   - If <code>handleClose</code> is set, a footer will automatically be
 *     created with a Close button.
 *   - If the modal requires buttons, then they should be wrapped in a 
 *     <code>ModalFooter</code> and be included in props.children.
 * - An element that should have the initial focus should have the property
 *   'data-focus'.
 *
 * @param {string}   id            the id to give this modal
 * @param {boolean}  isOpen        true if the modal is open
 * @param {string}   heading       title for the modal
 * @param {string}   headingStyle  any USWDS style to apply to the heading to
 *                                 override the default style
 * @param {function} handleClose   function to call to close the modal; this
 *                                 serves as a flag for the modal to create a
 *                                 'Close' button in the footer
 * @param {boolean}  isLarge       true for a larger modal size
 * @param {JSX}      children      what to display as the main + footer content
 * @param {object}   divProps      any additional properties for the modal
 */

// This Modal is wrapped in a FocusTrap which requires its child element to be a
// functional component that uses the React.forwardRef() API.
const Modal = forwardRef((props, ref) => {

    const {
        id,
        isOpen=false,
        heading,
        headingStyle,
        handleClose,
        isLarge=false,
        children,
        ...divProps
    } = props;

    const [mounted, setMounted] = useState(false);
    const initialPaddingRef = useRef(null);
    const tempPaddingRef = useRef(null);
    const modalEl = useRef(null);

    const DOCUMENT_ROOT = '#root';
    const HIDDEN = `[data-modal-hidden]`;

    // If the Modal is only presenting information, then forceAction can be
    // false/unset and the user can click outside the modal to dismiss it.
    // However, we decided to require forceAction to be true so that all our
    // dialogs function the same way.  Keep this functionality, though, in case
    // we resurrect it.
    const FORCE_ACTION = true;

    const closeModal = (event) => {
        handleClose(event);
    };

    useImperativeHandle(
        ref,
        () => ({
            modalId: id,
            modalIsOpen: isOpen,
        }),
        [id, isOpen]
    );

    const handleOpenEffect = () => {
        const { body } = document;
        body.style.paddingRight = tempPaddingRef.current || '';
        body.classList.add('usa-js-modal--active');

        document.querySelectorAll(DOCUMENT_ROOT).forEach((el) => {
            el.setAttribute('disabled', 'true');
            el.setAttribute('aria-hidden', 'true');
            el.setAttribute('data-modal-hidden', '');

            el.querySelectorAll(':not([disabled])').forEach((child) => {
                child.setAttribute('disabled', 'true');
                child.setAttribute('aria-hidden', 'true');
                child.setAttribute('data-modal-hidden', '');
            });
        });

        if (FORCE_ACTION) {
            body.classList.add('usa-js-no-click');
        }
    };

    const handleCloseEffect = () => {
        const { body } = document;
        body.style.paddingRight = initialPaddingRef.current || '';
        body.classList.remove('usa-js-modal--active');
        body.classList.remove('usa-js-no-click');

        document.querySelectorAll(HIDDEN).forEach((el) => {
            el.removeAttribute('aria-hidden');
            el.removeAttribute('data-modal-hidden');
            el.removeAttribute('disabled');
        });
    };

    useEffect(() => {
        const SCROLLBAR_WIDTH = getScrollbarWidth();
        const INITIAL_PADDING =
            window
                .getComputedStyle(document.body)
                .getPropertyValue('padding-right') || '0px';

        const TEMPORARY_PADDING = `${
            parseInt(INITIAL_PADDING.replace(/px/, ''), 10) +
            parseInt(SCROLLBAR_WIDTH.replace(/px/, ''), 10)
        }px`;

        initialPaddingRef.current = INITIAL_PADDING;
        tempPaddingRef.current = TEMPORARY_PADDING;

        setMounted(true);

        return () => {
            // Reset as if the modal is being closed
            handleCloseEffect();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted]);

    useEffect(() => {
        if (mounted) {
            if (isOpen === true) {
                handleOpenEffect();
            } else if (isOpen === false) {
                handleCloseEffect();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, mounted]);

    const initialFocus = () => {
        const focusEl = modalEl.current?.querySelector('[data-focus]');

        return focusEl ? focusEl : modalEl.current || false;
    };

    const focusTrapOptions = {
        initialFocus,
        escapeDeactivates: (): boolean => {
            if (FORCE_ACTION) {
                return false;
            }

            closeModal();
            return true;
        },
    };

    const modalHeadingId = `${id}-heading`
    const modal = (
        <FocusTrap active={isOpen} focusTrapOptions={focusTrapOptions}>
            <ModalWrapper
                role="dialog"
                id={id}
                data-force-action={FORCE_ACTION}
                isVisible={isOpen}
                handleClose={closeModal}
                forceAction={FORCE_ACTION}>
                <ModalWindow
                    modalId={id}
                    ref={modalEl}
                    isLarge={isLarge}
                    forceAction={FORCE_ACTION}
                    tabIndex={-1}
                    handleClose={closeModal}
                    modalHeadingId={modalHeadingId}
                    {...divProps}
                >
                    <ModalHeading
                        id={modalHeadingId}
                        heading={heading}
                        headingStyle={headingStyle}
                    />
                    {children}
                { 
                    !!handleClose && 
                    <ModalFooter className='display-flex flex-justify-end margin-top-3'>
                        <ModalCloseButton
                            modalId={id}
                            handleClose={handleClose}
                        />
                    </ModalFooter>
                }
                </ModalWindow>
            </ModalWrapper>
        </FocusTrap>
    );

    const modalRootNode = document.getElementById(MODAL_BASE_CLASS)
    const target = modalRootNode || document.body

    return ReactDOM.createPortal(modal, target)
});

export default Modal;
