import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

import { useEffect, useMemo, useRef,  useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ShortUniqueId from 'short-unique-id';

import { selectCompanyAction } from '../../app/slices/admin';
import { createCompany, deleteCompany, updateCompany, getCompanyDetail, getAccessRequestDetail } from '../../apiClient';
import { setAlert } from '../../app/slices/alert';
import { setLinks as setBreadcrumbs } from '../../app/slices/breadcrumbs';
import { setPageTitle } from '../../app/slices/page';
import placeholderImage from '../../assets/placeholder_image.png';
import ActionButton from '../../components/common/ActionButton';
import BodyText from '../../components/common/BodyText';
import Checkbox from '../../components/common/Checkbox';
import LinkButton from '../../components/common/LinkButton';
import OkCancelDialog from '../../components/common/OkCancelDialog';
import ProcessingOverlay from '../../components/common/ProcessingOverlay';
import TextArea from '../../components/common/TextArea';
import TextField from '../../components/common/TextField';
import { useAbortController } from '../../hooks';
import { COMPANY_ABBREVIATION_MAX_LENGTH, COMPANY_ROLE_NAME_TO_ROLE_ID,
         convertAccessRequestToCompanyDetail, validateCompany
       } from '../../utils/companyRegistration';
import { URL_HOME, URL_ADMIN, URL_ADMIN_COMPANIES } from '../../utils/navigation';
import { formatPhoneNumber, trimWhitespace, CANCEL_DELETE_TEXT, OK_DELETE_TEXT
       } from '../../utils/utils';
import { isErrorObjectEmpty } from '../../utils/validation';
import { fetchRoles, selectRoles } from '../../app/slices/company';

/**
 * This provides the user interface to enter a new company or edit the
 * details of an existing company.  It is typically accessed from the
 * company list on the Admin page (which implies It should only be available
 * for admins).
 */
export default function CompanyDetail() {
    const { abortSignalRef, isCancel, abort } = useAbortController();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const ivtUidLength = 15;
    const apiKeyUidLength = 40;

    const uid = new ShortUniqueId();
    const formRef = useRef(null);
    const companyAction = useSelector(selectCompanyAction);
    const companyRoles = useSelector(selectRoles);

    const [accessRequestId, setAccessRequestId] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [pageAction, setPageAction] = useState(companyAction.create); // action determined based on searchParams
    const [readonlyInputFields, setReadonlyInputFields] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('');
    const [input, setInput] = useState({
        name: '',
        abbreviation: '',
        address: '',
        url: '',
        ivt: '',
        isa_metadata: '',
        client_id: '',
        client_secret: '',
        api_key: '',
        cognito_group_name: '',
        email_domain: '',
        poc_name: '',
        poc_phone: '',
        poc_email: '',
        logo_url: '',
        allowed_oauth_scopes: [],
        sftp_enabled: false,
        s3_transfer_enabled: false,
        roles: {}
    });
    const [allowedScopes, setAllowedScopes] = useState({
        'cdmdata:read': false,
        'cdmdata:write': false,
        'cdmdata:stream': false,
        'publicdata:read': false,
        'publicdata:write': false,
        'publicdata:stream': false
    });
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const deleteDialogRef = useRef(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const [formErrors, setFormErrors] = useState({});

    const isCreating = useMemo(() => pageAction === companyAction.create, [pageAction, companyAction.create]);
    const isDeleting = useMemo(() => pageAction === companyAction.remove, [pageAction, companyAction.remove]);
    const isEditing = useMemo(() => pageAction === companyAction.update, [pageAction, companyAction.update]);
    const isViewing = useMemo(() => pageAction === companyAction.view, [pageAction, companyAction.view]);


    // Breadcrumbs are only available for the Admin
    const breadcrumbs = [
        { name: 'Home', href: URL_HOME },
        { name: 'Admin', href: URL_ADMIN },
        { name: 'Companies', href: URL_ADMIN_COMPANIES },
        { name: 'Company', href: null }
    ];

    const handleAllowedScopeChange = (e) => {
        setAllowedScopes({
            ...allowedScopes,
            [e.target.id]: e.target.checked
        });
    };

    const handleCompanyRoleChnage = (e, roleId) => {
        const updatedInput = { ...input };
        updatedInput.roles[roleId] = e.target.checked;
        setInput(updatedInput);
    }

    const handleInputChange = (fieldName, value) => {
        const updatedInput = {
            ...input,
            [fieldName]: value
        };
        setInput(updatedInput);
    };

    /**
     * Updates a field that has a character limit.  The field value will
     * be truncated if it exceeds the limit.
     */
    const handleInputChangeWithLimit = (fieldName, value, limit) => {
        if (value && typeof (value) === 'string') {
            if (value.length > limit) {
                value = value.substring(0, limit);
            }
        }
        handleInputChange(fieldName, value);
    };

    const handleCloseDeleteDialog = () => {
        setShowDeleteDialog(false);
    };

    const handleCancelDeleteDialog = () => {
        setShowDeleteDialog(false);
        updateSearchParams({ action: companyAction.update });
    };

    const canSubmit = () => {
        let rtn = false;
        // send a request if
        // 1. all required text fields are filled out
        // 2. at least one allowed scope is clicked
        let errors = validateCompany(input, allowedScopes);
        setFormErrors(errors);

        rtn = formRef.current.reportValidity() && isErrorObjectEmpty(errors)

        return rtn;
    };

    const handleSubmit = async () => {
        try {
            if (canSubmit()) {
                setLoading(true);
                setLoadingMsg('Saving company data...');

                // Recreate the roles field from clicked roles
                let roles = [];
                Object.entries(input.roles)?.forEach(([roleId, checked]) => {
                    if (checked) {
                        roles.push(Number(roleId));
                    }
                })
                let body = {
                    ...input,
                    email_domain: input.email_domain.split(',').map(d => d.trim()).join(','),
                    // Create allowed_oauth_scopes array field from clicked scopes
                    allowed_oauth_scopes: Object.keys(allowedScopes).filter(k => allowedScopes[k]),
                    roles: roles
                };

                // trim whitespace from all text input fields
                Object.entries(body).forEach(([k, v]) => {
                    body[k] = trimWhitespace(v);
                });

                switch (pageAction) {
                    case companyAction.create:
                        delete body.client_id;
                        delete body.client_secret;
                        const res = await createCompany({ body, abortSignal: abortSignalRef?.current });
                        dispatch(setAlert({
                            show: true,
                            message: 'successfully created a new company',
                            severity: 'success'
                        }));
                        // set query string params
                        setSearchParams({
                            companyId: res.data.id,
                            action: companyAction.view
                        })
                        break;
                    case companyAction.update:
                        delete body.id;
                        await updateCompany({ id: companyId, body, abortSignal: abortSignalRef?.current });
                        dispatch(setAlert({
                            show: true,
                            message: 'successfully updated the company',
                            severity: 'success'
                        }));
                        // change to the 'view' action after update is completed
                        updateSearchParams(companyAction.view);
                        break;
                    default:
                        console.error(`Invalid action type: ${pageAction}`)
                        break;
                };
                setLoading(false);
                setLoadingMsg('');
                resetFormErrors();
                navigateToCompanyListPage();
            }
        }
        catch (error) {
            if (isCancel(error))
                return

            console.error(`failed to ${pageAction} a company:`, error.message);
            dispatch(setAlert({
                show: true,
                message: `failed to ${pageAction} a company: ${error.response.data.error}`,
                severity: 'error'
            }));

            setLoading(false);
            setLoadingMsg('');
        }
    };

    const handleDelete = () => {
        handleCloseDeleteDialog();
        setLoading(true);
        setLoadingMsg('Deleting company...');
        deleteCompany({
            id: companyId,
            client_id: input?.client_id,
            abortSignal: abortSignalRef?.current
        })
        .then(() => {
            dispatch(setAlert({
                show: true,
                message: 'successfully deleted the company',
                severity: 'success'
            }));
            navigateToCompanyListPage();

            setLoading(false);
            setLoadingMsg('');
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to delete the company:', err.message);
            dispatch(setAlert({
                show: true,
                message: 'failed to delete the company',
                severity: 'error'
            }));

            setLoading(false);
            setLoadingMsg('');
        });
    }

    const isCdmRoleChecked = () => {
        return !!input?.roles[COMPANY_ROLE_NAME_TO_ROLE_ID['CDM']];
    }

    const initCompanyData = async (companyId) => {
        setLoading(true);
        setLoadingMsg('Loading company data...');
        getCompanyDetail({ id: companyId, abortSignal: abortSignalRef?.current })
        .then(res => {
            const company = res.data;
            if (company) {
                let roles = {};
                company.roles?.forEach(roleId => {
                    roles[roleId] = true;
                });
                setInput({
                    ...input,
                    id: companyId,
                    ...company,
                    roles
                });

                let AllowedOAuthScopes = {};
                if (company.allowed_oauth_scopes) {
                    AllowedOAuthScopes = company.allowed_oauth_scopes.reduce((obj, item) => {
                        let rtn = {
                            ...obj
                        };
                        // remove the custom scope id
                        let scope = item.substr(item.indexOf('/') + 1);
                        if (!!scope && scope in allowedScopes) {
                            Object.assign(rtn, { [scope]: true });
                        }
                        return rtn
                    }, {});
                }
                setAllowedScopes({
                    ...allowedScopes,
                    ...AllowedOAuthScopes
                });
            }
            else {
                console.error(`failed to init a company ${companyId}: API returned ${company}`);
                dispatch(setAlert({
                    show: true,
                    message: `failed to init a company ${companyId}`,
                    severity: 'error'
                }));
            }

            setLoading(false);
            setLoadingMsg('');
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error(`failed to init a company ${companyId}`, err.message);
            dispatch(setAlert({
                show: true,
                message: `failed to init a company ${companyId}`,
                severity: 'error'
            }));

            setLoading(false);
            setLoadingMsg('');
        });
    };

    const navigateToCompanyListPage = () => {
        navigate(URL_ADMIN_COMPANIES);
    };

    const resetFormErrors = () => {
        setFormErrors({})
    }

    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));

        // fetch roles first before initializing company data
        dispatch(fetchRoles({ abortSignalRef, abort }))
            .unwrap()
            .catch(err => {
                if (err === 'cancelled')
                    return;

                console.error('Failed to get roles', err);
                dispatch(setAlert({
                    show: true,
                    message: 'Failed to get roles',
                    severity: 'error'
                }));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const companyId = searchParams.get('companyId');
        setCompanyId(companyId);
        const action = searchParams.get('action');
        setPageAction(action);
        const accessRequestId = searchParams.get('accessRequestId')
        setAccessRequestId(accessRequestId);
    }, [searchParams])

    useEffect(() => {
        if (pageAction === companyAction.remove) {
            setShowDeleteDialog(true);
        }
    }, [pageAction, companyAction])

    useEffect(() => {
        resetFormErrors();
        let title = '';
        switch (pageAction) {
            case companyAction.create:
                title = '[Admin] Create a Company';
                setReadonlyInputFields(false);
                break;
            case companyAction.update:
                if (!input.name) {
                    initCompanyData(companyId);
                }
                title = `[Admin] Edit Company: ${input.name}`;
                setReadonlyInputFields(false);
                break;
            case companyAction.view:
                initCompanyData(companyId);
                title = `[Admin] View Company: ${input.name}`;
                setReadonlyInputFields(true);
                break;
            case companyAction.remove:
                initCompanyData(companyId);
                title = `[Admin] Delete Company: ${input.name}`;
                setReadonlyInputFields(true);
                break;
            default:
                console.error(`Invalid page action: ${pageAction}`);
                break;
        };
        dispatch(setPageTitle(title));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageAction]);

    useEffect(() => {
        resetFormErrors();
        let title = '';
        switch (pageAction) {
            case companyAction.create:
                title = '[Admin] Create a Company';
                break;
            case companyAction.update:
                title = `[Admin] Edit Company: ${input.name}`;
                break;
            case companyAction.view:
                title = `[Admin] View Company: ${input.name}`;
                break;
            case companyAction.remove:
                title = `[Admin] Delete Company: ${input.name}`;
                break;
            default:
                console.error(`Invalid page action: ${pageAction}`);
                break;
        };
        dispatch(setPageTitle(title));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input.name]);

    useEffect(() => {
        if (accessRequestId !== null) {
            setLoading(true);
            setLoadingMsg('Loading access request details...');
            getAccessRequestDetail({
                id: accessRequestId,
                selectFields: [
                    'id',
                    'team_name',
                    'organization_address',
                    'team_url',
                    'team_logo_url',
                    'team_poc_name',
                    'team_poc_phone',
                    'team_poc_email',
                    'requester_email'
                ].join(','),
                abortSignal: abortSignalRef?.current
            })
            .then(res => {
                if (res.data) {
                    let newCompanyDetail = convertAccessRequestToCompanyDetail(res.data);
                    setInput({
                        ...input,
                        ...newCompanyDetail
                    })
                }
            })
            .catch(error => {
                if (isCancel(error))
                    return;

                console.error('failed to retrieve access request details', error);
                dispatch(setAlert({
                    show: true,
                    message: 'Failed to retrieve access request details',
                    severity: 'error'
                }));
            })
            .finally(() => {
                setLoading(false);
                setLoadingMsg('');
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessRequestId]);

    const updateSearchParams = (newParams) => {
        let ogParams = {}
        for (let [k, v] of searchParams.entries()) {
            ogParams = {
                ...ogParams,
                [k]: v
            }
        }
        setSearchParams({
            ...ogParams,
            ...newParams
        });
    };

    // Define the abbreviation label here to reduce complexity below.
    const abbrev = input.abbreviation ?? '';
    const abbrevLabel =
        `Abbreviation (${abbrev.length} / ${COMPANY_ABBREVIATION_MAX_LENGTH} characters)`;

    return (
        <Container id='admin-company-detail' disableGutters>

            <Card sx={{ p: '1rem' }}>

                {/* Shows the circular progress icon when loading the data */}
                <ProcessingOverlay open={loading} msg={loadingMsg} />

                <CardContent>
                    <form ref={formRef}>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    id='company-name'
                                    name='name'
                                    label='Name'
                                    labelStyle='margin-top-0'
                                    value={input.name}
                                    onChange={(e) =>
                                        handleInputChange('name',
                                            e.target.value)}
                                    required
                                    disabled={readonlyInputFields}
                                    errorMessage={formErrors?.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='abbreviation'
                                    name='abbreviation'
                                    label={abbrevLabel}
                                    labelStyle='margin-top-2'
                                    value={abbrev}
                                    onChange={(e) =>
                                        handleInputChangeWithLimit(
                                            'abbreviation', e.target.value,
                                            COMPANY_ABBREVIATION_MAX_LENGTH)
                                    }
                                    required
                                    disabled={readonlyInputFields}
                                    errorMessage={formErrors?.abbreviation}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='address'
                                    name='address'
                                    label='Address'
                                    labelStyle='margin-top-2'
                                    value={input.address}
                                    onChange={(e) =>
                                        handleInputChange('address',
                                            e.target.value)}
                                    required
                                    disabled={readonlyInputFields}
                                    errorMessage={formErrors?.address}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='url'
                                    name='url'
                                    label='URL'
                                    labelStyle='margin-top-2'
                                    value={input.url}
                                    onChange={(e) =>
                                        handleInputChange('url', e.target.value)}
                                    required
                                    disabled={readonlyInputFields}
                                    errorMessage={formErrors?.url}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='logo-url'
                                    name='logo_url'
                                    label='Logo URL'
                                    labelStyle='margin-top-2'
                                    value={input.logo_url}
                                    onChange={(e) =>
                                        handleInputChange('logo_url',
                                            e.target.value)}
                                    disabled={readonlyInputFields}
                                    errorMessage={formErrors?.logo_url}
                                />
                            </Grid>

                            <Grid item xs={12} >
                                <Box
                                    component='img'
                                    alt={input.logo_url ? input.name : 'company logo placeholder'}
                                    src={input.logo_url ? input.logo_url : placeholderImage}
                                    sx={{
                                        width: '8rem',
                                        height: 'fit-content',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='poc-name'
                                    name='poc_name'
                                    label='POC Name'
                                    labelStyle='margin-top-2'
                                    value={input.poc_name}
                                    onChange={(e) =>
                                        handleInputChange('poc_name',
                                            e.target.value)}
                                    required
                                    disabled={readonlyInputFields}
                                    errorMessage={formErrors?.poc_name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='poc-phone'
                                    name='poc_phone'
                                    label='POC Phone'
                                    labelStyle='margin-top-2'
                                    value={input.poc_phone}
                                    onChange={(e) => {
                                        let phone = formatPhoneNumber(e.target.value);
                                        handleInputChange('poc_phone',
                                            phone)
                                    }}
                                    required
                                    disabled={readonlyInputFields}
                                    errorMessage={formErrors?.poc_phone}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='poc-email'
                                    name='poc_email'
                                    label='POC Email'
                                    labelStyle='margin-top-2'
                                    value={input.poc_email}
                                    onChange={(e) =>
                                        handleInputChange('poc_email',
                                            e.target.value)}
                                    required
                                    disabled={readonlyInputFields}
                                    errorMessage={formErrors?.poc_email}
                                />
                            </Grid>
                            {
                                // hide client ID and secret input fields when
                                // in create mode
                                !isCreating &&
                                <Fragment>
                                    <Grid item xs={12}>
                                        <TextField
                                            id='client-id'
                                            name='client_id'
                                            label='Client ID'
                                            labelStyle='margin-top-2'
                                            value={input.client_id}
                                            onChange={(e) =>
                                                handleInputChange('client_id',
                                                    e.target.value)}
                                            disabled={readonlyInputFields}
                                            errorMessage={formErrors?.client_id}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id='client-secret'
                                            name='client_secret'
                                            label='Client Secret'
                                            labelStyle='margin-top-2'
                                            value={input.client_secret}
                                            onChange={(e) =>
                                                handleInputChange('client_secret',
                                                    e.target.value)}
                                            disabled={readonlyInputFields}
                                            errorMessage={formErrors?.client_secret}
                                        />
                                    </Grid>
                                </Fragment>
                            }
                            <Grid container item xs={12} spacing={1}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'end'
                                }}
                            >
                                <Grid item xs={12} sm={9}>
                                    <TextField
                                        id='api-key'
                                        name='api_key'
                                        label='API Key'
                                        labelStyle='margin-top-2'
                                        value={input.api_key}
                                        onChange={(e) =>
                                            handleInputChange('api_key',
                                                e.target.value)}
                                        disabled={readonlyInputFields}
                                        errorMessage={formErrors?.api_key}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <ActionButton text='Generate UUID'
                                        disabled={readonlyInputFields}
                                        onClick={() =>
                                            handleInputChange('api_key',
                                                uid.randomUUID(apiKeyUidLength))}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='email-validation-Domain'
                                    name='email_validation_Domain'
                                    label='Email Validation Domain'
                                    labelStyle='margin-top-2'
                                    help="Comma-separated list of domains"
                                    value={input.email_domain}
                                    onChange={(e) =>
                                        handleInputChange('email_domain',
                                            e.target.value)}
                                    disabled={readonlyInputFields}
                                    errorMessage={formErrors?.email_domain}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='cognito-group'
                                    name='cognito_group'
                                    label='Cognito Group'
                                    labelStyle='margin-top-2'
                                    value={input.cognito_group_name}
                                    onChange={(e) =>
                                        handleInputChange('cognito_group_name',
                                            e.target.value)}
                                    required
                                    disabled={readonlyInputFields}
                                    errorMessage={formErrors?.cognito_group_name}
                                />
                            </Grid>
                            <Grid container item xs={12} spacing={1}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'end'
                                }}
                            >
                                <Grid item xs={12} sm={9}>
                                    <TextField
                                        id='ivt'
                                        name='ivt'
                                        label='IVT'
                                        labelStyle='margin-top-2'
                                        value={input.ivt}
                                        onChange={(e) =>
                                            handleInputChange('ivt',
                                                e.target.value)}
                                        required
                                        disabled={readonlyInputFields}
                                        errorMessage={formErrors?.ivt}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <ActionButton text='Generate IVT'
                                        disabled={readonlyInputFields}
                                        onClick={() =>
                                            handleInputChange('ivt',
                                                uid.randomUUID(ivtUidLength))}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TextArea
                                    id='isa-metadata'
                                    name='isa_metadata'
                                    label='ISA Metadata'
                                    labelStyle='margin-top-2'
                                    value={input.isa_metadata}
                                    onChange={(e) =>
                                        handleInputChange('isa_metadata',
                                            e.target.value)}
                                    disabled={readonlyInputFields}
                                    errorMessage={formErrors?.isa_metadata}
                                />
                            </Grid>
                            <Grid id='access-roles' item xs={12} >
                                <BodyText label='Access Roles' />
                                <FormControl
                                    error={formErrors?.companyRoles}
                                    sx={{
                                        display: 'block'
                                    }}
                                >
                                    {
                                        !isViewing && formErrors?.companyRoles != null &&
                                        <Alert
                                            severity='error'
                                            variant='filled'
                                        >
                                            At least one role must be checked.
                                        </Alert>
                                    }

                                    <div className="grid-container margin-top-1 margin-left-3 margin-right-0 padding-x-0">
                                        <div className="grid-row">
                                            <BodyText labelStyle="tablet:grid-col-4 padding-left-3 margin-bottom-1 margin-top-0 text-underline"
                                                label='Name'
                                            />
                                            <BodyText labelStyle="tablet:grid-col-8 margin-bottom-1 margin-top-0 text-underline"
                                                label='Definition'
                                            />
                                        </div>
                                    </div>
                                    {
                                        companyRoles && companyRoles.map(({ id, role, definition }) => (
                                            <div className="grid-row grid-gap-sm" key={id}>
                                                <div className="tablet:grid-col-4 margin-bottom-105">
                                                    <Checkbox
                                                        label={role}
                                                        labelStyle='margin-top-0 flex-align-center'
                                                        checked={!!input.roles?.[id]}
                                                        onChange={(e) => handleCompanyRoleChnage(e, id)}
                                                        id={id}
                                                        disabled={isViewing}
                                                    />
                                                </div>
                                                <div className="tablet:grid-col-8 margin-top-0 flex-align-center margin-bottom-105">
                                                    {definition}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </FormControl>
                            </Grid>
                            <Grid id='allowed-scopes' item xs={12} >
                                <label className='usa-label'>
                                    Allowed OAuth Scopes
                                </label>
                                <FormControl
                                    error={formErrors?.allowedScopes != null}
                                    helpertext={formErrors?.allowedScopes}
                                    sx={{
                                        display: 'block'
                                    }}
                                >
                                    {
                                        !isViewing && formErrors?.allowedScopes != null &&
                                        <Alert
                                            severity='error'
                                            variant='filled'
                                        >
                                            At least one scope must be checked.
                                        </Alert>
                                    }
                                    {
                                        Object.entries(allowedScopes).map(([key, value]) => {
                                            // if CDM is not chekced, do not display the cdm scopes
                                            if (!isCdmRoleChecked() && key.startsWith('cdmdata')) {
                                                return null;
                                            }

                                            return (
                                                <Checkbox
                                                    label={key}
                                                    checked={value}
                                                    onChange={handleAllowedScopeChange}
                                                    id={key}
                                                    key={key}
                                                    disabled={isViewing}
                                                />
                                            )
                                        })
                                    }
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider variant='fullWidth' sx={{ mt: '1rem' }} />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl
                                    sx={{
                                        display: 'block',
                                        mb: '2rem'
                                    }}
                                >
                                    <Checkbox
                                        label='SFTP'
                                        checked={input.sftp_enabled}
                                        onChange={e =>
                                            handleInputChange('sftp_enabled',
                                                e.target.checked)}
                                        id='sftp-cb'
                                        key='sftp-cb'
                                        disabled={isViewing}
                                    />
                                    <Checkbox
                                        label='S3 Transfer'
                                        checked={input.s3_transfer_enabled}
                                        onChange={e =>
                                            handleInputChange('s3_transfer_enabled',
                                                e.target.checked)}
                                        id='s3-cb'
                                        key='s3-cb'
                                        disabled={isViewing}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} spacing={2}>
                            <Grid item
                                xs={12}
                                sm={3}
                                sx={{
                                    textAlign: { xs: 'end', sm: 'left' }
                                }}
                            >
                                {
                                    !isCreating &&
                                    <ActionButton text='Delete' isDelete
                                        onClick={() => {
                                            updateSearchParams({
                                                action: companyAction.remove
                                            })
                                        }}
                                    />
                                }
                            </Grid>

                            <Grid item xs={12} sm={9}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'end'
                                }}
                            >
                                <LinkButton text='Cancel'
                                    href={URL_ADMIN_COMPANIES}
                                    style={{ marginRight: '1rem' }}
                                />
                                {
                                    isCreating &&
                                    <ActionButton text='Create'
                                        onClick={() => handleSubmit()}
                                    />
                                }
                                {
                                    (isViewing || isDeleting) &&
                                    <ActionButton text='Edit'
                                        onClick={() => updateSearchParams({
                                            action: companyAction.update
                                        })
                                        }
                                    />
                                }
                                {
                                    isEditing &&
                                    <ActionButton text='Save'
                                        onClick={() => handleSubmit()}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>

            {/* Dialog for the user to delete a company.  It must be
                created even if not shown for the sake of the ref */}
            <OkCancelDialog
                id='confirm-company-deletion-dialog'
                open={showDeleteDialog}
                heading='Confirm Company Deletion'
                okText={OK_DELETE_TEXT}
                cancelText={CANCEL_DELETE_TEXT}
                handleOkOp={handleDelete}
                handleCancelOp={handleCancelDeleteDialog}
                dialogRef={deleteDialogRef}
            >
                <BodyText labelStyle='text-center'>
                    Are you sure you want to delete company
                </BodyText>
                <BodyText labelStyle='text-center margin-top-1'>
                    <strong>{input.name}</strong>?
                </BodyText>
            </OkCancelDialog>
        </Container>
    );
};
