import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Formats the current UTC time with the given format.
 *
 * @param {string} format    optional date format; default is
 *                           'YYYY-MM-DD HH:mm:ss'
 *
 * @return {string} formatted "now" UTC time
 */
export const getFormattedNowDate = (format=TIMESTAMP_FORMAT) => {
    const now = dayjs().utc();
    return now.format(format);
};

/**
 * Takes a date and formats it with the given format.
 *
 * @param {object} date      date to format
 * @param {string} format    optional date format; default is
 *                           'YYYY-MM-DD HH:mm:ss'
 *
 * @return {string} formatted date
 */
export const getFormattedDate = (date, format=TIMESTAMP_FORMAT) => {
    return date.format(format);
};

/**
 * Converts a formatted date string into a Date object in UTC time.
 *
 * @param {string} dateStr  date string
 *
 * @return {object} date in UTC time
 */
export const getDateObjectFromEpoch = (dateStr) => {
    return dayjs(dateStr).utc(true);
};

/**
 * Converts a formatted date string into a Date object.
 *
 * @param {string} dateStr  date string; assumed format is 'YYYY-MM-DD HH:mm:ss'
 *
 * @return {object} date
 */
export const getDateObjectFromString = (dateStr) => {
    return dayjs(dateStr, TIMESTAMP_FORMAT);
};

/**
 * Takes a date string and reformats it with the given format.
 *
 * @param {string} dateStr   date string to reformat
 * @param {string} format    optional date format; default is
 *                           'YYYY-MM-DD HH:mm:ss'
 *
 * @return {string} date from dateStr, reformatted
 */
export const getFormattedDateFromString = (dateStr, format=TIMESTAMP_FORMAT) => {
    return getFormattedDate(getDateObjectFromString(dateStr), format);
};

/**
 * Formats a time string as MM/DD/YYYY.
 *
 * @param {string} timestamp   time to format
 *
 * @returns timestamp reformatted as MM/DD/YYYY
 */
export const getDateFromTimestamp = (timestamp) => {
    let rtn = null;
    if (timestamp) {
        let date = new Date(timestamp);
        rtn = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    }
    return rtn;
};
