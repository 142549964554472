import Skeleton from '@mui/material/Skeleton';

import { Fragment, useEffect, useState } from 'react';

import SelectObject from './SelectObject';

const DEFAULT_ALL_OPTION_VALUE = { id: 'all-services', name: 'All Services' };


/**
 * This provides a drop-down selector to select a service.
 *
 * @param {array(object)} serviceOptions        list of services
 * @param {function}      handleServiceSelect   selection handler; note this
 *                                              should handle just the
 *                                              selected service, NOT the
 *                                              selection event
 * @param {object}        selectedService       service to select, if any
 * @param {boolean}       showAllOption         true to pre-pend allOptionValue
 *                                              to the head of serviceOptions
 * @param {array(object)} allOptionValue        "all services" option(s); each
 *                                              item must contain an identifier
 *                                              and a service name
 * @param {string}        serviceLabel          label for the select control
 * @param {string}        serviceIdAttribute    name of the attribute in the
 *                                              serviceOptions that contains the
 *                                              unique service identifier
 * @param {string}        serviceValueAttribute name of the attribute in the
 *                                              serviceOptions that contains the
 *                                              service name
 * @param {boolean}       disabled              true to disable the selector
 */
const ServiceSelect = ({
    serviceOptions,
    handleServiceSelect,
    selectedService,
    showAllOption,
    allOptionValue=[DEFAULT_ALL_OPTION_VALUE],
    serviceLabel='Service',
    serviceIdAttribute='id',
    serviceValueAttribute='name',
    disabled,
}) => {


    const [services, setServices] = useState(null);

    useEffect(() => {
        setServices(serviceOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceOptions]);

    return (
        <Fragment>
            {
                (!services || !services.length) ? (
                    <Skeleton variant='rounded' height='3rem' />
                ) : (
                    <SelectObject
                        id='common-service-select'
                        name='service-select'
                        label={serviceLabel}
                        labelStyle='margin-top-0'
                        value={selectedService}
                        itemList={showAllOption ? [...allOptionValue, ...services] : services}
                        idAttribute={serviceIdAttribute}
                        valueAttribute={serviceValueAttribute}
                        handleSelect={handleServiceSelect}
                        disabled={disabled}
                    />
                )
            }
        </Fragment>
    );
};

export default ServiceSelect;
