import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import LabelValueTable from '../common/LabelValueTable';


/**
 * This provides the display of the Data Transfer Source information in
 * a Service Detail page when the user clicks on the service from the
 * Subscriptions page or service card.
 *
 * @param {boolean} loadingService  true if the service is still loading
 * @param {object}  service         the Data Transfer service
 */

const DataTransferSource = ({ loadingService, service }) => {

    // If the service is not ready yet or if this is not a Data Transfer
    // service, just bail
    if (loadingService || !service || !service.dts) {
        return null;
    }

    const dtsTableData = [
        {
            label: 'Platform:',
            value: service.dts.source_platform,
            baseId: 'dts-platform'
        },
        {
            label: 'Bucket Name:',
            value: service.dts.name,
            baseId: 'dts-bucket'
        },
        {
            label: 'Description:',
            value: service.dts.description,
            baseId: 'dts-desc'
        }
    ];

    return (
        <Card>
            <CardContent>
                <LabelValueTable tableData={dtsTableData} />
            </CardContent>
        </Card>
    );
};

export default DataTransferSource;
