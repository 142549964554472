import classnames from 'classnames';

/**
 * This provides the formatting for a field name in the display of a
 * "name: value" pair.  The function FieldName can be used to format the name.
 *
 * @param {string} id          the unique identifier for this field
 * @param {string} value       the field value to display
 * @param {string} topMargin   (optional) margin above 'value'; default is
 *                             'margin-top-1'
 * @param {string} valueStyle  any additional USWDS styles to add to the value
 *                             display, e.g. 'text-right' to right-justify
 */
const FieldValue = ({
    id,
    value,
    topMargin='margin-top-1',
    valueStyle
}) => {

    const classes = classnames('usa-label', topMargin, valueStyle);

    return (
        <label
            id={id}
            className={classes}
            style={{ wordBreak: 'break-word'}}
        >
            {value}
        </label>
    );
}

export default FieldValue;
