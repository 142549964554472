import { useState, forwardRef, useRef, useImperativeHandle, useEffect
       } from 'react';
import classnames from 'classnames';

import FieldLabel from './FieldLabel';


/**
 * This component provides a file selector where the user can either choose
 * to drag-and-drop the file or choose a file using a file browser.
 * This is a pared-down version of Trussworks' https://github.com/trussworks/react-uswds/blob/main/src/components/forms/FileInput/FileInput.tsx
 *
 * @param {string}   id              unique ID for the file input
 * @param {string}   name            name of the form element
 * @param {string}   hint            instructional text for the type of files
 *                                   accepted; default='Select any file type'
 *                                   or, when 'accept is set,
 *                                   'Select a file of type <accept>'
 * @param {string}   accept          comma-separated list of accepted file
 *                                   extensions; if unset, all files accepted
 * @param {string}   dragText        instructional text telling the user they
 *                                   can drag a file; default="Drag file here"
 * @param {string}   chooseText      instructional text telling the user they
 *                                   can open a file chooser;
 *                                   default="choose from folder"
 * @param {string}   invalidTypeText error message when the user attemps to drag
 *                                   a file with an invalid file type;
 *                                   default='<xxx> is not an accepted file type'
 * @param {boolean}  required        true if the file is required
 * @param {boolean}  disabled        true to disable the file input
 * @param {boolean}  hidden          true if the component should be hiddne.
 * @param {string}   className       additional USWDS styles to add to the
 *                                   containing wrapper
 * @param {function} onChange        callback when a file is chosen
 * @param {function} onDrop          callback when a file is dragged into the
 *                                   box
 * @param {string}   errorMessage    error message to display if the caller
 *                                   deems the file invalid
 * @param {object}   inputProps      any additional properties to add to the
 *                                   "input" element
 *
 * @return ref has elements
 *     {object}   input      - internale input ref
 *     {function} clearFiles - clears the files chosen
 *     {array}    files      - list of chosen files (which is only 1)
 */

const FileInput = forwardRef((props, ref) => {

    const {
        id,
        name,
        hint,
        accept,
        dragText,
        chooseText,
        invalidTypeText,
        required,
        disabled,
        hidden,
        className,
        onChange,
        onDrop,
        errorMessage,
        ...inputProps
    } = props;

    const internalRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);

    const [displayedErrorMessage, setDisplayedErrorMessage] = useState(null);

    const [files, setFiles] = useState([]);
    const [hideDragText, setHideDragText] = useState(false);

    // Error message can be passed in or set from within this component.
    // Initialize the displayed error message with what was passed in
    useEffect(() => {
        setDisplayedErrorMessage(errorMessage);
    }, [errorMessage])

    useEffect(() => {
        if (typeof navigator === 'undefined') {
            return;
        }

        const hideDragText =
            /rv:11.0/i.test(navigator?.userAgent) ||
            /Edge\/\d./i.test(navigator?.userAgent);

        setHideDragText(hideDragText);
    }, [])

    useImperativeHandle(
        ref,
        () => ({
            input: internalRef.current,
            clearFiles: () => setFiles([]),
            files,
        }),
        [files]
    )

    const fileInputClasses = classnames(
        'usa-file-input',
        {
            'usa-file-input--disabled': disabled,
            'display-none': hidden
        },
        className
    );

    const targetClasses = classnames('usa-file-input__target', {
        'usa-file-input--drag': isDragging,
        'has-invalid-file': !!displayedErrorMessage,
    });

    const defaultDragText = 'Drag file here or ';;
    const defaultChooseText = 'choose from folder';

    const instructionClasses = classnames('usa-file-input__instructions',
        {
            'has-invalid-file': !!displayedErrorMessage,
        }
    );

    const preventInvalidFiles = (e) => {
        let errMsg = null;

        if (accept) {
            const acceptedTypes = accept.split(',');
            let allFilesAllowed = true;
            for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
                const file = e.dataTransfer.files[parseInt(`${i}`)]
                if (allFilesAllowed) {
                    for (let j = 0; j < acceptedTypes.length; j += 1) {
                        const fileType = acceptedTypes[parseInt(`${j}`)];
                        allFilesAllowed = (file.name.indexOf(fileType) > 0) ||
                            file.type.includes(fileType.replace(/\*/g, ''));

                        // Once the file matches an accepted file type, we
                        // don't need to keep comparing the file to the accepted
                        // file types
                        if (allFilesAllowed) {
                            break;
                        }
                    }
                    if (!allFilesAllowed) {
                        const extensionIdx = file.name.lastIndexOf('.');
                        const extension = file.name.slice(extensionIdx);
                        errMsg = !!invalidTypeText ? invalidTypeText :
                            !!extension ?
                                `${extension} is not an accepted file type.` :
                                'This is not an accepted file type.';
                    }
                } else {
                    // Once one of the files fails to match the accepted file
                    // types, we don't need to keep checking files any more
                    break;
                }
            }

            if (!allFilesAllowed) {
                setFiles([]);
                e.preventDefault();
                e.stopPropagation();
            }
            setDisplayedErrorMessage(errMsg);
        }
    }

    // Event handlers
    const handleDragOver = () => setIsDragging(true);
    const handleDragLeave = () => setIsDragging(false);
    const handleDrop = (e) => {
        preventInvalidFiles(e);
        setIsDragging(false);
        if (onDrop) {
            onDrop(e);
        }
    }

    const handleChange = (e) => {
        setDisplayedErrorMessage(null);

        // Map input FileList to array of Files
        const fileArr = [];
        if (e.target?.files?.length) {
            const fileLength = e.target?.files?.length || 0;

            for (let i = 0; i < fileLength; i++) {
                const file = e.target.files.item(i);
                if (file) {
                    fileArr.push(file);
                }
            }
        }
        setFiles(fileArr);

        if (onChange) {
            onChange(e);
        }
    }

    const hintLabel = !!hint ? hint :
        accept ? `Select a file of type ${accept}` : 'Select any file type';
    const hintId=`${id}-hint`;

    return (
        <div
            id={`${id}-container`}
            className={fileInputClasses}
            aria-disabled={disabled}
        >
            <FieldLabel
                id={hintId}
                entryId={id}
                label={hintLabel}
                labelStyle='margin-top-1'
                required={required}
            />

            <div
                id={`${id}-drag-box`}
                className={targetClasses}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <div
                    id={`${id}-instructions`}
                    className={instructionClasses}
                    aria-hidden="true"
                >
                    {
                        !hideDragText &&
                        <span className="usa-file-input__drag-text">
                            {dragText || defaultDragText}
                        </span>
                    }
                    <span className="usa-file-input__choose">
                        {chooseText || defaultChooseText}
                    </span>
                </div>

                {
                    !!displayedErrorMessage &&
                    <div id={`${id}-error`} className="usa-file-input__box" >
                        <span className='usa-error-message' role='alert' >
                            {displayedErrorMessage}
                        </span>
                    </div>
                }

                <input
                    {...inputProps}
                    ref={internalRef}
                    type="file"
                    name={name}
                    id={id}
                    className="usa-file-input__input"
                    disabled={disabled}
                    onChange={handleChange}
                    accept={accept}
                />
            </div>
        </div>
    );
});

export default FileInput;
