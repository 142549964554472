import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

/**
 * This provides a styled icon button where the style applies a focus
 * outline that will satisfy the Sort Site accessibility checker.
 */

// Note the marginLeft is the same as the padding.  This keeps the focus
// outline from overlapping text if this icon abuts a label.
const DipIconButton = styled(IconButton)(({ theme }) => ({
    'color': theme.palette.primary.main,
    'marginLeft': '.2rem',
    'padding': '.2rem',
    '&:focus, &:hover': {
        outline: '2px solid #374274',
    }
}));

export default DipIconButton;
