import { checkServiceType, ServiceTypeUtils } from '../../utils/utils';
import Checkbox from '../common/Checkbox';
import FieldHelp from '../common/FieldHelp';
import SectionHeader from "../common/SectionHeader";
import Select from '../common/Select';
import TextArea from '../common/TextArea';
import TextField from '../common/TextField';
import AdditionalInfoLink from './AdditionalInfoLinks';

/**
 * This provides a user interface for a service provider to enter the
 * basic version information when registering a new service or editing a
 * service registration:
 * - version number and URL
 * - protocol (if streaming service)
 * - additional links
 */
const WizardVersionCore = ({
    input,
    handleInputChange,
    handleInputChangeByNameAndValue,
    readOnly,
    isEditingRestricted,
    serviceType,
    formErrors,
    ...props
}) => {

    const urlLabel =
        checkServiceType(serviceType, ServiceTypeUtils.TYPES.STREAMING) ?
            'Stream Connection URL' : 'Target URL';

    return (
        <>
            <SectionHeader
                title="Service Version Information"
                labelStyle="margin-top-0"
            />

            {/* Mui has a rule
              *      .css-MuiStack-root >
              *        :not(style + :not(style) {
              *            margin-top: 24px;
              *        }
              * which adds excessive gaps.  This 'div' blocks that.
              */}
            <div>
                <TextField
                    id="version"
                    name="version"
                    label="Version (format: x.x.x)"
                    value={input.version}
                    required
                    disabled={readOnly || isEditingRestricted}
                    onChange={(e) => handleInputChange(e)}
                    errorMessage={formErrors?.serviceVersion}
                />

                {
                    // Show the URL field if it's not data transfer service
                    !checkServiceType(
                        serviceType,
                        ServiceTypeUtils.TYPES.DATA_TRANSFER
                    ) && (
                        <TextField
                            id="target-url"
                            name="target_url"
                            label={urlLabel}
                            value={input.target_url}
                            required
                            disabled={readOnly || isEditingRestricted}
                            onChange={(e) => handleInputChange(e)}
                            errorMessage={formErrors?.serviceUrl}
                            help="The full URL for your current API
                              outside of DIP.  The URL must be the
                              base URL for your API, without
                              additional resource paths."
                        />
                    )
                }

                {
                    // Show the protocol field if streaming service
                    checkServiceType(
                        serviceType,
                        ServiceTypeUtils.TYPES.STREAMING
                    ) && (
                        <>
                            <Select
                                id="service-protocol-select"
                                name="protocol"
                                label="Service Protocol"
                                value={input.protocol}
                                itemList={optionList}
                                onChange={handleInputChange}
                                disabled={isEditingRestricted || readOnly}
                            />
                            <TextField
                                id="service-identifier"
                                name="service_identifier"
                                label="Service Identifier"
                                value={input.service_identifier}
                                onChange={(e) => handleInputChange(e)}
                                disabled={readOnly || isEditingRestricted}
                                errorMessage={formErrors?.serviceIdentifier}
                                help="An optional parameter used
                                  to route the streaming
                                  service request to one of
                                  multiple streaming
                                  services sharing a common url"
                            />
                        </>
                    )
                }

                <TextArea
                    id="release-summary"
                    name="release_summary"
                    label="Release Notes"
                    value={input.release_summary}
                    inputStyle="height-8"
                    required
                    disabled={readOnly}
                    onChange={(e) => handleInputChange(e)}
                    errorMessage={formErrors?.releaseSummary}
                    help='If you are adding a new version to an
                          existing service, please provide
                          information about what has changed from
                          the previously released version.  If this
                          is the first version for a new service,
                          you may simply enter "Initial Release".'
                />

                <Checkbox
                    id="beta-mode"
                    label="Beta Mode"
                    checked={input.is_beta}
                    disabled={readOnly}
                    onChange={() => {
                        handleInputChangeByNameAndValue(
                            "is_beta",
                            !input.is_beta
                        );
                        document.getElementById('beta-mode').focus();
                    }}
                    wrapperStyle='margin-top-3'
                />
                <FieldHelp
                    id="beta-mode-help"
                    help="Beta Mode enables the service owner to privately test the service without exposing them to others."
                />

                <AdditionalInfoLink
                    input={input}
                    readOnly={readOnly}
                    handleInputChangeByNameAndValue={
                        handleInputChangeByNameAndValue
                    }
                />

                {props.children}
            </div>
        </>
    );
};

const optionList = Object.freeze([
    {
        id: 'socket-io',
        value: 'SOCKET_IO',
    }
]);

export default WizardVersionCore;
