import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { verifyServiceOwnership } from "../../apiClient";
import { setAlert } from "../../app/slices/alert";
import { selectCompanyId } from "../../app/slices/user";
import CircularProgressIndicator from "../../components/common/CircularProgressIndicator";
import { useAbortController } from "../../hooks";
import { getAdminGroupName, isCurrentUserInGroup } from "../../utils/auth";
import { URL_403 } from "../../utils/navigation";

/**
 * This component is a wrapper around the child components.
 * This checks if the user belongs to the provider company for the service.
 * Admin users bypass this check.
 */
export default function RequireServiceOwnership({ serviceId, children }) {
    const { abortSignalRef, isCancel } = useAbortController();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const companyId = useSelector(useCallback(selectCompanyId, []));

    const [isOwner, setIsOwner] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const init = async () => {
        // Bypass the check if admin user.
        const isAdmin = await isCurrentUserInGroup(getAdminGroupName());
        if (isAdmin) {
            setIsOwner(true);
            setIsLoading(false);
        } else {
            try {
                const res = await verifyServiceOwnership({
                    serviceId: serviceId,
                    providerId: companyId,
                    abortSignal: abortSignalRef?.current,
                });

                setIsOwner(res.data);
                if (!res.data) {
                    navigate(URL_403, { replace: true });
                }
            } catch (err) {
                if (isCancel(err)) return;

                console.error(
                    "Failed to verify service ownership:",
                    err.response ? err.response.data : err
                );
                dispatch(
                    setAlert({
                        show: true,
                        message: "Failed to verify service ownership",
                        severity: "error",
                    })
                );
            } finally {
                setIsLoading(false);
            }
        }
    };

    if (isLoading) {
        return <CircularProgressIndicator />;
    }

    if (!isOwner) {
        return null;
    }

    return children;
}
