import Stack from '@mui/material/Stack';

import ActionButton from '../common/ActionButton';
import SearchFilter from './SearchFilter';


/**
 * This provides the set of filter checkboxes and the "clear filters" button.
 */

function FilterSelection({ handleSearchFilterChange, handleClearFilters }) {

    return (
        <Stack direction='column' id='filter-controls-container'
            sx={{
                flex: '0 0 auto',
                marginRight: '1.5vw',
            }}
        >
            <ActionButton text='Clear Filters'
                id='clear-filters'
                onClick={() => handleClearFilters()}
            />

            <SearchFilter
                handleChange={handleSearchFilterChange}
            />
        </Stack>
    );
};

export default FilterSelection;
