import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isCancel } from "axios";
import { getPlatformReleases, getPlatformSummaryOfCapabilities, 
         getServiceNews, getServiceNewsSummary 
       } from '../../apiClient';

/**
 * State information for the Platform News and Service News
 */
export const initialState = {

    // ID of the interval timer for the next news check
    newsIntervalId: null,

    //
    // platform news, which is comprised of the summary of capabilities
    // and per-release news items
    //

    // true if the user has unread platform news
    unreadPlatformNews: false,

    // summary of capabilities
    platformSummaryOfCapabilities: [],

    // whether or not summary of capabilities are being fetched
    fetchingPlatformSummaryOfCapabilities: false,

    // news items per release
    platformReleases: [],

    // whether or not platform news items per release are being fetched
    fetchingPlatformReleases: false,

    //
    // service news
    //

    // true if the user has unread service news
    unreadServiceNews: false,

    // number of service providers
    numServiceProviders: 0,

    // number of registered services
    numRegisteredServices: 0,

    // whether or not service statistics are being fetched
    fetchingServiceNewsSummary: false,

    // news items
    serviceNews: [],

    // whether or not service news items are being fetched
    fetchingServiceNews: false,
}

export const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setNewsIntervalId: (state, action) => {
            state.newsIntervalId = action.payload;
        },
        setUnreadPlatformNews: (state, action) => {
            state.unreadPlatformNews = action.payload;
        },
        setUnreadServiceNews: (state, action) => {
            state.unreadServiceNews = action.payload;
        },
        setPlatformSummaryOfCapabilities: (state, action) => {
            const { summaryOfCapabilities } = action.payload;
            if (summaryOfCapabilities) {
                state.platformSummaryOfCapabilities = summaryOfCapabilities;
            }
        },
        setPlatformReleases: (state, action) => {
            const { releases } = action.payload;
            if (releases) {
                state.platformReleases = releases;
            }
        },
    },
    extraReducers: builder => {

      // extra reducers for fetching platform summary of capabilities
      builder
        .addCase(fetchPlatformSummaryOfCapabilities.pending, (state, action) => {
            state.fetchingPlatformSummaryOfCapabilities = true;
        })
        .addCase(fetchPlatformSummaryOfCapabilities.fulfilled, (state, action) => {
            state.platformSummaryOfCapabilities = action.payload.summary;
            state.fetchingPlatformSummaryOfCapabilities = false;
        })
        .addCase(fetchPlatformSummaryOfCapabilities.rejected, (state, action) => {
            state.fetchingPlatformSummaryOfCapabilities = false;
        });

      // extra reducers for fetching platform releases
      builder
        .addCase(fetchPlatformReleases.pending, (state, action) => {
            state.fetchingPlatformReleases = true;
        })
        .addCase(fetchPlatformReleases.fulfilled, (state, action) => {
            state.platformReleases = action.payload.releases;
            state.fetchingPlatformReleases = false;
        })
        .addCase(fetchPlatformReleases.rejected, (state, action) => {
            state.fetchingPlatformReleases = false;
        });

      // extra reducers for fetching service news
      builder
        .addCase(fetchServiceNews.pending, (state, action) => {
            state.fetchingServiceNews = true;
        })
        .addCase(fetchServiceNews.fulfilled, (state, action) => {
            state.serviceNews = action.payload.service_news;
            state.fetchingServiceNews = false;
        })
        .addCase(fetchServiceNews.rejected, (state, action) => {
            state.fetchingServiceNews = false;
        });

      // extra reducers for fetching service summary statistics
      builder
        .addCase(fetchServiceNewsSummary.pending, (state, action) => {
            state.fetchingServiceNewsSummary = true;
        })
        .addCase(fetchServiceNewsSummary.fulfilled, (state, action) => {
            state.numRegisteredServices = action.payload.num_registered_services;
            state.numServiceProviders = action.payload.num_service_providers;
            state.fetchingServiceNewsSummary = false;
        })
        .addCase(fetchServiceNewsSummary.rejected, (state, action) => {
            state.fetchingServiceNewsSummary = false;
        });
    }
});

// Sends a GET request to the platform summary of capabilities API and 
// returns a Promise for the caller to handle
export const fetchPlatformSummaryOfCapabilities = createAsyncThunk(
    'platformNews/fetchPlatformSummaryOfCapabilities',
    async (payload, { rejectWithValue }) => {
        try {
            // cancel the previous request
            if (payload?.abort) payload.abort();

            const res = await getPlatformSummaryOfCapabilities({ 
                abortSignal: payload?.abortSignalRef?.current 
            });
            return res.data;
        }
        catch (error) {
            if (isCancel(error))
                return rejectWithValue('cancelled');

            console.error('Failed to get service news summary', error.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// Sends a GET request to the platform releases API and 
// returns a Promise for the caller to handle
export const fetchPlatformReleases = createAsyncThunk(
    'platformNews/fetchPlatformReleases',
    async (payload, { rejectWithValue }) => {
        try {
            // cancel the previous request
            if (payload?.abort) payload.abort();

            const res = await getPlatformReleases({ 
                abortSignal: payload?.abortSignalRef?.current 
            });
            return res.data;
        }
        catch (error) {
            if (isCancel(error))
                return rejectWithValue('cancelled');

            console.error('Failed to get service news summary', error.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// Sends a GET request to the service news summary API and returns a Promise for
// the caller to handle
export const fetchServiceNewsSummary = createAsyncThunk(
    'serviceNews/fetchServiceNewsSummary',
    async (payload, { rejectWithValue }) => {
        try {
            // cancel the previous request
            if (payload?.abort) payload.abort();

            const res = await getServiceNewsSummary({ 
                abortSignal: payload?.abortSignalRef?.current 
            });
            return res.data;
        }
        catch (error) {
            if (isCancel(error))
                return rejectWithValue('cancelled');

            console.error('Failed to get service news summary', error.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// Sends a GET request to the service news API and returns a Promise for
// the caller to handle
export const fetchServiceNews = createAsyncThunk(
    'serviceNews/fetchServiceNews',
    async (payload, { rejectWithValue }) => {
        try {
            // cancel the previous request
            if (payload?.abort) payload.abort();

            const res = await getServiceNews({ 
                abortSignal: payload?.abortSignalRef?.current 
            });
            return res.data;
        }
        catch (error) {
            if (isCancel(error))
                return rejectWithValue('cancelled');

            console.error('Failed to get service news summary', error.message);
            return rejectWithValue(error.response.data);
        }
    }
);

// Action creators are generated for each case reducer function
export const {
    setNewsIntervalId,
    setUnreadPlatformNews,
    setPlatformSummaryOfCapabilities,
    setPlatformReleases,
    setUnreadServiceNews,
} = newsSlice.actions

export const selectNewsIntervalId = state => state.news.newsIntervalId;

export const selectUnreadPlatformNews = state => state.news.unreadPlatformNews;
export const selectPlatformSummaryOfCapabilities = state => state.news.platformSummaryOfCapabilities;
export const selectFetchingPlatformSummaryOfCapabilities = state => state.news.fetchingPlatformSummaryOfCapabilities;
export const selectPlatformReleases = state => state.news.platformReleases;
export const selectFetchingPlatformReleases = state => state.news.fetchingPlatformReleases;

export const selectUnreadServiceNews = state => state.news.unreadServiceNews;
export const selectNumServiceProviders = state => state.news.numServiceProviders;
export const selectNumRegisteredServices = state => state.news.numRegisteredServices;
export const selectServiceNews = state => state.news.serviceNews;
export const selectFetchingServiceNews = state => state.news.fetchingServiceNews;
export const selectFetchingServiceNewsSummary = state => state.news.fetchingServiceNewsSummary;

export default newsSlice.reducer
