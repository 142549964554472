import Accordion from "../common/Accordion";
import BodyText from "../common/BodyText";

/**
 * This provides the user with a definition of each of the Quality of Service
 * metrics.  This information is presented in a table and is viewable in a
 * service version registration page.
 */
const QualityOfServiceHelp = () => {
    return (
        <Accordion
            id='quality-of-service-glossary'
            title='Quality of Service Glossary'
            aria-label='Quality of Service Glossary'
            itemList={[
                <div key='glossary-table'>
                    <BodyText labelStyle='margin-top-0 margin-bottom-1'
                        label='Quality of Service metrics are based on ISO 25010
                            characteristics that relate to the performance and
                            reliability of the service. These metrics are
                            documented by the service provider. Below are the
                            descriptions of the proposed metrics. Service
                            providers can also specify other metrics.  Metrics
                            are entered by accessing Quality of Service Metrics,
                            below.'
                    />
                    <table className='usa-table usa-table--striped usa-table--borderless' >
                        <thead>
                            <tr>
                                <th scope='col' className='text-white margin-y-0 bg-primary text-white text-bold' >
                                    QoS Metric
                                </th>
                                <th scope='col' className='text-white margin-y-0 bg-primary text-white text-bold' >
                                    Description
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                qosMetrics.map((row) => (
                                    <tr key={row.metric} >
                                        <td className='border-0' >
                                            {row.metric}
                                        </td>
                                        <td  className='border-0' >
                                            {row.description}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            ]}
        />
    );
};

const qosMetrics = [
    {
        metric: 'Availability',
        description: 'The degree to which a service is expected to ' +
            'function correctly and be accessible. Availability represents ' +
            'the probability that a service is ready and available ' +
            'immediately when invoked. Measurements could be estimated or ' +
            'observed. Some common ways of stating availability is "a ' +
            'service is available X hours out of 24 hours" (e.g. 16 hours ' +
            'out of 24 hours) or "a service has X nines availability" ' +
            '(e.g. one nine or 90%, three nines or 99.9%).'
    },
    {
        metric: 'Bandwidth',
        description: 'The capacity of the service expressed in terms of ' +
            'maximum bandwidth for a given period of time (such as a ' +
            'billing period). Measurements could be expressed in bytes.'
    },
    {
        metric: 'Latency',
        description: 'A measurement of the processing time of a service in ' +
            'terms of latency (delay) to receive a response, which does ' +
            'not include the time for the service to process the request ' +
            '(response time). For streaming services, it equals the delay to ' +
            'send data to the client. Measurements could be a statistical ' +
            'value (such as min, mean, 95th percentile, max) and should ' +
            'specify how elapsed time was bounded (start and end points).'
    },
    {
        metric: 'Recoverability',
        description: 'The degree to which, in the event of an interruption ' +
            'or a failure, the desired state of the service can be restored. ' +
            'The recoverability measure could take into account the time to ' +
            'bring back the service, such as time to bring the ' +
            'infrastructure up, restore the data, and restore the service.'
    },
    {
        metric: 'Request Limit',
        description: 'The capacity of the service expressed in terms of ' +
            'maximum allowed number of requests for a given period of time ' +
            '(such as a billing period). Measurements could include the ' +
            'number of concurrent users or quota.'
    },
    {
        metric: 'Response Time',
        description: 'A measurement of the processing time of a service, ' +
            'in terms of elapsed time it takes to send a response upon ' +
            'receiving a request. Measurements could be a statistical value ' +
            '(such as min, mean, 95th percentile, max) and should specify ' +
            'how elapsed time was bounded (start and end points).'
    }
]

export default QualityOfServiceHelp
