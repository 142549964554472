import NavList from './NavList';

/**
 * This holds the drop-down list of items for a menu.  The display of this
 * menu is controlled by a NavDropDownButton.  This menu basically is just
 * a pass-through to NavList, but it helped make the calling code a little
 * more intuitive to understand.
 *
 * @param {string}        id         unique identifier for this menu
 * @param {string}        type       type of menu: 'primary' for the main
 *                                   menu bar or 'subnav' for a sub-menu
 * @param {array(object)} items      array of sub-menu items
 * @param {boolean}       isOpen     true if the menu is open
 */
const NavDropDownMenu = ({ id, type, items, isOpen }) => {

    return (
        <NavList
            id={id}
            items={items}
            type={type}
            isOpen={isOpen}
        />
    );
};

export default NavDropDownMenu;
