import { Auth } from "aws-amplify";
import axios from 'axios';

/**
 * @returns Whether or not the user is authenticated.
 */
export const isUserAuthenticated = async () => {
    try {
        await Auth.currentAuthenticatedUser();
        return true;
    } catch (error) {
        console.log('User is not authenticated');
        return false;
    }
};

/**
 * Get the current authenticated user object
 * @returns CognitoUser 
 */
export const getCurrentAuthenticatedUser = async () => {
    try {
        const userInfo = await Auth.currentAuthenticatedUser();
        return userInfo;
    } catch (error) {
        console.error('Failed to get current user info', error);
        return null;
    }
};

/**
 * @param userGroupName the name of the AWS Cognito user pool group
 * @returns Whether or not the current user is in the given group.
 */
export const isCurrentUserInGroup = async (userGroupName) => {
    try {
        const userInfo = await getCurrentAuthenticatedUser();
        return userInfo
            .signInUserSession
            .accessToken
            .payload['cognito:groups']
            .includes(userGroupName);
    } catch (error) {
        console.error(`Faild to check if the current user is in ${userGroupName} group`, error);
        return false;
    }
};

export const getAdminGroupName = () => {
    return process.env.REACT_APP_CATALOG_SERVICE_AUTH_AWS_COGNITO_USER_POOL_ADMIN_GROUP;
};

/**
 * @returns Whether or not the user has the ISA verification token
 * in the access/id token of the current session
 */
export const hasISAVerificationToken = async () => {
    try {
        return !!(await getIsaVerificationToken());
    } catch (error) {
        return false;
    }
};

/**
 * @returns Isa Verification Token (IVT) in the access/id token if exists. 
 *          Otherwise, return null.
 */
export const getIsaVerificationToken = async () => {
    try {
        const session = await Auth.currentSession();
        return session.accessToken.payload.dip_ivt || session.idToken.payload.dip_ivt;
    } catch (error) {
        console.log('error getting IVT', error);
        return null;
    }
};

/**
 * @returns the Company ID the user is associated with in the access/id token 
 *          if exists. Otherwise, return null.
 */
export const getUserCompanyId = async () => {
    try {
        const session = await Auth.currentSession();
        const companyId = session.accessToken.payload.company_id || session.idToken.payload.company_id;
        return companyId;
    } catch (error) {
        console.log('Error getting company id from token', error);
        return null;
    }
};

/**
 * @returns Company roles in the access/id token if exists. 
 *          Otherwise, return an empty array.
 */
export const getCompanyRolesFromToken = async () => {
    try {
        const session = await Auth.currentSession();
        return JSON.parse(session.accessToken.payload.company_roles || session.idToken.payload.company_roles) || [];
    } catch (error) {
        console.log('Error getting company roles from token', error);
        return [];
    }
};

/**
 * @returns Whether or not the session token has been refreshed.
 */
export const refreshSessionToken = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        return user ? true : false;
    } catch (error) {
        console.error('Failed to refresh session token:', error);
        return false;
    }
};

/**
 * Get token from the Cognito Token endpoint
 * @param {string} cognitoTokenEndpoint Cognito Token Endpoint
 * @param {string} clientId Cognito App Client Id
 * @param {string} clientSecret Cognito App Client Secret
 * @returns {object|null} token object
 */
export const getAccessTokenFromCognito = async (cognitoTokenEndpoint, clientId, clientSecret) => {
    let token = null;

    let params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');
    params.append('client_id', clientId);
    let auth = {
        username: clientId,
        password: clientSecret
    };
    let res = await axios.post(
        cognitoTokenEndpoint,
        params,
        { auth }
    );

    if (res && res.status === 200) {
        token = res.data;
    } else {
        console.error('Failed to get access token from token endpoint: ', res);
    }

    return token;
};


/**
 * Get token from the Cognito Token endpoint and create the authorization headers.
 * @param {string} cognitoTokenEndpoint Cognito Token Endpoint
 * @param {string} clientId Cognito App Client Id
 * @param {string} clientSecret Cognito App Client Secret
 * @param {string} apiKey API Key
 * @returns {any[]} Authorization headers
 */
export const getAuthorizationHeaders = async (cognitoTokenEndpoint, clientId, clientSecret, apiKey) => {
    let headers = {};
    let token = await getAccessTokenFromCognito(cognitoTokenEndpoint, clientId, clientSecret);
    if (token) {
        headers = {
            'x-api-key': apiKey,
            Authorization: `${token['token_type']} ${token['access_token']}`
        }
    }

    return headers;
}

/**
 * @returns {any[]} Authorization headers for catalog service
 */
export const getCatalogServiceAuthorizationHeaders = async () => {
    const session = await Auth.currentSession();
    // use id token instead of access token since the API method for catalog 
    // service is not set up with the OAuth Scopes.
    let token = session?.idToken?.jwtToken;
    let headers = {};
    if (token) {
        headers = {
            Authorization: `Bearer ${token}`
        }
    }

    return headers;
}
