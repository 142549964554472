import { Box, Card, CardContent, Grid } from "@mui/material";

import { useCallback, useEffect, useState } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch } from "react-redux";

import dragHandle from '@uswds/uswds/src/img/usa-icons/drag_handle.svg';

import { getPlatformSummaryOfCapabilities, reorderPlatformSummaryCapabilities 
       } from "../../../apiClient";
import { setAlert } from "../../../app/slices/alert";
import { setLinks as setBreadcrumbs } from '../../../app/slices/breadcrumbs';
import { setPageTitle } from '../../../app/slices/page';
import BodyText from "../../../components/common/BodyText";
import DraggableItem from "../../../components/common/DraggableItem";
import LinkButton from "../../../components/common/LinkButton";
import MajorHeading from "../../../components/common/MajorHeading";
import MinorHeading from "../../../components/common/MinorHeading";
import { useAbortController } from "../../../hooks";
import { getSummaryCapabilityPage, PLATFORM_NEWS_PAGE_ACTION, PLATFORM_SUMMARY_TITLE 
       } from "../../../utils/platformNews";
import { URL_ADMIN, URL_ADMIN_PLATFORM_NEWS, URL_HOME } from "../../../utils/navigation";

/**
 * This component displays the summary of capabilities and allows the user
 * to reorder them by dragging or to click a button to edit/delete them.
 * There is a also a button to create a new summary capability.
 */
export default function PlatformSummaryCapabilityList() {
    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();

    const [loadingSummary, setLoadingSummary] = useState(false);
    const [orderUpdated, setOrderUpdated] = useState(false);

    const [platformSummary, setPlatformSummary] = useState();

    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));
        dispatch(setPageTitle('Platform Summary Administration'));

        setLoadingSummary(true);

        getPlatformSummaryOfCapabilities({ abortSignal: abortSignalRef?.current })
        .then((res) => {
            setPlatformSummary(res.data.summary);
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to get platform summary', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get platform summary',
                severity: 'error'
            }));
        })
        .finally(() => setLoadingSummary(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // update the order of the list when the drag ends
    const handleDragEnd = () => {
        if (orderUpdated === true) {
            setLoadingSummary(true);
            let orders = platformSummary.map(({ id }, order) => 
                    ({ id, order }));
            reorderPlatformSummaryCapabilities({
                body: { orders }, abortsignal: abortSignalRef?.current
            })
            .then(() => {
                dispatch(setAlert({
                    show: true,
                    message: 'Successfully reordered the platform summary of capabilities',
                    severity: 'success'
                }));
            })
            .catch(err => {
                if (isCancel(err))
                    return;

                console.error('failed to reorder the platform summary of capabilities',
                    err.message);
                dispatch(setAlert({
                    show: true,
                    message: 'Failed to reorder the platform summary of capabilities',
                    severity: 'error'
                }));
            })
            .finally(() => {
                setLoadingSummary(false);
                setOrderUpdated(false);
            })
        }
    };

    // Actual logic to move the item in the list
    const movePlatformSummaryCapability = useCallback((dragIndex, hoverIndex) => {
        setOrderUpdated(true);
        setPlatformSummary((prevCapabilities) => {
            let rtn = [...prevCapabilities];
            rtn.splice(dragIndex, 1);
            rtn.splice(hoverIndex, 0, prevCapabilities[dragIndex])
            return rtn;
        });
    }, [])

    const renderCard = ({ id, description }, index) => {
        return (
            <DraggableItem
                key={id}
                index={index}
                id={id}
                itemType='PlatformSummaryCapabilityItem'
                moveItem={movePlatformSummaryCapability}
                handleDragEnd={handleDragEnd}
            >
                <Grid container item key={id} sx={{ alignItems: 'center' }}>
                    <Grid item xs={8} >
                        <BodyText 
                            label={description} 
                            labelStyle='margin-top-0'
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'end'
                        }}
                    >
                        <LinkButton text='View'
                            href={getSummaryCapabilityPage([`summaryId=${id}`, `action=${PLATFORM_NEWS_PAGE_ACTION.view}`])}
                            style={{ marginRight: '1rem' }}
                        />
                        <LinkButton text='Edit'
                            href={getSummaryCapabilityPage([`summaryId=${id}`, `action=${PLATFORM_NEWS_PAGE_ACTION.update}`])}
                            style={{ marginRight: '1rem' }}
                        />
                        <LinkButton text='Delete' isDelete
                            href={getSummaryCapabilityPage([`summaryId=${id}`, `action=${PLATFORM_NEWS_PAGE_ACTION.delete}`])}
                        />
                    </Grid>
                </Grid>
            </DraggableItem>
        )
    };

    return (
        <Card id='admin-summary-card' sx={{ p: '1rem' }} >
            <CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <MajorHeading label={PLATFORM_SUMMARY_TITLE} loading={loadingSummary} />
                    <div>
                        <LinkButton text='Create a new capability'
                            href={getSummaryCapabilityPage([`action=${PLATFORM_NEWS_PAGE_ACTION.create}`])}
                        />
                    </div>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        borderBottomWidth: '2px',
                        borderBottomColor: 'secondary',
                        borderBottomStyle: 'solid',
                        marginBottom: '1rem'
                    }}
                >
                    <img
                        src={dragHandle}
                        alt='drag a summary capability to reorder it'
                        className='margin-right-1'
                    />
                    <Grid container>
                        <Grid item xs={12}>
                            <MinorHeading label='Description' />
                        </Grid>
                    </Grid>
                </Box>

                <Box>
                    <DndProvider backend={HTML5Backend}>
                        {
                            platformSummary && platformSummary.map((item, idx) => renderCard(item, idx))
                        }
                    </DndProvider>
                </Box>

            </CardContent>
        </Card >
    );
}

// Breadcrumbs are only available for the Admin
const breadcrumbs = [
    { name: 'Home', href: URL_HOME },
    { name: 'Admin', href: URL_ADMIN },
    { name: 'Platform News', href: URL_ADMIN_PLATFORM_NEWS },
    { name: 'Platform Summary of Capabilities', href: null }
];
