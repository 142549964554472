import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { useState } from 'react';

import { ALL_ITEMS } from '../../utils/utils';

/**
 * This provides the components to navigate through the rows of a data table
 * when not all rows are automatically displayed.  This component consists of
 * three pieces:
 * - on the left, a label stating which rows are being displayed
 * - in the middle, a selector for the number of rows to display
 * - on the right, a sequence of buttons to navigate through the rows.
 *   For this set of buttons,
 *   - the first and last page always have a button
 *   - the current page, the page before it, and the page after it always 
 *     have a button (if the current page is not the first or last page)
 *   - there are a maximum of 7 page navigation buttons, excluding Previous/Next
 *   - if there are more pages required than number of buttons, then an
 *     "overflow" (...) indicator will be displayed.
 *   - the Previous button is NOT shown when the user is on the first page
 *   - the Next button is NOT shown when the user is on the last page
 * Examples of the row navigation, where the angle brackets show the
 * current page:
 *     <1> [2] [2] [Next]
 *     [Previous] [1] <2> [3] [Next]
 *     [Previous] [1] [2] <3> 
 *     <1> [2] [3] [4] [5] [...] [8] [Next]
 *     [Previous] [1] [2] [3] <4> [5] [...] [8] [Next]
 *     [Previous] [1] [...] [4] <5> [6] [7] [8] [Next]
 *     [Previous] [1] [...] [4] [5] [6] [7] <8> 
 * This code was adapted from:
 * https://github.com/trussworks/react-uswds/blob/main/src/components/Pagination/Pagination.tsx
 * https://trussworks.github.io/react-uswds/?path=/story/components-pagination--default
 * which in turn is based on USWDS principles described at: 
 * https://designsystem.digital.gov/components/pagination/
 *
 * @param {number}   initialRowsPerPage   current number of rows displayed
 * @param {number}   page                 current page number, 0-based
 * @param {function} pageCallback         function to call when the user
 *                                        changes the page of data to display by
 *                                        clicking a page number button or the
 *                                        Previous/Next button.  This function
 *                                        should expect to receive the new
 *                                        0-based page number
 * @param {function} rowsPerPageCallback  function to call when the user
 *                                        changes the number of rows to display
 * @param {number}   numRows              total number of rows of data
 * @param {string}   units                units to display in the label;
 *                                        default is "Rows"
 */
const Pagination = ({ 
    initialRowsPerPage,
    page,  // 0-based
    pageCallback, 
    rowsPerPageCallback, 
    numRows=0,
    units='Rows',
    ...props
}) => {

    // Make sure the rows per page is one of our row options
    const [rowsPerPage, setRowsPerPage] = useState(
                               validateRowsPerPage(initialRowsPerPage));

    // In this component, page numbers are 1-based
    const currentPage = page + 1;

    // If rowsPerPage is "all", set the number of rows per page to be the
    // actual number of rows
    let rowsPerPageRevised = rowsPerPage;
    if ((rowsPerPage === ALL_ITEMS) && (numRows !== 0)) {
        rowsPerPageRevised = numRows;
    }

    const totalPages = Math.ceil(numRows / rowsPerPageRevised) || 1;

    const isOnFirstPage = currentPage === 1;
    const isOnLastPage = currentPage === totalPages;
    const isBeforeMiddleSlot = (totalPages - currentPage >= MIDDLE_SLOT);

    // If more pages than slots, use overflow indicators (...)
    const showOverflow = totalPages > MAX_SLOTS;
    const showPrevOverflow = showOverflow && currentPage > MIDDLE_SLOT;
    const showNextOverflow = showOverflow && isBeforeMiddleSlot;

    // Assemble an array of page number buttons to be shown.
    // If there's no overflow, it's a simple array of the page numbers.
    // Otherwise, initialize it only with the current page number -- the rest 
    // of the array will be filled in below
    const currentPageRange = showOverflow ? [currentPage] :
            Array.from({ length: totalPages }).map((_, i) => i + 1);

    // Determine the range of pages to show based on current page and the
    // number of slots.  
    if (showOverflow) {
        // First set the required minimum of slots/buttons before and after
        // the current page.
        // If showing Previous overflow, 2 = first_page + prev_overflow (...)
        // Otherwise, 1 = first_page
        const minSlotsBeforeCurrent = 
            isOnFirstPage ? 0 : showPrevOverflow ? 2 : 1;
        // If showing Next overflow, 2 = next_overflow (...) + last_page
        // Otherwise, 1 = last_page
        const minSlotsAfterCurrent = 
            isOnLastPage ? 0 : showNextOverflow ? 2 : 1;

        // Remaining slots to show (minus one for the current page)
        const remainingSlots = 
            MAX_SLOTS - 1 - (minSlotsBeforeCurrent + minSlotsAfterCurrent);

        // Determine how many more slots we have before/after the current page.
        // Note this count does NOT include the first/last page and any overflow
        let numSlotsBeforeCurrent = 0;
        let numSlotsAfterCurrent = 0;
        if (showPrevOverflow && showNextOverflow) {
            // We are in the middle of the set, there will be overflow (...) at
            // both the beginning and the end
            // Ex: [1] [...] [9] [10] [11] [...] [24]
            numSlotsBeforeCurrent = Math.round((remainingSlots - 1) / 2);
            numSlotsAfterCurrent = remainingSlots - numSlotsBeforeCurrent;
        } else if (showPrevOverflow) {
            // We are in the end of the set, there will be overflow (...) at
            // the beginning
            // Ex: [1] [...] [20] [21] [22] [23] [24]
            // Minus 1 is for last page
            numSlotsAfterCurrent = totalPages - currentPage - 1;
            numSlotsAfterCurrent = 
                numSlotsAfterCurrent < 0 ?  0 : numSlotsAfterCurrent;
            numSlotsBeforeCurrent = remainingSlots - numSlotsAfterCurrent;
        } else if (showNextOverflow) {
            // We are in the beginning of the set, there will be overflow (...)
            // at the end
            // Ex: [1] [2] [3] [4] [5] [...] [24]
            // Minus 2 is for first page and current page
            numSlotsBeforeCurrent = currentPage - 2;
            numSlotsBeforeCurrent =
                numSlotsBeforeCurrent < 0 ? 0 : numSlotsBeforeCurrent;
            numSlotsAfterCurrent = remainingSlots - numSlotsBeforeCurrent;
        }

        // Populate the remaining slots with the page numbers
        let counter = 1
        while (numSlotsBeforeCurrent > 0) {
            // Add previous pages before the current page
            currentPageRange.unshift(currentPage - counter);
            counter++;
            numSlotsBeforeCurrent--;
        }
        counter = 1
        while (numSlotsAfterCurrent > 0) {
            // Add subsequent pages after the current page
            currentPageRange.push(currentPage + counter);
            counter++;
            numSlotsAfterCurrent--;
        }

        // Add prev/next overflow indicators and first/last pages as needed
        if (showPrevOverflow) {
            currentPageRange.unshift('overflow')
        }
        if (currentPage !== 1) {
            currentPageRange.unshift(1)
        }
        if (showNextOverflow) {
            currentPageRange.push('overflow')
        }
        if (currentPage !== totalPages) {
            currentPageRange.push(totalPages)
        }
    }

    const prevPage = !isOnFirstPage;
    const nextPage = !isOnLastPage;

    const firstRow = numRows ? ((currentPage - 1) * rowsPerPageRevised + 1) : 0;
    const lastRow = (isOnLastPage? numRows : currentPage * rowsPerPageRevised);

    const handleChangeRowsPerPage = (event) => {
        let rows = parseInt(event.target.value, 10);
        setRowsPerPage(rows);
        rowsPerPageCallback(rows);
    };

    return (
        <div 
            style={{ 
                width: '100%', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between' 
            }}
        >
            <div className='usa-label margin-top-0 dip-pagination__text margin-left-2' >
                Showing {firstRow} - {lastRow} of {numRows}
            </div>
            
            <SelectRowsPerPage 
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                units={units}
            />
            
            <nav
                aria-label="Pagination" 
                className='usa-pagination margin-right-2'
            >
                <ul className="usa-pagination__list">
                    { prevPage && (
                        <li className="usa-pagination__item usa-pagination__arrow">
                                <Button
                                    className="usa-pagination__link usa-pagination__previous-page"
                                    aria-label="Previous page"
                                    data-testid="pagination-previous"
                                    onClick={() => pageCallback(currentPage - 2)}
                                >
                                    <NavigateBeforeIcon alt='previous page' />
                                    <span className="usa-pagination__link-text">
                                        Previous
                                    </span>
                                </Button>
                        </li>
                    )}
    
                    {currentPageRange.map((pageNum, i) =>
                        pageNum === 'overflow' ? (
                            <PaginationOverflow key={`pagination_overflow_${i}`} />
                        ) : (
                            <PaginationPage
                                key={`pagination_page_${pageNum}`}
                                page={pageNum}
                                isCurrent={pageNum === currentPage}
                                setPageFromPagination={pageCallback}
                            />
                        )
                    )}
    
                    {nextPage && (
                        <li className="usa-pagination__item usa-pagination__arrow">
                            <Button
                                className="usa-pagination__link usa-pagination__next-page"
                                aria-label="Next page"
                                data-testid="pagination-next"
                                onClick={() => pageCallback(currentPage)}
                            >
                                <span className="usa-pagination__link-text">
                                    Next
                                </span>
                                <NavigateNextIcon alt='next page' />
                            </Button>
                        </li>
                    )}
                </ul>
            </nav>
        </div>
    );
};

// Validates the number of rows per page to ensure that it is one of the
// options in our rows-per-page selector.  If it is not, then the 
// rows per page value is set to all rows.
const validateRowsPerPage = (origRowsPerPage) => {
    let rowsPerPage = ALL_ITEMS;
    const found = ROW_OPTIONS.find(option => origRowsPerPage === option.value);
    if (!!found) {
        rowsPerPage = found.value;
    }
    return rowsPerPage;
};

// This component displays the selector for the number of units (e.g. rows)
// per page.
const SelectRowsPerPage = ({ rowsPerPage, handleChangeRowsPerPage, units }) => (
    <div style={{ display: 'flex', alignItems: 'center' }} >
        <label htmlFor='rpp-select' 
            className='usa-label margin-top-0 dip-pagination__text'
            style={{ textWrap: 'nowrap' }}
        >
            {units} per page:&nbsp;
        </label>
        <select id='rpp-select' 
            name='rows-per-page'
            className='usa-select margin-top-0'
            value={rowsPerPage}
            onChange={(e) => handleChangeRowsPerPage(e)}
        >
            {
                ROW_OPTIONS.map(option => {
                    return (
                        <option
                            id={option.id}
                            key={option.id}
                            value={option.value}
                        >
                            {option.label}
                        </option>
                    )
                })
            }
        </select>
    </div>
);

// This component is a single "page <N>" button.
const PaginationPage = ({
    page,
    isCurrent,
    setPageFromPagination,
}) => {
    const pageClasses = 
        'usa-pagination__button' + (isCurrent ? ' usa-current': '');

    return (
        <li key={`pagination_page_${page}`}
            className="usa-pagination__item usa-pagination__page-no"
        >
            <Button
                data-testid="pagination-page-number"
                className={pageClasses}
                aria-label={`Page ${page}`}
                aria-current={isCurrent ? 'page' : undefined}
                onClick={() => setPageFromPagination(page - 1)}
            >
                {page}
            </Button>
        </li>
    );
};

// This component is the overflow (...) display when there are more pages
// than button slots
const PaginationOverflow = () => (
    <li
        className="usa-pagination__item usa-pagination__overflow"
        role="presentation">
        <span>…</span>
    </li>
);

// This is the baseline button used for the page numbers and Previous/Next
// buttons
const Button = ({
    onClick,
    addClassName,
    children,
    ...defaultProps
}) => {

    const classes = 'pagination-button usa-button usa-button--unstyled ' + 
            (!!addClassName ? addClassName : ''); 

    return (
        <button
            type='button'
            className={classes}
            onClick={onClick}
            {...defaultProps}
        >
            {children}
        </button>
    )
}

// The number of slots for page navigation buttons, excluding the
// Previous and Next buttons.  The number 7 is a USWDS guideline.
const MAX_SLOTS = 7;
const MIDDLE_SLOT = 4;

// The allowed options of rows per page for the selector
const ROW_OPTIONS = [
    {
        id: '5-rows',
        label: '5',
        value: 5,
    },
    {
        id: '10-rows',
        label: '10',
        value: 10,
    },
    {
        id: '25-rows',
        label: '25',
        value: 25,
    },
    {
        id: 'all-rows',
        label: 'All',
        value: ALL_ITEMS,
    }
];

export default Pagination;
