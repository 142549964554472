import { isInvalidTextField } from "./validation";

export const ACCESS_ROLE_PAGE_ACTION = {
    create: 'create',
    view: 'view',
    delete: 'delete',
    update: 'update'
}

export const validateAccessRole = (input) => {
    let errors = {}

    errors.role = isInvalidTextField(input?.role, 1, 64, ' ,.-+@');
    errors.definition = isInvalidTextField(input?.definition, 1, 128, ' ,.-+@');

    return errors;
}