import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { createDataTransferDestination, createDataTransferSubscription,
         deleteDataTransferDestination, getTransferDestinationList
       } from '../../apiClient';
import { setAlert } from '../../app/slices/alert';
import { useAbortController } from '../../hooks';
import ActionButton from '../common/ActionButton';
import CircularProgressIndicator from '../common/CircularProgressIndicator';
import BodyText from '../common/BodyText';
import FieldHelp from '../common/FieldHelp';
import LabelValueTable from '../common/LabelValueTable';
import MinorHeading from '../common/MinorHeading';
import OkCancelDialog from '../common/OkCancelDialog';
import Select, { SELECT_ELEMENT } from '../common/Select';
import TextField from '../common/TextField';
import Modal from '../common/modal/Modal';
import ModalFooter from '../common/modal/ModalFooter';
import { getCurrentAuthenticatedUser } from '../../utils/auth';
import { DataTransferSubscriptionUtils, validateDataTransferDestination
       } from '../../utils/dataTransfer';
import { CANCEL_DELETE_TEXT, OK_DELETE_TEXT } from '../../utils/utils';
import { isErrorObjectEmpty } from '../../utils/validation';

/**
 * This provides the dialog for setting up a data transfer destination.  It is
 * accessed by clicking the "subscribe" button for a data transfer service on
 * the service detail page.
 */
const DataTransferDestinationDialog = ({
    openDialog,
    handleCloseDialog,
    companyId,
    transferSourceId,
    handleServiceSubscription, // this method is to handle the subscription to the service, not the destination
    dialogRef,
}) => {

    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();

    const [destinations, setDestinations] = useState([]);
    const [optionList, setOptionList] = useState([]);
    const [selectedExistingDest, setSelectedExistingDest] = useState(null);
    const [createInput, setCreateInput] = useState({});
    const [tab, setTab] = useState(0);
    const [formErrors, setFormErrors] = useState({});
    const [currentUsername, setCurrentUsername] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const confirmDeleteRef = useRef(null);

    const formRef = useRef(null);

    useEffect(() => {
        initDestinatinList();
        initCreateInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initDestinatinList = async () => {
        try {
            let res = await getTransferDestinationList({
                provider_id: companyId, abortSignal: abortSignalRef?.current })
            let destList = res?.data?.transfer_destinations ?? [];
            setDestinations(destList);

            // Put together the list of options for the destination selection
            const options = destList.map((dest) => {
                return ({ id: makeId(dest), value: dest.name });
            });
            setOptionList([SELECT_ELEMENT, ...options]);

        } catch (err) {
            if (isCancel(err))
                return;

            console.error(`Failed to get destinations for provider ${companyId}:`, err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get transfer destinations list',
                severity: 'error'
            }));
        }
    };

    const initCreateInput = async () => {
        let { username } = await getCurrentAuthenticatedUser()
        setCurrentUsername(username);
        setCreateInput({
            ...createInputInitialState,
            provider_id: companyId,
            created_by: username,
            last_updated_by: username
        });
    }

    const handleCreateInputChange = ({ target }) => {
        setCreateInput({
            ...createInput,
            [target.name]: target.value
        });
    }

    // Factory method that creates a unique id for a destination.
    // The destination id by itself is just an integer, which is ambiguous
    const makeId = (destination) => {
        return `dest-${destination.id}`;
    }

    // Handles a destionation being selected from the drop-down.
    // This finds the destination based on its name
    const handleSelectDestination = (e) => {
        let dest = destinations.find(d => e.target.value === d.name);
        setSelectedExistingDest(dest);
    }

    const canSubscribe = () => {
        let valid = false;
        switch (tab) {
            case 0:
                // Select from the existing destinations
                valid = !!selectedExistingDest &&
                            Object.keys(selectedExistingDest).length > 0;
                break;
            case 1:
                // Create and subscribe to new destination
                valid = formRef.current.reportValidity();
                let dtdErrors = validateDataTransferDestination(createInput);
                setFormErrors(dtdErrors);
                valid = valid && isErrorObjectEmpty(dtdErrors);
                break;
            default:
                console.error(`Invalid tab value: ${tab}`);
        }
        return valid;
    }

    const createSubscriptionRequest = (destinationId) => {
        return {
            source_id: transferSourceId,
            dest_id: destinationId,
            status: DataTransferSubscriptionUtils.STATUSES.CREATED,
            created_by: currentUsername,
            last_updated_by: currentUsername
        };
    }

    const handleSubscribeBtnClick = async () => {
        const cleanup = () => {
            setIsLoading(false);
            setLoadingMsg('');
            initCreateInput();
            initDestinatinList();
            setSelectedExistingDest(null);
        };

        if (canSubscribe()) {
            try {
                let request = null;
                setIsLoading(true);
                setLoadingMsg('Subscribing to service...');
                switch (tab) {
                    case 0:
                        // subscribe to existing destination
                        request = createSubscriptionRequest(
                            selectedExistingDest.id);
                        await createDataTransferSubscription({
                            body: request,
                            abortSignal: abortSignalRef?.current });
                        handleSuccessfulSubRequest();
                        break;
                    case 1:
                        // create and subscribe to new destination
                        let res = await createDataTransferDestination({
                            body: createInput,
                            abortSignal: abortSignalRef?.current });
                        dispatch(setAlert({
                            show: true,
                            message: `Successfully created the destination`,
                            severity: 'success'
                        }));

                        request = createSubscriptionRequest(res.data.id);
                        await createDataTransferSubscription({
                            body: request,
                            abortSignal: abortSignalRef?.current });
                        handleSuccessfulSubRequest();
                        break;
                    default:
                        console.error(`Invalid tab value: ${tab}`);
                }

                cleanup();
            } catch (err) {
                if (isCancel(err))
                    return;

                console.error(`Failed to subscribe to the destination: ${err.message}`)
                dispatch(setAlert({
                    show: true,
                    message: 'Failed to subscribe to the destination',
                    severity: 'error'
                }));

                cleanup();
            };
        }
    }

    const handleSuccessfulSubRequest = () => {
        // subscribe to the transfer service as well after subscribing to the
        // transfer destination
        handleServiceSubscription();
        handleCloseDialog();
    }

    const handleDeleteDestination = async () => {
        try {
            setIsLoading(true);
            setLoadingMsg('Deleting service...');
            setOpenDeleteDialog(false);
            await deleteDataTransferDestination({ id: selectedExistingDest.id,
                      abortSignal: abortSignalRef?.current });
            initDestinatinList();
            setSelectedExistingDest(null);

            dispatch(setAlert({
                show: true,
                message: `Successfully deleted the destination`,
                severity: 'success'
            }));
            setIsLoading(false);
            setLoadingMsg('');
        } catch (err) {
            if (isCancel(err))
                return;

            console.error(`Failed to delete the destination: ${err.message}`)
            dispatch(setAlert({
                show: true,
                message: 'Failed to delete the destination',
                severity: 'error'
            }));

            setIsLoading(false);
            setLoadingMsg('');
        }
    }

    // The table of data to show for the selected destination
    const tableData = selectedExistingDest ?
        [
            {
                label: 'Platform:',
                value: selectedExistingDest.dest_platform,
                baseId: 'platform'
            },
            {
                label: 'Account ID:',
                value: selectedExistingDest.dest_account,
                baseId: 'acct-id'
            },
            {
                label: 'Identifier:',
                value: selectedExistingDest.dest_identifier,
                baseId: 'identifier'
            }
        ] : [];

    const getDtsDialogContents = () => (
        <div>
            <Box>
                <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                    { createTabButton('Select', 0) }
                    { createTabButton('Create', 1) }
                </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
                <Grid container>
                    <MinorHeading
                        label='Select an existing transfer destination'
                        labelStyle='margin-y-05'
                    />
                    <Grid item xs={12}>
                        <Select
                            id='dtd-company-select'
                            name='dtd_company_select'
                            label='Transfer Destination'
                            itemList={optionList}
                            value={selectedExistingDest?.name}
                            required
                            onChange={handleSelectDestination}
                        />
                    </Grid>
                    {
                        selectedExistingDest &&
                        <Grid item xs={12} sx={{ mt: '1rem' }} >
                            <LabelValueTable tableData={tableData} />
                        </Grid>
                    }
                </Grid>
            </TabPanel>

            <TabPanel value={tab} index={1}>
                <Grid container>
                    <MinorHeading
                        label='Create and subscribe to a new transfer destination'
                        labelStyle='margin-y-1'
                    />
                    <form ref={formRef}>
                        <Grid item xs={12}>
                            <TextField
                                id='dtd-name'
                                name='name'
                                label='Name'
                                labelStyle='margin-top-2'
                                value={createInput?.name}
                                onChange={handleCreateInputChange}
                                required
                                errorMessage={formErrors?.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                id='dest-platform-label'
                                name='dest_platform'
                                label='Destination Platform'
                                labelStyle='margin-top-2'
                                value={createInput?.dest_platform}
                                onChange={handleCreateInputChange}
                                itemList={platformOptions}
                                required
                                errorMessage={formErrors?.dest_platform}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id='dtd-dest-account'
                                name='dest_account'
                                label='Destination Account Number'
                                labelStyle='margin-top-2'
                                value={createInput?.dest_account}
                                onChange={handleCreateInputChange}
                                required
                                errorMessage={formErrors?.dest_account}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id='dtd-dest-identifier'
                                name='dest_identifier'
                                label='Root or Role ARN'
                                labelStyle='margin-top-2'
                                value={createInput?.dest_identifier}
                                onChange={handleCreateInputChange}
                                required
                                errorMessage={formErrors?.dest_identifier}
                            />
                            {
                                !formErrors?.dest_identifier &&
                                <>
                                    <FieldHelp help='Valid ARN format:' />
                                    <code>arn:aws:iam::&lt;account&gt;:root</code>
                                    <FieldHelp help='or' />
                                    <code>arn:aws:iam::&lt;account&gt;:role/&lt;role name&gt;</code>
                                </>
                            }
                        </Grid>
                    </form>
                </Grid>
            </TabPanel>

            <ModalFooter>
                {
                    isLoading ?
                        <CircularProgressIndicator
                            sx={{ ml: '1rem' }}
                            altText={loadingMsg}
                        />
                    : (
                        <Grid container item xs={12} sx={{ pt: '1rem' }} >
                            <Grid item
                                xs={12}
                                sm={3}
                                sx={{
                                    textAlign: { xs: 'end', sm: 'left' }
                                }}
                            >
                                {
                                    tab === 0 && selectedExistingDest &&
                                    <ActionButton text='Delete' isDelete
                                        onClick={() => setOpenDeleteDialog(true)}
                                        data-open-modal
                                        aria-controls={confirmDeleteRef?.current?.modalId}
                                    />
                                }
                            </Grid>

                            <Grid item
                                xs={12}
                                sm={9}
                                sx={{
                                    display: 'flex',
                                        justifyContent: 'end'
                                }}
                            >
                                <ActionButton text='Cancel'
                                    onClick={handleCloseDialog}
                                    data-close-modal
                                />
                                {
                                    tab === 1 &&
                                    <ActionButton text='Subscribe'
                                        buttonStyle='margin-left-2'
                                        onClick={handleSubscribeBtnClick}
                                        data-close-modal
                                    />
                                }
                                {
                                    tab === 0 && selectedExistingDest &&
                                    <ActionButton text='Subscribe'
                                        buttonStyle='margin-left-2'
                                        onClick={handleSubscribeBtnClick}
                                        data-close-modal
                                    />
                                }
                            </Grid>
                        </Grid>
                    )
                }
            </ModalFooter>
        </div>
    );

    return (
        <Fragment>
            <Modal
                id='dts-subscription-dialog'
                isOpen={openDialog}
                heading='Data Transfer Destination'
                ref={dialogRef}
            >
                {/* We need to create the dialog for the sake of the 'ref'
                    but don't create the content if the dialog is closed */}
                {
                    openDialog &&
                    getDtsDialogContents()
                }
            </Modal>

            <OkCancelDialog
                id='confirm-destination-deletion'
                open={openDeleteDialog}
                heading='Confirm Destination Deletion'
                okText={OK_DELETE_TEXT}
                cancelText={CANCEL_DELETE_TEXT}
                handleOkOp={handleDeleteDestination}
                handleCancelOp={() => setOpenDeleteDialog(false)}
                dialogRef={confirmDeleteRef}
            >
                {/* We need to create the dialog for the sake of the 'ref'
                    but don't create the content if the dialog is closed */}
                {
                    openDeleteDialog &&
                    <>
                        <BodyText labelStyle='text-center'>
                            Are you sure you want to delete
                        </BodyText>
                        <BodyText labelStyle='text-center margin-top-1'>
                            <strong>{selectedExistingDest?.name}</strong>?
                        </BodyText>
                        <BodyText labelStyle='text-center'>
                            <i>Deleting this destination will irrevocably remove any subscriptions to the API.</i>
                        </BodyText>
                    </>
                }
            </OkCancelDialog>
        </Fragment>
    );
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role='tabpanel'
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
            sx={{
                mt: '1rem'
            }}
        >
            {
                value === index && (
                    <Fragment>
                        {children}
                    </Fragment>
                )
            }
        </Box >
    );
};

const createTabButton = (label, value) => (
    <Tab label={label} value={value} id={`tab-${value}`} 
        disableRipple tabIndex={0}
        sx={{ m: '0.25rem', fontSize: '1rem', textTransform: 'none' }} 
    />
);

const createInputInitialState = {
    name: '',
    dest_platform: 'aws',
    dest_account: '',
    dest_identifier: '',
    dest_url: '',
    provider_id: null,
    created_by: '',
    last_updated_by: ''
};

const platformOptions = [
    {
        id: 'dts-platform',
        value: 'AWS',
    }
];

export default DataTransferDestinationDialog;
