import BodyText from "../common/BodyText";

/**
 * This component is for the Confirmation step in the request access process.
 * It tells the user their request has been submitted and lets them know
 * what to expect next.
 */
const ConfirmationStep = () => (
    <>
        <h2 className='text-success-darker font-serif-xl'>
            Success! Your request has been submitted!
        </h2>
        <BodyText
            label='Your request is being reviewed by the DIP team.'
            labelStyle='margin-top-6'
        />
        <BodyText
            label='Please allow up to 7 business days to process your request.'
        />
        <BodyText
            label='You will receive an email with access instructions once your request has been approved.'
        />
    </>
);

export default ConfirmationStep;
