import Box from '@mui/material/Box';

import { Fragment, useEffect, useState } from 'react';

import { ServiceTypeUtils, checkServiceType } from '../../utils/utils';
import BodyText from '../common/BodyText';
import WizardVersionAdminBasic from './WizardVersionAdminBasic';
import WizardVersionAuthentication from './WizardVersionAuthentication';
import WizardVersionCore from './WizardVersionCore';
import WizardVersionDomain from './WizardVersionDomain';
import WizardVersionPerformance from './WizardVersionPerformance';
import { REQUIRED_SERVICE_FILE_TYPE } from '../../utils/serviceRegistration';
import VirusScanWrapperForResourceFile from './VirusScanWrapperForResourceFile';
import AdditionalFiles from './AdditionalFiles';

/**
 * This provides the ability to enter, edit, or view all the parameters of
 * a service version.
 *
 * @param {object}   input                  service version data
 * @param {function} setInput               method to store new version data
 * @param {function} handleInputChange      callback to update version data;
 *                                          argument list is an event from
 *                                          which it takes the event.target.name
 *                                          and event.target.value to update
 *                                          the version data
 * @param {function} handleInputChangeByNameAndValue
 *                                          callback to update version data;
 *                                          argument list is the item name
 *                                          and item value
 * @param {boolean}  readOnly               true when the user is only viewing
 *                                          data
 * @param {boolean}  isEditingRestricted    true when editing is restricted,
 *                                          such as for a service version that
 *                                          has already been launched
 * @param {boolean}  admin                  true for the Admin user
 * @param {string}   formErrors             error messages to display, if any
 * @param {function} setFormErrors          setter for formErrors
 */
const ServiceVersionReview = ({
    input,
    setInput,
    handleInputChange,
    handleInputChangeByNameAndValue,
    readOnly,
    isEditingRestricted,
    admin,
    formErrors,
    setFormErrors
}) => {

    const [serviceType, setServiceType] = useState(null);

    const [totalNumberOfFiles, setTotalNumberOfFiles] = useState(0);
    const [totalFileSizeBytes, setTotalFileSizeBytes] = useState(0);

    useEffect(() => {
        setServiceType(input?.service_type?.toLowerCase());
    }, [input]);

    useEffect(() => {
        setTotalNumberOfFiles(input.additional_file_count ?? 0);
        setTotalFileSizeBytes(input.additional_file_size_bytes ?? 0);
    }, [input.additional_file_size_bytes, input.additional_file_count]);

    const handleResourceFileChange = (fileType, file) => {
        let copyFiles = { ...input.version_files };
        copyFiles[fileType] = file;
        handleInputChangeByNameAndValue('version_files', copyFiles)
    };

    const setVersionAdtnlFilesErrors = (update) => {
        setFormErrors((prev) => ({
            ...prev,
            versionAdditionalFiles: {
                ...update,
            },
        }));
    };

    const label=`API registration requires an ${checkServiceType(serviceType, ServiceTypeUtils.TYPES.REST) ? 'OAS 3.0 or Swagger UI 2.0' : 'AsyncAPI'} specification file.`;

    return (
        <Fragment>
            <WizardVersionCore
                input={input}
                handleInputChange={handleInputChange}
                handleInputChangeByNameAndValue={handleInputChangeByNameAndValue}
                readOnly={readOnly}
                isEditingRestricted={isEditingRestricted}
                serviceType={serviceType}
                formErrors={formErrors?.versionCore}
            >
                {
                    // If admin, display the admin actions component
                    admin &&
                    <WizardVersionAdminBasic
                        input={input}
                        setInput={setInput}
                        handleInputChange={handleInputChange}
                        readOnly={readOnly}
                        isEditingRestricted={isEditingRestricted}
                        serviceType={serviceType}
                        formErrors={formErrors?.versionAdminBasic}
                    />
                }
            </WizardVersionCore>

            <Box sx={{ my: '1rem' }} />

            <WizardVersionDomain
                input={input}
                handleInputChangeByNameAndValue={handleInputChangeByNameAndValue}
                readOnly={readOnly}
                serviceType={serviceType}
                formErrors={formErrors?.versionDomain}
            />


            { /* DATA_TRANSFER and SFTP services do not have API Spec files */ }
            {
                (checkServiceType(serviceType,
                    ServiceTypeUtils.TYPES.REST) ||
                 checkServiceType(serviceType,
                    ServiceTypeUtils.TYPES.STREAMING)) &&

                <>
                    <Box sx={{ my: '1rem' }} />

                    <VirusScanWrapperForResourceFile
                        id='resource-file_openapi-spec'
                        title='API Specification and Access Control'
                        label={label}
                        fileInput={input.version_files?.[REQUIRED_SERVICE_FILE_TYPE.openapi_spec]}
                        handleInputChange={(value) =>
                            handleResourceFileChange(
                                REQUIRED_SERVICE_FILE_TYPE.openapi_spec, value)
                        }
                        targetFolderName={process.env.REACT_APP_CATALOG_SERVICE_S3_API_SPEC_FOLDER_NAME}
                        acceptedFileTypes='.yaml,.yml'
                        readOnly={readOnly}
                        isEditingRestricted={isEditingRestricted}
                        formError={formErrors?.versionApiSpec?.openapi_spec_file}
                        required={true}
                        enableReplace={true}
                    />
                </>
            }

            <AdditionalFiles
                additionalFiles={input.version_additional_files}
                totalFileSizeBytes={totalFileSizeBytes}
                totalNumberOfFiles={totalNumberOfFiles}
                handleUpdate={({action, payload, fileSize}) => {
                    fileSize = fileSize ?? 0;

                    if (action === "add") {
                        setTotalNumberOfFiles((n) => n + 1);
                    } else if (action === "delete") {
                        setTotalNumberOfFiles((n) => n - 1);
                        setTotalFileSizeBytes((s) => s - fileSize);
                    } else if (action === "update") {
                        setTotalFileSizeBytes((s) => s + fileSize);
                    }
                    handleInputChangeByNameAndValue("version_additional_files", payload);
                }}
                formErrors={formErrors?.versionAdditionalFiles}
                setFormErrors={setVersionAdtnlFilesErrors}
                readOnly={readOnly}
            />

            <Box sx={{ my: '1rem' }} />

            <WizardVersionPerformance
                input={input}
                handleInputChange={handleInputChange}
                handleInputChangeByNameAndValue={handleInputChangeByNameAndValue}
                readOnly={readOnly}
                formErrors={formErrors?.versionPerformance}
            />

            <Box sx={{ my: '1rem' }} />

            {
                checkServiceType(serviceType, ServiceTypeUtils.TYPES.REST) && (
                    <Fragment>
                        <WizardVersionAuthentication
                            input={input}
                            handleInputChange={handleInputChange}
                            readOnly={readOnly}
                            isEditingRestricted={isEditingRestricted}
                            formErrors={formErrors?.versionAuthentication}
                        />

                        <Box sx={{ my: '1rem' }} />
                    </Fragment>
                )
            }
            <BodyText
                label='* indicates a required field'
                labelStyle='text-italic'
            />

        </Fragment>
    )
};

export default ServiceVersionReview;
