import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { useRef, useState } from 'react';
import validator from 'validator';

import ActionButton from '../common/ActionButton';
import BodyText from '../common/BodyText';
import ContactCard from '../common/ContactCard';
import FieldHelp from '../common/FieldHelp';
import MinorHeading from '../common/MinorHeading';
import OkCancelDialog from '../common/OkCancelDialog';
import TextField from '../common/TextField';
import { formatPhoneNumber, CANCEL_DELETE_TEXT, OK_DELETE_TEXT
       } from '../../utils/utils';
import { isInvalidTextField, isErrorObjectEmpty, isValidPhoneNumber
       } from '../../utils/validation';

/**
 * This provides a generic control to display or edit one or more pieces of
 * contact information.    The information includes:
 * - a title for the contact information (e.g. "Sales", "Engineering")
 * - a "help" icon which the user can click to get more information
 * - an "add" button to add a new contact
 * - the list of already-entered contacts.
 * When the user clicks the "add" button, a new dialog (contained in this
 * component) is opened to prompt the user for the type, email, phone, etc.
 */
const ContactInformation = ({
    id,
    title,
    header,
    helpText,
    typeHelpText,
    descHelpText,
    input,
    inputAttributeName,
    readOnly,
    handleInputChangeByNameAndValue
}) => {

    const formRef = useRef(null);
    const [editContactInfo, setEditContactInfo] = useState(false);
    const [errors, setErrors] = useState({});

    const [openContactDialog, setOpenContactDialog] = useState(false);
    const contactRef = useRef(null);

    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const deleteRef = useRef(null);

    const [contactInfo, setContactInfo] = useState({
        idx: null,
        type: '',
        name: '',
        email: '',
        phone: '',
        url: '',
        comments: ''
    });

    const resetData = () => {
        setContactInfo({
            idx: null,
            type: '',
            name: '',
            email: '',
            phone: '',
            url: '',
            comments: ''
        });
        setErrors({});
    }

    const handleCloseContactDialog = () => {
        setEditContactInfo(false);
        setOpenContactDialog(false);
        resetData();
    };

    const handleSave = () => {
        let newContactInfo = { ...contactInfo };
        let idx = newContactInfo.idx;
        delete newContactInfo.idx;

        let newErrors = {}

        newErrors.type = isInvalidTextField(newContactInfo.type, 1, 75, " .-'")

        // One of email or phone are mandatory
        if (validator.isEmpty(newContactInfo.email.trim()) &&
                validator.isEmpty(newContactInfo.phone.trim())) {
            newErrors.email = 'Please set either an email or a phone number';
            newErrors.phone = 'Please set either an email or a phone number';
        }

        if (!validator.isEmpty(newContactInfo.email.trim()) &&
                !validator.isEmail(newContactInfo.email)) {
            newErrors.email = 'Invalid email format'
        }

        if (!validator.isEmpty(newContactInfo.phone.trim()) &&
                !isValidPhoneNumber(newContactInfo.phone)) {
            newErrors.phone = 'Invalid phone number'
        }

        if (!validator.isEmpty(newContactInfo.url.trim()) &&
                !validator.isURL(newContactInfo.url)) {
            newErrors.url = 'Invalid URL format'
        }

        if (!validator.isEmpty(newContactInfo.comments.trim())) {
            newErrors.comments = isInvalidTextField(newContactInfo.comments,
                0, 500, " .,-:()'")
        }

        if (isErrorObjectEmpty(newErrors)) {
            if (editContactInfo) {
                handleInputChangeByNameAndValue(
                    inputAttributeName,
                    input[inputAttributeName].map((oldData, i) =>
                        i === idx ? newContactInfo : oldData)
                );
            } else {
                handleInputChangeByNameAndValue(
                    inputAttributeName,
                    [...input[inputAttributeName], { ...newContactInfo }]
                );
            }

            handleCloseContactDialog();
        } else {
            setErrors(newErrors);
        }
    };

    const deleteContactInfo = () => {
        handleInputChangeByNameAndValue(
            inputAttributeName,
            input[inputAttributeName].filter((l, i) => i !== contactInfo.idx)
        );
        setOpenDeleteConfirmation(false);
        resetData();
    };

    const handleInputChange = (target, value) => {
        setContactInfo({
            ...contactInfo,
            [target]: value
        })
    };

    return (
        <Box>
            <div className='display-flex flex-align-center flex-start'>
                <MinorHeading
                    label={title}
                    labelStyle='display-inline-flex margin-y-0 flex-align-center'
                    aria-describedby={`${id}-help`}
                />

                {
                    !readOnly &&
                    <ActionButton text='Add'
                        onClick={() => setOpenContactDialog(true)}
                        buttonStyle='margin-left-2'
                        data-open-modal
                        aria-controls={contactRef?.current?.modalId}
                    />
                }
            </div>

            <FieldHelp
                id={`${id}-help`}
                help={helpText}
            />

            <List>
                {
                    input[inputAttributeName] &&
                    input[inputAttributeName].map((el, idx) => {
                        return (
                            <ListItem
                                key={idx}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    pl: '1rem',
                                    border: '1px solid',
                                    borderRadius: '5px',
                                    borderColor: '#C4C4C4',
                                    mt: idx !== 0 ? '0.5rem' : 0
                                }}
                            >
                                <ContactCard info={el} />
                                <Box
                                    sx={{
                                        display: readOnly ? 'none' : 'flex'
                                    }}
                                >
                                    <ActionButton text='Edit'
                                        onClick={() => {
                                            setContactInfo({ ...el, idx });
                                            setEditContactInfo(true);
                                            setOpenContactDialog(true);
                                        }}
                                        data-open-modal
                                        aria-controls={contactRef?.current?.modalId}
                                    />
                                    <ActionButton text='Delete' isDelete
                                        onClick={() => {
                                            setContactInfo({ ...el, idx });
                                            setOpenDeleteConfirmation(true);
                                        }}
                                        buttonStyle='margin-left-2'
                                        data-open-modal
                                        aria-controls={deleteRef?.current?.modalId}
                                    />
                                </Box>
                            </ListItem>
                        );
                    })
                }
            </List>

            {/* Dialog for the user to enter/edit contact info. */}
            <OkCancelDialog
                id='contact-dialog'
                open={openContactDialog}
                heading={header}
                handleCancelOp={handleCloseContactDialog}
                handleOkOp={handleSave}
                isLarge
                dialogRef={contactRef}
            >
                {/* We need to create the dialog for the sake of the 'ref'
                    but don't create the content if the dialog is closed */}
                {
                    openContactDialog &&
                    <form ref={formRef}>
                        <TextField
                            id='contact-type'
                            name='type'
                            label='Type'
                            labelStyle='margin-top-05 width-mobile-lg'
                            value={contactInfo.type}
                            required
                            onChange={(e) =>
                                handleInputChange('type', e.target.value)}
                            errorMessage={errors?.type}
                            help={typeHelpText}
                        />
                        <TextField
                            id='contact-name'
                            name='name'
                            label='Name'
                            labelStyle='margin-top-2 width-mobile-lg'
                            value={contactInfo.name}
                            onChange={(e) =>
                                handleInputChange('name', e.target.value)}
                            errorMessage={errors?.name}
                        />
                        <TextField
                            id='contact-email'
                            name='email'
                            label='Email'
                            labelStyle='margin-top-2 width-mobile-lg'
                            value={contactInfo.email}
                            onChange={(e) =>
                                handleInputChange('email', e.target.value)}
                            errorMessage={errors?.email}
                        />
                        <TextField
                            id='contact-phone'
                            name='phone'
                            label='Phone'
                            labelStyle='margin-top-2 width-mobile-lg'
                            value={contactInfo.phone}
                            onChange={(e) => {
                                let phone = formatPhoneNumber(e.target.value);
                                handleInputChange('phone', phone)
                            }}
                            errorMessage={errors?.phone}
                            help='An optional extension may be
                                  included, e.g. (800) 555-1212 x123'
                        />
                        <TextField
                            id='contact-url'
                            name='url'
                            label='URL'
                            labelStyle='margin-top-2 width-mobile-lg'
                            value={contactInfo.url}
                            onChange={(e) =>
                                handleInputChange('url', e.target.value)}
                            errorMessage={errors?.url}
                        />
                        <TextField
                            id='contact-comments'
                            name='comments'
                            label='Description, Limitations, and/or Comments'
                            labelStyle='margin-top-2 width-mobile-lg'
                            value={contactInfo.comments}
                            onChange={(e) =>
                                handleInputChange('comments', e.target.value)}
                            errorMessage={errors?.comments}
                            help={descHelpText}
                        />
                    </form>
                }
            </OkCancelDialog>

            {/* Dialog for the user to delete contact info. */}
            <OkCancelDialog
                id='confirm-delete-contact'
                open={openDeleteConfirmation}
                heading='Confirm Contact Deletion'
                okText={OK_DELETE_TEXT}
                cancelText={CANCEL_DELETE_TEXT}
                handleOkOp={deleteContactInfo}
                handleCancelOp={() => setOpenDeleteConfirmation(false)}
                dialogRef={deleteRef}
            >
                {/* We need to create the dialog for the sake of the 'ref'
                    but don't create the content if the dialog is closed */}
                {
                    openDeleteConfirmation &&
                    <>
                        <BodyText labelStyle='text-center'>
                            Are you sure you want to delete
                        </BodyText>
                        <BodyText labelStyle='text-center margin-top-1'>
                            <strong>{contactInfo.type}</strong>?
                        </BodyText>
                    </>
                }
            </OkCancelDialog>
        </Box>
    );
};

export default ContactInformation;
