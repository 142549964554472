import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { hasISAVerificationToken, isUserAuthenticated } from '../../utils/auth';
import { URL_REQUSET_ACCESS } from '../../utils/navigation';

/**
 * This component is a wrapper around the child components or route elements
 * and it checks if the user has valid IVT in the access/id token.
 * If user does not have one, redirect to the IVT verification page.
 */
export default function RequireIvt({ children }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasIVT, setHasIVT] = useState(false);

  // perform side effect whenever the current location changes
  useEffect(() => {
    ivtCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const ivtCheck = async () => {
    // If authenticated, check IVT in the access/id token
    // If unauthenticated, redirect to access request page.
    let authenticated = await isUserAuthenticated();
    setIsAuthenticated(authenticated);
    if (authenticated) {
      if (!hasIVT) {
        const ivtVerified = await hasISAVerificationToken();
        setHasIVT(ivtVerified);

        // redirect to the request access page if no IVT
        if (!ivtVerified) {
          navigate(URL_REQUSET_ACCESS);
        };
      }
    } else {
      setHasIVT(false);
      navigate(URL_REQUSET_ACCESS);
    }
  }

  return (isAuthenticated && hasIVT) ? children ?? <Outlet /> : null;
}
