import { checkServiceType, ServiceTypeUtils } from "./utils";

/**
 * Get a link to the api spec page dependent on the service type
 * @param {string} serviceType 
 * @param  {...any} props 
 * @returns 
 */
export const getApiSpecPageLink = (serviceType, ...props) => {
  let rtn = '';
  if (checkServiceType(serviceType, ServiceTypeUtils.TYPES.REST)) {
    rtn = getOpenApiSpecPageLink(...props);
  } else {
    rtn = getAsyncApiSpecPageLink(...props);
  }
  return rtn;
};

/**
 * Get a link to the OpenAPI specification page
 * @param {*} serviceName name of the service
 * @param {*} openApiLocation location of the OpenAPI spec
 * @param {*} serviceId service ID
 * @param {*} providerId provider ID
 * @param {*} serviceUrl service URL
 * @param {*} companyId comsunmer company ID
 * @param {*} versionId service version ID
 */
export const getOpenApiSpecPageLink = (serviceName, openApiLocation, serviceId, providerId, serviceUrl, companyId, versionId) => {
  return `/openapi?name=${serviceName}&openApiLocation=${openApiLocation}` +
    `&serviceId=${serviceId}&providerId=${providerId}&serviceUrl=${serviceUrl}&companyId=${companyId}&` +
    `versionId=${versionId}`;
}

/**
 * Get a link to the AsyncAPI specification page
 * @param {*} serviceName name of the service
 * @param {*} asyncApiLocation location of the OpenAPI spec
 * @param {*} serviceId service ID
 * @param {*} providerId provider ID
 * @param {*} serviceUrl service URL
 */
export const getAsyncApiSpecPageLink = (serviceName, asyncApiLocation, serviceId, providerId) => {
  return `/asyncapi?name=${serviceName}&asyncApiLocation=${asyncApiLocation}` +
    `&serviceId=${serviceId}&providerId=${providerId}`;
}

export const getOpenApiSpecS3FileName = () => {
  return process.env.REACT_APP_CATALOG_SERVICE_S3_FILENAME_OPENAPI_SPEC;
};
