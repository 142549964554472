import Grid from '@mui/material/Grid'

import Select from '../common/Select';
import { SELECT_TITLE } from '../common/Select';
import TextField from '../common/TextField';

/**
 * This provides the user interface to enter the API Key for a service version.
 */
const ApiKeyFields = ({
    input,
    handleInputChange,
    readOnly,
    isEditingRestricted,
    formErrors
}) => {
    return (
        <Grid container item spacing={2}>
            <Grid item xs={12}>
                <Select
                    id='api-key-location-select'
                    name='auth_api_key_location'
                    label='Location of API key'
                    labelStyle='margin-top-0'
                    value={input.auth_api_key_location}
                    itemList={locationOptions}
                    onChange={handleInputChange}
                    required
                    disabled={isEditingRestricted || readOnly}
                    errorMessage={formErrors?.authApiKeyLocation}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='auth-api-key-name'
                    name='auth_api_key_name'
                    label='API Key Name'
                    labelStyle='margin-top-0'
                    value={input.auth_api_key_name}
                    onChange={handleInputChange}
                    required
                    disabled={isEditingRestricted || readOnly}
                    errorMessage={formErrors?.authApiKeyName}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='auth-api-key-value'
                    name='auth_api_key_value'
                    label='API Key Value'
                    labelStyle='margin-top-0'
                    value={input.auth_api_key_value}
                    onChange={handleInputChange}
                    required
                    disabled={isEditingRestricted || readOnly}
                    errorMessage={formErrors?.authApiKeyValue}
                />
            </Grid>
        </Grid>
    );
};

const locationOptions = Object.freeze([
    {
        id: 'api-location-select',
        value: SELECT_TITLE,
    },
    {
        id: 'api-location-querystring',
        label: 'Querystring parameter',
        value: 'querystring',
    },
    {
        id: 'api-location-header',
        label: 'HTTP header',
        value: 'header',
    },
]);

export default ApiKeyFields;
