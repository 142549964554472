import classnames from 'classnames';

/**
 * This creates a radio button group.  It is based on the USWDS radio button
 * which satisfies Section 508 accessibility requirements.
 *
 * @param {string}   label               label for the group of radio buttons
 * @param {string}   labelStyle          additional USWDS style classes to add
 *                                       to the label, e.g. 'margin-top-0' to
 *                                       remove a margin
 * @param {string}   groupStyle          additional USWDS style classes to add
 *                                       to the entire group, e.g.
 *                                       'margin-top-2' to add a margin
 * @param {object}   itemList            spec for each radio button
 * @param {string}   itemList.id         unique id for this radio button
 * @param {string}   itemList.name       unique name for form submission
 * @param {string}   itemList.value      unique value for form submission
 * @param {string}   itemList.label      user interfice display label
 * @param {boolean}  itemList.checked    true if the radio button is selected
 * @param {object}   itemList.labelStyle style to apply to this radio button
 * @param {boolean}  required            true if a radio button must be selected
 * @param {boolean}  disabled            true if the buttons are disabled
 */

const RadioButtonGroup = ({
    label,
    labelStyle,
    groupStyle,
    itemList,
    onChange,
    required=false,
    disabled=false
}) => {

    const requiredToken = required ? ' *' : null;

    const labelClasses = classnames('usa-legend', labelStyle);
    const groupClasses = classnames('usa-fieldset', groupStyle);

    return (
        <fieldset className={groupClasses} >
            <legend className={labelClasses} >
                {label} {requiredToken}
            </legend>
            {
                itemList && itemList.map(radio => {
                    const radioClasses = classnames('usa-radio__label',
                        radio.labelStyle);
                    return (
                        <div className='usa-radio' key={radio.id}>
                            <input
                                className='usa-radio__input'
                                id={radio.id}
                                type='radio'
                                name={radio.name}
                                value={radio.value}
                                checked={radio.checked}
                                onChange={onChange}
                                required={required}
                                disabled={disabled}
                            />
                            <label
                                className={radioClasses}
                                htmlFor={radio.id}
                            >
                                {radio.label}
                            </label>
                        </div>
                    )
                })
            }
        </fieldset>
    );
};

export default RadioButtonGroup;
