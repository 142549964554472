import Accordion from '../common/Accordion';
import Checkbox from '../common/Checkbox';


/**
 * This provides a generic card for each filter checkbox set.  It has the
 * expand/collapse functionality followed by the set of checkboxes.
 */

const FilterCard = ({
    id,
    type,
    title,
    listItemTextField,
    listItemTextConvertMap,
    filterList=[],
    handleChange,
    show,
    ...props
}) => {

    // The filter card ID is used as the prefix for the card's elements to
    // ensure element IDs are unique across the entire page
    const typeId = id;

    // Create the checkbox and service counts for each filter item
    const itemList = filterList.map((f) => {
        const myId = `${typeId}-${f.id}`
        const style = {display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center' ,
            marginBottom: '.5rem' };
        const label= listItemTextConvertMap ?
            listItemTextConvertMap[f[listItemTextField]] :
            f[listItemTextField];
        return (
            <div style={style} key={myId}  >
                <Checkbox
                    id={myId}
                    label={label}
                    checked={f.checked ? true : false }
                    onChange={(e) => {
                        handleChange({ type: type, id: f.id,
                            checked: (f.checked ? !f.checked : true)});
                        document.getElementById(myId).focus();
                    }}
                    labelStyle={{marginTop: 0 }}
                />
                <label className='service-counts'
                    style={{marginLeft: '1rem'}}
                >
                    {`(${f.service_count ?? 0})`}
                </label>
            </div>
        )
    });

    return (
        <Accordion id={typeId} title={title} itemList={itemList} show={true} />
    );
};

export default FilterCard;
