import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { getFormattedDate, getDateObjectFromString } from './timeUtils';

dayjs.extend(utc);

export const METRIC_GROUP_INTERVAL = Object.freeze({
  MINUTE: 'MINUTE',
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
});

export const TIME_RANGE_FILTER_OPTIONS = Object.freeze([
  {
    id: 0,
    name: '24 hours',
    supportedUnitIds: [0]
  }, {
    id: 1,
    name: '7 days',
    supportedUnitIds: [0, 1]
  }, {
    id: 2,
    name: '30 days',
    supportedUnitIds: [0, 1, 2]
  }, {
    id: 3,
    name: '60 days',
    supportedUnitIds: [0, 1, 2]
  }]);


// for xAxisTickFormat and xAxisHoverFormat, see: https://github.com/d3/d3-3.x-api-reference/blob/master/Formatting.md
// for xAxisA11yDateFormat, see: https://day.js.org/docs/en/display/format
export const UNIT_FILTER_OPTIONS = Object.freeze([
  {
    id: 0,
    name: 'Hour',
    xAxisTitle: 'Hour',
    xAxisTickFormat: '%m/%e %I%p',
    xAxisHoverFormat: '%x %I%p',
    xAxisA11yDateFormat: 'M/D/YY hA',
    groupInterval: METRIC_GROUP_INTERVAL.HOUR
  }, {
    id: 1,
    name: 'Day',
    xAxisTitle: 'Day',
    xAxisTickFormat: '%m/%d',
    xAxisHoverFormat: '%x',
    xAxisA11yDateFormat: 'M/D/YY',
    groupInterval: METRIC_GROUP_INTERVAL.DAY
  }, {
    id: 2,
    name: 'Week',
    xAxisTitle: 'Week',
    xAxisTickFormat: '%m/%d',
    xAxisHoverFormat: '%x',
    xAxisA11yDateFormat: 'M/D/YY',
    groupInterval: METRIC_GROUP_INTERVAL.WEEK
  }
]);

export const SERVICE_METRIC_GRAPH_VALUE_TYPE = Object.freeze({
    NONE: 'NONE',
    MILLISECONDS: 'MILLISECONDS',
    MINUTES: 'MINUTES',
    SECONDS: 'SECONDS',
    HOURS: 'HOURS',
    DAYS: 'DAYS',
    PERCENTAGE: 'PERCENTAGE',
    BYTES: 'BYTES'
});

export const SERVICE_METRIC_GRAPH_UNIT_TO_TEXT = Object.freeze({
    [SERVICE_METRIC_GRAPH_VALUE_TYPE.NONE]: '',
    [SERVICE_METRIC_GRAPH_VALUE_TYPE.BYTES]: 'bytes',
    [SERVICE_METRIC_GRAPH_VALUE_TYPE.MILLISECONDS]: 'ms',
    [SERVICE_METRIC_GRAPH_VALUE_TYPE.SECONDS]: 's',
    [SERVICE_METRIC_GRAPH_VALUE_TYPE.MINUTES]: 'min',
    [SERVICE_METRIC_GRAPH_VALUE_TYPE.HOURS]: 'h',
    [SERVICE_METRIC_GRAPH_VALUE_TYPE.DAYS]: 'days',
});

export const SERVICE_METRICS_HELP_TABLE_DATA = Object.freeze({
  availability: {
      label: 'Availability',
      definition: `The degree to which a service is expected to function
        correctly and be accessible.  Availability represents the probability
        that a service is ready and available immediately when invoked.`,
      methodOfCalculation: `The ratio of the number of successful API requests
        to the total number of valid API requests.  The number of successful
        API requests is computed as the total number of requests minus the
        number of 400 and 500 errors.  The number of valid API requests is
        computed as the total number of requests minus the number of 400
        errors.  The value is reported as a percentage of availability for a
        given interval.`
  },
  totalResponseTime: {
      label: 'Total Response Time',
      definition: `A measurement of the processing time of a service, in terms
        of elapsed time it takes to send a response upon receiving a request.
        This includes both the Platform Latency and the Service Processing
        Time.`,
      methodOfCalculation: `The time when the Platform sent a response to the
        consumer minus the time when the Platform received the request from the
        consumer.`,
  },
  platformLatency: {
      label: 'Platform Latency',
      definition: `The amount of time the Platform takes to forward the
        request to the provider plus the time the Platform takes to send the
        response to the consumer. This excludes the Service Processing Time.`,
      methodOfCalculation: `The difference between the Total Response Time and
        the Service Processing Time.`
  },
  serviceProcessingTime: {
      label: 'Service Processing Time',
      definition: `The time it takes for a service to respond to a request
        forwarded by the Platform. This excludes the Platform Latency.`,
      methodOfCalculation: `The time when the Platform receives the response
        from the service minus the time when Platform sent the request to the
        service.`
  },
});

export const getValueAndUnitString = (value, unit, calculation) => {

    if (isValidValue(value)) {
        // Use the unit-to-text map to determine which text to append to the
        // value
        let unitAsText = SERVICE_METRIC_GRAPH_UNIT_TO_TEXT[unit] ?? unit;
        let type = unit;

        // If calculation is percentage,
        // 1. append '%' to the value
        // 2. set the type to PERCENTAGE so that the value is shortened
        //    appropriately
        if (calculation === SERVICE_METRIC_GRAPH_VALUE_TYPE.PERCENTAGE) {
            unitAsText = '%';
            type = SERVICE_METRIC_GRAPH_VALUE_TYPE.PERCENTAGE
        }

        return `${shortenValuePerType(value, type)} ${unitAsText}`;
    } else {
        return 'N/A';
    }
};

export const isValidValue = (value) => {
    return (value !== undefined) && (value !== null) &&
           (value !== '') && !isNaN(value);
};

const shortenValuePerType = (value, type) => {
    if ((value === undefined) || (value === null)) {
        return '';
    }
    let rtn = value;
    switch (type) {
        case SERVICE_METRIC_GRAPH_VALUE_TYPE.MILLISECONDS:
        case SERVICE_METRIC_GRAPH_VALUE_TYPE.SECONDS:
        case SERVICE_METRIC_GRAPH_VALUE_TYPE.MINUTES:
        case SERVICE_METRIC_GRAPH_VALUE_TYPE.HOURS:
        case SERVICE_METRIC_GRAPH_VALUE_TYPE.DAYS:
        case SERVICE_METRIC_GRAPH_VALUE_TYPE.BYTES:
            rtn = Math.round(parseFloat(value));
            break;
        case SERVICE_METRIC_GRAPH_VALUE_TYPE.PERCENTAGE:
            rtn = parseFloat(value).toFixed(2);
            break;
        default:
            console.info(`Unknown unit ${type} - using original value: ${value}`)
    }
    return rtn;
};

export const getTimeRangeFilterParams = (timeRangeFilterOption) => {
  let [amount, unit] = timeRangeFilterOption.name.split(' ');
  let now = dayjs.utc();
  let startTime = getFormattedDate(now.subtract(amount, unit));
  let endTime = getFormattedDate(now);
  return { startTime, endTime };
};

export const createMetricServiceInput = (serviceId, versionId, startTime, endTime, groupInterval) => {
  let rtn = null;
  if (!!serviceId && !!startTime && !!endTime) {
    rtn = {
      "service_id": parseInt(serviceId),
      "service_version": versionId,
      "start_time": startTime,
      "end_time": endTime,
      "group_interval": groupInterval
    }
  } else {
    console.error("Invalid service input values:", { serviceId, startTime, endTime, groupInterval });
  }
  return rtn;
};

export const createServiceProviderMetricsInput = (
  metricId, serviceId, startTime, endTime,
  groupInterval, unit, calculation
) => {
  let rtn = null;
  if (!!metricId && !!serviceId && !!startTime && !!endTime && !!unit && !!calculation) {
    rtn = {
      "metric_id": metricId,
      "service_id": parseInt(serviceId),
      "start_time": startTime,
      "end_time": endTime,
      "group_interval": groupInterval,
      "unit": unit,
      "calculation": calculation
    }
  } else {
    console.error("Invalid service provider metric input values:", {
      metricId, serviceId, startTime, endTime, groupInterval, unit, calculation
    });
  }
  return rtn;
}

export const manipulateXaxisPerGroupInterval = (xAxis, groupInterval) => {
  let rtn = xAxis;
  if (groupInterval === METRIC_GROUP_INTERVAL.WEEK) {
    // For the weekly metrics, the metric API returns the future date. For example, if today is 12/14/2022, the last element
    // in the x axis array is 12/18/2022 because that refers to the data for the 7 days before 12/18, but it's confusing to a user
    // to see a future data point. Therefore, changing all labels back a week.
    rtn = xAxis.map(d => getFormattedDate(getDateObjectFromString(d).subtract(1, 'w')));
  } else if (groupInterval === METRIC_GROUP_INTERVAL.MONTH) {
    // For the monthly metrics, the metric API returns the last day of the month (e.g. 11/31/2022, 12/31/2022).
    // When the plotly turns that date (12/31/2022) into xaxis tick label as month (Dec, 2022),
    // it refers to the month after the current month specified in the date (Jan, 2023).
    // Therefore, setting the date to the first day of the month.
    rtn = xAxis.map(d => getFormattedDate(getDateObjectFromString(d).set('date', 1)));
  }
  return rtn;
};

export const getShadedTraceAxes = (xAxis, yMinAxis, yMaxAxis) => {
  const createShadedAxis = (a, b) => a.concat(b.reduceRight((acc, curVal) => acc.concat(curVal), []));

  let shadedTraceXaxis = createShadedAxis(xAxis, xAxis);
  let shadedTraceYaxis = createShadedAxis(yMinAxis, yMaxAxis);
  return { x_axis: shadedTraceXaxis, y_axis: shadedTraceYaxis }
}

/**
 * Formats quality of service metrics for the dashboard or other displays.
 *
 * @param {array(object)} metrics               service summary metrics returned
 *                                              from calling
 *                                              getMetricServiceSummary()
 * @param {string}        serviceIdentifierKey  name of the property to assign
 *                                              the service id
 */
export const formatMetrics = (metrics, serviceIdentifierKey) => {

    const formatted = metrics.map(service => ({
        ...service,
        service_name_w_version: `${service.servicename} (${service.serviceversion})`,
        [serviceIdentifierKey]: service.serviceid,
        avg_integration_latency: `${service.avg_integration_latency} ms`,
        avg_dip_latency: `${service.avg_response_latency - service.avg_integration_latency} ms`,
        avg_response_latency: `${service.avg_response_latency} ms`,
        avg_response_length: `${service.avg_response_length} bytes`,
        sum_response_length: `${service.sum_response_length} bytes`,
        availability: `${parseFloat(((service.total_calls - service.fivehundred_errors) / service.total_calls) * 100).toFixed(2)}%`
    }));

    return formatted;
}
