import { useParams, useSearchParams } from "react-router-dom";

import RequireServiceOwnership from "../../require/RequireServiceOwnership";
import MainServiceRegistration from "../registration/MainServiceRegistration";

/**
 * This provides access to a service's parameters when an existing service is
 * being viewed, edited, or deleted.  This is actually just a pass-through
 * function to set a few key parameters that it passes along to
 * MainServiceRegistration.
 */
const ManageMainService = ({ admin }) => {
    const { serviceId } = useParams();
    const [searchParams] = useSearchParams();

    return (
        <RequireServiceOwnership serviceId={serviceId}>
            <MainServiceRegistration
                admin={admin}
                serviceType={searchParams.get("service_type")}
                serviceId={serviceId}
                action={searchParams.get("action")}
            />
        </RequireServiceOwnership>
    );
};

export default ManageMainService;
