import { forwardRef } from 'react';
import classnames from 'classnames';

/**
 * This component provides a wrapper around a modal -- including an overlay
 * around the modal content.  This is adapted from Trussworks'
 * https://github.com/trussworks/react-uswds/blob/main/src/components/Modal/ModalWrapper/ModalWrapper.tsx
 *
 * @param {string}   id            the id to give this modal
 * @param {JSX}      children      what to display as the main content
 * @param {function} handleClose   function to call to close the modal; used
 *                                 when forceAction is false
 * @param {boolean}  isVisible     true if the modal is visible
 * @param {boolean}  forceAction   true if the user is required to use one of
 *                                 the modal's buttons to close it, false if
 *                                 the user can click outside the modal to
 *                                 close it; default=false
 * @param {object}   divProps      any additional properties for the modal
 */

// Export the base class for use by the Modal component
export const MODAL_BASE_CLASS = 'usa-modal-wrapper';

// This is wrapped in a FocusTrap which requires its child element to be a
// functional component that uses the React.forwardRef() API.
const ModalWrapper = forwardRef((props, ref) => {

    const {
        id,
        children,
        handleClose,
        isVisible,
        forceAction,
        ...divProps
    } = props;

    const classes = classnames(
        MODAL_BASE_CLASS,
        {
            'is-visible': isVisible,
            'is-hidden': !isVisible,
        },
    );

    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
        <div {...divProps} ref={ref} id={id} className={classes} role="dialog">
            <div
                className="usa-modal-overlay"
                onClick={forceAction ? undefined : handleClose}
                aria-controls={id}
            >
                {children}
            </div>
        </div>
    );
});

export default ModalWrapper;
