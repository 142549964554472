import classnames from 'classnames';

/**
 * This provides the formatting for a field name in the display of a
 * "name: value" pair.  The function FieldValue can be used to format the value.
 *
 * @param {string} name         the field name to display
 * @param {string} id           the unique identifier for this field
 * @param {string} valueId      the unique identifier for the value field that
 *                              this field is describing
 * @param {string} nameColor    (optional) color to write 'name'; default is
 *                              'text-primary'
 * @param {string} topMargin    (optional) margin above 'name'; default is
 *                              'margin-top-1'
 * @param {string} rightMargin  (optional) margin to the right of 'name';
 *                              default is 'margin-right-1'
 * @param {string} nameStyle    (optional) any additional styles besides those
 *                              above which should be applied to the field
 */
const FieldName = ({
    name,
    id,
    valueId,
    nameColor='text-primary',
    topMargin='margin-top-1',
    rightMargin='margin-right-1',
    nameStyle
}) => {


    const classes = classnames('usa-label', nameColor, topMargin,
        rightMargin, nameStyle);

    return (
        <label
            id={id}
            htmlFor={valueId}
            className={classes}
        >
            {name}
        </label>
    );
}

export default FieldName;
