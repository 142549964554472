import DataTable from "../common/DataTable";
import { SERVICE_METRICS_HELP_TABLE_DATA } from '../../utils/serviceMetrics';


const tableColumns = [
  {
    header: 'Metric',
    accessor: 'qosParameter'
  },
  {
    header: 'Description',
    accessor: 'description'
  },
  {
    header: 'Method',
    accessor: 'method'
  }
];

const tableRows = [
  {
    qosParameter: 'No. Calls',
    description: 'The number of requests received in a given period of time.',
    method: `Counts are aggregated over the given interval and are used to
      compute availability.`,
  },
  {
    qosParameter: SERVICE_METRICS_HELP_TABLE_DATA.availability.label,
    description: SERVICE_METRICS_HELP_TABLE_DATA.availability.definition,
    method: SERVICE_METRICS_HELP_TABLE_DATA.availability.methodOfCalculation,
  },
  {
    qosParameter: 'No. 500 Errors',
    description: `The number of 500 errors received in a given period of time.
      A 500 error is an internal server response code that indicates the server
      encountered an unexpected condition that prevented it from fulfilling the
      request.`,
    method: 'Counts are aggregated over the given interval.',
  },
  {
    qosParameter: 'No. 400 Errors',
    description: `The number of 400 errors received in a given period of time.
      A 400 error indicates the server cannot or will not process the request
      due to something that is perceived to be a client error (for example,
      malformed request syntax, an invalid URL, or an invalid header).`,
    method: 'Counts are aggregated over the given interval.',
  },
  {
    qosParameter: 'Mean ' + SERVICE_METRICS_HELP_TABLE_DATA.totalResponseTime.label,
    description: SERVICE_METRICS_HELP_TABLE_DATA.totalResponseTime.definition,
    method: SERVICE_METRICS_HELP_TABLE_DATA.totalResponseTime.methodOfCalculation,
  },
  {
    qosParameter: 'Mean ' + SERVICE_METRICS_HELP_TABLE_DATA.platformLatency.label,
    description: SERVICE_METRICS_HELP_TABLE_DATA.platformLatency.definition,
    method: SERVICE_METRICS_HELP_TABLE_DATA.platformLatency.methodOfCalculation,
  },
  {
    qosParameter: 'Mean ' + SERVICE_METRICS_HELP_TABLE_DATA.serviceProcessingTime.label,
    description: SERVICE_METRICS_HELP_TABLE_DATA.serviceProcessingTime.definition,
    method: SERVICE_METRICS_HELP_TABLE_DATA.serviceProcessingTime.methodOfCalculation,
  },
  {
    qosParameter: 'Mean Payload Throughput',
    description: `The rate at which service response data are sent to the
      consumer. This metric is based on the payload size; i.e., excluding
      header data.`,
    method: `Payload size divided by the time it takes to send a response to a
      consumer. The time taken to send the response is the time Platform
      finishes sending the response minus the time it started sending the
      response.`
  },
  {
    qosParameter: 'Sum of Data Usage',
    description: 'The total data usage aggregated over a given interval.',
    method: `The size of the data (full message size) sent in each response.
      Value is reported as the sum for a given interval.`,
  },
  {
    qosParameter: 'Provider Dynamic Metrics',
    description: `Indicates if custom service metrics are available which are
      defined and dynamically computed by the service provider.`,
    method: 'n/a',
  },
]

const DashboardSummaryHelpTable = () => {
  return (
    <DataTable
      id='dashboard-summary-help-table'
      columns={tableColumns}
      rows={tableRows}
      hidePagination={true}
    />
  )
};

export default DashboardSummaryHelpTable;
