import Box from '@mui/material/Box';

import BodyText from './BodyText';
import { getFormattedNowDate } from '../../utils/timeUtils';

/**
 * This provides the footer across the bottom of every page.
 * It displays the copyright.
 */
function Footer() {

    const year = getFormattedNowDate('YYYY');

    return (
        <Box
            id='footer'
            sx={{
                width: '100%',
                py: '1rem',
                px: '3rem',
                color: '#fff',
                bgcolor: 'primary.main',
                display: 'flex',
                justifyContent: 'space-between',
                position: 'absolute',
                bottom: 0
            }}
        >
            <BodyText labelStyle='margin-top-0' >
                &copy; 2022-{year} &nbsp;
                National Aeronautics and Space Administration
            </BodyText>
            <BodyText labelStyle='margin-top-0 text-color-white' >
                <a href="https://www.nasa.gov/accessibility/"
                    style={{color: 'white'}}
                >
                    Accessibility
                </a>
            </BodyText>
        </Box>
    );
}

export default Footer;
