import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import HelpOutlineIcon from '@mui/icons-material/Help';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';

import { useEffect, useRef, useState } from 'react';
import Plot from 'react-plotly.js';

import { getDateObjectFromEpoch, getDateObjectFromString, getFormattedDate,
         getFormattedDateFromString
       } from '../../utils/timeUtils';
import { getValueAndUnitString, SERVICE_METRIC_GRAPH_VALUE_TYPE,
         TIME_RANGE_FILTER_OPTIONS
       } from '../../utils/serviceMetrics';
import { capitalizeFirstLetterOnly } from '../../utils/utils';
import LabelValueTable from '../common/LabelValueTable';
import MinorHeading from '../common/MinorHeading';
import DipIconButton from '../common/DipIconButton';
import MetricHelp from './MetricHelp';

const panZoomFactor = 0.1;
const shadedTraceColor = 'rgba(46, 152, 254, 0.2)';

// the step in-between ticks on the x axis
const dTickFromXaxisTitle = {
    Month: 2592000000,    // 1 month as ms
    Week: 604800000       // 1 week as ms
}

const ServiceStatCard = ({
    id, title, mean, std, min, max, unit, calculation,
    methodOfCalculation, definition, openHelpDialog, helpRef
}) => {

    const tableData = [
        {
            label: 'Mean:',
            value: getValueAndUnitString(mean, unit, calculation),
            baseId: `${id}-mean`,
            valueStyle: 'text-right'
        },
        {
            label: 'STD:',
            value: getValueAndUnitString(std, unit, calculation),
            baseId: `${id}-std`,
            valueStyle: 'text-right'
        },
        {
            label: 'Min:',
            value: getValueAndUnitString(min, unit, calculation),
            baseId: `${id}-min`,
            valueStyle: 'text-right'
        },
        {
            label: 'Max:',
            value: getValueAndUnitString(max, unit, calculation),
            baseId: `${id}-max`,
            valueStyle: 'text-right'
        },
    ];

    return (
        <Card
            sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: {
                    xs: 'center',
                    sm: 'unset'
                }
            }}
        >
            <CardContent>
                <MinorHeading
                    label={
                        <>
                            {title}
                            {
                                methodOfCalculation && definition &&
                                <DipIconButton
                                    id={`stat-help-icon-${id}`}
                                    aria-label={`help for metric ${title}`}
                                    onClick={openHelpDialog}
                                    data-open-modal
                                    aria-controls={helpRef?.current?.modalId}
                                >
                                    <HelpOutlineIcon />
                                </DipIconButton>
                            }
                        </>
                    }
                />

                <LabelValueTable tableData={tableData} />
            </CardContent>
        </Card>
    );
};

/**
 * @param {string[]} xAxisArray string arrays containing all values on x axis
 * @param {string} xAxisTitle title of the x axis
 * @param {string} graphStartTime if provided, using it as the start time
 * @param {string} graphEndTime if provided, using it as the end time
 * @returns {string[]} date range for x axis
 */
const getXaxisRange = (xAxisArray, xAxisTitle, graphStartTime, graphEndTime) => {
    if (!xAxisArray) {
        return null;
    }
    const xAxisTitleToManipulateUnits = {
        Hour: {
            amount: 6,
            unit: 'h'
        },
        Day: {
            amount: 1,
            unit: 'd'
        },
        Week: {
            amount: 2,
            unit: 'd'
        },
        Month: {
            amount: 2,
            unit: 'w'
        }
    }

    // if graphStartTime is valid, use that time insetad of the fisrt element in the xAxisArray.
    let start = getDateObjectFromString(graphStartTime ?? xAxisArray[0]).subtract(
        xAxisTitleToManipulateUnits[xAxisTitle].amount,
        xAxisTitleToManipulateUnits[xAxisTitle].unit
    );
    // if graphEndTime is valid, use that time insetad of the last element in the xAxisArray.
    let end = getDateObjectFromString(graphEndTime ?? xAxisArray[xAxisArray.length - 1]).add(
        xAxisTitleToManipulateUnits[xAxisTitle].amount,
        xAxisTitleToManipulateUnits[xAxisTitle].unit
    );

    start = getFormattedDate(start);
    end = getFormattedDate(end);

    return [start, end];
};


const GraphSkeleton = ({ height }) => (
    <Skeleton variant='rounded' height={height} />
);

const ServiceGraph = ({
    id,
    title,
    data,
    loading,
    methodOfCalculation,
    definition,
    graphStartTime,
    graphEndTime,
    height = '22rem',
    timeRange = TIME_RANGE_FILTER_OPTIONS[3].name,
    xAxisTitle = 'Days',
    xAxisTickFormat = '%e',
    xAxisHoverFormat = '%x',
    xAxisA11yDateFormat = 'M/D/YY'
}) => {

    const plotRef = useRef(null);

    const [rerenderGraph, setRerenderGraph] = useState(false);
    const [xAxisRange, setXaxisRange] = useState([]);
    const [yAxisRange, setYaxisRange] = useState([]);

    const [showAllDataPointLabels, setShowAllDataPointLabels] = useState(false);
    const [allDataPointLabels, setAllDataPointLabels] = useState([]);
    const [metricHelp, setMetricHelp] = useState(null);
    const [isHelpOpen, setHelpOpen] = useState(false);
    const helpRef = useRef(null);
    const primaryColor = useTheme().palette.primary.main;

    useEffect(() => {
        setXaxisRange(getXaxisRange(data?.series?.x_axis,
            xAxisTitle, graphStartTime, graphEndTime));

        if (data?.series?.x_axis && data?.series?.y_axis) {
            let labels = [];
            for (let i = 0; i < data.series.x_axis.length; i++) {
                // for the date format, see:
                // https://day.js.org/docs/en/display/format
                let xLabel = getFormattedDateFromString(data.series.x_axis[i], xAxisA11yDateFormat);
                let yLabel = parseFloat(data.series.y_axis[i]).toFixed(2);
                labels.push(`${xLabel}, ${yLabel}`);
            }
            setAllDataPointLabels(labels)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    // Set up the help information for this graphic's metric
    useEffect(() => {
        if (!!title && !!definition && !!methodOfCalculation) {
                let row = [{
                        name: title,
                        definition: definition,
                        methodOfCalculation: methodOfCalculation
                }];
                setMetricHelp(row);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, definition, methodOfCalculation]);

    const panX = (dx, mode) => {
        const range = plotRef.current.props.layout.xaxis.range;
        let start = getDateObjectFromString(range[0]).valueOf(),
            end = getDateObjectFromString(range[1]).valueOf();

        dx *= (end - start);
        start += dx;
        end += dx * mode;

        start = getFormattedDate(getDateObjectFromEpoch(start));
        end = getFormattedDate(getDateObjectFromEpoch(end));

        setXaxisRange([start, end]);
    }

    const panY = (dx, mode) => {
        const range = plotRef.current.props.layout.yaxis.range;
        let start = range[0], end = range[1];

        dx *= (end - start);
        start += dx;
        end += dx * mode;

        setYaxisRange([start, end]);
    }

    const zoomIn = (fac) => {
        panX(fac, -1);
        panY(fac, -1);
    }

    const zoomOut = (fac) => {
        panX(fac, -1);
        panY(fac, -1);
    }

    // this function is attached to the component as a key event handler to support keyboard interaction.
    const graphKeyEventListener = (e) => {
        switch (e.key) {
            // Show all data point labels when the user press enter
            case 'Enter':
                e.preventDefault();
                setShowAllDataPointLabels(!showAllDataPointLabels);
                break;
            // Zoom in or zoom out when +/= or -/_ key is pressed respectively
            case '+':
            case '=':
                zoomIn(panZoomFactor);
                break;
            case '-':
            case '_':
                zoomOut(-panZoomFactor);
                break;
            // Support panning the graph using keyboard.
            case 'ArrowRight':
                e.preventDefault();
                panX(panZoomFactor, 1);
                break;
            case 'ArrowLeft':
                e.preventDefault();
                panX(-panZoomFactor, 1);
                break;
            case 'ArrowUp':
                e.preventDefault();
                panY(panZoomFactor, 1);
                break;
            case 'ArrowDown':
                e.preventDefault();
                panY(-panZoomFactor, 1);
                break;
            default: return;
        }

        setRerenderGraph(!rerenderGraph);
    }

    const getYaxisTitle = (calculation, unit) => {
        return capitalizeFirstLetterOnly(`${calculation} ${unit !== SERVICE_METRIC_GRAPH_VALUE_TYPE.NONE ? `(${unit})` : ''}`)
    }

    const openHelpDialog = () => {
        setHelpOpen(true);
    }

    const closeHelpDialog = () => {
        setHelpOpen(false);
    }

    return (
        <>
            <Grid container item columnSpacing={1}>
                <Grid item sm={12} md={9}
                    tabIndex={0}
                    onKeyDown={loading ? null : graphKeyEventListener}
                    sx={{
                        height: { height }, // change it to 'fit-content' to maximize the height.
                        borderRadius: '5px',
                        overflow: 'hidden',
                        pl: '0 !important',
                        '&:focus': {
                            outline: `4px solid #005EA2`
                        },
                    }}
                >
                    {
                        loading ? (
                            <GraphSkeleton height={height} />
                        ) : (
                            <Plot
                                ref={plotRef}
                                data={[
                                    {
                                        x: data?.series?.shadedTraceAxes?.x_axis,
                                        y: data?.series?.shadedTraceAxes?.y_axis,
                                        fill: "toself",
                                        fillcolor: shadedTraceColor,
                                        line: { color: "transparent" },
                                        name: `${title}`,
                                        type: "scatter",
                                        hoverinfo: 'x+y'
                                    },
                                    {
                                        x: data?.series?.x_axis,
                                        y: data?.series?.y_axis,
                                        line: { color: primaryColor },
                                        mode: "lines+markers+text",
                                        name: `${title}`,
                                        type: "scatter",
                                        hoverinfo: 'x+y',
                                        text: showAllDataPointLabels ? allDataPointLabels : [],
                                        textposition: 'bottom center',
                                    },
                                ]}
                                layout={{
                                    title: {
                                        text: `<b>${title} (${timeRange})</b>`,
                                        font: {
                                            color: primaryColor
                                        },
                                    },
                                    xaxis: {
                                        title: {
                                            text: `${xAxisTitle}`,
                                            font: {
                                                color: primaryColor
                                            }
                                        },
                                        // For numbers, see: https://github.com/d3/d3-3.x-api-reference/blob/master/Formatting.md#d3_format
                                        // And for dates see: https://github.com/d3/d3-3.x-api-reference/blob/master/Time-Formatting.md#format
                                        hoverformat: `${xAxisHoverFormat}`,
                                        tickformat: `${xAxisTickFormat}`,
                                        tickangle: `${xAxisTitle === 'Hour' ? '45' : 'auto'}`,
                                        type: 'date',
                                        automargin: true,
                                        autorange: false,
                                        range: xAxisRange,
                                        dtick: dTickFromXaxisTitle[xAxisTitle]
                                    },
                                    yaxis: {
                                        title: {
                                            text: getYaxisTitle(data?.calculation, data?.unit),
                                            font: {
                                                color: primaryColor
                                            },
                                            standoff: 20
                                        },
                                        range: yAxisRange,
                                        automargin: true
                                    },
                                    margin: {
                                        autoexpand: true,
                                        pad: 10
                                    },
                                    showlegend: false,
                                    autosize: true
                                }}
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                                useResizeHandler={true}
                                config={{
                                    displaylogo: false,
                                    displayModeBar: true,
                                    modeBarButtonsToRemove: ['select2d', 'lasso2d']
                                }}
                                key={rerenderGraph}
                            />
                        )
                    }
                </Grid>
                <Grid item sm={12} md={3}
                    sx={{ width: '100%', height: { height } }}
                >
                    {
                        loading ? (
                            <GraphSkeleton height='100%' />
                        ) : (
                            <ServiceStatCard
                                id={id}
                                title={title}
                                methodOfCalculation={methodOfCalculation}
                                definition={definition}
                                openHelpDialog={openHelpDialog}
                                mean={data?.mean}
                                std={data?.std}
                                min={data?.min}
                                max={data?.max}
                                unit={data?.unit}
                                calculation={data?.calculation}
                                helpRef={helpRef}
                            />
                        )
                    }
                </Grid>
            </Grid>

            <MetricHelp
                id={`${id}-help`}
                isDialogOpen={isHelpOpen}
                closeDialog={closeHelpDialog}
                rows={metricHelp}
                heading={title}
                helpRef={helpRef}
            />
        </>
    );
};

export default ServiceGraph;
