import { _axios } from './axiosConfig';
import { convertObjectToQueryStrParams } from './utils/utils';

// Catalog Service APIs
const CATALOG_SERVICE_BASE_URL = process.env.REACT_APP_CATALOG_SERVICE_URL;

const ACCESS_REQUEST_URL = '/access-request';
const ACCESS_REQUEST_STATUS_URL = '/access-request/status';
const ACCESS_REQUESTS_URL = '/access-requests';
const ACCESS_ROLE_URL = '/access-role';
const ACCESS_ROLES_URL = '/access-roles';
const ACCESS_ROLES_REORDER_URL = '/access-roles/reorder';
const CATEGORIES_URL = '/categories';
const COMAPANIES_URL = '/companies';
const COMPANY_URL = '/company';
const DATA_TRANSFER_DESTINATION = '/transfer/dest';
const DATA_TRANSFER_SOURCE = '/transfer/source';
const DATA_TRANSFER_SUBSCRIPTION = '/transfer/subscription';
const DATA_TRANSFER_SUBSCRIPTION_CHECK = '/transfer/subscription/check';
const DATA_TRANSFER_SUBSCRIPTION_STATUS = '/transfer/subscription/status';
const MY_ACCOUNT_URL = '/my-account';
const NEWS_MARK_NEWS_READ_URL = '/mark-news-read';
const NEWS_UNREAD_NEWS_URL = '/unread-news';
const PLATFORM_RELEASES_URL = '/platform-releases';
const PLATFORM_RELEASE_URL = '/platform-release';
const PLATFORM_RELEASE_NEWS_REORDER_URL = '/platform-release-news/reorder';
const PLATFORM_RELEASE_NEWS_URL = '/platform-release-news';
const PLATFORM_SUMMARY_CAPABILITIES_URL = '/platform-summary-capabilities';
const PLATFORM_SUMMARY_CAPABILITY_URL = '/platform-summary-capability';
const PLATFORM_SUMMARY_CAPABILITY_REORDER_URL = '/platform-summary-capability/reorder';
const PROXY_API_URL = '/proxy-api';
const SEARCH_URL = '/search';
const SERVICE_CATEGORIES_URL = '/service-categories';
const SERVICE_PROVIDER_METRICS = '/service_provider_metric';
const SERVICE_URL = '/service';
const SERVICE_COUNT_PER_FILTER_URL = '/service/count-per-filter';
const SERVICE_NEWS_URL = '/service-news';
const SERVICE_NEWS_NUM_LAUNCHED_VERSIONS_URL = '/service-news/num-launched-versions';
const SERVICE_NEWS_SUMMARY_URL = '/service-news-summary';
const SERVICE_VERSION_URL = '/service/version';
const SERVICE_VERSIONS_URL = '/service/versions';
const SERVICE_STATUS_URL = `${SERVICE_URL}/status`;
const SERVICES_URL = '/services';
const SUBSCRIPTION_URL = '/subscription';
const SUBSCRIPTIONS_URL = '/subscriptions';
const USER_URL = '/user';
const USER_ID_URL = '/user-id';
const USERS_URL = '/users';
const VERIFY_IVT_URL = '/verify-ivt';
const VERIFY_SERVICE_OWNERSHIP_URL = '/verify-service-ownership';

// Metric Service APIs
const METRIC_SERVICE_BASE_URL = process.env.REACT_APP_METRIC_SERVICE_URL;
const METRIC_SERVICE_AVAILABILITY_URL = '/availability';
const METRIC_SERVICE_RESPONSE_TIME_URL = '/response_time';
const METRIC_SERVICE_INTEGRATION_LATENCY_URL = '/latency_time';
const METRIC_SERVICE_DIP_LATENCY_URL = '/dip_latency_time';
const METRIC_SERVICE_PROVIDER_METRICS_URL = '/service_provider_metrics';
const METRIC_SERVICE_SUMMARY_URL = '/summary';

// Virus Scan APIs
const VIRUS_SCAN_BASE_URL = process.env.REACT_APP_VIRUS_SCAN_API_URL;
const VIRUS_SCAN_GET_SCAN_STATUS = '/get-scan-status';

const CATALOG_SERVICE_S3_PROXY_URL = process.env.REACT_APP_CATALOG_SERVICE_S3_PROXY_URL;

export const CATALOG_SERVICE_MOCK_SERVER_URL = `${CATALOG_SERVICE_BASE_URL}/mock`;

export const sendFeedback = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}/feedback`;
  return _axios.post(url, body, { signal: abortSignal });
};

export const createCompany = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${COMPANY_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
};

export const createDipUser = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${USER_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
};

export const deleteDipUser = ({ userId, abortSignal }) => {
  if (!!userId) {
    const url = `${CATALOG_SERVICE_BASE_URL}${USER_URL}/${userId}`;
    return _axios.delete(url, { signal: abortSignal });
  } else {
    return Promise.reject('Invalid userId:' + userId);
  }
};

export const getUser = ({ userId, abortSignal }) => {
  if (!!userId) {
    const url = `${CATALOG_SERVICE_BASE_URL}${USER_URL}/${userId}`;
    return _axios.get(url, { signal: abortSignal });
  } else {
    return Promise.reject('Invalid userId:' + userId);
  }
};

export const getUserId = ({ email, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${USER_ID_URL}`;
  const body = { email: email };
  return _axios.post(url, body, { signal: abortSignal });
};

export const updateUser = ({ userId, body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${USER_URL}/${userId}`;
  return _axios.put(url, body, { signal: abortSignal });
};

export const getUsers = ({ companyId, abortSignal }) => {
  let url = `${CATALOG_SERVICE_BASE_URL}${USERS_URL}`;
  if (!!companyId) {
    url += `?company_id=${companyId}`
  }
  return _axios.get(url, { signal: abortSignal });
};

export const deleteCompany = ({ id, client_id, abortSignal }) => {
  let queryStrings = '';
  if (!!client_id) {
    queryStrings = `?client_id=${client_id}`;
  }
  const url = `${CATALOG_SERVICE_BASE_URL}${COMPANY_URL}/${id}${queryStrings}`;
  return _axios.delete(url, { signal: abortSignal });
};

export const updateCompany = ({ id, body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${COMPANY_URL}/${id}`;
  return _axios.put(url, body, { signal: abortSignal });
};

export const getCompanies = ({
  providersOnly: providers_only,
  emailDomain: email_domain,
  selectFields: select_fields,
  abortSignal
}) => {
  const queryParams = convertObjectToQueryStrParams({
    providers_only, email_domain, select_fields
  });
  const url = `${CATALOG_SERVICE_BASE_URL}${COMAPANIES_URL}${queryParams}`;
  return _axios.get(url, { signal: abortSignal });
};

export const getCompanyDetail = ({ id, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${COMPANY_URL}/${id}`;
  return _axios.get(url, { signal: abortSignal });
};

export const getCategories = ({ abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${CATEGORIES_URL}`;
  return _axios.get(url, { signal: abortSignal });
};

export const getServiceNewsNumLaunchedVersions = ({ service_id, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_NEWS_NUM_LAUNCHED_VERSIONS_URL}/${service_id}`;
  return _axios.get(url, { signal: abortSignal });
};

export const getServiceNewsSummary = ({ abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_NEWS_SUMMARY_URL}`;
  return _axios.get(url, { signal: abortSignal });
};

export const getServiceNews = ({ abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_NEWS_URL}`;
  return _axios.get(url, { signal: abortSignal });
};

// Body: provider_id, service_id, version_id, and event_id
export const createServiceNews = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_NEWS_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
};

// Path parameter: userId
// Query string parameter: newsType (e.g. platform or service)
export const markNewsRead = ({ userId, newsType, abortSignal }) => {
  if (!!userId && !!newsType) {
    const queryParams = convertObjectToQueryStrParams({
      news: newsType
    });
    const url = `${CATALOG_SERVICE_BASE_URL}${NEWS_MARK_NEWS_READ_URL}/${userId}${queryParams}`;
    return _axios.post(url, { signal: abortSignal });
  } else {
    return Promise.reject('Invalid userId:' + userId + 
                          ' or news type:' + newsType);
  }
};

// Path parameter: userId
export const hasUnreadNews = ({ userId, abortSignal }) => {
  if (!!userId) {
    const url = `${CATALOG_SERVICE_BASE_URL}${NEWS_UNREAD_NEWS_URL}/${userId}`;
    return _axios.get(url, { signal: abortSignal });
  } else {
    return Promise.reject('Invalid userId:' + userId);
  }
};

export const getObjectFromS3 = ({ key, abortSignal }) => {
  const url = `${CATALOG_SERVICE_S3_PROXY_URL}/${key}`;
  return _axios.get(url, { signal: abortSignal });
};

// The objectPath parameter follows the format of {s3_bucket_name}/{path_to_the_object}
export const uploadObjectToS3 = ({ objectPath, file, abortSignal }) => {
  if (objectPath) {
    const url = `${CATALOG_SERVICE_S3_PROXY_URL}/${objectPath}`;
    return _axios.put(url, file, {
      headers: { 'Content-Type': file.type },
      signal: abortSignal
    });
  } else {
    return Promise.reject('Invalid objectKey:' + objectPath);
  }
};

export const getSearchResults = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${SEARCH_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
};

export const getServiceCategories = ({ serviceId, abortSignal }) => {
  if (serviceId !== undefined && serviceId !== null) {
    const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_CATEGORIES_URL}/${serviceId}`;
    return _axios.get(url, { signal: abortSignal });
  } else {
    return Promise.reject('Invalid serviceId:' + serviceId);
  }
};

export const createMainService = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
};

export const updateMainService = ({ serviceId, body, abortSignal }) => {
  if (!!serviceId) {
    const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_URL}/${serviceId}`;
    return _axios.put(url, body, { signal: abortSignal });
  } else {
    return Promise.reject('Invalid serviceId:' + serviceId);
  }
};

export const createServiceVersion = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_VERSION_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
};

export const updateServiceVersion = ({ service_id, version_id, body, abortSignal }) => {
  if (!!service_id && !!version_id) {
    const queryParams = convertObjectToQueryStrParams({
      service_id, version_id
    });
    const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_VERSION_URL}${queryParams}`;
    return _axios.put(url, body, { signal: abortSignal });
  } else {
    return Promise.reject(`Invalid service_id=${service_id} or version_id=${version_id}`);
  }
};

export const updateServiceStatus = ({ serviceId, versionId, body, abortSignal }) => {
  if (serviceId && versionId) {
    const queryParams = convertObjectToQueryStrParams({
      version_id: versionId
    });
    const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_STATUS_URL}/${serviceId}${queryParams}`;
    return _axios.put(url, body, { signal: abortSignal });
  } else {
    return Promise.reject(`Invalid serviceId=${serviceId} or versionId=${versionId}`);
  }
};

export const deleteService = ({ serviceId, abortSignal }) => {
  if (!!serviceId) {
    const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_URL}/${serviceId}`;
    return _axios.delete(url, { signal: abortSignal });
  } else {
    return Promise.reject('Invalid serviceId:' + serviceId);
  }
};

export const deleteServiceVersion = ({ service_id, version_id, abortSignal }) => {
  if (!!service_id && !!version_id) {
    const queryParams = convertObjectToQueryStrParams({
      service_id, version_id
    });
    const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_VERSION_URL}${queryParams}`;
    return _axios.delete(url, { signal: abortSignal });
  } else {
    return Promise.reject(`Invalid service_id=${service_id} or version_id=${version_id}`);
  }
};

/**
 * Get service detail info for the service
 * @param {number} service_id service ID
 * @param {number} consumer_id the consumer's company ID to find out whether or not the user is subcribed to the specific version
 * @param {string} version_status status of the service version to get
 * @returns
 */
export const getServiceDetail = ({ service_id, consumer_id, version_status, abortSignal }) => {
  if (!!service_id) {
    const queryParams = convertObjectToQueryStrParams({
      consumer_id, version_status
    });
    const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_URL}/${service_id}${queryParams}`;
    return _axios.get(url, { signal: abortSignal });
  } else {
    return Promise.reject('Invalid service_id:' + service_id);
  }
};

export const getServices = ({ provider_id, is_launched, service_type, consumer_id, abortSignal }) => {
  const queryParams = convertObjectToQueryStrParams({
    provider_id, is_launched, service_type, consumer_id
  });
  const url = `${CATALOG_SERVICE_BASE_URL}${SERVICES_URL}${queryParams}`;
  return _axios.get(url, { signal: abortSignal });
};

export const getServiceVersions = ({ service_id, status, abortSignal }) => {
  if (!!service_id) {
    const queryParams = convertObjectToQueryStrParams({
      service_id, status
    });
    const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_VERSIONS_URL}${queryParams}`;
    return _axios.get(url, { signal: abortSignal });
  } else {
    return Promise.reject('Invalid service_id:' + service_id);
  }
};

export const getServiceVersionDetail = ({ service_id, version_id, abortSignal }) => {
  if (service_id !== undefined && version_id !== null) {
    const queryParams = convertObjectToQueryStrParams({
      service_id, version_id
    });
    const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_VERSION_URL}${queryParams}`;
    return _axios.get(url, { signal: abortSignal });
  } else {
    return Promise.reject(`Invalid service_id=${service_id} or version_id=${version_id}`);
  }
};

export const getMyAccount = ({ field, value, abortSignal }) => {
  const queryParams = convertObjectToQueryStrParams({
    field, value
  });
  const url = `${CATALOG_SERVICE_BASE_URL}${MY_ACCOUNT_URL}${queryParams}`;
  return _axios.get(url, { signal: abortSignal });
};

export const getSubscriptions = ({ consumer_id, provider_id, abortSignal }) => {
  const queryParams = convertObjectToQueryStrParams({ consumer_id, provider_id })
  const url = `${CATALOG_SERVICE_BASE_URL}${SUBSCRIPTIONS_URL}${queryParams}`;
  return _axios.get(url, { signal: abortSignal });
};

export const getSubscriptionDetail = ({ company_id, service_id, version_id, abortSignal }) => {
  if (!!company_id && !!service_id && !!version_id) {
    const queryParams = convertObjectToQueryStrParams({ company_id, service_id, version_id });
    const url = `${CATALOG_SERVICE_BASE_URL}${SUBSCRIPTION_URL}${queryParams}`;
    return _axios.get(url, { signal: abortSignal });
  } else {
    return Promise.reject(`Invalid service_id: ${service_id} or company_id: ${company_id} op version_id: ${version_id}`);
  }
};

export const createSubscription = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${SUBSCRIPTION_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
};

export const deleteSubscription = ({ company_id, service_id, version_id, abortSignal }) => {
  if (!!company_id && !!service_id && !!version_id) {
    const queryParams = convertObjectToQueryStrParams({ company_id, service_id, version_id });
    const url = `${CATALOG_SERVICE_BASE_URL}${SUBSCRIPTION_URL}${queryParams}`;
    return _axios.delete(url, { signal: abortSignal });
  } else {
    return Promise.reject(`Invalid service_id: ${service_id}, company_id: ${company_id} or version_id: ${version_id}`);
  }
};

/**
 * Creates the open api spec expectation in the mock server
 * https://www.mock-server.com/mock_server/using_openapi.html#generate_expectation_from_openapi
 * @param {string|object} apiSpec
 */
export const createApiMockServerExpectation = ({ apiSpec, abortSignal }) => {
  const url = `${CATALOG_SERVICE_MOCK_SERVER_URL}/mockserver/openapi`;
  let specPayload = (typeof apiSpec === "string" ? apiSpec : JSON.stringify(apiSpec || ""));
  let body = {
    specUrlOrPayload: specPayload
  };
  return _axios.put(url, body, { signal: abortSignal });
};

export const getTransferSourceList = ({ abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${DATA_TRANSFER_SOURCE}`;
  return _axios.get(url, { signal: abortSignal });
}

export const createDataTransferSource = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${DATA_TRANSFER_SOURCE}`;
  return _axios.post(url, body, { signal: abortSignal });
};

export const getDataTransferSource = ({ id, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${DATA_TRANSFER_SOURCE}/${id}`;
  return _axios.get(url, { signal: abortSignal });
}

export const deleteDataTransferSource = ({ id, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${DATA_TRANSFER_SOURCE}/${id}`;
  return _axios.delete(url, { signal: abortSignal });
}

export const updateDataTransferSource = ({ id, body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${DATA_TRANSFER_SOURCE}/${id}`;
  return _axios.put(url, body, { signal: abortSignal });
};

export const createDataTransferSubscription = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${DATA_TRANSFER_SUBSCRIPTION}`;
  return _axios.post(url, body, { signal: abortSignal });
};

export const getTransferSubscriptionList = ({ source_id, dest_id, status, exclude_status, dest_provider_id, abortSignal }) => {
  const queryParams = convertObjectToQueryStrParams({ source_id, dest_id, status, exclude_status, dest_provider_id })
  const url = `${CATALOG_SERVICE_BASE_URL}${DATA_TRANSFER_SUBSCRIPTION}${queryParams}`;
  return _axios.get(url, { signal: abortSignal });
};

export const checkTransferSubscription = ({ source_id, dest_provider_id, abortSignal }) => {
  const queryParams = convertObjectToQueryStrParams({ source_id, dest_provider_id })
  const url = `${CATALOG_SERVICE_BASE_URL}${DATA_TRANSFER_SUBSCRIPTION_CHECK}${queryParams}`;
  return _axios.get(url, { signal: abortSignal });
};

export const updateDataTransferSubscriptionStatus = ({ id, body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${DATA_TRANSFER_SUBSCRIPTION_STATUS}/${id}`;
  return _axios.put(url, body, { signal: abortSignal });
};

export const deleteDataTransferSubscription = ({ id, delete_service_subscription, abortSignal }) => {
  const queryParams = convertObjectToQueryStrParams({ delete_service_subscription });
  const url = `${CATALOG_SERVICE_BASE_URL}${DATA_TRANSFER_SUBSCRIPTION}/${id}${queryParams}`;
  return _axios.delete(url, { signal: abortSignal });
}

export const createDataTransferDestination = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${DATA_TRANSFER_DESTINATION}`;
  return _axios.post(url, body, { signal: abortSignal });
};

export const deleteDataTransferDestination = ({ id, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${DATA_TRANSFER_DESTINATION}/${id}`;
  return _axios.delete(url, { signal: abortSignal });
};

export const getTransferDestinationList = ({ provider_id, abortSignal }) => {
  const queryParams = convertObjectToQueryStrParams({ provider_id })
  const url = `${CATALOG_SERVICE_BASE_URL}${DATA_TRANSFER_DESTINATION}${queryParams}`;
  return _axios.get(url, { signal: abortSignal });
};

export const getMetricServiceAvailability = ({ body, abortSignal }) => {
  const url = `${METRIC_SERVICE_BASE_URL}${METRIC_SERVICE_AVAILABILITY_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
}

export const getMetricServiceResponseTime = ({ body, abortSignal }) => {
  const url = `${METRIC_SERVICE_BASE_URL}${METRIC_SERVICE_RESPONSE_TIME_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
}

export const getMetricServiceIntegrationLatency = ({ body, abortSignal }) => {
  const url = `${METRIC_SERVICE_BASE_URL}${METRIC_SERVICE_INTEGRATION_LATENCY_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
}

export const getMetricServiceDipLatency = ({ body, abortSignal }) => {
  const url = `${METRIC_SERVICE_BASE_URL}${METRIC_SERVICE_DIP_LATENCY_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
}

export const getMetricServiceSummary = ({ body, abortSignal }) => {
  const url = `${METRIC_SERVICE_BASE_URL}${METRIC_SERVICE_SUMMARY_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
}

export const getServiceProviderMetrics = ({ id, version, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_PROVIDER_METRICS}/${id}${version ? `/${version}` : ''}`;
  return _axios.get(url, { signal: abortSignal });
}

export const getMetricServiceProviderMetrics = ({ body, abortSignal }) => {
  const url = `${METRIC_SERVICE_BASE_URL}${METRIC_SERVICE_PROVIDER_METRICS_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
}

export const getServiceCountPerFilter = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${SERVICE_COUNT_PER_FILTER_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
}

export const createProxyApi = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PROXY_API_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
}

export const deleteProxyApi = ({ service_id, version_id, api_id, abortSignal }) => {
  if (!!service_id && !!version_id && !!api_id) {
    const queryParams = convertObjectToQueryStrParams({
      service_id, version_id, api_id
    });
    const url = `${CATALOG_SERVICE_BASE_URL}${PROXY_API_URL}${queryParams}`;
    return _axios.delete(url, { signal: abortSignal });
  } else {
    return Promise.reject(`Invalid parameters: \
      service_id=${service_id} \
      version_id=${version_id} \
      version_id=${api_id}`
    );
  }
};

export const getAccessRoles = ({ abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${ACCESS_ROLES_URL}`;
  return _axios.get(url, { signal: abortSignal });
};

export const reorderAccessRoles = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${ACCESS_ROLES_REORDER_URL}`;
  return _axios.put(url, body, { signal: abortSignal });
};

export const createAccessRole = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${ACCESS_ROLE_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
};

export const updateAccessRole = ({ id, body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${ACCESS_ROLE_URL}/${id}`;
  return _axios.put(url, body, { signal: abortSignal });
};

export const getAccessRoleDetail = ({ id, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${ACCESS_ROLE_URL}/${id}`;
  return _axios.get(url, { signal: abortSignal });
};

export const deleteAccessRoleDetail = ({ id, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${ACCESS_ROLE_URL}/${id}`;
  return _axios.delete(url, { signal: abortSignal });
};

export const createAccessRequest = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${ACCESS_REQUEST_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
};

export const updateAccessRequestStatus = ({ id, body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${ACCESS_REQUEST_STATUS_URL}/${id}`;
  return _axios.put(url, body, { signal: abortSignal });
};

export const getAccessRequests = ({
  status,
  selectFields: select_fields,
  abortSignal
}) => {
  const queryParams = convertObjectToQueryStrParams({
    status, select_fields
  });
  const url = `${CATALOG_SERVICE_BASE_URL}${ACCESS_REQUESTS_URL}${queryParams}`;
  return _axios.get(url, { signal: abortSignal });
};

export const getAccessRequestDetail = ({
  id,
  requesterUsername: requester_username,
  selectFields: select_fields,
  abortSignal
}) => {
  const queryParams = convertObjectToQueryStrParams({
    id, requester_username, select_fields
  });
  const url = `${CATALOG_SERVICE_BASE_URL}${ACCESS_REQUEST_URL}${queryParams}`;
  return _axios.get(url, { signal: abortSignal });
};

// 
// PLATFORM NEWS - SUMMARY OF CAPABILITIES
//

export const getPlatformSummaryOfCapabilities = ({ abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PLATFORM_SUMMARY_CAPABILITIES_URL}`;
  return _axios.get(url, { signal: abortSignal });
};

export const getPlatformSummaryCapability = ({ id, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PLATFORM_SUMMARY_CAPABILITY_URL}/${id}`;
  return _axios.get(url, { signal: abortSignal });
};

export const createPlatformSummaryCapability = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PLATFORM_SUMMARY_CAPABILITY_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
};

export const updatePlatformSummaryCapability = ({ id, body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PLATFORM_SUMMARY_CAPABILITY_URL}/${id}`;
  return _axios.put(url, body, { signal: abortSignal });
};

export const deletePlatformSummaryCapability = ({ id, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PLATFORM_SUMMARY_CAPABILITY_URL}/${id}`;
  return _axios.delete(url, { signal: abortSignal });
};

export const reorderPlatformSummaryCapabilities = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PLATFORM_SUMMARY_CAPABILITY_REORDER_URL}`;
  return _axios.put(url, body, { signal: abortSignal });
};

//
// PLATFORM NEWS - RELEASES
//

export const getPlatformReleases = ({ abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PLATFORM_RELEASES_URL}`;
  return _axios.get(url, { signal: abortSignal });
};

export const getPlatformRelease = ({ id, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PLATFORM_RELEASE_URL}/${id}`;
  return _axios.get(url, { signal: abortSignal });
};

export const createPlatformRelease = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PLATFORM_RELEASE_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
};

export const updatePlatformRelease = ({ id, body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PLATFORM_RELEASE_URL}/${id}`;
  return _axios.put(url, body, { signal: abortSignal });
};

export const deletePlatformRelease = ({ id, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PLATFORM_RELEASE_URL}/${id}`;
  return _axios.delete(url, { signal: abortSignal });
};

export const createPlatformReleaseNews = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PLATFORM_RELEASE_NEWS_URL}`;
  return _axios.post(url, body, { signal: abortSignal });
};

export const getPlatformReleaseNews = ({ id, body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PLATFORM_RELEASE_NEWS_URL}/${id}`;
  return _axios.get(url, body, { signal: abortSignal });
};

export const updatePlatformReleaseNews = ({ id, body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PLATFORM_RELEASE_NEWS_URL}/${id}`;
  return _axios.put(url, body, { signal: abortSignal });
};

export const deletePlatformReleaseNews = ({ id, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PLATFORM_RELEASE_NEWS_URL}/${id}`;
  return _axios.delete(url, { signal: abortSignal });
};

export const reorderPlatformReleaseNews = ({ body, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${PLATFORM_RELEASE_NEWS_REORDER_URL}`;
  return _axios.put(url, body, { signal: abortSignal });
};

// END PLATFORM NEWS 

export const verifyIvt = ({ companyId, ivt, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${VERIFY_IVT_URL}?company_id=${companyId}&ivt=${ivt}`;
  return _axios.get(url, { signal: abortSignal });
};

export const verifyServiceOwnership = ({ providerId, serviceId, abortSignal }) => {
  const url = `${CATALOG_SERVICE_BASE_URL}${VERIFY_SERVICE_OWNERSHIP_URL}?provider_id=${providerId}&service_id=${serviceId}`;
  return _axios.get(url, { signal: abortSignal });
};

export const getVirusScanStautsForFile = ({ objectPath, abortSignal }) => {
  const queryParams = convertObjectToQueryStrParams({
    name: objectPath
  });
  const url = `${VIRUS_SCAN_BASE_URL}${VIRUS_SCAN_GET_SCAN_STATUS}${queryParams}`;
  return _axios.get(url, { signal: abortSignal });
};
