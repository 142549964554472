import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // flag whether or not to show the alert component
  show: false,

  // alert message
  message: '',

  // The possible severities of the alert:
  // error, info, success, warning
  severity: 'success',
}

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert: (state, action) => {
      const { show, message, severity } = action.payload;
      if (show && message && severity) {
        state.show = show ? show : false;
        state.message = show ? message: '';
        state.severity = show ? severity : 'success';
      }
    },

    hideAlert: (state) => {
      state.show = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setAlert, hideAlert } = alertSlice.actions

export const selectAlert = state => state.alert;

export default alertSlice.reducer