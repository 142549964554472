import validator from "validator";
import { isInvalidTextField, isValidPhoneNumber } from "./validation";

export const JUSTIFICATION_PURPOSES_ID_MAP = {
    'Data Analysis': 0,
    'Software Development': 1,
    'Other': 2
}

export const JUSTIFICATION_FAMILIARITY_ID_MAP = {
    'Not at all': 0,
    'Somewhat': 1,
    'Very': 2
}

// TEAM ID for 'None of the above' option in the teams list
export const FIRST_TIME_USER_TEAM_ID = -1;

export const DEFAULT_ACCESS_REQUEST_INPUT = {
    // Team information step input
    organization: '',
    organization_address: '',
    organization_country: '',
    team_name: '',
    team_poc_name: '',
    team_poc_email: '',
    team_poc_phone: '',
    team_url: '',
    team_logo_url: '',
    // Justifcation step input
    justification: '',
    purposes: {
        selectedIds: new Set(),
        otherText: ''
    },
    api_familiarity: 0,
    aviation_data_familiarity: 0,
    // requester information
    requester_name: '',
    requester_username: ''
};

export const validateTeamInfoInput = (input) => {
    let errors = {};
    errors.organization = isInvalidTextField(input?.organization, 1, 100, ' ,.-+@');
    errors.organization_address = isInvalidTextField(input?.organization_address, 1, 500, ' ,.-/');
    errors.organization_country = isInvalidTextField(input?.organization_country, 1, 100, ' ,.-+@');
    errors.team_name = isInvalidTextField(input?.team_name, 1, 100, ' ,.-+@');
    errors.team_poc_name = isInvalidTextField(input?.team_poc_name, 1, 100, " .,-'");

    if (!validator.isEmail(input?.team_poc_email)) {
        errors.team_poc_email = 'Invalid email'
    }

    if (!isValidPhoneNumber(input?.team_poc_phone)) {
        errors.team_poc_phone = 'Invalid phone number format'
    }

    if (!validator.isURL(input?.team_url)) {
        errors.team_url = 'Invalid URL format'
    }

    if (!validator.isURL(input?.team_logo_url)) {
        errors.team_logo_url = 'Invalid URL format'
    }

    return errors;
}

export const validateJustificationInfo = (input) => {
    let errors = {
        purposes: {
            selectedIds: null,
            otherText: null
        }
    };
    errors.justification = isInvalidTextField(input?.justification, 1, 500, " .,()+-'*/;:[]?_=\n\r");
    errors.purposes.selectedIds = input?.purposes?.selectedIds?.size ? null : 'Please select at least one purpose';

    // If Other purpose is selected, validate the teext in the other text field.
    if (input?.purposes?.selectedIds?.has(JUSTIFICATION_PURPOSES_ID_MAP['Other'])) {
        if (!input.purposes.otherText) {
            errors.purposes.otherText = 'The purpose is required';
        } else {
            errors.purposes.otherText = isInvalidTextField(input?.purposes?.otherText, 1, 100, ' ,.-+@');
        }
    }

    errors.api_familiarity = input?.api_familiarity !== null ? null : 'Please check one';
    errors.aviation_data_familiarity = input?.aviation_data_familiarity !== null ? null : 'Please check one';

    return errors;
}

export const deserializeAccessRequestDetail = (access_request) => {
    let rtn = {};

    if (access_request) {
        rtn = { ...access_request };
        rtn['purposes']['selectedIds'] = new Set(access_request['purposes']['selectedIds'])
    }

    return rtn;
}
