import { useParams, useSearchParams } from "react-router-dom";

import RequireServiceOwnership from "../../require/RequireServiceOwnership";
import ServiceVersionRegistration from "../registration/ServiceVersionRegistration";

/**
 * This provides access to a service version's data when an existing version is
 * being viewed, edited, or deleted.  This is actually just a pass-through
 * function to set a few key parameters that it passes along to
 * ServiceVersionRegistration.
 */

const ManageServiceVersion = ({ admin }) => {
    const { versionId } = useParams();
    const [searchParams] = useSearchParams();

    return (
        <RequireServiceOwnership serviceId={searchParams.get("serviceId")}>
            <ServiceVersionRegistration
                admin={admin}
                versionId={versionId}
                action={searchParams.get("action")}
            />
        </RequireServiceOwnership>
    );
};

export default ManageServiceVersion;
