import ClearIcon from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search'

import classnames from 'classnames';

import DipIconButton from './DipIconButton';
import FieldLabel from './FieldLabel';
import SearchTextField from '../search/SearchTextField';
import '../search/SearchTextField.css';


/**
 * This creates a labelled search input field with a clear icon and an optional
 * search icon.  If the caller's search feature is updated dynamically as
 * the user types, then the search icon should not be included and method
 * handleEnter should not be defined.
 *
 * @param {string}   id           the unique id for the text field
 * @param {string}   label        the label displayed to the user
 * @param {string}   labelStyle   additional USWDS style classes to add to the
 *                                label, e.g. 'margin-top-0' to remove a margin
 * @param {string}   value        contents to display in the text field
 * @param {function} setValue     method to call to update the value as the
 *                                user types
 * @param {function} handleEnter  (optional) method to call to instigate the 
 *                                search if the user clicks the Search icon.
 *                                The search icon is omitted if this method
 *                                is not defined.
 * @param {function} handleClear  method to call to clear the search field
 * @param {object}   props        any remaining props will be applied to the
 *                                containing wrapper
 */

const FilterInput = ({
    id,
    label,
    labelStyle,
    value,
    setValue,
    handleEnter,
    handleClear,
    ...props
}) => {

    const labelClasses = classnames(labelStyle, 'text-italic');

    return (
        <div style={{ width: '100%', marginBottom: '1rem' }} {...props} >
            <FieldLabel
                id={`${id}-label`}
                entryId={id}
                label={label}
                labelStyle={labelClasses}
            />

            <div style={{ display: 'flex', width: '100%' }} >
                <SearchTextField
                    id={id}
                    value={value}
                    setValue={setValue}
                    handleEnter={handleEnter}
                    style={{ display: 'flex' }}
                />

                {
                    !!handleEnter &&
                    <DipIconButton
                        id={`${id}-search`}
                        aria-label={`submit ${label}`}
                        onClick={handleEnter}
                        disabled={!value}
                    >
                        <Search />
                    </DipIconButton>
                }

                <DipIconButton
                    id={`${id}-clear`}
                    aria-label={`clear ${label}`}
                    onClick={handleClear}
                    disabled={!value}
                >
                    <ClearIcon />
                </DipIconButton>
            </div>
        </div>
    );
};

export default FilterInput;
