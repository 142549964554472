import Grid from '@mui/material/Grid';

import ContactCard from '../common/ContactCard';
import MinorSubHeading from '../common/MinorSubHeading';

/**
 * This provides the display of the service support information when the user
 * clicks on the service from the Subscriptions page or service card.
 */

const ServiceSupport = ({ loadingService, service,  }) => {

    // If the service is not ready yet, bail out now to keep the code cleaner.
    if (loadingService || !service) {
        return null;
    }

    // If the service has no POC or tech support, bail
    if (!service.point_of_contacts && !service.technical_support_contacts) {
        return null;
    }

    /**
     * Gets the set of contact cards to display.
     *
     * @param {string}        label     label to display over the contacts
     * @param {array(object)} contacts  either the service's point_of_contacts
     *                                  or technical_support_contacts.  this
     *                                  should have already been checked as
     *                                  non-null before calling this method
     */
    const getContactCards = (label, contacts) => (
        <Grid container item xs={12}
            sx={{
                mb: '1rem'
            }}
        >
            <MinorSubHeading
                label={label}
                labelStyle='margin-y-0'
            />
            {
                contacts.map((contact, idx) => {
                    return (
                        <Grid key={idx} item xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mt: '.2rem',
                                pl: '1rem',
                                border: '1px solid',
                                borderRadius: '5px',
                                borderColor: '#C4C4C4',
                            }}
                        >
                            <ContactCard info={contact} />
                        </Grid>
                    )
                })
            }
        </Grid>
    );

    return (
        <Grid container
            sx={{
                backgroundColor: 'white',
                padding: '1rem',
            }}
        >
            {
                service?.point_of_contacts &&
                getContactCards(
                    'Point of Contact',
                    service.point_of_contacts
                )
            }
            {
                service?.technical_support_contacts &&
                getContactCards(
                    'Technical Support',
                    service.technical_support_contacts
                )
            }
        </Grid>
    );
};

export default ServiceSupport;
