import { Auth } from "aws-amplify";

import ActionButton from "../common/ActionButton";
import BodyText from "../common/BodyText";
import ExternalLinkButton from "../common/ExternalLinkButton"
import MajorHeading from "../common/MajorHeading";

const GUEST_SERVICES_URL = 'https://guest.nasa.gov/';
const REDIRECT_LABEL = `You will be redirected to ${GUEST_SERVICES_URL} (opens in a new tab)`;

/**
 * This component is for the NASA Guest Account step in the request access
 * process.  It effectively consists of two pages.  Page 1 encourages the user
 * to create a NASA Guest Account and page 2 gives the user an important notice
 * before continuing on to NASA Guest Services.
 *
 * @param {boolean}  showNGSnotice        whether or not to show the NASA Guest
 *                                        Service account notice; false means
 *                                        to display page 1 and true means to
 *                                        display page 2
 * @param {function} setShowNGSnotice     function to set showNGSnotice value
 * @param {boolean}  isUserAuthenticated  whether or not the user is
 *                                        authenticated
 *
 * @returns JSX for page 1 (showNGSnotice=false) or page 2 (showNGSnotice=true)
 *          of the NASA Guest Account step
 */
const NasaGuestAccountStep = ({
    showNGSnotice,
    setShowNGSnotice,
    isUserAuthenticated
}) => {

    // There is actually a third case that can be displayed when
    // showNGSnotice=false and isUserAuthenticated=true -- this case occurs
    // if the user has advanced to the 3rd step and then clicks the Back
    // button.  In this case, the user is just told to click Next to continue.
    return (
        showNGSnotice ? (
            <>
                <MajorHeading
                    label='IMPORTANT NOTICE'
                />
                <BodyText
                    label='When creating a NASA Guest Service account, please:'
                    labelStyle='margin-top-3 margin-bottom-3'
                />
                <BodyText
                    labelStyle='margin-top-0 margin-bottom-0'
                >
                    <span><em><strong>Do</strong></em> use your affiliation’s email address (e.g., <em>@universityxyz.edu, @business.com</em>)</span>
                </BodyText>
                <BodyText
                    labelStyle='margin-top-0 margin-bottom-4'
                >
                    <span><em><strong>Do not</strong></em> use your personal email address (e.g., <em>@gmail.com, @yahoo.com</em>) as these will not be accepted</span>
                </BodyText>

                <ExternalLinkButton
                    text='Visit NASA Guest Services'
                    href={GUEST_SERVICES_URL}
                    target='_blank'
                    rel='guestAccountCreation'
                    aria-describedby='redirect-label'
                />
                <BodyText
                    id='redirect-label'
                    label={REDIRECT_LABEL}
                    labelStyle="font-body-2xs line-height-body-5 margin-top-2"
                />
            </>
        ) : isUserAuthenticated ? (
            <BodyText
                labelStyle='margin-bottom-2 margin-top-0'
            >
                <span>Click <strong>Next</strong> to continue signing up</span>
            </BodyText>
        ) : (
            <>
                <BodyText
                    label='Begin access for collaboration and information sharing with NASA by using a Guest Account!'
                    labelStyle='margin-bottom-2 margin-top-0'
                />
                <ActionButton
                    text='Visit NASA Guest Services'
                    onClick={() => setShowNGSnotice(true)}
                />
                <BodyText
                    label={REDIRECT_LABEL}
                    labelStyle="font-body-2xs line-height-body-5 margin-top-2"
                />
                <BodyText
                    label='Once you have created a NASA Guest Account,
                           please return to this page and sign in below.'
                    labelStyle="font-body-2xs line-height-body-5 margin-top-0"
                />
                <BodyText
                    label='Sign in to your NASA Guest Account'
                    labelStyle="margin-bottom-2 margin-top-6"
                />
                <ActionButton
                    text='Sign in'
                    onClick={() => Auth.federatedSignIn()}
                />
            </>
        )
    );
};

export default NasaGuestAccountStep;
