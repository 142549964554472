import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setPageTitle } from '../app/slices/page';

import './Faq.css';
import questionAndAnswers from '../assets/faq/faq.json'
import MajorHeading from '../components/common/MajorHeading'

const Faq = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPageTitle('Support: FAQ'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card id='faq' sx={{ p: '1rem' }}>
            <CardContent>
                <MajorHeading
                    label='Frequently Asked Questions'
                    labelStyle='text-center'
                />
                <table className="usa-table faq-table usa-table--borderless width-auto" role="presentation" >
                    <tbody>
                        {
                            questionAndAnswers.map((qna, idx) =>
                                <Fragment key={idx}>
                                    <tr>
                                        <td className="text-bold">{qna['Question']}</td>
                                    </tr>
                                    <tr>
                                        <td>{qna['Answer']}</td>
                                    </tr>
                                </Fragment>
                            )
                        }
                    </tbody>
                </table>
            </CardContent>
        </Card>
    )
};

export default Faq;
