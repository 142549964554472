import Grid from '@mui/material/Grid';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { markNewsRead } from '../apiClient';
import { setAlert } from '../app/slices/alert';
import { fetchServiceNewsSummary, fetchServiceNews, 
         selectFetchingServiceNews, selectNumRegisteredServices, 
         selectNumServiceProviders, selectServiceNews, setUnreadServiceNews 
       } from '../app/slices/news';
import { setPageTitle } from '../app/slices/page';
import { selectUserId } from '../app/slices/user';
import { useAbortController } from '../hooks';
import serviceNewsImg from '../assets/news/service_news.png';
import CircularProgressIndicator from '../components/common/CircularProgressIndicator';
import DipLink from '../components/common/DipLink';
import MinorHeading from '../components/common/MinorHeading';
import NewsPage from '../components/news/NewsPage';
import { getFormattedDateFromString } from '../utils/timeUtils';
import { getServiceDetailPageUrl } from '../utils/navigation';
import { paginate } from '../utils/utils';


/**
 * This provides the "Service News" page, which informs users of certain
 * events related to services, such as the service launches.
 */

function ServiceNews() {

    const dispatch = useDispatch();

    const { abortSignalRef, isCancel } = useAbortController();

    const userId = useSelector(selectUserId);

    const fetchingServiceNews = useSelector(selectFetchingServiceNews);
    const numServiceProviders = useSelector(selectNumServiceProviders);
    const numRegisteredServices = useSelector(selectNumRegisteredServices);
    const serviceNews = useSelector(selectServiceNews);
    const [latestNews, setLatestNews] = useState({});
    const [previousNews, setPreviousNews] = useState([]);
    const [displayedPreviousNews, setDisplayedPreviousNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [numItemsPerPage, setNumOfItemsPerPage] = useState(5);

    // Initialize the data
    useEffect(() => {
        dispatch(setPageTitle('Service News'));
        setNewsRead();
        initSummary();
        initServiceNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Separate the latest news from the previous news
    useEffect(() => {
        const trimmedNews = removeDuplicateEvents(serviceNews);
        organizeServiceNews(trimmedNews);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceNews]);

    // Sets the status that the news has been read.
    const setNewsRead = async () => {
        try {
            // Update the back end
            markNewsRead({userId, newsType:"service", abortSignalRef});

            // Update the front end
            dispatch(setUnreadServiceNews(false));
        } catch (err) {
            if (isCancel(err)) {
                return;
            }
            console.error('failed to set service news read status:',
                err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to set service news read status',
                severity: 'error'
            }));
        }
    };

    // Initialize the information displayed in the summary block at the top
    const initSummary = () => {
        dispatch(fetchServiceNewsSummary({
            abortSignalRef: abortSignalRef
        }))
        .unwrap()
        .catch(err => {
            if (err === 'cancelled')
                return;
    
            console.error('Failed to initialize the service news summary:', 
                err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get initialize service news summary',
                severity: 'error'
            }));
        });
    };

    // Initialize the service news
    const initServiceNews = () => {
        dispatch(fetchServiceNews({
            abortSignalRef: abortSignalRef
        }))
        .unwrap()
        .catch(err => {
            if (err === 'cancelled')
                return;
    
            console.error('Failed to initialize the service news', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to initialize the service news ',
                severity: 'error'
            }));
        });
    };

    // Set which news to display based on pagination
    useEffect(() => {
        setDisplayedPreviousNews(paginate(previousNews,
            numItemsPerPage, currentPage));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousNews, currentPage, numItemsPerPage]);

    // For paginating the news displayed
    const setPageFromPagination = (zeroBasedPage) => {
        setCurrentPage(zeroBasedPage + 1);
    }

    // For paginating the news displayed
    const setRowsPerPageFromPagination = (rowsPerPage) => {
        setCurrentPage(1);
        setNumOfItemsPerPage(rowsPerPage);                                          }

    // If a service was launched then relaunched in short order for whatever
    // reason, there will be duplicate events.  Keep only the most recent.
    const removeDuplicateEvents = (news) => {
        const newsMap = new Map();
        if (news) {
            news.forEach( item => {
                const primaryKey = getPrimaryKey(item);
                const secondaryKey = getSecondaryKey(primaryKey, item);
                if (!newsMap.has(secondaryKey)) {
                    newsMap.set(secondaryKey, item);
                } else {
                    const prevItem = newsMap.get(secondaryKey);
                    const prevDate = Date.parse(prevItem['event_timestamp']);
                    const currDate = Date.parse(item['event_timestamp']);
                    if (prevDate <= currDate) {
                        newsMap.delete(secondaryKey);
                        newsMap.set(secondaryKey, item);
                    }
                }
            })
        }
        const trimmedNews = Array.from(newsMap.values());

        return trimmedNews;
    };

    /**
     * Organize the news by month, provider, and type of event.
     */
    const organizeServiceNews = ( news ) => {
        if (news) {
            let newsCollection = [];
            let lastMonthYear = "";
            let newsForMonth = {};

            news.forEach( item => {
                let monthYear = getFormattedDateFromString(
                        item['event_timestamp'], "MMMM YYYY");

                // One data structure per month
                if (monthYear !== lastMonthYear) {
                    lastMonthYear = monthYear;
                    newsForMonth = {
                        date: monthYear,
                        newsByProviderAndEventArr: []
                    };
                    newsCollection.push(newsForMonth);
                }

                // Within the month, organize the news by provider and event type
                let newsByProviderAndEventArr = newsForMonth.newsByProviderAndEventArr;
                let newsForProviderAndEvent = newsByProviderAndEventArr.find(newsItem =>
                     newsItem.providerId === item.provider_id &&
                     newsItem.eventId === item.event_id
                );

                // New provider and event type for this month
                if (!newsForProviderAndEvent) {
                    newsForProviderAndEvent = {
                        providerId: item.provider_id,
                        eventId: item.event_id,
                        title: `${item.event_name} by ${item.provider_abbreviation}`,
                        primaryKey: `${getPrimaryKey(item)}`,
                        news: []
                    };

                    // NASA services get displayed after other service providers
                    if (item.provider_abbreviation === 'NASA') {
                        newsByProviderAndEventArr.push(newsForProviderAndEvent);
                    }
                    else {
                        newsByProviderAndEventArr.unshift(newsForProviderAndEvent);
                    }
                }

                // Store the news event for this provider and event type
                newsForProviderAndEvent.news.push({
                    service: `${item.service_name} v${item.version_id}`,
                    secondaryKey: `${getSecondaryKey(newsForProviderAndEvent.primaryKey, item)}`,
                    link: getServiceDetailPageUrl(item.service_id, item.provider_id)
                });
            });
            setLatestNews(newsCollection.shift());
            setPreviousNews(newsCollection);
        }
    };

    /**
     * Gets the primary key for a news block consisting of the timestamp,
     * event id, and provider id.
     */
    const getPrimaryKey= (item) => {
         const key = `${getFormattedDateFromString(item.event_timestamp, "YYYYMM")}-${item.event_id}-${item.provider_id}`;

         return key;
    };

    /**
     * Gets the secondary key for a news block consisting of the primary key
     * plus the service id and version id.
     */
    const getSecondaryKey= (primaryKey, item) => {
         const key = `${primaryKey}-${item.service_id}-${item.version_id}`;

         return key;
    };

    /**
     * Gets the text to display in the bullets of the summary block.
     *
     * @return {array(string)}  summary items to display in the bulleted list
     */
    const getSummary = () => {
        const summaryArr = [];

        const numServiceProvidersStr =
            `${numServiceProviders} Service Providers`;
        summaryArr.push(numServiceProvidersStr);

        const numRegisteredServicesStr =
            `${numRegisteredServices} Launched Services`;
        summaryArr.push(numRegisteredServicesStr);

        return summaryArr;
    };

    /**
     * Gets the JSX for the latest news block.
     */
    const getLatestNewsBlock = () => {
        if (fetchingServiceNews || !latestNews) {
            return <CircularProgressIndicator altText='Loading latest news...' />;
        }
        return (
            <MonthBlock key='latest' monthlyNews={latestNews} />
        );
    };

    /**
     * Gets the JSX for the previous news block.
     */
    const getPreviousNewsBlock = () => {
        if (fetchingServiceNews || !previousNews || !previousNews.length) {
            return <CircularProgressIndicator altText='Loading previous news...' />;
        }

        return (
            <>
                {
                    displayedPreviousNews.map((prior, idx) => (
                        <MonthBlock key={`${idx}`} monthlyNews={prior} />
                    ))
                }
            </>
        );
    };

    return (
        <NewsPage
            newsImg={serviceNewsImg}
            loading={fetchingServiceNews || !serviceNews || !serviceNews?.length}
            highlightTitle='Services by the Numbers'
            highlightItems={getSummary()}
            highlightKeyPrefix='summary'
            latestTitle='Latest News'
            latestContent={getLatestNewsBlock()}
            earlierTitle='Previous News'
            earlierContent={getPreviousNewsBlock()}
            initialRowsPerPage={numItemsPerPage}
            page={currentPage - 1}
            pageCallback={setPageFromPagination}
            rowsPerPageCallback={setRowsPerPageFromPagination}
            numRows={!!previousNews ? previousNews.length : 0}
            units='Months'
        />
    );
};

/**
 * Creates the heading for a month's updates as "<month> <year>".
 *
 * @param {string} dateStr   formatted "<month> <year>" of one of the
 *                           events in this month
 *
 * @return {JSX} the heading for a month block, including the border
 */
const MonthHeading = ({dateStr}) => (
    <Grid item xs={12}
        sx={{
            marginTop: '1.5rem',
            borderTop: 2,
            width: '100%'
        }}
    >
        <MinorHeading
            label={dateStr}
            labelStyle='margin-left-1 margin-top-1 margin-bottom-0'
        />
    </Grid>
);

/**
 * Formats the display block for a single month.
 *
 * @param {object} monthlyNews  news for the month
 *
 * @return JSX for the news display for a month
 */
const MonthBlock = ({monthlyNews}) => {
    if (!monthlyNews || !monthlyNews.newsByProviderAndEventArr) {
        return null;
    }

    return (
        <Grid container item xs={12}
            sx={{
                width: '100%'
            }}
        >
            <MonthHeading dateStr={monthlyNews.date} />
            <ul className='usa-list margin-top-1'>
              {
                monthlyNews.newsByProviderAndEventArr.map((item, idx) => (
                    <li key={item.primaryKey} className='line-height-sans-1'>
                        {item.title}
                        <ul className='usa-list margin-top-1'>
                          {
                            item.news.map((subitem, idx) => (
                                <li key={subitem.secondaryKey}
                                    className='line-height-sans-1 margin-bottom-0'
                                >
                                    <DipLink text={subitem.service}
                                        href={subitem.link}
                                    />
                                </li>
                            ))
                          }
                        </ul>
                    </li>
                ))
              }
            </ul>
        </Grid>
    );
};

export default ServiceNews;
