import classnames from 'classnames';
import { Link } from 'react-router-dom';

import ActionButton from './ActionButton';


/**
 * This creates a button which is actually a link to another page.  It is
 * based on the USWDS link which satisfies Section 508 accessibility
 * requirements.
 *
 * @param {string}   text      the button text
 * @param {string}   href      URL to navigate to upon button click
 * @param {boolean}  isDelete  true changes the color of the button to red,
 *                             such as for a "Delete" button
 * @param {boolean}  disabled  true to disable the link button
 * @param {object}   props     any other custom proprties
 */
const LinkButton = ({
    text,
    href,
    isDelete=false,
    disabled=false,
    ...props
}) => {

    if (href) {

        // Even though it is a link, it is styled with the button styles.
        const classes = classnames('usa-button',
            {
                'usa-button--secondary' : isDelete
            }
        );

        if (!disabled) {
            return (
                <Link className={classes} to={href} {...props} >
                   {text}
                </Link>
            );
        } else {
            // If the link is disabled, just return a disabled action button
            return (
                <ActionButton 
                   text={text}
                   onClick={() => {} }
                   disabled
                   {...props}
                />
            );
        }
    }

    console.error(`LinkButton ${text} missing href`);
    return null;
}

export default LinkButton;
