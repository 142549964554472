import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import validator from 'validator';

import { sendFeedback } from '../apiClient';
import { setAlert } from '../app/slices/alert';
import { setPageTitle } from '../app/slices/page';
import ActionButton from '../components/common/ActionButton';
import BodyText from '../components/common/BodyText';
import MajorHeading from '../components/common/MajorHeading';
import ProcessingOverlay from "../components/common/ProcessingOverlay";
import Select from '../components/common/Select';
import { SELECT_TITLE } from '../components/common/Select';
import TextArea from '../components/common/TextArea';
import TextField from '../components/common/TextField';
import { useAbortController } from '../hooks';
import { getCurrentAuthenticatedUser } from '../utils/auth';
import { isInvalidTextField, isErrorObjectEmpty } from '../utils/validation';


const Feedback = () => {
    const { abortSignalRef, isCancel } = useAbortController();
    const formRef = useRef(null);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [valid, setValid] = useState({
        valid: true,
        message: ''
    });
    const [accountDetails, setAccountDetails] = useState({
        username: '',
        name: '',
        email: '',
        cognitoGroups: null
    });
    const [feedback, setFeedback] = useState({
        email: '',
        name: '',
        topic: '',
        subject: '',
        body: ''
    });

    useEffect(() => {
        dispatch(setPageTitle('Support: Feedback'));

        getCurrentAuthenticatedUser()
        .then(async (user) => {
            if (user) {
                let accountDetails = {
                    cognitoGroups: user.signInUserSession.accessToken.payload['cognito:groups'],
                    email: user.attributes.email,
                    name: user.attributes.given_name && user.attributes.family_name ? `${user.attributes.given_name}    ${user.attributes.family_name}` : user.username,
                    username: user.username
                }

                // set data from cognito user object
                setAccountDetails(accountDetails);
                updateFeedback({
                    email: accountDetails.email,
                    name: accountDetails.name ?? ''
                })
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateFeedback = (update) => {
        setFeedback({ ...feedback, ...update })
    }

    const resetFeedback = () => {
        updateFeedback({
            email: accountDetails.email ?? '',
            name: accountDetails.name ?? '',
            topic: '',
            subject: '',
            body: ''
        })
    }

    const checkValidity = (input) => {

        let errors = {}
        if (!input?.topic || (SELECT_TITLE === input?.topic)) {
            errors.topic = 'Please select a topic';
        }
        errors.name = isInvalidTextField(input?.name, 1, 100, " .,-/()")
        errors.subject = isInvalidTextField(input?.subject, 1, 200, " .,-_()/'?!")
        errors.body = isInvalidTextField(input?.body, 1, 1000, " .,-_()/'\n?!")

        if (!validator.isEmail(input?.email)) {
            errors.email = 'Invalid email format'
        }

        setFormErrors(errors)

        let valid = formRef.current.reportValidity && isErrorObjectEmpty(errors)

        let message = ''

        if (!valid) {
            message = 'Missing required fields'
        }

        return { valid, message }
    }

    const publishEmail = () => {
        const cleanup = () => {
            setLoading(false)
            resetFeedback()
        };

        let validity = checkValidity(feedback)
        setValid(validity)

        if (validity.valid) {
            setLoading(true)

            let feedbackRequest = {
                email: feedback.email ?? '',
                name: feedback.name ?? '',
                topic: (!!feedback.topic && feedback.topic !== SELECT_TITLE) ?
                            feedback.topic : '',
                subject: feedback.subject ?? '',
                body: feedback.body ?? ''
            }

            sendFeedback({ body: feedbackRequest,
                           abortSignal: abortSignalRef?.current })
            .then(() => {
                dispatch(setAlert({
                    show: true,
                    message: 'Thank you for your feedback!',
                    severity: 'success'
                }));

                cleanup();
            }).catch((err) => {
                if (isCancel(err)) {
                    return;
                }
                dispatch(setAlert({
                    show: true,
                    message: 'Failed to send feedback',
                    severity: 'error'
                }));

                cleanup();
            });
        }
    }

    return (
        <Fragment>
            <ProcessingOverlay open={loading} msg='Sending feedback...' />

            <Card id='feedback' sx={{ p: '1rem' }}>
                <CardContent >
                    <MajorHeading
                        label='Feedback Form'
                        labelStyle='text-center'
                    />
                    <BodyText
                        label='Send us questions, report issues,
                               or make requests here.'
                        labelStyle='text-center'
                    />
                    <BodyText
                        label="By default, the message will include the
                               account's username and email contact
                               information."
                        labelStyle='text-center text-italic'
                    />
                    <BodyText
                        label='To receive a response to a different
                               address, please enter that here.'
                        labelStyle='text-center text-italic margin-top-0 margin-bottom-2'
                    />
                    <form ref={formRef} sx={{ pt: '1rem' }}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={6} >
                                <TextField
                                    id='name'
                                    name='name'
                                    label='Name'
                                    labelStyle='margin-top-2'
                                    value={feedback.name}
                                    onChange={(e) =>
                                        updateFeedback({name: e.target.value})}
                                    required
                                    errorMessage={formErrors?.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <TextField
                                    id='email'
                                    name='email'
                                    label='Email address'
                                    labelStyle='margin-top-2'
                                    value={feedback.email}
                                    onChange={(e) =>
                                        updateFeedback({email: e.target.value})}
                                    errorMessage={formErrors?.email}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Select
                            id='topic'
                            name='topic'
                            label='Choose a Topic'
                            value={feedback.topic}
                            itemList={topicList}
                            required
                            onChange={(e) =>
                                updateFeedback({ topic: e.target.value })}
                            errorMessage={formErrors?.topic}
                        />
                        <TextField
                            id='subject'
                            name='subject'
                            label='Subject'
                            labelStyle='margin-top-2'
                            value={feedback.subject}
                            onChange={(e) =>
                                updateFeedback({ subject: e.target.value })}
                            required
                            errorMessage={formErrors?.subject}
                        />
                        <TextArea
                            id='message'
                            name='message'
                            label='Message'
                            labelStyle='margin-top-2'
                            value={feedback.body}
                            onChange={(e) =>
                                updateFeedback({ body: e.target.value })}
                            required
                            errorMessage={formErrors?.body}
                        />
                    </form>
                    <Grid container justifyContent='right'
                        sx={{ p: '1rem', mt: '1rem' }}
                    >
                        {
                            !valid.valid &&
                            <Grid item xs={12} sx={{ p: '1rem' }}>
                                <Alert severity='error' variant='filled'>
                                    {valid.message}
                                </Alert>
                            </Grid>
                        }
                        <ActionButton text='Send Feedback'
                            id='send-feedback'
                            onClick={publishEmail}
                        />
                    </Grid>
                </CardContent>
            </Card>
        </Fragment>
    );
};

const topicList = Object.freeze([
    {
        id: 'fb-select',
        value: SELECT_TITLE,
    },
    {
        id: 'fb-question',
        value: 'Question',
    },
    {
        id: 'fb-bug',
        value: 'Bug Report',
    },
    {
        id: 'fb-feature',
        value: 'Feature Request',
    },
    {
        id: 'fb-general',
        value: 'General Feedback',
    }
]);

export default Feedback
