import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import yaml from 'js-yaml';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwaggerUI from "swagger-ui-react";

import { getCompanyDetail, getObjectFromS3 } from "../../apiClient";
import { setAlert } from "../../app/slices/alert";
import { setLinks as setBreadcrumbs } from '../../app/slices/breadcrumbs';
import { setPageTitle } from '../../app/slices/page';
import { selectCompanyId } from "../../app/slices/user";
import ActionButton from "../../components/common/ActionButton";
import CircularProgressIndicator from '../../components/common/CircularProgressIndicator';
import ConnectDialog from "../../components/common/ConnectDialog";
import { useAbortController } from "../../hooks";
import { ServiceTypeUtils } from "../../utils/utils";
import { URL_HOME, URL_ADMIN } from '../../utils/navigation';

const ProviderMetricsService = () => {

    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();

    const [spec, setSpec] = useState(null);
    const [openConnectDialog, setOpenConnectDialog] = useState(false);
    const connectDialogModalRef = useRef(null);
    const [company, setCompany] = useState(null);

    const userCompanyId = useSelector(selectCompanyId);

    const breadcrumbs = [
        { name: 'Home', href: URL_HOME },
        { name: 'Admin', href: URL_ADMIN },
        { name: 'Service Provider Metrics', href: null },
    ];

    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));
        dispatch(setPageTitle('Service Provider Metrics Administration'));
        initApiSpec();
        initCompanyData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initApiSpec = async () => {
        const specLocation = process.env.REACT_APP_PROVIDER_METRICS_SERVICE_OPENAPI_SPEC_LOCATION;

        try {
            let res = await getObjectFromS3({ key: specLocation, abortSignal: abortSignalRef?.current })
            if (res?.data) {
                let spec = yaml.load(res.data);
                setSpec(spec);
            } else {
                throw new Error('Invalid API spec was returned.')
            }
        } catch (err) {
            if (isCancel(err))
                return;

            console.error(`Invalid provider metrics service OpenAPI spec location: ${specLocation}`, err)
            dispatch(setAlert({
                show: true,
                message: 'Failed to get the API specificatin for the provider metrics service',
                severity: 'error'
            }));
        }
    }

    const initCompanyData = async () => {
        try {
            let res = await getCompanyDetail({ id: userCompanyId, abortSignal: abortSignalRef?.current });
            if (res?.data) {
                let companyData = res.data;
                setCompany(companyData);
            } else {
                throw new Error('Invalid comapny data was returned.')
            }
        } catch (err) {
            if (isCancel(err))
                return;

            console.error(`failed to get user company: ${userCompanyId}:`, err);
            dispatch(setAlert({
                show: true,
                message: `failed to get user company: ${userCompanyId}`,
                severity: 'error'
            }));
        }
    }

    const handleOpenConnectDialog = () => {
        setOpenConnectDialog(true);
    }

    const handleCloseConnectDialog = () => {
        setOpenConnectDialog(false);
    }

    // Plugin to wrap the swagger UI components
    const swaggerUIcomponentWrapperPlugin = function (system) {
        return {
            // refer to the following github page for a list of available
            // components:
            // https://github.com/swagger-api/swagger-ui/tree/master/src/core/components
            wrapComponents: {
                info: (Original, system) => (props) => {
                    return (
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    '& .info': {
                                        mb: '1rem'
                                    }
                                }}
                            >
                                <Original {...props} />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    mb: '1rem'
                                }}
                            >
                                <ActionButton text='Connect'
                                    id='connect'
                                    onClick={handleOpenConnectDialog}
                                    data-open-modal
                                    aria-controls={connectDialogModalRef?.current?.modalId}
                                />
                            </Grid>
                        </Grid>
                    );
                }
            }
        }
    }

    return (
        <div id='provider-metrics-openapi-spec-dom'>
            {
                spec ? (
                    <Card>
                        <SwaggerUI
                            spec={spec}
                            presets={[
                                SwaggerUI.presets.apis
                            ]}
                            plugins={[
                                SwaggerUI.plugins.DownloadUrl,
                                swaggerUIcomponentWrapperPlugin
                            ]}
                            supportedSubmitMethods={[]} // disable "Try It Out" button
                        />
                    </Card>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <CircularProgressIndicator 
                            type='large'
                            altText='Generating API...'
                        />
                    </Box>
                )
            }

            {/* Connect Dialog */}
            <ConnectDialog
                open={openConnectDialog}
                handleCloseDialog={handleCloseConnectDialog}
                modalRef={connectDialogModalRef}
                company={company}
                service={{
                    service_type: ServiceTypeUtils.TYPES.REST,
                    url: process.env.REACT_APP_PROVIDER_METRICS_SERVICE_URL
                }}
            />
        </div>
    )
};

export default ProviderMetricsService;
