import { Alert as MuiAlert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

import { useSelector, useDispatch } from 'react-redux';

import { selectAlert, hideAlert } from '../../app/slices/alert';

/**
 * This provides a quick alert in a colored band on the top right corner
 * of the display.  'Success' alerts display for 10 seconds before automatically
 * dismissing, while 'error' and 'warning' alerts remain open until the user
 * dismisses them.
 */

// Define new colors for the alert dialog because the default colors 
// don't meet color contrast requirements.  Null duration makes the
// alert remain open until the user dismisses it.
const alertConfig = {
    'error': {
        'background': '#A7252B',
        'foreground': 'white',
        'duration': null,
        'outline': '1px solid white'
    },
    'warning': {
        'background': '#F97307',
        'foreground': 'black',
        'duration': null,
        'outline': '1px solid black'
    },
    'success': {
        'background': '#0A660A',
        'foreground': 'white',
        'duration': 10000,
        'outline': '1px solid white'
    },
};

function Alert() {
    const { show, message, severity } = useSelector(selectAlert);
    const dispatch = useDispatch();

    const handleAlertClose = () => {
        dispatch(hideAlert());
    };

    return (
        <Snackbar
            open={show}
            autoHideDuration={alertConfig[severity].duration}
            onClose={handleAlertClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            <MuiAlert
                variant='filled'
                onClose={handleAlertClose}
                sx={{
                    backgroundColor: `${alertConfig[severity].background}`,
                    color: `${alertConfig[severity].foreground}`,
                    '& .MuiIconButton-root': {
                        '&:focus, &:hover' : {
                            outline: `${alertConfig[severity].outline}`,
                        }
                    }
                }}
            >
                {message}
            </MuiAlert>
        </Snackbar>
    );
}

export default Alert;
