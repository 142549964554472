import Stack from "@mui/material/Stack";

import AlertInline from "../common/AlertInline";
import BodyText from "../common/BodyText";
import Checkbox from "../common/Checkbox";
import TextField from "../common/TextField";
import { JUSTIFICATION_PURPOSES_ID_MAP } from "../../utils/requestAccess";

/**
 * This component provides the checkboxes for the user to selected why they
 * want to access DIP services for the justification step.  If the user
 * selects "Other", they will be presented with a text box to indicate what
 * that "other" purpose is.
 *
 * @param {object}   input      team information entered so far
 * @param {function} handleJustificationPurposeCheckboxChange
 *                              function to call when the user toggles any
 *                              "purpose" checkbox except "other"
 * @param {function} handleJustificationOtherTextChange
 *                              function to call when the user toggles the
 *                              Other purpose and enters text in the text field
 * @param {object}   errors     results of input validation
 * @param {boolean}  disabled   true to disable the checkboxes
 *
 * @returns JSX for selecting the purpose(s) for accessing DIP
 */
const AccessPurposeCheckboxes = ({
    input,
    handleJustificationPurposeCheckboxChange,
    handleJustificationPurposeOtherTextChange,
    errors,
    disabled
}) => {

    const justifcationCheckbox = (purposeId, purposeLabel) => {
        return (
            <div key={purposeId} >
                <Checkbox
                    id={`jfc-purpose-id-${purposeId}`}
                    label={purposeLabel}
                    checked={input.purposes.selectedIds.has(purposeId)}
                    onChange={(e) => handleJustificationPurposeCheckboxChange(
                        purposeLabel, e.target.checked
                    )}
                    disabled={disabled}
                />
                {
                    // If Other checkbox is checked, display the text field
                    purposeId === JUSTIFICATION_PURPOSES_ID_MAP['Other'] &&
                    input.purposes.selectedIds.has(JUSTIFICATION_PURPOSES_ID_MAP['Other']) &&
                    (
                        <div
                            style={{
                                display: 'inline-block', marginLeft: '2rem',
                            }}
                        >
                            <TextField
                                id={`justification-purpose-other-text`}
                                name='otherText'
                                label='Please enter the purpose'
                                labelStyle='margin-top-1'
                                value={input.purposes.otherText}
                                onChange={handleJustificationPurposeOtherTextChange}
                                errorMessage={errors?.otherText}
                                disabled={disabled}
                            />
                        </div>
                    )
                }
            </div>
        )
    }

    return (
        <>
            <BodyText
                label='What is your primary purpose for accessing DIP services? *'
                labelStyle='margin-top-0'
            />
            <BodyText
                label='(Check all that apply)'
                labelStyle='margin-top-0'
            />
            {
                errors?.selectedIds &&
                <AlertInline errorMessage={errors?.selectedIds} />
            }
            <Stack
                sx={{
                    ml: '1rem'
                }}
            >
                {
                    Object.entries(JUSTIFICATION_PURPOSES_ID_MAP).map(([purposeLabel, purposeId]) => {
                        return (
                            justifcationCheckbox(purposeId, purposeLabel)
                        )
                    })
                }
            </Stack>
        </>
    )
};

export default AccessPurposeCheckboxes;
