import { useEffect, useRef } from "react";
import { isCancel } from "axios";

/**
 * Creates a new AbortController and sends the abort signal when
 * 1. the component unmounts
 * 2. the abort() function gets invoked manually
 * @returns {abortControllerRef, abortSignal, isCancel}
 * abortControllerRef - a refernece to the AbortController
 * abortSignalRef - a refernece to the abort signal which is used to abort a DOM request
 * abort - a function to cancel the previous requests
 * isCancel - a function to check if an error is caused by cancelling the request
 */
export const useAbortController = () => {
  const abortControllerRef = useRef(new AbortController());
  const abortSignalRef = useRef(abortControllerRef.current.signal);

  useEffect(() => abort, []);

  const abort = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    abortSignalRef.current = abortControllerRef.current.signal;
  }

  return {
    abortControllerRef: abortControllerRef,
    abortSignalRef: abortSignalRef,
    abort: abort,
    isCancel: isCancel
  };
};