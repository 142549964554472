import WarningIcon from "@mui/icons-material/Warning";
import { Box } from "@mui/material";

const NewVersionIndicator = ({
    hideWarningIcon = false,
    upperPartSxProps = {},
    bottomPartSxProps = {},
    ...props
}) => {
    return (
        <Box color="subscribed.warn">
            <Box
                component="div"
                sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    fontWeight: "bold",
                    ...upperPartSxProps,
                }}
            >
                {!hideWarningIcon && <WarningIcon fontSize="inherit" />}
                {props.children}
            </Box>
            <Box
                component="div"
                sx={{
                    fontSize: "0.65rem",
                    ...bottomPartSxProps,
                }}
            >
                New version available
            </Box>
        </Box>
    );
};

export default NewVersionIndicator;
