export const QOS_METRICS = Object.freeze([
    {
        id: 'qos-availability',
        value: 'Availability',
    },
    {
        id: 'qos-bandwidth',
        value: 'Bandwidth',
    },
    {
        id: 'qos-latency',
        value: 'Latency',
    },
    {
        id: 'qos-recoverability',
        value: 'Recoverability',
    },
    {
        id: 'qos-request-limit',
        value: 'Request Limit',
    },
    {
        id: 'qos-response-time',
        value: 'Response Time',
    },
    {
        id: 'qos-other',
        value: 'Other',
    }
]);

export const isQosOther = (metric) => {

    let isOther = false;
    if (!!metric && ('other' === metric.toLowerCase()))
    {
        isOther = true;
    }

    return isOther;
}
