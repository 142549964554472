import { createSlice } from '@reduxjs/toolkit'

/**
 * State related to catalog browsing
 */
const initialState = {
    /**
     * States for sort options
     */
    sortOption: {
        serviceName: 'serviceName',
        providerName: 'providerName'
    },

    // Which sort option is selected for the services / search results
    sortServicesBy: 'serviceName',

    // Which sort option is selected for the subscriptions
    sortSubscriptionsBy: 'serviceName',
}

export const browseSlice = createSlice({
    name: 'browse',
    initialState,
    reducers: {

        // Sets the sort option for services
        setServicesSortBy: (state, action) => {
            if (action.payload) {
                state.sortServicesBy = action.payload;
            }
        },

        // Sets the sort option for subscriptions
        setSubscriptionsSortBy: (state, action) => {
            if (action.payload) {
                state.sortSubscriptionsBy = action.payload;
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const {
    setServicesSortBy,
    setSubscriptionsSortBy,
} = browseSlice.actions

export const selectServicesSortBy = state => state.browse.sortServicesBy;
export const selectSubscriptionsSortBy = state => state.browse.sortSubscriptionsBy;
export const selectSortOption = state => state.browse.sortOption;

export default browseSlice.reducer
