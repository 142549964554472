import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import React from 'react';

import ActionButton from '../common/ActionButton';
import BodyText from '../common/BodyText';
import BodyTitle from '../common/BodyTitle';
import { downloadS3File } from '../../utils/s3Utils';

/**
 * This provides the display of a service details when the user clicks on it
 * from the Subscriptions or service card.
 */

const ServiceDescription = ({ loadingService, service, dataDictionaryFile }) => {

    // This component has a lot of stuff to display, so if it is not ready yet,
    // just bail out now to keep the code below cleaner.
    if (loadingService) {
        return (
            <Card>
                <Skeleton variant='rectangular' height='10rem' />
            </Card>
        );
    }

    return (
        <Grid container
            sx={{
                backgroundColor: 'white',
                padding: '1rem',
            }}
        >
            <Grid container item
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: '1rem'
                }}
            >
                <Grid item
                    xs={12}
                    sm='auto'
                >
                    <BodyTitle
                        label={service.name}
                        labelStyle='margin-top-05'
                     />
                </Grid>
                <Grid item
                    xs={12}
                    sm='auto'
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                        mt: {
                            xs: '1rem',
                            sm: 0
                        }
                    }}
                >
                    {
                        dataDictionaryFile &&
                        <ActionButton text='Download Data Dictionary'
                            id='download-data-dictionary'
                            onClick={() => downloadS3File(dataDictionaryFile.path)}
                            buttonStyle='margin-right-1'
                        />
                    }
                </Grid>
            </Grid>

            <Grid item xs={12} >
                <BodyText
                    label={service.description}
                    labelStyle='text-pre-wrap margin-top-0 padding-right-2 padding-bottom-2'
                />
            </Grid>

        </Grid>
    );
};

export default ServiceDescription;
