import { AxiosError } from "axios";
import { getCompanyDetail } from "../apiClient";
import { setRerenderNavBar } from "../app/slices/page";
import { setField as setUserStateField } from '../app/slices/user';
import { catalogAppDataKey, store } from "../app/store";
import { getCompanyRolesFromToken, getUserCompanyId } from "./auth";

export const CANCEL_DELETE_TEXT = "No, don't delete";
export const OK_DELETE_TEXT = "Yes, delete";
export const ALL_ITEMS = -1;

/**
 * Copy the given value to the clipboard
 * @param {string} value value to copy
 */
export const copyToClipboard = (value) => {
  let dummy = document.createElement('input');
  document.body.appendChild(dummy);
  dummy.setAttribute('id', 'dummy_input');
  dummy.value = value;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};

export const getScrollbarWidth = () => {
    // Only run in browser
    if (typeof document !== 'undefined') {
        const outer = document.createElement('div');
        outer.setAttribute(
            'style',
            'visibility: hidden; overflow: scroll; ms-overflow-style: scrollbar'
        );
        document.body.appendChild(outer);

        const inner = document.createElement('div');
        outer.appendChild(inner);

        const scrollbarWidth = `${outer.offsetWidth - inner.offsetWidth}px`;
        outer.parentNode?.removeChild(outer);

        return scrollbarWidth;
    }

    return '';
};

/**
 * @param {boolean} shouldHide whether ot not to hide a given secret
 * @param {string|null} secret secret value
 * @returns {string|null}
 */
export const hideSecret = (shouldHide, secret) => {
  let rtn = secret;
  if (shouldHide && secret && secret.length > 0) {
    rtn = new Array(secret.length + 1).join('*');
  }
  return rtn;
};


/**
 * Gets the data to display for the current page.
 *
 * @param {any[]} items                all items
 * @param {number} numItemsPerPage     number of items per page or ALL_ITEMS
 * @param {number} currentPage         current page number, 1-based
 *
 * @returns items on the current page
 */
export const paginate = (items, numItemsPerPage, currentPage) => {
  let rtn = items;

  if (items && !!items.length) {
    if (numItemsPerPage !== ALL_ITEMS) {
      const start = (currentPage - 1) * numItemsPerPage;
      rtn = rtn.slice(start, start + numItemsPerPage);
    }
  }

  return rtn;
};

/**
 * Trim whitespace from the given string
 * @param {string} str string to trim
 * @returns string without whitespace from both ends of the string
 */
export const trimWhitespace = (str) => {
  return str && typeof (str) === 'string' ? str.trim() : str;
};

/**
 * Truncates the string to the specified length minus 3, then appends an
 * ellipsis.
 *
 * @param {string} str   string to trim
 * @param {number} len   length to trim the string to; this length
 *                       includes the ellipsis
 *
 * @returns {string} 'str' truncated to 'len' - 3 plus '...' if str's length
 *                   is > 'len'; otherwise the str
 */
export const truncateString = (str, len) => {
  let rtn = str;
  if (str && (typeof(str) === 'string') && (str.length > len)) {
    rtn = str.slice(0, (len - 3)) + '...';
  }
  return rtn;
};

/**
 * Formats the given phone number into (nnn)nnn-nnnn [xnnn], where the final
 * characters "xnnn" are an optional extension.
 *
 * @param {string} phone   phone number entered by the user
 *
 * @return {string} formatted phone number, or the phone number unchanged if
 *         there are invalid characters in the user-entered value
 */
export const formatPhoneNumber = (phone) => {

  let badCharacter = false;
  let extFlag = false;
  let numberRegex = /[0-9]/;
  let punctRegex = /([().-\s])/;
  let extRegex = /([EeXxTt])/;
  let numbersOnly = "";
  let answer = "";

  // Pull out just the numbers, and look for any unexpected characters.
  for (let char of phone) {
    if (numberRegex.test(char)) {
      numbersOnly = numbersOnly + char;
    }
    else if (punctRegex.test(char)) {
      ; // Nothing required
    }
    else if (extRegex.test(char)) {
      // Need a special flag if the last character entered is the user
      // starting to specify the extension
      if (char === phone.charAt(phone.length - 1)) {
          extFlag = true;
      }
    }
    else {
      badCharacter = true;
    }
  }

  if (!badCharacter) {
    // Begin the formatting
    if (numbersOnly.length > 0) {

      // If the user has entered anything, prefix it with the '(' of the
      // area code.
      let copyLen = Math.min(3, numbersOnly.length);
      answer = "(" + numbersOnly.substring(0, copyLen);
      if (numbersOnly.length >= 3) {

        // Put the closing prefix on the area code
        answer = answer + ") "
        if (numbersOnly > 3) {

           // Add the first 3 numbers of the telephone number
           copyLen = Math.min(6, numbersOnly.length);
           answer = answer + numbersOnly.substring(3, copyLen);
           if (numbersOnly.length >= 6) {

             // Add the hyphen and the rest of the number
             copyLen = Math.min(10, numbersOnly.length);
             answer = answer + "-" + numbersOnly.substring(6, copyLen);

             // Add the extension, if any
             if (numbersOnly.length === 10 && extFlag) {
               answer = answer + " x";
             }
             else if (numbersOnly.length > 10) {
               copyLen = numbersOnly.length;
               answer = answer + " x" + numbersOnly.substring(10, copyLen);
             }
           }
        }
      }
    }
  }
  else
  {
    // Return the bad input; let the field validation notify the user to fix it
    answer = phone;
  }

  return answer;
}


/**
 * Check if given serviceType is equal to the target service type
 * @param {string} serviceType
 * @param {string} target
 * @returns
 */
export const checkServiceType = (serviceType, target) => {
  return serviceType?.toLowerCase() === target?.toLowerCase();
};

/**
 * Convert a given object into the query string parameters
 * e.g.) {source_id: 42} => ?source_id=42
 * @param {object} obj
 * @returns query string parameters
 */
export const convertObjectToQueryStrParams = (obj) => {
  let rtn = '';
  if (obj && Object.keys(obj).length > 0) {
    rtn = '?';
    Object.entries(obj).forEach(([k, v]) => {
      if (v === true || v === false || v) {
        rtn += `${k}=${v}&`
      }
    })

    if (rtn.length > 1) {
      // remove the last character '&'
      rtn = rtn.slice(0, -1);
    }
  }
  return rtn;
};

export class ServiceTypeUtils {
  static TYPES = {
    REST: 'REST',
    STREAMING: 'STREAMING',
    DATA_TRANSFER: 'DATA_TRANSFER',
    SFTP: 'SFTP'
  };

  static TYPE_TO_STRING = {
    [ServiceTypeUtils.TYPES.REST]: 'REST',
    [ServiceTypeUtils.TYPES.STREAMING]: 'Streaming',
    [ServiceTypeUtils.TYPES.DATA_TRANSFER]: 'Data Transfer',
    [ServiceTypeUtils.TYPES.SFTP]: 'SFTP',
  }

  static TYPE_TO_SERVICE_PAGE_URL = {
    [ServiceTypeUtils.TYPES.REST]: 'rest',
    [ServiceTypeUtils.TYPES.STREAMING]: 'streaming',
    [ServiceTypeUtils.TYPES.DATA_TRANSFER]: 'data-transfer-source',
    [ServiceTypeUtils.TYPES.SFTP]: 'sftp',
  }

  static TYPE_TO_ADMIN_STORE_SLICE_PREFIX = {
    [ServiceTypeUtils.TYPES.REST]: 'rest',
    [ServiceTypeUtils.TYPES.STREAMING]: 'streaming',
    [ServiceTypeUtils.TYPES.DATA_TRANSFER]: 'dataTransfer',
    [ServiceTypeUtils.TYPES.SFTP]: 'sftp',
  }
};

// Capitalize the first letter of the given string and then lowercase the rest.
export const capitalizeFirstLetterOnly = (str) => {
  if (!!str && str.length > 0) {
    str = str[0].toUpperCase() + str.slice(1).toLowerCase();
  }
  return str;
};

export const is404NotFoundError = (error) => {
  return (error instanceof AxiosError && error.response.status === 404);
};

export const initializeCatalog = async () => {
    // Trigger a re-render of the nav bar when the user signs-in or when the page or the session token is refreshed.
    store.dispatch(setRerenderNavBar(!!!store.getState().page.rerenderNavBar));
    getUserCompanyId().then(async (id) => {
        if (id) {
            try {
                let res = await getCompanyDetail({ id });
                if (res.data) {
                    store.dispatch(
                        setUserStateField({
                            name: "companyId",
                            value: res.data.id,
                        })
                    );
                    store.dispatch(
                        setUserStateField({
                            name: "companyName",
                            value: res.data.name,
                        })
                    );
                    store.dispatch(
                        setUserStateField({
                            name: "companyAbbreviation",
                            value: res.data.abbreviation,
                        })
                    );
                    store.dispatch(
                        setUserStateField({
                            name: "companyRoles",
                            value: await getCompanyRolesFromToken(),
                        })
                    );
                }
            } catch (err) {
                console.error("failed to get company details for user:", err);
            }
        } else {
            console.error("failed to get company details for user");
        }
    });
};

export const cleanUpCatalog = async () => {
    store.dispatch(setRerenderNavBar(!!!store.getState().page.rerenderNavBar));
    console.log("clear the state of the store");
    localStorage.removeItem(`persist:${catalogAppDataKey}`);
}; 