import classnames from 'classnames';

/**
 * This provides the auxiliary help for a user entry field.
 *
 * @param {string} id           the unique identifier for this field
 * @param {string} help         the help text to display
 * @param {string} helpStyle    any additional USWDS styles which should be
 *                              applied to the field
 */
const FieldHelp = ({
    id,
    help,
    helpStyle
}) => {

    const classes = classnames('usa-label', 'font-sans-xs', 'text-italic',
        'margin-top-0', helpStyle);

    return (
        <label id={id} className={classes} >
            {help}
        </label>
    );
}

export default FieldHelp;
