import { Button } from '@mui/material';


/**
 * This creates a button which redirects to an external web page.
 *
 * @param {string}   text      the button text
 * @param {string}   href      URL to navigate to upon button click
 * @param {function} onClick   function to invoke when clicked
 * @param {boolean}  disabled  whether or not the button should be disabled
 * @param {object}   props     any other custom proprties
 */
const ExternalLinkButton = ({
    text,
    href,
    onClick = null,
    disabled = false,
    ...props
}) => {

    if (href) {
        return (
            <Button
                variant='contained'
                onClick={onClick}
                href={href}
                sx={{
                    marginBottom: '1rem',
                    backgroundColor: '#374274',
                    color: 'white',
                    '&:hover, &:focus': {
                        backgroundColor: '#2051B1',
                        color: 'white',
                    },
                    textTransform: 'none',
                }}
                disabled={disabled}
                {...props}
            >
                {text}
            </Button>
        );
    }

    console.error(`ExternalLinkButton ${text} missing href`);
    return null;
}

export default ExternalLinkButton;
