import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
    checkTransferSubscription, createSubscription,
    deleteDataTransferSubscription, deleteSubscription,
    getCompanyDetail, getDataTransferSource, getServiceDetail
} from '../apiClient';
import { setAlert } from '../app/slices/alert';
import { setPageTitle } from '../app/slices/page';
import {
    clearFilters, clearSearchText, fetchCategories,
    selectCategories, updateSearchFilter
} from '../app/slices/search';
import { selectCompanyId, selectCompanyRoles } from '../app/slices/user';
import BodyText from '../components/common/BodyText';
import CircularProgressIndicator from '../components/common/CircularProgressIndicator';
import ConnectDialog from '../components/common/ConnectDialog';
import MajorHeading from '../components/common/MajorHeading';
import NoMatchLabel from '../components/common/NoMatchLabel';
import OkCancelDialog from '../components/common/OkCancelDialog';
import StyledFilterChip from '../components/common/StyledFilterChip';
import DataTransferDestinationDialog from '../components/service-detail/DataTransferDestinationDialog';
import DataTransferSource from '../components/service-detail/DataTransferSource';
import ServiceDescription from '../components/service-detail/ServiceDescription';
import ServiceHeader from '../components/service-detail/ServiceHeader';
import ServiceSupport from '../components/service-detail/ServiceSupport';
import ServiceVersion from '../components/service-detail/ServiceVersion';
import { useAbortController } from '../hooks';
import { URL_SEARCH } from '../utils/navigation';
import {
    findCurrentVersion,
    getFileByType, getServiceLogoDataUrl, isCdmServiceByServiceScope,
    isCdmUserByCompanyScope
} from '../utils/serviceDetail';
import { initFiles, REQUIRED_SERVICE_FILE_TYPE, VIRUS_SCAN_STATUS } from '../utils/serviceRegistration';
import { ServiceTypeUtils, checkServiceType } from '../utils/utils';
import AdditionalFiles from '../components/service-detail/AdditionalFiles';

/**
 * This provides the display of a service details when the user clicks on it
 * from the Subscriptions or service card.
 */

function ServiceDetail() {

    const { abortSignalRef, isCancel } = useAbortController();

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { serviceId } = useParams();

    const [service, setService] = useState({});
    const [currentVersion, setCurrentVersion] = useState(null);
    const [otherVersions, setOtherVersions] = useState(null);
    const [provider, setProvider] = useState(null);
    const [company, setCompany] = useState(null);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const isSubscribedToCurrentVersion = useMemo(
        () => currentVersion?.subscribed,
        [currentVersion]
    );

    const [loadingService, setLoadingService] = useState(true);
    const [loadingVersions, setLoadingVersions] = useState(true);

    const [handlingSubscription, setHandlingSubscription] = useState(false);
    const [subscriptionHandlingVersionId, setSubscriptionHandlingVersionId] =
        useState(null);
    const [connectDialogServiceUrl, setConnectDialogServiceUrl] = useState(null);
    const [connectDialogServiceIdentifier, setConnectDialogServiceIdentifier] =
        useState(null);
    const [serviceCategories, setServiceCategories] = useState(null)
    const [openConnectDialog, setOpenConnectDialog] = useState(false);
    const connectDialogRef = useRef(null);
    const [showUnsubscribeDialog, setShowUnsubscribeDialog] = useState(false);
    const unsubscribeDialogRef = useRef(null);
    const [isCdmService, setIsCdmService] = useState(false);
    const [isCdmUser, setIsCdmUser] = useState(false);

    const [serviceAdditionalFiles, setServiceAdditionalFiles] = useState([]);

    // Data Transfer Service related states
    const [isDts, setIsDts] = useState(false);
    const [openDtsDialog, setOpenDtsDialog] = useState(false);
    const dtsDialogRef = useRef(null);
    const [dtsSubscription, setDtsSubscription] = useState(null);

    // Data dictionary file for the streaming service
    const [dataDictionaryFile, setDataDictionaryFile] = useState(null);

    const providerId = searchParams.get("providerId");

    const companyId = useSelector(selectCompanyId); // consumer's company ID
    const categories = useSelector(selectCategories);
    const userCompanyRoles = useSelector(selectCompanyRoles);

    const hideApiButton = useMemo(() =>
        isDts || checkServiceType(service.service_type, ServiceTypeUtils.TYPES.SFTP),
        [isDts, service.service_type]
    );    // Hide the api button if data transfer or SFTP service

    const disableSubscribeButton = useMemo(() => {
        let disable = false;
        if (isCdmService) {
            disable = !isCdmUser;
        }

        if (!disable && isDts && company) {
            disable = !company.s3_transfer_enabled;
        }

        // If the user's company does not have all roles specified by the
        // service, disable the subscription button.
        if (!disable && service?.roles) {
            let userCompanyRolesSet = new Set(userCompanyRoles || []);
            for (let serviceRole of service.roles) {
                if (!userCompanyRolesSet.has(serviceRole)) {
                    disable = true;
                    break;
                }
            }
        }

        return disable;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCdmService, isCdmUser, company, isDts]);

    useEffect(() => {
        // set page title
        dispatch(setPageTitle('Service'));
        dispatch(fetchCategories({ abortSignalRef }));

        // fetch provider detail for this service
        getCompanyDetail({ id: providerId,
                           abortSignal: abortSignalRef?.current })
        .then(res => {
            if (res['data']) {
                setProvider(res['data']);
            }
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error(`failed to get provider details for provider ${providerId}:`, err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get provider details',
                severity: 'error'
            }));
        });

        // fetch the detailed service info and service versions in the
        // "launched" state only.
        getServiceDetail({
            service_id: serviceId,
            consumer_id: companyId,
            version_status: 'launched',
            abortSignal: abortSignalRef?.current
        })
        .then(async (res) => {
            if (res?.data) {
                const service = res.data;
                dispatch(setPageTitle('Service: ' + service.name));

                service['service_logo_data_url'] = await getServiceLogoDataUrl(service);

                let isDts = checkServiceType(service.service_type,
                                ServiceTypeUtils.TYPES.DATA_TRANSFER);
                setIsDts(isDts);
                if (isDts && service.transfer_source_id) {
                    let dtsDetailRes = await getDataTransferSource({
                        id: service.transfer_source_id,
                        abortSignal: abortSignalRef?.current
                    });
                    service['dts'] = dtsDetailRes.data;

                    initDtsSubscription(service.transfer_source_id)
                }

                setIsCdmService(isCdmServiceByServiceScope(service));
                setService(service);
                initServiceVersions(service?.versions);
            }

            setLoadingService(false);
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error(`failed to get service details for service ${serviceId}:`, err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get service details for service',
                severity: 'error'
            }));

            setLoadingService(false);
        })

        // get user company, to pass to the Connect dialog
        getCompanyDetail({ id: companyId,
                           abortSignal: abortSignalRef?.current })
        .then(res => {
            if (res['data']) {
                let companyData = res['data'];
                setCompany(companyData);
                setIsCdmUser(isCdmUserByCompanyScope(companyData));
            }
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error(`failed to get user company: ${companyId}:`, err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get user company',
                severity: 'error'
            }));
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        initFiles(service?.files, true)
        .then(({ additionalFiles }) => {
            setServiceAdditionalFiles(additionalFiles);
        })
        .catch((err) => {
            console.error("failed to get additional files", err.message);
            dispatch(
                setAlert({
                    show: true,
                    message: "Failed to get additional files",
                    severity: "error",
                })
            );
        });

        // Get the data dictionary file from the service level. If not found, find it from the
        // current service version level
        if (
            checkServiceType(
                service.service_type,
                ServiceTypeUtils.TYPES.STREAMING
            ) ||
            checkServiceType(service.service_type, ServiceTypeUtils.TYPES.SFTP)
        ) {
            let dataDictionaryFile = getFileByType(
                service?.files,
                REQUIRED_SERVICE_FILE_TYPE.data_dictionary
            );
            if (dataDictionaryFile?.virus_scan_status === VIRUS_SCAN_STATUS.clean) {
                setDataDictionaryFile(dataDictionaryFile);
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service]);

    // Set the service domains (aka 'categories') once both the service AND
    // the list of all possible domains (aka 'categories') have been loaded.
    useEffect(() => {
        if (!!categories && !!categories.length && 
            !!service && !!service.category_ids) {

            let serviceCategories = categories.filter(c => 
                     service.category_ids.includes(c.id));
            setServiceCategories(serviceCategories);
        }
    }, [service, categories]);

    const initServiceVersions = async (versions) => {
        try {
            setLoadingVersions(true);
            
            const numVersions = versions?.length;
            if (!!numVersions) {
                assignSubscriptionStatus(versions);
                let allVersions = versions.map(async (v) => {
                    const { additionalFiles } = await initFiles(v.files, true);
                    return {
                        ...v,
                        additionalFiles: additionalFiles
                    }
                })

                allVersions = await Promise.all(allVersions);
                
                if (numVersions === 1) {
                    setCurrentVersion(allVersions[0]);
                    setOtherVersions(null);
                } else {
                    const current = findCurrentVersion(
                        allVersions
                    );
                    setCurrentVersion(current);

                    // Remove the current version from the list of versions
                    // and re-order them to be latest -> earliest
                    let others = allVersions.filter((ver) => {
                        return ver.version_id !== current.version_id;
                    });
                    setOtherVersions(others.reverse());
                }
            } else {
                setCurrentVersion(null);
                setOtherVersions(null);
            }
        } catch (err) {
            console.error("failed to initialize versions", err.message);
            dispatch(
                setAlert({
                    show: true,
                    message: "Failed to initialize versions",
                    severity: "error",
                })
            );
        } finally {
            setLoadingVersions(false);
        }
    }

    // Set whether the user is subscribed to this service by checking the
    // subscription status of the service versions.  This should only be
    // called when processing a known service, so checkService should never
    // be undefined.
    const assignSubscriptionStatus = (versions) => {
        let subscribed = false;
        if (versions && versions.length) {
            subscribed = !!versions.find(v => v.subscribed === true);
        }
        setIsSubscribed(subscribed);
    };

    const initDtsSubscription = (transferSourceId) => {
        checkTransferSubscription({ source_id: transferSourceId,
                                    dest_provider_id: companyId,
                                    abortSignal: abortSignalRef?.current })
        .then(res => {
            if (Object.keys(res.data).length > 0) {
                setDtsSubscription(res.data);
            }
        })
        .catch(err => {
            if (isCancel(err))
                return;

            console.error('failed to get the transfer subscription detail', err.message);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get the transfer subscription detail',
                severity: 'error'
            }));
        });
    }

    // When the category pill is clicked,
    // 1. clear the category filters and search text
    // 2. update the cateogry filter
    // 3. navigate to the search result page
    const handleCategoryClick = (categoryId) => {
        dispatch(clearFilters());
        dispatch(clearSearchText());
        dispatch(updateSearchFilter({
            type: 'categories',
            id: categoryId,
            checked: true
        }));
        navigate(URL_SEARCH);
    };

    const handleSubscriptionButtonClick = (versionId) => {
        setSubscriptionHandlingVersionId(versionId);
        if (!isDts) {
            handleSubscription(true, versionId);
        } else {
            setOpenDtsDialog(true);
        }
    }

    const handleCloseDtsDialog = () => {
        setOpenDtsDialog(false);
    }

    const handleSubscription = async (subscribe, versionId) => {
        const cleanup = () => {
            setHandlingSubscription(false);
            setSubscriptionHandlingVersionId(null);

            if (!subscribe) {
                handleCloseUnsubscribeDialog();
            }
        };

        try {
            setHandlingSubscription(true);

            if (!versionId) {
                let msg = `Invalid service version ID: ${versionId}`;
                let error = new Error(msg);
                error.alertMessage = [msg];
                throw error;
            }

            try {
                if (subscribe) {
                    // subscribe to the service
                    await createSubscription({
                        body: {
                            company_id: companyId,
                            service_id: serviceId,
                            version_id: versionId
                        },
                        abortSignal: abortSignalRef?.current
                    })

                    if (isDts) {
                        initDtsSubscription(service.transfer_source_id);
                    }

                    dispatch(setAlert({
                        show: true,
                        message: `Successfully subscribed to the service`,
                        severity: 'success'
                    }));
                } else {
                    // unsubscribe from the service
                    handleCloseUnsubscribeDialog();

                    if (isDts) {
                        if (dtsSubscription?.id) {
                            try {
                                await deleteDataTransferSubscription({
                                    id: dtsSubscription.id,
                                    abortSignal: abortSignalRef?.current });
                            } catch (err) {
                                if (isCancel(err))
                                    return;

                                // If failed to delete the transfer
                                // subscription, display a warning message and
                                // move on because the transfer subscription
                                // row might have been deleted already from
                                // the admin UI.
                                console.warn(`failed to delete the transfer subscription: ${err.message}`)
                                dispatch(setAlert({
                                    show: true,
                                    message: 'Failed to delete the transfer subscription',
                                    severity: 'warning'
                                }));
                            }
                        }
                    }

                    await deleteSubscription({
                        company_id: companyId,
                        service_id: serviceId,
                        version_id: versionId,
                        abortSignal: abortSignalRef?.current
                    });
                    dispatch(setAlert({
                        show: true,
                        message: `Successfully unsubscribed from version ${versionId}`,
                        severity: 'success'
                    }));
                }

                // Update the respective service version's subscribe flag
                if (service.versions && service.versions.length) {
                    let serviceCopy = { ...service };
                    let versionIdx = serviceCopy.versions.findIndex(v => v.version_id === versionId);
                    serviceCopy.versions[versionIdx].subscribed = subscribe;
                    setService(serviceCopy);
                    
                    initServiceVersions(serviceCopy?.versions);
                }
            } catch (err) {
                err['alertMessage'] = `failed to ${subscribe ? 'subscribe to' : 'unsubscribe from'} the service`;
                throw err;
            }

            cleanup();
        } catch (err) {
            if (isCancel(err))
                return;

            console.error(err.alertMessage, err.message);
            dispatch(setAlert({
                show: true,
                message: err.alertMessage,
                severity: 'error'
            }));

            cleanup();
        };
    }

    const handleOpenConnectDialog = (url, serviceIdentifier) => {
        setConnectDialogServiceUrl(url);
        setConnectDialogServiceIdentifier(serviceIdentifier);
        setOpenConnectDialog(true);
    }

    const handleCloseConnectDialog = () => {
        setOpenConnectDialog(false);
        setConnectDialogServiceUrl(null);
        setConnectDialogServiceUrl(null);
    }

    const handleOpenUnsubscribeDialog = (versionId) => {
        setSubscriptionHandlingVersionId(versionId);
        setShowUnsubscribeDialog(true);
    };

    const handleCloseUnsubscribeDialog = () => {
        setShowUnsubscribeDialog(false);
    };

    const getLeftSide = () => (
        <>
            {/* Service information */}
            <Grid item xs={12}
                id='service-information'
            >
                <MajorHeading
                    label='Service Information'
                    labelStyle='margin-y-0'
                />
            </Grid>
            <Grid item
                xs={12}
            >
                <ServiceHeader
                    service={service}
                    provider={provider}
                    isSubscribed={isSubscribed}
                    isSubscribedToCurrentVersion={isSubscribedToCurrentVersion}
                    loadingService={loadingService}
                />
            </Grid>

            {/* Service description */}
            <Grid item xs={12}
                id='service-description'
            >
                <MajorHeading
                    label='Description'
                    labelStyle='margin-bottom-0'
                />
            </Grid>
            <Grid item xs={12} >
                <ServiceDescription
                    loadingService={loadingService}
                    service={service}
                    dataDictionaryFile={dataDictionaryFile}
                />
            </Grid>

            {/* Categories */}
            <Grid item xs={12}
                sx={{
                    mt: '1rem',
                }}
            >
                {
                    serviceCategories && serviceCategories.map(c => {
                        return (
                            <StyledFilterChip
                                label={c.phrase}
                                key={c.id}
                                onClick={() => handleCategoryClick(c.id)}
                            />
                        )
                    })
                }
            </Grid>

            { /* Data Transfer Service stuff */ }
            {
                service?.dts &&
                <>
                    <Grid item xs={12}
                        id='data-transfer-source'
                        sx={{
                            mt: '1rem',
                        }}
                    >
                        <MajorHeading
                            label='Data Transfer Source'
                            labelStyle='margin-bottom-0'
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <DataTransferSource
                            loadingService={loadingService}
                            service={service}
                        />
                    </Grid>
                </>
            }

            {/* Service Support */}
            <Grid item xs={12}
                id='service-support'
                sx={{
                    mt: '1rem',
                }}
            >
                <MajorHeading
                    label='Service Support'
                    labelStyle='margin-bottom-0'
                />
            </Grid>
            <Grid item xs={12} >
                <ServiceSupport
                    loadingService={loadingService}
                    service={service}
                />
            </Grid>

            {/* Additional files at the service level */}
            {serviceAdditionalFiles?.length ? (
                <Grid item xs={12} sx={{ mt: "1rem" }}>
                    <AdditionalFiles files={serviceAdditionalFiles} />
                </Grid>
            ) : null}
    </>);

    const getRightSide = () => {
        return (
            <>
                { /* Current version */ }
                <Grid item
                    id='current-version'
                    xs={12}
                    sx={{
                    }}
                >
                    <MajorHeading label='Current Version' labelStyle='margin-y-0' />
                </Grid>

                <Grid item
                    id='currentVersionwrapper'
                    xs={12}
                    sx={{
                        mt: '0 !important'
                    }}
                >
                    {
                        !!currentVersion ?
                            <ServiceVersion
                                key={`cv-${currentVersion.version_id}`}
                                serviceId={service.id}
                                serviceName={service.name}
                                serviceVersion={currentVersion}
                                serviceType={service.service_type}
                                companyId={companyId}
                                providerId={providerId}
                                hideApiButton={hideApiButton}
                                handleOpenConnectDialog={handleOpenConnectDialog}
                                disableSubscribeButton={disableSubscribeButton}
                                handleSubscriptionButtonClick={handleSubscriptionButtonClick}
                                handleOpenUnsubscribeDialog={handleOpenUnsubscribeDialog}
                                handlingSubscription={handlingSubscription}
                                subscriptionHandlingVersionId={subscriptionHandlingVersionId}
                                initiallyExpanded={true}
                                connectDialogRef={connectDialogRef}
                                unsubscribeDialogRef={unsubscribeDialogRef}
                                dtsDialogRef={dtsDialogRef}
                            />
                        :
                            loadingVersions ?
                                <CircularProgressIndicator 
                                    type='large'
                                    altText='Loading service versions...'
                                />
                            :
                                <Card>
                                    <CardContent>
                                        <NoMatchLabel label='No Current Version' />
                                    </CardContent>
                                </Card>
                    }

                </Grid>

                { /* Service version */ }
                {
                    !!otherVersions &&
                    <>
                        <Grid item
                            id='other-versions'
                            xs={12}
                            sx={{
                            }}
                        >
                            <MajorHeading label='Other Versions' labelStyle='margin-top-8' />
                        </Grid>

                        <Grid container item
                            id='versionlistwrapper'
                            rowSpacing={1}
                            xs={12}
                            sx={{
                                mt: '0 !important'
                            }}
                        >
                            {
                                otherVersions.map((serviceVersion, idx) =>
                                    <ServiceVersion
                                        key={`ov-${serviceVersion.version_id}`}
                                        serviceId={service.id}
                                        serviceName={service.name}
                                        serviceVersion={serviceVersion}
                                        serviceType={service.service_type}
                                        companyId={companyId}
                                        providerId={providerId}
                                        hideApiButton={hideApiButton}
                                        handleOpenConnectDialog={handleOpenConnectDialog}
                                        disableSubscribeButton={disableSubscribeButton}
                                        handleSubscriptionButtonClick={handleSubscriptionButtonClick}
                                        handleOpenUnsubscribeDialog={handleOpenUnsubscribeDialog}
                                        handlingSubscription={handlingSubscription}
                                        subscriptionHandlingVersionId={subscriptionHandlingVersionId}
                                        connectDialogRef={connectDialogRef}
                                        unsubscribeDialogRef={unsubscribeDialogRef}
                                        dtsDialogRef={dtsDialogRef}
                                    />
                                )
                            }

                        </Grid>
                    </>
                }
            </>
        );
    }

    return (
        <Box id='single-service'>
            <Grid container
                direction='row'
                alignItems='start'
                spacing={2}
            >
                <Grid container item
                    xs={12}
                    lg={6}
                    direction='column'
                    sx={{
                    }}
                >
                    { getLeftSide() }
                </Grid>

                { /* Right side content */ }
                <Grid container item
                    xs={12}
                    lg={6}
                    direction='column'
                    sx={{
                    }}
                >
                    { getRightSide() }
                </Grid>
            </Grid>

            {/* Connect Dialog */}
            <ConnectDialog
                open={openConnectDialog}
                handleCloseDialog={handleCloseConnectDialog}
                modalRef={connectDialogRef}
                company={company}
                service={{ ...service, url: connectDialogServiceUrl, serviceIdentifier: connectDialogServiceIdentifier }}
            />

            {/* Unsubscribe Confirmation Dialog */}
            <OkCancelDialog
                id='confirm-subscription-cancellation-dialog'
                open={showUnsubscribeDialog}
                heading='Confirm Subscription Cancellation'
                okText='Yes, unsubscribe'
                cancelText="No, don't unsubscribe"
                handleOkOp={() => handleSubscription(false, subscriptionHandlingVersionId)}
                handleCancelOp={handleCloseUnsubscribeDialog}
                dialogRef={unsubscribeDialogRef}
            >
                {
                    showUnsubscribeDialog &&
                    <>
                        <BodyText labelStyle='text-center'>
                            Are you sure you want to unsubscribe from
                        </BodyText>
                        <BodyText labelStyle='text-center margin-top-1'>
                            service <strong>{service.name}</strong> version <strong>{subscriptionHandlingVersionId}</strong>?
                        </BodyText>
                    </>
                }
            </OkCancelDialog>

            {
                service?.dts &&
                <DataTransferDestinationDialog
                    openDialog={openDtsDialog}
                    handleCloseDialog={handleCloseDtsDialog}
                    companyId={companyId}
                    transferSourceId={service.dts.id}
                    handleServiceSubscription={() => handleSubscription(true, subscriptionHandlingVersionId)}
                    dialogRef={dtsDialogRef}
                />
            }
        </Box >
    );
};

export default ServiceDetail;
