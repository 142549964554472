import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setAlert } from '../app/slices/alert';
import { setPageTitle } from '../app/slices/page';
import { clearFilters, clearSearchText,
         fetchCategories, fetchProviders, fetchSearchResults,
         selectFetchingSearchResult, selectSearchResult,
         selectSelectedCategories, selectSelectedProviders,
         selectSelectedServiceTypes, selectSearchText, updateSearchFilter
       } from '../app/slices/search';
import { fetchSubscriptions, selectFetchingSubscriptions,
         selectSubscriptions } from '../app/slices/subscription';
import { selectCompanyId } from '../app/slices/user';
import { useAbortController } from '../hooks';
import { URL_HOME } from '../utils/navigation';
import FilterSelection from '../components/search/FilterSelection';
import ServicesCardDisplay from '../components/service-card/ServicesCardDisplay';
import SubscriptionsCardDisplay from '../components/service-card/SubscriptionsCardDisplay';


/**
 * This provides the main page with the service tiles and search filters
 * when the user has initiated a search for services.
 */

function CatalogSearch() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // For obtaining the services that this user is subscribed to
  const [ loadedSubscriptions, setLoadedSubscriptions ] = useState(false);
  const subscriptions = useSelector(selectSubscriptions);
  const fetchingSubscriptions = useSelector(selectFetchingSubscriptions);
  const companyId = useSelector(selectCompanyId);
  const { abortSignalRef, abort } = useAbortController();

  // For obtaining the search result
  const fetchingSearchResult = useSelector(selectFetchingSearchResult);
  const searchResult = useSelector(selectSearchResult);

  // For knowing what filter checkboxes are selected and if search text is set
  const selectedCategories = useSelector(selectSelectedCategories);
  const selectedProviders = useSelector(selectSelectedProviders);
  const selectedServiceTypes = useSelector(selectSelectedServiceTypes);
  const searchText = useSelector(selectSearchText);


  useEffect(() => {
    // set the page title
    dispatch(setPageTitle('Catalog Search'));

    // get category list
    dispatch(fetchCategories({ abortSignalRef }))
    .unwrap()
    .catch(err => {
        if (err === 'cancelled')
            return;

        console.error('Failed to get categories:', err);
        dispatch(setAlert({
            show: true,
            message: 'Failed to get categories',
            severity: 'error'
        }));
    });

    // get provider list
    dispatch(fetchProviders({ abortSignalRef }))
    .unwrap()
    .catch(err => {
        if (err === 'cancelled')
            return;

        console.error('Failed to get providers:', err);
        dispatch(setAlert({
            show: true,
            message: 'Failed to get providers',
            severity: 'error'
        }));
    });

    // populate the search results
    getSearchResults({ abortSignalRef });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Get subscriptions.  This has to be done separately since it depends
  // on the companyId which *may* not be set yet the first time this page is
  // loaded if the user is super fast at instigating a search
  useEffect(() => {
    if (companyId) {
        const getLogo = true;
        dispatch(fetchSubscriptions({companyId, getLogo, abortSignalRef}))
        .unwrap()
        .catch(err => {
            if (err === 'cancelled')
                return;

            console.error('Failed to get subscriptions:', err);
            dispatch(setAlert({
                show: true,
                message: 'Failed to get subscriptions',
                severity: 'error'
            }));
        })  
        .finally(() => {
            setLoadedSubscriptions(true);
        });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const getSearchResults = async (payload) => {
    dispatch(fetchSearchResults({
        getServiceCountPerFilter: true,
        ...payload,
    }))
    .unwrap()
    .catch(err => {
        if (err === 'cancelled')
            return;

        console.error('Failed to get search results:', err);
        dispatch(setAlert({
            show: true,
            message: 'Failed to get search results',
            severity: 'error'
        }));
    });
  }

  // Update the filters.
  // If filter is clear, navigate to the home page
  // Otherwise, get search results based on the filter value.
  const handleSearchFilterChange = (payload) => {
    dispatch(updateSearchFilter(payload))
    .unwrap()
    .then(isFilterClear => {
        if (isFilterClear && !searchText) {
            navigate(URL_HOME);
        } else {
            getSearchResults({ abortSignalRef, abort });
        }
    })
    .catch(error => {
        console.error('error updating the search filter:', error);
    });
  }

  const handleClearFilters = () => {
    dispatch(clearFilters());
    if (!searchText) {
        navigate(URL_HOME);
    } else {
        getSearchResults({ abortSignalRef, abort });
    }
  }

  // Clear the search text.
  // If the filters are also clear, navigate to the home page.
  // Otherwise, get search results based on the filter value.
  const handleClearSearchText = () => {
    dispatch(clearSearchText())
    if (!selectedCategories.length && !selectedProviders.length &&
        !selectedServiceTypes.length)
    {
        navigate(URL_HOME);
    } else {
        getSearchResults({ abortSignalRef, abort });
    }
  }

  return (
      <Stack direction='row'
          id='catalog-search'
          sx={{
              display: 'flex',
              marginTop: '1rem',
          }}
      >
          { /* Checkboxes for filter selection */ }
          <FilterSelection
              handleSearchFilterChange={handleSearchFilterChange}
              handleClearFilters={handleClearFilters}
          />

          { /* Service cards */ }
          <Card id="service-cards" sx={{ height: 'fit-content' }} >
              <CardContent>
                  <SubscriptionsCardDisplay
                      subscriptions={subscriptions}
                      fetching={!loadedSubscriptions || fetchingSubscriptions}
                  />
                  <ServicesCardDisplay
                      fetchingSubscriptions={fetchingSubscriptions}
                      subscriptions={subscriptions}
                      fetchingAllServices={fetchingSearchResult}
                      services={searchResult?.services}
                      isAllServices={false}
                      handleSearchFilterChange={handleSearchFilterChange}
                      handleClearSearchText={handleClearSearchText}
                  />
              </CardContent>
          </Card>
      </Stack>
  );
};

export default CatalogSearch;
