/**
 * This creates a step indicator which shows a series of labeled steps that
 * indicate which steps have been completed, which step is current, and which
 * steps are still to-do.  It is based on the USWDS Step Indicator which
 * satisfies Section 508 accessibility requirements.
 *
 * @param {array(object)} stepList       spec for each step
 * @param {number}        stepList.step  number of this step, 0-based
 * @param {string}        stepList.label text for this step
 * @param {number}        activeStep     currently active step, 0-based
 */
const StepIndicator = ({ stepList, activeStep }) => {

    return (
        <div
            className="usa-step-indicator usa-step-indicator--center usa-step-indicator--counters"
            aria-label="progress"
        >
            <ol className="usa-step-indicator__segments">
                {
                    stepList.map(accessStep => {
                        let stepClass = "usa-step-indicator__segment";
                        let completedStep = false;
                        let ariaCurrent = false;
                        if (accessStep.step < activeStep) {
                            stepClass += " usa-step-indicator__segment--complete"
                            completedStep = true;
                        }
                        else if (accessStep.step === activeStep) {
                            stepClass += " usa-step-indicator__segment--current"
                            ariaCurrent = true;
                        }
                        return (
                            <li className={stepClass}
                                key={accessStep.label}
                                aria-current={ariaCurrent}
                            >
                                <span className="usa-step-indicator__segment-label" >
                                    {accessStep.label}
                                    {completedStep &&
                                        <span className="usa-sr-only">completed</span>
                                    }
                                </span>
                            </li>
                        );
                    })
                }
            </ol>
        </div>
    );
};

export default StepIndicator;
